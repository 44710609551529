import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSliceName } from '../../../../../../core/store/store.utils';
import { RecommendationServiceApiProvider } from '../../../../services/recommendation/recommendationServiceApiProvider';
import { propertySelector } from '../../../../../../core/store/property/property.selector';
import { CMS } from '../../../../constants/cms.constants';
import { COPY_HYPERLINK_URL_TO_CLIPBOARD } from '../../recommendation/recommendation.actions';
import { EDITOR_ACTION_TYPE_PREFIX } from '../../editor.constants';
import { copyToClipboard } from '../../../../../../core/utils/utils';

export const initialState = {
  shouldShowRecommendations: true,
  suggestedArticles: [],
  suggestedPages: [],
  isLoadingPages: false,
  isLoadingArticles: false,
  suggestedArticlesError: null,
  suggestedPagesError: null,
};

const ERROR_MESSAGE = 'Something went wrong!';

const sliceScope = 'HyperlinksRecommendation';
const SLICE_NAME_WITH_PREFIX = EDITOR_ACTION_TYPE_PREFIX + sliceScope;
const SLICE_NAME = getSliceName(SLICE_NAME_WITH_PREFIX, sliceScope);

export const suggestArticleActionSlice = createAsyncThunk(
  `${SLICE_NAME}/suggestArticleActionSlice`,
  async (obj, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const property = propertySelector.currentProperty(getState(), CMS).slug;
    const { text, draftId } = obj;
    try {
      const searchSuggestArticlesResponse = await RecommendationServiceApiProvider.getPostsRecommendation(
        property, text, draftId,
      );
      return searchSuggestArticlesResponse.posts.filter(item => item.id !== draftId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const suggestPagesActionSlice = createAsyncThunk(
  `${SLICE_NAME}/suggestPagesActionSlice`,
  async (obj, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const property = propertySelector.currentProperty(getState(), CMS).slug;
    const { text } = obj;
    try {
      const suggestPagesResponse = await RecommendationServiceApiProvider.getPagesRecommendation(property, text);
      const { pages, tags } = suggestPagesResponse;
      return { pages, tags };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const suggestSearchArticlesSlice = createAsyncThunk(
  `${SLICE_NAME}/suggestSearchArticlesSlice`,
  async (obj, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const property = propertySelector.currentProperty(getState(), CMS).slug;
    const { text, draftId } = obj;
    try {
      const { posts } = await RecommendationServiceApiProvider.getSearchPostsRecommendation(property, text);
      const filteredSearchSuggestedArticle = posts.filter(item => item.id !== draftId);
      return filteredSearchSuggestedArticle;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const copyHyperlinkUrlToClipboard = createAsyncThunk(
  `${SLICE_NAME}/COPY_HYPERLINK_URL_TO_CLIPBOARD`,
  (url, thunkAPI) => {
    copyToClipboard(url);
    const { dispatch } = thunkAPI;
    return dispatch({
      type: COPY_HYPERLINK_URL_TO_CLIPBOARD,
      payload: url,
    });
  },
);

const hyperlinksRecommendation = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetEditorState(state) {
      Object.assign(state, initialState);
    },
    clearRecommendationLists(state) {
      state.suggestedArticles = [];
      state.suggestedPages = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(suggestArticleActionSlice.pending, state => {
        state.isLoadingArticles = true;
      })
      .addCase(suggestArticleActionSlice.fulfilled, (state, action) => {
        state.suggestedArticles = true;
        state.suggestedArticles = action.payload;
        state.suggestedArticlesError = null;
        state.isLoadingArticles = false;
      })
      .addCase(suggestArticleActionSlice.rejected, (state, action) => {
        state.suggestedArticlesError = action.payload;
        state.isLoadingArticles = false;
      })
      .addCase(suggestPagesActionSlice.pending, state => {
         state.isLoadingPages = true;
      })
      .addCase(suggestPagesActionSlice.fulfilled, (state, action) => {
        state.suggestedPages = action.payload.pages;
        state.suggestedPagesError = null;
        state.isLoadingPages = false;
      })
      .addCase(suggestPagesActionSlice.rejected, state => {
        state.suggestedPagesError = ERROR_MESSAGE;
        state.isLoadingPages = false;
      })
      .addCase(suggestSearchArticlesSlice.pending, state => {
      state.isLoadingArticles = true;
      })
      .addCase(suggestSearchArticlesSlice.fulfilled, (state, action) => {
        state.suggestedArticles = action.payload;
        state.suggestedArticlesError = null;
        state.isLoadingArticles = false;
      })
      .addCase(suggestSearchArticlesSlice.rejected, state => {
        state.suggestedArticlesError = ERROR_MESSAGE;
        state.isLoadingArticles = false;
      });
  },
});

export const {
  resetEditorState,
  clearRecommendationLists,
} = hyperlinksRecommendation.actions;

export default hyperlinksRecommendation.reducer;
