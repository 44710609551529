import {
  getStartOfMonth,
  formatDate,
  DATE_FORMATS,
  subMonthsFromDate,
  getMonthsForInterval,
  parseISO,
  getEndOfMonth,
} from '@ftbpro/mm-admin-core-utils';

export const createArrayOfMonths = (number: number) => {
  const currentDate = Date.now();
  const endDate = formatDate(
    getStartOfMonth(currentDate),
    DATE_FORMATS.ISO_DATE,
  );
  const startDate = formatDate(
    subMonthsFromDate(parseISO(endDate), number - 1),
    DATE_FORMATS.ISO_DATE,
  );

  return getMonthsForInterval(
    parseISO(startDate),
    parseISO(endDate),
  );
};

export const createStartEndDate = () => {
  const currentDate = Date.now();
  const start = formatDate(
    getStartOfMonth(currentDate),
    DATE_FORMATS.ISO_DATE,
  );
  const end = formatDate(getEndOfMonth(currentDate), DATE_FORMATS.ISO_DATE);

  return { start, end };
};
