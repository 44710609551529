import { COLORS } from '../../../../../../core/styles/colors';

export const getStylesObject = ({
  columns, loading, isPreviewImage, isSelected,
}) => ({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    padding: '0',
    margin: '-8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'inherit',
    alignItems: 'stretch',
    padding: '8px 0',
    flex: '1 0 100%',
  },
  column: {
    padding: '0 4px',
    marginTop: '0',
    marginBottom: '0',
    flex: `0 0 ${columns === 1 ? '100%' : '33.33%'}`,
  },
  imageHolder: {
    cursor: 'pointer',
    height: isPreviewImage ? 'auto' : '110px',
    width: isPreviewImage ? '100%' : '144px',
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${isSelected || isPreviewImage ? COLORS.PRIMARY100 : COLORS.SECONDARY15}`,
    padding: '4px',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      margin: '4px',
      background: isSelected ? 'rgba(79,125,255,0.5)' : 'none',
    },
    '&:hover': {
      border: `1px solid ${COLORS.PRIMARY100}`,
    },
  },
  imageItem: {
    display: loading ? 'none' : 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageCaption: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '16px',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 75%)',
    color: COLORS.WHITE,
    margin: '4px',
  },
  placeholder: {
    height: isPreviewImage ? '300px' : '100%',
    width: '100%',
    background: COLORS.SECONDARY20,
    alignSelf: 'center',
    padding: '4px',
  },
});
