import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { EditorNetworkService } from '../../services/editorServiceApiProvider';
import { propertySelector } from '../../../../../core/store/property/property.selector';
import { CMS } from '../../../constants/cms.constants';
import { draftSelector } from '../draft/draft.selector';
import {
  dismissGlobalNotification,
  pushGlobalNotificationActionCreator,
} from '../../../../../core/globalNotifications/store/globalNotifications.actions';

export const UPDATE_COMMENTS = '[EDITOR] update comments';
export const UPDATE_COMMENTS_FOR_ENTITY_REQUEST = '[EDITOR] request for comments';
export const UPDATE_COMMENTS_FOR_ENTITY_SUCCEEDED = '[EDITOR] update comments list for entity succeeded';
export const UPDATE_COMMENTS_FOR_ENTITY_FAILURE = '[EDITOR] update comments list for entity failure';
export const DELETE_COMMENTS_OF_ENTITY = '[EDITOR] delete comments of entity';

export const ADD_COMMENTS_FAILURE_TEXT = 'Oops, something went wrong. Please try again';

export function updateDraftComments(comments = {}) {
  return dispatch => {
    dispatch({
      type: UPDATE_COMMENTS,
      payload: { comments },
    });
  };
}

export const updateCommentsListForEntity = (entityKey, comments, property, draftId) => dispatch => {
  dispatch({
    type: UPDATE_COMMENTS_FOR_ENTITY_REQUEST,
  });
  return EditorNetworkService.updateComments({ entityKey, comments, property, draftId })
    .then(() => {
      dispatch({
        type: UPDATE_COMMENTS_FOR_ENTITY_SUCCEEDED,
        payload: {
          entityKey,
          comments,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: UPDATE_COMMENTS_FOR_ENTITY_FAILURE,
      });
      dispatch(pushGlobalNotificationActionCreator({
        id: entityKey,
        text: ADD_COMMENTS_FAILURE_TEXT,
        status: SNACK_BAR_STATUSES.ERROR,
        buttonText: 'Undo',
        onButtonClick: () => {
          dismissGlobalNotification(entityKey)(dispatch);
        },
      }));
    });
};

export const deleteCommentsListForEntity = entityKey => (dispatch, getState) => {
  const state = getState();
  const property = propertySelector.currentPropertySlug(state, CMS);
  const draftId = draftSelector.draftId(state);
  return EditorNetworkService.deleteComments({ entityKey, property, draftId }).then(() => {
    dispatch({
      type: DELETE_COMMENTS_OF_ENTITY,
      payload: {
        entityKey,
      },
    });
  });
};
