export const HEADER_TEXT = 'Publisher Dashboard';

export const TABLE_NO_SCHEDULED_ARTICLES_MESSAGE = 'There are no scheduled articles for this destination';
export const TABLE_NO_DATA_MESSAGE = 'There are no articles for this destination';

export const FILTER_KEYS = {
  DISPLAYING: 'displaying',
  FEED: 'feed',
  STATUS: 'status',
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  DATE_RANGE: 'dateRange',
  SEARCH: 'search',
  INCLUDE_LABELS: 'includeLabels',
  INCLUDE_LABELS_METHOD: 'includeLabelsMethod',
};

export const INCLUDE_LABELS_METHOD_OPTIONS = ['All of these labels', 'Any of these labels'];

export const FEED_FILTER_VALUES = {
  ALL_FEEDS: 'All Destinations',
};

export const DISPLAYING_FILTER_VALUES = {
  ALL_POSTS: 'Active Articles',
  ARCHIVED: 'Archived Articles',
};

export const STATUS_FILTER_VALUES = {
  DASHBOARD: {
    ALL_STATUSES: 'All Statuses',
    SUBMITTED: 'Submitted',
    SCHEDULED: 'Scheduled',
    PUBLISHED: 'Published',
    FAILED: 'Failed',
  },
};

export const DATE_RANGE_FILTER_VALUES = {
  DASHBOARD: {
    LAST_48_HOURS: 'Last 48 Hours',
    LAST_7_DAYS: 'Last 7 Days',
    LAST_30_DAYS: 'Last 30 Days',
    LAST_90_DAYS: 'Last 90 Days',
    ALL_TIME: 'All Time',
  },
};

export const ARCHIVE_BUTTON_TOOLTIP_TEXT = {
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
};
