export const ROOT_SLICE = 'vms';
const ROUTE_VMS_PREFIX = '/vms';
export const ROUTE_NEW_PAGE_SUFFIX = 'new';
export const PLATLISTS = '/playlists';

export enum PlaylistType {
  Dynamic = 'dynamic',
  Semantic = 'semantic',
  Manual = 'manual',
}

export const PLAYLIST_ROUTES_SCHEMA = {
    PLAYLISTS: `${ROUTE_VMS_PREFIX}${PLATLISTS}`,
    DYNAMIC_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/dynamic`,
    NEW_DYNAMIC_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/dynamic/:property/${ROUTE_NEW_PAGE_SUFFIX}`,
    EDIT_DYNAMIC_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/dynamic/:property/:id`,
    EDIT_DYNAMIC_PLAYLIST_HQ: `${ROUTE_VMS_PREFIX}${PLATLISTS}/dynamic/:tenant/:organization/:property/:id`,
    MANUAL_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/manual`,
    NEW_MANUAL_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/manual/:property/${ROUTE_NEW_PAGE_SUFFIX}`,
    EDIT_MANUAL_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/manual/:property/:id`,
    EDIT_MANUAL_PLAYLIST_HQ: `${ROUTE_VMS_PREFIX}${PLATLISTS}/manual/:tenant/:organization/:property/:id`,
    SEMANTIC_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/semantic`,
    NEW_SEMANTIC_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/semantic/:property/${ROUTE_NEW_PAGE_SUFFIX}`,
    EDIT_SEMANTIC_PLAYLIST: `${ROUTE_VMS_PREFIX}${PLATLISTS}/semantic/:property/:id`,
    EDIT_SEMANTIC_PLAYLIST_HQ: `${ROUTE_VMS_PREFIX}${PLATLISTS}/semantic/:tenant/:organization/:property/:id`,
};
