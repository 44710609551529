import React, { useRef } from 'react';
import { CrowdSignalBlockProps } from '../crowdSignal.types';
import { HtmlEmbedBlock } from '../../shared/HtmlEmbedBlock';

export const CrowdSignalBlock = (props: CrowdSignalBlockProps) => {
  const { data } = props;
  const blockRef = useRef(null);
      const parser = new DOMParser();
    const doc = parser.parseFromString(data.value.html, 'text/html');
    const iframeElement = doc.querySelector('iframe');
    if (iframeElement) {
      iframeElement.width = '100%';
      iframeElement.height = '500';
    }

  const embedBlockProps = {
    ...props,
    data: {
      ...data,
      value: {
        ...data.value,
        embedCodeHTMLString: iframeElement?.outerHTML,
      },
    },
    blockRef,
  };

  return <HtmlEmbedBlock {...embedBlockProps} />;
};
