import { Logger } from '../logger';

export const getLocalStorageData = key => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    Logger.error(error);
    return null;
  }
};

export const setLocalStorageData = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    Logger.error(error);
  }
};
