import { RootState } from 'core/store';
import { getEditorState } from '../../editor.selector';
import { InThisStorySliceState } from './types';

function getInThisStoryState(state: RootState): InThisStorySliceState {
  return getEditorState(state).editorialMenu.inThisStory;
}

export const inThisStorySelector = {
  inThisStoryList(state: RootState) {
    return getInThisStoryState(state).inThisStoryList;
  },
  selectedInThisStories(state: RootState) {
    return getInThisStoryState(state).selectedInThisStories;
  },
};
