export const UNAUTHORIZED_ERROR_TITLE = 'Sorry, you don’t have access';

export const UNAUTHORIZED_ERROR_MESSAGE = 'Please contact your administrator.';

export const NO_ORG_PERMISSION_MESSAGE = 'Try going here instead: ';

export const UNAUTHORIZED_ERROR_DESCRIPTION = {
    NO_ROLES: 'It seems that you don’t have the right permissions to access our platform.',
    NO_PROPERTIES: 'It seems that you’re not assigned any properties.',
    NO_ORG_PERMISSION: 'It seems that you don’t have permission to access this organization',
};

export const BLOCKED_USER_ERROR = 'you aren\'t authorized to access the platform.';

export const VOLTAX_LOGO = 'VoltaxLogo';
