import { Store } from '@reduxjs/toolkit';
import { hasOwnProperty } from '@ftbpro/mm-admin-core-utils';
import { RootState } from 'core/store';
import { getFetchFunction } from './networkMiddleware.constans';
import { getMMRequestConfig, isNetworkAction } from './networkMiddleware.utils';
import { userSelector } from '../../store/user/user.selector';
import { NetworkActionPayload, MMFetchErrorObject } from './types';

export const networkMiddleware = (store: any) => (next: any) => async (action: any) => {
  const { dispatch, getState }: Store<RootState> = store;
  const networkActionType = action.type;
  const state: RootState = getState();

  if (!isNetworkAction(networkActionType) || !userSelector.getUserToken(state)) {
    return next(action);
  }

  const {
    body,
    url,
    method,
    data,
    headers,
    onPending,
    onSuccess,
    onFailure,
    timeout,
  } = action.payload as NetworkActionPayload;

  const fetchFunction = getFetchFunction[networkActionType];

  const requestConfig = getMMRequestConfig(state, headers);

  const options = {
    timeout,
    method,
    body: body ?? (data && JSON.stringify(data)),
    ...requestConfig,
  };

  onPending && dispatch(onPending());
  try {
    const res = await fetchFunction({ url, options });
    if (res) {
      const payloadData = hasOwnProperty(res, 'data') ? res.data : res;
      onSuccess && dispatch(onSuccess(payloadData));
    }
  } catch (error) {
    onFailure && dispatch(onFailure(error as MMFetchErrorObject));
  }

  return next(action);
};
