export const COLORS = {
  PRIMARY100: '#4f7dff',
  PRIMARY90: '#5887FF',
  PRIMARY80: '#6b95ff',
  PRIMARY75: '#7b9fff',
  PRIMARY30: '#c8d8ff',
  PRIMARY15: '#e4ecff',
  PRIMARY10: '#ecf2ff',
  PRIMARY5: '#f6f8ff',

  SECONDARY100: '#1f0f66',
  SECONDARY20: '#d3cee2',
  SECONDARY15: '#dfdbe9',
  SECONDARY10: '#e8e7f0',
  SECONDARY5: '#f4f3f7',
  SECONDARY3: '#f8f7fa',
  SECONDARY30: '#bbb7d1',
  SECONDARY40: '#a79ec5',

  TERTIARY100: '#692ed1',
  TERTIARY50: '#ae91e2',
  TERTIARY30: '#cbbaeb',
  TERTIARY10: '#e7e2f2',
  TERTIARY5: '#eeebf3',
  TERTIARY3: '#faf8fd',

  BLACK100_OPACITY10: 'rgba(0, 0, 0, 0.1)',
  BLACK100_OPACITY20: 'rgba(0, 0, 0, 0.2)',
  BLACK100_OPACITY30: 'rgba(0, 0, 0, 0.3)',
  BLACK100_OPACITY80: 'rgba(0, 0, 0, 0.8)',
  BLACK100_OPACITY5: 'rgba(0, 0, 0, 0.05)',
  BLACK100_OPACITY12: 'rgba(0, 0, 0, 0.12)',
  BLACK100_OPACITY32: 'rgba(0, 0, 0, 0.32)',
  BLACK100_OPACITY15: 'rgba(0, 0, 0, 0.15)',

  BLACK100: '#000000',
  BLACK90: '#191919',
  BLACK70: '#4c4c4c',
  BLACK60: '#bcb7d2',
  BLACK50: '#7f7f7f',
  BLACK40: 'rgba(0, 0, 0, 0.5)',
  BLACK30: '#b2b2b2',
  BLACK20: '#cccccc',
  BLACK10: '#e5e5e5',
  BLACK5: '#f2f2f2',
  BLACK3: '#f7f7f7',
  WHITE: '#ffffff',
  RED: '#d30000',
  GREEN: '#3fb400',
  GREEN_OPACITY10: 'rgba(63, 180, 0, 0.1)',
  YELLOW: '#ffb54f',
  ORANGE: '#ffab4f',
  LIGHT_ORANGE: '#fff5e3',
  TRANSPARENT_ORANGE: 'rgba(255, 171, 79, 0.5)',
  TRANSPARENT: 'transparent',

  SELECTION_BACKGROUND: '#CCE2FF',
  INLINE_TEXT_TOOLTIP: '#303030',

  TERTIARY_GRADIENT: 'linear-gradient(47deg, #6A2FD2 0%, #7B4CF8 35.90%, #CE4CFC 100%)',
  TERTIARY50_GRADIENT: 'linear-gradient(135deg, #692ED1 0%, #BDA5E7 30.73%, #D9CDEE 63.54%, rgba(201, 76, 252, 0.30) 100%)',
  TERTIARY_TEXT_GRADIENT: 'linear-gradient(77deg, #6A2FD2 0%, #7B4CF8 51.90%, #CE4CFC 100%)',
};

export const COLOR_SCHEMES = {
  PRIMARY: {
    50: COLORS.PRIMARY5, // siteVariables.brand16
    100: COLORS.PRIMARY10, // siteVariables.brand14
    200: COLORS.PRIMARY10, // siteVariables.brand12
    300: COLORS.PRIMARY15,
    400: COLORS.PRIMARY100,
    500: COLORS.PRIMARY100, // siteVariables.brand, siteVariables.brand06 (same color?)
    600: '#55578D',
    700: '#4A4C78',
    800: '#414265',
    900: '#33344A', // siteVariables.brand02
  },
  GREY: {
    50: COLORS.WHITE, // '#FFFFFF', // siteVariables.white
    100: COLORS.BLACK10,
    200: COLORS.BLACK20,
    300: COLORS.BLACK30,
    400: COLORS.BLACK40,
    500: COLORS.BLACK50,
    600: COLORS.BLACK70,
    700: COLORS.BLACK70,
    800: COLORS.BLACK90,
    900: COLORS.BLACK100, // '#000000', // siteVariables.black
  },
};
