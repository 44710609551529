import { DATE_FORMATS, formatDate, isThisYear, isTodayDate } from '@ftbpro/mm-admin-core-utils';
import { getFormattedDate } from '../../../core/utils/date.utils';
import { NOT_FEATURED_POST_DATE, SWIMSUIT_SLUG } from '../constants/cms.constants';

export const getArticleFormattedDate = date => (date ? getFormattedDate(date) : NOT_FEATURED_POST_DATE);

export const getArticleFormattedDateAndTime = datetime => {
  if (datetime) {
    let dateFormat = `${DATE_FORMATS.SHORT_DATE} ${DATE_FORMATS.TIME}`;
    if (isTodayDate(datetime)) {
      dateFormat = DATE_FORMATS.TIME;
    } else if (!isThisYear(datetime)) {
      dateFormat = DATE_FORMATS.DATE_TIME_AMPM;
    }
    return formatDate(datetime, dateFormat);
  }

  return NOT_FEATURED_POST_DATE;
};

export const convertSecondsToMilliseconds = time => time * 1000;

export const createSuggestionText = (title, bodyText) => {
  return `${title} ${bodyText}`;
};

export const isSwimsuit = slug => slug === SWIMSUIT_SLUG || true;
