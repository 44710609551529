export const EVENT_TYPES = {
  DASHBOARD_FILTERS_APPLIED: 'Dashboard filters applied',
  DASHBOARD_RESET_CLICKED: 'Dashboard reset clicked',
  DASHBOARD_PAGE_VIEWED: 'Dashboard viewed',
  DASHBOARD_PAGE_LOADED: 'Dashboard loaded',
  DASHBOARD_ERROR: 'Error',
  DASHBOARD_DISCLAIMER_VIEWABLE: 'Disclaimer viewable',
  QUERY_BUILDER_SHARE_CLICKED: 'Share button clicked',
  QUERY_BUILDER_SCHEDULE_REPORT_CREATED: 'Schedule report creation',
  QUERY_BUILDER_RUN_REPORT: 'Run report',
  QUERY_BUILDER_CLEAR_ALL_CLICKED: 'Clear all clicked',
  QUERY_BUILDER_SAVED_REPORT_LOADED: 'Saved report loaded',
  QUERY_BUILDER_SAVED_REPORT_DELETED: 'Saved report deleted',
  QUERY_BUILDER_GENERATE_API_KEY: 'API key generated',
  QUERY_BUILDER_SCHEDULE_REPORTS_VIEWED: 'Scheduled reports viewed',
  QUERY_BUILDER_ERROR: 'Error',
};

export const DASHBOARD_EVENT_CATEGORY = 'Publisher Dashboard';

export const QUERY_BUILDER_EVENT_CATEGORY = 'Reports Builder';
