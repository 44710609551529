import React from 'react';
import { css } from '@emotion/core';

import { Plugin } from '../Plugin/Plugin';
import { getStylesObject } from './giphy.styles';

const GIF_TEXT = 'Gif';

export const GifBlock = ({
   shouldPreventEdit,
   shouldPreventDelete,
   onMediaDeleted,
   onMediaEdited,
   onMediaEditCanceled,
   isEditing,
   data,
}) => {
  const { image } = getStylesObject();
  return (
    <Plugin.Block
      shouldPreventEdit={shouldPreventEdit}
      shouldPreventDelete={shouldPreventDelete}
      onDelete={onMediaDeleted}
      onEdit={onMediaEdited}
      onCancel={onMediaEditCanceled}
      isEditing={isEditing}
    >
      <img src={data.value.url} alt="BlockGif" css={css(image)} />
    </Plugin.Block>
  );
};

export const GifBlockOverview = ({ value }) => {
  const { overviewImage } = getStylesObject();
  return (
    <Plugin.OverviewBlock>
      <img src={value.url} alt="OverviewGif" css={css(overviewImage)} />
      <span>{GIF_TEXT}</span>
    </Plugin.OverviewBlock>
  );
};
