export const IMAGE_MAX_SIZE_IN_BYTES = 5242880;
export const IMAGE_MIN_WIDTH = 400;
export const IMAGE_MIN_HEIGHT = 224;

export const ERROR_MESSAGES = {
  IMAGE_SIZE_TOO_LARGE: 'This one tipped the scales. Please choose an image that’s no larger than 5 MB.',
  IMAGE_DIMENSIONS_TOO_SMALL: 'The file is too small. Please upload a new file with a minimum resolution of 400x224 pixels',
};

export const HTTP = 'http://';
export const HTTPS = 'https://';

export const BLANK_URL = '_blank';
export const NO_FOLLOW_URL = 'nofollow';

export const AVAILABLE_UPLOAD_SOURCES = {
  EDITOR: 'EDITOR',
  ECHO: 'ECHO',
};

export const IMAGE_REQUIREMENTS = {
  FILETYPE: 'Supported Filetypes: jpeg, png, jpg, webp',
  DIMENSIONS: 'Minimum dimensions: 400X224 pixels',
  SIZE: 'Maximum size: 5MB',
};
