import { KEY_CODE_Y, KEY_CODE_Z } from '../editor.constants';
import { BLOCK_TYPES } from './blocksDescriptorGenerator';

export function doesDraftHaveUrl({ url }) {
  return !!url;
}

export function hasDraftBeenPublished(draftData) {
  return doesDraftHaveUrl(draftData);
}

export function isUndoKeyboard(e) {
  const isCtrlZEvent = e.keyCode === KEY_CODE_Z && e.ctrlKey;
  const isMetaZEvent = e.keyCode === KEY_CODE_Z && e.metaKey;
  return isCtrlZEvent || isMetaZEvent;
}

export function isRedoKeyboard(e) {
  const isMetaShiftZEvent = e.keyCode === KEY_CODE_Z && e.shiftKey && e.metaKey;
  const isCtrlShiftZEvent = e.keyCode === KEY_CODE_Z && e.shiftKey && e.ctrlKey;
  const isCtrlY = e.keyCode === KEY_CODE_Y && e.ctrlKey;
  return isCtrlShiftZEvent || isMetaShiftZEvent || isCtrlY;
}

export const getIsGroupOfLinksExists = blockList => {
  return blockList.some(block => block.type === BLOCK_TYPES.CONTENT_ENRICHMENT && block.value.embedType === 'GroupOfLinks');
};
