export enum LOADING_STATES {
  IDLE = 'idle',
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCESS = 'success',
}

export const PLATFORM_TITLE = 'Voltax';

export const SYSTEM_LOGO = {
  stn: 'STN',
  minuteMedia: 'Minute Media',
};
