import { runInstagramScript } from '../plugins/externalScripts/editorPluginsScripts';
import { mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { EditorServiceDataProvider } from './editorServiceConfigsProvider';
import { getOembedObjectFromOEmbedResponse } from './oembed/oEmbedApiProvider';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';

export async function loadInstagramPosts() {
  const instagram = await runInstagramScript();
  return instagram.Embeds.process();
}

export async function getInstagramOembedData(url, property) {
  const configs = {
    method: 'GET',
  };
  const res = await mmNetworkService.fetch(EditorServiceDataProvider.getInstagramOembedEndpoint(url, property), configs);
  return getOembedObjectFromOEmbedResponse(res, url, BLOCK_TYPES.INSTAGRAM);
}
