import {
  DATE_FORMATS,
  formatDate,
  getStartOfMonth,
  getEndOfMonth,
  subMonthsFromDate,
  subYearsFromDate,
  parseISO,
  isSameMonth,
  getStartOfYear,
  getEndOfYear,
} from '@ftbpro/mm-admin-core-utils';
import { DropdownListItemProps } from '@ftbpro/mm-admin-ui-components';
import { createArrayOfMonths } from './analytics.utils';
import {
  StatsFromServer,
  ViewTier,
  SiteStats,
  ActiveUser,
  UserForTable,
  UserStats,
  UserStatsForTable,
  PaymentStats,
  PaymentStatsForTable,
  PostStats,
  AuthorsFromServer,
} from './types';
import { WORDPRESS_URL_PARAM } from '../analytics.constants';

export const formatStatsForClient = (stats: StatsFromServer): SiteStats => {
  return {
    nonExpertViews: stats?.nonExpertPageViews
      ? totalViews(stats.nonExpertPageViews).toLocaleString()
      : 0,
    siteViews: stats?.siteViews
      ? totalViews(stats.siteViews).toLocaleString()
      : 0,
    pubCount: stats?.pubCount ?? 0,
    activeUsers: stats?.activeUsers
      ? formatActiveUsersForTable(stats.activeUsers)
      : [],
  };
};

export const totalViews = (views: ViewTier[]): number => views.reduce(
    (total: number, item: Record<string, number>) => total + item.views,
    0,
  );

export const formatPageViews = (views: ViewTier[]) => views.reduce((acc, cur) => {
    return { ...acc, [`geoTier${cur.geoTier}`]: cur.views ?? 0 };
  }, {});

export const formatActiveUsersForTable = (
  activeUsers: ActiveUser[],
): UserForTable[] => {
  return activeUsers.map((user, index) => {
    const { name, email, pubCount, lastPublished, views } = user;
    return {
      actions: { id: index },
      name,
      email,
      pubCount,
      lastPublished: formatDate(
        new Date(lastPublished * 1000),
        DATE_FORMATS.DATE_PERIOD,
      ),
      ...(views?.length && formatPageViews(views)),
    };
  });
};

export const formatMonthsForFilter = (
  monthCount: number,
): { date: string; value: string }[] => {
  const currentDate = Date.now();
  const months = [];

  for (let i = 0; i <= monthCount - 1; i += 1) {
    const nextMonth = subMonthsFromDate(currentDate, i);
    months.push({
      value: formatDate(nextMonth, DATE_FORMATS.MONTH_AND_YEAR).toString(),
      date: JSON.stringify(formatMonthParams(nextMonth)),
    });
  }

  return months;
};

export const formatYearsForFilter = (
  yearCount: number,
): { date: string; value: string }[] => {
  const currentDate = Date.now();
  const years = [];

  for (let i = 0; i <= yearCount - 1; i += 1) {
    const nextYear = subYearsFromDate(currentDate, i);
    years.push({
      value: formatDate(nextYear, 'yyyy').toString(),
      date: JSON.stringify(formatYearParams(nextYear)),
    });
  }

  return years;
};

export const formatUserStatsForTable = (
  userStats: UserStats[],
): UserStatsForTable[] => {
  return userStats.map((stat, index) => {
    const { siteName, pubCount, views } = stat;
    return {
      actions: { id: index },
      siteName,
      pubCount,
      ...(views.length && formatPageViews(views)),
    };
  });
};

export const formatMonthParams = (date: Date) => ({
  startDate: formatDate(getStartOfMonth(new Date(date)), DATE_FORMATS.ISO_DATE),
  endDate: formatDate(getEndOfMonth(new Date(date)), DATE_FORMATS.ISO_DATE),
});

export const formatYearParams = (date: Date) => ({
  startDate: formatDate(getStartOfYear(new Date(date)), DATE_FORMATS.ISO_DATE),
  endDate: formatDate(getEndOfYear(new Date(date)), DATE_FORMATS.ISO_DATE),
});

export const formatPaymentsForTable = (
  payments: PaymentStats[],
): PaymentStatsForTable[] => {
  if (!payments.length) {
    return [];
  }

  const monthArray = createArrayOfMonths(12);

  return monthArray
    .map((date, index) => {
      const paymentsByMonth = payments?.filter(payment => {
        return isSameMonth(parseISO(payment.date), date);
      });

      if (!paymentsByMonth?.length) {
        return null;
      }

      const totalPayment = paymentsByMonth.reduce((acc, curr) => {
        return acc + Number(curr.total.replace(/,/g, ''));
      }, 0);

      return {
        actions: { id: index },
        payments: paymentsByMonth,
        amount: totalPayment.toFixed(2),
        month: formatDate(parseISO(paymentsByMonth[0].date), 'MMMM yyyy'),
      };
    })
    .filter(stats => stats);
};

export const formatPostsForTable = (posts: PostStats[]) => {
  return posts.map((post: PostStats) => ({
    pageViews: post.pageViews,
    post: {
      url: post.url.includes(WORDPRESS_URL_PARAM) ? '' : post.url,
      title: post.title,
    },
    ...(post.author && { author: post.author }),
  }));
};

export const formatAuthorsForFilter = (authors: AuthorsFromServer[]): DropdownListItemProps[] => {
  return authors.map(author => {
    return ({ content: author.name, value: author.id });
  });
};
