import { isEnterPressed } from '../../../../core/utils/keyboard.utils';

export const isShiftEnterKeyPressedEvent = event => {
  return isEnterPressed(event) && event.shiftKey;
};

export const removeEmptyParagraphsFromTextArray = textArray => textArray.filter(item => item.trim() !== '');

export const removeLineBreaks = text => text.replace(/\n/g, '');

const hasHttpOrHttpsPrefix = url => (url.substring(0, 7) === 'http://' || url.substring(0, 8) === 'https://');

export const addHttpsPrefixToUrlIfNeeded = url => (hasHttpOrHttpsPrefix(url) ? url : `https://${url}`);

export const getNumberOfWordsInAString = str => str.split(' ').filter(word => word !== '').length;
