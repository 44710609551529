import {
  Editor,
  Node,
  Point,
  Range,
  Text,
  Transforms,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { SLATE_NODE_TYPES } from './slate.constants';
import {
  createMultipleTextNodes,
  createSlateValue,
  getSanitizedSlateFragment,
  getNodesValueByIndex,
  isSlateValueEmpty,
  isFragmentValid,
} from './slateValue.utils';
import { BLOCK_TYPES } from '../blocksDescriptorGenerator';
import { deserialize } from './slateSerializer.utils';

const NEW_LINE_TEXT_SEPARATOR_REGEX = /\n\s*\n/;

export const selectAll = slateEditor => {
  Transforms.select(slateEditor, []);
};

export const isSlateBlockTypeActive = (type, slateEditor) => {
  const [match] = Editor.nodes(slateEditor, {
    match: node => node.type === type,
  });
  return !!match;
};

export const isSlateMarkTypeActive = (slateEditor, type) => {
  const [match] = Editor.nodes(slateEditor, {
    match: node => node[type] === true,
    mode: 'all',
  });
  return !!match;
};

const doesEverySubPathEqualZero = pathsArray => {
  return pathsArray.every(subPath => subPath === 0);
};

export const isAnchorCollapsedToStart = slateEditor => {
  const { selection } = slateEditor;
  const anchorPoint = Range.start(selection);
  const { path: pathsArray } = anchorPoint;
  return anchorPoint.offset === 0 && doesEverySubPathEqualZero(pathsArray);
};

const getLastNodeEntry = slateEditor => {
  const [...nodeEntries] = Editor.nodes(slateEditor, {
    mode: 'lowest',
  });
  const lastNodeEntry = nodeEntries[nodeEntries.length - 1];
  return lastNodeEntry;
};

export const isAnchorCollapsedToEnd = slateEditor => {
  const { selection } = slateEditor;
  const focusPoint = Range.end(selection);
  const lastNodeEntry = getLastNodeEntry(slateEditor);
  return lastNodeEntry[0].text.length === focusPoint.offset;
};

export const shouldListBeDeleted = (EventKeyCode, slateEditor) => {
  const textLeaf = Editor.string(slateEditor, [0, 0]);
  // keycode === 8 backspace
  return ((EventKeyCode === 8) && textLeaf.length === 0 && (isSlateBlockTypeActive(SLATE_NODE_TYPES.LIST_ITEM, slateEditor)));
};

export const getStringListFromSlateValue = node => {
  if (Text.isText(node)) {
    return node.text;
  }
  return node.children.map(childNode => getStringListFromSlateValue(childNode)).join(' ');
};

const getAllInlineInNode = (arrayOfLinks, node) => {
  if (node.type === SLATE_NODE_TYPES.LINK) {
    return [...arrayOfLinks, node.url];
  } if (node.children) {
    return node.children.reduce(getAllInlineInNode, arrayOfLinks);
  }
    return arrayOfLinks;
};

export const getAllLinksInBlock = (arrayOfLinks, block) => {
  let wrapperNode;
  if (block.type === BLOCK_TYPES.TEXT) {
    [wrapperNode] = block.value;
    return getAllInlineInNode(arrayOfLinks, wrapperNode);
  } if (block.type === BLOCK_TYPES.LIST_ITEM) {
    [wrapperNode] = block.value.items[2].value;
    return getAllInlineInNode(arrayOfLinks, wrapperNode);
  }
  return arrayOfLinks;
};

export const destroyAnExistingList = slateEditor => {
  Transforms.unwrapNodes(slateEditor, { match: n => n.type === SLATE_NODE_TYPES.BULLETED_LIST });
  Transforms.unwrapNodes(slateEditor, { match: n => n.type === SLATE_NODE_TYPES.NUMBERED_LIST });
  Transforms.unwrapNodes(slateEditor, { match: n => n.type === SLATE_NODE_TYPES.LIST_ITEM });
  const emptyParagraphValue = createSlateValue([{ text: '' }]);
  Editor.insertNode(slateEditor, emptyParagraphValue);
};

export function toggleHyperlinkedWord(slateEditor, href) {
  const link = {
    type: SLATE_NODE_TYPES.LINK,
    url: href,
    children: [],
  };
  Transforms.wrapNodes(slateEditor, link, { split: true });
  Transforms.collapse(slateEditor, { edge: 'end' });
}

export const removeLinkFromSelectedWord = slateEditor => {
  Transforms.unwrapNodes(slateEditor, { match: n => n.type === SLATE_NODE_TYPES.LINK });
};

export const addSlateLinkObject = (slateEditor, slateLinkObject, targetLocation) => {
  Transforms.select(slateEditor, targetLocation);
  // remove link and update afterwards
  if (isSlateBlockTypeActive(SLATE_NODE_TYPES.LINK, slateEditor)) {
   removeLinkFromSelectedWord(slateEditor);
  }
  Transforms.wrapNodes(slateEditor, slateLinkObject, { split: true });
  Transforms.collapse(slateEditor, { edge: 'end' });
  const { selection: newSelection } = slateEditor;
  setTimeout(() => {
    ReactEditor.focus(slateEditor);
    Transforms.select(slateEditor, Editor.after(slateEditor, newSelection.focus.path));
  }, 0);
};

export const isListType = type => {
  return type === SLATE_NODE_TYPES.BULLETED_LIST
    || type === SLATE_NODE_TYPES.NUMBERED_LIST;
};

const getNewSlateType = (isActive, isList, type) => {
  if (isActive) {
    return SLATE_NODE_TYPES.PARAGRAPH;
  } if (isList) {
    return SLATE_NODE_TYPES.LIST_ITEM;
  }
    return type;
};

export const toggleTextBlockTypeOfTextValue = (type, slateEditor) => {
  const isActive = isSlateBlockTypeActive(type, slateEditor);
  const isList = isListType(type);
  selectAll(slateEditor);
  Transforms.unwrapNodes(slateEditor, {
    match: node => isListType(node.type),
    split: true,
  });
  Transforms.setNodes(slateEditor, {
    type: getNewSlateType(isActive, isList, type),
  });

  if (!isActive && isList) {
    const block = { type, children: [] };
    Transforms.wrapNodes(slateEditor, block);
  }
};

export const toggleMarkType = (type, slateEditor) => {
  const isActive = isSlateMarkTypeActive(slateEditor, type);

  if (isActive) {
    Editor.removeMark(slateEditor, type);
  } else {
    Editor.addMark(slateEditor, type, true);
  }
};

export const getHrefFromLink = node => {
  return node.url;
};

export const findAlreadyGivenHrefIfExists = slateEditor => {
  const [link] = Editor.nodes(slateEditor,
    { match: n => n.type === SLATE_NODE_TYPES.LINK });
  return link || false;
};

export const splitEditorIntoTwoNodes = slateEditor => {
  slateEditor.insertBreak();
  return [
    getNodesValueByIndex(slateEditor, 0),
    getNodesValueByIndex(slateEditor, 1),
  ];
};

export const setSelection = ({ anchorPath, anchorOffset, focusPath, focusOffset }) => ({
  type: 'set_selection',
  newProperties: {
    anchor: {
      path: anchorPath,
      offset: anchorOffset,
    },
    focus: {
      path: focusPath,
      offset: focusOffset,
    },
  },
});

export const isEditorEmpty = slateEditor => {
  const editorFirstChild = getNodesValueByIndex(slateEditor, 0);
  return editorFirstChild[0].type === SLATE_NODE_TYPES.PARAGRAPH
  && isSlateValueEmpty(editorFirstChild);
};

export const insertNonFormattedText = (nonFormattedText, slateEditor) => {
  const textArray = nonFormattedText.split(NEW_LINE_TEXT_SEPARATOR_REGEX);
  const multipleTextNodesArray = createMultipleTextNodes(textArray);
  Transforms.insertNodes(slateEditor, multipleTextNodesArray);
  Transforms.mergeNodes(slateEditor, {
    at: [1],
  });
};

const insertFormattedFragment = (synthesizedFragments, slateEditor) => {
  const isAnchorCollapsedToEndPriorNodeManipulation = isAnchorCollapsedToEnd(slateEditor);
  const isSlaveValueEmptyPriorNodeManipulation = isSlateValueEmpty(slateEditor.children);
  Transforms.insertNodes(slateEditor, synthesizedFragments);
  Transforms.mergeNodes(slateEditor, {
    at: [1],
  });
  const shouldMergeLastNode = !isAnchorCollapsedToEndPriorNodeManipulation && !isSlaveValueEmptyPriorNodeManipulation;
  if (shouldMergeLastNode) {
    Transforms.mergeNodes(slateEditor, {
      at: [slateEditor.children.length - 1],
    });
  }
};

export const insertFormattedPastedTextYieldsValidFragment = (pastedHtmlDoc, pastedText, slateEditor) => {
  const slateFragment = deserialize(pastedHtmlDoc.body);
  const synthesizedFragments = getSanitizedSlateFragment(slateFragment);
  if (!isFragmentValid(synthesizedFragments)) {
    return insertNonFormattedText(pastedText, slateEditor);
  }
  return insertFormattedFragment(synthesizedFragments, slateEditor);
};

export const unWrapEditorsFirstChild = (editorFirstChild, slateEditor) => {
  Transforms.unwrapNodes(slateEditor, {
    at: [0],
    match: innerNode => innerNode.type === editorFirstChild.type,
  });
};

const insertLineBreakersAtNodeEnd = fragment => {
  return fragment.map(({ type, children }, index) => {
    const isLastNode = index === fragment.length;
    if (!isLastNode) {
      return {
        type,
        children: [...children, { text: '\n\n' }],
      };
    }
      return {
        type,
        children,
      };
  });
};

export const insertFormattedPastedTextToGroupedBlock = (pastedHtmlDoc, slateEditor) => {
  const slateFragment = deserialize(pastedHtmlDoc.body);
  const synthesizedFragment = getSanitizedSlateFragment(slateFragment);
  const synthesizedFragmentWithLineBreakers = insertLineBreakersAtNodeEnd(synthesizedFragment);
  Transforms.insertNodes(slateEditor, synthesizedFragmentWithLineBreakers);
  while (slateEditor.children.length > 1) {
    Transforms.mergeNodes(slateEditor, {
      at: [1],
    });
  }
};

export const resetNodes = (editor, options = {}) => {
  const children = [...editor.children];

  children.forEach(node => editor.apply({ type: 'remove_node', path: [0], node }));

  if (options.nodes) {
    const nodes = Node.isNode(options.nodes) ? [options.nodes] : options.nodes;
    nodes.forEach((node, i) => editor.apply({ type: 'insert_node', path: [i], node }));
  }

  const point = options.at && Point.isPoint(options.at) ? options.at : Editor.end(editor, []);

  if (point) {
    Transforms.select(editor, point);
  }
};
