import { COLORS } from './colors';

export const SITE_VARIABLES = {
  PADDING: {
    EXTRA_TINY: '2px',
    TINY: '4px',
    EXTRA_SMALL: '6px',
    SMALL: '8px',
    MEDIUM_SMALL: '12px',
    MEDIUM: '16px',
    BIG: '24px',
    HUGE: '32px',
    HUMONGOUS: '40px',
  },
  MARGIN: {
    EXTRA_TINY: '2px',
    TINY: '4px',
    EXTRA_SMALL: '6px',
    SMALL: '8px',
    MEDIUM_SMALL: '12px',
    MEDIUM: '16px',
    BIG: '24px',
    HUGE: '32px',
    HUMONGOUS: '40px',
    EXTRA_HUMONGOUS: '48px',
  },
  BORDER_RADIUS: {
    EXTRA_TINY: '2px',
    TINY: '4px',
    EXTRA_SMALL: '6px',
    SMALL: '8px',
    MEDIUM: '16px',
    BIG: '24px',
    HUGE: '32px',
    HUMONGOUS: '40px',
    ROUND: '50%',
  },
  BOX_SHADOW: {
    LIGHT: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  COLORS: {
    BACKGROUND: COLORS.SECONDARY3,
    BLACK: COLORS.BLACK100,
    WHITE: COLORS.WHITE,
    TRANSPARENT: COLORS.TRANSPARENT,
    DISABLED: COLORS.BLACK20,
    LIGHTER_ACTIVE: COLORS.SECONDARY10,
    ACTIVE: COLORS.SECONDARY15,
    DARKER_ACTIVE: COLORS.SECONDARY20,
    SELECTED: COLORS.PRIMARY100,
    PRIMARY: COLORS.PRIMARY100,
    SECONDARY: COLORS.SECONDARY100,
    PRIMARY_HOVER: COLORS.PRIMARY80,
    ERROR: COLORS.RED,
    HOVER: COLORS.PRIMARY10,
    CAUTION: COLORS.YELLOW,
    PENDING: COLORS.YELLOW,
    SUCCESS: COLORS.GREEN,
    SUCCESS_BACKGROUND: COLORS.GREEN_OPACITY10,
    ARCHIVE: COLORS.BLACK50,
    ARCHIVE_BACKGROUND: COLORS.BLACK10,
    DIVIDER: COLORS.PRIMARY15,
    SHADOW: COLORS.BLACK100_OPACITY10,
    ORANGE: COLORS.ORANGE,
    LIGHT_ORANGE: COLORS.LIGHT_ORANGE,
    TERTIARY: COLORS.TERTIARY100,
    DARK_VADER: COLORS.BLACK70,
    INCOMPLETE: COLORS.BLACK30,
    PURPLE_GRAY: COLORS.SECONDARY40,
  },
  TEXT_COLOR: {
    WHITE: COLORS.WHITE,
    DEFAULT: COLORS.BLACK90,
    SUBTITLE: COLORS.BLACK50,
    ACTIVE: COLORS.BLACK30,
    HINT: COLORS.BLACK50,
    DISABLED: COLORS.BLACK20,
    SELECTED: COLORS.PRIMARY100,
    SUCCESS: COLORS.GREEN,
    ERROR: COLORS.RED,
    LIGHT_GRAY: COLORS.BLACK100_OPACITY30,
  },
  ICON_COLORS: {
    ACTIVE: COLORS.SECONDARY20,
    DEFAULT: COLORS.SECONDARY30,
    SELECTED: COLORS.PRIMARY100,
    BORDER: COLORS.PRIMARY90,
  },
  SURFACE_STYLES: {
    ACTIVE: {
      border: `solid 1px ${COLORS.SECONDARY15}`,
      borderRadius: '4px',
    },
    TRANSPARENT: {
      BORDER: `1px solid ${COLORS.TRANSPARENT}`,
    },
  },
  WIDTH: {
    MAIN_CONTENT: '720px',
  },
};
