import { isEmpty, isFunction, isPositiveInteger, isUndefined, isValidJSON } from '@ftbpro/mm-admin-core-utils';
import { ValidationField } from './types';

export const validResult = <T>(value: T) => ({ value, error: null });
export const invalidResult = <T>(value: T, error: string) => ({ value, error });

const validateRegex = (regex: RegExp) => (value: string) => regex.test(value);

export const createValidator = <T>(predicate: (value: T) => boolean, errorMessage: string) => (value: T) => {
  return predicate(value) ? validResult(value) : invalidResult(value, errorMessage);
};
export const createRegExValidator = (regex: RegExp, errorMessage: string) => (value: string) => {
  return validateRegex(regex)(value) ? validResult(value) : invalidResult(value, errorMessage);
};

const mandatoryFieldValidator = (value: string) => !isEmpty(value);
const oneOfTheFieldsValidator = (values: string[]) => values.some(mandatoryFieldValidator);
export const MANDATORY_FIELD = 'This field is mandatory';
export const SOME_FIELD = 'At least one of the fields is mandatory';

const VALID_EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const INVALID_EMAIL_FIELD = 'Invalid email address';

const VALID_HOSTNAME_REGEX = /^(([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9])$/i;
const INVALID_HOSTNAME_FIELD = 'You must fill a valid hostname';

const VALID_DATE_REGEX = /^(?:[1][9][0-9][0-9]|[2][0][012][0-9])[-](?:[0][1-9]|[1][012])[-](?:[0][1-9]|[12][0-9]|[3][01])$/i;
const INVALID_DATE_FIELD = 'You must enter a valid date';

const INVALID_POSITIVE_INTEGER_FIELD = 'You must fill a valid positive integer';

const INVALID_JSON_FIELD = 'You must enter a valid JSON';

export const VALID_URL_REGEX = new RegExp('^(https?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+;\\[\\]\\{\\}]*)*' // port and path
  + '(\\?[;&a-z$\\d%_.~+=-\\[\\]\\{\\}]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
const INVALID_URL_FIELD = 'You must fill a valid URL';

export const COMMON_VALIDATORS = {
  mandatory: createValidator(mandatoryFieldValidator, MANDATORY_FIELD),
  some: createValidator(oneOfTheFieldsValidator, SOME_FIELD),
  validEmail: createRegExValidator(VALID_EMAIL_REGEX, INVALID_EMAIL_FIELD),
  validHostname: createRegExValidator(VALID_HOSTNAME_REGEX, INVALID_HOSTNAME_FIELD),
  validURL: createRegExValidator(VALID_URL_REGEX, INVALID_URL_FIELD),
  validPositiveInteger: createValidator(isPositiveInteger, INVALID_POSITIVE_INTEGER_FIELD),
  validJSON: createValidator(isValidJSON, INVALID_JSON_FIELD),
  validDate: createRegExValidator(VALID_DATE_REGEX, INVALID_DATE_FIELD),
};

export const isValidatedField = ({ error }: ValidationField<unknown>) => !isUndefined(error) && isEmpty(error);
export const isValidationField = (field: unknown): field is ValidationField<unknown> => typeof field === 'object'
  && Object.prototype.hasOwnProperty.call(field, 'value')
  && Object.prototype.hasOwnProperty.call(field, 'error');

export const formatValueAsValidationField = <T>(value: T, initialErrorValue?: string | null) => {
  return {
    value: isUndefined(value) ? '' : value,
    error: initialErrorValue,
  } as ValidationField<T>;
};
export const getValueFromValidationField = <T>({ value }: ValidationField<T>) => value;

export const getValuesFromValidatedObject = <T = any>(object: Record<string, unknown>) => {
  return Object.entries(object).reduce((acc, [key, currentValue]) => {
    return {
      ...acc,
      [key]: isValidationField(currentValue) ? currentValue.value : currentValue,
    };
  }, {}) as T;
};

type ExcludeFunction = (key: string) => boolean;
type ExcludeFunctionOrArray = ExcludeFunction | string[];

const isExcludeFunction = (value: ExcludeFunctionOrArray): value is ExcludeFunction => {
  return isFunction(value);
};

const shouldNotFormatField = (key: string, value: unknown, exclude?: ExcludeFunctionOrArray) => {
  let shouldBeExcluded = false;
  if (exclude) {
    shouldBeExcluded = isExcludeFunction(exclude) ? exclude(key) : exclude.includes(key);
  }

  return shouldBeExcluded || isValidationField(value);
};

export const formatObjectValuesAsValidationFields = <T = any>(object: Record<string, unknown>, exclude?: ExcludeFunctionOrArray) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: shouldNotFormatField(key, value, exclude) ? value : formatValueAsValidationField(value, null),
    };
  }, {}) as T;
};

export const isObjectPropertiesValidated = (objectToValidate: Record<string, unknown>) => {
  return Object.entries(objectToValidate).every(([, currentValue]) => {
    return isValidationField(currentValue) ? isValidatedField(currentValue) : true;
  });
};
