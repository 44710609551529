import React from 'react';
import { css } from '@emotion/core';

import { linkableImageSettingsStyles } from './linkableImageSettings.styles';
import { LinkableImageSettingsTitle } from './linkableImageSettingsComponents/LinkableImageSettingsTitle';
import { LinkableImageSettingsBody } from './linkableImageSettingsComponents/LinkableImageSettingsBody';

export const LinkableImageSettings = ({
 checked,
 onToggleChange,
 onLinkableImageDataChange,
 linkableImageSettings,
 linkableImageLinkError = '',
}) => {
  const { targetLink, shouldOpenNewTab, shouldNoFollow } = linkableImageSettings;

  const changeLinkableImageSettings = ({ link, open, follow }) => onLinkableImageDataChange({
    targetLink: link,
    shouldOpenNewTab: open,
    shouldNoFollow: follow,
  });

  return (
    <div css={css(linkableImageSettingsStyles.wrapper)}>
      <LinkableImageSettingsTitle onToggleChange={onToggleChange} checked={checked} />
      <LinkableImageSettingsBody
        checked={checked}
        linkableImageLinkError={linkableImageLinkError}
        shouldNoFollow={shouldNoFollow}
        targetLink={targetLink}
        shouldOpenNewTab={shouldOpenNewTab}
        changeLinkableImageSettings={changeLinkableImageSettings}
      />
    </div>
  );
};
