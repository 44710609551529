import { getEditorState } from '../editor.selector';
import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';

function getCoverState(state) {
  return getEditorState(state).cover;
}

export const coverImageSelector = {
  cover(state) {
    return getCoverState(state);
  },
  image(state) {
    return getCoverState(state).image;
  },
  media(state) {
    return getCoverState(state).media;
  },
  mediaType(state) {
    return (coverImageSelector.media(state) && coverImageSelector.media(state).type)
      || BLOCK_TYPES.IMAGE;
  },
  coverImageUrl(state) {
    const { image } = getCoverState(state);
    return image ? image.url : null;
  },
  fullCoverImageUrl(state) {
    const { image } = getCoverState(state);
    return image ? image.fullImageUrl : null;
  },
  isCoverImageValid(state) {
    return !!this.coverImageUrl(state);
  },
  coverImageCaption(state) {
    const { image } = getCoverState(state);
    return image ? image.caption : null;
  },
  isLoadingCoverImage(state) {
    return getCoverState(state).isLoading;
  },
};
