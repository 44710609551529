import { SITE_VARIABLES } from '../../../../core/styles/variables';
import { flexColumn, flexRow, flexRowBetweenCenter, flexRowCenter } from '../../../../core/styles/flex';

export const linkableImageSettingsStyles = {
  wrapper: {
    ...flexColumn,
    padding: `${SITE_VARIABLES.PADDING.SMALL} 0 ${SITE_VARIABLES.PADDING.MEDIUM_SMALL} 0}`,
  },
  titleContainer: {
    ...flexRowBetweenCenter,
  },
  titleLabel: {
    ...flexRowCenter,
  },
  titleTooltip: {
    ...flexRow,
    paddingLeft: SITE_VARIABLES.PADDING.TINY,
  },
  inputItem: {
    padding: `${SITE_VARIABLES.PADDING.SMALL} 0}`,
  },
  checkboxItem: {
    padding: `${SITE_VARIABLES.PADDING.TINY} 0}`,
  },
  toggle: {
    marginLeft: SITE_VARIABLES.MARGIN.TINY,
  },
  furtherInfo: {
    color: SITE_VARIABLES.COLORS.ARCHIVE,
  },
};
