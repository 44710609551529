import {
  COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD,
  DRAFT_OPENED_FOR_EDITING_ECHO_DASHBOARD,
  PREVIEW_PAGE_OPEN_FROM_DASHBOARD,
  SET_NEW_DATE_RANGE,
  STATUS_FILTER_VALUE_CHANGE,
  TOGGLE_POST_VISIBILITY_SUCCESS,
} from '../echoDashboard/echoDashboard.actions';
import {
  TOGGLE_SCHEDULE_POST_MODAL_SHORTCUT,
  TOGGLE_AUTHORS_MODAL,
  TOGGLE_COMMENTS_MODAL,
  TOGGLE_SCHEDULE_POST_MODAL_THROUGH_CLOSE_CLICK,
  TOGGLE_SCHEDULE_POST_MODAL_THROUGH_FEEDS,
} from '../echoModals/echoModals.actions';
import {
  ADD_COMMERCIAL_TAG,
  CHANGE_DFP_CATEGORY,
  DRAFT_OPENED_FOR_EDITING_THROUGH_CUSTOMIZATION_SCREEN,
  HIDE_OWNER,
  PIN_TO_TOP,
  PREVIEW_PAGE_OPEN,
  SET_NO_INDEX,
  TOGGLE_SPONSORED_CONTENT,
  POST_PATH_EDITED, COPY_URL_TO_CLIPBOARD, POST_PUBLISH_SUCCESS, ADD_SUGGESTED_CHANNEL_TO_POST,
} from '../echoCustomization/echoCustomization.actions';
import { CMS_PAGES } from '../../../constants/cms.constants';

export const EVENT_TYPES = {
  SCHEDULE_PUBLISH_NOW: 'scheduling - publish now',
  SCHEDULE_FOR_TIME_IN_THE_FUTURE: 'published time scheduled for later',
  UNFEATURE_POST_FROM_TO_BE_PUBLISHED_DESTINATION: 'type destination click - close',
  UNFEATURE_POST_FROM_PUBLISHED_DESINATION: 'unfeature - Remove',
  STATE_FILTER_CLICK: 'status filter',
  DATE_RANGE_FILTER_CLICK: 'date range filter click',
  COMMENT_CLICK: 'comment click',
  ARCHIVE_POST_CLICK: 'archive clicked from table',
  HOVER_EDIT_CLICK: 'edit clicked from table',
  DELETE_CLICKED_FROM_TABLE: 'deleted clicked from table',
  DELETE_CLICKED_FROM_ARTICLE: 'deleted clicked from article',
  EDIT_CLICK_FROM_ARTICLE: 'edit click from article',
  OPEN_SCHEDULE_POST_MODAL_SHORTCUT: 'scheduling shortcut clicked',
  SHCEDULE_SHORTCUT_CLOSE: 'scheduling shortcut - close',
  SUGGESTED_DESTINATION_CLICKED: 'suggested destination clicked',
  PIN_TO_TOP_CERTAIN_DESTINATION: 'pin to top clicked',
  SCHEDULE_DESTINATION_THROUGH_FEED: 'type destination click - schedule',
  AUTHOR_HIDE: 'author - hide',
  ADDITIONAL_AUTHOR_ADDED: 'author add',
  DFP_CATEGORY_CHANGED: 'dfp category click',
  NO_INDEX_ARTICLE_ON_GOOGLE_SET: 'no index clicked',
  COMMERCIAL_TAG_ADDED: 'Commercials tags click',
  SPONSORED_CONTENT_TURNED_ON: 'sponsored article clicked',
  URL_EDITED: 'url changed',
  PUBLISHER_TABLE_PAGEVIEW: 'publisher table pageview',
  PUBLISHER_CUSTOMIZATION_PAGEVIEW: 'publisher customization pageview',
  COMMENT_ADDED_FROM_TABLE: 'comment added from table',
  COMMENT_ADDED_FROM_ARTICLE: 'comment added from article',
  PREVIEW_CLICK_FROM_ARTICLE: 'preview click from article',
  PREVIEW_CLICKED_FROM_TABLE: 'preview clicked from table',
  COPY_URL_CLICKED_FROM_TABLE: 'copy url clicked from table',
  COPY_URL_CLOCKED_FROM_ARTICLE: 'copy url clicked from article',
  TIME_FILTER_CLICKED: 'time filter clicked',
  UPDATE_ARTICLE_CLICKED: 'update article clicked',
  ARTICLE_PUBLISHED: 'article published',
};

export const MAP_ACTIONS_TO_EVENT_TYPES = {
  [STATUS_FILTER_VALUE_CHANGE]: EVENT_TYPES.STATE_FILTER_CLICK,
  [TOGGLE_COMMENTS_MODAL]: EVENT_TYPES.COMMENT_CLICK,
  [TOGGLE_POST_VISIBILITY_SUCCESS]: EVENT_TYPES.ARCHIVE_POST_CLICK,
  [DRAFT_OPENED_FOR_EDITING_ECHO_DASHBOARD]: EVENT_TYPES.HOVER_EDIT_CLICK,
  [DRAFT_OPENED_FOR_EDITING_THROUGH_CUSTOMIZATION_SCREEN]: EVENT_TYPES.EDIT_CLICK_FROM_ARTICLE,
  [TOGGLE_SCHEDULE_POST_MODAL_SHORTCUT]: EVENT_TYPES.OPEN_SCHEDULE_POST_MODAL_SHORTCUT,
  [TOGGLE_SCHEDULE_POST_MODAL_THROUGH_CLOSE_CLICK]: EVENT_TYPES.SHCEDULE_SHORTCUT_CLOSE,
  [PIN_TO_TOP]: EVENT_TYPES.PIN_TO_TOP_CERTAIN_DESTINATION,
  [TOGGLE_SCHEDULE_POST_MODAL_THROUGH_FEEDS]: EVENT_TYPES.SCHEDULE_DESTINATION_THROUGH_FEED,
  [HIDE_OWNER]: EVENT_TYPES.AUTHOR_HIDE,
  [TOGGLE_AUTHORS_MODAL]: EVENT_TYPES.ADDITIONAL_AUTHOR_ADDED,
  [CHANGE_DFP_CATEGORY]: EVENT_TYPES.DFP_CATEGORY_CHANGED,
  [SET_NO_INDEX]: EVENT_TYPES.NO_INDEX_ARTICLE_ON_GOOGLE_SET,
  [ADD_COMMERCIAL_TAG]: EVENT_TYPES.COMMERCIAL_TAG_ADDED,
  [TOGGLE_SPONSORED_CONTENT]: EVENT_TYPES.SPONSORED_CONTENT_TURNED_ON,
  [POST_PATH_EDITED]: EVENT_TYPES.URL_EDITED,
  [PREVIEW_PAGE_OPEN]: EVENT_TYPES.PREVIEW_CLICK_FROM_ARTICLE,
  [CMS_PAGES.ECHO_DASHBOARD]: EVENT_TYPES.PUBLISHER_TABLE_PAGEVIEW,
  [CMS_PAGES.ECHO_CUSTOMIZATION]: EVENT_TYPES.PUBLISHER_CUSTOMIZATION_PAGEVIEW,
  [PREVIEW_PAGE_OPEN_FROM_DASHBOARD]: EVENT_TYPES.PREVIEW_CLICKED_FROM_TABLE,
  [SET_NEW_DATE_RANGE]: EVENT_TYPES.TIME_FILTER_CLICKED,
  [COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD]: EVENT_TYPES.COPY_URL_CLICKED_FROM_TABLE,
  [COPY_URL_TO_CLIPBOARD]: EVENT_TYPES.COPY_URL_CLOCKED_FROM_ARTICLE,
  [POST_PUBLISH_SUCCESS]: EVENT_TYPES.UPDATE_ARTICLE_CLICKED,
  [ADD_SUGGESTED_CHANNEL_TO_POST]: EVENT_TYPES.SUGGESTED_DESTINATION_CLICKED,
};

export const ECHO = 'Echo';
