import { Dispatch } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from 'core/store';
import { getNotificationCreator, NotificationDataWithID, NotificationData } from '../globalNotifications.utils';

export const PUSH_GLOBAL_NOTIFICATION = '[global notifications center] push global notification';
export const DISMISS_GLOBAL_NOTIFICATION = '[global notifications center] dismiss global notification';

export const pushGlobalNotificationActionCreator = (notification: NotificationData) => {
  const globalNotificationWithId: NotificationDataWithID = getNotificationCreator().createGlobalNotification(notification);
  return ({
    type: PUSH_GLOBAL_NOTIFICATION,
    payload: globalNotificationWithId,
  });
};

// TODO - remove all
export const pushGlobalNotification = (notification: NotificationData): AppThunk => (dispatch: AppDispatch) => {
  const globalNotificationWithId: NotificationDataWithID = getNotificationCreator().createGlobalNotification(notification);
  return dispatch({
    type: PUSH_GLOBAL_NOTIFICATION,
    payload: globalNotificationWithId,
  });
};

export const dismissGlobalNotification = (globalNotificationId: number) => (dispatch:Dispatch) => {
  dispatch({
    type: DISMISS_GLOBAL_NOTIFICATION,
    payload: globalNotificationId,
  });
};
