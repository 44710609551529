import { SITE_VARIABLES } from 'core/styles/variables';
import { flexRowAlignCenter, flexColumn } from 'core/styles/flex';
import { Types } from '@ftbpro/mm-admin-ui-components';

export interface DateRangeSetupStyles {
  builderDateContainer: Types.StylesObject
  dateListContainer: Types.StylesObject
  dateContainer: Types.StylesObject
  dateDropdown: Types.StylesObject
  dateTextButton: Types.StylesObject
}

export const getStylesObject = (renderAsCustomFilter: boolean): DateRangeSetupStyles => {
  const containerExtraStyle = renderAsCustomFilter ? { backgroundColor: 'transparent' } : {};
  return {
    builderDateContainer: {
      ...flexRowAlignCenter,
      ...containerExtraStyle,
      padding: '0',
      gap: SITE_VARIABLES.MARGIN.BIG,
    },
    dateListContainer: {
      ...flexColumn,
      gap: SITE_VARIABLES.MARGIN.MEDIUM,
      padding: '0',
      paddingRight: SITE_VARIABLES.PADDING.BIG,
    },
    dateContainer: {
      padding: '0',
    },
    dateDropdown: {
      contentWidth: 'max-content',
    },
    dateTextButton: {
      color: SITE_VARIABLES.COLORS.BLACK,
      disabledColor: SITE_VARIABLES.ICON_COLORS.SELECTED,
    },
  };
};
