import { StatsName } from './dashboardPage.constants';

export const DASHBOARD_TITLES = {
  HOMEPAGE: 'Voltax Homepage Dashboard',
  REVENUE_OVERVIEW: 'Total Revenue Overview',
  MM_SSP_REVENUE: 'MM SSP Revenue',
  VOLTAX_PLAYER_REVENUE: 'Voltax Player Revenue',
  REVENUE_OVERVIEW_SUMMARY: 'Total Revenue Summary',
  UNIFIED_PLAYER_REVENUE: 'Unified Player Revenue',
};

export const FILTER_TITLES = {
  DOMAIN: 'Site',
  COUNTRY: 'Country',
  PLACEMENT: 'Placement',
  DEVICE: 'Device',
  OS: 'OS',
  ACTIVITY: 'Activity',
  PLACEMENT_ID: 'Placement ID',
  DEMAND_SOURCE: 'Demand Source',
  PLAYER_NAME: 'Player Name',
  DEMAND_OWNER: 'Demand Owner',
  BROWSER: 'Browser',
  DEMAND_TYPE: 'Demand Type',
};

export const APPLY_BUTTON = 'Apply';
export const RESET_BUTTON = 'Reset';
export const CHART_ERROR_TEXT = 'Something went wrong';
export const CHART_NO_DATA_TEXT = 'No Results';
export const CHART_ERROR_NOTIFICATION_TEXT = 'Something went wrong, please try again later';

export const HOMEPAGE_STATS_INFO = {
  [StatsName.NetRevnue]: 'Total net revenue earned by all activities',
};

export const REVENUE_OVERVIEW_STATS_INFO = {
  [StatsName.NetRevnue]: 'Total net revenue earned by all activities',
  [StatsName.MmMarketplaceRevenue]: 'Revenue earned by Minute Media\'s monetization',
  [StatsName.PublisherDemandRevenue]: 'Net revenue earned via Publisher\'s External Demand Activity',
  [StatsName.EstimatedNetPayout]: 'Net Payout for current month is only Estimated. Net Payout = MM Marketplace Revenue - Publisher Serving Fees (charged on Pubs External Demand Revenue)',
};

export const MM_SSP_REVENUE_STATS_INFO = {
  [StatsName.NetRevnue]: 'Total Revenue earned via Minute Media\'s SSP activity only',
  [StatsName.Impressions]: 'In the context of video, this metric measures the number of video ads recorded as "viewed" or showing the first frame of the video ad, in following MRC’s video ad measurement guidelines.',
};

export const VOLTAX_PLAYER_REVENUE_STATS_INFO = {
  [StatsName.NetRevnue]: 'Net revenue earned via the MM SSP Activity. This includes MM Marketplace revenue & Net Publisher External Demand Revenue via the MM SSP.',
  [StatsName.MmMarketplaceRevenue]: 'Net revenue earned via Minute Media\'s monetization on the Voltax Video Player',
  [StatsName.PublisherDemandRevenue]: 'Net revenue earned by the publisher\'s own demand on the Voltax Video Player',
  [StatsName.PlayerLoads]: 'Number of times player is loaded on the site (recorded only for Voltax players)',
  [StatsName.Impressions]: 'In the context of video, this metric measures the number of video ads recorded as "viewed" or showing the first frame of the video ad, in following MRC’s video ad measurement guidelines.',
};

export const REVENUE_OVERVIEW_SUMMARY_STATS_INFO = {
  [StatsName.NetRevnue]: 'Total net revenue earned by all activities',
  [StatsName.STNMarketplaceRevenue]: 'Revenue earned by STN\'s monetization',
  [StatsName.PublisherDemandRevenue]: 'Net revenue earned by Publisher\'s demand activity',
  [StatsName.EstimatedNetPayout]: 'Net Payouts displayed here are only estimated. Net Payouts = STN Marketplace Revenue - Publisher Demand Fees (charged on publisher demand revenue).',
};

export const UNIFIED_PLAYER_REVENUE_STATS_INFO = {
  [StatsName.NetRevnue]: 'Total net revenue earned by all activities',
  [StatsName.STNMarketplaceRevenue]: 'Revenue earned by STN\'s monetization',
  [StatsName.PublisherDemandRevenue]: 'Net revenue earned by Publisher\'s demand activity',
  [StatsName.PlayerLoads]: 'Number of times a monetizable player is loaded on the site',
  [StatsName.Impressions]: 'Number of times an ad is displayed on Publisher\'s sites',
};

export const DISCLAIMER = 'Disclaimer:';
export const DISCLAIMER_ERROR = 'We had a problem displaying the disclaimer, please refresh the page. If the error continues, please reach out to support.';
export const RESET_FILTERS_BUTTON = 'Reset Filters';
