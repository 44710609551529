import { changeDocumentTitle } from '../../../core/utils/utils';
import { MAP_CMS_PAGES_TO_PAGE_TITLE } from '../constants/cms.constants';

export const NOTIFY_NEW_PAGE_WAS_OPENED = '[CMS] new page was opened';

export const notifyNewPageOpen = cmsPage => dispatch => {
  changeDocumentTitle(MAP_CMS_PAGES_TO_PAGE_TITLE[cmsPage]);
  dispatch({
    type: NOTIFY_NEW_PAGE_WAS_OPENED,
    payload: cmsPage,
  });
};
