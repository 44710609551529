export const HTML_PASTE_DATA_KEY = 'text/html';
export const TEXT_PASTE_DATA_KEY = 'text';
export const WHATSAPP_DATA_KEY = 'application/whatsapp';
export const SLATE_FRAGMENT = 'application/x-slate-fragment';

export const SLATE_NODE_TYPES = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  LINE: 'line',
  PARAGRAPH: 'paragraph',
  BULLETED_LIST: 'bulleted-list',
  HEADING_TWO: 'heading-two',
  HEADING_THREE: 'heading-three',
  HEADING_FOUR: 'heading-four',
  LIST_ITEM: 'list-item',
  NUMBERED_LIST: 'numbered-list',
  LINK: 'link',
};

export const WRAPPING_SLATE_NODES = [
  SLATE_NODE_TYPES.PARAGRAPH,
  SLATE_NODE_TYPES.BULLETED_LIST,
  SLATE_NODE_TYPES.NUMBERED_LIST,
  SLATE_NODE_TYPES.HEADING_TWO,
  SLATE_NODE_TYPES.HEADING_THREE,
  SLATE_NODE_TYPES.HEADING_FOUR,
];

export const BASIC_LINK_OBJECT = {
  children: [],
  type: SLATE_NODE_TYPES.LINK,
};

export const EMPTY_SLATE_VALUE = [
  {
    type: SLATE_NODE_TYPES.PARAGRAPH,
    children: [{ text: '' }],
  },
];
