export const ELEMENT_PANEL_CONTEXT = {
  EDITING_COVER_IMAGE: 'editingCoverImage',
  EDITING_BLOCK: 'editingBlock',
};

export const MEDIA = 'Media';
export const USER_UPLOAD_PROVIDER = 'UserUpload';
export const UPLOAD_IMAGE_TOOLTIP = 'Only Media Admin can upload images';

export const CSS_CONSTANTS = {
  ELEMENT_PANEL_TOPBAR_HEIGHT: '46px',
  ELEMENT_PANEL_TOPBAR_SCROLL_BUTTON_WIDTH: '24px',
  ELEMENT_PANEL_TOPBAR_SCROLL_BUTTON_HEIGHT: '24px',
  ELEMENT_PANEL_TOPBAR_SCROLL_BUTTON_WRAP_WIDTH: '50px',
};

export const ICON_DIMENSIONS = { HEIGHT: '10px', WIDTH: '10px' };
