import React, { useEffect } from 'react';
import { useStyles } from '@ftbpro/mm-admin-core-hooks';
import { AppcuesLaunchpadStyles, getStylesObject, injectGlobalOverideStyles } from './appcuesLaunchpad.styles';
import Icon from './icon-volty.svg';
import { VOLTAX_TUTORIALS } from './appcuesLaunchpad.constants';

export const AppcuesLaunchpad = () => {
  const styles = useStyles<AppcuesLaunchpadStyles>(getStylesObject);

  injectGlobalOverideStyles();

  useEffect(() => {
    window.Appcues?.loadLaunchpad?.('#appcues-launchpad', {
      position: 'left',
      header: `<h1>${VOLTAX_TUTORIALS}</h1>`,
      icon: Icon,
    });
  }, []);

  return (
    <div
      css={styles.appcuesLaunchpad}
      id="appcues-launchpad"
    />
);
};
