import { mmNetworkService } from 'core/network/mmServicesApiProvider';
import { EditorServiceDataProvider } from './editorServiceConfigsProvider';
import { MediaLibraryImagesNetworkService } from '../../../mediaLibrary/images/services/mediaLibraryImagesApiProvider';
import { IMAGN_IMAGES_PROVIDER } from '../plugins/shared/plugins.constants';

const LIMIT = 30;

export class ImagnServices {
  static async searchImages({ searchQuery, offset = 1, property }) {
    const configs = {
      method: 'GET',
    };
    const path = `/rest/search?terms=(${encodeURI(searchQuery)})&limit=${LIMIT}&offset=${offset}&format=json`;
    try {
      const res = await mmNetworkService.fetch(EditorServiceDataProvider.getImagnSearchEndpoint(path, property), configs);
      if (res) {
        return res;
      }
    } catch (e) {
      return e;
    }
    return null;
  }

  static async downloadImage(id, property) {
    try {
      const downloadPath = `/rest/download/?imageID=${id}`;
      return await mmNetworkService.imageFetch(EditorServiceDataProvider.getImagnSearchEndpoint(downloadPath, property))
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise(resolve => {
            reader.onloadend = async () => {
              const { imageUrl, signedUrl } = await MediaLibraryImagesNetworkService.copyImageByFile({
                provider: IMAGN_IMAGES_PROVIDER,
                property,
              });
              const uploadResponse = await MediaLibraryImagesNetworkService.uploadFile({
                file: {
                  imageSrc: reader.result,
                },
                url: signedUrl,
              });
              const uploadResObject = await uploadResponse.json();
              const { public_id: path, format: fileType } = uploadResObject;
              resolve({ uri: imageUrl, path, fileType });
            };
          });
        });
    } catch (e) {
      return e;
    }
  }
}
