import { RecommendationServiceApiProvider } from '../../../services/recommendation/recommendationServiceApiProvider';
import { copyToClipboard } from '../../../../../core/utils/utils';

export const SUGGEST_ARTICLES_REQUEST = '[EDITOR] make a request to load suggested posts';
export const SUGGEST_ARTICLES_SUCCESS = '[EDITOR] successfully loaded suggested posts';
export const SUGGEST_ARTICLES_FAILURE = '[EDITOR] failure while loading suggested posts';

export const SUGGEST_PAGES_REQUEST = '[EDITOR] make a request to load suggested pages';
export const SUGGEST_PAGES_SUCCESS = '[EDITOR] successfully loaded suggested pages';
export const SUGGEST_PAGES_FAILURE = '[EDITOR] failure while loading suggested pages';

export const SUGGEST_SEARCH_ARTICLES_REQUEST = '[EDITOR] make a request to load suggested search posts';
export const SUGGEST_SEARCH_ARTICLES_SUCCESS = '[EDITOR] successfully loaded suggested search posts';
export const SUGGEST_SEARCH_ARTICLES_FAILURE = '[EDITOR] failure while loading suggested search posts';

export const TOGGLE_RECOMMENDATION_WINDOW = '[EDITOR] toggle recommendation window';

export const CLEAR_RECOMMENDATION_LISTS = '[EDITOR] clear recommendation lists';

export const COPY_HYPERLINK_URL_TO_CLIPBOARD = '[EDITOR] copy hyperlink url to clipboard';

const ERROR_TEXT = 'Error occurred while processing request';

export const suggestArticles = (property, text, draftId) => async dispatch => {
  dispatch({
    type: SUGGEST_ARTICLES_REQUEST,
  });
  try {
    const suggestArticlesResponse = await RecommendationServiceApiProvider.getPostsRecommendation(property, text);
    const filteredSuggestedArticle = suggestArticlesResponse.posts.filter(item => item.id !== draftId);
    return dispatch({
      type: SUGGEST_ARTICLES_SUCCESS,
      payload: {
        suggestedArticles: filteredSuggestedArticle,
      },
    });
  } catch (error) {
    return dispatch({
      type: SUGGEST_ARTICLES_FAILURE,
      payload: {
        text: ERROR_TEXT,
        error,
      },
    });
  }
};

export const suggestPages = (property, text) => async dispatch => {
  dispatch({
    type: SUGGEST_PAGES_REQUEST,
  });
  try {
    const suggestPagesResponse = await RecommendationServiceApiProvider.getPagesRecommendation(property, text);
    return dispatch({
      type: SUGGEST_PAGES_SUCCESS,
      payload: {
        suggestedPages: suggestPagesResponse,
      },
    });
  } catch (error) {
    return dispatch({
      type: SUGGEST_PAGES_FAILURE,
      payload: {
        text: ERROR_TEXT,
        error,
      },
    });
  }
};

export const suggestSearchArticles = (property, text, draftId) => async dispatch => {
  dispatch({
    type: SUGGEST_SEARCH_ARTICLES_REQUEST,
  });
  try {
    const searchSuggestArticlesResponse = await RecommendationServiceApiProvider.getSearchPostsRecommendation(property, text);
    const filteredSearchSuggestedArticle = searchSuggestArticlesResponse.posts.filter(item => item.id !== draftId);
    return dispatch({
      type: SUGGEST_SEARCH_ARTICLES_SUCCESS,
      payload: {
        suggestedArticles: filteredSearchSuggestedArticle,
      },
    });
  } catch (error) {
    return dispatch({
      type: SUGGEST_SEARCH_ARTICLES_FAILURE,
      payload: {
        text: ERROR_TEXT,
        error,
      },
    });
  }
};

export const toggleRecommendationWindow = isOpen => dispatch => {
  dispatch({
    type: TOGGLE_RECOMMENDATION_WINDOW,
    payload: isOpen,
  });
};

export const clearRecommendationLists = () => dispatch => {
  dispatch({
    type: CLEAR_RECOMMENDATION_LISTS,
  });
};

export const copyHyperlinkUrlToClipboard = recommendationUrl => dispatch => {
  copyToClipboard(recommendationUrl);
  dispatch({
    type: COPY_HYPERLINK_URL_TO_CLIPBOARD,
    payload: recommendationUrl,
  });
};
