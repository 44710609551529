export enum MessageSource {
  BackgroundTasksService = 'bgTasksManager'
}

export enum MessageType {
  Config = 'config',
  Completed = 'completed',
  InProgress = 'inProgress'
}

export enum TaskTerminationReason {
  Completed = 'completed',
  Failed = 'failed',
  AbortedByUser = 'abortedByUser'
}
