import throttle from 'lodash.throttle';
import { PLATFORM_TITLE } from '../../app.contants';
import { DEBUG_QUERY_PARAM, getQueryParamValue } from './URL';

export function leadingZerosMaker(number, size) {
  return (`0${number}`).slice(-size);
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// TODO - move these 3 func to date.utils.js
export function dateHelper(date, formatOptions = {}) {
  // TODO rework showFullDate for more display options and add formatting to month (no only number but short/full month name)
  const { hoursFormat = 'meridiem', showOnlyTime, showNoYear, showNoTime } = formatOptions;
  const dateObj = new Date(Date.parse(date));
  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const monthName = monthNames[month];
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const meridiem = hours >= 12 ? 'PM' : 'AM';

  const formattedDay = `${leadingZerosMaker(day, 2)}`;
  const formattedMonth = `${leadingZerosMaker(month + 1, 2)}`;
  const formattedYear = `${(`${year}`).slice(-2)}`;
  const formattedHour = hoursFormat === 'meridiem' ? `${hours % 12 === 0 ? '12' : leadingZerosMaker(hours % 12, 2)}` : `${leadingZerosMaker(hours, 2)}`;
  const formattedMinutes = `${leadingZerosMaker(minutes, 2)}`;
  const formattedMeridiem = hoursFormat === 'meridiem' ? `${meridiem}` : '';

  if (showNoYear) {
    return `${formattedDay}/${formattedMonth} ${formattedHour}:${formattedMinutes} ${formattedMeridiem}`;
  }
  if (showOnlyTime) {
    return `${formattedHour}:${formattedMinutes} ${formattedMeridiem}`;
  }
  if (showNoTime) {
    return `${monthName} ${formattedDay}, ${year}`;
  }
  return `${formattedDay}/${formattedMonth}/${formattedYear} ${formattedHour}:${formattedMinutes} ${formattedMeridiem}`;
}

export function areSameDates(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

export function areSameYear(d1, d2) {
  return d1.getFullYear() === d2.getFullYear();
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

const LOCALES_MAP = {
  en: {
    value: 'en',
    text: 'English',
  },
  es: {
    value: 'es',
    text: 'Spanish',
  },
  it: {
    value: 'it',
    text: 'Italian',
  },
  de: {
    value: 'de',
    text: 'German',
  },
  fr: {
    value: 'fr',
    text: 'French',
  },
  tr: {
    value: 'tr',
    text: 'Turkish',
  },
  id: {
    value: 'id',
    text: 'Indonesian',
  },
  vn: {
    value: 'vn',
    text: 'Vietnamese',
  },
  th: {
    value: 'th',
    text: 'Thai',
  },
  hi: {
    value: 'hi',
    text: 'Hindi',
  },
  'pt-BR': {
    value: 'pt-BR',
    text: 'Portuguese',
  },
};

export function getLocalesObjects(locales) {
  return locales.map(locale => LOCALES_MAP[locale]);
}

export function getLocaleText(locale) {
  return LOCALES_MAP[locale].text;
}

export function copyTextToClipboard(text) {
  const input = document.createElement('input');
  input.type = 'text';
  input.value = text;
  document.body.append(input);
  input.select();
  document.execCommand('copy');
  input.remove();
}

export function getFirstOrDefault(array, defaultValue = null) {
  return array && array.length > 0 ? array[0] : defaultValue;
}

export function isEmpty(value) {
  return !value || value.length === 0;
}

export function isEmptyObject(value) {
  return value && Object.keys(value) && Object.keys(value).length === 0;
}

export function isUndefined(value) {
  return typeof value === 'undefined';
}

export function isFunction(value) {
  return typeof value === 'function';
}

export function isObject(value) {
  return typeof value === 'object';
}

export function isBoolean(value) {
  return typeof value === 'boolean';
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const isEqualArrays = (firstArr, secondArr) => {
  return firstArr.length === secondArr.length && firstArr.every((v, i) => v === secondArr[i]);
};

export const isEqualSortedArrays = (firstArray, secondArray) => {
  if (firstArray && secondArray && (firstArray.length === secondArray.length)) {
    return firstArray.every((value, index) => value === secondArray[index]);
  }
  return !firstArray && !secondArray;
};

export const numericalSort = arrayForSort => {
  return arrayForSort.sort((a, b) => {
    return a - b;
  });
};

export const isEqualUnsortedArrays = (firstArray, secondArray) => {
  if (firstArray && secondArray) {
    const firstSortArr = [...firstArray].sort();
    const secondSortArr = [...secondArray].sort();
    return isEqualSortedArrays(firstSortArr, secondSortArr);
  }
  return !firstArray && !secondArray;
};

const copyAttributes = (elemFrom, elemTo) => Array.from(elemFrom.attributes)
  .forEach(attr => elemTo.setAttribute(attr.name, attr.value));

function convertToExecutableScripts(scripts) {
  return scripts
    .map(script => {
      const executable = document.createElement('script');
      executable.innerHTML = script.innerHTML ? script.innerHTML : '';
      copyAttributes(script, executable);
      return executable;
    });
}

const isJS = script => (script.type === '' || script.type.indexOf('javascript') >= 0);

function findJavaScriptElements(wrapper) {
  const scripts = Array.from(wrapper.getElementsByTagName('script'));
  return scripts.filter(isJS);
}

function removeScripts(scripts) {
  scripts.forEach(script => script.remove());
}

function wrapHTML(html) {
  const tempWrapper = window.parent.document.createElement('div');
  tempWrapper.innerHTML = html;
  return tempWrapper;
}

export function generateInsertableHTML(html) {
  const wrapper = wrapHTML(html);
  const scripts = findJavaScriptElements(wrapper);
  // Remove injected scripts and leave only created executable scripts
  removeScripts(scripts);
  const elements = Array.from(wrapper.childNodes);
  return {
    elements,
    scripts: convertToExecutableScripts(scripts),
  };
}

export function capitalizeString(string) {
  return `${string.charAt(0)
    .toUpperCase()}${string.slice(1)}`;
}

export function capitalizeEveryLetterInAString(string) {
  return string.split(' ').map(word => capitalizeString(word)).join(' ');
}

export const PAGE_TITLES = {
  ALERTS: 'Alerts',
  ANALYTICS: 'Analytics',
  ECHO: 'Echo',
  POSTS: 'Posts',
  DISTRIBUTION: 'Distribution',
  DASHBOARD: 'Dashboard',
  TAGS: 'Tags',
  EDITOR: 'Editor',
  CRAFT: 'Craft',
  SEO: 'SEO',
  CRAFT_NEW_PAGE: 'New Page',
  CRAFT_EDIT_PAGE: 'Edit Page',
  CRAFT_PAGES: 'Pages',
  CRAFT_FEEDS: 'Feeds',
  CRAFT_SITE_LAYOUT_NAVIGATION: 'Navigation',
  CRAFT_AUTHORS: 'Authors',
  CRAFT_CATEGORIES: 'Categories',
  CRAFT_THEMES: 'Theming Tool',
  CRAFT_PAGE_BUILDER: 'Page Builder',
  CRAFT_FACTS: 'Facts Generator',
  VMS: 'VMS',
  REPORTS: 'Reports',
  ECHO_SCHEDULE: 'Schedule',
  ECHO_PUBLISHER: 'Publisher',
  MARKETPLACE: 'Marketplace',
  MONETIZATION: 'Monetization',
  PROMOTIONS: 'Promotions',
  SITE_POLICY: 'Site Policy',
  ADMINISTRATION: 'Administration',
  MEDIA_LIBRARY: 'Media Library',
  ORGANIZATION_SETTINGS: 'Organization Settings',
  ONBOARDING: 'Creators Onboarding',
  EXPERIMENTS: 'Experiments',
  ADS_TXT_MANAGER: 'Ads.txt Manager',
  CONTRIBUTORS_ANALYTICS: 'Contributors Analytics',
};

export function changeDocumentTitle(title) {
  if (title) {
    document.title = title;
  } else {
    document.title = PLATFORM_TITLE;
  }
}

export function throttleAction(action, wait, options) {
  // for options see: https://lodash.com/docs/4.17.4#throttle
  const throttled = throttle(
    (dispatch, actionArgs) => dispatch(action(...actionArgs)),
    wait,
    options,
  );

  // see: https://github.com/gaearon/redux-thunk
  const thunk = (...actionArgs) => dispatch => throttled(dispatch, actionArgs);

  // provide hook to _.throttle().cancel() to cancel any trailing invocations
  thunk.cancel = throttled.cancel;
  thunk.flush = throttled.flush;

  return thunk;
}

export function isDigitOnly(string) {
  return [...string].every(c => '0123456789'.includes(c));
}

export const isString = stringValue => typeof stringValue === 'string';

export function isValidURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(str);
}

export const isValidEmail = str => {
  const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return regex.test(str);
};

export function isPositiveInteger(str) {
  const n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
}

export function isValidJSON(value) {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

export function isFile(value) {
  return value instanceof File;
}

const PROPERTIES_MAPPER = {
  '12up_en_international_web': '12up',
  '12up_es_international_web': '12up',
  '90min_en_international_web': '90min',
  '90min_es_international_web': '90min',
  '90min_fr_international_web': '90min',
  '90min_it_international_web': '90min',
  '90min_pt-BR_international_web': '90min',
  '90min_tr_international_web': '90min',
  german_site_de_international_web: '90min',
  '90min_id_international_web': '90min_asia',
  '90min_th_international_web': '90min_asia',
  '90min_vn_international_web': '90min_asia',
  '90min_zh-CN_international_web': '90min_asia',
  ht_media_en_international_web: '90min_asia',
  ht_media_hi_international_web: '90min_asia',
  dbltap_en_international_web: 'dbltap',
  thebiglead_en_international_web: 'thebiglead',
  theduel_en_international_web: 'theduel',
  floor8_en_international_web: 'floor8',
  floor8_es_international_web: 'floor8',
};

export const transformCmsPropertyToVmsProperty = ({ property }) => {
  return PROPERTIES_MAPPER[property.slug] || property.slug;
};

export const turnSvgStringIntoImageSrc = svgString => `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;

const PIXEL_RATIO = 4;
const CANVAS_CONTEXT_DIMENSION = '2d';

export function cropImageSrc(imageData, imageRef) {
  const pixelCrop = imageData?.pixelCrop;
  const croppedImageSource = imageData?.croppedImageSource;
  const canvasRef = document.createElement('canvas');
  if (!pixelCrop || !canvasRef || !imageRef) {
    return croppedImageSource;
  }
  const canvas = canvasRef;
  const crop = pixelCrop;

  const scaleX = imageRef.naturalWidth / imageRef.width;
  const scaleY = imageRef.naturalHeight / imageRef.height;
  const ctx = canvas.getContext(CANVAS_CONTEXT_DIMENSION);

  canvas.width = crop.width * PIXEL_RATIO;
  canvas.height = crop.height * PIXEL_RATIO;

  ctx.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0);
  ctx.imageSmoothingEnabled = false;
  ctx.drawImage(
    imageRef,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return canvasRef.toDataURL('image/png');
}

export const isDebugMode = () => getQueryParamValue(DEBUG_QUERY_PARAM) === 'true';

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};
