import React, { Component, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';

import { YoutubeIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from './Plugin/Plugin';
import { EmbedBlock } from './shared/EmbedBlock';

import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';

import { overviewBlockText } from '../utils/blockList.utils';
import { urlInputPlaceholderGenerator, pluginAddButtonTextHandler } from '../utils/plugins.utils';

import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { MEDIA_ITEM_HEIGHT, MEDIA_ITEM_WIDTH } from './Plugin/plugin.styles';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const YOUTUBE_OEMBED_PREFIX = `https://www.youtube.com/oembed?${OEMBED_QUERY_PARAMS.URL}=`;
const YOUTUBE_OEMBED_ADDITIONAL_QUERY_PARAMS = `&${OEMBED_QUERY_PARAMS.MAX_WIDTH}=${MEDIA_ITEM_WIDTH}&${OEMBED_QUERY_PARAMS.MAX_HEIGHT}=${MEDIA_ITEM_HEIGHT}`;
const ENTER_YOUTUBE_PLACEHOLDER = urlInputPlaceholderGenerator('YouTube');
export const EMBED_BROKEN_URL_TEXT = 'The embed url you are using is not from the Youtube. Please paste a correct one.';

// Element-Panel Top Bar Button
export const YoutubeEmbedTopBarIcon = props => {
  return (
    <Icon icon={YoutubeIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class YoutubeEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.YOUTUBE;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      youtubeData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.YOUTUBE;
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setVideoUrl(url);
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { youtubeData } = this.state;
    onAdd(youtubeData);
  };

  onUrlInputKeyDown = e => {
    const { youtubeData } = this.state;
    if (isEnterPressed(e) && youtubeData) {
      this.onAdd(youtubeData);
    }
  };

  setVideoUrl = url => {
    this.setState({ url }, this.getUrlOembedData(url));
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    if (this.isValidYoutubeUrl(url)) {
      const oEmbedEndPoint = YOUTUBE_OEMBED_PREFIX + encodeURI(url) + YOUTUBE_OEMBED_ADDITIONAL_QUERY_PARAMS;
      getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.YOUTUBE)
        .then(youtubeData => {
          finishLoading();
          this.setState({ youtubeData });
        })
        .catch(() => {
          finishLoading();
          this.setState({ youtubeData: null });
        });
    } else {
      finishLoading();
      this.setState({ youtubeData: null });
    }
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, youtubeData } = this.state;
    if (url === '' || isLoading) {
      return null;
    }
    return youtubeData && this.isValidYoutubeUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: youtubeData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_BROKEN_URL_TEXT} />;
  };

  clearInput = () => {
    this.setState({ url: '' });
  };

  isValidYoutubeUrl = url => {
    return url.includes('youtube') || url.includes('youtu.be');
  };

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { youtubeData, url } = this.state;
    const isAddDisabled = youtubeData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_YOUTUBE_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const YoutubeBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  const isIframeInserted = () => {
    return blockRef.current && blockRef.current.querySelector('iframe');
  };

  useEffect(() => {
    if (isIframeInserted()) {
      blockRef.current.querySelector('iframe').addEventListener('load', () => {
        setLoading(false);
      });
    }
  }, []);

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function YoutubeEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <YoutubeEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const youtubeEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<YoutubeEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<YoutubeEmbedPanelComponent {...props} />),
  getPluginBlock: props => <YoutubeBlock {...props} />,
  getPluginOverviewBlock: props => (<YoutubeEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.YOUTUBE,
};
