import { getLocalStorageData } from '../../../../core/utils/storage.utils';
import { FILTER_KEYS } from '../components/imagesDashboard/imagesDashboard.constants';
import { IMAGE_DASHBOARD_UPLOAD_IMAGE_TEXTS } from '../components/modals/modal.constants';

const TAGS_ARRAY_SEPARATOR = ',';
export const IMAGE_DASHBOARD_MAX_MB_SIZE = 5;
export const IMAGE_DASHBOARD_FORMATS = ['.JPG', '.PNG', '.JPEG'];

export const getValueFromLocalStorage = key => {
  const value = getLocalStorageData(`mediaLibrary-${key}`);
  if (value && key === FILTER_KEYS.TAGS) {
    const tagsArray = value.split(TAGS_ARRAY_SEPARATOR);
    return tagsArray.length ? tagsArray : [];
  }
  if (value && (key === FILTER_KEYS.DATE_FROM || key === FILTER_KEYS.DATE_TO)) {
    return new Date(value);
  }
  return value || '';
};

export const getMBSize = (bytes, decimals = 2) => {
  if (bytes === 0) return 0;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  return parseFloat((bytes / (k ** 2)).toFixed(dm));
};

export function getFileExtension(file) {
  return file.name.split('.').pop();
}

export const isImageFormatSupported = selectedFile => {
  const ext = getFileExtension(selectedFile);
  return IMAGE_DASHBOARD_FORMATS.some(value => value.includes(ext.toUpperCase()));
};

export const generateImageInfoBody = ({ imageInfo, currentUser }) => {
  const { alt, audit, tags, caption, credit, description } = imageInfo;
  const { createdAt, height, provider, publicId, size, status, title, updateAt, uploadedBy, url, width } = description;
  return JSON.stringify({
    title,
    provider,
    url,
    status,
    caption,
    createdAt,
    updateAt,
    tags,
    uploadedBy,
    approvedBy: currentUser,
    altText: alt,
    credit,
    auditTrail: audit,
    height,
    width,
    size,
    publicId,
  });
};

export const getImageData = imageFile => {
  const { imageData } = imageFile;
  const { file } = imageData;
  if (!isImageFormatSupported(file)) {
    return {
      isValid: false,
      message: IMAGE_DASHBOARD_UPLOAD_IMAGE_TEXTS.ERROR_TYPE,
    };
  }
  if (getMBSize(file.size) > IMAGE_DASHBOARD_MAX_MB_SIZE) {
    return {
      isValid: false,
      message: IMAGE_DASHBOARD_UPLOAD_IMAGE_TEXTS.ERROR_SIZE,
    };
  }
  return {
    isValid: true,
    message: '',
  };
};

export const truncateImageTitle = (str, n = 45) => ((str.length > n)
  ? `${str.substr(0, n - 1)}...` : str);
