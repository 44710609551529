const BASE_ROUTE = '/administration';
const USERS = '/users';
const AUDIT = '/audit';
const HQ_USERS = '/hq-users';
const VOLTAX_USERS = '/voltax-users';
export const NEW = '/new';
const VOLTAX_ROLES = '/voltax-roles';
const HQ_ROLES = '/hq-roles';
const VOLTAX_PRODUCTS = '/voltax-products';
const HQ_PRODUCTS = '/hq-products';
const VOLTAX_AUDIT = '/voltax-audit';
const HQ_AUDIT = '/hq-audit';

export const ADMINISTRATION_ROUTES = {
  BASE: BASE_ROUTE,
  USERS: `${BASE_ROUTE}${USERS}`,
  NEW_USER: `${BASE_ROUTE}${USERS}${NEW}`,
  AUDIT: `${BASE_ROUTE}${AUDIT}`,
  HQ_USERS: `${BASE_ROUTE}${HQ_USERS}`,
  NEW_HQ_USER: `${BASE_ROUTE}${HQ_USERS}${NEW}`,
  VOLTAX_USERS: `${BASE_ROUTE}${VOLTAX_USERS}`,
  VOLTAX_ROLES: `${BASE_ROUTE}${VOLTAX_ROLES}`,
  HQ_ROLES: `${BASE_ROUTE}${HQ_ROLES}`,
  NEW_HQ_ROLE: `${BASE_ROUTE}${HQ_ROLES}${NEW}`,
  VOLTAX_PRODUCT_EDIT: `${BASE_ROUTE}${VOLTAX_PRODUCTS}${NEW}`,
  HQ_PRODUCT_EDIT: `${BASE_ROUTE}${HQ_PRODUCTS}${NEW}`,
  VOLTAX_AUDIT: `${BASE_ROUTE}${VOLTAX_AUDIT}`,
  HQ_AUDIT: `${BASE_ROUTE}${HQ_AUDIT}`,
};

export const ADMINISTRATION = 'administration';

export const SAVE_BUTTON_TEXT = 'Save';

export const ADD_PROPERTIES_TO_SELECTED_ROLE = 'Please add properties to selected roles';

export const ADD_USERS_TO_SELECTED_ROLE = 'You must add users to selected roles';
