import { TabKey } from './store/types';

export const SITE_STATS_TITLE = 'Site Stats';
export const USER_STATS_TITLE = 'My Stats';
export const FAILED_POST_STATS = 'We had a problem displaying your data';

export const SITE_STATS_TEXT = {
  DISCLAIMER:
    'DISCLAIMER: The data you are currently seeing is an estimate of your traffic only. Data is updated daily at 3pm Eastern time for the previous day‘s traffic. Please do not rely on this for a final payment calculation. Only active contributors (within 60 days) are displayed in the Site Stats table, but all site views and page views are counted. Tier 4 only applies to site experts on soccer sites.',
  INSTRUCTIONS: 'Check out your site’s overview for today | ',
  RESULTS: 'Showing results for:',
  PUBLISHED: 'Site’s Published Articles',
  VIEWS: 'Total Site Views',
  NON_VIEWS: 'Non-Expert Site Views',
};

export const USER_STATS_TEXT = {
  RESULTS: 'Showing results for:',
  INSTRUCTIONS: 'Check out your overview for today | ',
  DISCLAIMER:
    'The data you are currently seeing is an estimate of your traffic only. Data is updated daily at 3pm Eastern time for the previous day‘s traffic. Please do not rely on this for a final payment calculation. Tier 4 only applies to site experts on soccer sites',
  PAYMENTS: 'My payment history',
};

export const BUTTON_TEXT = {
  SITE_STATS_NAV: 'Go to Site Stats',
  USER_STATS_NAV: 'Go to My Stats',
};

export const USER_STATS_LABELS: {[key in TabKey] ?: string} = {
  [TabKey.Overview]: 'My Stats Overview',
  [TabKey.Post]: 'Post Stats',
  [TabKey.Payments]: 'Payment History',
};

export const SITE_STATS_LABELS: {[key in TabKey] ?: string} = {
  [TabKey.Overview]: 'Site Stats Overview',
  [TabKey.Post]: 'Post Stats',
};

export const USER_STATS_TABS_DATA = [
  { label: USER_STATS_LABELS.overview, selected: true, key: TabKey.Overview },
  { label: USER_STATS_LABELS.post, selected: false, key: TabKey.Post },
  { label: USER_STATS_LABELS.payments, selected: false, key: TabKey.Payments },
];

export const SITE_STATS_TAB_DATA = [
  { label: SITE_STATS_LABELS.overview, selected: true, key: TabKey.Overview },
  { label: SITE_STATS_LABELS.post, selected: false, key: TabKey.Post },
];

export const SEARCH_PLACEHOLDER = 'Search for a post title';
