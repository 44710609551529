import { mmNetworkService } from '../../network/mmServicesApiProvider';

export enum NetworkAction {
  MMFetch = 'mmFetch',
}

export enum HttpMethod {
  Post = 'POST',
  Get = 'GET',
  Delete = 'DELETE',
  Put = 'PUT',
  Patch= 'PATCH',
  Head = 'HEAD',
  Options = 'OPTIONS',
}

export const getFetchFunction = {
  [NetworkAction.MMFetch]: mmNetworkService.mmFetch,
};

export const FETCH_TIMEOUT = 60 * 1000;
