import { getEditorialMenuState } from '../editorialMenu.reducer';

function socialSharingState(state) {
  return getEditorialMenuState(state).socialSharing;
}

export const socialSharingSelector = {
  getSocialSharing(state) {
    return socialSharingState(state);
  },
  getSocialSharingOgTitle(state) {
    return socialSharingSelector.getSocialSharing(state).title;
  },
  getSocialSharingOgImageData(state) {
    return socialSharingSelector.getSocialSharing(state).image;
  },
  getSocialSharingDescription(state) {
    return socialSharingSelector.getSocialSharing(state).description;
  },
};
