import React, { Component, useEffect } from 'react';
import { css } from '@emotion/core';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';

import { ExcoIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from './Plugin/Plugin';
import { EmbedBlock } from './shared/EmbedBlock';

import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';
import { loadExco } from '../services/excoServices';

import { overviewBlockText } from '../utils/blockList.utils';
import { urlInputPlaceholderGenerator, pluginAddButtonTextHandler } from '../utils/plugins.utils';

import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const EXCO_OEMBED_PREFIX = `https://oembed.playbuzz.com/item?${OEMBED_QUERY_PARAMS.URL}=`;
const ENTER_EXCO_PLACEHOLDER = urlInputPlaceholderGenerator('Exco');
const EMBED_BROKEN_URL_TEXT = 'The embed url you are using is not from the Exco. Please paste a correct one.';

// Element-Panel Top Bar Button

export const ExcoEmbedTopBarIcon = props => {
  return (
    <Icon icon={ExcoIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class ExcoEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && (editedBlockData.type === BLOCK_TYPES.EXCO || editedBlockData.type === BLOCK_TYPES.PLAYBUZZ);
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      excoData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.EXCO || nextProps.blockType === BLOCK_TYPES.PLAYBUZZ;
  }

  componentDidUpdate(prevProps, prevState) {
    const { excoData } = this.state;
    const needsLoad = excoData && excoData !== prevState.excoData;
    if (needsLoad) {
      loadExco();
    }
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { excoData } = this.state;
    onAdd(excoData);
  };

  onUrlInputKeyDown = e => {
    const { excoData } = this.state;
    if (isEnterPressed(e) && excoData) {
      this.onAdd(excoData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    if (this.isValidExcoUrl(url)) {
      const oEmbedEndPoint = EXCO_OEMBED_PREFIX + encodeURI(url);
      getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.EXCO)
        .then(excoData => {
          finishLoading();
          this.setState({ excoData });
        })
        .catch(() => {
          finishLoading();
          this.setState({ excoData: null });
        });
    } else {
      finishLoading();
      this.setState({ excoData: null });
    }
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, excoData } = this.state;
    if (url === '' || isLoading) {
      return null;
    }
    return excoData && this.isValidExcoUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: excoData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_BROKEN_URL_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidExcoUrl = url => url.includes('playbuzz') || url.includes('ex.co');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { excoData, url } = this.state;
    const isAddDisabled = excoData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_EXCO_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const ExcoBlock = props => {
  useEffect(() => {
    loadExco();
  });

  const embedBlockProps = { ...props };
  return <EmbedBlock {...embedBlockProps} />;
};

export function ExcoEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <ExcoEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const excoEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<ExcoEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<ExcoEmbedPanelComponent {...props} />),
  getPluginBlock: props => <ExcoBlock {...props} />,
  getPluginOverviewBlock: props => (<ExcoEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.EXCO,
};
