export const REPORTS = 'reports'; // old
export const ANALYTICS = 'analytics';
const BASE_ROUTE = `/${ANALYTICS}`;
const DASHBOARDS = '/dashboard';
const USERS = '/users';
const QUERY_BUILDER = '/query_builder';
const DATA_SOURCES = '/data-sources';
const HQ_USERS = '/hq-users';
const HOMEPAGE_DASHBOARD = '/homepageDashboard';

export const DASHBOARDS_PATHS = {
  OLD_OVERVIEW: 'overview',
  REVENUE_OVERVIEW: 'revenue-overview',
  MM_SSP: 'mm-ssp',
  VOLTAX_REVENUE: 'voltax-revenue',
  PUBLISHER_EXTERNAL_DEMAND: 'publisher-external-demand',
  VOLTAX_DISPLAY: 'voltax-display',
  GENERAL_OVERVIEW: 'general-overview',
  REVENUE_OVERVIEW_SUMMARY: 'total-revenue-overview-summary',
  UNIFIED_PLAYER_REVENUE: 'unified-voltax-revenue',
};

export enum DashboardsPaths {
  Homepage = 'homepage',
  RevenueOverview = 'revenue-overview',
  MMSSPRevenue = 'mm-ssp',
  VoltaxRevenue = 'voltax-revenue',
  RevenueOverviewSummary = 'total-revenue-overview-summary',
  UnifiedPlayerRevenue = 'unified-voltax-revenue',
}

export const ANALYTICS_ROUTES = {
  BASE: BASE_ROUTE,
  ANALYTICS_USERS: `${BASE_ROUTE}${USERS}`,
  DASHBOARDS: `${BASE_ROUTE}${DASHBOARDS}`,
  QUERY_BUILDER_USERS: `${BASE_ROUTE}${QUERY_BUILDER}${USERS}`,
  QUERY_BUILDER: `${BASE_ROUTE}${QUERY_BUILDER}`,
  REVENUE_OVERVIEW_DASHBOARD: `${BASE_ROUTE}${DASHBOARDS}/${DASHBOARDS_PATHS.REVENUE_OVERVIEW}`,
  MM_SSP_DASHBOARD: `${BASE_ROUTE}${DASHBOARDS}/${DASHBOARDS_PATHS.MM_SSP}`,
  VOLTAX_REVENUE_DASHBOARD: `${BASE_ROUTE}${DASHBOARDS}/${DASHBOARDS_PATHS.VOLTAX_REVENUE}`,
  UNIFIED_PLAYER_REVENUE_DASHBOARD: `${BASE_ROUTE}${DASHBOARDS}/${DASHBOARDS_PATHS.UNIFIED_PLAYER_REVENUE}`,
  REVENUE_OVERVIEW_SUMMARY_DASHBOARD: `${BASE_ROUTE}${DASHBOARDS}/${DASHBOARDS_PATHS.REVENUE_OVERVIEW_SUMMARY}`,
  DATA_SOURCES_MANAGEMENT: `${BASE_ROUTE}${DATA_SOURCES}`,
  HQ_ANALYTICS_USERS: `${BASE_ROUTE}${HQ_USERS}`,
  HOMEPAGE: `${BASE_ROUTE}${HOMEPAGE_DASHBOARD}`,
  REPORTS: `${BASE_ROUTE}/${REPORTS}`,
};

export enum ExploreProductsName {
  RecirculationVideo = 'RecirculationVideo',
  VideoHub = 'VideoHub',
  SemanticPlaylist = 'SemanticPlaylist',
}
