import { ConfigsProvider } from 'core/configs/ConfigsProvider';

const { QUERY_BUILDER_API_BASE_URL } = ConfigsProvider;
const REPORT = 'report';
const REPORT_QUERY = 'report-query';
const DATA_SOURCES = 'datasources';
const ORGANIZATIONS = 'organizations';
const REPORTS_CONFIG = 'reports-config';
const SCHEDULE = 'schedule';
const API_KEY = 'apikey';
const REGENERATE = 'regenerate';
const GENERATE = 'generate';
const EXISTS = 'exists';

export class QueryBuilderServiceConfigsProvider {
  static getDataSourceListEndpoint(organizationID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${DATA_SOURCES}`;
  }

  static getReportQueryEndpoint() {
    return `${QUERY_BUILDER_API_BASE_URL}/${REPORT_QUERY}`;
  }

  static getReportEndpoint(organizationID: string, dataSourceID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${DATA_SOURCES}/${dataSourceID}/${REPORT}`;
  }

  static getSaveReportEndpoint(organizationID: string, reportConfigID?: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${REPORTS_CONFIG}/${reportConfigID}`;
  }

  static getSaveAsReportEndpoint(organizationID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${REPORTS_CONFIG}`;
  }

  static getReportsListEndpoint(organizationID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${REPORTS_CONFIG}`;
  }

  static getReportOnLoadEndpoint(organizationID: string, reportConfigID?: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${REPORTS_CONFIG}/${reportConfigID}`;
  }

  static getScheduleEndpoint(organizationID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${SCHEDULE}`;
  }

  static getSpecificSchedule(organizationID: string, id: string | undefined) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${SCHEDULE}/${id}`;
  }

  static getGenerateApiKeyEndpoint(organizationID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${API_KEY}/${GENERATE}`;
  }

  static getRegenerateApiKeyEndpoint(organizationID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${API_KEY}/${REGENERATE}`;
  }

  static getIsAPIKeyExistEndpoint(organizationID: string) {
    return `${QUERY_BUILDER_API_BASE_URL}/${ORGANIZATIONS}/${organizationID}/${API_KEY}/${EXISTS}`;
  }
}
