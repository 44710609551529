import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { ErrorPage } from '../errorPage/ErrorPage';
import { authorizeRoute, getAllPremissionsProperties } from './routes.utils';
import { UNAUTHORIZED_ERROR_TITLE } from '../errorPage/errorPage.constants';
import { userSelector } from '../../store/user/user.selector';
import { propertySelector } from '../../store/property/property.selector';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { VoltaxRole } from '../../store/user/roles.constants';
import { HQRole } from '../../store/user/roles.constants.hq';

interface ProtectedRouteOptions {
  allowedRoles?: VoltaxRole[] | HQRole[];
  allowNoProperties?: boolean;
  allowNoOrganization?: boolean;
  allowedHQRole?: HQRole[]
}

interface ProtectedRouteConfig {
  routeConfig: ProtectedRouteOptions | null
}

export const ProtectedRouteContext = React.createContext<ProtectedRouteConfig>({ routeConfig: null });

interface ProtectedRouteProps extends RouteProps, ProtectedRouteOptions {
}

export const ProtectedRoute = (protectedRouteProps: ProtectedRouteProps) => {
  const { component, path, allowedRoles, allowNoProperties, allowNoOrganization, allowedHQRole, ...rest } = protectedRouteProps;
  const Component = component as React.FunctionComponent;

  const propertiesList = useSelector(propertySelector.organizationProperties);
  const userProperties = getAllPremissionsProperties(useSelector(userSelector.getVoltaxUserPermission), propertiesList);

  const userRoles = useSelector(userSelector.userRoles);
  const userOrgId = useSelector(userSelector.getUserOrgId);
  const { routeConfig } = useContext(ProtectedRouteContext);
  const calcAllowNoProperties = allowNoProperties ?? routeConfig?.allowNoProperties;
  const calcAllowNoOrganization = allowNoOrganization ?? routeConfig?.allowNoOrganization;
  const calcAllowedRoles = allowedRoles ?? routeConfig?.allowedRoles ?? [];
  const calcAllowedHQRole = allowedHQRole ?? routeConfig?.allowedHQRole ?? [];

  const userPermission = { userRoles, userProperties, userOrgId };
  const routeRestrictions = {
    allowedRoles: isEmpty(calcAllowedRoles) ? calcAllowedHQRole : calcAllowedRoles,
    allowNoProperties: calcAllowNoProperties,
    allowNoOrganization: calcAllowNoOrganization,
  };
  const renderComponentOrUnauthorizedPage:React.FunctionComponent = props => {
    const { isAuthorized, error, message } = authorizeRoute(userPermission, routeRestrictions);
    return isAuthorized
    ? (
      <ErrorBoundary key={path as string}>
        {Component && <Component {...props} />}
      </ErrorBoundary>
      )
    : (
      <ErrorPage
        title={UNAUTHORIZED_ERROR_TITLE}
        description={error}
        message={message}
      />
      );
  };

  // TODO(types) - MUST change to specific type
  return (
    <ProtectedRouteContext.Provider value={{
          routeConfig: {
            allowedRoles: calcAllowedRoles,
            allowNoProperties: calcAllowNoProperties,
            allowNoOrganization: calcAllowNoOrganization,
          },
        }}
    >
      <Route render={renderComponentOrUnauthorizedPage as any} path={path} {...rest} />
    </ProtectedRouteContext.Provider>
    );
};
