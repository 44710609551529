export const getStylesObject = () => {
    return {
      wrapper: {
        position: 'absolute',
        height: 'max-content',
        maxHeight: '100vh',
        overflowY: 'scroll',
        paddingTop: '24px',
        paddingLeft: '32px',
        right: '0',
        bottom: '0',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    };
  };
