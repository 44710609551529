import { SITE_VARIABLES } from '../../../../../core/styles/variables';

export const getStylesObject = () => {
  return {
    header: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 16px 0',
      marginBottom: '16px',
    },
    dropdownVariables: {
      dropdownButtonHeight: '32px',
      dropdownWidth: '100%',
    },
    errorStyles: {
      color: SITE_VARIABLES.TEXT_COLOR.ERROR,
      marginBottom: SITE_VARIABLES.MARGIN.TINY,
      textAlign: 'center',
      paddingTop: '16px',
    },
    loadMoreButton: {
      paddingTop: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
  };
};
