export const TEXT_JAVASCRIPT_TYPE = 'text/javascript';

const GTM_KEY = 'GTM-N3LH97W';

export const GOOGLE_TAG_MANAGER = {
  SCRIPT_FOR_HEAD: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_KEY}');
    `,
  NOSCRIPT_FOR_BODY: `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_KEY}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `,
};

export const HTML_ELEMENT_TYPES = {
  SCRIPT: 'script',
  NOSCRIPT: 'noscript',
};
