import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSliceName } from '../../../../../../core/store/store.utils';
import { ImageServices } from '../../../../services/imageServices/imageServiceApiProvider';
import { AVAILABLE_UPLOAD_SOURCES } from '../../../../services/imageServices/imageService.constants';
import { CROP_ASPECT_TYPES_NAMES, formatImageNameForUpload } from '../../../../services/imageServices/imageService.utils';
import { getImageFromDriveData } from '../../../utils/elementPanelContainer.utils';
import { deserializeItemToImageObject } from '../../../utils/editorServiceDataFormatter';
import { EDITOR_ACTION_TYPE_PREFIX } from '../../editor.constants';

export const initialState = {
    title: '',
    description: '',
    image: null,
};

const sliceScope = 'SocialSharing';
const SLICE_NAME_WITH_PREFIX = EDITOR_ACTION_TYPE_PREFIX + sliceScope;
const SLICE_NAME = getSliceName(SLICE_NAME_WITH_PREFIX, sliceScope);

export const uploadAndUpdateSocialSharingImage = createAsyncThunk(
  `${SLICE_NAME}/uploadAndUpdateSocialSharingImage`,
  async ({ imageSource, imageFileName, percentageCrop, property }) => {
    const uploadResponse = await ImageServices.cropAndUploadImage({
      source: AVAILABLE_UPLOAD_SOURCES.EDITOR,
      filename: formatImageNameForUpload(imageFileName),
      imageData: imageSource,
      crop: percentageCrop,
      property,
    });
    return getImageFromDriveData({
      uploadResponse,
      caption: '',
      credit: '',
      aspectRatio: CROP_ASPECT_TYPES_NAMES.HORIZONTAL,
      cropping: uploadResponse.newCropping,
    });
  },
);

const socialSharing = createSlice({
  reducers: {
    updateSocialSharingTitle(state, action) {
      state.title = action.payload;
    },
    updateSocialSharingDescription(state, action) {
      state.description = action.payload;
    },
    updateSocialSharingImage(state, action) {
      state.image = action.payload;
    },
    autoFillSocialSharingIfNeedTo(state, action) {
      const { title, image, description } = state;
      const { draftTitle, draftDescription, draftCover } = action.payload;
      if (!title) {
        socialSharing.caseReducers.updateSocialSharingTitle(state, { payload: draftTitle });
      }
      if (!description) {
        const abbreviatedBodyTextAsSocialSharingDescription = draftDescription.substring(0, 160);
        socialSharing.caseReducers.updateSocialSharingDescription(state, { payload: abbreviatedBodyTextAsSocialSharingDescription });
      }
      if (!image) {
        socialSharing.caseReducers.updateSocialSharingImage(state, { payload: draftCover });
      }
    },
    reportUseArticleDataForSocialSharing() {},
    updateSocialSharing(state, action) {
      const { og } = action.payload;
      const { image: serializedImage, title, description } = og;
      const image = serializedImage.metadata ? deserializeItemToImageObject(serializedImage.metadata) : null;
      state.title = title;
      state.description = description;
      state.image = image;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(uploadAndUpdateSocialSharingImage.fulfilled, (state, action) => {
        socialSharing.caseReducers.updateSocialSharingImage(state, action);
      });
  },
  initialState,
  name: SLICE_NAME,
});

export const {
  updateSocialSharingTitle,
  updateSocialSharingDescription,
  updateSocialSharingImage,
  autoFillSocialSharingIfNeedTo,
  updateSocialSharing,
  reportUseArticleDataForSocialSharing,
} = socialSharing.actions;

export default socialSharing.reducer;
