import { ConfigsProvider } from 'core/configs/ConfigsProvider';

const { CONTRIBUTORS_ANALYTICS_API_BASE_URL } = ConfigsProvider;
const BASE_PROPERTIES = '/properties';
const BASE_USERS = '/users';
const STATS = '/stats';
const INVOICES = '/invoices';
const ARTICLES = '/articles';
const ORGANIZATIONS = '/organizations';

export class AnalyticsServiceConfigsProvider {
  static getSiteStatsEndpoint(siteId: string, query: string) {
    return `${CONTRIBUTORS_ANALYTICS_API_BASE_URL}${BASE_PROPERTIES}/${siteId}${STATS}${query}`;
  }

  static getUserStatsEndpoint(userId: string, organizationId: string, query: string) {
    return `${CONTRIBUTORS_ANALYTICS_API_BASE_URL}${ORGANIZATIONS}/${organizationId}${BASE_USERS}/${userId}${STATS}${query}`;
  }

  static getUserInvoicesEndpoint(userId: string, organizationId: string, query: string) {
    return `${CONTRIBUTORS_ANALYTICS_API_BASE_URL}${ORGANIZATIONS}/${organizationId}${BASE_USERS}/${userId}${INVOICES}${query}`;
  }

  static getPostStatsEndpoint(property: string, query: string, path: string, organizationId: string) {
    const relativePath = path || `${ORGANIZATIONS}/${organizationId}${BASE_PROPERTIES}/${property}${STATS}${ARTICLES}${query}`;
    return `${CONTRIBUTORS_ANALYTICS_API_BASE_URL}${relativePath}`;
  }

  static getUsersEndpoint(property: string) {
    return `${CONTRIBUTORS_ANALYTICS_API_BASE_URL}${BASE_PROPERTIES}/${property}${BASE_USERS}`;
  }
}
