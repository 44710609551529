import { mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { EchoServiceConfigsProvider } from '../../newEcho/services/echoServiceConfigsProvider';
import { EditorServiceDataProvider } from '../../editor/services/editorServiceConfigsProvider';

import {
  getPixelCroppingAndUrl,
  getImageUploadStringParams,
  getRealImageObject,
  replaceHttpToHttpsIfNeedTo,
} from './imageService.utils';

import { AVAILABLE_UPLOAD_SOURCES } from './imageService.constants';
import { Logger } from '../../../../core/logger';

export class ImageServices {
  static uploadImage({ source, filename, imageData, crop, property }) {
    return ImageServices.getSignedUploadUrl({ source, filename, imageData, crop, property })
      .then(signedUploadResponse => {
        return ImageServices.uploadImageToMediaProvider(signedUploadResponse.signed_url, imageData)
          .then(response => response.json())
          .then(obj => {
            return { url: replaceHttpToHttpsIfNeedTo(obj.url), path: signedUploadResponse.image_url, fileExtension: 'jpg' };
          });
      });
  }

  static async cropAndUploadImage({ source, filename, imageData, crop, property }) {
    try {
      const image = await getRealImageObject(imageData);
      const { url, ...rest } = await ImageServices.uploadImage({ source, filename, imageData, property });
      const { url: croppedUrl, cropping } = getPixelCroppingAndUrl({ image, crop, url });
      return {
        ...rest,
        url: croppedUrl,
        fullImageUrl: url,
        newCropping: cropping,
      };
    } catch (e) {
      Logger.log(e);
      return e;
    }
  }

  static getUploadUrlEndpointBySource(source) {
    const SOURCE_TO_UPLOAD_ENDPOINT_FUNCTION = {
      [AVAILABLE_UPLOAD_SOURCES.ECHO]: EchoServiceConfigsProvider.getSignedUploadImageEndpoint,
      [AVAILABLE_UPLOAD_SOURCES.EDITOR]: EditorServiceDataProvider.getSignedUploadImageEndpoint,
    };
    return SOURCE_TO_UPLOAD_ENDPOINT_FUNCTION[source];
  }

  static async getSignedUploadUrl({ source, filename, imageData, crop, property }) {
    const uploadParamsString = await getImageUploadStringParams(imageData, crop);
    const configs = {
      method: 'GET',
    };
    const uploadEndpointFunction = ImageServices.getUploadUrlEndpointBySource(source);
    return mmNetworkService.fetch(uploadEndpointFunction(filename, uploadParamsString, property), configs);
  }

  static uploadImageToMediaProvider(signedUrl, imageData) {
    const data = new FormData();
    data.append('file', imageData);
    const configs = {
      method: 'POST',
      body: data,
    };
    return mmNetworkService.externalFetch(signedUrl, configs);
  }
}
