import {
  getAmountOfWordsInBlockList,
  getContentTypesToQuantity,
  getOrderedBlocksWithWordCount,
} from './blockList.selector.utils';
import { BLOCK_TYPES } from './blocksDescriptorGenerator';
import { getAllLinksInBlock } from './slate/slate.utils';

export const getBlocksTypesToQuantity = blockList => {
  const blocksTypesWithQuantity = {};
  blockList.forEach(block => {
    if (block.type === BLOCK_TYPES.ADDER) return;
    blocksTypesWithQuantity[block.type] = !blocksTypesWithQuantity[block.type] ? 1 : blocksTypesWithQuantity[block.type] + 1;
  });
  return blocksTypesWithQuantity;
};

export const getAnalysis = (blockList, coverMediaType) => {
  return {
    numberOfWordsTyped: getAmountOfWordsInBlockList(blockList),
    blockTypeToQuantity: getBlocksTypesToQuantity(blockList),
    orderedBlocksWithWordCount: getOrderedBlocksWithWordCount(blockList),
    contentTypesToQuantity: getContentTypesToQuantity(blockList),
    listOfArticleLinks: blockList.reduce(getAllLinksInBlock, []),
    coverMediaType,
  };
};
