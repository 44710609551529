 import { getEditorState } from '../editor.selector';
import { elementPanelSelector } from '../elementPanel/elementPanel.selector';
import { ELEMENT_PANEL_CONTEXT } from '../../components/elementPanel/elementPanel.constants';
import { getAccumulatedTextStringFromBlockList } from '../../utils/blockList.utils';
import {
  getAmountOfWordsInBlockList,
} from '../../utils/blockList.selector.utils';
import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';

function getBlockListState(state) {
  return getEditorState(state).blockList;
}

export const blockListSelector = {
  blockList(state) {
    return getBlockListState(state).blockList;
  },
  cachedBlockList(state) {
    return getBlockListState(state).cachedBlockList;
  },
  isDragged(state) {
    return getBlockListState(state).isDragged;
  },
  currentlyActiveBlockKey(state) {
    return getBlockListState(state).currentlyActiveBlockKey;
  },
  isBlockAdderReplacementIndicationOn(state) {
    return getBlockListState(state).isBlockAdderReplacementIndicationOn;
  },
  blockKeyForInsertion(state) {
    return getBlockListState(state).blockKeyForInsertion;
  },
  isEditingBlockMedia(state, currentEditedBlockKey) {
    const { currentlyActiveBlockKey } = getBlockListState(state);
    return elementPanelSelector.elementPanelContext(state) === ELEMENT_PANEL_CONTEXT.EDITING_BLOCK && currentlyActiveBlockKey === currentEditedBlockKey;
  },
  isCurrentActiveBlockKey(state, blockKey) {
    return getBlockListState(state).currentlyActiveBlockKey === blockKey;
  },
  blockData(state, blockKey) {
    return getBlockListState(state).blockList.find(block => block.key === blockKey);
  },
  blockValue(state, blockKey) {
    return getBlockListState(state).blockList.find(block => block.key === blockKey).value;
  },
  blockListBodyText(state) {
    return getAccumulatedTextStringFromBlockList(getBlockListState(state).blockList);
  },
  numberOfWordsTyped(state) {
    return getAmountOfWordsInBlockList(getBlockListState(state).blockList);
  },
  isThereAnActiveBlock(state) {
    return getBlockListState(state).currentlyActiveBlockKey !== null;
  },
  getFirstPossiblyWritingBlockKey(state) {
    return blockListSelector.blockList(state).find(block => (block.type === BLOCK_TYPES.ADDER || block.type === BLOCK_TYPES.TEXT)).key;
  },
  isLinkableImageInBlockList(state) {
    return blockListSelector.blockList(state).some(block => block?.value?.linkURL);
  },
  isOverviewModeActive(state) {
    return getBlockListState(state).isOverviewMode;
  },
  sortingOrder(state) {
    return getBlockListState(state).sortingOrder;
  },
};
