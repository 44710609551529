export const GETTY_SORTING_OPTIONS = {
  BEST_MATCH: 'best_match',
  MOST_POPULAR: 'most_popular',
  NEWEST: 'newest',
  OLDEST: 'oldest',
};

export const GETTY_DROPDOWN_OPTIONS = {
  BEST_MATCH: 'Best match',
  MOST_POPULAR: 'Most popular',
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
};

export const GETTY_DROPDOWN_OPTION_TO_SORT_OPTION = {
  [GETTY_DROPDOWN_OPTIONS.BEST_MATCH]: GETTY_SORTING_OPTIONS.BEST_MATCH,
  [GETTY_DROPDOWN_OPTIONS.MOST_POPULAR]: GETTY_SORTING_OPTIONS.MOST_POPULAR,
  [GETTY_DROPDOWN_OPTIONS.NEWEST]: GETTY_SORTING_OPTIONS.NEWEST,
  [GETTY_DROPDOWN_OPTIONS.OLDEST]: GETTY_SORTING_OPTIONS.OLDEST,
};

export const GETTY_IMAGE_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const GETTY_EMBED_PLUGIN_NAME = 'Getty Images';
export const GETTY_CREATIVE_EMBED_PLUGIN_NAME = 'Getty Creative';
