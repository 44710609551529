export class EditorAnalyticsFlowMonitor {
  constructor() {
    this.setContentToInitialValue();
  }

  setContentToInitialValue() {
    this.contentAddedOrderNumber = 0;
    this.hasStartedEditingTitle = false;
  }

  shouldReportTitleEditing(title) {
    if (title.length === 1 && !this.hasStartedEditingTitle) {
      this.hasStartedEditingTitle = true;
      return true;
    }
    return false;
  }

  getAndIncrementContentFlowOrderNumber() {
    this.contentAddedOrderNumber += 1;
    return this.contentAddedOrderNumber;
  }
}

let instance;

export const getAnalyticsFlowMonitor = () => {
  if (!instance) {
    instance = new EditorAnalyticsFlowMonitor();
  }
  return instance;
};
