import { mmNetworkService } from 'core/network/mmServicesApiProvider';
import { MediaLibraryImagesNetworkService } from '../../../mediaLibrary/images/services/mediaLibraryImagesApiProvider';
import { IMAGE_EXCHANGE_PROVIDER } from '../plugins/shared/plugins.constants';

const IMAGE_EXCHANGE_API_SEARCH_IMAGE = 'https://images.fansided.com/wp-json/image-exchange/v1/images?';

export class ImageExchangeService {
  static getDefaultConfig() {
    return {
      method: 'GET',
    };
  }

  static async searchImages({ searchQuery, paged = 1, postsPerPage = 21, vertical = '' }) {
    const url = `${IMAGE_EXCHANGE_API_SEARCH_IMAGE}vertical=${vertical}&paged=${paged}&posts_per_page=${postsPerPage}&search=${searchQuery}`;
    try {
      const response = await mmNetworkService.externalFetch(url, ImageExchangeService.getDefaultConfig());
      if (response.status !== 200) {
        throw new Error('');
      }
      const resObject = await response.json();
      return resObject;
    } catch (error) {
      return error;
    }
  }

  static async copyImage(image, property) {
    try {
      const { imageUrl, path, fileType } = await this.uploadCopy(image.url, property);
      return { uri: imageUrl, path, fileType };
    } catch (e) {
      return e;
    }
  }

  static async uploadCopy(uri, property) {
    const { imageUrl, signedUrl } = await MediaLibraryImagesNetworkService.copyImageByFile({
      provider: IMAGE_EXCHANGE_PROVIDER,
      property,
    });
    const uploadResponse = await MediaLibraryImagesNetworkService.uploadFile({
      file: {
        imageSrc: uri,
      },
      url: signedUrl,
    });
    const uploadResObject = await uploadResponse.json();
    const { public_id: path, format: fileType } = uploadResObject;
    return { imageUrl, path, fileType };
  }
}
