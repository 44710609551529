import { ECHO } from './echoAnalyticsMiddleware.constants';
import { CMS_PAGES } from '../../../constants/cms.constants';

const filterOutReportedAuthors = (alreadyReportedAuthors, newAndReportedAuthors) => {
  return newAndReportedAuthors.filter(author => {
    return !alreadyReportedAuthors.includes(author);
  });
};

export const getNewAuthorsAsString = (alreadyReportedAuthors, newAndReportedAuthors) => {
  const newAuthors = filterOutReportedAuthors(alreadyReportedAuthors, newAndReportedAuthors);
  const newAuthorsAsString = newAuthors.map(author => author.name).toString().replace(/,/g, ', ');
  return newAuthorsAsString;
};

export const shouldReportSchedulingForTimeInTheFuture = action => action.payload.feeds[0].featureTime;

export const hasPostBeenHidden = action => action.payload.data.hidden;

export const hasPostBeenPinnedToTop = action => !action.payload.feed.pinnedTTL;

const doesActionTypeIncludeEchoString = actionType => {
  return actionType.includes(ECHO.toUpperCase());
};

export const shouldSkipEchoAnalyticsMiddleware = action => {
  const { type: actionType, payload: actionPayload } = action;
  if (!actionType) {
    return true;
  }
  return (
    !doesActionTypeIncludeEchoString(actionType)
    && actionPayload !== CMS_PAGES.ECHO_DASHBOARD
    && actionPayload !== CMS_PAGES.ECHO_CUSTOMIZATION
  );
};
