export const IMAGE_EXCHANGE_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const IMAGE_EXCHANGE_VERTICALS_PARAMS = {
  ALL: 'all',
  ENTERTAINMENT: 'entertainment',
  TRENDING_TOPICS: 'trending_topics',
  EXTRA: 'extra',
  LOCAL: 'local',
  NFL: 'nfl',
  NBA: 'nba',
  MLB: 'mlb',
  NHL: 'nhl',
  SOCCER: 'soccer',
  NCAAB: 'ncaab',
  NCAAF: 'ncaaf',
  LIFESTYLE: 'lifestyle',
  ESPORTS: 'esports',
};

export const IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN = {
  ALL: 'All Verticals',
  ENTERTAINMENT: 'Entertainment',
  TRENDING_TOPICS: 'Trending Topics',
  EXTRA: 'Extra',
  LOCAL: 'Local',
  NFL: 'NFL',
  NBA: 'NBA',
  MLB: 'MLB',
  NHL: 'NHL',
  SOCCER: 'Soccer',
  NCAAB: 'NCAAB',
  NCAAF: 'NCAAF',
  LIFESTYLE: 'Lifestyle',
  ESPORTS: 'ESports',
};

export const IMAGE_EXCHANGE_DROPDOWN_OPTION_TO_SERVER = {
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.ALL]: IMAGE_EXCHANGE_VERTICALS_PARAMS.ALL,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.ENTERTAINMENT]: IMAGE_EXCHANGE_VERTICALS_PARAMS.ENTERTAINMENT,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.TRENDING_TOPICS]: IMAGE_EXCHANGE_VERTICALS_PARAMS.TRENDING_TOPICS,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.EXTRA]: IMAGE_EXCHANGE_VERTICALS_PARAMS.EXTRA,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.LOCAL]: IMAGE_EXCHANGE_VERTICALS_PARAMS.LOCAL,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.NFL]: IMAGE_EXCHANGE_VERTICALS_PARAMS.NFL,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.NBA]: IMAGE_EXCHANGE_VERTICALS_PARAMS.NBA,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.MLB]: IMAGE_EXCHANGE_VERTICALS_PARAMS.MLB,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.NHL]: IMAGE_EXCHANGE_VERTICALS_PARAMS.NHL,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.SOCCER]: IMAGE_EXCHANGE_VERTICALS_PARAMS.SOCCER,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.NCAAB]: IMAGE_EXCHANGE_VERTICALS_PARAMS.NCAAB,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.NCAAF]: IMAGE_EXCHANGE_VERTICALS_PARAMS.NCAAF,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.LIFESTYLE]: IMAGE_EXCHANGE_VERTICALS_PARAMS.LIFESTYLE,
  [IMAGE_EXCHANGE_VERTICALS_FOR_DROPDOWN.ESPORTS]: IMAGE_EXCHANGE_VERTICALS_PARAMS.ESPORTS,

};
