import { STATUSES } from '@ftbpro/mm-admin-ui-components';

const BASE_ROUTE = '/ab-testing';

export const AB_TESTS_ROUTES = {
  BASE: BASE_ROUTE,
  NEW: `${BASE_ROUTE}/new`,
  EDIT: `${BASE_ROUTE}`,
};

export enum TestStatus {
    Active = 'active',
    Draft = 'draft',
    Stopped = 'stopped',
    Completed = 'completed',
    Pending = 'pending',
  }

  export const STATUS_TEXT = {
    ACTIVE: 'Active',
    DRAFT: 'Draft',
    STOPPED: 'Stopped',
    COMPLETED: 'Completed',
    PENDING: 'Pending',
  };

  export const STATUS_PROPS = {
    [TestStatus.Active]: {
      status: STATUSES.SUCCESS,
      text: STATUS_TEXT.ACTIVE,
    },
    [TestStatus.Draft]: {
      status: STATUSES.NEW,
      text: STATUS_TEXT.DRAFT,
    },
    [TestStatus.Stopped]: {
      status: STATUSES.FAILURE,
      text: STATUS_TEXT.STOPPED,
    },
    [TestStatus.Completed]: {
      status: STATUSES.ARCHIVED,
      text: STATUS_TEXT.COMPLETED,
    },
    [TestStatus.Pending]: {
      status: STATUSES.PENDING,
      text: STATUS_TEXT.PENDING,
    },
  };
