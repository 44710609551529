export const SEO_MENU_TITLE = 'SEO optimization';

export const SEO_MENU = {
  BUTTON_TEXT: 'SEO',
  BUTTON_TEXT_TOOLTIP: 'Voltax SEO Guide',
  SEO_FIELDS: 'Metadata fields',
  HYPERLINKS: 'Hyperlinks',
  TAGS: 'Tags',
  TIPS_BUTTON: 'Tips',
  IN_THIS_STORY: 'Teams',
};

export const SEO_MENU_ANALYTIC_LABEL = {
  SEO_DESCRIPTION: 'seo description',
  HYPERLINKS: 'hyperlinks',
  SEO_TITLE: 'seo title',
  TAGS: 'tags',
};

export const PLATFORM_URL = 'https://platform.help.voltax.io/hc/en-us/articles/1260802154449-Editor-SEO-Best-Practices';

export const SEO_TITLE_SUMMARY = {
  text: `The meta title & description are shown in search engine results that should best describe your page.
   They are major factors in optimizing the page ranking and increasing the organic traffic `,
  note: 'If the fields remain empty, the article’s title and first 160 characters will be used as the meta title & description respectively.',
};

export const SEO_MENU_FIELDS = {
  TITLE_LABEL: 'Meta title',
  TITLE_PLACEHOLDER: 'Enter Meta title',
  DESCRIPTION_LABEL: 'Meta description',
  DESCRIPTION_PLACEHOLDER: 'Enter Meta description',
  TITLE_MAX_LENGTH: 85,
  DESCRIPTION_MAX_LENGTH: 255,
};

export const SEO_MENU_FIELD_NAMES = {
  TITLE: SEO_MENU_FIELDS.TITLE_LABEL,
  DESCRIPTION: SEO_MENU_FIELDS.DESCRIPTION_LABEL,
};

export const HYPERLINKS_SUMMARY = `Editorial hyperlinks are a powerful way of funneling readers to your site's main pages 
  and help search engines better establish a clear information hierarchy of the site.`;

export const SEO_MENU_HYPERLINKS = {
  PLACEHOLDER: 'Search',
  TOOLTIP: 'Refresh hyperlinks',
};

export const SEO_MENU_TAGS = {
  DESCRIPTION: 'Content tags help categorize articles, which later assist in analysing and extracting traffic insights by various segmentations',
  LABEL: 'Tags',
};

export const SEO_TITLE_TIPS = [
  'Write a short, unique, compelling, and accurate description of the page.',
  'Use the main targeted keywords within a naturally phrased sentence, ideally close to the beginning.',
  'Keep it short, between 50 - 60 characters, to avoid it trimmed by search engines.',
  'Try to avoid ALL CAPS titles.',
];

export const SEO_DESCRIPTION_TIPS = [
  'Write a compelling and unique description that accurately summarizes the page.',
  'Use primary and secondary keywords to improve click-through rate.',
  'Keep it 110-160 characters long, including the main message in the first 110 chars.',
];

export const TAGS_TIPS = [
  'Choose the main tags that best describe the article',
  'Highly recommended to add more than 3 tags',
  'Recommended to tag the article type (e.g "Evergreen", "News", "Slideshow")',
];

export const HYPERLINKS_TIPS = [
  'Choose the pages within the site that are relevant to the page\'s topic',
  'Use an anchor text that best describes the following landing page to improve its search rankings',
  'Use between 3-10 links in total (internal & external) depending on the article length',
];

export const META_ASSISTANT_WRAPPER_TITLE = 'AI Meta Description Suggestions';
export const META_ASSISTANT_CONTENT_TYPE = 'metaDescription';

export const META_ASSISTANT_TEXT = {
  DESCRIPTION: 'AI Meta Description Suggestions',
  TOOLTIP: 'Please write at least 150 characters The more of the article you write the better!',
  SPARK_ID: 'meta-spark-button',
  ID: 'meta-description-button',
};

export const META_ASSISTANT_MIN_CHARS_REQUIRED = 150;

export const EXPECTED_NUMBER_OF_META_SUGGESTIONS = 3;

export const META_ASSISTANT_TENANTS = ['mmsport', 'qa'];
