import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Descendant } from 'slate';
import { deserializeHtmlToSlateValue } from '../../utils/slate/slateSerializer.utils';
import { EMPTY_INTRO_INITIAL_VALUE } from '../../components/editorIntro/editorIntro.constants';

export const POST_INTRO = 'postIntro';

export interface PostIntroSliceState {
  postIntro: Array<Descendant>;
  postIntroUpdatedValue: Array<Descendant>;
  isEditing: boolean;
  postIntroError: boolean;
}

export const initialState : PostIntroSliceState = {
  postIntro: [],
  postIntroUpdatedValue: [],
  isEditing: false,
  postIntroError: false,
};

const postIntroSlice = createSlice({
  name: POST_INTRO,
  initialState,
  reducers: {
    setPostIntro(state, action: PayloadAction<{ intro: string, isInitialValue: boolean }>) {
      const { intro, isInitialValue } = action.payload;
      const introHtmlString = intro || EMPTY_INTRO_INITIAL_VALUE;
      const postIntroSlateValue = deserializeHtmlToSlateValue(introHtmlString);
      if (isInitialValue) {
        state.postIntro = postIntroSlateValue;
      } else {
        state.postIntroUpdatedValue = postIntroSlateValue;
      }
    },
    updatePostIntro(state, action: PayloadAction<Array<Descendant>>) {
      state.postIntro = action.payload;
    },
    setIsEditing(state, action: PayloadAction<boolean>) {
      state.isEditing = action.payload;
    },
    setPostIntroError(state, action: PayloadAction<boolean>) {
      state.postIntroError = action.payload;
    },
  },
});

export const {
  setPostIntro,
  updatePostIntro,
  setIsEditing,
  setPostIntroError,
} = postIntroSlice.actions;

export default postIntroSlice.reducer;
