export const CLOSE_ELEMENT_PANEL = '[EDITOR] close element panel';
export const SET_ELEMENT_PANEL_LOADING = '[EDITOR] notify element panel loading state';
export const SET_IS_EDITING_PANEL_ACTIVE = '[EDITOR] set set is editing panel active ';
export const SET_CURRENT_ELEMENT_PANEL_TYPE = '[EDITOR] set current element panel type';
export const OPEN_ELEMENT_PANEL_AT_PANEL_TYPE = '[EDITOR] open element panel at panel type';
export const CLEAR_EDITED_BLOCK_DATA = '[EDITOR] clear editor block data';
export const SET_IS_CONTENT_ENRICHMENT = '[EDITOR] toggle content enrichment panel';
export const REPORT_MEDIA_ADDED = '[EDITOR] report media added';
export const REPORT_EMBED_CREATED = '[EDITOR] report content enrichment embed created';
export const OPEN_GETTY_PANEL = '[EDITOR] open getty panel';
export const SEARCH_GETTY_IMAGES = '[EDITOR] search getty images';
export const GETTY_IMAGE_SELECTED = '[EDITOR] getty image selected';

export const closeElementPanel = () => dispatch => {
  dispatch({
    type: CLOSE_ELEMENT_PANEL,
  });
};

export const setIsContentEnrichment = isContentEnrichment => dispatch => {
  dispatch({
    type: SET_IS_CONTENT_ENRICHMENT,
    payload: isContentEnrichment,
  });
};

export const setIsElementPanelLoading = isLoading => dispatch => {
  dispatch({
    type: SET_ELEMENT_PANEL_LOADING,
    payload: isLoading,
  });
};

export const setIsEditingPanelActive = isEditingPanelActive => dispatch => {
  dispatch({
    type: SET_IS_EDITING_PANEL_ACTIVE,
    payload: isEditingPanelActive,
  });
};

export const setCurrentElementPanelType = currentPanelType => dispatch => {
  dispatch({
    type: SET_CURRENT_ELEMENT_PANEL_TYPE,
    payload: currentPanelType,
  });
};

export const clearEditedBlockData = () => dispatch => {
  dispatch({
    type: CLEAR_EDITED_BLOCK_DATA,
  });
};

export const openElementPanelAtPanelType = ({ context, panelToOpen, editedBlockData }) => dispatch => {
  dispatch({
    type: OPEN_ELEMENT_PANEL_AT_PANEL_TYPE,
    payload: {
      context,
      panelToOpen,
      editedBlockData,
    },
  });
};

export const reportMediaAdded = (pluginData, additionalData, editingCover, blockType, currentActivePanelType) => dispatch => {
  dispatch({
    type: REPORT_MEDIA_ADDED,
    payload: {
      pluginData,
      additionalData,
      editingCover,
      blockType,
      currentActivePanelType,
    },
  });
};

export const reportEmbedCreated = embedType => dispatch => {
  dispatch({
    type: REPORT_EMBED_CREATED,
    payload: {
      embedType,
    },
  });
};

export const openGettyPanel = () => dispatch => {
  dispatch({
    type: OPEN_GETTY_PANEL,
  });
};

export const searchGettyImages = () => dispatch => {
  dispatch({
    type: SEARCH_GETTY_IMAGES,
    payload: { type: 'Editorial' },
  });
};

export const gettyImageSelected = () => dispatch => {
  dispatch({
    type: GETTY_IMAGE_SELECTED,
  });
};
