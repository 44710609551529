export const SHOW_SUBMIT_MODAL = '[EDITOR] present submit modal';
export const DISMISS_SUBMIT_MODAL = '[EDITOR] dismiss submit modal';
export const PRESENT_ARTICLE_OVERLAP_MODAL = '[EDITOR] article overlap modal';
export const TOGGLE_SOCIAL_SHARING_MODAL = '[EDITOR] toggle social sharing modal visibility';
export const TOGGLE_COMMENTS_MODAL = '[EDITOR] toggle comments modal';
export const TOGGLE_CREATE_TABLE_MODAL = '[EDITOR] toggle create table modal visibility';
export const TOGGLE_IMPORT_ARTICLE_MODAL = '[EDITOR] toggle import article modal visibility';

export const presentArticleOverlapModal = () => dispatch => {
  dispatch({
    type: PRESENT_ARTICLE_OVERLAP_MODAL,
  });
};

export const toggleSocialSharingModal = shouldPresentSocialSharingModal => dispatch => {
  dispatch({
    type: TOGGLE_SOCIAL_SHARING_MODAL,
    payload: {
      shouldPresentSocialSharingModal,
    },
  });
};

export const presentSubmitModal = publishedPostUrl => dispatch => {
  dispatch({
    type: SHOW_SUBMIT_MODAL,
    payload: publishedPostUrl,
  });
};

export const dismissSubmitModal = () => dispatch => {
  dispatch({
    type: DISMISS_SUBMIT_MODAL,
  });
};

export const toggleCommentsModal = (shouldPresentCommentsModal, entityKey) => dispatch => {
  dispatch({
    type: TOGGLE_COMMENTS_MODAL,
    payload: {
      shouldPresentCommentsModal,
      entityKey,
    },
  });
};

export const toggleCreateTableModal = shouldPresentCreateTableModal => dispatch => {
  dispatch({
    type: TOGGLE_CREATE_TABLE_MODAL,
    payload: {
      shouldPresentCreateTableModal,
    },
  });
};

export const toggleImportArticleModal = shouldPresentImportArticleModal => dispatch => {
  dispatch({
    type: TOGGLE_IMPORT_ARTICLE_MODAL,
    payload: {
      shouldPresentImportArticleModal,
    },
  });
};
