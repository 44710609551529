import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { notifyTokenExpiredIfNeedTo } from 'core/store/user/userSlice';
import { Logger } from 'core/logger';
import { pushGlobalNotificationActionCreator } from 'core/globalNotifications/store/globalNotifications.actions';
import { propertySelector } from 'core/store/property/property.selector';
import { copyToClipboard } from 'core/utils/utils';
import { EditorNetworkService } from '../../services/editorServiceApiProvider';

import {
  EDITOR_DASHBOARD_VIEW_TYPES,
  PUSH_NOTIFICATION_TEXTS,
} from '../../components/editorDashboard/editorDashboard.constants';

import { editorDashboardSelector } from './editorDashboard.selector';
import { CMS } from '../../../constants/cms.constants';
import { CMSNavigator } from '../../../utils/CMSNavigator';

import { DRAFTS_VIEW } from '../../constants/editor.constants';

export const COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD = '[EDITOR DASHBOARD] copy url to clipboard from dashboard';
export const TOGGLE_DRAFTS_POSTS_BY_USER = '[DASHBOARD - EDITOR WIDGET] - toggle drafts posts view by user';
export const LOAD_DRAFTS_REQUEST = '[EDITOR DASHBOARD] - load drafts request';
export const LOAD_DRAFTS_SUCCESS = '[EDITOR DASHBOARD] - load drafts success';
export const LOAD_DRAFTS_FAILURE = '[EDITOR DASHBOARD] - load drafts failure';
export const TOGGLE_DELETE_DRAFT_MODAL = '[EDITOR DASHBOARD] - toggling delete draft modal';
export const DELETE_DRAFT_REQUEST = '[EDITOR DASHBOARD] - delete draft request';
export const DELETE_DRAFT_SUCCESS = '[EDITOR DASHBOARD] - delete draft success';
export const DELETE_DRAFT_FAILURE = '[EDITOR DASHBOARD] - delete draft failure';
export const TOGGLE_CURRENT_VIEW = '[EDITOR DASHBOARD] - toggle view between drafts and articles';
export const PREVIEW_PAGE_OPEN_FROM_EDITOR_DASHBOARD = '[EDITOR DASHBOARD] preview page open';
export const GET_WRITERS_REQUEST = '[EDITOR DASHBOARD] - get writers request';
export const GET_WRITERS_SUCCESS = '[EDITOR DASHBOARD] - get writers success';
export const GET_WRITERS_FAILURE = '[EDITOR DASHBOARD] - get writers failure';
export const SET_SELECTED_WRITERS = '[EDITOR DASHBOARD] - set selected writers';

const getViewTypeFunction = view => {
  switch (view) {
    case EDITOR_DASHBOARD_VIEW_TYPES.DRAFTS: {
      return EditorNetworkService.getDrafts;
    }
    case EDITOR_DASHBOARD_VIEW_TYPES.SUBMITTED: {
      return EditorNetworkService.getSubmittedDrafts;
    }
    case EDITOR_DASHBOARD_VIEW_TYPES.PUBLISHED: {
      return EditorNetworkService.getPublishedArticles;
    }
    default: return null;
  }
};

export const getDrafts = ({ view, property }) => dispatch => {
  dispatch({
    type: LOAD_DRAFTS_REQUEST,
  });
  const viewTypeFunction = getViewTypeFunction(view);
  return viewTypeFunction && viewTypeFunction(property)
    .then(data => {
      return dispatch({
        type: LOAD_DRAFTS_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      notifyTokenExpiredIfNeedTo(error)(dispatch);
      Logger.error(error);
      return dispatch({
        type: LOAD_DRAFTS_FAILURE,
        payload: error,
      });
    });
};

export const deleteDraft = ({ property, id }) => dispatch => {
  dispatch({
    type: DELETE_DRAFT_REQUEST,
  });
  return EditorNetworkService.deleteDraft({ property, id })
    .then(() => {
      dispatch({
        type: DELETE_DRAFT_SUCCESS,
        payload: id,
      });
      return dispatch(pushGlobalNotificationActionCreator({
        text: PUSH_NOTIFICATION_TEXTS.DELETED_DRAFT,
        status: SNACK_BAR_STATUSES.SUCCESS,
      }));
    })
    .catch(error => {
      notifyTokenExpiredIfNeedTo(error)(dispatch);
      Logger.error(error);
      return dispatch({
        type: DELETE_DRAFT_FAILURE,
        payload: error,
      });
    });
};

export const toggleDeleteDraftModal = ({ isDeleteDraftModalVisible, modalDraftData }) => dispatch => {
  return dispatch({
    type: TOGGLE_DELETE_DRAFT_MODAL,
    payload: { isDeleteDraftModalVisible, modalDraftData },
  });
};

export const toggleCurrentView = view => dispatch => {
  return dispatch({
    type: TOGGLE_CURRENT_VIEW,
    payload: { currentView: view },
  });
};

export const deleteDraftAndUpdateData = ({ id }) => async (dispatch, getState) => {
  const state = getState();
  const view = editorDashboardSelector.currentView(state);
  const property = propertySelector.currentPropertySlug(state, CMS);
  await deleteDraft({ property, id })(dispatch);
  return getDrafts({ view, property })(dispatch);
};

export const userToggledDraftPostViews = view => dispatch => {
  localStorage.setItem(DRAFTS_VIEW, view);
  dispatch({
    type: TOGGLE_DRAFTS_POSTS_BY_USER,
    payload: { view },
  });
  toggleCurrentView(view)(dispatch);
};

export function openPreviewPageFromEditorDashboard(property, id) {
  return dispatch => {
    CMSNavigator.toEditorPreview(property, id);
    dispatch({
      type: PREVIEW_PAGE_OPEN_FROM_EDITOR_DASHBOARD,
    });
  };
}

export function copyUrlToClipboard(url) {
  return dispatch => {
    copyToClipboard(url);
    dispatch({
      type: COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD,
      payload: url,
    });
  };
}

export const getWriters = ({ property }) => dispatch => {
  dispatch({
    type: GET_WRITERS_REQUEST,
  });
  return EditorNetworkService.getWriters(property)
    .then(data => {
      return dispatch({
        type: GET_WRITERS_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      notifyTokenExpiredIfNeedTo(error)(dispatch);
      Logger.error(error);
      return dispatch({
        type: GET_WRITERS_FAILURE,
        payload: error,
      });
    });
};

export const setSelectedWriters = ({ property, writerNames, writerIds }) => dispatch => {
  dispatch({
    type: SET_SELECTED_WRITERS,
    payload: writerNames,
  });
  return EditorNetworkService.getDraftsByAuthors(property, writerIds)
    .then(data => {
      return dispatch({
        type: LOAD_DRAFTS_SUCCESS,
        payload: data.drafts,
      });
    })
    .catch(error => {
      notifyTokenExpiredIfNeedTo(error)(dispatch);
      Logger.error(error);
      return dispatch({
        type: LOAD_DRAFTS_FAILURE,
        payload: error,
      });
    });
};
