import { ConfigsProvider } from './ConfigsProvider';
import { onRedirectCallback } from '../auth/auth0.utils';
import { Logger } from '../logger';

const AUTH0_CONFIG = ConfigsProvider.AUTH0_CONFIGS;
const LOGOUT_QUERY = 'logout';
const CONNECTION_QUERY = 'connection';
const SSO_CONNECTION = 'sso';

export const getAuth0InitOption = () => {
  const currentUrl = new URL(window.location);

  const {
    AUTH0_CLIENT_ID: client_id,
    AUTH0_DOMAIN: auth0_domain,
    AUTH0_CUSTOM_DOMAIN: custom_domain,
    AUTH0_CONNECTION: connection,
    LOGIN_LOGO_SRC: logo_src,
    FAVICON_SRC: favicon_src,
    IS_SSO_CONNECTION: isSsoConnection,
    SSO_CONNECTION: ssoConnection,
  } = AUTH0_CONFIG;

  const authConnection = isSsoConnection ? ssoConnection : connection;
  let connectionSource = authConnection;
  let m_ssoConnection = ssoConnection;

  if (currentUrl.searchParams.has(LOGOUT_QUERY)) {
    connectionSource = connection;
    if (currentUrl.searchParams.get(CONNECTION_QUERY) === SSO_CONNECTION) {
      connectionSource = AUTH0_CONFIG.SSO_CONNECTION;
    } else {
      connectionSource = AUTH0_CONFIG.AUTH0_CONNECTION;
      m_ssoConnection = AUTH0_CONFIG.AUTH0_CONNECTION;
    }
  }

  return {
    domain: custom_domain,
    clientId: client_id,
    logoSrc: logo_src,
    faviconSrc: favicon_src,
    connection: connectionSource,
    isSsoConnection,
    ssoConnection: m_ssoConnection,
    audience: `https://${auth0_domain}/api/v2/`,
    scope: 'openid groups permissions roles profile email user_metadata picture',
    Logger,
    onRedirectCallback,
  };
};
