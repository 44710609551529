import { mmNetworkService } from 'core/network/mmServicesApiProvider';
import { EditorServiceDataProvider } from './editorServiceConfigsProvider';
import { MediaLibraryImagesNetworkService } from '../../../mediaLibrary/images/services/mediaLibraryImagesApiProvider';
import { GETTY_IMAGES_PROVIDER } from '../plugins/shared/plugins.constants';
import { GETTY_EMBED_PLUGIN_NAME } from '../plugins/getty/getty.constants';

const GETTY_API_SEARCH_IMAGE = 'https://api.gettyimages.com/v3/search/images/editorial?phrase=';
const GETTY_API_GET_IMAGE = 'https://api.gettyimages.com/v3/images/';
const GETTY_API_DOWNLOAD_IMAGE = 'https://api.gettyimages.com/v3/downloads/images/';
const QUERY_FIELDS = 'fields=caption,id,display_set,title,artist,people';

const GETTY_CREATIVE_API_SEARCH_IMAGE = 'https://api.gettyimages.com/v3/search/images/creative?phrase=';

const LOCAL_STORAGE_GETTY_HEADER = 'gettyAuthToken';
const LOCAL_STORAGE_GETTY_API_KEY = 'gettyApiKey';

export class GettyServices {
  static saveGettyTokensToLocalStorage({ token, apiKey }) {
    localStorage.setItem(LOCAL_STORAGE_GETTY_HEADER, token);
    localStorage.setItem(LOCAL_STORAGE_GETTY_API_KEY, apiKey);
  }

  static getGettyAuthToken(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getGettyAuthTokenEndpoint(property), configs);
  }

  static getDefaultConfig() {
    return {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem(LOCAL_STORAGE_GETTY_HEADER),
        'Api-Key': localStorage.getItem(LOCAL_STORAGE_GETTY_API_KEY),
      },
    };
  }

  static refreshToken(property) {
    return new Promise((resolve, reject) => {
      GettyServices.getGettyAuthToken(property).then(response => {
        GettyServices.saveGettyTokensToLocalStorage(response);
        resolve();
      })
        .catch(e => {
          reject(e);
        });
    });
  }

  static async searchImages({ pluginName, searchQuery, page = 1, sortedBy = 'newest', property }) {
    const gettyApiUrl = pluginName === GETTY_EMBED_PLUGIN_NAME ? GETTY_API_SEARCH_IMAGE : GETTY_CREATIVE_API_SEARCH_IMAGE;
    const url = `${gettyApiUrl}${searchQuery}&page=${page}&sort_order=${sortedBy}&${QUERY_FIELDS}`;
    try {
      const response = await mmNetworkService.externalFetch(url, GettyServices.getDefaultConfig());
      if (response.status !== 200) {
        throw new Error('');
      }
      const resObject = await response.json();
      return resObject.images;
    } catch (e) {
      // Attempt to refresh a token and call search func again
      try {
        await GettyServices.refreshToken(property);
        const res = await mmNetworkService.externalFetch(url, GettyServices.getDefaultConfig());
        if (res) {
          const resObject = await res.json();
          return resObject.images;
        }
      } catch (error) {
        return error;
      }
    }
    return null;
  }

  static async checkImageDownloadSizes(id) {
    const url = `${GETTY_API_GET_IMAGE}${id}?fields=download_sizes`;
    const downloadSizeResp = await mmNetworkService.externalFetch(url, {
      ...GettyServices.getDefaultConfig(),
      method: 'GET',
    });
    const resObject = await downloadSizeResp.json();
    return resObject;
  }

  static getImageUrl({ availableSizes, id, size }) {
    const imageInfo = availableSizes.images[0];
    const { download_sizes: downloadSizes } = imageInfo;
    const image = downloadSizes.find(item => item.name === size) || downloadSizes[downloadSizes.length - 1];
    const imageHeightQueryParam = image ? `height=${image.height}&` : '';
    return `${GETTY_API_DOWNLOAD_IMAGE}${id}?${imageHeightQueryParam}auto_download=false&product_type=easyaccess`;
  }

  static async copyImage(id, size, property) {
    try {
      let uri = await this.downloadImage(id, size);
      if (uri === null) {
        await GettyServices.refreshToken(property);
        uri = await this.downloadImage(id, size);
        if (uri === null) {
          return new Error('unable to fetch image');
        }
      }

      const { imageUrl, path, fileType } = await this.uploadCopy(uri, property);

      return { uri: imageUrl, path, fileType };
    } catch (e) {
      return e;
    }
  }

  static async uploadCopy(uri, property) {
    const { imageUrl, signedUrl } = await MediaLibraryImagesNetworkService.copyImageByFile({
      provider: GETTY_IMAGES_PROVIDER,
      property,
    });
    const uploadResponse = await MediaLibraryImagesNetworkService.uploadFile({
      file: {
        imageSrc: uri,
      },
      url: signedUrl,
    });
    const uploadResObject = await uploadResponse.json();
    const { public_id: path, format: fileType } = uploadResObject;
    return { imageUrl, path, fileType };
  }

  static async downloadImage(id, size) {
    try {
      const availableSizes = await GettyServices.checkImageDownloadSizes(id);
      const config = {
        ...GettyServices.getDefaultConfig(),
        method: 'POST',
      };
      const response = await mmNetworkService.externalFetch(GettyServices.getImageUrl({
        availableSizes,
        id,
        size,
      }), config);

      if (response.status !== 200) {
        return null;
      }

      const resObject = await response.json();
      return resObject.uri;
    } catch (e) {
      return null;
    }
  }
}
