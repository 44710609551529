const hasPermissionForService = (requiredPermissions, userRoles = []) => {
  return requiredPermissions.some(permission => userRoles.includes(permission));
};

export const getAllowedNavigationItems = (availableNavigationItems, userRoles) => {
  return availableNavigationItems.reduce((allowedNavigationItems, currentNavigationItem) => {
    if (currentNavigationItem.subItems) {
      const allowedSubItems = getAllowedNavigationItems(currentNavigationItem.subItems, userRoles);
      if (allowedSubItems && allowedSubItems.length > 0) {
        return [
          ...allowedNavigationItems,
          {
          ...currentNavigationItem,
          subItems: allowedSubItems,
          }];
      }
      return allowedNavigationItems;
    }
    if (hasPermissionForService(currentNavigationItem.requiredPermissions, userRoles)) {
      return [...allowedNavigationItems, currentNavigationItem];
    }
    return allowedNavigationItems;
  }, []);
};
