import { BLOCK_TYPES } from './blocksDescriptorGenerator';
import { getNumberOfWordsInAString,
} from './inlineText.utils';
import { getStringListFromSlateValue,
} from './slate/slate.utils';

const filterBlockTypesText = blockDescriptor => blockDescriptor.type === BLOCK_TYPES.TEXT;

const filterBlockTypesListItem = blockDescriptor => blockDescriptor.type === BLOCK_TYPES.LIST_ITEM;

const filterBlockTypesTitle = blockDescriptor => blockDescriptor.type === BLOCK_TYPES.TITLE;

const filterBlockTypesQuote = blockDescriptor => blockDescriptor.type === BLOCK_TYPES.QUOTE;

const filterBlockTypesFAQ = blockDescriptor => blockDescriptor.type === BLOCK_TYPES.FAQ;

const filterBlockTypesQuestion = blockDescriptor => blockDescriptor.type === BLOCK_TYPES.QUESTION;

const wordCountAccumulator = (numberOfWordsSoFar, text) => {
  return numberOfWordsSoFar + getNumberOfWordsInAString(text);
};

export const countWordsInTextBlock = textBlock => {
  const wordsArray = textBlock.value.map(getStringListFromSlateValue);
  return wordsArray.reduce(wordCountAccumulator, 0);
};

const countWordsInQuoteBlock = quoteBlock => {
  return getNumberOfWordsInAString(quoteBlock.value.cite) + getNumberOfWordsInAString(quoteBlock.value.text);
};

const countWordsInListItemBlock = listBlock => {
  return getAmountOfWordsInBlockList(listBlock.value.items); // eslint-disable-line
};

// this function is an implementation of a transducer
// it only count words don't be alarmed...
const countPropertiesOfFilteredItems = predicate => countPropertiesOfItem => (counter, item) => (predicate(item) ? counter + countPropertiesOfItem(item) : counter);

const countWordsInTitleBlock = titleBlock => {
  return getNumberOfWordsInAString(titleBlock.value.text);
};

export const getAmountOfWordsInBlockList = blockList => {
  const textBlocksWords = blockList.reduce(countPropertiesOfFilteredItems(filterBlockTypesText)(countWordsInTextBlock), 0);
  const listBlocksWords = blockList.reduce(countPropertiesOfFilteredItems(filterBlockTypesListItem)(countWordsInListItemBlock), 0);
  const innerTitleWords = blockList.reduce(countPropertiesOfFilteredItems(filterBlockTypesTitle)(countWordsInTitleBlock), 0);
  const quoteBlocksWords = blockList.reduce(countPropertiesOfFilteredItems(filterBlockTypesQuote)(countWordsInQuoteBlock), 0);
  const faqBlocksWords = blockList.reduce(countPropertiesOfFilteredItems(filterBlockTypesFAQ)(countWordsInListItemBlock), 0);
  const questionBlocksWords = blockList.reduce(countPropertiesOfFilteredItems(filterBlockTypesQuestion)(countWordsInTitleBlock), 0);
  return textBlocksWords + listBlocksWords + innerTitleWords + quoteBlocksWords + faqBlocksWords + questionBlocksWords;
};

export const getOrderedBlocksWithWordCount = blocks => {
  const orderedBlocksWithWordCount = [];
  blocks.forEach(block => {
    switch (block.type) {
      case BLOCK_TYPES.ADDER:
        break;
      case BLOCK_TYPES.TEXT:
        orderedBlocksWithWordCount.push({
          type: BLOCK_TYPES.TEXT,
          numberOfWords: countWordsInTextBlock(block),
        });
        break;
      case BLOCK_TYPES.TITLE:
        orderedBlocksWithWordCount.push({
          type: BLOCK_TYPES.TITLE,
          numberOfWords: countWordsInTitleBlock(block),
        });
        break;
      case BLOCK_TYPES.LIST_ITEM: {
        const numberOfWords = getAmountOfWordsInBlockList(block.value.items);
        orderedBlocksWithWordCount.push({
          type: block.type,
          numberOfWords,
        });
      }
        break;
      default:
        orderedBlocksWithWordCount.push({
          type: block.type,
          numberOfWords: 0,
        });
    }
  });
  return orderedBlocksWithWordCount;
};

export const getContentTypesToQuantity = blocks => {
  const contentTypesToQuantity = {};
  blocks.forEach(block => {
    switch (block.type) {
      case BLOCK_TYPES.ADDER:
        break;
      case BLOCK_TYPES.LIST_ITEM: {
        const typeOfContentInListItem = getContentTypesToQuantity(block.value.items);
        Object.entries(typeOfContentInListItem).forEach(([blockType, numberOfOccurrences]) => {
          if (blockType !== BLOCK_TYPES.TITLE) {
            contentTypesToQuantity[blockType] = contentTypesToQuantity[blockType] + numberOfOccurrences || 1;
          }
        });
        break;
      }
      default:
        contentTypesToQuantity[block.type] = contentTypesToQuantity[block.type] + 1 || 1;
    }
  });
  return contentTypesToQuantity;
};
