import React, { Component, useRef, useState, useEffect } from 'react';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/core';

import { DailyMotionIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from './Plugin/Plugin';
import { EmbedBlock } from './shared/EmbedBlock';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';
import { overviewBlockText } from '../utils/blockList.utils';
import { urlInputPlaceholderGenerator, pluginAddButtonTextHandler } from '../utils/plugins.utils';
import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const DAILY_MOTION_OEMBED_URL_PREFIX = `https://www.dailymotion.com/services/oembed?${OEMBED_QUERY_PARAMS.URL}=`;
const ENTER_DAILY_MOTION_PLACEHOLDER = urlInputPlaceholderGenerator('DailyMotion');
const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Daily Motion. Please paste a correct one.';

// Element-Panel Top Bar Button

export const DailyMotionEmbedTopBarIcon = props => {
  return (
    <Icon icon={DailyMotionIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class DailyMotionEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = props.editedBlockData && props.editedBlockData.type === BLOCK_TYPES.DAILY_MOTION;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      dailyMotionData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.DAILY_MOTION;
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { dailyMotionData } = this.state;
    onAdd(dailyMotionData);
  };

  onUrlInputKeyDown = e => {
    const { dailyMotionData } = this.state;
    if (isEnterPressed(e) && dailyMotionData) {
      this.onAdd(dailyMotionData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    if (this.isValidDailyMotionUrl(url)) {
      const oEmbedEndPoint = `${DAILY_MOTION_OEMBED_URL_PREFIX}${encodeURI(url)}`;
      getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.DAILY_MOTION, true)
        .then(dailyMotionData => {
          finishLoading();
          this.setState({ dailyMotionData });
        })
        .catch(() => {
          finishLoading();
          this.setState({ dailyMotionData: null });
        });
    } else {
      finishLoading();
      this.setState({ dailyMotionData: null });
    }
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, dailyMotionData } = this.state;
    if (url === '' || isLoading) {
      return null;
    }
    return dailyMotionData && this.isValidDailyMotionUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: dailyMotionData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidDailyMotionUrl = url => url.includes('dailymotion');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { dailyMotionData, url } = this.state;
    const isAddDisabled = dailyMotionData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_DAILY_MOTION_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const DailyMotionBlock = props => {
  const blockRef = useRef(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const dailyMotionNode = blockRef.current;
    dailyMotionNode.querySelector('iframe').addEventListener('load', () => {
      setLoading(false);
    });
  });

  const embedBlockProps = { ...props, loading, blockRef };
  return <EmbedBlock {...embedBlockProps} />;
};

export function DailyMotionEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <DailyMotionEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const dailyMotionEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<DailyMotionEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<DailyMotionEmbedPanelComponent {...props} />),
  getPluginBlock: props => <DailyMotionBlock {...props} />,
  getPluginOverviewBlock: props => (<DailyMotionEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.DAILY_MOTION,
};
