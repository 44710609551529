import { mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { Logger } from '../../../../core/logger';

const GIPHY_API_SEARCH_URL = 'https://api.giphy.com/v1/gifs/search';
const GIPHY_API_KEY = 'Yihu6kSkeu9f0hjw3PlZjvQrdZBxEITo';

export class GiphyServices {
  static getDefaultConfig() {
    return {
      method: 'GET',
    };
  }

  static filterSearchResponse(response) {
    return {
      data: response.data.map(item => {
        const { id, title, url, images } = item;
        return {
          id,
          title,
          url,
          preview: images.preview_gif,
          original: images.original,
        };
      }),
      pagination: response.pagination,
    };
  }

  static async searchGifs({ searchQuery, limit = 30, offset = 0 }) {
    const url = `${GIPHY_API_SEARCH_URL}?api_key=${GIPHY_API_KEY}&q=${searchQuery}&limit=${limit}&offset=${offset}&rating=G&lang=en`;
    try {
      const response = await mmNetworkService.externalFetch(url, GiphyServices.getDefaultConfig());
      const imagesData = await response.json();
      return GiphyServices.filterSearchResponse(imagesData);
    } catch (e) {
      Logger.info(e);
    }
    return null;
  }
}
