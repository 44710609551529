import { SITE_VARIABLES } from 'core/styles/variables';
import { Types } from '@ftbpro/mm-admin-ui-components';

export interface EmbedStyles {
  embed: Types.StylesObject;
  radioGroup: Types.StylesObject;
  contentEnrichmentBlock: Types.StylesObject;
  divider: Types.StylesObject;
}

export const getStylesObject = (): EmbedStyles => {
  return {
    embed: {
      flex: "0 1 calc(~'100% - 52px')",
      maxHeight: "calc(~'100% - 52px')",
      textAlign: 'center',
      '& > iframe': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
      '& > .fb_iframe_widget, & > .fb_iframe_widget *': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    radioGroup: {
      marginBottom: '16px',
    },
    contentEnrichmentBlock: {
      lineHeight: 'initial',
    },
    divider: {
      margin: `${SITE_VARIABLES.MARGIN.SMALL} 0 ${SITE_VARIABLES.MARGIN.BIG}`,
    },
  };
};
