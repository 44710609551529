import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';

export const IMAGE_VIEW_TYPES = {
  GRID_IMAGE: 'GRID_IMAGE',
  PREVIEW_IMAGE: 'PREVIEW_IMAGE',
};

const getSmallImageUrlFromGettyImageObject = image => image.display_sizes[0].uri;
const getPreviewImageUrlFromGettyImageObject = image => image.display_sizes.find(item => item.name === 'preview').uri;

const getSmallImageUrlFromImageExchangeObject = image => image.sizes.thumbnail.url;
const getPreviewImageUrlFromImageExchangeObject = image => image.url;

const getSmallImageUrlFromGiphyGifObject = image => image.preview.url;
const getPreviewImageUrlFromGiphyGifObject = image => image.original.url;

const getPreviewImageUrlFromImagnImageObject = image => image.previewUrl;

const getPreviewImageUrlFromMediaLibraryEmbed = image => image.url;

const IMAGE_URL_MAP = {
  [BLOCK_TYPES.GETTY]: {
    [IMAGE_VIEW_TYPES.GRID_IMAGE]: getSmallImageUrlFromGettyImageObject,
    [IMAGE_VIEW_TYPES.PREVIEW_IMAGE]: getPreviewImageUrlFromGettyImageObject,
  },
  [BLOCK_TYPES.IMAGE_EXCHANGE]: {
    [IMAGE_VIEW_TYPES.GRID_IMAGE]: getSmallImageUrlFromImageExchangeObject,
    [IMAGE_VIEW_TYPES.PREVIEW_IMAGE]: getPreviewImageUrlFromImageExchangeObject,
  },
  [BLOCK_TYPES.MEDIA_LIBRARY_EMBED]: {
    [IMAGE_VIEW_TYPES.GRID_IMAGE]: getPreviewImageUrlFromMediaLibraryEmbed,
    [IMAGE_VIEW_TYPES.PREVIEW_IMAGE]: getPreviewImageUrlFromMediaLibraryEmbed,
  },
  [BLOCK_TYPES.GIPHY]: {
    [IMAGE_VIEW_TYPES.GRID_IMAGE]: getSmallImageUrlFromGiphyGifObject,
    [IMAGE_VIEW_TYPES.PREVIEW_IMAGE]: getPreviewImageUrlFromGiphyGifObject,
  },
  [BLOCK_TYPES.IMAGN]: {
    [IMAGE_VIEW_TYPES.GRID_IMAGE]: getPreviewImageUrlFromImagnImageObject,
    [IMAGE_VIEW_TYPES.PREVIEW_IMAGE]: getPreviewImageUrlFromImagnImageObject,
  },
};

// TODO: We should rewrite this and make it more robust it's not natural...
export const getUrlFromImageResponseObject = ({ blockType, image, imageViewType }) => {
  return IMAGE_URL_MAP[blockType][imageViewType](image);
};

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};
