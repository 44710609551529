import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { emptyPanelPluginData } from './EmptyPanel';
import { imageFromDrivePluginData } from './imageFromDrive/ImageFromDrive';
import { twitterEmbedPluginData } from './twitter/TwitterEmbed';
import { instagramEmbedPluginData } from './InstagramEmbed';
import { youtubeEmbedPluginData } from './YoutubeEmbed';
import { facebookEmbedPluginData } from './FacebookEmbed';
import { streamableEmbedPluginData } from './StreamableEmbed';
import { excoEmbedPluginData } from './ExcoEmbed';
import { dailyMotionEmbedPluginData } from './DailyMotionEmbed';
import { vimeoEmbedPluginData } from './VimeoEmbed';
import { gettyCreativeEmbedPluginData, gettyEmbedPluginData } from './getty/GettyEmbed';
import { giphyEmbedPluginData } from './giphy/GiphyEmbed';
import { soundcloudEmbedPluginData } from './SoundcloudEmbed';
import { gfycatEmbedPluginData } from './GfycatEmbed';
import { MMPlayerEmbed } from './mmPlayer/MMPlayerEmbed';
import { iframeEmbedPluginData } from './IFrameEmbed';
import { apesterEmbedPluginData } from './ApesterEmbed';
import { cerosEmbedPluginData } from './CerosEmbed';
import { imagnEmbedPluginData } from './imagn/ImagnEmbed';
import { contentEnrichmentEmbedPluginData } from './ContentEnrichmentEmbed';
import { mediaLibraryEmbedPluginData } from './mediaLibraryEmbed/MediaLibraryEmbed';
import { imageExchangePluginData } from './imageExchange/ImageExchange';
import { liveBlogEmbedPluginData } from './LiveBlog/LiveBlogEmbed';
import { crowdSignalEmbedPluginData } from './crowdSignal/CrowdSignalEmbed';

export const ELEMENT_PANEL_PLUGIN_TYPES = {
  EMPTY_PANEL: 'emptyPanel',
  CROP_IMAGE_PANEL: 'cropImagePanel',
  MEDIA_LIBRARY_EMBED_PANEL: 'mediaLibraryEmbedPanel',
  GETTY_EMBED_PANEL: 'gettyEmbedPanel',
  IMAGN_EMBED_PANEL: 'imagnEmbedPanel',
  YOUTUBE_EMBED_PANEL: 'youtubeEmbedPanel',
  IMAGE_EXCHANGE_PANEL: 'imageExchangePanel',
  EMBED_IFRAME_PANEL: 'embedIframePanel',
  TWITTER_EMBED_PANEL: 'twitterEmbedPanel',
  INSTAGRAM_EMBED_PANEL: 'instagramEmbedPanel',
  FACEBOOK_EMBED_PANEL: 'facebookEmbedPanel',
  STREAMABLE_EMBED_PANEL: 'streamableEmbedPanel',
  EXCO_EMBED_PANEL: 'excoEmbedPanel',
  DAILY_MOTION_EMBED_PANEL: 'dailyMotionEmbedPanel',
  VIMEO_EMBED_PANEL: 'vimeoEmbedPluginData',
  GIPHY_EMBED_PANEL: 'giphyEmbedPanel',
  SOUNDCLOUD_EMBED_PANEL: 'soundcloudEmbedPanel',
  GFYCAT_EMBED_PANEL: 'gfycatEmbedPanel',
  MM_PLAYER_EMBED_PANEL: 'mmPlayerEmbedPanel',
  APESTER: 'apesterEmbedPanel',
  CEROS_EMBED_PANEL: 'cerosEmbedPanel',
  IFRAME_EMBED_PANEL: 'iFrameEmbedPanel',
  CONTENT_ENRICHMENT_EMBED_PANEL: 'contentEnrichmentEmbedPanel',
  LIVE_BLOG_EMBED_PANEL: 'liveBlogEmbedPanel',
  GETTY_CREATIVE_EMBED_PANEL: 'gettyCreativeEmbedPanel',
  CROWD_SIGNAL_EMBED_PANEL: 'crowdSignalEmbedPanel',
};

export const ELEMENT_PANEL_PLUGIN_NAMES = {
  [ELEMENT_PANEL_PLUGIN_TYPES.EMPTY_PANEL]: 'Empty panel',
  [ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL]: 'Image Upload',
  [ELEMENT_PANEL_PLUGIN_TYPES.MEDIA_LIBRARY_EMBED_PANEL]: 'Voltax Images',
  [ELEMENT_PANEL_PLUGIN_TYPES.GETTY_EMBED_PANEL]: 'Getty Images',
  [ELEMENT_PANEL_PLUGIN_TYPES.IMAGN_EMBED_PANEL]: ' Imagn',
  [ELEMENT_PANEL_PLUGIN_TYPES.IMAGE_EXCHANGE_PANEL]: 'Image Exchange',
  [ELEMENT_PANEL_PLUGIN_TYPES.YOUTUBE_EMBED_PANEL]: 'YouTube',
  [ELEMENT_PANEL_PLUGIN_TYPES.TWITTER_EMBED_PANEL]: 'Twitter',
  [ELEMENT_PANEL_PLUGIN_TYPES.INSTAGRAM_EMBED_PANEL]: 'Instagram',
  [ELEMENT_PANEL_PLUGIN_TYPES.FACEBOOK_EMBED_PANEL]: 'Facebook',
  [ELEMENT_PANEL_PLUGIN_TYPES.GIPHY_EMBED_PANEL]: 'Giphy',
  [ELEMENT_PANEL_PLUGIN_TYPES.GFYCAT_EMBED_PANEL]: 'Gfycat',
  [ELEMENT_PANEL_PLUGIN_TYPES.MM_PLAYER_EMBED_PANEL]: 'Voltax player',
  [ELEMENT_PANEL_PLUGIN_TYPES.STREAMABLE_EMBED_PANEL]: 'Streamable',
  [ELEMENT_PANEL_PLUGIN_TYPES.EXCO_EMBED_PANEL]: 'Exco',
  [ELEMENT_PANEL_PLUGIN_TYPES.DAILY_MOTION_EMBED_PANEL]: 'Daily Motion',
  [ELEMENT_PANEL_PLUGIN_TYPES.VIMEO_EMBED_PANEL]: 'Vimeo',
  [ELEMENT_PANEL_PLUGIN_TYPES.SOUNDCLOUD_EMBED_PANEL]: 'SoundCloud',
  [ELEMENT_PANEL_PLUGIN_TYPES.APESTER]: 'Apester',
  [ELEMENT_PANEL_PLUGIN_TYPES.CEROS_EMBED_PANEL]: 'Ceros',
  [ELEMENT_PANEL_PLUGIN_TYPES.IFRAME_EMBED_PANEL]: 'iFrame Embed',
  [ELEMENT_PANEL_PLUGIN_TYPES.CONTENT_ENRICHMENT_EMBED_PANEL]: 'Custom Embed',
  [ELEMENT_PANEL_PLUGIN_TYPES.LIVE_BLOG_EMBED_PANEL]: 'Live Blog',
  [ELEMENT_PANEL_PLUGIN_TYPES.CROWD_SIGNAL_EMBED_PANEL]: 'Crowd Signal',
  [ELEMENT_PANEL_PLUGIN_TYPES.GETTY_CREATIVE_EMBED_PANEL]: 'Getty Creative',
};

export const pluginPanelTypesToPluginData = {
  [ELEMENT_PANEL_PLUGIN_TYPES.EMPTY_PANEL]: emptyPanelPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL]: imageFromDrivePluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.MEDIA_LIBRARY_EMBED_PANEL]: mediaLibraryEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.GETTY_EMBED_PANEL]: gettyEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.IMAGN_EMBED_PANEL]: imagnEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.IMAGE_EXCHANGE_PANEL]: imageExchangePluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.TWITTER_EMBED_PANEL]: twitterEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.YOUTUBE_EMBED_PANEL]: youtubeEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.INSTAGRAM_EMBED_PANEL]: instagramEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.FACEBOOK_EMBED_PANEL]: facebookEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.GIPHY_EMBED_PANEL]: giphyEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.GFYCAT_EMBED_PANEL]: gfycatEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.MM_PLAYER_EMBED_PANEL]: MMPlayerEmbed,
  [ELEMENT_PANEL_PLUGIN_TYPES.STREAMABLE_EMBED_PANEL]: streamableEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.EXCO_EMBED_PANEL]: excoEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.DAILY_MOTION_EMBED_PANEL]: dailyMotionEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.VIMEO_EMBED_PANEL]: vimeoEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.SOUNDCLOUD_EMBED_PANEL]: soundcloudEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.APESTER]: apesterEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.CEROS_EMBED_PANEL]: cerosEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.CONTENT_ENRICHMENT_EMBED_PANEL]: contentEnrichmentEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.IFRAME_EMBED_PANEL]: iframeEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.LIVE_BLOG_EMBED_PANEL]: liveBlogEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.CROWD_SIGNAL_EMBED_PANEL]: crowdSignalEmbedPluginData,
  [ELEMENT_PANEL_PLUGIN_TYPES.GETTY_CREATIVE_EMBED_PANEL]: gettyCreativeEmbedPluginData,
};

export const mediaBlockTypeToPluginData = {
  [BLOCK_TYPES.IMAGE]: imageFromDrivePluginData,
  [BLOCK_TYPES.TWITTER]: twitterEmbedPluginData,
  [BLOCK_TYPES.MEDIA_LIBRARY_EMBED]: mediaLibraryEmbedPluginData,
  [BLOCK_TYPES.INSTAGRAM]: instagramEmbedPluginData,
  [BLOCK_TYPES.FACEBOOK]: facebookEmbedPluginData,
  [BLOCK_TYPES.YOUTUBE]: youtubeEmbedPluginData,
  [BLOCK_TYPES.GIPHY]: giphyEmbedPluginData,
  [BLOCK_TYPES.GFYCAT]: gfycatEmbedPluginData,
  [BLOCK_TYPES.MM_PLAYER]: MMPlayerEmbed,
  [BLOCK_TYPES.STREAMABLE]: streamableEmbedPluginData,
  [BLOCK_TYPES.EXCO]: excoEmbedPluginData,
  [BLOCK_TYPES.PLAYBUZZ]: excoEmbedPluginData,
  [BLOCK_TYPES.DAILY_MOTION]: dailyMotionEmbedPluginData,
  [BLOCK_TYPES.VIMEO]: vimeoEmbedPluginData,
  [BLOCK_TYPES.SOUNDCLOUD]: soundcloudEmbedPluginData,
  [BLOCK_TYPES.APESTER]: apesterEmbedPluginData,
  [BLOCK_TYPES.CEROS]: cerosEmbedPluginData,
  [BLOCK_TYPES.IFRAME_EMBED]: iframeEmbedPluginData,
  [BLOCK_TYPES.CONTENT_ENRICHMENT]: contentEnrichmentEmbedPluginData,
  [BLOCK_TYPES.LIVE_BLOG]: liveBlogEmbedPluginData,
  [BLOCK_TYPES.CROWD_SIGNAL]: crowdSignalEmbedPluginData,
};

export const isValidMediaBlock = blockType => !!mediaBlockTypeToPluginData[blockType];

export const getBlockComponent = (blockType, props) => {
  return mediaBlockTypeToPluginData[blockType].getPluginBlock ? mediaBlockTypeToPluginData[blockType].getPluginBlock(props) : null;
};

export const getBlockOverviewComponent = (blockType, props) => {
  return mediaBlockTypeToPluginData[blockType].getPluginOverviewBlock ? mediaBlockTypeToPluginData[blockType].getPluginOverviewBlock(props) : null;
};

export const mediaTypeToPanelType = {
  [youtubeEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.YOUTUBE_EMBED_PANEL,
  [imageFromDrivePluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL,
  [dailyMotionEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.DAILY_MOTION_EMBED_PANEL,
  [vimeoEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.VIMEO_EMBED_PANEL,
  [facebookEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.FACEBOOK_EMBED_PANEL,
  [giphyEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.GIPHY_EMBED_PANEL,
  [gfycatEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.GFYCAT_EMBED_PANEL,
  [MMPlayerEmbed.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.MM_PLAYER_EMBED_PANEL,
  [excoEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.EXCO_EMBED_PANEL,
  [streamableEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.STREAMABLE_EMBED_PANEL,
  [instagramEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.INSTAGRAM_EMBED_PANEL,
  [twitterEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.TWITTER_EMBED_PANEL,
  [soundcloudEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.SOUNDCLOUD_EMBED_PANEL,
  [apesterEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.APESTER,
  [cerosEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.CEROS_EMBED_PANEL,
  [iframeEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.IFRAME_EMBED_PANEL,
  [contentEnrichmentEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.CONTENT_ENRICHMENT_EMBED_PANEL,
  [liveBlogEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.LIVE_BLOG_EMBED_PANEL,
  [crowdSignalEmbedPluginData.pluginBlockType]: ELEMENT_PANEL_PLUGIN_TYPES.CROWD_SIGNAL_EMBED_PANEL,
};

export const PREMIUM_PLUGIN_TYPES = [
  ELEMENT_PANEL_PLUGIN_TYPES.GETTY_EMBED_PANEL,
  ELEMENT_PANEL_PLUGIN_TYPES.IMAGN_EMBED_PANEL,
  ELEMENT_PANEL_PLUGIN_TYPES.CEROS_EMBED_PANEL,
  ELEMENT_PANEL_PLUGIN_TYPES.APESTER,
  ELEMENT_PANEL_PLUGIN_TYPES.CONTENT_ENRICHMENT_EMBED_PANEL,
];
