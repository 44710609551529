import { SITE_VARIABLES } from '../../../../../../core/styles/variables';

export const getStylesObject = (imageLoading, hasImage, imageWidth) => ({
  cropTypeContainer: {
    width: '100%',
    marginBottom: '16px',
  },
  cropAreaHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '16px',
  },
  captionInputWrapper: {
    marginTop: '24px',
  },
  altInputWrapper: {
    marginTop: '24px',
  },
  creditInputWrapper: {
    marginTop: '24px',
  },
  descriptionWrapper: {
    marginBottom: SITE_VARIABLES.MARGIN.BIG,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  imagePickerWrapper: {
    marginBottom: '16px',
  },
  imageRequirementsText: {
    color: SITE_VARIABLES.TEXT_COLOR.HINT,
    marginBottom: '4px',
  },
  reactCropWrapper: {
    /* OVERRIDE REACT CROP PACKAGE STYLES */
    '.ReactCrop__crop-selection': {
      borderImageSlice: 'unset',
      border: `2px solid ${SITE_VARIABLES.COLORS.SELECTED}`,
      '.ReactCrop__drag-handle': {
        background: SITE_VARIABLES.COLORS.SELECTED,
        width: '6px',
        height: '6px',
      },
      '.ord-ne, .ord-nw': {
        top: '3px',
      },
      '.ord-ne, .ord-se': {
        right: '3px',
      },
      '.ord-se, .ord-sw': {
        bottom: '3px',
      },
      '.ord-nw, .ord-sw': {
        left: '3px',
      },
      '.ord-ne:after, .ord-nw:after, .ord-se:after, .ord-sw:after': {
        display: 'none',
      },
    },
    '.ReactCrop__drag-handle': {
      border: 'none',
    },
  },
  image: {
    maxHeight: '100%',
  },
  reactCrop: {
    backgroundColor: 'transparent',
    display: imageLoading || !hasImage ? 'none' : 'block',
    width: imageWidth,
  },
});

export const radioVariables = {
  radioItemHorizontalMargin: '30px',
};
