import { SITE_VARIABLES } from '../../../../../core/styles/variables';

const getVideoHolderBorderColor = ({ hasError, isSelected, isPreviewVideo }) => {
  if (hasError) {
    return SITE_VARIABLES.COLORS.ERROR;
  }
  return isSelected || isPreviewVideo ? SITE_VARIABLES.COLORS.SELECTED : SITE_VARIABLES.COLORS.ACTIVE;
};

export const getStylesObject = ({
  columns, loading, isPreviewVideo, isSelected, hasError,
}) => ({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    padding: '0',
    margin: '-8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'inherit',
    alignItems: 'stretch',
    padding: '5px 0',
    flex: '1 0 100%',
  },
  column: {
    padding: `${columns === 1 ? '0' : '0 5px'}`,
    marginTop: '0',
    marginBottom: '0',
    flex: `0 0 ${columns === 1 ? '100%' : '33.33%'}`,
  },
  videoHolder: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: SITE_VARIABLES.PADDING.EXTRA_SMALL,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: getVideoHolderBorderColor({ hasError, isSelected, isPreviewVideo }),
    borderRadius: SITE_VARIABLES.BORDER_RADIUS.TINY,
    cursor: 'pointer',
    '&:hover': {
      borderColor: hasError ? SITE_VARIABLES.COLORS.ERROR : SITE_VARIABLES.COLORS.SELECTED,
    },
  },
  infoHolder: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: '1',
    paddingLeft: '10px',
  },
  videoTitle: {
    marginBottom: SITE_VARIABLES.MARGIN.TINY,
  },
  videoDate: {
    color: SITE_VARIABLES.TEXT_COLOR.ACTIVE,
    marginBottom: SITE_VARIABLES.MARGIN.TINY,
  },
  videoDescription: {
    // margin-top to enable justify self to bottom
    marginTop: 'auto',
    justifySelf: 'flex-end',
  },
  imageHolder: {
    cursor: 'pointer',
    height: isPreviewVideo ? 'auto' : '90px',
    width: isPreviewVideo ? '100%' : '160px',
    minHeight: isPreviewVideo ? '248px' : 'auto',
    flex: '0 0 auto',
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      display: isSelected ? 'block' : 'none',
      background: SITE_VARIABLES.COLORS.SELECTED,
      opacity: '.5',
    },
  },
  imageItem: {
    display: loading ? 'none' : 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  placeholder: {
    height: isPreviewVideo ? '300px' : '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
});
