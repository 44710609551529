import {
  serializeSlateValueToHtmlString,
  deserializeHtmlToSlateValue,
} from './slate/slateSerializer.utils';
import { isEmptySlateParagraph, isSlateValueEmpty } from './slate/slateValue.utils';
import {
  BLOCK_TYPES,
  generateNewBlockDescriptor,
} from './blocksDescriptorGenerator';
import { BLOCK_ADDER_TYPES } from '../components/blockList/blocklist.constants';
import { isValidMediaBlock } from '../plugins/pluginConfiguration';
import { IMAGE_SIZE_TYPES } from '../../constants/image.constants';
import {
  EMPTY_SLATE_VALUE,
} from './slate/slate.constants';

const BREAK_LINE_TAG = '<br/>';
export const BLOCK_METADATA_VALUE_TYPES = {
  TEXT: 'text',
  HTML: 'html',
  URL: 'url',
  COLLECTION: 'collection',
  OBJECT: 'object',
  TABLE: 'table',
};

function createValidTypedField(fieldName, fieldType, fieldValue) {
  return fieldValue ? {
    [fieldName]: {
      type: fieldType,
      value: fieldValue,
    },
  } : null;
}

function addBlockIdIfExists(blockKey) {
  return blockKey ? { id: blockKey } : {};
}

function serializeTextValueToTextDataItem({ value, key }) {
  return {
    type: BLOCK_TYPES.TEXT,
    metadata: {
      ...createValidTypedField('html', BLOCK_METADATA_VALUE_TYPES.HTML, serializeSlateValueToHtmlString(value)),
    },
    id: key,
  };
}

function serializeTitleValueToTitleDataItem({ value: title, key }) {
  return {
    type: BLOCK_TYPES.TITLE,
    metadata: {
      text: {
        type: BLOCK_METADATA_VALUE_TYPES.TEXT,
        value: title.text,
      },
      listItemNumber: {
        type: BLOCK_METADATA_VALUE_TYPES.TEXT,
        value: `${title.listItemNumber}`,
      },
    },
    id: key,
  };
}

function serializeQuoteValueToQuoteItem({ key, value }) {
  return {
    type: BLOCK_TYPES.QUOTE,
    metadata: {
      ...createValidTypedField('text', BLOCK_METADATA_VALUE_TYPES.HTML, value.text.split('\n').join(BREAK_LINE_TAG)),
      cite: {
        type: BLOCK_METADATA_VALUE_TYPES.TEXT,
        value: value.cite,
      },
    },
    id: key,
  };
}

function serializeTableOfContentsValueToTableOfContentsItem({ key, value }) {
  return {
    type: BLOCK_TYPES.TABLE_OF_CONTENTS,
    metadata: {
      title: {
        type: BLOCK_METADATA_VALUE_TYPES.TEXT,
        value: value.title,
      },
    },
    id: key,
  };
}

function serializeImportedTableValueToImportedTableDataItem({ value, key }) {
  return {
    id: key,
    type: BLOCK_TYPES.IMPORTED_TABLE,
    metadata: {
      html: {
        type: BLOCK_METADATA_VALUE_TYPES.HTML,
        value: value.html.value,
      },
    },
  };
}

function serializeImportedContentValueToImportedContentItem({ value, key }) {
  return {
    id: key,
    type: BLOCK_TYPES.IMPORTED_EMBEDDED_CONTENT,
    metadata: value,
  };
}

function serializeImageToImageDataItem(image, blockKey) {
  return {
    type: BLOCK_TYPES.IMAGE,
    metadata: {
      ...createValidTypedField('url', BLOCK_METADATA_VALUE_TYPES.URL, image.url),
      ...createValidTypedField('path', BLOCK_METADATA_VALUE_TYPES.TEXT, image.path),
      ...createValidTypedField('fileExtension', BLOCK_METADATA_VALUE_TYPES.TEXT, image.fileExtension),
      ...createValidTypedField('fullImageUrl', BLOCK_METADATA_VALUE_TYPES.URL, image.fullImageUrl),
      ...createValidTypedField('fullImageHtml', BLOCK_METADATA_VALUE_TYPES.HTML, image.fullImageHtml),
      ...createValidTypedField('host', BLOCK_METADATA_VALUE_TYPES.TEXT, image.host),
      ...createValidTypedField('caption', BLOCK_METADATA_VALUE_TYPES.TEXT, image.caption),
      ...createValidTypedField('description', BLOCK_METADATA_VALUE_TYPES.TEXT, image.description),
      ...createValidTypedField('provider', BLOCK_METADATA_VALUE_TYPES.TEXT, image.provider),
      ...createValidTypedField('credit', BLOCK_METADATA_VALUE_TYPES.HTML, image.credit),
      ...createValidTypedField('alt', BLOCK_METADATA_VALUE_TYPES.TEXT, image.alt),
      ...createValidTypedField('aspectRatio', BLOCK_METADATA_VALUE_TYPES.TEXT, image.aspectRatio),
      ...createValidTypedField('sizeType', BLOCK_METADATA_VALUE_TYPES.TEXT, image.sizeType),
      ...createValidTypedField('linkURL', BLOCK_METADATA_VALUE_TYPES.TEXT, image.linkURL),
      ...createValidTypedField('linkTargetAttribute', BLOCK_METADATA_VALUE_TYPES.TEXT, image.linkTargetAttribute),
      ...createValidTypedField('linkRelAttribute', BLOCK_METADATA_VALUE_TYPES.TEXT, image.linkRelAttribute),
      ...createValidTypedField('cropping', BLOCK_METADATA_VALUE_TYPES.OBJECT, image.cropping),
    },
    ...addBlockIdIfExists(blockKey),
  };
}

function serializeGifToGifDataItem({ key, value: image }) {
  return {
    type: BLOCK_TYPES.GIPHY,
    metadata: {
      ...createValidTypedField('url', BLOCK_METADATA_VALUE_TYPES.URL, image.url),
      ...createValidTypedField('path', BLOCK_METADATA_VALUE_TYPES.TEXT, image.path),
      ...createValidTypedField('caption', BLOCK_METADATA_VALUE_TYPES.TEXT, image.caption),
      ...createValidTypedField('description', BLOCK_METADATA_VALUE_TYPES.TEXT, image.description),
      ...createValidTypedField('provider', BLOCK_METADATA_VALUE_TYPES.TEXT, image.provider),
      ...createValidTypedField('credit', BLOCK_METADATA_VALUE_TYPES.TEXT, image.credit),
      ...createValidTypedField('width', BLOCK_METADATA_VALUE_TYPES.TEXT, image.width),
      ...createValidTypedField('height', BLOCK_METADATA_VALUE_TYPES.TEXT, image.height),
    },
    id: key,
  };
}

function serializeEmbedBlockToEmbedDataItem(value, type, blockKey) {
  return {
    type,
    metadata: {
      ...createValidTypedField('type', BLOCK_METADATA_VALUE_TYPES.TEXT, value.type),
      ...createValidTypedField('width', BLOCK_METADATA_VALUE_TYPES.TEXT, value.width),
      ...createValidTypedField('height', BLOCK_METADATA_VALUE_TYPES.TEXT, value.height),
      ...createValidTypedField('html', BLOCK_METADATA_VALUE_TYPES.HTML, value.html),
      ...createValidTypedField('url', BLOCK_METADATA_VALUE_TYPES.URL, value.url),
      ...createValidTypedField('thumbnail', BLOCK_METADATA_VALUE_TYPES.URL, value.thumbnail),
      ...createValidTypedField('originalEmbedUrl', BLOCK_METADATA_VALUE_TYPES.URL, value.originalEmbedUrl),
      ...createValidTypedField('caption', BLOCK_METADATA_VALUE_TYPES.TEXT, value.caption),
      ...createValidTypedField('description', BLOCK_METADATA_VALUE_TYPES.TEXT, value.description),
      ...createValidTypedField('provider', BLOCK_METADATA_VALUE_TYPES.TEXT, value.provider),
      ...createValidTypedField('credit', BLOCK_METADATA_VALUE_TYPES.TEXT, value.credit),
      ...createValidTypedField('mediaId', BLOCK_METADATA_VALUE_TYPES.TEXT, value.mediaId),
      ...createValidTypedField('embedType', BLOCK_METADATA_VALUE_TYPES.TEXT, value.embedType),
      ...createValidTypedField('rawHTML', BLOCK_METADATA_VALUE_TYPES.HTML, value.rawHTML),
    },
    ...addBlockIdIfExists(blockKey),
  };
}

function serializeMMPlayerItemToDataItem(value, blockKey) {
  return {
    type: BLOCK_TYPES.MM_PLAYER,
    metadata: {
      ...createValidTypedField('html', BLOCK_METADATA_VALUE_TYPES.HTML, value.html),
      ...createValidTypedField('previewHtml', BLOCK_METADATA_VALUE_TYPES.HTML, value.previewHtml),
      ...createValidTypedField('duration', BLOCK_METADATA_VALUE_TYPES.HTML, value.duration),
      ...createValidTypedField('mediaId', BLOCK_METADATA_VALUE_TYPES.TEXT, value.mediaId),
      ...createValidTypedField('playerId', BLOCK_METADATA_VALUE_TYPES.TEXT, value.playerId),
      ...createValidTypedField('thumbnail', BLOCK_METADATA_VALUE_TYPES.URL, value.thumbnailUrl),
      ...createValidTypedField('type', BLOCK_METADATA_VALUE_TYPES.TEXT, value.type),
      ...createValidTypedField('caption', BLOCK_METADATA_VALUE_TYPES.TEXT, value.caption),
      ...createValidTypedField('duration', BLOCK_METADATA_VALUE_TYPES.TEXT, value.duration),
      ...createValidTypedField('url', BLOCK_METADATA_VALUE_TYPES.URL, value.url),
      ...createValidTypedField('creationDate', BLOCK_METADATA_VALUE_TYPES.TEXT, value.creationDate && value.creationDate.toString()),
      ...createValidTypedField('credit', BLOCK_METADATA_VALUE_TYPES.TEXT, value.credit),
      ...createValidTypedField('videoProvider', BLOCK_METADATA_VALUE_TYPES.TEXT, value.videoProvider),
      ...createValidTypedField('version', BLOCK_METADATA_VALUE_TYPES.TEXT, value.version),
    },
    ...addBlockIdIfExists(blockKey),
  };
}

function serializeIframeItemToDataItem({ value, key }) {
  return {
    type: BLOCK_TYPES.IFRAME_EMBED,
    metaData: {
      ...createValidTypedField('embedCodeHTMLString', BLOCK_METADATA_VALUE_TYPES.HTML, value.embedCodeHTMLString),
      ...createValidTypedField('src', BLOCK_METADATA_VALUE_TYPES.URL, value.src),
      ...createValidTypedField('height', BLOCK_METADATA_VALUE_TYPES.TEXT, value.height),
    },
    id: key,
  };
}

function serializeLiveBlogItemToDataItem({ value, key }) {
  return {
    type: BLOCK_TYPES.LIVE_BLOG,
    metaData: {
      ...createValidTypedField('embedCodeHTMLString', BLOCK_METADATA_VALUE_TYPES.HTML, value.embedCodeHTMLString),
      ...createValidTypedField('html', BLOCK_METADATA_VALUE_TYPES.HTML, value.html),
      ...createValidTypedField('eventId', BLOCK_METADATA_VALUE_TYPES.TEXT, value.eventId),
      ...createValidTypedField('provider', BLOCK_METADATA_VALUE_TYPES.TEXT, value.provider),
    },
    id: key,
  };
}

function serializeMediaItemToCoverDataItem(block) {
  return block.type === BLOCK_TYPES.MM_PLAYER ? serializeMMPlayerItemToDataItem(block.value) : serializeEmbedBlockToEmbedDataItem(block.value, block.type);
}

function serializeTableValueToTableDataItem({ value, key }) {
  const formatValue = value.map(row => row.map(cell => {
    if (!cell.length) {
      return '<p></p>';
    }

    const { html } = createValidTypedField(
      'html',
      BLOCK_METADATA_VALUE_TYPES.HTML,
      serializeSlateValueToHtmlString(JSON.parse(cell)),
    );

    return html.value;
  }));

  return {
    type: BLOCK_TYPES.TABLE,
    metadata: {
      data: {
        type: BLOCK_METADATA_VALUE_TYPES.TABLE,
        value: formatValue,
      },
    },
    id: key,
  };
}

const serializeQuestionValueToQuestionItem = ({ value, key }) => {
  return {
    id: key,
    type: BLOCK_TYPES.QUESTION,
    metadata: {
      text: {
        type: BLOCK_METADATA_VALUE_TYPES.TEXT,
        value: value.text,
      },
    },
  };
};

const serializeImportedVideoValueToImportedVideoItem = ({ value, key }) => {
  return {
    id: key,
    type: BLOCK_TYPES.IMPORTED_VIDEO,
    metadata: {
      html: {
        type: BLOCK_METADATA_VALUE_TYPES.HTML,
        value: value.html,
      },
    },
  };
};

const serializeRawHtmlValueToItem = ({ value, key }) => {
  return {
    id: key,
    type: BLOCK_TYPES.RAW_HTML,
    metadata: {
      html: {
        type: BLOCK_METADATA_VALUE_TYPES.HTML,
        value: value.html,
      },
    },
  };
};

function getValueFromFieldWithFallback(itemMetadata, fieldName, fallbackValue = '') {
  return itemMetadata[fieldName] ? itemMetadata[fieldName].value : fallbackValue;
}

function getCroppingValueFromFieldWithFallback(itemMetadata, fieldName) {
  return itemMetadata[fieldName] ? itemMetadata[fieldName].value : null;
}

function serializeListItemBlockToListItemDataItem(groupValue) {
  const { items } = groupValue.value;

  const filteredItems = items.filter(item => {
    const isItemBlockAdder = item.type === BLOCK_TYPES.ADDER;
    const isItemEmptyTextBlock = item.type === BLOCK_TYPES.TEXT && isEmptySlateParagraph(item.value);
    return !isItemBlockAdder && !isItemEmptyTextBlock;
  });

  return {
    type: BLOCK_TYPES.LIST_ITEM,
    metadata: {
      listItemNumber: {
        type: BLOCK_METADATA_VALUE_TYPES.TEXT,
        value: `${groupValue.value.listItemNumber}`,
      },
      items: {
        type: BLOCK_METADATA_VALUE_TYPES.COLLECTION,
        value: filteredItems.map(block => formatBlockForSave(block)), // eslint-disable-line
      },
    },
    id: groupValue.key,
  };
}

const serializeFAQBlockToFAQDataItem = groupValue => {
  const { items } = groupValue.value;
  const filteredItems = items.filter(item => !(item.type === BLOCK_TYPES.TEXT && isEmptySlateParagraph(item.value)));

  return {
    id: groupValue.key,
    type: BLOCK_TYPES.FAQ,
    metadata: {
      items: {
        type: BLOCK_METADATA_VALUE_TYPES.COLLECTION,
        value: filteredItems.map(block => formatBlockForSave(block)), // eslint-disable-line
      },
    },

  };
};

function deserializeHtmlToTextBlockData(itemMetadata, id) {
  if (!itemMetadata.html) {
    return null;
  }
  const blockValue = deserializeHtmlToSlateValue(itemMetadata.html.value);
  return generateNewBlockDescriptor(BLOCK_TYPES.TEXT, blockValue, id);
}

function deserializeItemToImportedTableItem(itemMetadata, id) {
  return generateNewBlockDescriptor(BLOCK_TYPES.IMPORTED_TABLE, itemMetadata, id);
}

function deserializeItemToImportedContentItem(itemMetadata, id) {
  return generateNewBlockDescriptor(BLOCK_TYPES.IMPORTED_EMBEDDED_CONTENT, itemMetadata, id);
}

export function deserializeItemToGifObject(itemMetadata) {
  return {
    url: getValueFromFieldWithFallback(itemMetadata, 'url'),
    path: getValueFromFieldWithFallback(itemMetadata, 'path'),
    caption: getValueFromFieldWithFallback(itemMetadata, 'caption'),
    description: getValueFromFieldWithFallback(itemMetadata, 'description'),
    provider: getValueFromFieldWithFallback(itemMetadata, 'provider'),
    credit: getValueFromFieldWithFallback(itemMetadata, 'credit'),
    width: getValueFromFieldWithFallback(itemMetadata, 'width'),
    height: getValueFromFieldWithFallback(itemMetadata, 'height'),
  };
}

export function deserializeItemToImageObject(itemMetadata) {
  return {
    url: getValueFromFieldWithFallback(itemMetadata, 'url'),
    path: getValueFromFieldWithFallback(itemMetadata, 'path'),
    fileExtension: getValueFromFieldWithFallback(itemMetadata, 'fileExtension'),
    fullImageUrl: getValueFromFieldWithFallback(itemMetadata, 'fullImageUrl'),
    fullImageHtml: getValueFromFieldWithFallback(itemMetadata, 'fullImageHtml'),
    host: getValueFromFieldWithFallback(itemMetadata, 'host'),
    caption: getValueFromFieldWithFallback(itemMetadata, 'caption'),
    description: getValueFromFieldWithFallback(itemMetadata, 'description'),
    provider: getValueFromFieldWithFallback(itemMetadata, 'provider'),
    credit: getValueFromFieldWithFallback(itemMetadata, 'credit'),
    alt: getValueFromFieldWithFallback(itemMetadata, 'alt'),
    aspectRatio: getValueFromFieldWithFallback(itemMetadata, 'aspectRatio'),
    sizeType: getValueFromFieldWithFallback(itemMetadata, 'sizeType', IMAGE_SIZE_TYPES.REGULAR),
    linkURL: getValueFromFieldWithFallback(itemMetadata, 'linkURL'),
    linkTargetAttribute: getValueFromFieldWithFallback(itemMetadata, 'linkTargetAttribute'),
    linkRelAttribute: getValueFromFieldWithFallback(itemMetadata, 'linkRelAttribute'),
    cropping: getCroppingValueFromFieldWithFallback(itemMetadata, 'cropping'),
  };
}

function deserializeTitleToTitleObject(itemMetadata) {
  return {
    text: getValueFromFieldWithFallback(itemMetadata, 'text'),
    listItemNumber: parseInt(getValueFromFieldWithFallback(itemMetadata, 'listItemNumber'), 10),
  };
}

function deserializeItemToQuoteObject(itemMetadata) {
  return {
    text: getValueFromFieldWithFallback(itemMetadata, 'text').split(BREAK_LINE_TAG).join('\n'),
    cite: getValueFromFieldWithFallback(itemMetadata, 'cite'),
  };
}

function deserializeItemToTableOfContentsObject(itemMetadata) {
  return {
    title: getValueFromFieldWithFallback(itemMetadata, 'title'),
  };
}

function deserializeItemToQuestionObject(itemMetadata) {
  return {
    text: getValueFromFieldWithFallback(itemMetadata, 'text'),
  };
}

function deserializeItemToImportedVideoObject(itemMetadata) {
  return {
    html: getValueFromFieldWithFallback(itemMetadata, 'html'),
  };
}

function deserializeItemToRawHtmlObject(itemMetadata) {
  return {
    html: getValueFromFieldWithFallback(itemMetadata, 'html'),
  };
}

function deserializeItemToEmbedObject(itemMetadata) {
  return {
    type: getValueFromFieldWithFallback(itemMetadata, 'type'),
    width: getValueFromFieldWithFallback(itemMetadata, 'width'),
    height: getValueFromFieldWithFallback(itemMetadata, 'height'),
    url: getValueFromFieldWithFallback(itemMetadata, 'url'),
    html: getValueFromFieldWithFallback(itemMetadata, 'html'),
    thumbnail: getValueFromFieldWithFallback(itemMetadata, 'thumbnail'),
    originalEmbedUrl: getValueFromFieldWithFallback(itemMetadata, 'originalEmbedUrl'),
    caption: getValueFromFieldWithFallback(itemMetadata, 'caption'),
    description: getValueFromFieldWithFallback(itemMetadata, 'description'),
    provider: getValueFromFieldWithFallback(itemMetadata, 'provider'),
    credit: getValueFromFieldWithFallback(itemMetadata, 'credit'),
    mediaId: getValueFromFieldWithFallback(itemMetadata, 'mediaId'),
    embedType: getValueFromFieldWithFallback(itemMetadata, 'embedType'),
    rawHTML: getValueFromFieldWithFallback(itemMetadata, 'rawHTML'),
  };
}

function deserializeItemToMMPlayerObject(itemMetaData) {
  return {
    html: getValueFromFieldWithFallback(itemMetaData, 'html'),
    previewHtml: getValueFromFieldWithFallback(itemMetaData, 'previewHtml'),
    mediaId: getValueFromFieldWithFallback(itemMetaData, 'mediaId'),
    playerId: getValueFromFieldWithFallback(itemMetaData, 'playerId'),
    thumbnailUrl: getValueFromFieldWithFallback(itemMetaData, 'thumbnail'),
    type: getValueFromFieldWithFallback(itemMetaData, 'type'),
    caption: getValueFromFieldWithFallback(itemMetaData, 'caption'),
    duration: getValueFromFieldWithFallback(itemMetaData, 'duration'),
    url: getValueFromFieldWithFallback(itemMetaData, 'url'),
    creationDate: getValueFromFieldWithFallback(itemMetaData, 'creationDate'),
    credit: getValueFromFieldWithFallback(itemMetaData, 'credit'),
    videoProvider: getValueFromFieldWithFallback(itemMetaData, 'videoProvider'),
    version: getValueFromFieldWithFallback(itemMetaData, 'version'),
  };
}

function deserializeItemToIframeEmbedObject(itemMetaData) {
  return {
    embedCodeHTMLString: getValueFromFieldWithFallback(itemMetaData, 'embedCodeHTMLString'),
    src: getValueFromFieldWithFallback(itemMetaData, 'src'),
    height: getValueFromFieldWithFallback(itemMetaData, 'height'),
  };
}

function deserializeItemToLiveBlogEmbedObject(itemMetaData) {
  return {
    embedCodeHTMLString: getValueFromFieldWithFallback(itemMetaData, 'embedCodeHTMLString'),
    html: getValueFromFieldWithFallback(itemMetaData, 'html'),
    eventId: getValueFromFieldWithFallback(itemMetaData, 'eventId'),
    provider: getValueFromFieldWithFallback(itemMetaData, 'provider'),
  };
}

function deserializeItemToTableObject(itemMetaData) {
  return itemMetaData.data.value.map(row => row.map(cell => JSON.stringify(deserializeHtmlToSlateValue(cell))));
}

function deserializeItemToListItemObject(itemMetadata) {
  const hasMediaBlock = !!itemMetadata.items.value.find(item => isValidMediaBlock(item.type));
  const hasTextBlock = !!itemMetadata.items.value.find(item => item.type === BLOCK_TYPES.TEXT);
  const items = itemMetadata.items.value ? itemMetadata.items.value.map(item => formatItemToBlock(item)) : null; // eslint-disable-line
  if (!hasMediaBlock) {
    items.splice(1, 0, (generateNewBlockDescriptor(BLOCK_TYPES.ADDER, BLOCK_ADDER_TYPES.GROUPED_PLACEHOLDER)));
  }
  if (!hasTextBlock) {
    items.splice(2, 0, (generateNewBlockDescriptor(BLOCK_TYPES.TEXT, EMPTY_SLATE_VALUE)));
  }
  return {
    listItemNumber: getValueFromFieldWithFallback(itemMetadata, 'listItemNumber'),
    items,
  };
}

function deserializeItemToFAQObject(itemMetadata) {
  const hasTextBlock = !!itemMetadata.items.value.find(item => item.type === BLOCK_TYPES.TEXT);
  const items = itemMetadata.items.value ? itemMetadata.items.value.map(item => formatItemToBlock(item)) : null; // eslint-disable-line
  if (!hasTextBlock) {
    items.splice(2, 0, (generateNewBlockDescriptor(BLOCK_TYPES.TEXT, EMPTY_SLATE_VALUE)));
  }
  return {
    items,
  };
}

function deserializeItemToImageBlock(itemMetadata, id) {
  const blockValue = deserializeItemToImageObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.IMAGE, blockValue, id);
}

function deserializeItemToGifBlock(itemMetadata, id) {
  const blockValue = deserializeItemToGifObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.GIPHY, blockValue, id);
}

function deserializeItemToEmbedBlock(blockType, itemMetaData, id) {
  const blockValue = deserializeItemToEmbedObject(itemMetaData);
  return generateNewBlockDescriptor(blockType, blockValue, id);
}
function deserializeTitleToTitleBlock(itemMetadata, id) {
  const blockValue = deserializeTitleToTitleObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.TITLE, blockValue, id);
}

function deserializeItemToQuestionBlock(itemMetadata, id) {
  const blockValue = deserializeItemToQuestionObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.QUESTION, blockValue, id);
}

function deserializeItemToImportedVideoBlock(itemMetadata, id) {
  const blockValue = deserializeItemToImportedVideoObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.IMPORTED_VIDEO, blockValue, id);
}

function deserializeItemToRawHtmlBlock(itemMetadata, id) {
  const blockValue = deserializeItemToRawHtmlObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.RAW_HTML, blockValue, id);
}

function deserializeItemToQuoteBlock(itemMetadata, id) {
  const blockValue = deserializeItemToQuoteObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.QUOTE, blockValue, id);
}

function deserializeListItemToListItemBlock(blockType, itemMetaData, id) {
  const blockValue = deserializeItemToListItemObject(itemMetaData);
  return generateNewBlockDescriptor(blockType, blockValue, id);
}

function deserializeItemToFAQBlock(itemMetaData, id) {
  const blockValue = deserializeItemToFAQObject(itemMetaData);
  return generateNewBlockDescriptor(BLOCK_TYPES.FAQ, blockValue, id);
}

function deserializeItemToMMPlayerBlock(itemMetaData, id) {
  const blockValue = deserializeItemToMMPlayerObject(itemMetaData);
  return generateNewBlockDescriptor(BLOCK_TYPES.MM_PLAYER, blockValue, id);
}

function deserializeItemToIframeEmbedBlock(itemMetaData, id) {
  const blockValue = deserializeItemToIframeEmbedObject(itemMetaData);
  return generateNewBlockDescriptor(BLOCK_TYPES.IFRAME_EMBED, blockValue, id);
}

function deserializeItemToLiveBlogEmbedBlock(itemMetaData, id) {
  const blockValue = deserializeItemToLiveBlogEmbedObject(itemMetaData);
  return generateNewBlockDescriptor(BLOCK_TYPES.LIVE_BLOG, blockValue, id);
}

function deserializeItemToTableBlock(itemMetaData, id) {
  const blockValue = deserializeItemToTableObject(itemMetaData);

  return generateNewBlockDescriptor(BLOCK_TYPES.TABLE, blockValue, id);
}

function deserializeItemToTableOfContentsBlock(itemMetadata, id) {
  const blockValue = deserializeItemToTableOfContentsObject(itemMetadata);
  return generateNewBlockDescriptor(BLOCK_TYPES.TABLE_OF_CONTENTS, blockValue, id);
}

function formatBlockForSave(block) {
  switch (block.type) {
    case BLOCK_TYPES.TEXT: {
      return serializeTextValueToTextDataItem(block);
    }
    case BLOCK_TYPES.IMAGE: {
      return serializeImageToImageDataItem(block.value, block.key);
    }
    case BLOCK_TYPES.TWITTER: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.INSTAGRAM: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.FACEBOOK: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.APESTER: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.YOUTUBE: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.GFYCAT: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.STREAMABLE: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.EXCO: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.CEROS: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.PLAYBUZZ: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.DAILY_MOTION: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.SOUNDCLOUD: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.VIMEO: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.CONTENT_ENRICHMENT: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.CROWD_SIGNAL: {
      return serializeEmbedBlockToEmbedDataItem(block.value, block.type, block.key);
    }
    case BLOCK_TYPES.DIVIDER:
    case BLOCK_TYPES.PAGE_BREAK: {
      return {
        type: block.type,
        id: block.key,
      };
    }
    case BLOCK_TYPES.LIST_ITEM: {
      return serializeListItemBlockToListItemDataItem(block);
    }
    case BLOCK_TYPES.TITLE: {
      return serializeTitleValueToTitleDataItem(block);
    }
    case BLOCK_TYPES.GIPHY: {
      return serializeGifToGifDataItem(block);
    }
    case BLOCK_TYPES.QUOTE: {
      return serializeQuoteValueToQuoteItem(block);
    }
    case BLOCK_TYPES.MM_PLAYER: {
      return serializeMMPlayerItemToDataItem(block.value, block.key);
    }
    case BLOCK_TYPES.IFRAME_EMBED: {
      return serializeIframeItemToDataItem(block);
    }
    case BLOCK_TYPES.LIVE_BLOG: {
      return serializeLiveBlogItemToDataItem(block);
    }
    case BLOCK_TYPES.TABLE: {
      return serializeTableValueToTableDataItem(block);
    }
    case BLOCK_TYPES.TABLE_OF_CONTENTS: {
      return serializeTableOfContentsValueToTableOfContentsItem(block);
    }
    case BLOCK_TYPES.IMPORTED_TABLE: {
      return serializeImportedTableValueToImportedTableDataItem(block);
    }
    case BLOCK_TYPES.IMPORTED_EMBEDDED_CONTENT: {
      return serializeImportedContentValueToImportedContentItem(block);
    }
    case BLOCK_TYPES.FAQ: {
      return serializeFAQBlockToFAQDataItem(block);
    }
    case BLOCK_TYPES.QUESTION: {
      return serializeQuestionValueToQuestionItem(block);
    }
    case BLOCK_TYPES.IMPORTED_VIDEO: {
      return serializeImportedVideoValueToImportedVideoItem(block);
    }
    case BLOCK_TYPES.RAW_HTML: {
      return serializeRawHtmlValueToItem(block);
    }
    default:
      return block;
  }
}

function formatItemToBlock(item) {
  const { type, metadata, id } = item;
  switch (type) {
    case BLOCK_TYPES.TEXT: {
      return deserializeHtmlToTextBlockData(metadata, id);
    }
    case BLOCK_TYPES.IMAGE: {
      return deserializeItemToImageBlock(metadata, id);
    }
    case BLOCK_TYPES.TWITTER: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.TWITTER, metadata, id);
    }
    case BLOCK_TYPES.INSTAGRAM: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.INSTAGRAM, metadata, id);
    }
    case BLOCK_TYPES.YOUTUBE: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.YOUTUBE, metadata, id);
    }
    case BLOCK_TYPES.GFYCAT: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.GFYCAT, metadata, id);
    }
    case BLOCK_TYPES.FACEBOOK: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.FACEBOOK, metadata, id);
    }
    case BLOCK_TYPES.APESTER: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.APESTER, metadata, id);
    }
    case BLOCK_TYPES.STREAMABLE: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.STREAMABLE, metadata, id);
    }
    case BLOCK_TYPES.EXCO: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.EXCO, metadata, id);
    }
    case BLOCK_TYPES.PLAYBUZZ: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.PLAYBUZZ, metadata, id);
    }
    case BLOCK_TYPES.DAILY_MOTION: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.DAILY_MOTION, metadata, id);
    }
    case BLOCK_TYPES.SOUNDCLOUD: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.SOUNDCLOUD, metadata, id);
    }
    case BLOCK_TYPES.VIMEO: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.VIMEO, metadata, id);
    }
    case BLOCK_TYPES.CONTENT_ENRICHMENT: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.CONTENT_ENRICHMENT, metadata, id);
    }
    case BLOCK_TYPES.DIVIDER:
    case BLOCK_TYPES.PAGE_BREAK: {
      return generateNewBlockDescriptor(type, null, id);
    }
    case BLOCK_TYPES.LIST_ITEM: {
      return deserializeListItemToListItemBlock(BLOCK_TYPES.LIST_ITEM, metadata, id);
    }
    case BLOCK_TYPES.TITLE: {
      return deserializeTitleToTitleBlock(metadata, id);
    }
    case BLOCK_TYPES.GIPHY: {
      return deserializeItemToGifBlock(metadata, id);
    }
    case BLOCK_TYPES.MM_PLAYER: {
      return deserializeItemToMMPlayerBlock(metadata, id);
    }
    case BLOCK_TYPES.CEROS: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.CEROS, metadata, id);
    }
    case BLOCK_TYPES.QUOTE: {
      return deserializeItemToQuoteBlock(metadata, id);
    }
    case BLOCK_TYPES.IFRAME_EMBED: {
      return deserializeItemToIframeEmbedBlock(metadata, id);
    }
    case BLOCK_TYPES.LIVE_BLOG: {
      return deserializeItemToLiveBlogEmbedBlock(metadata, id);
    }
    case BLOCK_TYPES.IMPORTED_EMBEDDED_CONTENT: {
      return deserializeItemToImportedContentItem(metadata, id);
    }
    case BLOCK_TYPES.IMPORTED_TABLE: {
      return deserializeItemToImportedTableItem(metadata, id);
    }
    case BLOCK_TYPES.TABLE: {
      return deserializeItemToTableBlock(metadata, id);
    }
    case BLOCK_TYPES.TABLE_OF_CONTENTS: {
      return deserializeItemToTableOfContentsBlock(metadata, id);
    }
    case BLOCK_TYPES.FAQ: {
      return deserializeItemToFAQBlock(metadata, id);
    }
    case BLOCK_TYPES.QUESTION: {
      return deserializeItemToQuestionBlock(metadata, id);
    }
    case BLOCK_TYPES.IMPORTED_VIDEO: {
      return deserializeItemToImportedVideoBlock(metadata, id);
    }
    case BLOCK_TYPES.RAW_HTML: {
      return deserializeItemToRawHtmlBlock(metadata, id);
    }
    case BLOCK_TYPES.CROWD_SIGNAL: {
      return deserializeItemToEmbedBlock(BLOCK_TYPES.CROWD_SIGNAL, metadata, id);
    }
    default:
      return item;
  }
}

function filterNonValidForSaveBlocks(blockList) {
  return blockList.filter(block => {
    switch (block.type) {
      case BLOCK_TYPES.ADDER: {
        return false;
      }
      case BLOCK_TYPES.QUOTE: {
        return !!block.value.text;
      }
      default:
        return true;
    }
  });
}

function createBlockListDataForSave(blockList) {
  const filteredBlockList = filterNonValidForSaveBlocks(blockList);
  return filteredBlockList.map(item => {
    return formatBlockForSave(item);
  });
}

function isValidCoverData(coverData) {
  return coverData.image && coverData.image.url;
}

function isSocialSharingImageValid(image) {
  return image && image.url;
}

function hasMedia(coverData) {
  return !!coverData.media;
}

function serializeCover(coverData) {
  const image = serializeImageToImageDataItem(coverData.image);
  const media = hasMedia(coverData) ? serializeMediaItemToCoverDataItem(coverData.media) : null;
  return {
    image,
    media,
  };
}

function deserializeCoverMediaItemToMediaObject(mediaItem) {
  const { type } = mediaItem;
  let value;
  if (type === BLOCK_TYPES.MM_PLAYER) {
    value = deserializeItemToMMPlayerObject(mediaItem.metadata);
  } else {
    value = deserializeItemToEmbedObject(mediaItem.metadata);
  }
  return { type, value };
}

export function getCoverDataFromDraftCover(draftCover) {
  const image = draftCover.image ? deserializeItemToImageObject(draftCover.image.metadata) : null;
  const media = draftCover.media ? deserializeCoverMediaItemToMediaObject(draftCover.media) : null;
  return {
    image,
    media,
  };
}

export function getBlockListDataFromDraftBody(draftBody) {
  return draftBody.map(item => formatItemToBlock(item)).filter(item => !!item);
}

export function getDraftDataPayload(data) {
  const {
    title,
    intro,
    coverData,
    blocklist,
    tagsAndSeo,
    analysis,
    bodyText,
    socialSharing,
    isGroupOfLinksExists,
    comments,
    inThisStories,
  } = data;
  const { articleTags, seoTitle, seoDescription } = tagsAndSeo;
  const body = createBlockListDataForSave(blocklist);
  const { image } = socialSharing;
  const socialSharingSerializedImage = isSocialSharingImageValid(image) ? serializeImageToImageDataItem(image) : {};
  const cover = isValidCoverData(coverData) ? serializeCover(coverData) : {};
  const introData = isSlateValueEmpty(intro) ? '' : serializeSlateValueToHtmlString(intro);
  return {
    title,
    intro: introData,
    body,
    cover,
    tags: articleTags,
    seo: seoDescription,
    seoTitle,
    analysis,
    bodyText,
    isGroupOfLinksExists,
    comments,
    socialSharing: {
      og: {
        ...socialSharing,
        image: socialSharingSerializedImage,
      },
      twitter: {
        ...socialSharing,
        image: socialSharingSerializedImage,
      },
    },
    inThisStory: inThisStories,
  };
}
