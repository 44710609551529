import {
  IMAGES_DASHBOARD_PAGE_SIZE,
  STATUS_FILTER_VALUES, STATUS_FILTER_VALUES_QUERY,
} from '../components/imagesDashboard/imagesDashboard.constants';

const getSearchQueryParam = search => (search ? `qtext=${search}` : '');

const getStatusQueryParam = status => {
  return status && STATUS_FILTER_VALUES.DASHBOARD.ALL_STATUSES !== status ? `status=${STATUS_FILTER_VALUES_QUERY[status]}` : '';
};

const getDateFromQueryParam = dateFrom => (dateFrom ? `created_from=${new Date(dateFrom).toISOString()}` : '');

const getDateToQueryParam = dateTo => (dateTo ? `created_to=${new Date(dateTo).toISOString()}` : '');

const getTagsQueryParam = tags => (tags?.length ? `include_tags=${tags.toString()}` : '');

export const getImagesQueryString = (filters, limit) => {
  const { status, search, dateFrom, dateTo, tags } = filters;
  const FILTERS_QUERY_OBJECT = {
    status: getStatusQueryParam(status),
    search: getSearchQueryParam(search),
    limit: limit ? `limit=${limit}` : `limit=${IMAGES_DASHBOARD_PAGE_SIZE}`,
    updated_from: getDateFromQueryParam(dateFrom),
    updated_to: getDateToQueryParam(dateTo),
    include_tags: getTagsQueryParam(tags),
  };
  return Object.values(FILTERS_QUERY_OBJECT).filter(queryParam => queryParam).join('&');
};
