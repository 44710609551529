import { BackgroundTaskStatus } from '@ftbpro/mm-admin-ui-components';
import { MessageType } from 'core/backgroundTasks/BackgroundTasks.constants';
import { BackgroundTaskData } from 'core/backgroundTasks/store/backgroundTasks.constants';
import { RequestConfig } from 'core/middleware/networkMiddleware/types';
import { TaskConfig, WebWorkerWrapper } from '../WebWorkerWrapper';
import QueryWorkerTask from './query.worker';
import { formatBackgroundTaskData } from './queryWorker.utils';

export interface QueryTaskConfig extends TaskConfig, RequestConfig {
}

export type ResourcesStatusCount = {
  total?: number;
  succeeded?: number;
  failed?: number;
  inProgress?: number;
  pending?: number;
  canceled?: number;
}

interface QueryBulkResponseData {
  status: MessageType.InProgress | MessageType.Completed,
  resourcesStatusCount: ResourcesStatusCount
  resources: Array<Record<string, string | number>>,
  pagination: unknown // todo - add pagination data if needed
}

export class QueryWorker extends WebWorkerWrapper {
  constructor(taskConfig: QueryTaskConfig) {
    super(taskConfig as TaskConfig);
    this.nativeWorkerInstance = new QueryWorkerTask();
  }

  wrapTask(): string {
    return '';
  }

  isDone(e: MessageEvent<any>): boolean {
    return e.data.status === MessageType.Completed;
  }

  formatData(data: QueryBulkResponseData): BackgroundTaskData {
    const { total = 0, succeeded = 0, failed = 0, inProgress = 0, canceled = 0 } = data?.resourcesStatusCount;
    const formatterArgs = { succeeded, failed, inProgress, total };
    if (data.status === MessageType.InProgress) {
      return formatBackgroundTaskData(formatterArgs, BackgroundTaskStatus.InProgress, this.config);
    }
    if (data.status === MessageType.Completed) {
      if (failed > 0) {
        return formatBackgroundTaskData(formatterArgs, BackgroundTaskStatus.Error, this.config);
      }
      if (canceled > 0) {
        return formatBackgroundTaskData(formatterArgs, BackgroundTaskStatus.Canceled, this.config);
      }
    }

    return formatBackgroundTaskData(formatterArgs, BackgroundTaskStatus.Done, this.config);
  }
}
