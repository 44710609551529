import { reportEvent } from '../../../../../core/analytics';
import {
  TOGGLE_AUTHORS_MODAL,
  TOGGLE_COMMENTS_MODAL,
  TOGGLE_SCHEDULE_POST_MODAL_SHORTCUT,
  TOGGLE_SCHEDULE_POST_MODAL_THROUGH_CLOSE_CLICK,
  TOGGLE_SCHEDULE_POST_MODAL_THROUGH_FEEDS,
  UPDATE_COMMENTS_SUCCESS,
} from '../echoModals/echoModals.actions';
import {
  DELETE_POST_SUCCESS,
  DRAFT_OPENED_FOR_EDITING_ECHO_DASHBOARD,
  STATUS_FILTER_VALUE_CHANGE,
  TOGGLE_POST_VISIBILITY_SUCCESS,
  PREVIEW_PAGE_OPEN_FROM_DASHBOARD,
  COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD,
  SET_NEW_DATE_RANGE,
} from '../echoDashboard/echoDashboard.actions';
import {
  ADD_COMMERCIAL_TAG,
  CHANGE_DFP_CATEGORY,
  HIDE_OWNER,
  PIN_TO_TOP,
  SET_NO_INDEX,
  TOGGLE_SPONSORED_CONTENT,
  DRAFT_OPENED_FOR_EDITING_THROUGH_CUSTOMIZATION_SCREEN,
  REMOVE_CHANNEL_FROM_POST,
  SET_FEATURED_TIME_TO_ARRAY_OF_FEEDS,
  PREVIEW_PAGE_OPEN,
  ADD_SUGGESTED_CHANNEL_TO_POST,
  COPY_URL_TO_CLIPBOARD,
  POST_PUBLISH_SUCCESS,
} from '../echoCustomization/echoCustomization.actions';
import {
  ECHO,
  EVENT_TYPES,
  MAP_ACTIONS_TO_EVENT_TYPES,
} from './echoAnalyticsMiddleware.constants';
import {
  getNewAuthorsAsString,
  hasPostBeenHidden,
  hasPostBeenPinnedToTop,
  shouldReportSchedulingForTimeInTheFuture,
  shouldSkipEchoAnalyticsMiddleware,
} from './echoAnalyticsMiddleware.utils';
import { echoCustomizationSelector } from '../echoCustomization/echoCustomization.selector';
import { NOTIFY_NEW_PAGE_WAS_OPENED } from '../../../store/cms.actions';
import { propertySelector } from '../../../../../core/store/property/property.selector';
import { CMS } from '../../../constants/cms.constants';
import { SERVER_POST_STATUSES } from '../../constants/echo.constants';
import { PLACING_CONTEXT_SCREEN } from '../../components/modals/modals.constants';
import { createCustomDimensions } from '../../../utils/analytics.utils';

export const echoAnalyticsMiddleware = store => next => action => {
  const state = store.getState();
  const property = propertySelector.currentPropertySlug(state, CMS);

  if (
    shouldSkipEchoAnalyticsMiddleware(action, ECHO)
  ) {
    return next(action);
  }

  switch (action.type) {
    case DRAFT_OPENED_FOR_EDITING_ECHO_DASHBOARD:
    case DRAFT_OPENED_FOR_EDITING_THROUGH_CUSTOMIZATION_SCREEN:
    case TOGGLE_SCHEDULE_POST_MODAL_SHORTCUT:
    case TOGGLE_SCHEDULE_POST_MODAL_THROUGH_CLOSE_CLICK:
    case TOGGLE_SCHEDULE_POST_MODAL_THROUGH_FEEDS:
    case PREVIEW_PAGE_OPEN: {
      reportEvent(
        '',
        MAP_ACTIONS_TO_EVENT_TYPES[action.type],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case DELETE_POST_SUCCESS: {
      const { placingContextScreen } = action.payload;
      const eventAction = placingContextScreen === PLACING_CONTEXT_SCREEN.ECHO_DASHBOARD
        ? EVENT_TYPES.DELETE_CLICKED_FROM_TABLE
        : EVENT_TYPES.DELETE_CLICKED_FROM_ARTICLE;
      reportEvent(
        '',
        eventAction,
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case UPDATE_COMMENTS_SUCCESS: {
      const { placingContextScreen } = action.payload;
      const eventAction = placingContextScreen === PLACING_CONTEXT_SCREEN.ECHO_DASHBOARD
        ? EVENT_TYPES.COMMENT_ADDED_FROM_TABLE
        : EVENT_TYPES.COMMENT_ADDED_FROM_ARTICLE;
      reportEvent(
        '',
        eventAction,
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case ADD_SUGGESTED_CHANNEL_TO_POST: {
      reportEvent(
        '',
        MAP_ACTIONS_TO_EVENT_TYPES[ADD_SUGGESTED_CHANNEL_TO_POST],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case PREVIEW_PAGE_OPEN_FROM_DASHBOARD: {
      reportEvent(
        '',
        MAP_ACTIONS_TO_EVENT_TYPES[PREVIEW_PAGE_OPEN_FROM_DASHBOARD],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD: {
      reportEvent(
        '',
        MAP_ACTIONS_TO_EVENT_TYPES[COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case COPY_URL_TO_CLIPBOARD: {
      reportEvent(
        '',
        MAP_ACTIONS_TO_EVENT_TYPES[COPY_URL_TO_CLIPBOARD],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case SET_NEW_DATE_RANGE: {
      reportEvent(
        `${action.payload}`,
        MAP_ACTIONS_TO_EVENT_TYPES[SET_NEW_DATE_RANGE],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TOGGLE_SPONSORED_CONTENT: {
      const { isSponsoredContentEnabled } = action.payload;
      if (isSponsoredContentEnabled) {
        reportEvent(
          '',
          MAP_ACTIONS_TO_EVENT_TYPES[TOGGLE_SPONSORED_CONTENT],
          ECHO,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case CHANGE_DFP_CATEGORY: {
      reportEvent(
        `category=${action.payload.adsCategory}`,
        MAP_ACTIONS_TO_EVENT_TYPES[CHANGE_DFP_CATEGORY],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TOGGLE_POST_VISIBILITY_SUCCESS: {
      if (hasPostBeenHidden(action)) {
        reportEvent(
          '',
          MAP_ACTIONS_TO_EVENT_TYPES[TOGGLE_POST_VISIBILITY_SUCCESS],
          ECHO,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case SET_NO_INDEX: {
      const { noIndex } = action.payload;
      if (noIndex) {
        reportEvent(
          '',
          MAP_ACTIONS_TO_EVENT_TYPES[SET_NO_INDEX],
          ECHO,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case POST_PUBLISH_SUCCESS: {
      const postData = echoCustomizationSelector.postData(state);
      const isPublished = postData && postData.status === SERVER_POST_STATUSES.PUBLISHED;
      const eventAction = isPublished ? EVENT_TYPES.UPDATE_ARTICLE_CLICKED : EVENT_TYPES.ARTICLE_PUBLISHED;
      const isUrlChanged = action.payload ? 'yes' : 'no';
      const customDimensions = createCustomDimensions(state);
      reportEvent(
        `url changed=${isUrlChanged}`,
        eventAction,
        ECHO,
        {
          ...customDimensions,
          articleId: customDimensions.articleId === 'none' && postData ? postData.id : 'none',
        },
      );
      return next(action);
    }
    case PIN_TO_TOP: {
      if (hasPostBeenPinnedToTop(action)) {
        reportEvent(
          '',
          MAP_ACTIONS_TO_EVENT_TYPES[PIN_TO_TOP],
          ECHO,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case SET_FEATURED_TIME_TO_ARRAY_OF_FEEDS: {
      const actionToReport = shouldReportSchedulingForTimeInTheFuture(action)
        ? EVENT_TYPES.SCHEDULE_FOR_TIME_IN_THE_FUTURE
        : EVENT_TYPES.SCHEDULE_PUBLISH_NOW;
      reportEvent(
        '',
        actionToReport,
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TOGGLE_AUTHORS_MODAL: {
      const { addedAuthors: newAndReportedAuthors } = action.payload;
      if (newAndReportedAuthors) {
        const { additional: alreadyReportedAuthors } = echoCustomizationSelector.authors(state);
        reportEvent(
          `property=${property};author=${getNewAuthorsAsString(alreadyReportedAuthors, newAndReportedAuthors)}`,
          MAP_ACTIONS_TO_EVENT_TYPES[TOGGLE_AUTHORS_MODAL],
          ECHO,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case ADD_COMMERCIAL_TAG: {
      reportEvent(
        `property=${property};tags=${action.payload}`,
        MAP_ACTIONS_TO_EVENT_TYPES[ADD_COMMERCIAL_TAG],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case REMOVE_CHANNEL_FROM_POST: {
      const actionToReport = (action.payload.featureTime)
        ? EVENT_TYPES.UNFEATURE_POST_FROM_PUBLISHED_DESINATION
        : EVENT_TYPES.UNFEATURE_POST_FROM_TO_BE_PUBLISHED_DESTINATION;
      reportEvent(
        `property=${property}`,
        actionToReport,
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case NOTIFY_NEW_PAGE_WAS_OPENED: {
      reportEvent(
        `property=${property}`,
        MAP_ACTIONS_TO_EVENT_TYPES[action.payload],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case STATUS_FILTER_VALUE_CHANGE: {
      const { newFilterState } = action.payload;
      reportEvent(
        `property=${property};state=${newFilterState}`,
        MAP_ACTIONS_TO_EVENT_TYPES[STATUS_FILTER_VALUE_CHANGE],
        ECHO,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TOGGLE_COMMENTS_MODAL: {
      const { isCommentsModalVisible } = action.payload;
      if (isCommentsModalVisible) {
        reportEvent(
          `property=${property}`,
          MAP_ACTIONS_TO_EVENT_TYPES[TOGGLE_COMMENTS_MODAL],
          ECHO,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case HIDE_OWNER: {
      const { hideOwner } = action.payload;
      if (hideOwner) {
        reportEvent(
          `property=${property}`,
          MAP_ACTIONS_TO_EVENT_TYPES[HIDE_OWNER],
          ECHO,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    default:
      return next(action);
  }
};
