import { RootState } from '../../../../core/store';
import { QueryBuilderSliceState } from './queryBuilder.types';

const getQueryBuilderSliceState = (state: RootState): QueryBuilderSliceState => state.reports.queryBuilder;

export const queryBuilderSelector = {
  dataSourceList: (state: RootState) => getQueryBuilderSliceState(state).dataSourceList,
  queryParams: (state: RootState) => getQueryBuilderSliceState(state).queryParams,
  loadingReport: (state: RootState) => getQueryBuilderSliceState(state).loading,
  dimensionColumnDefs: (state: RootState) => getQueryBuilderSliceState(state).dimensionsColumnDefs,
  reportData: (state: RootState) => getQueryBuilderSliceState(state).data,
  saveState: (state: RootState) => getQueryBuilderSliceState(state).isSaved,
  reports: (state: RootState) => getQueryBuilderSliceState(state).reportsList,
  scheduleParams: (state: RootState) => getQueryBuilderSliceState(state).scheduleParams,
  tableStatus: (state: RootState) => getQueryBuilderSliceState(state).tableStatus,
  scheduledReports: (state: RootState) => getQueryBuilderSliceState(state).scheduledReports,
  reportCreatorID: (state: RootState) => getQueryBuilderSliceState(state).reportCreatorID,
  isAPIKeyExist: (state: RootState) => getQueryBuilderSliceState(state).isAPIKeyExist,
  APIKey: (state: RootState) => getQueryBuilderSliceState(state).APIKey,
  openGenerateAPIKeyModal: (state: RootState) => getQueryBuilderSliceState(state).openGenerateAPIKeyModal,
  openRegenerateAPIKeyModal: (state: RootState) => getQueryBuilderSliceState(state).openRegenerateAPIKeyModal,
};
