const SCROLL_ELEMENT_TO_THE_MIDDLE_OPTION = {
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
};
const SCROLL_ELEMENT_TO_THE_BEGINNING_OPTION = {
    behavior: 'smooth',
    block: 'start',
};

export class ScrollManager {
    clearCurrentElementRef() {
      this.currentElementRef = null;
    }

    scrollTolElementIfNeedTo(elementId, scrollBehaviourOptions) {
        const elementForScroll = document.getElementById(elementId);
        const shouldScroll = this.currentElementRef !== elementForScroll;
        if (shouldScroll) {
            this.currentElementRef = elementForScroll;
            setTimeout(() => {
                this.currentElementRef.scrollIntoView(scrollBehaviourOptions);
            }, 0);
        }
    }

    unregisterElementIfCurrentElement(elementId) {
      if (this.currentElementRef && this.currentElementRef.id === elementId) {
        this.clearCurrentElementRef();
      }
    }

    scrollElementToTheBeginningOfTheScreenIfNeedsTo(elementId) {
        this.scrollTolElementIfNeedTo(elementId, SCROLL_ELEMENT_TO_THE_BEGINNING_OPTION);
    }

    scrollElementToTheMiddleOfTheScreenIfNeedsTo(elementId) {
        this.scrollTolElementIfNeedTo(elementId, SCROLL_ELEMENT_TO_THE_MIDDLE_OPTION);
    }
}

let instance;
export const getScrollManager = () => {
    if (!instance) {
        instance = new ScrollManager();
    }
    return instance;
};
