export const TAB_PANEL = {
  OVERVIEW_MENU: {
    KEY: 'overviewMenu',
    TITLE: 'Overview',
  },
  SEO_MENU: {
    KEY: 'seoMenu',
    TITLE: 'SEO Optimization',
  },
  SOCIAL_SHARING_MENU: {
    KEY: 'socialSharingMenu',
    TITLE: 'Social Sharing',
  },
  MIGRATION_MENU: {
    KEY: 'migrationMenu',
    TITLE: 'Migrate',
  },
};

export const MINIMUM_NUMBER_OF_TAGS = 3;

export const SI = 'si';
