import { navigateToPage, getPreviouslyVisitedPage } from '../../../core/routing/history';
import { CMS_ROUTES, CMS_ROUTES_BASE } from '../editor/utils/cmsRouter.constants';

export const CMSNavigator = {
   toDraftEditing(property, id) {
    navigateToPage(`${CMS_ROUTES.EDITOR_DASHBOARD}/${property}/${id}`);
  },
  toArticleCustomization(property, id) {
    navigateToPage(`${CMS_ROUTES.ECHO_DASHBOARD}/${property}/${id}`);
  },
  toPreviewPreviousPage(isEditorPreview) {
    const previouslyVisitedPage = getPreviouslyVisitedPage();
    const nextPageToOpen = isEditorPreview ? CMS_ROUTES.EDITOR_DASHBOARD : CMS_ROUTES.ECHO_DASHBOARD;
    return navigateToPage(previouslyVisitedPage || nextPageToOpen);
  },
  toEchoPreviousPage() {
    navigateToPage(getPreviouslyVisitedPage() || CMS_ROUTES.ECHO_DASHBOARD);
  },
  toEditorPreviousPage() {
    const previouslyVisitedPage = getPreviouslyVisitedPage();
    navigateToPage(previouslyVisitedPage || CMS_ROUTES.EDITOR_DASHBOARD);
  },
  toEditorPreview(property, id) {
    navigateToPage(`${CMS_ROUTES.EDITOR_DASHBOARD}/${property}/${id}/preview`);
  },
  toEchoPreview(property, id) {
     navigateToPage(`${CMS_ROUTES.ECHO_DASHBOARD}/${property}/${id}/preview`);
  },
  toEchoDashboard() {
     navigateToPage(CMS_ROUTES.ECHO_DASHBOARD);
  },
  toEditorDashboard() {
     navigateToPage(CMS_ROUTES.EDITOR_DASHBOARD);
  },
  toDashboard() {
     navigateToPage(CMS_ROUTES.MAIN_DASHBOARD);
  },
  getPostEditingUrl(property, postId) {
  return `${CMS_ROUTES_BASE}/editor/${property}/${postId}`;
},
};
