import { BackgroundTaskStatus } from '@ftbpro/mm-admin-ui-components';
import { TaskConfig } from './WebWorkerWrapper';

export const DEFAULT_CONTENT_RENDERERS_BY_STATE: TaskConfig['contentFormatByState'] = {
  [BackgroundTaskStatus.InProgress]: '${succeeded} out of ${total} completed..',
  [BackgroundTaskStatus.Done]: 'Done Proccesing Task',
  [BackgroundTaskStatus.Error]: '${failed} out of ${total} items have failed proccesing.',
  [BackgroundTaskStatus.Canceled]: '${canceled} out of ${total} items have been canceled and will not be proccesed.',
};

export const DEFAULT_TITLE_FORMATTER = 'In Progress';
