import { flexColumnCenterCenter, flexRowCenter } from '../../styles/flex';
import { SITE_VARIABLES } from '../../styles/variables';

const CSS_SELECTORS = {
  IMMEDIATE_CHILD: '> *',
};

export const getStylesObject = () => {
    return {
      mainContainer: {
       ...flexRowCenter,
       marginTop: '180px',
       padding: SITE_VARIABLES.PADDING.BIG,
      },
      contentContainer: {
        ...flexColumnCenterCenter,
        [CSS_SELECTORS.IMMEDIATE_CHILD]: {
          flex: 1,
        },
      },
      image: {
        width: '144px',
        height: '168px',
      },
      title: {
        marginTop: '32px',
        height: '28px',
      },
      description: {
        height: '40px',
        marginTop: '16px',
        ...flexColumnCenterCenter,
      },
    };
  };
