import { ConfigsProvider } from 'core/configs/ConfigsProvider';
// instagram external script
let instagramLoaderScriptPromise = null;
export function runInstagramScript() {
  if (instagramLoaderScriptPromise) {
    return instagramLoaderScriptPromise;
  }

  instagramLoaderScriptPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://platform.instagram.com/en_US/embeds.js';
    script.async = true;
    script.onload = () => resolve(window.instgrm);
    script.onFailure = err => reject(err);
    document.head.appendChild(script);
  });
  return instagramLoaderScriptPromise;
}

// twitter external script
// https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/set-up-twitter-for-websites
let twitterScriptLoaderPromise = null;
export function runTwitterScript() {
  if (twitterScriptLoaderPromise) {
    return twitterScriptLoaderPromise;
  }
  twitterScriptLoaderPromise = new Promise(resolve => {
      window.twttr = (function (d, s, id) { // eslint-disable-line
      const fjs = d.getElementsByTagName(s)[0];
      const t = window.twttr || {};
      if (d.getElementById(id)) return t;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);

        t._e = []; //eslint-disable-line
      t.ready = f => {
          t._e.push(f); //eslint-disable-line
      };
      return t;
    }(document, 'script', 'twitter-wjs'));
    window.twttr.ready(() => resolve(window.twttr));
  });
  return twitterScriptLoaderPromise;
}

let facebookScriptLoaderPromise = null;
export function runFacebookScript() {
  if (facebookScriptLoaderPromise) {
    return facebookScriptLoaderPromise;
  }
  facebookScriptLoaderPromise = new Promise(resolve => {
    if (!window.FB) {
      window.fbAsyncInit = () => {
        FB.init({ // eslint-disable-line
          appId: 'none',
          xfbml: true,
          version: 'v3.1',
        });
        resolve(window.FB);
      };
      (function (d, s, id) { // eslint-disable-line
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) resolve(window.FB);
        const js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    } else {
      resolve(window.FB);
    }
  });
  return facebookScriptLoaderPromise;
}

export function runExcoScript() {
  return new Promise(resolve => {
    (function (d, s, id) { // eslint-disable-line
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) resolve();
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://embed.playbuzz.com/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'playbuzz-sdk'));
    resolve();
  });
}

let ApesterScriptLoadePromise = null;
export function runApesterScript() {
  if (ApesterScriptLoadePromise) {
    return ApesterScriptLoadePromise;
  }
  ApesterScriptLoadePromise = new Promise(resolve => {
    (function (d, s, id) { // eslint-disable-line
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) resolve();
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://static.apester.com/js/sdk/latest/apester-sdk.js';
      fjs?.parentNode?.insertBefore(js, fjs);
      js.onload = () => resolve(window.APESTER);
    }(document, 'script', 'apester-sdk'));
  });
  return ApesterScriptLoadePromise;
}

const { CONTENT_ENRICHMENT_EMBEDS_SRC } = ConfigsProvider;

export function injectContentEnrichmentScriptIfNeedsTo() {
  const script = document.createElement('script');

  if (!document.querySelector('#mm_content_embed')) {
    script.id = 'mm_content_embed';
    script.src = CONTENT_ENRICHMENT_EMBEDS_SRC;
    document.head.appendChild(script);
  }
}

export function renderContentEnrichmentEmbeds() {
  /* eslint-disable no-underscore-dangle */
  if (window.__mm_content_enrichment__) {
    window.__mm_content_enrichment__.renderEmbeds();
  }
  /* eslint-enable no-underscore-dangle */
}
