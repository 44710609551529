export const EMBEDS_LIST_STRINGS = {
  CREATE_NEW: 'Create New Block',
  CREATE_EMBED: 'Create New Embed',
  ALL: 'All Embeds',
  CHOOSE_TYPE: 'Choose the type of embed you would like to create',
  CANCEL: 'Cancel',
  DELETE: 'The embed has been deleted',
  REJECTED: 'The embed failed to delete',
  COPY: 'You copied the embed successfully!',
  COPY_ITEM: 'has been copied and saved successfully!',
};

export const TABLE_COLUMN_KEYS = {
  EMBED_TYPE: 'embedType',
  NAME: 'name',
  AUTHOR: 'author',
  UPDATED_AT: 'updatedAt',
  URL: 'url',
  ACTIONS: 'actions',
};

export const TABLE_COLUMN_LABELS = {
  TYPE: 'Type',
  NAME: 'Name',
  CREATED_BY: 'Created by',
  UPDATED_ON: 'Updated on',
  URL: 'URL',
};

export const TABLE_ACTION_BAR_TOOL_TIPS = {
  COPY: 'Copy URL',
  DUPLICATE: 'Duplicate',
  PREVIEW: 'Preview',
  DELETE: 'Delete',
  EDIT: 'Edit',
};

export const NO_DATA_MESSAGE = 'No Matching Embeds';

export const DELETE_EMBED_MODAL = {
  TEXT: 'Are you sure you want to permanently delete this embed?',
  TITLE: 'Delete embed',
  BUTTON_NO: 'No, keep this embed',
  BUTTON_YES: 'Yes, delete it',
};

export const EMBED_ERROR = 'Failed to fetch embed data';

export const PREVIEW_ERROR = 'Previews are not available at this time.';

export const FEEDS_ERROR = 'Failed to fetch feeds data';

export const INVALID_URL = 'The URL is not valid please try again';

export const URL_EXIST = 'This URL already exists';

export const MAXIMUM_ARTICLES = 'This group has reached its maximum number of articles';

export const ADD_LINK = 'Add Link';

export const FEED_DROPDOWN = {
  PLACEHOLDER: 'Enter feed name',
  LABEL: 'Feed Name',
};

export const LIMIT_DROPDOWN = {
  LABEL: 'Number of articles',
};

export const ADD_IMAGES = 'Add Images To Links';

export const EMBED_BUTTONS = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  PREVIEW: 'Preview',
  URL: 'Copy URL',
};
