import React from 'react';
import { NO_ORG_PERMISSION_MESSAGE } from './errorPage.constants';
import { getVoltaxOrganizationUrl } from '../../voltax/utils';
import { useStyles } from '../../hooks/useStyles';
import { getStylesObject } from './organizationIndication.styles';

export const OrganizationIndication = ({ userOrgId }) => {
  const link = getVoltaxOrganizationUrl(userOrgId);
  const { organizationLink } = useStyles(getStylesObject);
  return (
    <React.Fragment>
      {NO_ORG_PERMISSION_MESSAGE}
      <a css={organizationLink} href={link}>{link}</a>
    </React.Fragment>
  );
};
