import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import { AppDispatch } from 'core/store';
import {
  TITLE_MIN_LENGTH,
  TITLE_MAX_LENGTH,
} from '../../components/editorTitle/editorTitle.constants';
import { SmartServiceConfigsProvider } from '../../services/smartServiceConfigsProvider';
import { RESET_EDITOR_STATE } from '../draft/draft.actions';
import { POST_TITLE_INITIAL_STATE, TITLE_ACTION } from '../editor.constants';
import { HeadlineBodyType, SuggestionsType } from './postTitle.types';

const title = 'title';

const postTitleSlice = createSlice({
  name: title,
  initialState: POST_TITLE_INITIAL_STATE,
  reducers: {
    [TITLE_ACTION](state, action: PayloadAction<string>) {
      const titleLength = action.payload.length;
      state.postTitle = action.payload;
      state.isTitleValid = titleLength >= TITLE_MIN_LENGTH && titleLength <= TITLE_MAX_LENGTH;
    },
    toggleHeadlineModal(state) {
      state.isOpenHeadlineModal = !state.isOpenHeadlineModal;
    },
    setHeadlineLoading(state) {
      state.isHeadlineLoading = !state.isHeadlineLoading;
    },
    resetSuggestions(state) {
      state.headlineSuggestions = POST_TITLE_INITIAL_STATE.headlineSuggestions;
    },
    fetchHeadlineSuggestionPending(state) {
      state.isHeadlineLoading = true;
    },
    fetchHeadlineSuggestionSuccess(
      state,
      action: PayloadAction<SuggestionsType[]>,
    ) {
      state.isHeadlineLoading = false;
      state.headlineSuggestions = action.payload;
    },
    fetchHeadlineSuggestionFailure(state, action: PayloadAction<string>) {
      state.isHeadlineLoading = false;
      state.error = action.payload;
    },
    toggleHeadlineReminder(state, action: PayloadAction<boolean>) {
      state.isOpenHeadlineReminder = action.payload;
    },
    resetHeadlineSuggestions(state) {
      state.headlineSuggestions = POST_TITLE_INITIAL_STATE.headlineSuggestions;
      state.isOpenHeadlineModal = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_EDITOR_STATE, state => {
      state.postTitle = '';
      state.isTitleValid = true;
      state.isOpenHeadlineReminder = false;
      resetHeadlineSuggestions();
    });
  },
});

export const fetchHeadlineSuggestions = (property: string, postId: string, body: HeadlineBodyType) => async (dispatch: AppDispatch) => {
    const url = SmartServiceConfigsProvider.getSmartGenerationEndpoint(
      property,
      postId,
    );

    await dispatch(
      networkAction({
        url,
        body: JSON.stringify(body),
        method: HttpMethod.Post,
        onPending: fetchHeadlineSuggestionPending,
        onSuccess: fetchHeadlineSuggestionSuccess,
        onFailure: fetchHeadlineSuggestionFailure,
      }),
    );
  };

export const {
  [TITLE_ACTION]: setPostTitle,
  toggleHeadlineModal,
  fetchHeadlineSuggestionFailure,
  fetchHeadlineSuggestionPending,
  fetchHeadlineSuggestionSuccess,
  resetHeadlineSuggestions,
  setHeadlineLoading,
  resetSuggestions,
  toggleHeadlineReminder,
} = postTitleSlice.actions;

export default postTitleSlice.reducer;
