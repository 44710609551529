import { COLORS } from '../../../../../core/styles/colors';

const CSS_CLASSES = {
  TWITTER_EMBED_ITEM: 'twitter-tweet-rendered',
};

export const getStylesObject = ({ isTweetSelected }) => {
  return {
    header: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      padding: '0 0 16px 0',
      marginBottom: '16px',
    },
    filter: {
      display: 'flex',
      alignSelf: 'center',
    },
    input: {
      width: '100%',
    },
    filterVariables: {
      dropdownButtonHeight: '32px',
      dropdownWidth: '100px',
    },
    loadMoreButton: {
      paddingTop: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    radioGroup: {
      marginBottom: '16px',
    },
    previewItem: {
      position: 'relative',
      cursor: 'pointer',
      marginBottom: '16px',
      borderColor: COLORS.PRIMARY10,
      [`& .${CSS_CLASSES.TWITTER_EMBED_ITEM}`]: {
        margin: '0 !important',
      },
    },
    cursorCatcher: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderWidth: '2px',
      borderColor: isTweetSelected ? COLORS.PRIMARY100 : 'transparent',
      '&:hover': {
        boxShadow: `0 0 10px 0 ${COLORS.BLACK100_OPACITY10}`,
      },
    },
    embed: {
      flex: "0 1 calc(~'100% - 52px')",
      maxHeight: "calc(~'100% - 52px')",
      textAlign: 'center',
    },
  };
};
