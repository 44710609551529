import { isEmpty } from './utils';

const PROTOCOL_AND_DOMAIN_REGEX = /^(?:\w+:)?\/\/(\S+)$/;
const LOCALHOST_DOMAIN_REGEX = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
const NON_LOCALHOST_DOMAIN_REGEX = /^[^\s.]+\.\S{2,}$/;

// TODO(types) - MUST change to specific type
export const isStringUrl = (str:any) => {
  if (typeof str !== 'string') {
    return false;
  }

  const match = str.match(PROTOCOL_AND_DOMAIN_REGEX);
  if (!match) {
    return false;
  }

  const stringAfterProtocol = match[1];
  if (!stringAfterProtocol) {
    return false;
  }

  if (LOCALHOST_DOMAIN_REGEX.test(stringAfterProtocol)
    || NON_LOCALHOST_DOMAIN_REGEX.test(stringAfterProtocol)) {
    return true;
  }

  return false;
};

// TODO(types) - MUST change to specific type
export const capitalizeFirstLetter = (str:any) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

// TODO(types) - MUST change to specific type
export const capitalizeEveryFirstLetter = (text:any) => {
  return text
    .split(/\s+/g)
    .map((word:any) => capitalizeFirstLetter(word))
    .join(' ');
};

export const buildQueryFromObject = (queryObject: Record<string, string | number>, initialQueryString = '') => {
  return Object.entries(queryObject).reduce((acc, [key, value]) => {
    if (isEmpty(value)) return acc;
    const appendDelimiter = isEmpty(acc) ? '?' : '&';
    return `${acc}${appendDelimiter}${key}=${encodeURIComponent(value)}`;
  }, initialQueryString);
};

// TODO(types) - MUST change to specific type
export const isExistInTextCaseInsensitive = (text:any, query = '') => {
  const searchTerm = query.toLowerCase().trim();
  const textTerm = text.toLowerCase();
  return textTerm.includes(searchTerm);
};

// TODO(types) - MUST change to specific type
export const removeExtraSpacesFromString = (string:any) => (
  string.replace(/\s+/g, ' ').trim()
);

// TODO(types) - MUST change to specific type
export const trim = (text:any) => text.trim();

// TODO(types) - MUST change to specific type
export const slugifyName = (name:any) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/\s/ig, '_')
    .replace(/[^0-9A-Z-_]/ig, '')
    .replace(/_+/g, '_')
    .replace(/_$/, '');
};
