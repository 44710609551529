import { ThunkMiddleware } from 'redux-thunk';
import { reportEvent } from 'core/analytics';
import {
  fetchChartDataRejected,
  fetchDisclaimerRejected,
  fetchFilterDataRejected,
} from '../store/dashboards/dashboardsSlice';
import {
  DASHBOARD_EVENT_CATEGORY,
  EVENT_TYPES,
  QUERY_BUILDER_EVENT_CATEGORY,
} from './reportsAnalyticsMiddleware.constants';
import {
  createDashboardsCustomDimensions,
  createQueryBuilderCustomDimensions,
  getFiltersLabel,
  getRequestFromAction,
  getTimeframeLabel,
  shouldSkipAction,
} from './reportsAnalyticsMiddleware.utils';
import { DASHBOARDS_EVENT_TYPES } from '../store/dashboards/dashboards.constants';
import { QUERY_BUILDER_EVENT_TYPES } from '../store/queryBuilder/queryBuilder.constants';
import { queryBuilderSelector } from '../store/queryBuilder/queryBuilder.selector';

export const reportsAnalyticsMiddleware: ThunkMiddleware = store => next => action => {
  const state = store.getState();

  if (shouldSkipAction(action)) {
    return next(action);
  }

  switch (action.type) {
    case DASHBOARDS_EVENT_TYPES.DASHBOARD_FILTERS_APPLIED: {
      const { selectedFilters, selectedDateRange, dashboardPath } = action.payload;
      reportEvent(
        `filters=${getFiltersLabel(selectedFilters)}; timeframe=${getTimeframeLabel(selectedDateRange)}`,
        EVENT_TYPES.DASHBOARD_FILTERS_APPLIED,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state, dashboardPath),
      );
      return next(action);
    }
    case DASHBOARDS_EVENT_TYPES.DASHBOARD_RESET_CLICKED: {
      const dashboardPath = action.payload;
      reportEvent(
        '',
        EVENT_TYPES.DASHBOARD_RESET_CLICKED,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state, dashboardPath),
      );
      return next(action);
    }
    case DASHBOARDS_EVENT_TYPES.DASHBOARD_PAGE_VIEWED: {
      const dashboardPath = action.payload;
      reportEvent(
        '',
        EVENT_TYPES.DASHBOARD_PAGE_VIEWED,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state, dashboardPath),
      );
      return next(action);
    }
    case DASHBOARDS_EVENT_TYPES.DASHBOARD_PAGE_LOADED: {
      const { loadingTime, dashboardPath } = action.payload;
      reportEvent(
        `time_to_load=${loadingTime}`,
        EVENT_TYPES.DASHBOARD_PAGE_LOADED,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state, dashboardPath),
      );
      return next(action);
    }
    case fetchChartDataRejected.type: {
      const { error, dashboardPath, chartName } = action.payload;
      reportEvent(
        `component=chart: ${chartName}, error_message=${error}`,
        EVENT_TYPES.DASHBOARD_ERROR,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state, dashboardPath),
      );
      return next(action);
    }
    case fetchFilterDataRejected.type: {
      const { error } = action.payload;
      reportEvent(
        `component=filter, error_message=${error}`,
        EVENT_TYPES.DASHBOARD_ERROR,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state),
      );
      return next(action);
    }
    case fetchDisclaimerRejected.type: {
      const error = action.payload;
      reportEvent(
        `component=disclaimer, error_message=${error}`,
        EVENT_TYPES.DASHBOARD_ERROR,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state),
      );
      return next(action);
    }
    case DASHBOARDS_EVENT_TYPES.DASHBOARD_DISCLAIMER_VIEWABLE: {
      const dashboardPath = action.payload;
      reportEvent(
        '',
        EVENT_TYPES.DASHBOARD_DISCLAIMER_VIEWABLE,
        DASHBOARD_EVENT_CATEGORY,
        createDashboardsCustomDimensions(state, dashboardPath),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_SHARE_CLICKED: {
      reportEvent(
        '',
        EVENT_TYPES.QUERY_BUILDER_SHARE_CLICKED,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_SCHEDULE_REPORT_CREATED: {
      const scheduleParams = queryBuilderSelector.scheduleParams(state);
      const { emailAddresses, timeZone, schedulePeriod, scheduleTime } = scheduleParams;
      reportEvent(
        `email=${emailAddresses.toString()}; timezone=${timeZone}; report_sending_time=${schedulePeriod}, ${scheduleTime}`,
        EVENT_TYPES.QUERY_BUILDER_SCHEDULE_REPORT_CREATED,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_RUN_REPORT: {
      const queryParams = queryBuilderSelector.queryParams(state);
      const { metrics, dimensions } = queryParams;
      reportEvent(
        `metrics=${metrics}; dimensions=${dimensions}`,
        EVENT_TYPES.QUERY_BUILDER_RUN_REPORT,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_CLEAR_ALL_CLICKED: {
      reportEvent(
        '',
        EVENT_TYPES.QUERY_BUILDER_CLEAR_ALL_CLICKED,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_SAVED_REPORT_LOADED: {
      const { id, name } = action.payload;
      reportEvent(
        '',
        EVENT_TYPES.QUERY_BUILDER_SAVED_REPORT_LOADED,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state, id, name),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_GENERATE_API_KEY: {
      reportEvent(
        '',
        EVENT_TYPES.QUERY_BUILDER_GENERATE_API_KEY,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_SCHEDULE_REPORTS_VIEWED: {
      reportEvent(
        '',
        EVENT_TYPES.QUERY_BUILDER_SCHEDULE_REPORTS_VIEWED,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_DELETE_SCHEDULE_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_FETCH_REPORTS_LIST_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_UPDATE_SCHEDULE_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_FETCH_DATA_SOURCE_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_FETCH_REPORT_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_FETCH_SCHEDULE_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_LOAD_REPORT_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_SAVE_REPORT_ERROR:
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_SAVE_SCHEDULE_ERROR: {
      reportEvent(
        `request=${getRequestFromAction(action.type)}, error_message=${action.payload}`,
        EVENT_TYPES.QUERY_BUILDER_ERROR,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state),
      );
      return next(action);
    }
    case QUERY_BUILDER_EVENT_TYPES.QUERY_BUILDER_DELETE_SAVED_REPORT: {
      const { id, name } = action.payload;
      reportEvent(
        `name=${name}`,
        EVENT_TYPES.QUERY_BUILDER_SAVED_REPORT_DELETED,
        QUERY_BUILDER_EVENT_CATEGORY,
        createQueryBuilderCustomDimensions(state, id, name),
      );
      return next(action);
    }
    default:
      return next(action);
  }
};
