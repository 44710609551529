export const CMS_ROUTES_BASE = '/cms';

export const CMS_ROUTES = {
  MAIN_DASHBOARD: '/',
  ECHO_DASHBOARD: `${CMS_ROUTES_BASE}/distribution`,
  ECHO_CUSTOMIZATION: `${CMS_ROUTES_BASE}/distribution/:property/:id`,
  ECHO_SCHEDULING: `${CMS_ROUTES_BASE}/schedule`,
  ECHO_PREVIEW: `${CMS_ROUTES_BASE}/distribution/:property/:id/preview`,
  EDITOR_DASHBOARD: `${CMS_ROUTES_BASE}/editor`,
  EDITOR_PAGE: `${CMS_ROUTES_BASE}/editor/:property/:id`,
  EDITOR_PREVIEW: `${CMS_ROUTES_BASE}/editor/:property/:id/preview`,
  PN_DASHBOARD: `${CMS_ROUTES_BASE}/pn/:property/:id`,
  CONTENT_ENRICHMENT_DASHBOARD: `${CMS_ROUTES_BASE}/content-enrichment`,
};
