import React from 'react';
import { Icon } from '@ftbpro/mm-admin-ui-components';
import { CrowdSignalIcon } from '@ftbpro/mm-admin-assets';
import { TopBarIconProps } from '../LiveBlog/liveBlog.types';
import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';
import { CrowdSignalPanelComponent } from './components/CrowdSignalPanelComponent';
import { CrowdSignalBlock } from './components/CrowdSignalBlock';
import { CrowdSignalEmbedOverview } from './components/CrowdSignaleOverview';
import { CrowdSignalBlockProps, CrowdSignalPanelComponentProps } from './crowdSignal.types';

export const crowdSignalEmbedPluginData = {
  getPluginTopBarButtonIcon: (props: TopBarIconProps) => {
    return <Icon icon={CrowdSignalIcon} width={28} height={28} {...props} />;
  },

  getPluginPanelComponent: (props: CrowdSignalPanelComponentProps) => <CrowdSignalPanelComponent {...props} />,
  getPluginBlock: (props: CrowdSignalBlockProps) => <CrowdSignalBlock {...props} />,
  getPluginOverviewBlock: () => <CrowdSignalEmbedOverview />,
  pluginBlockType: BLOCK_TYPES.CROWD_SIGNAL,
};
