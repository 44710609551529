import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { navigateToPage } from '../routing/history';

export const onRedirectCallback = (appState: any) => {
  const targetUrl = appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname;
    navigateToPage(targetUrl);
};

const ID_TOKEN = 'id_token';
const DEEP_LINK_PARAMETER = 'deep_link';
const DEEP_LINK_INDICATION = `?${DEEP_LINK_PARAMETER}=`;
const BLOCKED_USER_ERROR_DESCRIPTION = 'user is blocked';
const ERROR_DESCRIPTION = 'error_description';

const cleanDeepLink = (deepLink: string) => deepLink.replace(/&.*/, '');

const getDeepLinkQUery = (deepLink : string) => { return !isEmpty(deepLink) ? `${DEEP_LINK_INDICATION}${cleanDeepLink(deepLink)}` : ''; };

export const getCurrentUrlForRedirect = (currentUrl = window.location.href, baseUrl = window.location.origin) => {
  const regex = new RegExp(`^(${baseUrl})(([\\/\\w]*$)|((\\/)*(\\${DEEP_LINK_INDICATION})(.+)))`);
  const matches = currentUrl.match(regex);
  const [,,, pathname,,,, deepLink] = matches || [];
  const newRedirectPath = `${baseUrl}${getDeepLinkQUery(deepLink) || getDeepLinkQUery(pathname)}`;
  return { redirectPath: newRedirectPath, deepLink: deepLink || pathname };
};

export const getToken = () => localStorage.getItem(ID_TOKEN);

export const isUserBlocked = (params: string) => {
  const searchParams = new URLSearchParams(params);
  const errorDescription = searchParams.get(ERROR_DESCRIPTION);
  return errorDescription === BLOCKED_USER_ERROR_DESCRIPTION;
};
