import { capitalizeEveryFirstLetter } from '../../../../../core/utils/text.utils';

import { SUPPORTED_EXTENSIONS } from './LogoField/logoField.constants';

export const validateLogo = image => new Promise((resolve, reject) => {
  // TODO refactor to use supported extensions as array, not string
  return image.type === SUPPORTED_EXTENSIONS ? resolve(image) : reject();
});

export const getImageFormatStringParams = extension => `format=${extension}`;

const convertValueToPixels = (first, second) => Math.round(first * (second / 100));

export const getCropSizes = (crop, imageData) => ({
  width: convertValueToPixels(imageData.width, crop.width),
  height: convertValueToPixels(imageData.height, crop.height),
  x: convertValueToPixels(imageData.width, crop.x),
  y: convertValueToPixels(imageData.height, crop.y),
});

export const getImageCropStringParams = (crop, imageData) => {
  if (crop) {
    const { width, height, x, y } = getCropSizes(crop, imageData);
    return `&transformation=c_crop,w_${width},h_${height},x_${x},y_${y}`;
  }
  return '';
};

export const isImageAnUrl = img => typeof img === 'string' && img.startsWith('http');

export const validateImageByWidth = (image, width = 0) => image && image.imageData.width >= width;

export const validateImageByHeight = (image, height = 0) => image && image.imageData.height >= height;

export const validateImageByExtension = (image, supportedExtensions) => image && !!supportedExtensions.find(type => type === image.imageData.file.type);

export const replaceHttpToHttpsIfNeedTo = str => (str.startsWith('https') ? str : str.replace('http', 'https'));

export const getImageUploadStringParams = ({ extension, crop, imageData }) => {
  return `${getImageFormatStringParams(extension)}${getImageCropStringParams(crop, imageData)}`;
};

export const formatNavigationToolbarItemTooltip = itemType => `Add ${capitalizeEveryFirstLetter(itemType)}`;
