import { ConfigsProvider } from '../../../../core/configs/ConfigsProvider';

const { CRAFT_API_BASE_URL } = ConfigsProvider;

const ORGANIZATIONS = '/organizations/';

const IN_THIS_STORY = '/inthisstory';

export class InThisStoryDataProvider {
  static getInThisStoryByOrganization(organization: string) {
    return `${CRAFT_API_BASE_URL}${ORGANIZATIONS}${organization}${IN_THIS_STORY}`;
  }
}
