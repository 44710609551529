import { RootState } from 'core/store';
import { getEditorState } from '../editor.selector';
import { PostIntroSliceState } from './postIntroSlice';

const getPostIntroSliceState = (state: RootState): PostIntroSliceState => getEditorState(state).intro;

export const postIntroSelector = {
  postIntro: (state: RootState) => getPostIntroSliceState(state).postIntro,
  postIntroUpdatedValue: (state: RootState) => getPostIntroSliceState(state).postIntroUpdatedValue,
  isEditing: (state: RootState) => getPostIntroSliceState(state).isEditing,
  postIntroError: (state: RootState) => getPostIntroSliceState(state).postIntroError,
};
