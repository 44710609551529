import { RootState } from 'core/store';
import { AnalyticsSliceState } from './types';

export const getAnalyticsSlice = (state: RootState): AnalyticsSliceState => state.contributorsAnalytics;

export const analyticsSelector = {
  stats: (state: RootState) => getAnalyticsSlice(state).stats,
  siteStatsDate: (state: RootState) => getAnalyticsSlice(state).siteStatsDate,
  totalStats: (state: RootState) => getAnalyticsSlice(state).totalStats,
  statsSortConfig: (state: RootState) => getAnalyticsSlice(state).statsSortConfig,
  loading: (state: RootState) => getAnalyticsSlice(state).loading,
  userStats: (state: RootState) => getAnalyticsSlice(state).userStats,
  userStatsDate: (state: RootState) => getAnalyticsSlice(state).userStatsDate,
  userStatsTab: (state: RootState) => getAnalyticsSlice(state).userStatsTab,
  paymentsStats: (state: RootState) => getAnalyticsSlice(state).paymentsStats,
  paymentsStatsDate: (state: RootState) => getAnalyticsSlice(state).paymentsStatsDate,
  paymentsLoading: (state: RootState) => getAnalyticsSlice(state).paymentsLoading,
  usersLoading: (state: RootState) => getAnalyticsSlice(state).usersLoading,
  postsLoading: (state: RootState) => getAnalyticsSlice(state).postsLoading,
  postStats: (state: RootState) => getAnalyticsSlice(state).postStats,
  postStatsDate: (state: RootState) => getAnalyticsSlice(state).postStatsDate,
  postProperty: (state: RootState) => getAnalyticsSlice(state).postProperty,
  postPagination: (state: RootState) => getAnalyticsSlice(state).postPagination,
  postQuery: (state: RootState) => getAnalyticsSlice(state).postQuery,
  error: (state: RootState) => getAnalyticsSlice(state).error,
  authors: (state:RootState) => getAnalyticsSlice(state).authors,
  siteStatsTab: (state: RootState) => getAnalyticsSlice(state).siteStatsTab,
};
