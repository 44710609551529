import {
  BLANK_URL, ERROR_MESSAGES, HTTP,
  HTTPS, IMAGE_MAX_SIZE_IN_BYTES,
  IMAGE_MIN_HEIGHT, IMAGE_MIN_WIDTH, NO_FOLLOW_URL,
} from './imageService.constants';

export const UNCROPPED_PREFIX = 'uncropped-';
export const IMAGE_NAME_MAX_LENGTH_FOR_UPLOAD = 50;

export const validateImageSize = fileSize => fileSize < IMAGE_MAX_SIZE_IN_BYTES;

export const validateImageDimensions = ({ width, height }) => {
  return !((width < IMAGE_MIN_WIDTH) || (height < IMAGE_MIN_HEIGHT));
};

export const convertValueToPixels = (first, second) => Math.round(first * (second / 100));

export const getCropStringParams = (image, crop) => {
  if (crop) {
    const newWidth = convertValueToPixels(image.width, crop.width);
    const newHeight = Math.floor(newWidth / crop.aspect);
    const startX = convertValueToPixels(image.naturalWidth, crop.x);
    const startY = convertValueToPixels(image.naturalHeight, crop.y);
    return `&transformation=c_crop,w_${newWidth},h_${newHeight},x_${startX},y_${startY}`;
  }
  return '';
};

export const getImageFormatStringParams = () => {
  return 'format=jpg';
};

export const isImageAnUrl = img => typeof img === 'string' && img.startsWith('http');

export const replaceHttpToHttpsIfNeedTo = str => (str.startsWith('https') ? str : str.replace('http', 'https'));

// TODO: refactor the async design of this function since nothing here is async
export const validateImageFile = image => new Promise((resolve, reject) => {
  const { imageData, imageSrc } = image;
  const { width, height } = imageData;
  if (validateImageSize(imageData.file.size)) {
    const valid = validateImageDimensions({ width, height });
    return valid ? resolve(imageSrc) : reject(ERROR_MESSAGES.IMAGE_DIMENSIONS_TOO_SMALL);
  }
  return reject(ERROR_MESSAGES.IMAGE_SIZE_TOO_LARGE);
});

export const removeForbiddenCharsFromImageName = name => {
  return name.replace(/[^-_\s\w\d]/g, '').replace(/\s/g, '-');
};

const trimImageName = (name, length) => {
  return name.substring(0, length);
};

export const formatImageNameForUpload = name => {
  return trimImageName(removeForbiddenCharsFromImageName(name), IMAGE_NAME_MAX_LENGTH_FOR_UPLOAD);
};

export const removeFileExtension = name => name.substring(0, name.lastIndexOf('.'));

export const getImageUploadStringParams = (imageData, crop) => {
  return new Promise(resolve => {
    const imageObj = new Image();
    imageObj.onload = () => {
      resolve(`${getImageFormatStringParams()}${getCropStringParams(imageObj, crop)}`);
    };
    if (isImageAnUrl(imageData)) {
      imageObj.src = replaceHttpToHttpsIfNeedTo(imageData);
    } else {
      imageObj.src = imageData;
    }
  });
};

export const getRealImageObject = imageData => {
  return new Promise(resolve => {
    const imageObj = new Image();
    imageObj.onload = () => {
      resolve(imageObj);
    };
    if (isImageAnUrl(imageData)) {
      imageObj.src = replaceHttpToHttpsIfNeedTo(imageData);
    } else {
      imageObj.src = imageData;
    }
  });
};

export const CROP_ASPECT_TYPES = {
  VERTICAL: 3 / 4,
  HORIZONTAL: 16 / 9,
  SQUARE: 1,
  LANDSCAPE: 3 / 2,
  PORTRAIT: 2 / 3,
};

export const CROP_ASPECT_TYPES_NAMES = {
  VERTICAL: '3/4',
  HORIZONTAL: '16/9',
  SQUARE: '1/1',
  LANDSCAPE: '3/2',
  PORTRAIT: '2/3',
  ORIGINAL: 'original',
};

export const CROP_ASPECT_TYPES_LABELS = {
  VERTICAL: '3:4',
  HORIZONTAL: '16:9',
  SQUARE: '1:1',
  LANDSCAPE: '3:2',
  PORTRAIT: '2:3',
  ORIGINAL: 'Original',
};

export const ORIGINAL_ASPECT = {
  label: CROP_ASPECT_TYPES_LABELS.ORIGINAL,
  value: 'Omit',
};

export const CROP_OPTIONS = Object.keys(CROP_ASPECT_TYPES).map(key => {
  return { label: CROP_ASPECT_TYPES_LABELS[key], value: CROP_ASPECT_TYPES[key] };
});

export const CROP_ASPECT_NAME_TO_VALUE = {
  [CROP_ASPECT_TYPES_NAMES.VERTICAL]: CROP_ASPECT_TYPES.VERTICAL,
  [CROP_ASPECT_TYPES_NAMES.HORIZONTAL]: CROP_ASPECT_TYPES.HORIZONTAL,
  [CROP_ASPECT_TYPES_NAMES.SQUARE]: CROP_ASPECT_TYPES.SQUARE,
  [CROP_ASPECT_TYPES_NAMES.LANDSCAPE]: CROP_ASPECT_TYPES.LANDSCAPE,
  [CROP_ASPECT_TYPES_NAMES.PORTRAIT]: CROP_ASPECT_TYPES.PORTRAIT,
  [CROP_ASPECT_TYPES_NAMES.ORIGINAL]: ORIGINAL_ASPECT.value,
};

export const getInitialPercentageCrop = aspect => {
  return {
    x: 0,
    y: 0,
    aspect,
    height: 100,
    unit: '%',
  };
};

export const getCropAspectName = (type, hasOriginal = false) => {
  const cropAspectTypes = hasOriginal ? { ...CROP_ASPECT_TYPES, ORIGINAL: ORIGINAL_ASPECT.value } : CROP_ASPECT_TYPES;
  const typeName = Object.keys(cropAspectTypes).find(key => cropAspectTypes[key] === type);
  return CROP_ASPECT_TYPES_NAMES[typeName];
};

export const getUncroppedImageName = name => `${UNCROPPED_PREFIX}${name}`;

export const substringImageNameFromUrl = url => {
  const lastDashIndex = url.lastIndexOf('-');
  const name = lastDashIndex ? url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('-')) : url.substring(url.lastIndexOf('/'));
  return (name.startsWith(UNCROPPED_PREFIX)) ? name.replace(UNCROPPED_PREFIX, '') : name;
};

export const validateLinkableImage = link => [HTTP, HTTPS].some(protocol => link.startsWith(protocol));

export const formatImgLinkObject = ({ linkableImageSettings }) => {
  const { targetLink, shouldOpenNewTab, shouldNoFollow } = linkableImageSettings;
  return {
    linkURL: targetLink,
    linkTargetAttribute: shouldOpenNewTab ? BLANK_URL : '',
    linkRelAttribute: shouldNoFollow ? NO_FOLLOW_URL : '',
  };
};

export const substringImageCaptionAndCredit = (str, maxLen = 100, separator = ' ') => {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

export const getPixelCroppingAndUrl = ({ image, crop, url }) => {
  const newWidth = convertValueToPixels(image.width, crop.width);
  const newHeight = Math.floor(newWidth / crop.aspect);
  const startX = convertValueToPixels(image.naturalWidth, crop.x);
  const startY = convertValueToPixels(image.naturalHeight, crop.y);
  const croppingParams = crop.aspect !== ORIGINAL_ASPECT.value ? `/c_crop,w_${newWidth},h_${newHeight},x_${startX},y_${startY}` : '';
  const position = url.indexOf('upload') + 'upload'.length;

  return {
    url: url.substr(0, position) + croppingParams + url.substr(position),
    cropping: {
      width: newWidth,
      height: newHeight,
      x: startX,
      y: startY,
    },
  };
};
