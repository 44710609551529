import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { NetworkAction } from './networkMiddleware.constans';
import { organizationSelector } from '../../store/organization/organization.selector';
import { userSelector } from '../../store/user/user.selector';

export const isNetworkAction = (actionType: AnyAction['type']): actionType is NetworkAction => {
  return Object.values(NetworkAction).indexOf(actionType) !== -1;
};

export const getMMRequestConfig = (state: RootState, headers?: Record<string, string>) => {
  const token = userSelector.getUserToken(state);
  const tenant = organizationSelector.currentTenant(state);

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'MM-Tenant': tenant,
      ...headers,
    },
    mode: 'cors',
    cache: 'default',
  };
};
