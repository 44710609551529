import { propertySelector } from '../../../core/store/property/property.selector';
import { CMS } from '../constants/cms.constants';
import { draftSelector } from '../editor/store/draft/draft.selector';
import { userSelector } from '../../../core/store/user/user.selector';

export const createCustomDimensions = state => {
  const property = propertySelector.currentPropertySlug(state, CMS);
  const articleId = draftSelector.draftId(state) || 'none';
  const platformUserId = userSelector.getUserId(state);
  const platformUserRole = userSelector.getUserRoles(state);

  return {
    property,
    articleId,
    platformUserId,
    platformUserRole,
  };
};
