import { ConfigsProvider } from '../../../../core/configs/ConfigsProvider';

const { MATCHES_SERVICES_BASE_URL } = ConfigsProvider;
const POSTS = '/posts';
const VALIDATE = '/validate';
const PROPERTIES = '/properties';

export class MatchesServiceConfigsProvider {
  static getIsValidUrlEndpoint({ postId, property }) {
    return `${MATCHES_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${VALIDATE}`;
  }
}
