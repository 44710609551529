import { AggFuncType } from '@ftbpro/mm-admin-ui-components';
import { ChartData, TableFormats } from './dashboards.types';
import { createNewFilter } from '../../pages/DashboardPage/dashboardPage.utils';
import {
  ChartsFormat,
  ChartsType,
  DASHBOARDS_DATA_SOURCE_ID,
  DATE_INITIAL_VALUE,
  FilterId,
  MM_SSP_FILTERS_INITIAL_VALUE,
  UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
  VOLTAX_REVENUE_FILTERS_INITIAL_VALUE,
} from './dashboards.constants';
import { DashboardsPaths } from '../../reports.constants';
import { formatDateObject } from './dashboards.formatters';

export const dashboardsData: Record<DashboardsPaths, ChartData[]> = {
  [DashboardsPaths.MMSSPRevenue]: [
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['total'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Revenue',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['impressions'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Impressions',
        orderBy: 'impressions',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['net_cpm'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Avg CPM',
        orderBy: 'net_cpm',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.LineGraph,
      chartFormat: ChartsFormat.Currency,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['total'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.BarChart,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['impressions'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Impressions',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['country'],
        metrics: ['total'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Country (Top 10)',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 10,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['device_category'],
        metrics: ['total'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Device',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['placement'],
        metrics: ['total'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Placement',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Table,
      chartFormat: ChartsFormat.Currency,
      chartLine: 3,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['domain'],
        metrics: ['mm_marketplace_revenue', 'total', 'pubs_revenue'],
        filters: [createNewFilter(MM_SSP_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Site',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
  ],
  [DashboardsPaths.RevenueOverview]: [
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Total Revenue',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['mm_marketplace_revenue'],
        filters: [],
        constraints: [],
        name: 'MM Marketplace Revenue',
        orderBy: 'mm_marketplace_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['pubs_revenue'],
        filters: [],
        constraints: [],
        name: "Publisher's Demand Revenue",
        orderBy: 'pubs_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['net_payout'],
        filters: [],
        constraints: [],
        name: 'Estimated Net Payout',
        orderBy: 'net_payout',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.LineGraph,
      chartFormat: ChartsFormat.Currency,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.BarChart,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['impressions'],
        filters: [],
        constraints: [],
        name: 'Net Impressions',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['country'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Country (Top 10)',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 10,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['device_category'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Device',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['activity'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Activity',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Table,
      chartFormat: ChartsFormat.Currency,
      chartLine: 3,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['domain'],
        metrics: ['mm_marketplace_revenue', 'total', 'pubs_revenue'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Site',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
  ],
  [DashboardsPaths.Homepage]: [
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Total Revenue',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['impressions'],
        filters: [],
        constraints: [],
        name: 'Total Impressions',
        orderBy: 'impressions',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['net_cpm'],
        filters: [],
        constraints: [],
        name: 'Avg CPM',
        orderBy: 'net_cpm',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.LineGraph,
      chartFormat: ChartsFormat.Currency,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Day',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['placement'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Placement',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['country'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Country (Top 10)',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 10,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['device_category'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Device',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['os'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by OS',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
  ],
  [DashboardsPaths.VoltaxRevenue]: [
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['total'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Total Player Revenue',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['mm_marketplace_revenue'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'MM Marketplace Revenue',
        orderBy: 'mm_marketplace_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['pubs_revenue'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: "Publisher's Demand Revenue",
        orderBy: 'pubs_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['player_loads'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Player Loads',
        orderBy: 'player_loads',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 0,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['impressions'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Impressions',
        orderBy: 'impressions',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.LineGraph,
      chartFormat: ChartsFormat.Currency,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['total'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.BarChart,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 1,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['impressions'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Impressions',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['country'],
        metrics: ['total'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Country (Top 10)',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 10,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['device_category'],
        metrics: ['total'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Device',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['placement'],
        metrics: ['total'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Placement',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Table,
      chartFormat: ChartsFormat.Currency,
      chartLine: 3,
      data: {
        dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
        dimensions: ['domain'],
        metrics: ['mm_marketplace_revenue', 'total', 'pubs_revenue'],
        filters: [createNewFilter(VOLTAX_REVENUE_FILTERS_INITIAL_VALUE)],
        constraints: [],
        name: 'Net Revenue by Site',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
  ],
  [DashboardsPaths.RevenueOverviewSummary]: [
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Total Revenue',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['stn_marketplace_revenue'],
        filters: [],
        constraints: [],
        name: 'STN Marketplace Revenue',
        orderBy: 'stn_marketplace_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['pubs_revenue'],
        filters: [],
        constraints: [],
        name: "Publisher's Demand Revenue",
        orderBy: 'pubs_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['net_payout'],
        filters: [],
        constraints: [],
        name: 'Estimated Net Payout',
        orderBy: 'net_payout',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.LineGraph,
      chartFormat: ChartsFormat.Currency,
      compactNotation: true,
      chartLine: 1,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.BarChart,
      chartFormat: ChartsFormat.Decimal,
      compactNotation: true,
      chartLine: 1,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['impressions'],
        filters: [],
        constraints: [],
        name: 'Net Impressions',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['country'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Country (Top 10)',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 10,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['device_category'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Device',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['demand_owner'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Demand Owner',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Table,
      chartFormat: ChartsFormat.Currency,
      chartLine: 3,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['domain'],
        metrics: ['stn_marketplace_revenue', 'total', 'pubs_revenue'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Site',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
  ],
  [DashboardsPaths.UnifiedPlayerRevenue]: [
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Total Player Revenue',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['stn_marketplace_revenue'],
        filters: [],
        constraints: [],
        name: 'STN Marketplace Revenue',
        orderBy: 'stn_marketplace_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Currency,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['pubs_revenue'],
        filters: [],
        constraints: [],
        name: "Publisher's Demand Revenue",
        orderBy: 'pubs_revenue',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['player_loads'],
        filters: [],
        constraints: [],
        name: 'Player Loads',
        orderBy: 'player_loads',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Decimal,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['impressions'],
        filters: [],
        constraints: [],
        name: 'Impressions',
        orderBy: 'impressions',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Stats,
      chartFormat: ChartsFormat.Percent,
      chartLine: 0,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: [],
        metrics: ['viewability'],
        filters: [],
        constraints: [],
        name: 'Viewable Impressions',
        orderBy: 'viewability',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.LineGraph,
      chartFormat: ChartsFormat.Currency,
      compactNotation: true,
      chartLine: 1,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.BarChart,
      chartFormat: ChartsFormat.Decimal,
      compactNotation: true,
      chartLine: 1,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['date'],
        metrics: ['impressions'],
        filters: [],
        constraints: [],
        name: 'Net Impressions',
        orderBy: 'date',
        isAscending: true,
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['country'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Country (Top 10)',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 10,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['device_category'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Device',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.PieChart,
      chartFormat: ChartsFormat.Currency,
      chartLine: 2,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['os'],
        metrics: ['total'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by OS',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
    {
      chartType: ChartsType.Table,
      chartFormat: ChartsFormat.Currency,
      chartLine: 3,
      data: {
        dataSourceID: UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID,
        dimensions: ['domain'],
        metrics: ['stn_marketplace_revenue', 'total', 'pubs_revenue', 'viewability'],
        filters: [],
        constraints: [],
        name: 'Net Revenue by Site',
        orderBy: 'total',
        noiseReduction: false,
        noiseReductionConfig: '',
        timezone: 'UTC',
        limit: 5000,
      },
    },
  ],
};

export const reportInitialData: { [key: string]: any } = {
  dataSourceID: DASHBOARDS_DATA_SOURCE_ID,
  dimensions: [],
  metrics: [],
  dateRange: formatDateObject(DATE_INITIAL_VALUE),
  filters: [],
  constraints: [],
  name: 'Filters',
  orderBy: '',
  noiseReduction: false,
  noiseReductionConfig: '',
  timezone: 'UTC',
  limit: 5000,
};

export const filtersPerDashboard: Record<DashboardsPaths, FilterId[]> = {
  [DashboardsPaths.RevenueOverview]: [FilterId.Domain, FilterId.PlacementId, FilterId.Country, FilterId.Device, FilterId.Activity, FilterId.OS, FilterId.DemandSource, FilterId.PlayerName],
  [DashboardsPaths.VoltaxRevenue]: [FilterId.Placement, FilterId.Device, FilterId.Country, FilterId.Domain, FilterId.OS, FilterId.PlayerName],
  [DashboardsPaths.MMSSPRevenue]: [FilterId.Placement, FilterId.Country, FilterId.OS, FilterId.Domain, FilterId.PlacementId, FilterId.Device, FilterId.DemandSource, FilterId.PlayerName],
  [DashboardsPaths.Homepage]: [],
  [DashboardsPaths.RevenueOverviewSummary]: [FilterId.Domain, FilterId.Country, FilterId.Device, FilterId.OS, FilterId.PlayerName, FilterId.DemandOwner, FilterId.Browser, FilterId.DemandType],
  [DashboardsPaths.UnifiedPlayerRevenue]: [FilterId.Device, FilterId.Country, FilterId.Domain, FilterId.OS, FilterId.PlayerName],
};

export const tableFormats: TableFormats = {
  [DashboardsPaths.UnifiedPlayerRevenue]: { viewability: { tableformatsStyle: ChartsFormat.Percent, aggFunc: AggFuncType.Avg } },
  [DashboardsPaths.Homepage]: {},
  [DashboardsPaths.RevenueOverview]: {},
  [DashboardsPaths.MMSSPRevenue]: {},
  [DashboardsPaths.VoltaxRevenue]: {},
  [DashboardsPaths.RevenueOverviewSummary]: {},
};
