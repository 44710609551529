import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { buildQueryFromObject, copyToClipboard } from '@ftbpro/mm-admin-core-utils';
import { AppDispatch, RootState } from 'core/store';
import { Pagination } from 'core/types';
import { dismissGlobalNotification, pushGlobalNotificationActionCreator } from 'core/globalNotifications/store/globalNotifications.actions';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import { ContentEnrichmentConfigsProvider } from '../configs/ContentEnrichmentConfigsProvider';
import { CONTENT_ENRICHMENT_INITIAL_STATE } from '../contentEnrichment.constants';
import {
  EMBED_BUTTONS,
  EMBED_ERROR,
  EMBEDS_LIST_STRINGS,
  FEEDS_ERROR,
  INVALID_URL,
  URL_EXIST,
} from '../contentEnrichment.strings';
import { Embed, EmbedData, EmbedQuery, FeedFromServer, Team } from './types';
import {
  formatFeedsFromServer,
  formatPostDataFromServer,
} from './contentEnrichment.formatters';
import { isUrlExist } from '../contentEnrichment.utils';

const title = 'contentEnrichment';

const contentEnrichmentSlice = createSlice({
  name: title,
  initialState: CONTENT_ENRICHMENT_INITIAL_STATE,
  reducers: {
    setEmbedPreview(state, action: PayloadAction<boolean>) {
      state.preview = action.payload;
    },
    setEmbedData(state, action: PayloadAction<EmbedData>) {
      state.embedData = action.payload;
    },
    updateEmbedsQuery(state, action: PayloadAction<EmbedQuery>) {
      state.embedsQuery = { ...state.embedsQuery, ...action.payload };
    },
    setModalOpen(state, action: PayloadAction<boolean>) {
      state.modalOpen = action.payload;
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
    setEmbedId(state, action: PayloadAction<string>) {
      state.embedId = action.payload;
    },
    fetchEmbedsListPending: state => {
      state.loading = true;
    },
    fetchEmbedsListSuccess: (
      state,
      action: PayloadAction<{ embeds: Embed[]; pagination: Pagination }>,
    ) => {
      state.loading = false;
      const { embeds, pagination } = action.payload;
      state.embedsList = embeds;
      state.totalEmbeds = pagination.count || 1;
    },
    fetchEmbedsListRejected: (
      state,
      action: PayloadAction<{ message: string }>,
    ) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    deleteEmbedPending: state => {
      state.loading = true;
    },
    deleteEmbedSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.embedsList = state.embedsList.filter(
        (e: Embed) => e.id !== action.payload,
      );
      state.totalEmbeds -= 1;
      state.modalOpen = false;
    },
    deleteEmbedRejected: (
      state,
      action: PayloadAction<{ message: string }>,
    ) => {
      state.loading = false;
      state.error = action.payload.message;
      state.modalOpen = false;
    },
    getEmbedDataPending(state) {
      state.isEmbedFormLoading = true;
    },
    getEmbedDataSuccess(state, action: PayloadAction<Embed>) {
      const { name, data, id } = action.payload;
      state.isEmbedFormLoading = false;
      state.embedId = id;
      state.embedData = { ...state.embedData, name, data };
    },
    getEmbedDataRejected(state) {
      state.isEmbedFormLoading = false;
    },
    getTeamsPending(state) {
      state.isTeamsListLoading = true;
    },
    getTeamsSuccess(state, action: PayloadAction<Team[]>) {
      state.teamsList = action.payload;
      state.isTeamsListLoading = false;
    },
    getTeamsRejected(state, action: PayloadAction<{ message: string }>) {
      state.error = action.payload.message;
      state.isTeamsListLoading = false;
    },
    getFeedsPending(state) {
      state.isEmbedFormLoading = true;
    },
    getFeedsSuccess(state, action: PayloadAction<FeedFromServer[]>) {
      state.isEmbedFormLoading = false;
      state.feedsList = formatFeedsFromServer(action.payload);
    },
    getFeedsRejected(state) {
      state.isEmbedFormLoading = false;
      state.feedsList = [];
    },
    setEmbedUrl(state, action: PayloadAction<string>) {
      state.embedUrl = action.payload;
    },
    getPostDataSuccess(state, action: PayloadAction<EmbedData>) {
      state.embedData = action.payload;
    },
    getPostDataRejected(state) {
      state.error = INVALID_URL;
    },
    saveEmbedPending(state) {
      state.loading = true;
    },
    saveEmbedSuccess(state, action: PayloadAction<Embed>) {
      state.loading = false;
      const embed = action.payload;
      state.isDrawerOpen = false;
      state.embedsQuery = { ...state.embedsQuery, page: 1 };
      state.embedUrl = embed.url;
    },
    saveEmbedRejected(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const fetchEmbedsList = (property: string, query: EmbedQuery) => async (dispatch: AppDispatch) => {
    const queryString = buildQueryFromObject(query);

    await dispatch(
      networkAction({
        url: ContentEnrichmentConfigsProvider.getContentEnrichmentEmbedsEndpoint(
          property,
          queryString,
        ),
        onPending: fetchEmbedsListPending,
        onSuccess: fetchEmbedsListSuccess,
        onFailure: fetchEmbedsListRejected,
      }),
    );
  };

export const getEmbedData = (property: string, id: string) => async (dispatch: AppDispatch) => {
    await dispatch(
      networkAction({
        url: ContentEnrichmentConfigsProvider.getContentEnrichmentEmbedEndpoint(
          property,
          id,
        ),
        onPending: getEmbedDataPending,
        onSuccess: getEmbedDataSuccess,
        onFailure: () => {
          dispatch(
            pushGlobalNotificationActionCreator({
              status: SNACK_BAR_STATUSES.ERROR,
              text: EMBED_ERROR,
            }),
          );
          return { type: getEmbedDataRejected.type };
        },
      }),
    );
  };

export const deleteEmbed = (id: string, property: string) => async (dispatch: AppDispatch) => {
    await dispatch(
      networkAction({
        url: ContentEnrichmentConfigsProvider.removeContentEnrichmentEmbedEndpoint(
          id,
          property,
        ),
        method: HttpMethod.Delete,
        onPending: deleteEmbedPending,
        onSuccess: () => {
          dispatch(
            pushGlobalNotificationActionCreator({
              status: SNACK_BAR_STATUSES.SUCCESS,
              text: EMBEDS_LIST_STRINGS.DELETE,
            }),
          );
          return { type: deleteEmbedSuccess.type, payload: id };
        },
        onFailure: error => {
          dispatch(
            pushGlobalNotificationActionCreator({
              status: SNACK_BAR_STATUSES.ERROR,
              text: EMBEDS_LIST_STRINGS.REJECTED,
            }),
          );
          return { type: deleteEmbedRejected.type, payload: error };
        },
      }),
    );
  };

export const getTeams = (property: string) => async (dispatch: AppDispatch) => {
  await dispatch(
    networkAction({
      url: ContentEnrichmentConfigsProvider.getContentEnrichmentTeamsEndpoint(
        property,
      ),
      onPending: getTeamsPending,
      onSuccess: getTeamsSuccess,
      onFailure: getTeamsRejected,
    }),
  );
};

export const getPostData = (property: string, url: string) => async (dispatch: AppDispatch, getState: RootState) => {
    await dispatch(
      networkAction({
        url: ContentEnrichmentConfigsProvider.getContentEnrichmentPostsEndpoint(
          property,
          url,
        ),
        onSuccess: data => {
          const { post } = data;
          const state = getState().cms.contentEnrichment;
          const { posts = [], withImages } = state.embedData?.data;
          const urlExists = isUrlExist(posts, post.id);
          const embedData = {
            ...state.embedData,
            error: urlExists ? URL_EXIST : '',
            ...(!urlExists
              ? {
                  data: {
                    withImages,
                    posts: [...posts, formatPostDataFromServer(post)],
                  },
                }
              : {}),
          };

          return { type: getPostDataSuccess.type, payload: embedData };
        },
        onFailure: getPostDataRejected,
      }),
    );
  };

export const saveEmbed = ({ embedType, state, data, property, name = '', id = '' }: EmbedData) => async (dispatch: AppDispatch) => {
    await dispatch(
      networkAction({
        url: ContentEnrichmentConfigsProvider.getContentEnrichmentEmbedEndpoint(
          property as string,
          id,
        ),
        method: id ? HttpMethod.Patch : HttpMethod.Post,
        data: {
          embedType,
          state,
          property,
          name,
          data,
        },
        onPending: saveEmbedPending,
        onSuccess: (embed: Embed) => {
          copyToClipboard(embed.url);
          dispatch(
            pushGlobalNotificationActionCreator({
              status: SNACK_BAR_STATUSES.SUCCESS,
              text: `${embedType} ${EMBEDS_LIST_STRINGS.COPY_ITEM}`,
              buttonText: EMBED_BUTTONS.URL,
              onButtonClick: () => {
                copyToClipboard(embed.url);
                dismissGlobalNotification(embedType as any)(dispatch);
              },
            }),
          );
          return { type: saveEmbedSuccess.type, payload: embed };
        },
        onFailure: error => {
          dispatch(
            pushGlobalNotificationActionCreator({
              status: SNACK_BAR_STATUSES.ERROR,
              text: 'The embed did not save.',
            }),
          );
          return { type: saveEmbedRejected.type, payload: error };
        },
      }),
    );
  };

export const getFeeds = (property: string) => async (dispatch: AppDispatch) => {
  await dispatch(
    networkAction({
      url: ContentEnrichmentConfigsProvider.getContentEnrichmentFeedsEndpoint(
        property,
      ),
      onPending: getFeedsPending,
      onSuccess: getFeedsSuccess,
      onFailure: () => {
        dispatch(
          pushGlobalNotificationActionCreator({
            status: SNACK_BAR_STATUSES.ERROR,
            text: FEEDS_ERROR,
          }),
        );
        return { type: getFeedsRejected.type };
      },
    }),
  );
};

export const {
  updateEmbedsQuery,
  setDrawerOpen,
  fetchEmbedsListPending,
  fetchEmbedsListSuccess,
  fetchEmbedsListRejected,
  setEmbedPreview,
  setEmbedData,
  setModalOpen,
  setEmbedId,
  deleteEmbedPending,
  deleteEmbedRejected,
  deleteEmbedSuccess,
  getEmbedDataRejected,
  getEmbedDataPending,
  getEmbedDataSuccess,
  getTeamsSuccess,
  getTeamsPending,
  getTeamsRejected,
  getFeedsPending,
  getFeedsSuccess,
  getFeedsRejected,
  setEmbedUrl,
  getPostDataSuccess,
  getPostDataRejected,
  saveEmbedPending,
  saveEmbedRejected,
  saveEmbedSuccess,
} = contentEnrichmentSlice.actions;

export default contentEnrichmentSlice.reducer;
