import { NullWorker } from './NullWorker';
import { QueryTaskConfig, QueryWorker } from './QueryWorker/QueryWorker';
import {
  TaskConfig,
  WebWorkerWrapper,
} from './WebWorkerWrapper';

function IsQueryTask(taskConfig: any): taskConfig is QueryTaskConfig {
  return taskConfig.url !== undefined;
}
export class WorkersFactory {
  static createWorker(taskConfig: TaskConfig): WebWorkerWrapper {
    if (IsQueryTask(taskConfig)) {
      return new QueryWorker(taskConfig);
    }

    return new NullWorker(taskConfig);
  }
}
