import React, { Suspense } from 'react';
import { Loader } from '@ftbpro/mm-admin-ui-components';
import { useStyles } from '../../hooks/useStyles';
import { getStylesObject } from './routes.styles';

export const LazyRoutes = ({ routes, defaultPage }) => {
  const styles = useStyles(getStylesObject, []);
  const Routes = routes;

  return (
    <div css={styles.allRoutesMainContainer}>
      <Suspense fallback={<Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />}>
        <Routes defaultPage={defaultPage} />
      </Suspense>
    </div>
  );
};
