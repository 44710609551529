import React from 'react';
import { css } from '@emotion/core';
import { Button, ButtonType, CssTooltip, Loader, Text, TOOLTIP_POSITIONS } from '@ftbpro/mm-admin-ui-components';

import { useStyles } from '../../../../../core/hooks/useStyles';

import { MediaOverlay } from '../../components/shared/MediaOverlay';

import { COLORS } from '../../../../../core/styles/colors';
import {
  PLUGIN_BUTTON_ADD_TEXT,
  PLUGIN_BUTTON_CANCEL_TEXT,
  PLUGIN_COPYRIGHT_INFORMATION, PLUGIN_COPYRIGHT_TOOLTIP_INFORMATION,
} from '../shared/plugins.constants';

import { getPluginStyles } from './plugin.styles';

export const PluginContainer = ({ children }) => {
  const { container } = getPluginStyles();
  return (
    <div css={css(container)} data-test-id="plugin-container">
      {children}
    </div>
  );
};

export const PluginContent = ({ children }) => {
  const { content } = getPluginStyles();
  return (
    <div css={css(content)} data-test-id="plugin-content">
      {children}
    </div>
  );
};

export const PluginButtons = ({
  cancelButtonText = PLUGIN_BUTTON_CANCEL_TEXT,
  addButtonText = PLUGIN_BUTTON_ADD_TEXT,
  onCancelClick,
  onAddClick,
  isCancelDisabled,
  isAddDisabled,
}) => {
  const { buttonsWrapper } = getPluginStyles();
  const buttonStyle = { marginRight: '16px' };
  return (
    <div css={css(buttonsWrapper)} data-test-id="plugin-buttons">
      <Button
        type={ButtonType.Secondary}
        disabled={isCancelDisabled}
        style={buttonStyle}
        onClick={onCancelClick}
      >
        {cancelButtonText}
      </Button>
      <Button
        type={ButtonType.Primary}
        disabled={isAddDisabled}
        style={buttonStyle}
        onClick={onAddClick}
      >
        {addButtonText}
      </Button>
    </div>
  );
};

export const PluginBlock = ({
  loading,
  children,
  onEdit,
  shouldPreventEdit,
  shouldPreventDelete,
  isEditing,
  onDelete,
  onCancel,
}) => {
  const { blockWrapper, mediaItem } = useStyles(getPluginStyles, [loading]);
  return (
    <div css={css(blockWrapper)} data-test-id="plugin-block">
      {loading && <Loader active={loading} />}
      <div css={css(mediaItem)}>
        {children}
        <MediaOverlay
          shouldPreventEdit={shouldPreventEdit}
          shouldPreventDelete={shouldPreventDelete}
          onDelete={onDelete}
          onEdit={onEdit}
          onCancelEdit={onCancel}
          isEditing={isEditing}
        />
      </div>
    </div>
  );
};

export const PluginOverviewBlock = ({ children }) => {
  const { blockOverviewWrapper, blockOverviewItem } = getPluginStyles();
  return (
    <div css={css(blockOverviewWrapper)}>
      <div css={css(blockOverviewItem)}>
        {children}
      </div>
    </div>
  );
};

export const PluginErrorMsgComponent = ({ text }) => {
  const { errorMessage } = getPluginStyles();
  return (
    <div css={css(errorMessage)} data-test-id="plugin-error">
      <Text type={Text.TEXT_TYPES.PARAGRAPH_M} color={COLORS.RED}>{text}</Text>
    </div>
  );
};

export const PluginCopyrightInformation = () => {
  const { copyrightInformation } = getPluginStyles();
  const { copyrightTooltip } = getPluginStyles();
  return (
    <div css={css(copyrightInformation)} data-test-id="plugin-copyrightInformation">
      <Text type={Text.TEXT_TYPES.PARAGRAPH_M} color={COLORS.BLACK50}>{PLUGIN_COPYRIGHT_INFORMATION}</Text>
      <div css={css(copyrightTooltip)}>
        <CssTooltip content={PLUGIN_COPYRIGHT_TOOLTIP_INFORMATION} position={TOOLTIP_POSITIONS.TOP_LEFT} multiline />
      </div>
    </div>
  );
};

export const Plugin = {
  Container: PluginContainer,
  Content: PluginContent,
  Buttons: PluginButtons,
  Block: PluginBlock,
  OverviewBlock: PluginOverviewBlock,
  ErrorMsgComponent: PluginErrorMsgComponent,
  CopyrightInformation: PluginCopyrightInformation,
};
