import { ContentEnrichmentSliceState } from './store/types';
import StoryLink from './assets/StoryLink.png';
import Grade from './assets/Grade.png';
import Matchup from './assets/Matchup.png';
import Rank from './assets/Rank.png';
import Score from './assets/Score.png';
import Trade from './assets/Trade.png';
import GroupOfLinks from './assets/GroupOfLinks.png';
import ThreeImageLinks from './assets/ThreeImageLinks.png';
import GroupOfExternalLinks from './assets/GroupOfExternalLinks.png';
import { SWIMSUIT_SLUG } from '../constants/cms.constants';

export const EMBEDS_LIST_PAGE_SIZE = 50;

export const image = {
  StoryLink,
  Grade,
  Matchup,
  Rank,
  Score,
  Trade,
  GroupOfLinks,
  ThreeImageLinks,
  GroupOfExternalLinks,
};

export const DEBOUNCE_DELAY = 300;

export const CONTENT_ENRICHMENT_INITIAL_STATE: ContentEnrichmentSliceState = {
  loading: false,
  error: '',
  embedsList: [],
  preview: false,
  embedData: {
    name: '',
    data: {},
  },
  embedsQuery: {
    embedType: '',
    page: 1,
    search: '',
  },
  totalEmbeds: 0,
  modalOpen: false,
  embedId: '',
  isDrawerOpen: false,
  isEmbedFormLoading: false,
  isTeamsListLoading: false,
  teamsList: [],
  feedsList: [],
  embedUrl: '',
};

export const allowedPropertiesForGOLWithImages = ['251', '253', SWIMSUIT_SLUG];
