import { RootState } from '../index';
import { NavigationFiltersState } from './navigationFilters.constants';

const getNavigationFiltersSliceState = (state: RootState) => state.navigationFilters as NavigationFiltersState;

export const navigationFiltersSelector = {
  filterType: (state: RootState) => getNavigationFiltersSliceState(state).filterType,
  product: (state: RootState) => getNavigationFiltersSliceState(state).product,
  disableTenantSelection: (state: RootState) => getNavigationFiltersSliceState(state).disableTenantSelection,
  disableOrganizationSelection: (state: RootState) => getNavigationFiltersSliceState(state).disableOrganizationSelection,
  disablePropertySelection: (state: RootState) => getNavigationFiltersSliceState(state).disablePropertySelection,
  preSelectedTenant: (state: RootState) => getNavigationFiltersSliceState(state).preSelectedTenant,
  preSelectedOrganization: (state: RootState) => getNavigationFiltersSliceState(state).preSelectedOrganization,
  preSelectedProperty: (state: RootState) => getNavigationFiltersSliceState(state).preSelectedProperty,
  shouldFilterByProduct: (state: RootState) => getNavigationFiltersSliceState(state).shouldFilterByProduct,
  allowedRoles: (state: RootState) => getNavigationFiltersSliceState(state).allowedRoles,
  customFilterData: (state: RootState) => getNavigationFiltersSliceState(state).customFilterData,
  customFilterSelectedValue: (state: RootState) => getNavigationFiltersSliceState(state).customFilterData?.selectedValue,
};
