import { getEditorState } from '../editor.selector';
import { postTitleSelector } from '../postTitle/postTitle.selector';
import { getDraftDataPayload } from '../../utils/editorServiceDataFormatter';
import { coverImageSelector } from '../coverImage/coverImage.selector';
import { blockListSelector } from '../blocks/blockList.selector';
import { removeAllBlockAdders } from '../../utils/blockList.utils';
import { tagsAndSeoSelector as tagsAndSeoSelectorSlice } from '../editorialMenu/tagsAndSeo/tagsAndSeo.selector';
import { getAnalysis } from '../../utils/draftAnalysis.utils';
import { socialSharingSelector } from '../editorialMenu/socialSharing/socialSharing.selector';
import { getIsGroupOfLinksExists } from '../../utils/draft.utils';
import { postIntroSelector } from '../postIntro/postIntro.selector';
import { inThisStorySelector } from '../editorialMenu/inThisStory/inThisStorySlice.selector';

function getDraftState(state) {
  return getEditorState(state).draft;
}

export const draftSelector = {
  draftId(state) {
    return getDraftState(state).currentDraftId;
  },
  isDraftBeingCreated(state) {
    return getDraftState(state).isDraftBeingCreated;
  },
  isLoading(state) {
    return getDraftState(state).isLoading;
  },
  originallyLoadedDraft(state) {
    return getDraftState(state).originallyLoadedDraft;
  },
  isPublished(state) {
    return getDraftState(state).hasBeenPublished;
  },
  publishedUrl(state) {
    return getDraftState(state).url;
  },
  undoList(state) {
    return getDraftState(state).undoList;
  },
  latestUndo(state) {
    return getDraftState(state).latestUndo;
  },
  redoList(state) {
    return getDraftState(state).redoList;
  },
  propertySettings(state) {
    return getDraftState(state).propertySettings;
  },
  draftPayload(state) {
    const title = postTitleSelector.postTitle(state);
    const coverData = coverImageSelector.cover(state);
    const coverMediaType = coverImageSelector.mediaType(state);
    const blockList = blockListSelector.blockList(state);
    const intro = postIntroSelector.postIntro(state);
    const { comments } = getEditorState(state);
    const blockListWithoutBlockAdders = removeAllBlockAdders(blockList);
    const analysis = getAnalysis(blockList, coverMediaType);
    const tagsAndSeo = {
      articleTags: tagsAndSeoSelectorSlice.articleTags(state),
      seoTitle: tagsAndSeoSelectorSlice.seoTitle(state),
      seoDescription: tagsAndSeoSelectorSlice.seoDescription(state),
    };
    const bodyText = blockListSelector.blockListBodyText(state);
    const socialSharing = socialSharingSelector.getSocialSharing(state);
    const isGroupOfLinksExists = getIsGroupOfLinksExists(blockList);
    const inThisStories = inThisStorySelector.selectedInThisStories(state);

    const data = {
      title,
      coverData,
      intro,
      blocklist: blockListWithoutBlockAdders,
      tagsAndSeo,
      analysis,
      bodyText,
      socialSharing,
      isGroupOfLinksExists,
      comments,
      inThisStories,
    };
    return getDraftDataPayload(data);
  },
  isWorkingDraft(state) {
    return getDraftState(state).isWorkingDraft;
  },
};
