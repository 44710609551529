import { getEditorState } from '../editor.selector';

function getEditorDashboardState(state) {
  return getEditorState(state).editorDashboard;
}

export const editorDashboardSelector = {
  currentView(state) {
    return getEditorDashboardState(state).currentView;
  },
  isLoading(state) {
    return getEditorDashboardState(state).isLoading;
  },
  hasErrors(state) {
    return getEditorDashboardState(state).hasErrors;
  },
  errorCode(state) {
    return getEditorDashboardState(state).error && getEditorDashboardState(state).error.status;
  },
  drafts(state) {
    return getEditorDashboardState(state).drafts;
  },
  modalDraftData(state) {
    return getEditorDashboardState(state).modalDraftData;
  },
  isDeleteDraftModalVisible(state) {
    return getEditorDashboardState(state).isDeleteDraftModalVisible;
  },
  isDeleteDraftModalLoading(state) {
    return getEditorDashboardState(state).isDeleteDraftModalLoading;
  },
  editorLocale(state) {
    return getEditorDashboardState(state).editorLocale;
  },
  editorSite(state) {
    return getEditorDashboardState(state).editorSite;
  },
  writers(state) {
    return getEditorDashboardState(state).writers;
  },
  selectedWriters(state) {
    return getEditorDashboardState(state).selectedWriters;
  },
};
