export const TWITTER_OEMBED_PREFIX = 'https://publish.twitter.com/oembed?omit_script=true&url=';
export const TWEET_SEARCH_PLACEHOLDER = 'Search tweets';
export const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Twitter. Please paste a correct one.';

export const TWITTER_SOURCE_TYPES = {
  URL: 'url',
  SEARCH: 'searchQuery',
};

export const TWITTER_RADIO_OPTIONS = [
  {
    label: 'URL',
    value: TWITTER_SOURCE_TYPES.URL,
  },
  {
    label: 'Search',
    value: TWITTER_SOURCE_TYPES.SEARCH,
  },
];

export const TWITTER_SORTING_OPTIONS = {
  POPULAR: 'popular',
  RECENT: 'recent',
  MIXED: 'mixed',
};

export const TWITTER_DROPDOWN_OPTIONS = {
  POPULAR: 'Popular',
  RECENT: 'Recent',
  MIXED: 'Mixed',
};

export const TWITTER_DROPDOWN_OPTION_TO_SORT_OPTION = {
  [TWITTER_DROPDOWN_OPTIONS.POPULAR]: TWITTER_SORTING_OPTIONS.BEST_MATCH,
  [TWITTER_DROPDOWN_OPTIONS.RECENT]: TWITTER_SORTING_OPTIONS.MOST_POPULAR,
  [TWITTER_DROPDOWN_OPTIONS.MIXED]: TWITTER_SORTING_OPTIONS.NEWEST,
};

export const TWITTER_ERROR_MESSAGES = {
  NO_DATA: 'Sorry, we did not find any tweets',
  NO_SEARCH_QUERY: 'Please, provide the search query',
  NO_MORE_TWEETS: 'Sorry, there are no more tweets',
  SEARCH_ERROR: 'Search request failed, please, try again later',
  OEMBED_ERROR: 'Oembed service responded with error, please, check url or try again later',
};

export const TWEET_SEARCH_COUNT = 5;
export const MAX_TWEETS_COUNT = 50;
export const LOAD_MORE_TEXT = 'Load more tweets';
