import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';
import { ELEMENT_PANEL_PLUGIN_TYPES, mediaTypeToPanelType } from '../../plugins/pluginConfiguration';

import {
  clearEditedBlockData,
  closeElementPanel,
  openElementPanelAtPanelType,
} from '../elementPanel/elementPanel.actions';
import { setCurrentlyActiveBlockKey } from '../blocks/blockList.actions';

import {
  ELEMENT_PANEL_CONTEXT,
} from '../../components/elementPanel/elementPanel.constants';

export const SET_COVER_IMAGE = '[EDITOR] successfully uploaded cover image';
export const UPLOADING_COVER_IMAGE_FAILURE = '[EDITOR] failed to upload cover image';
export const DELETE_COVER_IMAGE = '[EDITOR] delete cover image';
export const UPDATE_COVER_IMAGE = '[EDITOR] load draft cover image';
export const SET_IS_COVER_LOADING = '[EDITOR] cover is loading';

export const onAddCoverImageClick = () => dispatch => {
  clearEditedBlockData()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  openElementPanelAtPanelType({
    context: ELEMENT_PANEL_CONTEXT.EDITING_COVER_IMAGE,
    panelToOpen: ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL,
    editedBlockData: null,
  })(dispatch);
};

export const setCover = coverData => dispatch => {
  dispatch({
    type: SET_COVER_IMAGE,
    payload: coverData,
  });
};

export const onDeleteCoverImageClick = () => dispatch => {
  dispatch({
    type: DELETE_COVER_IMAGE,
  });
};

export const onEditCoverImageClick = data => dispatch => {
  const hasMedia = !!data.media;
  const panelToOpen = hasMedia ? mediaTypeToPanelType[data.media.type] : ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL;
  const editedBlockData = hasMedia ? data.media : { type: BLOCK_TYPES.IMAGE, value: data.image };
  return openElementPanelAtPanelType({
    context: ELEMENT_PANEL_CONTEXT.EDITING_COVER_IMAGE,
    panelToOpen,
    editedBlockData,
  })(dispatch);
};

export const onCancelAddingCoverImageClick = () => dispatch => {
  closeElementPanel()(dispatch);
};

export const uploadingCoverImageFailed = () => dispatch => {
  dispatch({
    type: UPLOADING_COVER_IMAGE_FAILURE,
  });
};

export const updateCoverImage = imageData => ({
  type: UPDATE_COVER_IMAGE,
  payload: imageData,
});

export const setIsCoverPhotoLoading = isLoading => dispatch => {
  dispatch({
    type: SET_IS_COVER_LOADING,
    payload: isLoading,
  });
};
