import { Dispatch } from '@reduxjs/toolkit';
import { initBackgroundTask } from './BackgroundTasksService';
import { getTasksFromLocalStorage } from './store/backgroundTasks.utils';

export const initTasksFromLocalStorage = (dispatch: Dispatch, isTaskExists: (taskId: string) => boolean) => {
  const tasks = getTasksFromLocalStorage();
  Object.keys(tasks).forEach((taskId: string) => {
    if (!isTaskExists(taskId)) {
      initBackgroundTask(dispatch, tasks[taskId]);
    }
  });
};
