import { RootState } from 'core/store';
import { getEditorState } from '../../editor.selector';
import { MINIMUM_NUMBER_OF_TAGS } from '../../../components/editorialMenu/editorialMenu.constants';

const getTagsAndSeoState = (state: RootState) => getEditorState(state).editorialMenu.tagsAndSeo;

export const tagsAndSeoSelector = {
  tagsAndSeo: (state: RootState) => getTagsAndSeoState(state),
  shouldShowTagsAndSeoModal: (state: RootState) => getTagsAndSeoState(state).shouldShowTagsAndSeoModal,
  autocompletedTagsError: (state: RootState) => getTagsAndSeoState(state).autocompletedTagsError,
  suggestedTagsError: (state: RootState) => getTagsAndSeoState(state).suggestedTagsError,
  suggestedTagsLoading: (state: RootState) => getTagsAndSeoState(state).suggestedTagsLoading,
  seoDescription: (state: RootState) => getTagsAndSeoState(state).seoDescription,
  suggestedDescriptions: (state: RootState) => getTagsAndSeoState(state).suggestedDescriptions,
  suggestedDescriptionsLoading: (state: RootState) => getTagsAndSeoState(state).suggestedDescriptionsLoading,
  suggestedDescriptionsError: (state: RootState) => getTagsAndSeoState(state).suggestedDescriptionsError,
  seoTitle: (state: RootState) => getTagsAndSeoState(state).seoTitle,
  tagsInputValue: (state: RootState) => getTagsAndSeoState(state).tagsInputValue,
  autocompletedTags: (state: RootState) => getTagsAndSeoState(state).autocompletedTags,
  articleTags: (state: RootState) => getTagsAndSeoState(state).articleTags,
  suggestedTags: (state: RootState) => getTagsAndSeoState(state).suggestedTags,
  cachedSuggestedTags: (state: RootState) => getTagsAndSeoState(state).cachedSuggestedTags,
  isDropdownLoading: (state: RootState) => getTagsAndSeoState(state).isDropdownLoading,
  isTagsNumberValid: (state: RootState) => getTagsAndSeoState(state).articleTags.length >= MINIMUM_NUMBER_OF_TAGS,
};
