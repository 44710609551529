import { buildQueryFromObject } from '@ftbpro/mm-admin-core-utils';
import { ConfigsProvider } from 'core/configs/ConfigsProvider';

const { CONTENT_ENRICHMENT_API_BASE_URL } = ConfigsProvider;
const PROPERTIES = '/properties';
const POSTS = '/posts';
const EMBEDS = '/embeds';
const FEEDS = '/feeds';
const TEAMS = '/teams';

export class ContentEnrichmentConfigsProvider {
  static get CONTENT_ENRICHMENT_API_ENDPOINT() {
    return CONTENT_ENRICHMENT_API_BASE_URL;
  }

  static getContentEnrichmentPostsEndpoint(property: string, url: string) {
    return `${CONTENT_ENRICHMENT_API_BASE_URL}${PROPERTIES}/${property}${POSTS}${buildQueryFromObject({ url })}`;
  }

  static getContentEnrichmentEmbedEndpoint(property: string, id: string) {
    const hasId = id ? `/${id}` : '';
    return `${CONTENT_ENRICHMENT_API_BASE_URL}${PROPERTIES}/${property}${EMBEDS}${hasId}`;
  }

  static getContentEnrichmentFeedsEndpoint(property: string) {
    return `${CONTENT_ENRICHMENT_API_BASE_URL}${PROPERTIES}/${property}${FEEDS}`;
  }

  static getContentEnrichmentEmbedsEndpoint(property: string, query: string) {
    return `${CONTENT_ENRICHMENT_API_BASE_URL}${PROPERTIES}/${property}${EMBEDS}${query}`;
  }

  static postContentEnrichmentEmbedEndpoint(property: string) {
    return `${CONTENT_ENRICHMENT_API_BASE_URL}${PROPERTIES}/${property}${EMBEDS}`;
  }

  static removeContentEnrichmentEmbedEndpoint(id: string, property: string) {
    return `${CONTENT_ENRICHMENT_API_BASE_URL}${PROPERTIES}/${property}${EMBEDS}/${id}`;
  }

  static getContentEnrichmentTeamsEndpoint(property: string) {
    return `${CONTENT_ENRICHMENT_API_BASE_URL}${PROPERTIES}/${property}${TEAMS}`;
  }
}
