import {
  DigitOneIcon,
  DigitThreeIcon,
  DigitFiveIcon,
  DigitTenIcon,
  ImageIcon,
  NumberedListIcon,
  QuoteIcon,
  DividerIcon,
  SlideShowIcon,
  TableIcon,
  TableOfContentsIcon,
  FAQIcon,
 } from '@ftbpro/mm-admin-assets';

export const BLOCK_ADDER_TYPES = {
  TEXT: 'text',
  BOTTOM: 'bottom',
  GROUPED_PLACEHOLDER: 'groupedPlaceholder',
  BETWEEN: 'between',
};

export const BLOCK_ADDER_OPTIONS_NAMES = {
  MEDIA: 'media',
  LIST: 'list',
  ONE_LIST_BLOCK: 'one list block',
  THREE_LIST_BLOCKS: 'three list blocks',
  FIVE_LIST_BLOCKS: 'five list blocks',
  TEN_LIST_BLOCKS: 'ten list blocks',
  ONE_FAQ_BLOCK: 'one faq block',
  THREE_FAQ_BLOCKS: 'three faq blocks',
  FIVE_FAQ_BLOCKS: 'five faq blocks',
  TEN_FAQ_BLOCKS: 'ten faq blocks',
  QUOTE: 'quote',
  DIVIDER: 'divider',
  PAGE_BREAK: 'page break',
  TABLE: 'table',
  TABLE_OF_CONTENTS: 'table of contents',
  FAQ: 'faq',
};

const TOOLTIP_TEXT = {
  [BLOCK_ADDER_OPTIONS_NAMES.MEDIA]: 'Media',
  [BLOCK_ADDER_OPTIONS_NAMES.LIST]: 'List',
  [BLOCK_ADDER_OPTIONS_NAMES.ONE_LIST_BLOCK]: '1 List Block',
  [BLOCK_ADDER_OPTIONS_NAMES.THREE_LIST_BLOCKS]: '3 List Blocks',
  [BLOCK_ADDER_OPTIONS_NAMES.FIVE_LIST_BLOCKS]: '5 List Blocks',
  [BLOCK_ADDER_OPTIONS_NAMES.TEN_LIST_BLOCKS]: '10 List Blocks',
  [BLOCK_ADDER_OPTIONS_NAMES.QUOTE]: 'Quote',
  [BLOCK_ADDER_OPTIONS_NAMES.DIVIDER]: 'Divider',
  [BLOCK_ADDER_OPTIONS_NAMES.PAGE_BREAK]: 'New Slide',
  [BLOCK_ADDER_OPTIONS_NAMES.TABLE]: 'Table',
  [BLOCK_ADDER_OPTIONS_NAMES.TABLE_OF_CONTENTS]: 'Table Of Contents',
  [BLOCK_ADDER_OPTIONS_NAMES.FAQ]: 'Questions & Answers',
  [BLOCK_ADDER_OPTIONS_NAMES.ONE_FAQ_BLOCK]: '1 Q&A Block',
  [BLOCK_ADDER_OPTIONS_NAMES.THREE_FAQ_BLOCKS]: '3 Q&A Blocks',
  [BLOCK_ADDER_OPTIONS_NAMES.FIVE_FAQ_BLOCKS]: '5 Q&A Blocks',
  [BLOCK_ADDER_OPTIONS_NAMES.TEN_FAQ_BLOCKS]: '10 Q&A Blocks',
};

export const NUMBER_OF_LIST_ITEMS = [
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.ONE_LIST_BLOCK,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.ONE_LIST_BLOCK],
    icon: DigitOneIcon,
    style: {
      visibility: 'hidden',
    },
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.THREE_LIST_BLOCKS,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.THREE_LIST_BLOCKS],
    icon: DigitThreeIcon,
    style: {
      visibility: 'hidden',
    },
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.FIVE_LIST_BLOCKS,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.FIVE_LIST_BLOCKS],
    icon: DigitFiveIcon,
    style: {
      visibility: 'hidden',
    },
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.TEN_LIST_BLOCKS,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.TEN_LIST_BLOCKS],
    icon: DigitTenIcon,
    style: {
      visibility: 'hidden',
    },
  },
];

export const NUMBER_OF_FAQ_ITEMS = [
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.ONE_FAQ_BLOCK,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.ONE_FAQ_BLOCK],
    icon: DigitOneIcon,
    style: {
      visibility: 'hidden',
    },
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.THREE_FAQ_BLOCKS,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.THREE_FAQ_BLOCKS],
    icon: DigitThreeIcon,
    style: {
      visibility: 'hidden',
    },
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.FIVE_FAQ_BLOCKS,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.FIVE_FAQ_BLOCKS],
    icon: DigitFiveIcon,
    style: {
      visibility: 'hidden',
    },
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.TEN_FAQ_BLOCKS,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.TEN_FAQ_BLOCKS],
    icon: DigitTenIcon,
    style: {
      visibility: 'hidden',
    },
  },
];

export const BLOCK_ADDER_OPTIONS = [
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.MEDIA,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.MEDIA],
    icon: ImageIcon,
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.LIST,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.LIST],
    icon: NumberedListIcon,
    innerOptions: NUMBER_OF_LIST_ITEMS,
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.QUOTE,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.QUOTE],
    icon: QuoteIcon,
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.DIVIDER,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.DIVIDER],
    icon: DividerIcon,
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.PAGE_BREAK,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.PAGE_BREAK],
    icon: SlideShowIcon,
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.TABLE,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.TABLE],
    icon: TableIcon,
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.TABLE_OF_CONTENTS,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.TABLE_OF_CONTENTS],
    icon: TableOfContentsIcon,
  },
  {
    type: BLOCK_ADDER_OPTIONS_NAMES.FAQ,
    tooltipText: TOOLTIP_TEXT[BLOCK_ADDER_OPTIONS_NAMES.FAQ],
    icon: FAQIcon,
    innerOptions: NUMBER_OF_FAQ_ITEMS,
  },
];

export const LIST_ITEMS_SORTING_ORDER = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};
