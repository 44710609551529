import React from 'react';
import { Text } from '@ftbpro/mm-admin-ui-components';

const CROP_IMAGE_TITLE = 'Crop the image:';

export const CropImageTitle = () => {
  return (
    <Text type={Text.TEXT_TYPES.TITLE_M}>
      {CROP_IMAGE_TITLE}
    </Text>
  );
};
