import React, { Component } from 'react';
import { Plugin } from '../Plugin/Plugin';

export class EmbedBlock extends Component {
  shouldComponentUpdate(nextProps) {
    const { loading } = this.props;
    return (
      loading !== nextProps.loading
      || nextProps.isCurrentActiveBlockKey
      );
  }

  hasInnerHtml = () => {
    const { data } = this.props;
    return data && data.value.html;
  };

  render() {
    const { className, data, shouldPreventEdit, shouldPreventDelete, onMediaDeleted, onMediaEdited, onMediaEditCanceled, isEditing, loading, blockRef, styles } = this.props;

    return (
      this.hasInnerHtml()
        ? (
          <Plugin.Block
            loading={loading}
            shouldPreventEdit={shouldPreventEdit}
            shouldPreventDelete={shouldPreventDelete}
            onDelete={onMediaDeleted}
            onEdit={onMediaEdited}
            onCancel={onMediaEditCanceled}
            isEditing={isEditing}
          >
            <div
              className={className}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: data.value.html }}
              ref={blockRef}
              css={styles}
            />
          </Plugin.Block>
        )
        : null
    );
  }
}
