import React from 'react';
import { connect } from 'react-redux';
import { SnackBar } from '@ftbpro/mm-admin-ui-components';
import { bindActionCreators } from '@reduxjs/toolkit';

import { capitalizeEveryFirstLetter } from '@ftbpro/mm-admin-core-utils';
import { dismissGlobalNotification as dismissGlobalNotificationAction } from './store/globalNotifications.actions';
import { globalNotificationsCenterStyles } from './globalNotificationsCenter.styles';

export const GlobalNotificationsCenterComponent = props => {
  const { globalNotifications, dismissGlobalNotification, logoutAction } = props;
  const renderNotifications = () => {
    return globalNotifications.map(({ id, status, text, buttonText = '', onButtonClick, showLogout = false, closeable = true, onCloseAction, stayOpen, animationDuration }) => {
      const onCloseClosure = () => {
        dismissGlobalNotification(id);
        return onCloseAction && onCloseAction();
      };

      const onTextButtonAction = showLogout ? logoutAction : onButtonClick;

      return (
        <SnackBar
          key={id}
          status={status}
          text={text}
          textButtonContent={capitalizeEveryFirstLetter(buttonText)}
          onTextButtonClick={onTextButtonAction}
          onClose={closeable ? onCloseClosure : false}
          stayOpen={stayOpen}
          variables={animationDuration ? { animationDuration } : {}}
        />
      );
    });
  };

  return globalNotifications
    ? (
      <div style={globalNotificationsCenterStyles.notificationsWrapper}>
        {renderNotifications()}
      </div>
    )
    : null;
};

const mapStateToProps = state => {
  return {
    globalNotifications: state.globalNotifications.globalNotifications,
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  dismissGlobalNotification: dismissGlobalNotificationAction,
}, dispatch);

export const GlobalNotificationsCenter = connect(mapStateToProps, mapDispatchToProps)(GlobalNotificationsCenterComponent);
