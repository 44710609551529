import { EchoNetworkService } from '../../services/echoServiceApiProvider';
import { Logger } from '../../../../../core/logger/index';
import { PLACING_CONTEXT_SCREEN } from '../../components/modals/modals.constants';

export const TOGGLE_COMMENTS_MODAL = '[NEW ECHO] open comments modal';
export const TOGGLE_HIDE_POST_MODAL = '[NEW ECHO] open hide post modal';
export const TOGGLE_SCHEDULE_POST_MODAL = '[NEW ECHO] open schedule post modal';
export const TOGGLE_SCHEDULE_POST_MODAL_SHORTCUT = '[NEW ECHO] open schedule post modal through shortcut';
export const TOGGLE_SCHEDULE_POST_MODAL_THROUGH_FEEDS = '[NEW ECHO] open schedule post modal through feeds';
export const TOGGLE_SCHEDULE_POST_MODAL_THROUGH_CLOSE_CLICK = '[NEW ECHO] close schedule post modal though close click';
export const TOGGLE_REMOVE_SCHEDULED_POST_MODAL = '[NEW ECHO] open remove scheduled post modal';
export const TOGGLE_UNFEATURE_MODAL = '[NEW ECHO] toggle unfeature feed modal';
export const TOGGLE_DELETE_POST_MODAL = '[NEW ECHO] toggle delete post modal';
export const TOGGLE_AUTHORS_MODAL = '[NEW ECHO] toggle authors modal';
export const UPDATE_COMMENTS_REQUEST = '[NEW ECHO] update post comments request';
export const UPDATE_COMMENTS_SUCCESS = '[NEW ECHO] update post comments success';
export const UPDATE_COMMENTS_FAILURE = '[NEW ECHO] update post comments failure';
export const TOGGLE_BACK_CONFIRMATION_MODAL = '[NEW ECHO] toggle back confirmation modal';
export const TOGGLE_LABELS_MODAL = '[NEW_ECHO] toggle labels modal';
export const UPDATE_LABELS_REQUEST = '[NEW ECHO] update post labels request';
export const UPDATE_LABELS_SUCCESS = '[NEW ECHO] update post labels success';
export const UPDATE_LABELS_FAILURE = '[NEW ECHO] update post labels failure';
export const TOGGLE_LABELS_MODAL_LOADING = '[NEW ECHO] toggle labels modal loading';

export const toggleCommentsModal = ({ isCommentsModalVisible, modalPostData }) => dispatch => {
  dispatch({
    type: TOGGLE_COMMENTS_MODAL,
    payload: {
      isCommentsModalVisible,
      modalPostData,
    },
  });
};

export const toggleHidePostModal = ({ isHidePostModalVisible, modalPostData }) => dispatch => {
  dispatch({
    type: TOGGLE_HIDE_POST_MODAL,
    payload: {
      isHidePostModalVisible,
      modalPostData,
    },
  });
};

export const toggleAuthorsModal = ({ isAuthorsModalVisible, addedAuthors }) => dispatch => {
  dispatch({
    type: TOGGLE_AUTHORS_MODAL,
    payload: {
      isAuthorsModalVisible,
      addedAuthors,
    },
  });
};

export const toggleSchedulePostModal = ({ isSchedulePostModalVisible, modalPostData, scheduleDate, scheduledFeeds }) => dispatch => {
  dispatch({
    type: TOGGLE_SCHEDULE_POST_MODAL,
    payload: {
      isSchedulePostModalVisible,
      modalPostData,
      scheduleDate,
      scheduledFeeds,
    },
  });
};

export const toggleRemoveScheduledPostModal = ({ isRemoveScheduledPostModalVisible, modalPostData, scheduledFeeds }) => dispatch => {
  dispatch({
    type: TOGGLE_REMOVE_SCHEDULED_POST_MODAL,
    payload: {
      isRemoveScheduledPostModalVisible,
      modalPostData,
      scheduledFeeds,
    },
  });
};

export const toggleUnfeatureFeedModal = ({ isUnfeatureFeedModalVisible, unfeaturingFeed }) => dispatch => {
  dispatch({
    type: TOGGLE_UNFEATURE_MODAL,
    payload: {
      isUnfeatureFeedModalVisible,
      unfeaturingFeed,
    },
  });
};

export const toggleDeletePostModal = ({ isDeletePostModalVisible, modalPostData }) => dispatch => {
  dispatch({
    type: TOGGLE_DELETE_POST_MODAL,
    payload: {
      isDeletePostModalVisible,
      modalPostData,
    },
  });
};

export function updateCommentsList({ newComments, id, property, placingContextScreen }) {
  return async dispatch => {
    dispatch({
      type: UPDATE_COMMENTS_REQUEST,
    });
    try {
      await EchoNetworkService.updatePostComments({ data: { comments: newComments }, postId: id, property });
      return dispatch({
        type: UPDATE_COMMENTS_SUCCESS,
        payload: {
          id,
          comments: newComments,
          placingContextScreen,
        },
      });
    } catch (error) {
      Logger.error(error);
      return dispatch({
        type: UPDATE_COMMENTS_FAILURE,
        payload: error,
      });
    }
  };
}

export function openSchedulePostModalShortcut(schedulingData) {
  return dispatch => {
    dispatch({
      type: TOGGLE_SCHEDULE_POST_MODAL_SHORTCUT,
    });
    return toggleSchedulePostModal({ ...schedulingData, isSchedulePostModalVisible: true })(dispatch);
  };
}

export function openSchedulePostModalThroughFeeds(schedulingData) {
  return dispatch => {
    dispatch({
      type: TOGGLE_SCHEDULE_POST_MODAL_THROUGH_FEEDS,
    });
    return toggleSchedulePostModal({ ...schedulingData, isSchedulePostModalVisible: true })(dispatch);
  };
}

export function closeSchedulePostModal() {
  return dispatch => {
    dispatch({
      type: TOGGLE_SCHEDULE_POST_MODAL_THROUGH_CLOSE_CLICK,
    });
    return toggleSchedulePostModal({ isSchedulePostModalVisible: false })(dispatch);
  };
}

export const setShouldShowBackConfirmationModal = shouldShowBackConfirmationModal => dispatch => {
  dispatch({
    type: TOGGLE_BACK_CONFIRMATION_MODAL,
    payload: {
      shouldShowBackConfirmationModal,
    },
  });
};

export const toggleLabelsModal = (modalPostData, placingContextScreen) => async dispatch => {
  if (placingContextScreen === PLACING_CONTEXT_SCREEN.ECHO_DASHBOARD) {
    dispatch({
      type: TOGGLE_LABELS_MODAL_LOADING,
      payload: { isLabelsModalLoading: true },
    });
    const postData = await EchoNetworkService.getPostData({
      postId: modalPostData.id,
      property: modalPostData.property,
    });
    dispatch({
      type: TOGGLE_LABELS_MODAL_LOADING,
      payload: { isLabelsModalLoading: false },
    });
    return dispatch({
      type: TOGGLE_LABELS_MODAL,
      payload: {
        modalPostData: postData,
      },
    });
  }
  return dispatch({
    type: TOGGLE_LABELS_MODAL,
    payload: {
      modalPostData,
    },
  });
};

export function updateLabelsList({ newLabels, id, property, placingContextScreen }) {
  return async dispatch => {
    dispatch({
      type: UPDATE_LABELS_REQUEST,
    });
    try {
      await EchoNetworkService.updatePostLabels({ data: { labels: newLabels }, postId: id, property });
      return dispatch({
        type: UPDATE_LABELS_SUCCESS,
        payload: {
          id,
          labels: newLabels,
          placingContextScreen,
        },
      });
    } catch (error) {
      Logger.error(error);
      return dispatch({
        type: UPDATE_LABELS_FAILURE,
        payload: error,
      });
    }
  };
}
