import React from 'react';
import { useSelector } from 'react-redux';
import { navigationFiltersSelector } from 'core/store/navigationFilters/navigationFilters.selector';
import { FilterType } from 'core/store/navigationFilters/navigationFilters.constants';
import { CustomFilter } from './CustomFilter';
import { TenantFilter } from './TenantFilter';
import { OrganizationFilter } from './OrganizationFilter';
import { VoltaxPropertyFilter } from './VoltaxPropertyFilter';
import { HQPropertyFilter } from './HQPropertyFilter';

export const NavigationFilters = (): JSX.Element => {
  const filterType = useSelector(navigationFiltersSelector.filterType);
  const showHqPropertyFilter = filterType === FilterType.HQ_PROPERTY;
  const showOrganizationFilter = filterType === FilterType.ORGANIZATION || showHqPropertyFilter;
  const showTenantFilter = filterType === FilterType.TENANT || showOrganizationFilter;
  const showVoltaxPropertyFilter = filterType === FilterType.VOLTAX_PROPERTY;
  const showCustomFilter = filterType === FilterType.CUSTOM_FILTER;

  return (
    <>
      {showTenantFilter && <TenantFilter />}
      {showOrganizationFilter && <OrganizationFilter />}
      {showHqPropertyFilter && <HQPropertyFilter />}
      {showVoltaxPropertyFilter && <VoltaxPropertyFilter />}
      {showCustomFilter && <CustomFilter />}
    </>
  );
};
