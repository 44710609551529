import { AnyAction } from '@reduxjs/toolkit';
import { FETCH_TIMEOUT, HttpMethod, NetworkAction } from './networkMiddleware.constans';

interface NetworkActionOptions {
  body?: any,
  url: string,
  method?: HttpMethod,
  type?: NetworkAction.MMFetch,
  data?: any,
  onPending?: () => AnyAction,
  onSuccess?: (data: any) => AnyAction,
  onFailure?: (error: any) => AnyAction,
  timeout?: string,
  headers?: { [key: string]: string },
}

export const networkAction = (props: NetworkActionOptions) => {
    const {
      body,
      url = '',
      method = HttpMethod.Get,
      type = NetworkAction.MMFetch,
      data,
      onPending,
      onSuccess,
      onFailure,
      timeout = FETCH_TIMEOUT,
      headers,
  } = props;

      return {
        type,
        payload: {
          body,
          url,
          method,
          data,
          onPending,
          onSuccess,
          onFailure,
          headers,
          timeout,
        },
      };
  };
