import React from 'react';
import { Container, CustomDropdown, RangeDatePicker, TextButton, CustomFilter } from '@ftbpro/mm-admin-ui-components';
import { DatesData } from '@ftbpro/mm-admin-ui-components/src';
import {
  DATE_FORMATS,
  formatDate,
  getStartOfMonth,
  getStartOfYesterday,
  subDaysFromDate,
} from '@ftbpro/mm-admin-core-utils';
import { useStyles } from 'core/hooks/useStyles';
import { getStylesObject } from './dateRangeSetup.styles';
import {
  buildFilterTitle,
  getDateObject,
  getEndOfLastMonthDate,
  getStartOfLastMonthDate,
} from './dateRangeSetup.utils';
import { DATE_RANGE } from './dateRangeSetup.strings';

export enum DateType {
  Today = 'Today',
  Yesterday = 'Yesterday',
  LastMonth = 'Last Month',
  MonthToDate = 'Month to date',
  Last7Days = 'Last 7 Days',
  Last30Days = 'Last 30 Days',
  Custom = 'Custom',
}

export interface DateObject {
  startDate: string;
  endDate: string;
  dateType: DateType;
}

interface DateRangeSetupProps {
  dateType: DateType;
  startDate: string;
  endDate: string;
  onDateChange: (dateObject: Partial<DateObject>) => void;
  renderAsCustomFilter?: boolean;
}

export const DateRangeSetup = (props: DateRangeSetupProps) => {
  const { startDate, endDate, dateType, onDateChange, renderAsCustomFilter = false } = props;
  const selectedStartDate = getDateObject(startDate);
  const selectedEndDate = getDateObject(endDate);
  const dateRangePlaceholder = dateType === DateType.Custom ? buildFilterTitle(selectedStartDate, selectedEndDate, DATE_RANGE) : dateType;

  const todayFormatted = formatDate(new Date(), DATE_FORMATS.ISO_DATE);
  const yesterdayFormatted = formatDate(getStartOfYesterday(), DATE_FORMATS.ISO_DATE);
  const weekAgoFormatted = formatDate(subDaysFromDate(new Date(), 7), DATE_FORMATS.ISO_DATE);
  const monthAgoFormatted = formatDate(subDaysFromDate(new Date(), 30), DATE_FORMATS.ISO_DATE);
  const startOfLastMonthFormatted = getStartOfLastMonthDate();
  const endOfLastMonthFormatted = getEndOfLastMonthDate();
  const startOfMonthFormatted = formatDate(getStartOfMonth(new Date()), DATE_FORMATS.ISO_DATE);

  const DropMenuComponent = renderAsCustomFilter ? CustomFilter : CustomDropdown;

  const styles = useStyles(getStylesObject, [renderAsCustomFilter]);

  const isDateRangePeriodSelected = (from: string, to: string): boolean => (from === startDate && to === endDate);

  const handleDatePickerClick = (startDateArg: string, endDateArg: string, type: DateType) => {
    onDateChange({
      startDate: startDateArg,
      endDate: endDateArg,
      dateType: type,
    });
  };

  const getDataRangeByPeriodObject = (dateRange: DateType, from: string, to: string) => {
    return {
      date: dateRange,
      handleDateClick: () => handleDatePickerClick(from, to, dateRange),
      isSelected: isDateRangePeriodSelected(from, to),
    };
  };

  const dateRangeByPeriods: Array<{ date: DateType, handleDateClick: () => void, isSelected: boolean }> = [
    getDataRangeByPeriodObject(DateType.Today, todayFormatted, todayFormatted),
    getDataRangeByPeriodObject(DateType.Yesterday, yesterdayFormatted, yesterdayFormatted),
    getDataRangeByPeriodObject(DateType.Last7Days, weekAgoFormatted, yesterdayFormatted),
    getDataRangeByPeriodObject(DateType.Last30Days, monthAgoFormatted, yesterdayFormatted),
    getDataRangeByPeriodObject(DateType.LastMonth, startOfLastMonthFormatted, endOfLastMonthFormatted),
    getDataRangeByPeriodObject(DateType.MonthToDate, startOfMonthFormatted, todayFormatted),
  ];

  const handleStartDateSelected = (data: DatesData) => {
    onDateChange({
      startDate: formatDate(data.startDate ?? new Date(), DATE_FORMATS.ISO_DATE),
      endDate: formatDate(data.startDate ?? new Date(), DATE_FORMATS.ISO_DATE),
      dateType: DateType.Custom,
    });
  };

  const handleEndDateSelected = (data: DatesData) => {
    const newEndData = formatDate(data.endDate ?? new Date(), DATE_FORMATS.ISO_DATE);
    onDateChange({
      endDate: newEndData,
      dateType: DateType.Custom,
    });
  };

  return (
    <Container style={styles.builderDateContainer}>
      <DropMenuComponent
        title={dateRangePlaceholder}
        labelProps={{ text: DATE_RANGE }}
        content={(
          <Container style={styles.dateContainer}>
            <Container style={styles.dateListContainer}>
              {dateRangeByPeriods.map((dateObject, index) => {
                return (
                  <TextButton
                    key={index}
                    onClick={dateObject.handleDateClick}
                    disabled={dateObject.isSelected}
                    content={dateObject.date}
                    variables={styles.dateTextButton}
                  />
                );
              })}
            </Container>
            <RangeDatePicker
              onStartDateSelect={handleStartDateSelected}
              onEndDateSelect={handleEndDateSelected}
              initialStartDate={selectedStartDate}
              initialEndDate={selectedEndDate}
            />
          </Container>
        )}
        variables={styles.dateDropdown}
      />
    </Container>
  );
};
