import { GroupOfLinksType } from '../components/contentEnrichmentEmbedForms/GroupOfLinksForm/groupOfLinksForm.constants';

export enum EmbedType {
  StoryLink = 'StoryLink',
  Rank = 'Rank',
  Trade = 'Trade',
  Score = 'Score',
  Matchup = 'Matchup',
  Grade = 'Grade',
  GroupOfLinks = 'GroupOfLinks',
  ThreeImageLinks = 'ThreeImageLinks',
  GroupOfExternalLinks = 'GroupOfExternalLinks',
}

export interface ContentEnrichmentSliceState {
  loading: boolean;
  error?: string;
  embedsList: Embed[];
  preview: boolean;
  embedData: EmbedData;
  embedsQuery: EmbedQuery;
  totalEmbeds: number;
  modalOpen: boolean;
  embedId: string;
  isDrawerOpen: boolean;
  isEmbedFormLoading: boolean;
  isTeamsListLoading: boolean;
  teamsList: Team[];
  feedsList: Feed[];
  embedUrl: string;
}

export type EmbedQuery = {
  embedType?: string;
  page?: number;
  search?: string;
  limit?: number;
  embedName?: string;
};

export interface Author {
  name: string;
  username: string;
}

export interface Embed {
  author: Author;
  createdAt: string;
  id: string;
  embedType: EmbedType
  name: string;
  property: string;
  state: string;
  updatedAt: string;
  url: string;
  data?: EmbedDataFromServer | Record<string, any>;
}

export interface FeedLinksData {
  embedName: string;
  linkAUrl: string;
  linkATitle: string;
  linkAImage: string;
  linkBUrl: string;
  linkBTitle: string;
  linkBImage: string;
  linkCUrl: string;
  linkCTitle: string;
  linkCImage: string;
}

export interface Post {
  id: string;
  url: string;
  title: string;
  verified: boolean;
  image?: {
    value: {
      alt: string
      url: string
    }
  }
}

export interface ExternalLink {
  title: string;
  url: string;
}

export interface Feed {
  value: string;
  slug: string;
}

export interface FeedFromServer {
  name: string;
  slug: string;
}

export interface EmbedData {
  data?: EmbedDataFromServer | Record<string, any>;
  error?: string;
  name?: string;
  id?: string;
  embedType?: EmbedType;
  property?: string;
  state?: string;
  url?: string;
}

export type EmbedDataFromServer = {
  type?: GroupOfLinksType;
  embedName?: string;
  configuration?: {
    limit: number;
    topic: string;
  };
  withImages?: boolean;
  posts?: Post[];
}

export interface Team {
  division: string;
  id: string;
  league: string;
  logoUrl: string;
  name: string;
  shortName: string;
  sport: string;
}
