import { setLocalStorageData, getLocalStorageData, isUndefined } from '@ftbpro/mm-admin-core-utils';
import { TaskConfig } from '../workers/WebWorkerWrapper';
import { BackgroundTaskData } from './backgroundTasks.constants';

const BACKGROUND_TASKS_KEY = 'backgroundTasks';

export const getTasksFromLocalStorage = () => {
  return JSON.parse(getLocalStorageData(BACKGROUND_TASKS_KEY) ?? '{}');
};

export const addTaskToLocalStorage = (params: { taskID: string, taskData: BackgroundTaskData, taskConfig?: TaskConfig, onTerminateActionTypeName?: string }) => {
  const tasks = getTasksFromLocalStorage();
  const { taskID, taskData, taskConfig, onTerminateActionTypeName } = params;
  setLocalStorageData(BACKGROUND_TASKS_KEY, JSON.stringify({
    ...tasks,
    [taskID]: {
      taskID,
      backgroundTaskData: taskData,
      taskConfig,
      onTerminateActionTypeName,
    },
  }));
};

export const removeTaskFromLocalStorage = (taskID: string) => {
  const tasks = getTasksFromLocalStorage();
  if (!isUndefined(tasks[taskID])) {
    delete tasks[taskID];
    setLocalStorageData(BACKGROUND_TASKS_KEY, JSON.stringify(tasks));
  }
};
