import { CSS_CLASSES } from '../../components/editorCover/editorCover.styles';
import { COLORS } from '../../../../../core/styles/colors';

export const MEDIA_ITEM_WIDTH = 480;
const ASPECT_RATIO_HORIZONTAL = 16 / 9;
export const MEDIA_ITEM_HEIGHT = MEDIA_ITEM_WIDTH / ASPECT_RATIO_HORIZONTAL;

export const getPluginStyles = loading => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      overflow: 'hidden',
    },
    content: {
      flex: '1',
      padding: '1.5rem',
      overflowY: 'overlay',

      // may cause additional styles
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
    },
    buttonsWrapper: {
      flex: '0 0 64px',
      flexDirection: 'row',
      borderTop: `solid 1px ${COLORS.BLACK20}`,
      padding: '1rem 0',
      alignItems: 'center',
      justifyContent: 'flex-end',
      display: 'flex',
    },
    blockWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      paddingTop: '8px',
      paddingBottom: '8px',
      minHeight: '96px',
    },
    mediaItem: {
      textAlign: 'center',
      position: 'relative',
      lineHeight: '0',
      maxWidth: '75%',
      width: `${MEDIA_ITEM_WIDTH}px`,
      display: loading ? 'none' : 'block',
      '&:hover': {
        [`.${CSS_CLASSES.MEDIA_OVERLAY}`]: {
          display: 'flex',
        },
      },
      'div > iframe': {
        maxWidth: '100%',
      },
    },
    blockOverviewWrapper: {
      height: '48px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    blockOverviewItem: {
      display: 'flex',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      height: '100%',
    },
    errorMessage: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '10px',
    },
    copyrightInformation: {
      display: 'flex',
      justifyContent: 'center',
      padding: '8px',
    },
    copyrightTooltip: {
      paddingLeft: '4px',
    },
  };
};
