import { SITE_VARIABLES } from '../../styles/variables';
import { flexRowAlignCenter } from '../../styles/flex';

export const getStylesObject = () => {
  return {
    scopeFilterSeparator: {
      margin: `0px ${SITE_VARIABLES.MARGIN.SMALL}`,
      color: SITE_VARIABLES.TEXT_COLOR.SUBTITLE,
      ...flexRowAlignCenter,
    },
  };
};

export const FILTER_VARIABLES = {
  buttonTextColor: SITE_VARIABLES.COLORS.PRIMARY,
  toggleIndicatorColor: SITE_VARIABLES.COLORS.PRIMARY,
};
