import { jsx } from 'slate-hyperscript';
import { Text, Node } from 'slate';
import {
  SLATE_NODE_TYPES,
  HTML_PASTE_DATA_KEY,
} from './slate.constants';

// todo: extract all 'magic strings to a constants file'.
const HTML_TAG_ATTRIBUTES = {
  STYLE: 'style',
};
const HTML_ATTRIBUTE_KEYS = {
  FONT_WEIGHT: 'font-weight',
  TEXT_DECORATION: 'text-decoration',
  FONT_STYLE: 'font-style',
};

const GOOGLE_DOCS_MARKS_INDICATOR = {
  BOLD: '700',
  UNDERLINE: 'underline',
  ITALIC: 'italic',
};

const GOOGLE_DOC_ID_PREFIX_INDICATOR = 'docs-internal-guid';

const OPENING_A_TAG = '<a';
const CLOSING_A_TAG = '</a>';
const getATagWithChildren = ({
   url,
   rel,
   target,
  },
  children) => {
  let aTagAttributes = ` href="${url}"`;
  if (rel) {
    aTagAttributes += ` rel="${rel}"`;
  }
  if (target) {
    aTagAttributes += ` target="${target}"`;
  }
  return `${OPENING_A_TAG}${aTagAttributes}>${children}${CLOSING_A_TAG}`;
};
const domParser = new DOMParser();

const isTextNode = node => Text.isText(node) || typeof node === 'string';

const areAllChildrenOfTextType = children => {
  // return children.filter(isTextNode).length === children.length;
  return children.every(isTextNode);
};

const isChildrenAccumulatedTextLengthGreaterThanZero = children => {
  const childrenTextLength = children.reduce((lengthAccumulator, child) => {
    if (typeof child === 'string') {
      return lengthAccumulator + child.length;
    }
    if (Text.isText(child)) {
      return lengthAccumulator + Node.string(child).length;
    }
    return lengthAccumulator;
  }, 0);

  return childrenTextLength;
};

const shouldConditionalElementBeCreated = children => {
  return areAllChildrenOfTextType(children) && isChildrenAccumulatedTextLengthGreaterThanZero(children);
};

const CONDITIONAL_ELEMENTS = ['A', 'DIV'];

const ELEMENT_TAGS = {
  A: el => {
    const url = el.getAttribute('href');
    const rel = el.getAttribute('rel');
    const target = el.getAttribute('target');
    let linkProperties = {
      url,
      type: SLATE_NODE_TYPES.LINK,
    };
    if (rel) {
      linkProperties = { ...linkProperties, rel };
    }
    if (target) {
      linkProperties = { ...linkProperties, target };
    }
    return linkProperties;
  },
  // Todo: once adding other h tags to be supported by the editor, change the deserializer to those added h tags.
  // Todo: change to SLATE_NODE_TYPES

  H1: () => ({ type: 'heading-two' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-three' }),
  H6: () => ({ type: 'heading-three' }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  UL: () => ({ type: 'bulleted-list' }),
  DIV: () => ({ type: 'paragraph' }),
};

// COMPAT: `B` is conditional here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  B: el => {
    return el.id?.includes(GOOGLE_DOC_ID_PREFIX_INDICATOR)
      ? false
      : { bold: true };
  },
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  // Google Docs uses decorated span in order to render bold, italic and underline
  SPAN: el => {
    const spamAttributes = el.getAttribute(HTML_TAG_ATTRIBUTES.STYLE)?.split(';');
    const textNodeMarks = spamAttributes?.reduce((currentTextNodeMarks, currentAttribute) => {
    const [attributeKey, attributeValue] = currentAttribute.split(':');
      if (attributeKey === HTML_ATTRIBUTE_KEYS.FONT_WEIGHT && attributeValue === GOOGLE_DOCS_MARKS_INDICATOR.BOLD) {
        currentTextNodeMarks.bold = true; // eslint-disable-line
      }
      if (attributeKey === HTML_ATTRIBUTE_KEYS.TEXT_DECORATION && attributeValue === GOOGLE_DOCS_MARKS_INDICATOR.UNDERLINE) {
        currentTextNodeMarks.underline = true; // eslint-disable-line
      }
      if (attributeKey === HTML_ATTRIBUTE_KEYS.FONT_STYLE && attributeValue === GOOGLE_DOCS_MARKS_INDICATOR.ITALIC) {
        currentTextNodeMarks.italic = true; // eslint-disable-line
      }
      return currentTextNodeMarks;
    }, {});
    return textNodeMarks;
  },
};

export const deserialize = el => {
  const {
    nodeType,
    textContent,
    nodeName,
    childNodes,
  } = el;
  if (nodeType === 3) { // nodeType = 3 is a text node.
    return textContent;
  } if (nodeType !== 1) {
    return null;
  } if (nodeName === 'BR') {
    return '\n';
  }

  let parent = el;

  if (
    nodeName === 'PRE'
    && childNodes[0]
    && childNodes[0].nodeName === 'CODE'
  ) {
    [parent] = childNodes; // eslint-disable-line
  }

  let children = Array.from(parent.childNodes)
    .map(deserialize)
    .flat();

  if (!children[0]) {
    children = [
      { text: '' },
    ];
  }

  if (nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if ((ELEMENT_TAGS[nodeName] && !CONDITIONAL_ELEMENTS.includes(nodeName))
  || (ELEMENT_TAGS[nodeName] && CONDITIONAL_ELEMENTS.includes(nodeName) && shouldConditionalElementBeCreated(children))) {
    const attrs = ELEMENT_TAGS[nodeName](el, children);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName] && TEXT_TAGS[nodeName](el)) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.filter(isTextNode).map(child => jsx('text', attrs, child));
  }

  return children;
};

const BREAK_LINE_TAG = '<br/>';

const serialize = node => {
  if (Text.isText(node)) {
    let children = node.text.split('\n').join(BREAK_LINE_TAG);
    if (children === BREAK_LINE_TAG) {
      return children;
    }
    if (node[SLATE_NODE_TYPES.BOLD]) {
      children = `<strong>${children}</strong>`;
    }
    if (node[SLATE_NODE_TYPES.ITALIC]) {
      children = `<em>${children}</em>`;
    }
    if (node[SLATE_NODE_TYPES.UNDERLINE]) {
      children = `<u>${children}</u>`;
    }
    return children;
  }

  const children = node.children.map(n => serialize(n)).join('');

  switch (node.type) {
    case SLATE_NODE_TYPES.PARAGRAPH:
      return `<p>${children}</p>`;
    case SLATE_NODE_TYPES.LINE:
      return `<p>${children}</p>`;
    case SLATE_NODE_TYPES.LINK: {
      return getATagWithChildren(node, children);
    }
    case SLATE_NODE_TYPES.LIST_ITEM:
      return `<li>${children}</li>`;
    case SLATE_NODE_TYPES.BULLETED_LIST:
      return `<ul>${children}</ul>`;
    case SLATE_NODE_TYPES.HEADING_TWO:
      return `<h2>${children}</h2>`;
    case SLATE_NODE_TYPES.HEADING_THREE:
      return `<h3>${children}</h3>`;
    case SLATE_NODE_TYPES.HEADING_FOUR:
      return `<h4>${children}</h4>`;
    case SLATE_NODE_TYPES.NUMBERED_LIST:
      return `<ol>${children}</ol>`;
    default:
      return children;
  }
};

export const serializeSlateValueToHtmlString = value => {
  return value.map(serialize).join('');
};

export const deserializeHtmlToSlateValue = html => {
  const htmlDoc = domParser.parseFromString(html, HTML_PASTE_DATA_KEY);
  const htmlFragment = deserialize(htmlDoc.body);
  return htmlFragment;
};
