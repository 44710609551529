import React, { memo } from 'react';
import { css } from '@emotion/core';
import { Text } from '@ftbpro/mm-admin-ui-components';
import { FullBleedIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from '../Plugin/Plugin';
import { getImageBlockStyles, IMAGE_BLOCK_CLASS } from './styles/imageBlock.styles';
import { getBlocksStyles } from '../../components/blockList/BlocksFactory/blocksFactory.styles';
import { IMAGE_SIZE_TYPES } from '../../../constants/image.constants';

const IMAGE_OVERVIEW_TEXT = 'Image';

export const ImageBlockComponent = ({
  data,
  isEditing,
  shouldPreventEdit,
  shouldPreventDelete,
  onMediaDeleted,
  onMediaEdited,
  onMediaEditCanceled,
}) => {
  const getBlockValue = () => data.value;

  const getCaption = () => getBlockValue().caption || '';

  const getCredit = () => {
    const { credit } = getBlockValue();
    return credit ? ` | ${credit}` : '';
  };

  const getCreditAndCaption = () => `${getCaption()}${getCredit()}`;

  const openInNewTab = url => window.open(url, '_blank').focus();

  const {
    image,
    caption,
    container,
    fullBleedIconWrapper,
  } = getImageBlockStyles();

  return (
    <Plugin.Block
      shouldPreventEdit={shouldPreventEdit}
      shouldPreventDelete={shouldPreventDelete}
      isEditing={isEditing}
      onDelete={onMediaDeleted}
      onEdit={onMediaEdited}
      onCancel={onMediaEditCanceled}
    >
      <div css={css(container)}>
        <div className={IMAGE_BLOCK_CLASS.IMAGE_WRAPPER}>
          <img src={data.value.url} alt="BlockImage" css={css(image)} />
          {data?.value?.linkURL ? (
            <div className={IMAGE_BLOCK_CLASS.HOVER_LINK}>
              <div className={IMAGE_BLOCK_CLASS.HOVER_LINK_TEXT} onClick={() => openInNewTab(data.value.linkURL)}>
                {data.value.linkURL}
              </div>
            </div>
          ) : null}
        </div>
        {data.value.sizeType === IMAGE_SIZE_TYPES.FULL_BLEED ? (
          <div css={css(fullBleedIconWrapper)}>
            <FullBleedIcon />
          </div>
        ) : null}
        <div css={css(caption)}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>{getCreditAndCaption()}</Text>
        </div>
      </div>
    </Plugin.Block>
  );
};

export const ImageBlockOverview = ({ value }) => {
  const { url, caption, credit } = value;
  const { overviewImage } = getImageBlockStyles();
  const styles = getBlocksStyles();

  const renderBlockTitle = () => {
    if (caption || credit) {
      return `${caption || ''} ${caption && credit ? ' | ' : ''}${credit || ''}`;
    }
    return IMAGE_OVERVIEW_TEXT;
  };
  return (
    <Plugin.OverviewBlock>
      <div css={styles.overviewBlockIcon}>
        <img src={url} alt="OverviewImage" css={css(overviewImage)} />
      </div>
      <div css={styles.overviewBlockTextWrapper}>
        <Text type={Text.TEXT_TYPES.TITLE_M} style={styles.overviewBlockText}>
          {renderBlockTitle()}
        </Text>
      </div>
    </Plugin.OverviewBlock>
  );
};

const shouldReturnMemoizedComponent = (prevProps, nextProps) => !nextProps.isCurrentActiveBlockKey;

export const ImageBlock = memo(ImageBlockComponent, shouldReturnMemoizedComponent);
