const BACK_OFFICE_EVENT_TO_GA = 'back office event to ga';

export const reportEvent = (backOfficeEventLabel, eventType, reportingApplication, customDimensions) => {
  window.dataLayer.push({
    event: BACK_OFFICE_EVENT_TO_GA,
    backoffice_event_category: reportingApplication,
    backoffice_event_label: backOfficeEventLabel.toLowerCase(),
    backoffice_event_action: eventType,
    ...customDimensions,
  });
};
