import { getEditorServices } from '../services/editorServiceApiProvider';
import { areDraftValuesDifferent } from '../utils/draftComparison.utils';

import { blockListSelector } from '../store/blocks/blockList.selector';
import { draftSelector } from '../store/draft/draft.selector';

import { HTTP_STATUS_CODES } from '../../../../core/network/mmServicesApiProvider';

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

class EditorSaveManager {
  property = null;

  oldDraftValue = null;

  intervalRef = null;

  onSaveHandler = null;

  onStartSaving = null;

  addToUndoList = null;

  isSavingInProgress = false;

  updateRequestLetterId = makeid(2);

  updateRequestCounter = 0;

  startAutoSave({ property, onSaveHandler, onStartSaving, onAddToUndoList, getState }) {
    this.property = property;
    this.onSaveHandler = onSaveHandler;
    this.addToUndoList = onAddToUndoList;
    this.onStartSaving = onStartSaving;
    this.oldDraftValue = draftSelector.draftPayload(getState());
    this.intervalRef = setInterval(() => this.saveIfNeedTo(), 5000);
    this.getState = getState;
  }

  saveIfNeedTo = () => {
    const state = this.getState();
    const draftData = draftSelector.draftPayload(state);
    const isOverviewModeActive = blockListSelector.isOverviewModeActive(state);
    const shouldSave = !this.isSavingInProgress && !isOverviewModeActive && areDraftValuesDifferent(this.oldDraftValue, draftData);
    return new Promise(resolve => {
      if (shouldSave) {
        this.onStartSaving();
        this.isSavingInProgress = true;
        const updateCounter = `${this.updateRequestLetterId}-${this.updateRequestCounter}`;
        this.updateRequestCounter += 1;
        this.addToUndoList(this.oldDraftValue);
        getEditorServices().updateDraft({
          property: this.property,
          id: draftSelector.draftId(state),
          draft: draftData,
          updateCounter,
        }).then(() => {
          this.oldDraftValue = draftData;
          const saveDraftResponse = {
            success: true,
            error: null,
          };
          this.onSaveHandler(saveDraftResponse);
          this.isSavingInProgress = false;
          resolve(saveDraftResponse);
        }).catch(e => {
          const saveDraftResponse = {
            success: false,
            error: e,
          };
          this.onSaveHandler(saveDraftResponse);
          this.isSavingInProgress = false;
          if (e.code === HTTP_STATUS_CODES.CONFLICT) {
            this.stopAutoSave();
          }
          resolve(saveDraftResponse);
        });
      } else {
        resolve(true);
      }
    });
  };

  stopAutoSave() {
    clearInterval(this.intervalRef);
  }
}

let instance;
export const getEditorSaveManager = () => {
  if (!instance) {
    instance = new EditorSaveManager();
  }
  return instance;
};
