export const IMAGE_DASHBOARD_DELETE_MODAL_TEXTS = {
  CANCEL_BUTTON: 'Cancel',
  DELETE_BUTTON: 'Delete',
  DESCRIPTION: 'This image will be permanently removed from the media library and from any article using it. Are you sure you want to delete it?',
  TITLE: 'Delete image',
};

export const IMAGE_DASHBOARD_UPLOAD_IMAGE_TEXTS = {
  TITLE: 'Upload image',
  LABEL_TEXT: 'By uploading the content you confirm your right to use it',
  ASPECT_RATIO: '16/9',
  IMAGE_REQUIREMENTS: {
    FILETYPE: 'Supported Filetypes: jpeg, png, jpg, webp',
    DIMENSIONS: 'Minimum dimensions: 400X224 pixels',
    SIZE: 'Maximum size: 5MB',
  },
  ERROR_TYPE: 'Filetype is not supported',
  ERROR_SIZE: 'The image you added exceeds 5MB. Please choose a smaller image.',
  BUTTON: 'Upload',
};

export const IMAGE_DASHBOARD_BULK_UPLOAD_IMAGE_TEXTS = {
  TITLE: 'Upload images',
  LABEL_TEXT: 'By uploading the content you confirm your right to use it',
  UPLOAD_BUTTON: 'Upload',
  CLOSE: 'Close',
};

export const IMAGE_INFO_CONFIRMATION_MODAL_TEXT = {
  TITLE: 'Discard changes',
  DESCRIPTION: 'Changes will not be saved. Are you sure you want to leave this page?',
  YES: 'Discard changes',
  NO: 'Keep editing',
};
