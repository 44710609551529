import { SITE_VARIABLES } from '../../../../../../core/styles/variables';
import { flexRowCenter } from '../../../../../../core/styles/flex';

export const CSS_SELECTORS = {
  FOCUS: ':focus',
  HOVER_OR_FOCUS_HOVER: '&:hover, &:focus:hover:not(:disabled)',
  DISABLED: '&:disabled',
  HOVER: '&:hover',
  SPAN: '& > span',
};
export const IMAGE_BLOCK_CLASS = {
  HOVER_LINK: 'hoverLinkField',
  HOVER_LINK_TEXT: 'hoverLinkFieldText',
  IMAGE_WRAPPER: 'imageWrapper',
};
export const getImageBlockStyles = () => ({
  container: {
    marginBottom: '8px',
    position: 'relative',
    [`${CSS_SELECTORS.HOVER}`]: {
      [`.${IMAGE_BLOCK_CLASS.HOVER_LINK}`]: {
        ...flexRowCenter,
        position: 'absolute',
        alignItems: 'center',
        bottom: '0',
      },
    },
    [`.${IMAGE_BLOCK_CLASS.IMAGE_WRAPPER}`]: {
      position: 'relative',
    },
    [`.${IMAGE_BLOCK_CLASS.HOVER_LINK}`]: {
      display: 'none',
      backgroundColor: `${SITE_VARIABLES.COLORS.BLACK}`,
      maxHeight: '18px',
      height: '18px',
      width: '100%',
      maxWidth: '320px',
      cursor: 'pointer',
    },
    [`.${IMAGE_BLOCK_CLASS.HOVER_LINK_TEXT}`]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '9px',
      lineHeight: '1.25',
      padding: `${SITE_VARIABLES.PADDING.TINY}`,
      textOverflow: 'ellipsis',
      textDecoration: 'underline',
      color: `${SITE_VARIABLES.COLORS.WHITE}`,
      borderBottom: '1px solid',
    },
  },

  image: {
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  caption: {
    paddingTop: '4px',
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  overviewImage: {
    width: '32px',
    height: '20px',
  },
  fullBleedIconWrapper: {
    color: SITE_VARIABLES.COLORS.SELECTED,
    background: SITE_VARIABLES.COLORS.HOVER,
    height: '28px',
    width: '28px',
    position: 'absolute',
    top: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
