import React, { useRef } from 'react';
import { HtmlEmbedBlock } from '../../shared/HtmlEmbedBlock';
import { LiveBlogEditorType } from '../liveBlog.types';
import { getLiveBlogIframe } from '../liveBlogEmbed.utils';

export const LiveBlogBlock = (props: LiveBlogEditorType) => {
  const { data } = props;
  const blockRef = useRef(null);
  const iframe = getLiveBlogIframe(data.value.eventId);

  const embedBlockProps = {
    ...props,
    data: {
      ...data,
      value: {
        ...data.value,
        embedCodeHTMLString: iframe,
      },
    },
    blockRef,
  };

  return <HtmlEmbedBlock {...embedBlockProps} />;
};
