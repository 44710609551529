import { Table } from '@ftbpro/mm-admin-ui-components';
import { AnalyticsSliceState, Property, TabKey } from './store/types';

export const CONTRIBUTORS_ANALYTICS = 'Contributors Analytics';

export const ANALYTICS_ROUTES_BASE = '/contributorsAnalytics';

export const CONTRIBUTORS_ANALYTICS_ROUTES = {
  BASE: ANALYTICS_ROUTES_BASE,
  SITE_STATS: `${ANALYTICS_ROUTES_BASE}/siteStats`,
  USER_STATS: `${ANALYTICS_ROUTES_BASE}/myStats`,
};

export const ANALYTICS_INITIAL_STATE: AnalyticsSliceState = {
  totalStats: {
    nonExpertViews: 0,
    siteViews: 0,
    pubCount: 0,
    activeUsers: [],
  },
  loading: false,
  stats: {},
  error: '',
  siteStatsDate: { value: '', date: '' },
  statsSortConfig: {
    sortByKey: 'name',
    direction: Table.SortDirections.ASC,
  },
  userStats: [],
  userStatsDate: { value: '', date: '' },
  paymentsStats: [],
  userStatsTab: TabKey.Overview,
  siteStatsTab: TabKey.Overview,
  paymentsStatsDate: { value: '', date: '' },
  paymentsLoading: false,
  usersLoading: false,
  postsLoading: false,
  postStats: [],
  postProperty: {} as Property,
  postStatsDate: { value: '', date: '' },
  postPagination: {
    count: 0,
    next: '',
    prev: '',
  },
  postQuery: {
    userId: '',
    startDate: '',
    endDate: '',
    limit: 0,
    search: '',
  },
  authors: [],
};

export const MONTHS_IN_DATE_FILTER = 20;
export const YEARS_IN_DATE_FILTER = 6;
export const DEBOUNCE_DELAY = 300;

export const CONTRIBUTORS_ACTION_TYPE = 'contributorsAnalytics';
export const USER_STATS_EVENT_TYPES = {
  USER_STATS_PROPERTY_FILTER: `${CONTRIBUTORS_ACTION_TYPE} post stats filter`,
  USER_STATS_FILTER_CLICK: `${CONTRIBUTORS_ACTION_TYPE} user stats filter`,
  USER_STATS_SITE_STATS_CLICK: `${CONTRIBUTORS_ACTION_TYPE} Site Stats button clicked`,
};

export const SITE_STATS_EVENT_TYPES = {
  SITE_STATS_FILTER_CLICK: `${CONTRIBUTORS_ACTION_TYPE} Site Stats filter`,
  SITE_STATS_USER_STATS_CLICK: `${CONTRIBUTORS_ACTION_TYPE} My Stats button clicked`,
};

export const WORDPRESS_URL_PARAM = '/?p=';
