import {
  format,
  isToday as isTodayHelper,
  isThisYear as isThisYearHelper,
  setHours,
  setMinutes,
  parseISO as parseISOToDate,
  addHours,
  addMonths,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  subHours,
  subDays,
  subYears,
  getUnixTime,
  setSeconds,
  getHours,
  getMinutes,
  isSameDay as isDayEqual,
  formatISO as formatToISO,
  addDays,
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

export const DATE_FORMATS = {
  DEFAULT: 'dd/MM/yyyy h:mmaa',
  DATE_TIME_AMPM: 'dd/MM/yyyy hh:mm aa',
  TIME: 'hh:mm aa',
  DATE: 'dd/MM/yyyy',
  LONG_DATE: 'dd / MM / yyyy',
  DATE_TWO_DIGITS: 'dd/MM/yy',
  MONTH_AND_DAY: 'MMMM d',
  SHORT_DATE_WITH_TIME: 'MMM d h:mmaa',
  SHORT_DATE: 'MMM d',
  MONTH_DAY_YEAR: 'MMM d, yyyy',
};

export const TIME_PERIODS = {
  AM: 'AM',
  PM: 'PM',
};

export const TIME_ZONES = {
  EST: 'EST',
  GMT: 'GMT',
  PST: 'PST',
};

const DAY_LENGTH = 24 * 60 * 60 * 1000;

const normalizeDate = date => (typeof date === 'string' ? parseISOToDate(date) : date);

export function unixToDate(unixTime = 0) {
  return new Date(unixTime * 1000);
}

export function dateToUTCInISOString(date) {
  return new Date(date.getTime()).toISOString();
}

export function formatDate(datetime, formatString = DATE_FORMATS.DEFAULT) {
  return format(normalizeDate(datetime), formatString);
}

export function isTodayDate(date) {
  return isTodayHelper(normalizeDate(date));
}

export function isThisYear(date) {
  return isThisYearHelper(normalizeDate(date));
}

export function getFormattedDate(date) {
  let dateFormat = DATE_FORMATS.DATE;
  if (isTodayDate(date)) {
    dateFormat = DATE_FORMATS.TIME;
  } else if (isThisYear(date)) {
    dateFormat = DATE_FORMATS.MONTH_AND_DAY;
  }
  return formatDate(date, dateFormat);
}

export const roundToNearestMinutes = roundTo => {
  const ROUNDING = roundTo * 60 * 1000;
  return new Date(new Date(Math.ceil((+new Date()) / ROUNDING) * ROUNDING));
};

export const getAMPMTimeFromDate = date => {
  let hours = getHours(date);
  let minutes = getMinutes(date);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  hours = hours < 10 ? `0${hours}` : hours;
  return `${hours}:${minutes} ${ampm}`;
};

export const getAMPMTimeObject = date => {
  const ampmTime = getAMPMTimeFromDate(date);
  const [time, modifier] = ampmTime.split(' ');
  const [hours, minutes] = time.split(':');
  return {
    date,
    hours: hours.length === 1 ? `0${ hours}` : hours,
    minutes: minutes.length === 1 ? `0${ minutes}` : minutes,
    period: modifier,
  };
};

export const getTimeFromAMPM = ampmTime => {
  const [time, modifier] = ampmTime.split(' ');
  let [hours, minutes] = time.split(':'); // eslint-disable-line
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const getTimeFromTimeVars = (hours, minutes, period) => {
  return getTimeFromAMPM(`${hours}:${minutes} ${period}`);
};

export const setTimeToDate = (date, time) => {
  const [hours, minutes] = time.split(':');
  const dateWithHoursSet = setHours(new Date(date), hours);
  return setMinutes(dateWithHoursSet, minutes);
};

export const getDateIfExists = (date, defaultValue = null) => (date ? new Date(date) : defaultValue);

export const addHoursToDate = (date, amount) => addHours(date, amount);

export const addMonthsToDate = (date, amount) => addMonths(date, amount);

export const subHoursFromDate = (date, amount) => subHours(date, amount);

export const subDaysFromDate = (date, amount) => subDays(date, amount);

export const subYearsFromDate = (date, amount) => subYears(date, amount);

export const isDateAfter = (date, dateToCompare) => isAfter(date, dateToCompare);

export const isDateBefore = (date, dateToCompare) => isBefore(date, dateToCompare);

export const isDateEqual = (date, dateToCompare) => isEqual(date, dateToCompare);

export const getDifferenceInMinutes = (first, second) => differenceInMinutes(first, second);

export const getDifferenceInHours = (first, second) => differenceInHours(first, second);

export const getDifferenceInDays = (first, second) => differenceInDays(first, second);

export const getDifferenceInWeeks = (first, second) => differenceInWeeks(first, second);

export const getDifferenceInMonths = (first, second) => differenceInMonths(first, second);

export const getDifferenceInYears = (first, second) => differenceInYears(first, second);

export const getStartOfDay = date => startOfDay(date);

export const dateToUnix = date => getUnixTime(date);

export const setHoursMinutesSeconds = (date, { hours, minutes, seconds }) => setHours(setMinutes(setSeconds(date, seconds), minutes), hours);

export const isSameOrAfter = (first, second) => isDateAfter(first, second) || isDateEqual(first, second);

export const isSameOrBefore = (first, second) => isDateBefore(first, second) || isDateEqual(first, second);

export const isSameDay = (first, second) => isDayEqual(first, second);

export const formatISO = date => formatToISO(date);

export const parseISO = isoString => parseISOToDate(isoString);

export const addDaysToDate = (date, amount) => addDays(date, amount);

export const getYesterdayDateFromMidnight = () => {
  const yesterday = new Date(new Date().getTime() - DAY_LENGTH);
  return setHoursMinutesSeconds(yesterday, { hours: 0, minutes: 0, seconds: 0 });
};

export const getPreviousMondayFromMidnight = () => {
  const date = new Date();
  const day = date.getDay();
  const prevMonday = new Date();
  if (date.getDay() === 0) {
    prevMonday.setDate(date.getDate() - 7);
  } else {
    prevMonday.setDate(date.getDate() - (day - 1));
  }

  return setHoursMinutesSeconds(prevMonday, { hours: 0, minutes: 0, seconds: 0 });
};

export const getFirstDayOfTheMonthDateFromMidnight = () => {
  const date = new Date();
  const firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  return firstDayOfTheMonth;
};

export const getDiffForHumans = (datetime, daysLimit = 7) => {
  const dateNow = new Date();
  const date = normalizeDate(datetime);
  const diffDays = getDifferenceInDays(dateNow, date);

  if (diffDays <= daysLimit) {
    if (diffDays === 0) {
      const diffHours = getDifferenceInHours(dateNow, date);
      const diffMinutes = getDifferenceInMinutes(dateNow, date);

      if (diffHours) {
        return (diffHours === 1) ? 'an hour ago' : `${diffHours} hours ago`;
      }

      if (diffMinutes) {
        return (diffMinutes === 1) ? 'a minute ago' : `${diffMinutes} minutes ago`;
      }

      return 'just now.';
    }

    if (diffDays >= 7) {
      const diffYears = getDifferenceInYears(dateNow, date);
      const diffMonths = getDifferenceInMonths(dateNow, date);
      const diffWeeks = getDifferenceInWeeks(dateNow, date);

      if (diffYears) {
        return (diffYears === 1) ? 'a year ago' : `${diffYears} years ago`;
      }

      if (diffMonths) {
        return (diffMonths === 1) ? 'a month ago' : `${diffMonths} months ago`;
      }

      if (diffWeeks) {
        return (diffWeeks === 1) ? 'a week ago' : `${diffWeeks} weeks ago`;
      }
    }

    return (diffDays === 1) ? 'a day ago' : `${diffDays} days ago`;
  }

  return formatDate(date, DATE_FORMATS.MONTH_DAY_YEAR);
};
