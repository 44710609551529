import { runFacebookScript } from '../plugins/externalScripts/editorPluginsScripts';
import { mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { EditorServiceDataProvider } from './editorServiceConfigsProvider';
import { getOembedObjectFromOEmbedResponse } from './oembed/oEmbedApiProvider';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';

export async function loadFacebook(callback) {
  try {
    const fb = await runFacebookScript();
    fb.XFBML.parse();
    if (callback) {
      fb.Event.subscribe('xfbml.render', callback);
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function getFacebookPostOembedData(url, queryParam, property) {
  const res = await mmNetworkService.fetch(EditorServiceDataProvider.getFacebookPostOembedEndpoint(url, queryParam, property));
  return getOembedObjectFromOEmbedResponse(res, url, BLOCK_TYPES.FACEBOOK);
}

export async function getFacebookVideoOembedData(url, queryParam, property) {
  const res = await mmNetworkService.fetch(EditorServiceDataProvider.getFacebookVideoOembedEndpoint(url, queryParam, property));
  return getOembedObjectFromOEmbedResponse(res, url, BLOCK_TYPES.FACEBOOK);
}
