import { createSlice } from '@reduxjs/toolkit';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { AppDispatch } from 'core/store';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import {
  pushGlobalNotificationActionCreator,
} from 'core/globalNotifications/store/globalNotifications.actions';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import {
  MIGRATE_ERROR_MESSAGE,
  MIGRATE_SUCCESS_MESSAGE,
} from '../../../components/editorialMenu/migrationMenu/migration.strings';
import { MigrationProvider } from '../../../../services/migrationServices/migrationServiceConfigsProvider';

export const MIGRATION = 'migration';

export interface MigrationSliceState {
  loading: boolean
}

export const initialState : MigrationSliceState = {
  loading: false,
};

const migrationSlice = createSlice({
  name: MIGRATION,
  initialState,
  reducers: {
    migrateArticlePending(state) {
      state.loading = true;
    },
    migrateArticleSuccess(state) {
      state.loading = false;
    },
    migrateArticleRejected(state) {
      state.loading = false;
    },
  },
});

export const migrateArticle = ({ migrateUrl, draftId }: { migrateUrl:string, draftId:string }) => async (dispatch: AppDispatch) => {
  const body = { postURL: migrateUrl, draftID: draftId };
  await dispatch(networkAction({
    url: MigrationProvider.getMigrationUrl(),
    method: HttpMethod.Post,
    body: JSON.stringify(body),
    onPending: migrateArticlePending,
    onSuccess: () => {
      dispatch(migrateArticleSuccess());
      return pushGlobalNotificationActionCreator({
        text: MIGRATE_SUCCESS_MESSAGE,
        status: SNACK_BAR_STATUSES.SUCCESS,
      });
    },
    onFailure: () => {
      dispatch(migrateArticleRejected());
      return pushGlobalNotificationActionCreator({
        text: MIGRATE_ERROR_MESSAGE,
        status: SNACK_BAR_STATUSES.ERROR,
      });
    },
  }));
};

export const {
  migrateArticleRejected,
  migrateArticleSuccess,
  migrateArticlePending,
} = migrationSlice.actions;

export default migrationSlice.reducer;
