export const DATEPICKER_TITLE = 'Date';

export const TIME_PICKER_TEXT = {
  TITLE: 'Time (Local)',
  HOURS_PLACEHOLDER: 'hh',
  MINUTES_PLACEHOLDER: 'mm',
};

export const DELETE_POST_MODAL_TEXT = {
  TITLE: 'Delete article',
  MODAL_TEXT: 'Are you sure you want to permanently delete this article?',
  BUTTON_TEXT: 'Yes',
  CANCEL_BUTTON: 'No',
};

export const LABELS_MODAL_TEXT = {
  TITLE: 'Edit labels',
  MODAL_TEXT: 'Labels help categorize articles, which later assist in finding them in the Publisher.',
  BUTTON_TEXT: 'Apply',
  CANCEL_BUTTON: 'Cancel',
  INPUT_LABEL: 'Label',
  INPUT_PLACEHOLDER: 'Enter label name',
  APPLY_DISABLED_TOOLTIP: 'No changes were made',
};

export const UNFEATURE_FEED_MODAL_COMPONENT_TEXT = {
  TITLE: 'Removing article from destination',
  MODAL_TEXT: 'You are about to remove this article from its current destination',
  BUTTON_TEXT: 'Remove',
  CANCEL_BUTTON: 'Cancel',
};

export const HIDE_POST_MODAL_COMPONENT_TEXT = {
  ARCHIVE: {
    TITLE: 'Archive this post',
    MODAL_TEXT: 'Archiving this article will remove it from the table but not delete it. Do you want to proceed?',
    BUTTON_TEXT: 'Archive',
    CANCEL_BUTTON: 'Cancel',
  },
  UNARCHIVE: {
    TITLE: 'Unarchive this post',
    MODAL_TEXT: 'Are you sure you want to unarchive this article?',
    BUTTON_TEXT: 'Unarchive',
    CANCEL_BUTTON: 'Cancel',
  },
};

export const PLACING_CONTEXT_SCREEN = {
  ECHO_DASHBOARD: 'echo dashboard',
  ECHO_CUSTOMIZATION: 'echo_customization',
};

export const BACK_CONFIRMATION_MODAL_CONSTANTS = {
  TITLE: 'Discard changes',
  MODAL_TEXT: 'Changes will not be saved. Are you sure you want to leave this page?',
  BUTTON_TEXT: 'Discard changes',
  CANCEL_BUTTON: 'Keep editing',
};
