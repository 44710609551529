import { v1 as uuid } from 'uuid';
import { isEmpty, isNull } from '@ftbpro/mm-admin-core-utils';
import { DashboardsPaths } from '../../reports.constants';
import { ChartsFormat, FilterId } from '../../store/dashboards/dashboards.constants';
import { ChartState, Filter } from '../../store/dashboards/dashboards.types';
import { DASHBOARD_TITLES, FILTER_TITLES, HOMEPAGE_STATS_INFO, MM_SSP_REVENUE_STATS_INFO, REVENUE_OVERVIEW_STATS_INFO, REVENUE_OVERVIEW_SUMMARY_STATS_INFO, UNIFIED_PLAYER_REVENUE_STATS_INFO, VOLTAX_PLAYER_REVENUE_STATS_INFO } from './dashboardPage.strings';
import { StatsName } from './dashboardPage.constants';

const MAX_FRACTION_DIGITS = 2;
const DEFAULT_CURRENCY = 'USD';
const LOCALE = 'en-US';
export const DASHBOARD_TO_DASHBOARD_NAME: Readonly<Record<DashboardsPaths, string>> = {
  [DashboardsPaths.Homepage]: DASHBOARD_TITLES.HOMEPAGE,
  [DashboardsPaths.RevenueOverview]: DASHBOARD_TITLES.REVENUE_OVERVIEW,
  [DashboardsPaths.MMSSPRevenue]: DASHBOARD_TITLES.MM_SSP_REVENUE,
  [DashboardsPaths.VoltaxRevenue]: DASHBOARD_TITLES.VOLTAX_PLAYER_REVENUE,
  [DashboardsPaths.RevenueOverviewSummary]: DASHBOARD_TITLES.REVENUE_OVERVIEW_SUMMARY,
  [DashboardsPaths.UnifiedPlayerRevenue]: DASHBOARD_TITLES.UNIFIED_PLAYER_REVENUE,
};

export const getChartsPerLineFromDashboard = (chartsData: Record<string, ChartState>) => {
  return Object.values(chartsData).reduce((acc: ChartState[][], chart: ChartState) => {
    const { chartLine } = chart;
    if (acc[chartLine]) {
      acc[chartLine].push(chart);
    } else {
      acc[chartLine] = [chart];
    }
    return acc;
  }, []);
};

export const isEmptyDataset = (chartData: any) => {
  return ('value' in chartData && isNull(chartData.value)) || ('data' in chartData && isEmpty(chartData.data));
};

export const createNewFilter = (data: Filter) => {
  const filterID = uuid();
  return {
    ...data,
    id: filterID,
  };
};

export const getChartFormatter = (formatStyle = ChartsFormat.Currency, compactNotation = false) => {
  const currency = formatStyle === ChartsFormat.Currency ? { currency: DEFAULT_CURRENCY } : {};
  return new Intl.NumberFormat(LOCALE, {
    style: formatStyle,
    maximumFractionDigits: MAX_FRACTION_DIGITS,
    ...currency,
    ...(compactNotation ? { notation: 'compact' } : {}),
  }).format;
};

export const FILTERS_TO_FILTERS_NAMES: Readonly<Record<FilterId, string>> = {
  [FilterId.Domain]: FILTER_TITLES.DOMAIN,
  [FilterId.Country]: FILTER_TITLES.COUNTRY,
  [FilterId.Placement]: FILTER_TITLES.PLACEMENT,
  [FilterId.Device]: FILTER_TITLES.DEVICE,
  [FilterId.OS]: FILTER_TITLES.OS,
  [FilterId.Activity]: FILTER_TITLES.ACTIVITY,
  [FilterId.PlacementId]: FILTER_TITLES.PLACEMENT_ID,
  [FilterId.DemandSource]: FILTER_TITLES.DEMAND_SOURCE,
  [FilterId.PlayerName]: FILTER_TITLES.PLAYER_NAME,
  [FilterId.DemandOwner]: FILTER_TITLES.DEMAND_OWNER,
  [FilterId.Browser]: FILTER_TITLES.BROWSER,
  [FilterId.DemandType]: FILTER_TITLES.DEMAND_TYPE,
};

export const DASHBOARD_TO_STATS_INFO : {[key in DashboardsPaths] ?: {[titleKey in StatsName] ?: string}} = {
  [DashboardsPaths.Homepage]: HOMEPAGE_STATS_INFO,
  [DashboardsPaths.RevenueOverview]: REVENUE_OVERVIEW_STATS_INFO,
  [DashboardsPaths.MMSSPRevenue]: MM_SSP_REVENUE_STATS_INFO,
  [DashboardsPaths.VoltaxRevenue]: VOLTAX_PLAYER_REVENUE_STATS_INFO,
  [DashboardsPaths.UnifiedPlayerRevenue]: UNIFIED_PLAYER_REVENUE_STATS_INFO,
  [DashboardsPaths.RevenueOverviewSummary]: REVENUE_OVERVIEW_SUMMARY_STATS_INFO,
};

export const getFilterSelectedValues = (filterId: FilterId, filters: Filter[]) => {
  return filters.find(selectedFilter => selectedFilter.field === filterId)?.data || [];
};
