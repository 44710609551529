import { ConfigsProvider } from 'core/configs/ConfigsProvider';

const { SETUP_SERVICE_API_BASE_URL } = ConfigsProvider;

const SI_HANDLING = '/sihandling';

const MIGRATE = '/migrate-manual-draft';

export class MigrationProvider {
  static getMigrationUrl() {
    return `${SETUP_SERVICE_API_BASE_URL}${SI_HANDLING}${MIGRATE}`;
  }
}
