import React from 'react';

import { Plugin } from '../Plugin/Plugin';

export const MMPlayerBlock = ({
  data,
  loading,
  shouldPreventEdit,
  shouldPreventDelete,
  isEditing,
  className,
  onMediaDeleted,
  onMediaEdited,
  onMediaEditCanceled,
}) => {
  return (
    data && data.value.html
      ? (
        <Plugin.Block
          loading={loading}
          shouldPreventEdit={shouldPreventEdit}
          shouldPreventDelete={shouldPreventDelete}
          onDelete={onMediaDeleted}
          onEdit={onMediaEdited}
          onCancel={onMediaEditCanceled}
          isEditing={isEditing}
        >
          <div
            dangerouslySetInnerHTML={{ /* eslint-disable-line */
              __html: data.value.previewHtml || data.value.embedCodeHTMLString,
            }}
            className={className}
          />
        </Plugin.Block>
      )
      : null
  );
};
