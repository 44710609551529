/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSliceName } from 'core/store/store.utils';
import { propertySelector } from 'core/store/property/property.selector';
import { Product } from 'core/constants';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import { AppDispatch, RootState } from 'core/store';
import { SmartServiceConfigsProvider } from 'containers/cms/editor/services/smartServiceConfigsProvider';
import { DropdownListItemProps } from '@ftbpro/mm-admin-ui-components';
import { blockListSelector } from '../../blocks/blockList.selector';
import { postTitleSelector } from '../../postTitle/postTitle.selector';
import { createSuggestionText } from '../../../../utils/cms.utils';
import { articleTextPurgeBeforeApiRequest } from '../../../utils/tagsAndSeo.utils';
import { TagsServiceApiProvider } from '../../../services/tagsServiceConfigsProvider';
import { EDITOR_ACTION_TYPE_PREFIX } from '../../editor.constants';
import { META_ASSISTANT_CONTENT_TYPE } from '../../../components/editorialMenu/seoMenu/seoMenu.constants';
import { TagsAndSeoSliceState } from './types';
import { SuggestionsType } from '../../postTitle/postTitle.types';

const sliceScope = 'tagsAndSeo';
const SLICE_NAME_WITH_PREFIX = EDITOR_ACTION_TYPE_PREFIX + sliceScope;
const SLICE_NAME = getSliceName(SLICE_NAME_WITH_PREFIX, sliceScope);
const LIMIT_SUGGEST = 10;
const LIMIT_AUTO_COMPLETE = 5;

export const initialState: TagsAndSeoSliceState = {
  shouldShowTagsAndSeoModal: false,
  articleTags: [],
  seoDescription: '',
  suggestedDescriptions: [],
  suggestedDescriptionsLoading: false,
  suggestedDescriptionsError: null,
  seoTitle: '',
  tagsInputValue: '',
  autocompletedTags: [],
  suggestedTags: [],
  cachedSuggestedTags: [],
  isDropdownLoading: false,
  autocompletedTagsError: null,
  suggestedTagsError: null,
  suggestedTagsLoading: false,
};

const tagsAndSeo = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    setTagsInputValue(state, action: PayloadAction<string>) {
      state.tagsInputValue = action.payload;
      state.isDropdownLoading = true;
    },
    updateArticleTagsValue(state, action: PayloadAction<Array<string>>) {
      state.articleTags = action.payload;
    },
    updateSeoDescriptionValue(state, action: PayloadAction<string>) {
      state.seoDescription = action.payload;
    },
    setSeoTitleValue(state, action: PayloadAction<string>) {
      state.seoTitle = action.payload;
    },
    updateSeoTitleValue(state, action: PayloadAction<string>) {
      state.seoTitle = action.payload;
    },
    setDropdownLoading(state, action: PayloadAction<boolean>) {
      state.isDropdownLoading = action.payload;
    },
    purgeAutocompletedTags(state) {
      state.autocompletedTags = [];
    },
    updateArticleTags(
      state,
      action: PayloadAction<{
        currentTags: Array<string>;
        currentSuggestedTags: Array<string>;
      }>,
    ) {
      const { currentTags, currentSuggestedTags } = action.payload;
      state.articleTags = currentTags;
      state.suggestedTags = currentSuggestedTags;
    },
    purgeAutocompletedTagsError(state) {
      state.autocompletedTagsError = null;
    },
    resetEditorState(state) {
      Object.assign(state, initialState);
    },
    suggestTagsPending(state) {
      state.suggestedTagsLoading = true;
    },
    suggestTagsSuccess(state, action: PayloadAction<Array<string>>) {
      state.suggestedTagsLoading = false;
      state.cachedSuggestedTags = action.payload;
      state.suggestedTagsError = null;
      state.suggestedTags = action.payload.filter(
        item => !state.articleTags.includes(item),
      );
    },
    suggestTagsRejected(state, action: PayloadAction<string>) {
      state.suggestedTagsLoading = false;
      state.suggestedTagsError = action.payload;
    },
    autocompleteTagsSuccess(
      state,
      action: PayloadAction<Array<string | number | DropdownListItemProps>>,
    ) {
      state.autocompletedTags = action.payload;
      state.isDropdownLoading = false;
    },
    autocompleteTagsRejected(state) {
      state.isDropdownLoading = false;
    },
    getMetaDescriptionSuggestionsPending(state) {
      state.suggestedDescriptionsLoading = true;
    },
    getMetaDescriptionSuggestionsSuccess(
      state,
      action: PayloadAction<SuggestionsType[]>,
    ) {
      state.suggestedDescriptionsLoading = false;
      state.suggestedDescriptions = action.payload;
    },
    getMetaDescriptionSuggestionsRejected(state, action) {
      state.suggestedDescriptionsError = action.payload;
      state.suggestedDescriptionsLoading = false;
    },
    resetMetaDescriptionSuggestions(state) {
      state.suggestedDescriptions = [];
    },
    setAutoFillSeoDescription(state, action: PayloadAction<string>) {
      state.seoDescription = action.payload;
    },
    reportEditorialMenuItemClick(state, action) {},
    reportTakeToTagsClick(state) {},
    reportTakeToSeoTitleClick(state) {},
    reportTakeToSeoDescriptionClick(state) {},
    reportTakeToHyperlinksClick(state) {},
    reportLightBulbTooltipClick(state, action) {},
    reportSeoHelpCenterButtonClick(state) {},
    reportSuggestedSeoDescriptionClicked(state) {},
    reportSuggestedSeoDescriptionRegenerationClicked(state) {},
    reportSuggestedSeoDescriptionFeedback(state, action) {},
    reportSuggestedSeoDescriptionRecommendationClicked(state, action) {},
    reportSuggestedSeoDescriptionSparkClicked(state) {},
  },
});

export const suggestTags = () => async (dispatch: AppDispatch, getState: RootState) => {
    const state = getState();
    const currentPropertySlug = propertySelector.currentPropertySlug(state, Product.CMS) || '';
    const articleSummary = blockListSelector.blockListBodyText(state);
    const title = postTitleSelector.postTitle(state);
    const text = createSuggestionText(
      title,
      articleSummary ? articleTextPurgeBeforeApiRequest(articleSummary) : '',
    );
    await dispatch(
      networkAction({
        url: TagsServiceApiProvider.suggestTags(currentPropertySlug),
        method: HttpMethod.Post,
        body: JSON.stringify({ text, limit: LIMIT_SUGGEST }),
        onPending: suggestTagsPending,
        onSuccess: suggestTagsSuccess,
        onFailure: suggestTagsRejected,
      }),
    );
  };

export const autocompleteTags = (text: string) => async (dispatch: AppDispatch, getState: RootState) => {
    const state = getState();
    const currentPropertySlug = propertySelector.currentPropertySlug(state, Product.CMS) || '';
    await dispatch(
      networkAction({
        url: TagsServiceApiProvider.autocompleteTag(currentPropertySlug),
        method: HttpMethod.Post,
        body: JSON.stringify({ text, limit: LIMIT_AUTO_COMPLETE }),
        onSuccess: autocompleteTagsSuccess,
        onFailure: autocompleteTagsRejected,
      }),
    );
  };

export const getMetaDescriptionSuggestions = () => async (dispatch: AppDispatch, getState: RootState) => {
    const state = getState();
    const currentPropertySlug = propertySelector.currentPropertySlug(state, Product.CMS) || '';
    const articleSummary = blockListSelector.blockListBodyText(state);
    const postId = state.cms.editor.draft.currentDraftId;
    const body = { content: articleSummary, type: META_ASSISTANT_CONTENT_TYPE };
    await dispatch(
      networkAction({
        url: SmartServiceConfigsProvider.getSmartGenerationEndpoint(
          currentPropertySlug,
          postId,
        ),
        method: HttpMethod.Post,
        body: JSON.stringify(body),
        onPending: getMetaDescriptionSuggestionsPending,
        onSuccess: getMetaDescriptionSuggestionsSuccess,
        onFailure: getMetaDescriptionSuggestionsRejected,
      }),
    );
  };

export const {
  setTagsInputValue,
  updateArticleTagsValue,
  updateSeoDescriptionValue,
  setSeoTitleValue,
  updateSeoTitleValue,
  setDropdownLoading,
  purgeAutocompletedTags,
  updateArticleTags,
  purgeAutocompletedTagsError,
  resetEditorState,
  reportTakeToTagsClick,
  reportEditorialMenuItemClick,
  reportTakeToSeoTitleClick,
  reportTakeToSeoDescriptionClick,
  reportTakeToHyperlinksClick,
  reportLightBulbTooltipClick,
  reportSeoHelpCenterButtonClick,
  reportSuggestedSeoDescriptionSparkClicked,
  reportSuggestedSeoDescriptionClicked,
  reportSuggestedSeoDescriptionRecommendationClicked,
  reportSuggestedSeoDescriptionRegenerationClicked,
  reportSuggestedSeoDescriptionFeedback,
  suggestTagsPending,
  suggestTagsSuccess,
  suggestTagsRejected,
  autocompleteTagsSuccess,
  autocompleteTagsRejected,
  getMetaDescriptionSuggestionsSuccess,
  getMetaDescriptionSuggestionsRejected,
  getMetaDescriptionSuggestionsPending,
  resetMetaDescriptionSuggestions,
  setAutoFillSeoDescription,
} = tagsAndSeo.actions;

export default tagsAndSeo.reducer;
