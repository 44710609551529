import { RootState } from 'core/store';
import { getEditorState } from '../editor.selector';
import { PostTitleSliceState } from './postTitle.types';

const getPostTitleState = (state: RootState): PostTitleSliceState => getEditorState(state).title;

export const postTitleSelector = {
  postTitle: (state: RootState) => getPostTitleState(state).postTitle,
  isTitleValid: (state: RootState) => getPostTitleState(state).isTitleValid,
  isOpenHeadlineModal: (state: RootState) => getPostTitleState(state).isOpenHeadlineModal,
  isHeadlineLoading: (state: RootState) => getPostTitleState(state).isHeadlineLoading,
  headlineSuggestions: (state: RootState) => getPostTitleState(state).headlineSuggestions,
  error: (state: RootState) => getPostTitleState(state).error,
  isOpenHeadlineReminder: (state: RootState) => getPostTitleState(state).isOpenHeadlineReminder,
};
