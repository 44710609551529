import {
  removeBlockAdders,
  setCurrentlyActiveBlockKey,
  setBlockKeyForInsertion,
  insertBlockDivider,
  insertListItemBlock,
  insertQuoteBlock,
  insertPageBreak,
  insertTableOfContentsBlock,
  insertFAQBlocks,
} from './blockList.actions';

import { toggleCreateTableModal } from '../editorModals/editorModals.actions';

import {
  closeElementPanel,
  openElementPanelAtPanelType,
  clearEditedBlockData,
} from '../elementPanel/elementPanel.actions';

import { ELEMENT_PANEL_CONTEXT } from '../../components/elementPanel/elementPanel.constants';
import { BLOCK_ADDER_OPTIONS_NAMES } from '../../components/blockList/blocklist.constants';
import { blockListSelector } from './blockList.selector';
import { ELEMENT_PANEL_PLUGIN_TYPES } from '../../plugins/pluginConfiguration';
import { TABLE_BLOCK_EVENTS } from '../../components/blockList/systemBlocks/TableBlock/tableBlock.strings';

export const BLOCK_ADDER_FOCUSED = '[EDITOR] on block adder focused';
export const BLOCK_ADDER_BLURRED = '[EDITOR] on block adder blured';
export const USER_ADDED_BLOCK_THROUGH_BLOCK_ADDER = '[EDITOR] creative added';
export const REPLACE_BLOCK_ADDER_WITH_TEXT_BLOCK = '[EDITOR] replace block adder with text block';
export const REPLACE_BLOCK_ADDER_WITH_MULTIPLE_TEXT_BLOCKS = '[EDITOR] user started typing or copied text in a block adder';

export const replaceBlockAdderWithTextBlock = (blockAdderKeyToReplace, textValue) => dispatch => {
  dispatch({
    type: REPLACE_BLOCK_ADDER_WITH_TEXT_BLOCK,
    payload: {
      blockAdderKeyToReplace,
      textValue,
    },
  });
};

export const replaceBlockAdderWithMultipleTextBlocks = (blockAdderKeyToReplace, multipleTextValues) => dispatch => {
  dispatch({
    type: REPLACE_BLOCK_ADDER_WITH_MULTIPLE_TEXT_BLOCKS,
    payload: {
      multipleTextValues,
      blockAdderKeyToReplace,
    },
  });
};

const setBlockForAddition = blockKey => dispatch => {
  setCurrentlyActiveBlockKey(blockKey)(dispatch);
  return setBlockKeyForInsertion(blockKey)(dispatch);
};

export const onAdderPlusButtonClicked = blockKey => dispatch => {
  setBlockForAddition(blockKey)(dispatch);
};

export const onAdderCancelButtonClicked = () => dispatch => {
  setBlockForAddition(null)(dispatch);
  removeBlockAdders()(dispatch);
  closeElementPanel()(dispatch);
};

export const onAdderOptionClick = (blockKey, optionType) => (dispatch, getState) => {
  dispatch({
    type: USER_ADDED_BLOCK_THROUGH_BLOCK_ADDER,
    payload: {
      optionType,
    },
  });
  switch (optionType) {
    case BLOCK_ADDER_OPTIONS_NAMES.MEDIA: {
      const currentlyActiveBlockKey = blockListSelector.currentlyActiveBlockKey(getState());
      if (currentlyActiveBlockKey !== blockKey) {
        setBlockForAddition(blockKey)(dispatch);
      }
      clearEditedBlockData()(dispatch);
      return openElementPanelAtPanelType({
        context: ELEMENT_PANEL_CONTEXT.EDITING_BLOCK,
        panelToOpen: ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL,
        editedBlockData: null,
      })(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.ONE_LIST_BLOCK: {
      return insertListItemBlock(blockKey, 1)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.THREE_LIST_BLOCKS: {
      return insertListItemBlock(blockKey, 3)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.FIVE_LIST_BLOCKS: {
      return insertListItemBlock(blockKey, 5)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.TEN_LIST_BLOCKS: {
      return insertListItemBlock(blockKey, 10)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.QUOTE: {
      return insertQuoteBlock(blockKey)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.DIVIDER: {
      return insertBlockDivider(blockKey)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.PAGE_BREAK: {
      return insertPageBreak(blockKey)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.TABLE: {
      dispatch({
        type: TABLE_BLOCK_EVENTS.TABLE_SELECTED,
      });
      return toggleCreateTableModal(true)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.TABLE_OF_CONTENTS: {
      return insertTableOfContentsBlock(blockKey)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.ONE_FAQ_BLOCK: {
      return insertFAQBlocks(blockKey, 1)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.THREE_FAQ_BLOCKS: {
      return insertFAQBlocks(blockKey, 3)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.FIVE_FAQ_BLOCKS: {
      return insertFAQBlocks(blockKey, 5)(dispatch);
    }
    case BLOCK_ADDER_OPTIONS_NAMES.TEN_FAQ_BLOCKS: {
      return insertFAQBlocks(blockKey, 10)(dispatch);
    }
    default: {
      return null;
    }
  }
};

export const onBlockAdderInputFocus = blockKey => dispatch => {
  dispatch({
    type: BLOCK_ADDER_FOCUSED,
    payload: blockKey,
  });
};

export const onBlockAdderInputBlur = blockKey => dispatch => {
  dispatch({
    type: BLOCK_ADDER_BLURRED,
    payload: blockKey,
  });
};
