import { SITE_VARIABLES } from 'core/styles/variables';
import { flexColumn, flexColumnCenter, flexRow, flexRowCenter } from 'core/styles/flex';

export const getStylesObject = () => {
  return {
    loadMoreButton: {
      paddingTop: SITE_VARIABLES.PADDING.BIG,
      ...flexRowCenter,
    },
    editImageContainer: {
      alignContent: 'center',
      ...flexColumnCenter,
    },
    header: {
      wrapper: {
        ...flexColumn,
        alignContent: 'start',
      },
      filterWrapper: {
        ...flexRow,
        margin: `${SITE_VARIABLES.MARGIN.MEDIUM} 0 ${SITE_VARIABLES.MARGIN.BIG} 0`,
      },
    },
  };
};
