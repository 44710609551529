export const TITLE_MIN_LENGTH = 10;
export const TITLE_MAX_LENGTH = 115;
export const TITLE_SUGGESTION_MIN_LENGTH = 50;

const OPTIMAL_LENGTH = `${TITLE_MIN_LENGTH} - ${TITLE_MAX_LENGTH} characters`;

export const TITLE_TOO_SHORT_ERROR_MESSAGE = `Just a little more, please (${OPTIMAL_LENGTH})`;
export const TITLE_TOO_LONG_ERROR_MESSAGE = `That one's a little too long. Try ${OPTIMAL_LENGTH}.`;

export const TITLE_PLACEHOLDER = 'Give it a title';
