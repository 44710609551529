import { Loader, NavigationBar } from '@ftbpro/mm-admin-ui-components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'core/store/user/user.selector';
import { NavigationFilters } from 'core/components/NavigationFilters/NavigationFilters';
import { AppcuesLaunchpad } from 'core/components/AppcuesLaunchpad/AppcuesLaunchpad';
import { shouldLoadAppcues } from 'core/externalScripts/appcues/index';
import { disableTextVariables } from 'app.styles';
import { organizationSelector } from 'core/store/organization/organization.selector';
import { SYSTEM_LOGO } from 'app.contants';
import { navigateToPage } from './core/routing/history';
import { ConfigsProvider } from './core/configs/ConfigsProvider';
import { LazyRoutes } from './core/components/routes/LazyRoutes';
import { getDefaultLocation } from './location.utils';

export const AppContent = ({
  userData,
  logout,
  navigationItems,
  routes,
  isAppDataInitializing = false,
  styles,
}) => {
  const location = useLocation();
  const isUserDataLoaded = useSelector(userSelector.isUserDataLoaded);
  const isLogoDataLoading = useSelector(organizationSelector.loadingLogo);
  const isExternal = useSelector(organizationSelector.isExternal);
  const showSTNLogo = useSelector(organizationSelector.logo) === SYSTEM_LOGO.stn;
  const showText = isExternal && showSTNLogo;
  const logoSrc = isExternal && showSTNLogo ? ConfigsProvider.STN_LOGO : ConfigsProvider.MENU_LOGO_SRC;
  const forceHideHelpButtons = location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === 'networkvideos';
  const appCues = (shouldLoadAppcues() ? AppcuesLaunchpad : undefined);
  const launchPad = !forceHideHelpButtons ? appCues : undefined;
  const helpCenterLink = !forceHideHelpButtons ? ConfigsProvider.HELP_CENTER_URL : undefined;

  return (
    isUserDataLoaded && !isLogoDataLoading
    ? (
      <div css={styles.content}>
        <NavigationBar
          navigationItems={navigationItems}
          user={userData}
          onLogout={logout}
          helpCenterLink={helpCenterLink}
          locationPath={location.pathname}
          navigationHandler={navigateToPage}
          logoSrc={logoSrc}
          onLogoClick={() => navigateToPage('/')}
          launchpad={launchPad}
          showText={showText}
          variables={!showText ? disableTextVariables : {}}
        >
          <NavigationFilters />
        </NavigationBar>
        {isAppDataInitializing ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} /> : <LazyRoutes routes={routes} defaultPage={getDefaultLocation(navigationItems)} />}
      </div>
    ) : <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
  );
};
