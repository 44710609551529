import { WritingSuggestionSliceState } from './writingSuggestion/writingSuggestion.types';
import { PostTitleSliceState } from './postTitle/postTitle.types';

export const EDITOR_ACTION_TYPE_PREFIX = '[EDITOR] ';
export const TITLE_ACTION = '[EDITOR] Update post title';
export const HEADLINE_EVENT_TYPES = {
  HEADLINE_FEEDBACK_CLICKED: '[EDITOR] Headline feedback clicked',
  HEADLINE_CLICKED: '[EDITOR] Headlines Suggestions clicked',
  HEADLINE_CLOSED: '[EDITOR] Headlines Suggestions closed',
  HEADLINE_REGENERATED: '[EDITOR] Headlines Suggestions regenerated',
  HEADLINE_SELECTED: '[EDITOR] Headlines Suggestions selected',
  HEADLINE_SPARK_CLICKED: '[EDITOR] Headline Voltax Spark clicked',
  HEADLINE_REMINDER: '[EDITOR] Headlines Suggestions reminder on page',
  HEADLINE_REMINDER_CLICKED: '[EDITOR] Headlines Suggestions reminder clicked',
};

export const WRITING_SUGGESTIONS_INITIAL_STATE: WritingSuggestionSliceState = {
  dropdownToggle: false,
  loading: false,
  suggestions: [],
  suggestionVertical: '',
  suggestionUpdated: '',
  error: '',
};

export const POST_TITLE_INITIAL_STATE: PostTitleSliceState = {
  postTitle: '',
  isTitleValid: true,
  isOpenHeadlineModal: false,
  isHeadlineLoading: false,
  headlineSuggestions: [],
  error: '',
  isOpenHeadlineReminder: false,
};
