export const NEW_USER_TITLE = 'New';
export const NEW_USER = 'new';
export const PROPERTY = 'property';
export const ALL_PROPERTIES_IN_ORGANIZATION = '*';
export const ALL_ORG_PROPERTIES = 'Allow organization-wide access';
export const TOOLTIP_CONTENT = 'If checked will automatically add all new properties created in the organization';
export const USER_IS_BLOCKED = 'The user is blocked';
export const ROLES_AND_PROPERTIES = 'Roles and Properties';

export const TENANT = 'tenant';
export const ORGANIZATION = 'organization';
