import * as FullStory from '@fullstory/browser';
import { ConfigsProvider } from '../../configs/ConfigsProvider';

export function shouldReportToFullstory() {
  return ConfigsProvider.FeatureFlags.ReportFullStory;
}

export function initFullStory() {
  if (shouldReportToFullstory()) {
    FullStory.init({
    orgId: ConfigsProvider.FULL_STORY_ORG_ID,
    });
  }
}

export function identifyUserInFullStory(username) {
  if (shouldReportToFullstory()) {
    FullStory.identify(`${ConfigsProvider.TENANT}-${username}`, { displayName: username });
  }
}
