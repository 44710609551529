import { HTTP_METHODS, mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { RecommendationServiceConfigProvider } from './recommendationServiceConfigProvider';

const LIMIT_RECOMMENDATION_NUMBER = 20;
const TIME_RANGE_NUMBER = 180;

export class RecommendationServiceApiProvider {
  static getPostsRecommendation(property, text) {
    const nowDate = new Date().toISOString();
    const suggestReq = {
      text,
      limit: LIMIT_RECOMMENDATION_NUMBER,
      date: nowDate,
      timeRange: TIME_RANGE_NUMBER,
    };
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(suggestReq),
    };
    return mmNetworkService.fetch(RecommendationServiceConfigProvider.getRecommendationPostsEndpoint(property), configs);
  }

  static getPagesRecommendation(property, text) {
    const suggestReq = {
      text,
      limit: LIMIT_RECOMMENDATION_NUMBER,
    };
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(suggestReq),
    };
    return mmNetworkService.fetch(RecommendationServiceConfigProvider.getRecommendationPagesEndpoint(property), configs);
  }

  static getSearchPostsRecommendation(property, text) {
    const suggestReq = {
      text,
      limit: LIMIT_RECOMMENDATION_NUMBER,
    };
    const configs = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify(suggestReq),
    };
    return mmNetworkService.fetch(RecommendationServiceConfigProvider.getRecommendationSearchPostsEndpoint(property), configs);
  }
}
