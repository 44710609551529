import { BackgroundTaskStatus } from '@ftbpro/mm-admin-ui-components';
import { BackgroundTaskData } from 'core/backgroundTasks/store/backgroundTasks.constants';
import { formateTemplateString } from '@ftbpro/mm-admin-core-utils';
import { TaskConfig } from '../WebWorkerWrapper';
import { ResourcesStatusCount } from './QueryWorker';

const isUnavailable = (status: BackgroundTaskStatus): status is BackgroundTaskStatus.Unavailable => {
  return status === BackgroundTaskStatus.Unavailable;
};

export const formatProgress = (formatterArgs: ResourcesStatusCount & { total: number }) => {
  const { succeeded = 0, failed = 0, canceled = 0, total } = formatterArgs;
  return { progress: succeeded + failed + canceled, succeeded, total };
};

export function formatBackgroundTaskData(formatterArgs: ResourcesStatusCount & { total: number }, status: BackgroundTaskStatus, taskConfig?: TaskConfig): BackgroundTaskData {
  const { progress, total } = formatProgress(formatterArgs);
  const formattedTitle = { title: formateTemplateString(formatterArgs, taskConfig?.inProgressTitleFormatter) };
  const isInProgress = status === BackgroundTaskStatus.InProgress;

  return {
    status,
    progressValue: progress,
    progressBarTotal: total,
    ...(isUnavailable(status) ? {} : { content: formateTemplateString(formatterArgs, taskConfig?.contentFormatByState?.[status]) }),
    ...(isInProgress ? formattedTitle : {}),
  };
}
