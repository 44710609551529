import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { FilterType, NavigationFiltersState } from './navigationFilters.constants';

const SLICE_NAME = 'NavigationFilters';

const navigationFiltersSlice = createSlice({
  name: SLICE_NAME,
  initialState: {} as NavigationFiltersState,
  reducers: {
    setNavigationFiltersData(state, action: PayloadAction<Partial<NavigationFiltersState>>) {
      const { customFilterData, ...rest } = action.payload;
      return {
        ...state,
        ...rest,
        customFilterData: {
          ...state.customFilterData,
          ...customFilterData,
        },
      };
    },
    setCustomFilterSelectedValue(state, action: PayloadAction<string>) {
      state.customFilterData.selectedValue = action.payload;
    },
  },
  extraReducers: {
    [LOCATION_CHANGE]: state => {
      state.filterType = FilterType.NONE;
    },
  },
});

export const {
  setNavigationFiltersData,
  setCustomFilterSelectedValue,
} = navigationFiltersSlice.actions;

export default navigationFiltersSlice.reducer;
