import { PREVIEW_PREFIX, PLUGIN_BUTTON_EDIT_TEXT, PLUGIN_BUTTON_ADD_TEXT } from '../plugins/shared/plugins.constants';

export function changeStreamableDimensions(html) {
  return html.replace(/(height[^\s]+)/, 'height="340"').replace(/(width[^\s]+)/, 'width="460"');
}

export const splitArrayIntoChunks = (array, chunkSize) => {
  return new Array(Math.ceil(array.length / chunkSize)).fill().map((_, i) => array.slice(i * chunkSize, (i * chunkSize) + chunkSize));
};

export const getIdWithoutPreviewPrefix = id => id.split(PREVIEW_PREFIX)[1];

export const insertPreviewRow = ({
  items,
  id,
  uniqueField = 'id',
  previewPrefix = PREVIEW_PREFIX,
}) => {
  let indexInGrid = null;
  let previewItem = null;
  items.forEach((array, index) => {
    const searchItem = array.find(item => item[uniqueField] === id);
    if (searchItem) {
      indexInGrid = index;
      previewItem = searchItem;
    }
  });
  return previewItem
    ? [...items.slice(0, indexInGrid + 1), [{ ...previewItem, [uniqueField]: `${previewPrefix}${previewItem[uniqueField]}` }], ...items.slice(indexInGrid + 1)]
    : items;
};

export const urlInputPlaceholderGenerator = name => `Paste ${name} URL`;

export const pluginAddButtonTextHandler = editorData => (editorData ? PLUGIN_BUTTON_EDIT_TEXT : PLUGIN_BUTTON_ADD_TEXT);

export const formatImagnImagesResult = images => {
   const imagesObject = images.flat();
   return imagesObject.map(image => ({
     ...image,
    title: image.headline,
    id: (image.imgId).toString(),
   }));
 };
