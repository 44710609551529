import { useSelector } from 'react-redux';

import { UserData } from 'core/store/user/types';
import { userSelector } from '../store/user/user.selector';

export const useUserData = (): UserData => {
  const rawTokenData = useSelector(userSelector.getUserToken);
  const userDetails = useSelector(userSelector.getUser);
  const userRoles = useSelector(userSelector.getUserRoles);
  const orgId = useSelector(userSelector.getUserOrgId);
  return {
    token: rawTokenData,
    userDetails,
    userRoles,
    orgId,
  };
};
