import { reportEvent } from 'core/analytics';
import { propertySelector } from 'core/store/property/property.selector';
import { formatDate } from 'core/utils/date.utils';
import { Product } from 'core/constants';
import {
  SUBMIT_DRAFT_REQUEST_SUCCESS,
  START_DRAFT_CREATION,
  UPDATE_PUBLISHED_DRAFT_REQUEST_SUCCESS,
  OPEN_PREVIEW_PAGE_FROM_DRAFT,
} from '../draft/draft.actions';
import { draftSelector } from '../draft/draft.selector';
import {
  DELETE_COVER_IMAGE,
  SET_COVER_IMAGE,
} from '../coverImage/coverImage.actions';
import {
  INSERT_LIST_ITEM_BLOCKS,
  INSERT_QUOTE_ITEM_BLOCK,
  REMOVE_BLOCK,
  REPLACE_TEXT_BLOCK_WITH_BLOCK_ADDER,
  INSERT_BLOCK_DIVIDER,
} from '../blocks/blockList.actions';
import { blockListSelector } from '../blocks/blockList.selector';
import { TOGGLE_SOCIAL_SHARING_MODAL } from '../editorModals/editorModals.actions';
import {
  TOGGLE_DRAFTS_POSTS_BY_USER,
  DELETE_DRAFT_SUCCESS,
  PREVIEW_PAGE_OPEN_FROM_EDITOR_DASHBOARD,
} from '../editorDashboard/editorDashboard.actions';
import {
  EDITOR, EDITORIAL_MENU_KEY_TO_EVENT_TYPE,
  EVENT_TYPES,
  WRITING_SUGGESTIONS_EVENT_TYPES,
} from './editorAnalyticsMiddleware.constants';
import {
  createMediaAddedLabel,
  didUserChangeImage,
  shouldSkipActionInEditorMiddleware,
} from './editorAnalyticsMiddleware.utils';
import { coverImageSelector } from '../coverImage/coverImage.selector';
import { getAnalyticsFlowMonitor } from './editorAnalyticsFlowMonitor';
import { getBlockIndexByKey } from '../../utils/blockList.utils';
import { REPLACE_BLOCK_ADDER_WITH_TEXT_BLOCK, USER_ADDED_BLOCK_THROUGH_BLOCK_ADDER } from '../blocks/blockAdder.actions';
import { NOTIFY_NEW_PAGE_WAS_OPENED } from '../../../store/cms.actions';
import { COPY_HYPERLINK_URL_TO_CLIPBOARD, TOGGLE_RECOMMENDATION_WINDOW } from '../recommendation/recommendation.actions';
import { REPORT_MEDIA_ADDED, REPORT_EMBED_CREATED, OPEN_GETTY_PANEL, GETTY_IMAGE_SELECTED, SEARCH_GETTY_IMAGES } from '../elementPanel/elementPanel.actions';
import { ELEMENT_PANEL_PLUGIN_NAMES } from '../../plugins/pluginConfiguration';
import { createCustomDimensions } from '../../../utils/analytics.utils';
import { UPDATE_COMMENTS_FOR_ENTITY_SUCCEEDED } from '../comments/comments.actions';
import { reportUseArticleDataForSocialSharing } from '../editorialMenu/socialSharing/socialSharingSlice';
import {
  reportEditorialMenuItemClick,
  reportLightBulbTooltipClick,
  reportSeoHelpCenterButtonClick,
  reportSuggestedSeoDescriptionRegenerationClicked,
  reportSuggestedSeoDescriptionClicked,
  reportSuggestedSeoDescriptionFeedback,
  reportSuggestedSeoDescriptionRecommendationClicked,
  reportSuggestedSeoDescriptionSparkClicked,
  reportTakeToHyperlinksClick,
  reportTakeToSeoDescriptionClick,
  reportTakeToSeoTitleClick,
  reportTakeToTagsClick,
} from '../editorialMenu/tagsAndSeo/tagsAndSeoSlice';
import { setPostTitle } from '../postTitle/postTitleSlice';
import { HEADLINE_EVENT_TYPES } from '../editor.constants';
import { TABLE_BLOCK_EVENTS } from '../../components/blockList/systemBlocks/TableBlock/tableBlock.strings';
import { HYPERLINK_MODAL_CLOSED, HYPERLINK_MODAL_OPENED } from '../blocks/inlineTextBlock.actions';

export const editorAnalyticsMiddleware = store => next => action => {
  const state = store.getState();

  // validate handle only editor actions
  if (shouldSkipActionInEditorMiddleware(action, EDITOR)) {
    return next(action);
  }

  switch (action.type) {
    case START_DRAFT_CREATION: {
      getAnalyticsFlowMonitor().setContentToInitialValue();
      reportEvent(
        `draft id=${action.payload}`,
        EVENT_TYPES.NEW_ARTICLE_CLICK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case SET_COVER_IMAGE: {
      const draftId = draftSelector.draftId(state);
      if (didUserChangeImage(coverImageSelector.image(state), action.payload.image)) {
        reportEvent(
          `article id=${draftId};old caption: ${coverImageSelector.coverImageCaption(state) || ''};new caption: ${action.payload.image.caption || ''}; new image provider:
           ${action.payload.image.provider}`,
          EVENT_TYPES.EDIT_PICTURE,
          EDITOR,
        );
      }
      return next(action);
    }
    case WRITING_SUGGESTIONS_EVENT_TYPES.EDITOR_SUGGESTIONS_CLICKED: {
      if (state.cms.editor.writingSuggestion.dropdownToggle) {
        reportEvent(
          '',
          EVENT_TYPES.WRITING_SUGGESTION_OPENED,
          EDITOR,
        );
      } else {
        reportEvent(
          '',
          EVENT_TYPES.WRITING_SUGGESTION_CLOSED,
          EDITOR,
        );
      }
      return next(action);
    }
    case WRITING_SUGGESTIONS_EVENT_TYPES.EDITOR_SUGGESTIONS_ITEM: {
      reportEvent(
        `postId=${action.payload.postId} listIndex=${action.payload.index}; listItem=${action.payload.postTitle};`,
        EVENT_TYPES.WRITING_SUGGESTION_SELECTED,
        EDITOR,
      );
      return next(action);
    }
    case REPORT_MEDIA_ADDED: {
      const { pluginData, additionalData, editingCover, blockType, currentActivePanelType } = action.payload;
      reportEvent(
        createMediaAddedLabel(pluginData, additionalData, editingCover, blockType),
        `${ELEMENT_PANEL_PLUGIN_NAMES[currentActivePanelType]} added`,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case REPORT_EMBED_CREATED: {
      const { embedType } = action.payload;
      reportEvent(
        `embedType=${embedType}`,
        EVENT_TYPES.CUSTOM_EMBED_CREATED,
        EDITOR,
      );
      return next(action);
    }
    case TOGGLE_SOCIAL_SHARING_MODAL: {
      if (action.payload.shouldPresentSocialSharingModal) {
        reportEvent(
          '',
          EVENT_TYPES.SOCIAL_SHARING_CLICKED,
          EDITOR,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case SUBMIT_DRAFT_REQUEST_SUCCESS: {
      const date = formatDate(new Date(), 'dd/MM/yy');
      const editorialMenuState = state.cms.editor.editorialMenu;
      const hasLinkableImage = blockListSelector.isLinkableImageInBlockList(state);
      const { seoTitle, seoDescription } = editorialMenuState.tagsAndSeo;
      const { title, description, image } = editorialMenuState.socialSharing;
      const { analysis } = draftSelector.draftPayload(state);
      const numLinks = analysis.listOfArticleLinks.length;
      reportEvent(
        `
          date= ${date}; 
          links=${numLinks};
          seo title=${seoTitle ? 'yes' : 'no'};
          seo desc=${seoDescription ? 'yes' : 'no'};
          ss title=${title ? 'yes' : 'no'}; 
          ss image=${image.url ? 'yes' : 'no'};
          ss desc=${description ? 'yes' : 'no'};
          image link=${hasLinkableImage ? 'yes' : 'no'};
        `,
        EVENT_TYPES.SUBMIT_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case UPDATE_PUBLISHED_DRAFT_REQUEST_SUCCESS: {
      const date = formatDate(new Date(), 'dd/MM/yy');
      reportEvent(
        `date= ${date}`,
        EVENT_TYPES.UPDATE_PUBLISHED_DRAFT_REQUEST_SUCCESS,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case INSERT_QUOTE_ITEM_BLOCK: {
      reportEvent(
        '',
        EVENT_TYPES.QUOTE_ADDED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case INSERT_LIST_ITEM_BLOCKS: {
      reportEvent(
        `lists=${action.payload.numberOfNewBlocksToGenerate}`,
        EVENT_TYPES.LIST_ADDED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case INSERT_BLOCK_DIVIDER: {
      reportEvent(
        '',
        EVENT_TYPES.DIVIDER_ADDED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TOGGLE_RECOMMENDATION_WINDOW: {
      if (action.payload) {
        reportEvent(
          '',
          EVENT_TYPES.HYPERLINKS_SUGGESTIONS_ACCESSED,
          EDITOR,
          createCustomDimensions(state),
        );
      }
        return next(action);
    }
    case PREVIEW_PAGE_OPEN_FROM_EDITOR_DASHBOARD: {
      reportEvent(
        '',
        EVENT_TYPES.PREVIEW_PAGE_OPEN_FROM_EDITOR_DASHBOARD,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case OPEN_PREVIEW_PAGE_FROM_DRAFT: {
      reportEvent(
        '',
        EVENT_TYPES.OPEN_PREVIEW_PAGE_FROM_DRAFT,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case COPY_HYPERLINK_URL_TO_CLIPBOARD: {
      reportEvent(
        `target URL=${action.payload}`,
        EVENT_TYPES.COPY_HYPERLINK_URL_TO_CLIPBOARD,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportUseArticleDataForSocialSharing.type: {
      reportEvent(
        '',
        EVENT_TYPES.REPORT_USE_ARTICLE_DATA_FOR_SOCIAL_SHARING,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case DELETE_DRAFT_SUCCESS: {
      const { payload } = action;
      reportEvent(
        `article id=${payload}`,
        EVENT_TYPES.USER_DELETES_ARTICLE,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case USER_ADDED_BLOCK_THROUGH_BLOCK_ADDER: {
      const { optionType } = action.payload;
      if (optionType !== 'media') {
        const property = propertySelector.currentPropertySlug(state, Product.CMS);
        const draftId = draftSelector.draftId(state);
        reportEvent(
          `property=${property};article id=${draftId};type=${optionType}`,
          EVENT_TYPES.BlOCK_ADDED,
          EDITOR,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case setPostTitle.type: {
      if (getAnalyticsFlowMonitor().shouldReportTitleEditing(action.payload)) {
        const property = propertySelector.currentPropertySlug(state, Product.CMS);
        const draftId = draftSelector.draftId(state);
        reportEvent(
          `property=${property};article id=${draftId};title;order number
          = ${getAnalyticsFlowMonitor().getAndIncrementContentFlowOrderNumber()}`,
          EVENT_TYPES.CONTENT_ADDED,
          EDITOR,
          createCustomDimensions(state),
        );
      }
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_FEEDBACK_CLICKED: {
      const { like, headlines } = action.payload;
      reportEvent(
        `feedback=${like ? 'positive' : 'negative'} id=${JSON.stringify(headlines)}`,
        EVENT_TYPES.HEADLINE_FEEDBACK_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_CLICKED: {
      reportEvent(
        '',
        EVENT_TYPES.HEADLINE_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_CLOSED: {
      reportEvent(
        '',
        EVENT_TYPES.HEADLINE_CLOSED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_REGENERATED: {
      reportEvent(
        '',
        EVENT_TYPES.HEADLINE_REGENERATED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_SELECTED: {
      const { headline } = action.payload;
      reportEvent(
        `selected_headline=${JSON.stringify(headline)}`,
        EVENT_TYPES.HEADLINE_SELECTED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_SPARK_CLICKED: {
      reportEvent(
        '',
        EVENT_TYPES.HEADLINE_SPARK_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportSuggestedSeoDescriptionSparkClicked.type: {
      reportEvent(
        '',
        EVENT_TYPES.SUGGESTED_SEO_DESCRIPTION_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_REMINDER: {
      reportEvent(
        '',
        EVENT_TYPES.HEADLINE_REMINDER_OPENED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HEADLINE_EVENT_TYPES.HEADLINE_REMINDER_CLICKED: {
      reportEvent(
        '',
        EVENT_TYPES.HEADLINE_REMINDER_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case REPLACE_BLOCK_ADDER_WITH_TEXT_BLOCK: {
      const { blockAdderKeyToReplace } = action.payload;
      const property = propertySelector.currentPropertySlug(state, Product.CMS);
      const draftId = draftSelector.draftId(state);
      const paragraphNumber = getBlockIndexByKey(blockListSelector.blockList(state), blockAdderKeyToReplace);
      reportEvent(
        `property=${property};article id=${draftId};paragraph number=
         ${paragraphNumber};order number = ${getAnalyticsFlowMonitor().getAndIncrementContentFlowOrderNumber()}`,
        EVENT_TYPES.CONTENT_ADDED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case DELETE_COVER_IMAGE: {
      const draftId = draftSelector.draftId(state);
      reportEvent(
        `article id=${draftId};picture;${getAnalyticsFlowMonitor().getAndIncrementContentFlowOrderNumber()}`,
        EVENT_TYPES.DELETE_CONTENT,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case REPLACE_TEXT_BLOCK_WITH_BLOCK_ADDER:
    case REMOVE_BLOCK: {
      const draftId = draftSelector.draftId(state);
      const { blockKeyToReplace } = action.payload;
      const paragraphNumber = getBlockIndexByKey(blockListSelector.blockList(state), blockKeyToReplace);
      reportEvent(
        `article id=${draftId};paragraph number=${paragraphNumber};order number=
        ${getAnalyticsFlowMonitor().getAndIncrementContentFlowOrderNumber()}`,
        EVENT_TYPES.DELETE_CONTENT,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TOGGLE_DRAFTS_POSTS_BY_USER: {
      const { payload } = action;
      reportEvent(
        payload.view,
        EVENT_TYPES.NAVIGATION_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case NOTIFY_NEW_PAGE_WAS_OPENED: {
      const property = propertySelector.currentPropertySlug(state, Product.CMS);
      reportEvent(
        `property=${property}`,
        EVENT_TYPES[action.payload],
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case UPDATE_COMMENTS_FOR_ENTITY_SUCCEEDED: {
      reportEvent(
        '',
        EVENT_TYPES.ARTICLE_COMMENTS_ADDED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportTakeToSeoTitleClick.type: {
      reportEvent(
        '',
        EVENT_TYPES.REPORT_TAKE_TO_SEO_TITLE_CLICK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportTakeToSeoDescriptionClick.type: {
      reportEvent(
        '',
        EVENT_TYPES.REPORT_TAKE_TO_SEO_DESCRIPTION_CLICK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportTakeToHyperlinksClick.type: {
      reportEvent(
        '',
        EVENT_TYPES.REPORT_TAKE_TO_HYPERLINKS_CLICK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportEditorialMenuItemClick.type: {
      reportEvent(
        '',
        EDITORIAL_MENU_KEY_TO_EVENT_TYPE[action.payload],
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportTakeToTagsClick.type: {
      reportEvent(
        '',
        EVENT_TYPES.REPORT_TAKE_TO_TAGS_CLICK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportLightBulbTooltipClick.type: {
      reportEvent(
        action.payload,
        EVENT_TYPES.REPORT_LIGHT_BULB_TOOLTIP_CLICK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportSeoHelpCenterButtonClick.type: {
      reportEvent(
        '',
        EVENT_TYPES.REPORT_SEO_HELPER_CENTER_BUTTON_CLICK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportSuggestedSeoDescriptionClicked.type: {
      reportEvent(
        '',
        EVENT_TYPES.SUGGESTED_SEO_DESCRIPTION_OPENED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportSuggestedSeoDescriptionRegenerationClicked.type: {
      reportEvent(
        '',
        EVENT_TYPES.SUGGESTED_SEO_DESCRIPTION_REGENERATED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportSuggestedSeoDescriptionFeedback.type: {
      const { feedback } = action.payload;
      reportEvent(
        `positive_feedback=${feedback}`,
        EVENT_TYPES.SUGGESTED_SEO_DESCRIPTION_FEEDBACK,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case reportSuggestedSeoDescriptionRecommendationClicked.type: {
      const { category, recommendation } = action.payload;
      reportEvent(
        `category=${category};recommendation=${recommendation}`,
        EVENT_TYPES.SUGGESTED_SEO_DESCRIPTION_RECOMMENDATION_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TABLE_BLOCK_EVENTS.TABLE_SELECTED: {
      reportEvent(
        '',
        EVENT_TYPES.TABLE_BLOCK_SELECTED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TABLE_BLOCK_EVENTS.TABLE_CREATED: {
      const { row, column } = action.payload;
      reportEvent(
        `dimensions=${row},${column}`,
        EVENT_TYPES.TABLE_BLOCK_CREATED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TABLE_BLOCK_EVENTS.TABLE_COPIED: {
      reportEvent(
        '',
        EVENT_TYPES.TABLE_BLOCK_COPIED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TABLE_BLOCK_EVENTS.TABLE_DELETED: {
      reportEvent(
        '',
        EVENT_TYPES.TABLE_BLOCK_DELETED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case TABLE_BLOCK_EVENTS.TABLE_EDITED: {
      const { label, event } = action.payload;

      reportEvent(
        `${label}`,
        `${event}`,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case OPEN_GETTY_PANEL: {
      reportEvent(
        '',
        EVENT_TYPES.OPEN_GETTY_PANEL,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case SEARCH_GETTY_IMAGES: {
      const { type } = action.payload;
      reportEvent(
        `${type}`,
        EVENT_TYPES.GETTY_IMAGE_SEARCH,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case GETTY_IMAGE_SELECTED: {
      reportEvent(
        '',
        EVENT_TYPES.GETTY_IMAGE_SELECTED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HYPERLINK_MODAL_CLOSED: {
      reportEvent(
        '',
        EVENT_TYPES.HYPERLINK_MODAL_CLOSED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    case HYPERLINK_MODAL_OPENED: {
      reportEvent(
        '',
        EVENT_TYPES.TEXT_EDITOR_HYPERLINK_CLICKED,
        EDITOR,
        createCustomDimensions(state),
      );
      return next(action);
    }
    default:
      return next(action);
  }
};
