import { BackgroundTaskStatus } from '@ftbpro/mm-admin-ui-components';
import { Logger } from 'core/logger';
import { sleep } from '@ftbpro/mm-admin-core-utils';
import { MessageSource } from '../BackgroundTasks.constants';
import { BackgroundTaskData } from '../store/backgroundTasks.constants';
import { DEFAULT_CONTENT_RENDERERS_BY_STATE, DEFAULT_TITLE_FORMATTER } from './webWorker.constants';

/**
 * @typedef {object} ContentRenderersByState - text render functions for each status of the background task viewer
 */

export interface TaskConfig {
  interval?: number;
  /** @type {ContentRenderersByState} */
  contentFormatByState?: {
    [BackgroundTaskStatus.InProgress]?: string /** Text to display inside the Viewer in progress - above the progress bar */
    [BackgroundTaskStatus.Done]?: string /** Text to display inside the Viewer on completed successfuly */
    [BackgroundTaskStatus.Error]?: string /** Text to display inside the Viewer on completed with errors */
    [BackgroundTaskStatus.Canceled]?: string /** Text to display inside the Viewer on completed with canceled items */
    [BackgroundTaskStatus.Loading]?: string /** Text to display inside the Viewer on completed with canceled items */
  };
  inProgressTitleFormatter?: string, /** Text shown in title in progress - after the progress value */
}

export abstract class WebWorkerWrapper {
  nativeWorkerInstance?: Worker;

  interval?: number;

  config?: TaskConfig;

  constructor(taskConfig: TaskConfig) {
    this.interval = taskConfig.interval;
    this.config = taskConfig;
    this.config.contentFormatByState = { ...DEFAULT_CONTENT_RENDERERS_BY_STATE, ...(this.config.contentFormatByState || {}) };
    this.config.inProgressTitleFormatter = this.config.inProgressTitleFormatter || DEFAULT_TITLE_FORMATTER;
  }

  abstract isDone(e: MessageEvent<any>): boolean;

  abstract formatData(data: any): BackgroundTaskData | null;

  abstract wrapTask(): string;

  set onmessage(handler: ((e: MessageEvent<any>) => void) | null) {
    if (this.nativeWorkerInstance) {
      this.nativeWorkerInstance.onmessage = e => { handler?.(e); };
    }
  }

  postMessage(message?: any) {
    Logger.debug(message);
    if (message?.source === MessageSource.BackgroundTasksService) {
      Logger.log(`posting message to nativeWorkerInstance in WebWorkerWrapper, should sleep for ${this.interval ?? 0}`);
      sleep(this.interval ?? 0).then(() => {
        this.nativeWorkerInstance?.postMessage(message);
      });
    }
  }

  terminate() {
    Logger.debug('terminate nativeWorkerInstance');
    this.nativeWorkerInstance?.terminate();
  }
}
