import {
  DATE_FORMATS,
  formatDate,
  getEndOfMonth,
  getStartOfMonth,
  isNullOrUndefined,
  parseISO,
} from '@ftbpro/mm-admin-core-utils';
import { FROM } from './dateRangeSetup.strings';

export const buildFilterTitle = (startDate : (Date | null | undefined), endDate: (Date | null | undefined), placeholder: string) => {
  if (isNullOrUndefined(startDate) && isNullOrUndefined(endDate)) {
    return placeholder;
  }
  if (!isNullOrUndefined(startDate) && isNullOrUndefined(endDate)) {
    return `${FROM} ${formatDate(startDate as Date, DATE_FORMATS.DATE)}`;
  }
  return `${formatDate(startDate as Date, DATE_FORMATS.DATE)}-${formatDate(endDate as Date, DATE_FORMATS.DATE)}`;
};

export const getDateObject = (date: string | Date | null | undefined) => {
  return typeof date === 'string' ? parseISO(date) : date;
};

export const getStartOfLastMonthDate = () => {
  const curr = new Date();
  return formatDate(getStartOfMonth(new Date(curr.getFullYear(), curr.getMonth() - 1, curr.getDate())), DATE_FORMATS.ISO_DATE);
};

export const getEndOfLastMonthDate = () => {
  const curr = new Date();
  return formatDate(getEndOfMonth(new Date(curr.getFullYear(), curr.getMonth() - 1, curr.getDate())), DATE_FORMATS.ISO_DATE);
};
