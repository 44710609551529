import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Placeholder, Text } from '@ftbpro/mm-admin-ui-components';

import { splitArrayIntoChunks, insertPreviewRow } from '../../utils/plugins.utils';
import { convertSecondsToMilliseconds, getArticleFormattedDate } from '../../../utils/cms.utils';

import { PREVIEW_PREFIX } from '../shared/plugins.constants';

import { SITE_VARIABLES } from '../../../../../core/styles/variables';

import { getStylesObject } from './videoList.styles';

export const VideosList = ({
  videos,
  selectedVideoData,
  onGridVideoClick,
  onSelectedVideoClick,
}) => {
  const [loadedIndicators, setLoadedIndicators] = useState({});
  const { grid } = getStylesObject({ columns: 1 });

  const generateGridWithVideo = items => insertPreviewRow({
    items,
    id: selectedVideoData.payload_id,
    uniqueField: 'payload_id',
  });

  const handleThumbnailLoad = video => setLoadedIndicators({
    ...loadedIndicators,
    [video.payload_id]: true,
  });

  const renderPlaceholder = isPreviewVideo => <Placeholder type={Placeholder.PLACEHOLDER_TYPES.SQUARE} rows={1} variables={getStylesObject({ isPreviewVideo }).placeholder} />;

  const renderPreviewVideoContainer = () => {
    const { videoHolder, imageHolder, imageItem } = getStylesObject({
      isPreviewVideo: true,
      loading: false,
    });
    return (
      <div css={css(videoHolder)}>
        <div css={css(imageHolder)}>
          <div
            css={css(imageItem)}
            dangerouslySetInnerHTML={{ /* eslint-disable-line */
              __html: selectedVideoData.previewHtml,
            }}
          />
        </div>
      </div>
    );
  };

  const renderVideoContainer = video => {
    const isCurrentVideoSelected = selectedVideoData && selectedVideoData.payload_id === video.payload_id;
    const {
      videoHolder,
      imageHolder,
      imageItem,
      infoHolder,
      videoTitle,
      videoDate,
      videoDescription,
    } = getStylesObject({
      loading: !loadedIndicators[video.payload_id],
      isSelected: isCurrentVideoSelected,
      hasError: !!video.error,
    });
    const onClickFunc = isCurrentVideoSelected ? onSelectedVideoClick : onGridVideoClick;
    return (
      <div css={css(videoHolder)} onClick={() => onClickFunc(video)}>
        <div css={css(imageHolder)}>
          {!loadedIndicators[video.payload_id] ? renderPlaceholder(false) : null}
          <img
            css={css(imageItem)}
            src={video.image}
            alt="video-thumbnail"
            onLoad={() => handleThumbnailLoad(video)}
          />
        </div>
        <div css={css(infoHolder)}>
          <Text css={css(videoTitle)} type={Text.TEXT_TYPES.TITLE_M} rows={2}>
            {video.title}
          </Text>
          <Text css={css(videoDate)} type={Text.TEXT_TYPES.PARAGRAPH_S}>
            {getArticleFormattedDate(new Date(convertSecondsToMilliseconds(video.pubdate)))}
          </Text>
          <Text css={css(videoDescription)} type={Text.TEXT_TYPES.PARAGRAPH_S} rows={2}>
            {video.description}
          </Text>
          {video.error ? (
            <Text type={Text.TEXT_TYPES.PARAGRAPH_S} color={SITE_VARIABLES.COLORS.ERROR}>
              {video.error}
            </Text>
          ) : null}
        </div>
      </div>
    );
  };

  const isErroredVideo = (video, isPreviewVideo) => {
    if (selectedVideoData) {
      const hasError = selectedVideoData.error && video.payload_id === selectedVideoData.payload_id;
      return hasError && isPreviewVideo;
    }
    return null;
  };

  const renderGridRow = (videosList, index) => {
    const [currentVideo] = videosList;
    const isPreviewVideo = currentVideo.payload_id.indexOf(PREVIEW_PREFIX) !== -1;
    const { row, column } = getStylesObject({ columns: 1 });
    return (
      <div css={css(row)} key={`grid-row-${index}`}>
        {videosList.map(video => {
          return (
            <div css={css(column)} key={video.payload_id}>
              {isPreviewVideo || isErroredVideo(currentVideo, isPreviewVideo)
                ? renderPreviewVideoContainer() : renderVideoContainer(video)}
            </div>
          );
        })}
      </div>
    );
  };

  const gridItems = splitArrayIntoChunks(videos, 1);
  const videosList = selectedVideoData ? generateGridWithVideo(gridItems) : gridItems;
  return (
    <div css={css(grid)}>
      {videosList.map((chunks, index) => renderGridRow(chunks, index))}
    </div>
  );
};
