import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATES } from 'app.contants';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import { AppDispatch } from 'core/store';
import { getSliceName } from 'core/store/store.utils';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { DashboardsPaths, REPORTS } from '../../reports.constants';
import { QueryBuilderServiceConfigsProvider } from '../../services/queryBuilderServiceApiProvider';
import { ChartData, Filter } from './dashboards.types';
import { formatReportData, formatDateObject } from './dashboards.formatters';
import {
  dashboardsData,
  reportInitialData,
  tableFormats,
} from './dashboardsDataConfig.constants';
import {
  ChartsType,
  ChartsFormat,
  INITIAL_STATE,
  SLICE_SCOPE,
  FilterId,
  DISCLAIMER,
  DATE_INITIAL_VALUE,
  DASHBOARDS_EVENT_TYPES,
} from './dashboards.constants';
import { pushGlobalNotificationActionCreator } from '../../../../core/globalNotifications/store/globalNotifications.actions';
import { CHART_ERROR_NOTIFICATION_TEXT } from '../../pages/DashboardPage/dashboardPage.strings';
import { DateObject } from '../../pages/components/DateRangeSetup/DateRangeSetup';

const SLICE_NAME = getSliceName(REPORTS, SLICE_SCOPE);

const DashboardsSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    fetchChartDataPending(state, action: PayloadAction<{ dashboardPath: DashboardsPaths, chartName: string, chartType: ChartsType, chartLine: number, chartFormat: ChartsFormat }>) {
      const { dashboardPath, chartName, chartType, ...extraChartData } = action.payload;
      state[dashboardPath].chartsData[chartName] = {
        chartLoading: LOADING_STATES.PENDING,
        chartError: '',
        chartType,
        chartData: [],
        chartName,
        ...extraChartData,
      };
    },
    fetchChartDataSuccess(state, action: PayloadAction<{ dashboardPath: DashboardsPaths, reportData: {[ key: string]: any}, chartName: string, chartType: ChartsType }>) {
      const { dashboardPath, reportData, chartName, chartType } = action.payload;
      state[dashboardPath].chartsData[chartName].chartLoading = LOADING_STATES.SUCCESS;
      state[dashboardPath].chartsData[chartName].chartData = formatReportData(reportData, chartType, tableFormats[dashboardPath]);
    },
    fetchChartDataRejected(state, action: PayloadAction<{ dashboardPath: DashboardsPaths, error: string, chartName: string }>) {
      const { dashboardPath, error, chartName } = action.payload;
      state[dashboardPath].chartsData[chartName].chartLoading = LOADING_STATES.FAILED;
      state[dashboardPath].chartsData[chartName].chartError = error;
    },
    fetchFilterDataPending(state, action: PayloadAction<FilterId>) {
      const filterId = action.payload;
      state.filtersData[filterId].filterItemsLoading = true;
    },
    fetchFilterDataSuccess(state, action: PayloadAction<{ reportData: {[ key: string]: any}, filterId: FilterId }>) {
      const { reportData, filterId } = action.payload;
      const { report } = reportData;
      state.filtersData[filterId].filterItemsLoading = false;
      state.filtersData[filterId].filterListItems = report.map((data: any) => {
        return data[filterId].display;
      });
    },
    fetchFilterDataRejected(state, action: PayloadAction<{ error: string, filterId: FilterId }>) {
      const { error, filterId } = action.payload;
      state.filtersData[filterId].filterItemsError = error;
      state.filtersData[filterId].filterItemsLoading = false;
    },
    fetchDisclaimerPending(state) {
      state.disclaimerData.isLoading = true;
    },
    fetchDisclaimerSuccess(state, action : PayloadAction<{report: {[ key: string]: any}}>) {
      state.disclaimerData.isLoading = false;
      const { report } = action.payload;
      state.disclaimerData.disclaimer = report[0]?.Disclaimer.display.split('\n');
    },
    fetchDisclaimerRejected(state, action: PayloadAction<string>) {
      state.disclaimerData.isLoading = false;
      state.disclaimerData.disclaimer = [];
      state.disclaimerData.error = action.payload;
    },
    setPageLoaded(state, action: PayloadAction<boolean>) {
      state.pageLoaded = action.payload;
    },
  },
});

export const fetchChartData = (organizationID: string, chartData: ChartData, dashboardPath: DashboardsPaths, selectedFilters: Filter[], selectedDateRange: DateObject) => async (dispatch: AppDispatch) => {
  const { chartType, data, ...extraChartData } = chartData;
  const { name: chartName } = data;
  const extendedFilters = [...data.filters, ...selectedFilters];
  const extendedData = { ...data, filters: extendedFilters, dateRange: formatDateObject(selectedDateRange) };
  await dispatch(networkAction({
    method: HttpMethod.Post,
    url: QueryBuilderServiceConfigsProvider.getReportEndpoint(organizationID, chartData.data.dataSourceID),
    data: extendedData,
    onPending: () => {
      return { type: fetchChartDataPending.type, payload: { dashboardPath, chartName, chartType, ...extraChartData } };
    },
    onSuccess: reportData => {
      return { type: fetchChartDataSuccess.type, payload: { dashboardPath, reportData, chartName, chartType } };
    },
    onFailure: error => {
      dispatch(pushGlobalNotificationActionCreator({
        text: CHART_ERROR_NOTIFICATION_TEXT,
        status: SNACK_BAR_STATUSES.ERROR,
      }));
      return { type: fetchChartDataRejected.type, payload: { dashboardPath, error, chartName } };
    },
  }));
};

export const fetchDashboardData = (organizationID: string, dashboardPath: DashboardsPaths, selectedFilters: Filter[] = [], selectedDateRange: DateObject = DATE_INITIAL_VALUE) => async (dispatch: AppDispatch) => {
  let loadingTime = 0;
  const dashboardData = dashboardsData[dashboardPath];
  // eslint-disable-next-line no-return-assign
  const loadingTimeIntervalId = setInterval(() => loadingTime += 100, 100);
  const fetchCharts = dashboardData.map((chart: ChartData) => {
    return dispatch(fetchChartData(organizationID, chart, dashboardPath, selectedFilters, selectedDateRange));
  });
  await Promise.all(fetchCharts).then(() => {
    dispatch({ type: DASHBOARDS_EVENT_TYPES.DASHBOARD_PAGE_LOADED, payload: { loadingTime, dashboardPath } });
    clearInterval(loadingTimeIntervalId);
    dispatch({ type: setPageLoaded.type, payload: true });
  });
};

export const fetchFilterListItems = (organizationID: string, filterId: FilterId, dashboardDataSourceId: string) => async (dispatch: AppDispatch) => {
  await dispatch(networkAction({
    method: HttpMethod.Post,
    url: QueryBuilderServiceConfigsProvider.getReportEndpoint(organizationID, dashboardDataSourceId),
    data: { ...reportInitialData, dimensions: [filterId], orderBy: filterId, dataSourceID: dashboardDataSourceId },
    onPending: () => {
      return { type: fetchFilterDataPending.type, payload: filterId };
    },
    onSuccess: reportData => {
      return { type: fetchFilterDataSuccess.type, payload: { filterId, reportData } };
    },
    onFailure: error => {
      return { type: fetchFilterDataRejected.type, payload: { filterId, error } };
    },
  }));
};

export const fetchDisclaimer = (organizationID: string, disclaimerDataSourceId: string) => async (dispatch: AppDispatch) => {
  await dispatch(networkAction({
    method: HttpMethod.Post,
    url: QueryBuilderServiceConfigsProvider.getReportEndpoint(organizationID, disclaimerDataSourceId),
    data: { ...reportInitialData, dimensions: [DISCLAIMER], orderBy: DISCLAIMER, dataSourceID: disclaimerDataSourceId },
    onPending: fetchDisclaimerPending,
    onSuccess: fetchDisclaimerSuccess,
    onFailure: fetchDisclaimerRejected,
  }));
};

export const {
  fetchChartDataPending,
  fetchChartDataSuccess,
  fetchChartDataRejected,
  fetchFilterDataPending,
  fetchFilterDataSuccess,
  fetchFilterDataRejected,
  fetchDisclaimerPending,
  fetchDisclaimerSuccess,
  fetchDisclaimerRejected,
  setPageLoaded,
} = DashboardsSlice.actions;

export default DashboardsSlice.reducer;
