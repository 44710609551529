import { SITE_VARIABLES } from '../../styles/variables';

export const getStylesObject = () => {
    return {
      errorMainContainer: {
       width: '100%',
      },
      allRoutesMainContainer: {
        backgroundColor: SITE_VARIABLES.COLORS.BACKGROUND, // TBD - remove after old design is gone - this is for backward compatability
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        height: '100vh',
        overflowX: 'hidden',
        // Might influence other parts of the project. If it happens I'm sorry.
        minWidth: '0px',
      },
    };
  };
