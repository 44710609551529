const domParser = new DOMParser();

const HTML_TEXT_TYPE = 'text/html';
const HEIGHT_ATTRIBUTE_REGEX = /height="\d+([^"]*)/g;
const HEIGHT_STYLE_ATTRIBUTE_REGEX = /height: \d+([^;]*)/g;

const getHtmlTag = (htmlString, tagName) => {
  return domParser
    .parseFromString(htmlString, HTML_TEXT_TYPE)
    .getElementsByTagName(tagName)[0];
};

const extractHeightFromIframeString = embedCodeHTMLString => {
  let height = null;
  if (
    embedCodeHTMLString.match(HEIGHT_ATTRIBUTE_REGEX)
    && !embedCodeHTMLString.match(HEIGHT_ATTRIBUTE_REGEX)[0].includes('%')
  ) {
    height = embedCodeHTMLString
      .match(HEIGHT_ATTRIBUTE_REGEX)[0]
      .replace('height="', '');
  }
  if (
    embedCodeHTMLString.match(HEIGHT_STYLE_ATTRIBUTE_REGEX)
    && !embedCodeHTMLString.match(HEIGHT_STYLE_ATTRIBUTE_REGEX)[0].includes('%')
  ) {
    height = embedCodeHTMLString
      .match(HEIGHT_STYLE_ATTRIBUTE_REGEX)[0]
      .replace('height: ', '');
  }
  return height;
};

export const isValidIframeEmbedCode = embedCodeHTMLString => {
  const iframeHeight = extractHeightFromIframeString(embedCodeHTMLString);
  return (
    embedCodeHTMLString.trimStart().startsWith('<iframe')
    && embedCodeHTMLString.trimEnd().endsWith('</iframe>')
    && embedCodeHTMLString.includes('src')
    && iframeHeight
  );
};

export const formatEmbedForPost = embedCodeHTMLString => {
  if (!embedCodeHTMLString.includes('riddle.com')) {
    return embedCodeHTMLString;
  }

  const iframe = domParser
    .parseFromString(embedCodeHTMLString, HTML_TEXT_TYPE)
    .getElementsByTagName('iframe')[0];

  const riddleId = iframe.getAttribute('data-rid-id');
  const embedString = embedCodeHTMLString.replace('staticHeight=true', '');

  return `<div data-block-type="implement-riddle-iframe-embed" class="riddle2-wrapper" data-rid-id="${riddleId}">${embedString}</div>`;
};

export const formatEmbedForEditor = embedCodeHTMLString => {
  if (!embedCodeHTMLString.includes('riddle.com')) {
    return embedCodeHTMLString;
  }

  const first = embedCodeHTMLString.indexOf('<ifram');
  const last = embedCodeHTMLString.indexOf('</iframe');
  const formatted = embedCodeHTMLString.slice(first, last + 9);
  return formatted;
};

export const getIframeEmbedData = embedCodeHTMLString => {
  const formatEmbed = formatEmbedForPost(embedCodeHTMLString);
  const iFrameTag = getHtmlTag(formatEmbed, 'iframe');
  const height = extractHeightFromIframeString(embedCodeHTMLString).replace(
    'px',
    '',
  );
  return {
    embedCodeHTMLString: formatEmbed,
    src: iFrameTag.getAttribute('src'),
    height,
  };
};
