import { Logger } from '../logger';
import {
  GOOGLE_TAG_MANAGER,
  TEXT_JAVASCRIPT_TYPE,
  HTML_ELEMENT_TYPES,
} from './analyticsService.constants';

const injectGTMScriptToHead = () => {
  const analyticsScriptForHead = document.createElement(HTML_ELEMENT_TYPES.SCRIPT);
  analyticsScriptForHead.type = TEXT_JAVASCRIPT_TYPE;
  try {
    analyticsScriptForHead.appendChild(document.createTextNode(GOOGLE_TAG_MANAGER.SCRIPT_FOR_HEAD));
    document.head.appendChild(analyticsScriptForHead);
  } catch (error) {
    Logger.warn(error);
  }
};

const injectGTMScriptToBody = () => {
  const analyticsScriptForBody = document.createElement(HTML_ELEMENT_TYPES.NOSCRIPT);
  analyticsScriptForBody.type = TEXT_JAVASCRIPT_TYPE;
  try {
    analyticsScriptForBody.appendChild(document.createTextNode(GOOGLE_TAG_MANAGER.NOSCRIPT_FOR_BODY));
    document.body.appendChild(analyticsScriptForBody);
  } catch (error) {
    Logger.warn(error);
  }
};

export const injectGTMScript = () => {
  injectGTMScriptToHead();
  injectGTMScriptToBody();
};
