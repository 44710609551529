import React, { Component, useEffect, useRef, useState } from 'react';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';
import { VimeoIcon } from '@ftbpro/mm-admin-assets';
import { css } from '@emotion/core';

import { Plugin } from './Plugin/Plugin';
import { EmbedBlock } from './shared/EmbedBlock';
import { Logger } from '../../../../core/logger';

import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';
import { overviewBlockText } from '../utils/blockList.utils';
import { urlInputPlaceholderGenerator, pluginAddButtonTextHandler } from '../utils/plugins.utils';
import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const VIMEO_OEMBED_URL_PREFIX = `https://vimeo.com/api/oembed.json?${OEMBED_QUERY_PARAMS.URL}=`;
const ENTER_VIMEO_PLACEHOLDER = urlInputPlaceholderGenerator('Vimeo');
export const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Vimeo. Please paste a correct one.';

// Element-Panel Top Bar Button

export const VimeoEmbedTopBarIcon = props => {
  return (
    <Icon icon={VimeoIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class VimeoEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = this.props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.VIMEO;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      vimeoData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.VIMEO;
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { vimeoData } = this.state;
    onAdd(vimeoData);
  };

  onUrlInputKeyDown = e => {
    const { vimeoData } = this.state;
    if (isEnterPressed(e) && vimeoData) {
      this.onAdd(vimeoData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    if (this.isValidVimeoUrl(url)) {
      const oEmbedEndPoint = `${VIMEO_OEMBED_URL_PREFIX}${encodeURI(url)}`;
      getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.VIMEO, false)
        .then(vimeoData => {
          finishLoading();
          this.setState({ vimeoData });
        })
        .catch(e => {
          finishLoading();
          this.setState({ vimeoData: null });
          Logger.error(e);
        });
    } else {
      finishLoading();
      this.setState({ vimeoData: null });
    }
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, vimeoData } = this.state;
    if (url === '' || isLoading) {
      return null;
    }
    return vimeoData && this.isValidVimeoUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: vimeoData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => {
    this.setState({ url: '' });
  };

  isValidVimeoUrl = url => {
    return url.includes('vimeo');
  };

  render() {
    const { editedBlockData, onCancel } = this.props;
    const { vimeoData, url } = this.state;
    const isAddDisabled = vimeoData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_VIMEO_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const VimeoBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  const isIframeInserted = () => {
    return blockRef.current && blockRef.current.querySelector('iframe');
  };

  useEffect(() => {
    if (isIframeInserted()) {
      blockRef.current.querySelector('iframe').addEventListener('load', () => {
        setLoading(false);
      });
    }
  }, []);

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function VimeoEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <VimeoEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const vimeoEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<VimeoEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<VimeoEmbedPanelComponent {...props} />),
  getPluginBlock: props => <VimeoBlock {...props} />,
  getPluginOverviewBlock: props => (<VimeoEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.VIMEO,
};
