import { ConfigsProvider } from 'core/configs/ConfigsProvider';

const { TAGS_API_BASE_URL } = ConfigsProvider;

const PROPERTIES = 'properties';
const TAGS = 'tags';

export class TagsServiceApiProvider {
  static autocompleteTag(property: string) {
    return `${TAGS_API_BASE_URL}/${PROPERTIES}/${property}/${TAGS}/autocomplete`;
  }

  static suggestTags(property: string) {
    return `${TAGS_API_BASE_URL}/${PROPERTIES}/${property}/${TAGS}/extract`;
  }
}
