import { HTTP_METHODS, mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { MatchesServiceConfigsProvider } from './matchesServiceConfigsProvider';

export class MatchesNetworkService {
  static async isValidUrl({ data, postId, property }) {
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(MatchesServiceConfigsProvider.getIsValidUrlEndpoint({ postId, property }), configs);
  }
}
