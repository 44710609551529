import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Icon,
  Input,
  Divider,
  Button,
  ButtonType,
} from '@ftbpro/mm-admin-ui-components';
import { CustomIcon } from '@ftbpro/mm-admin-assets';
import { setDrawerOpen } from 'containers/cms/contentEnrichment/store/contentEnrichmentSlice';
import { Plugin } from './Plugin/Plugin';
import {
  pluginAddButtonTextHandler,
  urlInputPlaceholderGenerator,
} from '../utils/plugins.utils';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { getStylesObject } from './styles/embed.styles';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { EmbedBlock } from './shared/EmbedBlock';
import { overviewBlockText } from '../utils/blockList.utils';
import { ConfigsProvider } from '../../../../core/configs/ConfigsProvider';
import {
  injectContentEnrichmentScriptIfNeedsTo,
  renderContentEnrichmentEmbeds,
} from './externalScripts/editorPluginsScripts';
import {
  OEMBED_QUERY_PARAMS,
  OEMBED_RESOURCE,
} from '../services/oembed/oEmbed.constants';
import { setIsContentEnrichment } from '../store/elementPanel/elementPanel.actions';

export const EMBED_BROKEN_URL_TEXT = 'The embed url you are using is not from the Content Enrichment. Please paste a correct one.';
const CUSTOM_EMBED = 'custom embed';
const CREATE_EMBED = 'Create new embed';
const CONTENT_ENRICHMENT_OEMBED_PREFIX = ConfigsProvider.CONTENT_ENRICHMENT_API_BASE_URL;
const ENTER_PLACE_HOLDER = urlInputPlaceholderGenerator(CUSTOM_EMBED);

export const CustomEmbedTopBarIcon = props => {
  return <Icon icon={CustomIcon} width={28} height={28} {...props} />;
};

export const ContentEnrichmentEmbed = props => {
  const {
    onCancel,
    editedBlockData,
    onAdd,
    isLoading,
    startLoading,
    finishLoading,
  } = props;

  const dispatch = useDispatch();

  const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.CONTENT_ENRICHMENT;

  const [contentEnrichmentData, setContentEnrichmentData] = useState(null);
  const [url, setUrl] = useState(
    isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
  );

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getUrlOembedData(url);
  }, []);

  useEffect(() => {
    injectContentEnrichmentScriptIfNeedsTo();
  }, []);

  const onAddHandler = () => {
    onAdd(contentEnrichmentData);
  };

  const onCreateEmbed = () => {
    dispatch(setDrawerOpen(true));
    dispatch(setIsContentEnrichment(true));
  };

  const getUrlOembedData = newUrl => {
    startLoading();
    if (newUrl) {
      const oEmbedEndpoint = `${CONTENT_ENRICHMENT_OEMBED_PREFIX}/${OEMBED_RESOURCE}?${OEMBED_QUERY_PARAMS.URL}=${newUrl}`;
      getOembedData(
        oEmbedEndpoint,
        oEmbedEndpoint,
        BLOCK_TYPES.CONTENT_ENRICHMENT,
      )
        .then(contentEnrichmentOEmbedRes => {
          finishLoading();
          setContentEnrichmentData(contentEnrichmentOEmbedRes);
          renderContentEnrichmentEmbeds();
        })
        .catch(() => {
          finishLoading();
          setContentEnrichmentData(null);
        });
    } else {
      finishLoading();
    }
  };

  const onInputChange = ({ target: { value: newUrl } }) => {
    setUrl(newUrl);
    getUrlOembedData(newUrl);
  };

  const clearInput = () => {
    setUrl('');
  };

  const getPreviewComponent = () => {
    if (url === '' || isLoading) {
      return null;
    }

    return contentEnrichmentData ? (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: contentEnrichmentData.html }}
        css={getStylesObject().embed}
        key="dangerouslySetInnerHTML"
      />
    ) : (
      <Plugin.ErrorMsgComponent text={EMBED_BROKEN_URL_TEXT} />
    );
  };

  const isAddDisabled = contentEnrichmentData === null || url === '';

  return (
    <Plugin.Container>
      <Plugin.Content>
        <Input
          value={url}
          placeholder={ENTER_PLACE_HOLDER}
          autoFocus
          style={pluginInputStyle}
          onChange={onInputChange}
          clearInput={clearInput}
        />
        {getPreviewComponent()}
        <Divider style={getStylesObject().divider} />
        <Button
          content={CREATE_EMBED}
          type={ButtonType.Secondary}
          onClick={onCreateEmbed}
        />
      </Plugin.Content>
      <Plugin.CopyrightInformation />
      <Plugin.Buttons
        isAddDisabled={isAddDisabled}
        onAddClick={onAddHandler}
        addButtonText={pluginAddButtonTextHandler(contentEnrichmentData)}
        onCancelClick={onCancel}
      />
    </Plugin.Container>
  );
};

export const ContentEnrichmentBlock = props => {
  useEffect(() => {
    injectContentEnrichmentScriptIfNeedsTo();
    renderContentEnrichmentEmbeds();
  });

  const embedBlockProps = {
    ...props,
    styles: getStylesObject().contentEnrichmentBlock,
  };

  return <EmbedBlock {...embedBlockProps} />;
};

export function ContentEnrichmentOverviewBlock({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <CustomEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

export const contentEnrichmentEmbedPluginData = {
  getPluginTopBarButtonIcon: props => <CustomEmbedTopBarIcon {...props} />,
  getPluginPanelComponent: props => <ContentEnrichmentEmbed {...props} />,
  getPluginBlock: props => <ContentEnrichmentBlock {...props} />,
  getPluginOverviewBlock: props => (
    <ContentEnrichmentOverviewBlock {...props} />
  ),
  pluginBlockType: BLOCK_TYPES.CONTENT_ENRICHMENT,
};
