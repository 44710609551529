import { CMS_PAGES } from '../../../constants/cms.constants';
import { EDITOR } from './editorAnalyticsMiddleware.constants';

export const didUserChangeImage = (prevImageData, nextImageData) => prevImageData !== null
  && prevImageData !== nextImageData;

const doesActionTypeIncludeEditorString = actionType => {
  return actionType.includes(EDITOR.toUpperCase());
};

export const shouldSkipActionInEditorMiddleware = action => {
  const { type: actionType, payload } = action;
  if (!actionType) {
    return true;
  }
  return !doesActionTypeIncludeEditorString(actionType)
    && payload !== CMS_PAGES.EDITOR_DRAFT;
};

export const createMediaAddedLabel = (pluginData, additionalData, editingCover, blockType) => {
  const { alt } = pluginData;
  const element = editingCover ? 'cover' : 'body';
  if (blockType === 'image') {
    return editingCover
      ? `Element=${element};alt text=${alt ? 'yes' : 'no'}`
      : `Element=${element};crop=${pluginData.aspectRatio};alt text=${alt ? 'yes' : 'no'}`;
  }
  if (blockType === 'twitter' || blockType === 'giphy' || blockType === 'mmPlayer') {
    const { pluginMode } = additionalData;
    return `Element=${element};${pluginMode}`;
  }
    return `Element=${element}`;
};
