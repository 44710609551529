import React, { Component, Fragment } from 'react';
import { Icon, Filter, SearchInput, TextButton } from '@ftbpro/mm-admin-ui-components';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { GettyIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from '../Plugin/Plugin';
import { ImageBlock, ImageBlockOverview } from '../shared/ImageBlock';
import { PluginGridWithPreview } from '../shared/PluginGridWithPreview';
import { CroppingAreaCustomization } from '../../shared/croppingArea/CroppingAreaCustomization';

import { GettyServices } from '../../services/gettyServices';

import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';

import {
  ERROR_MESSAGES,
  RESPONSE_ERRORS,
  PANEL_MODES,
  GETTY_IMAGES_PROVIDER,
  PLUGIN_BUTTON_CHOOSE_TEXT, ENTER_SEARCH_PLACEHOLDER, LOAD_MORE_TEXT,
} from '../shared/plugins.constants';

import { removeLineBreaks } from '../../utils/inlineText.utils';
import { pluginAddButtonTextHandler } from '../../utils/plugins.utils';
import {
  CROP_ASPECT_TYPES, formatImgLinkObject,
  getCropAspectName,
  getInitialPercentageCrop,
  substringImageCaptionAndCredit, validateLinkableImage,
} from '../../../services/imageServices/imageService.utils';
import { getUrlFromImageResponseObject, IMAGE_VIEW_TYPES } from '../shared/plugins.utils';

import { getStylesObject } from '../shared/styles/gettyAndImagn.styles';
import {
  GETTY_CREATIVE_EMBED_PLUGIN_NAME,
  GETTY_DROPDOWN_OPTION_TO_SORT_OPTION,
  GETTY_DROPDOWN_OPTIONS,
  GETTY_EMBED_PLUGIN_NAME,
  GETTY_IMAGE_SIZES,
  GETTY_SORTING_OPTIONS,
} from './getty.constants';
import { ImageDescription } from '../../shared/ImageDescription';
import { IMAGE_SIZE_TYPES } from '../../../constants/image.constants';
import { FullBleedImageToggler } from '../../shared/FullBleedImageToggler';
import { isEnterPressed } from '../../../../../core/utils/keyboard.utils';
import { LinkableImageSettings } from '../../shared/LinkableImageSettings';
import { gettyImageSelected, openGettyPanel, searchGettyImages } from '../../store/elementPanel/elementPanel.actions';

// Element-Panel Top Bar Button
const LINKABLE_IMAGE_URL_ERROR = 'URL must start with \'http://\' or \'https://\'';

export const GettyTopBarIcon = props => {
  return (
    <Icon icon={GettyIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class GettyPanelComponent extends Component {
  constructor(props) {
    super();
    this.resultsPageNumber = 1;
    this.currentSearchRequest = '';
    this.sortedBy = GETTY_SORTING_OPTIONS.BEST_MATCH;
    this.state = {
      hasSearched: false,
      searchQuery: '',
      gettyImagesList: [],
      mode: PANEL_MODES.GETTY,
      selectedImage: null,
      percentageCrop: { aspect: CROP_ASPECT_TYPES.HORIZONTAL },
      caption: '',
      credit: '',
      alt: '',
      imageLoading: false,
      errorMessage: null,
      noMoreImagesFound: false,
      dropDownValue: GETTY_DROPDOWN_OPTIONS.BEST_MATCH,
      sizeType: IMAGE_SIZE_TYPES.REGULAR,
      isImageLinkable: !!props.editedBlockData?.value.linkURL,
      linkableImageSettings: {
        targetLink: props.editedBlockData?.value.linkURL || '',
        shouldOpenNewTab: !!props.editedBlockData?.value.linkTargetAttribute,
        shouldNoFollow: !!props.editedBlockData?.value.linkRelAttribute,
      },
      linkableImageLinkError: '',
    };
  }

  componentDidMount() {
    const { openPanel } = this.props;
    openPanel();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.IMAGE;
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery } = this.state;
    if (searchQuery !== prevState.searchQuery) {
      this.resultsPageNumber = 1;
    }
  }

   setIsImageLinkable = () => {
    const { isImageLinkable } = this.state;
    this.setState({
      isImageLinkable: !isImageLinkable,
    });
  };

   setLinkableImageSettings =({ targetLink, shouldOpenNewTab, shouldNoFollow }) => {
    this.setState({
      linkableImageSettings: {
        targetLink,
        shouldOpenNewTab,
        shouldNoFollow,
      },
    });
  };

  setLinkableImageLinkError=error => {
     this.setState({
       linkableImageLinkError: error,
     });
  };

  onSearchInputChange = e => {
    const { searchImage } = this.props;
    const { errorMessage, hasSearched } = this.state;
    const searchQuery = e.target.value;
    if (!hasSearched) {
      searchImage();
      this.setState({
        hasSearched: true,
      });
    }

    this.setState({
      searchQuery,
      errorMessage: errorMessage === ERROR_MESSAGES.NO_SEARCH_QUERY && searchQuery ? null : errorMessage,
    });
  };

  onDropdownChange = async (e, { value }) => {
    const { searchQuery } = this.state;
    this.setState({
      dropDownValue: value,
    });
    this.sortedBy = GETTY_DROPDOWN_OPTION_TO_SORT_OPTION[value];
    if (searchQuery) {
      await this.searchGettyImages();
    }
  };

  onSearchInputKeyDown = async e => {
    const { searchQuery } = this.state;
    if (isEnterPressed(e) && searchQuery) {
      await this.searchGettyImages();
    }
  };

  onGridImageClick = image => {
    const { startLoading } = this.props;
    startLoading();
    return this.setSelectedImage({
      ...image,
      previewImageUrl: getUrlFromImageResponseObject({
        blockType: BLOCK_TYPES.GETTY,
        image,
        imageViewType: IMAGE_VIEW_TYPES.PREVIEW_IMAGE,
      }),
    });
  };

  onImageChoose = () => {
    const { imageSelected } = this.props;
    const { selectedImage, percentageCrop: { aspect } } = this.state;
    imageSelected();
    this.setState({
      mode: PANEL_MODES.CROP,
      imageLoading: true,
      caption: selectedImage && selectedImage.title ? substringImageCaptionAndCredit(selectedImage.title) : '',
      credit: selectedImage && selectedImage.title ? `${selectedImage.artist}/${GETTY_IMAGES_PROVIDER}` : '',
      percentageCrop: { aspect },
    });
  };

  onImageError = image => {
    const { gettyImagesList } = this.state;
    const { id } = image;
    this.setState({
      gettyImagesList: gettyImagesList.filter(item => item.id !== id),
    });
  };

  setCompletedCrop = percentageCrop => {
    this.setState({ percentageCrop });
  };

  onCropAspectTypeChange = type => {
    const percentageCrop = getInitialPercentageCrop(type);
    this.setState({
      percentageCrop,
    });
  };

  onChangeCaption = (e, value) => {
    this.setState({ caption: removeLineBreaks(value) });
  };

  onChangeAlt = (e, value) => {
    this.setState({ alt: removeLineBreaks(value) });
  };

  onCaptionInputKeyDown = e => {
    if (isEnterPressed(e)) {
      this.onAddHandler();
    }
  };

  onImageLoaded = () => {
    this.setState({
      imageLoading: false,
    });
  };

  onBackButtonClick = () => {
    this.setState({
      mode: PANEL_MODES.GETTY,
    });
  };

  onAddHandler = () => {
    const { onAdd } = this.props;
    const { isImageLinkable, linkableImageSettings, alt } = this.state;
    const isValidLinkableUrl = isImageLinkable && validateLinkableImage(linkableImageSettings.targetLink);
    if ((!isImageLinkable || isValidLinkableUrl) && alt) {
      this.setLinkableImageLinkError('');
      return onAdd(this.getData());
    }
    return this.setLinkableImageLinkError(LINKABLE_IMAGE_URL_ERROR);
  };

  setImages = images => {
    const { finishLoading } = this.props;
    const { searchQuery, gettyImagesList } = this.state;
    const imagesFound = images && images.length;
    if (imagesFound) {
      this.setState({
        gettyImagesList: this.resultsPageNumber !== 1 && this.currentSearchRequest === searchQuery ? [...gettyImagesList, ...images] : images,
        errorMessage: null,
        noMoreImagesFound: false,
      });
    } else if (this.resultsPageNumber === 1) {
      this.setState({ gettyImagesList: [] });
      this.showErrorMessage(ERROR_MESSAGES.NO_DATA);
    }
    finishLoading();
  };

  setSelectedImage = image => {
    const { finishLoading } = this.props;
    this.setState({
      selectedImage: image,
      caption: image.title,
      alt: image?.people?.join(', ') || image.title,
    });
    finishLoading();
  };

  getData = () => {
    const { shouldAllowHighQualityImages } = this.props;
    const { selectedImage, percentageCrop, caption, credit, alt, sizeType, isImageLinkable, linkableImageSettings } = this.state;
    const downloadSize = shouldAllowHighQualityImages ? GETTY_IMAGE_SIZES.LARGE : GETTY_IMAGE_SIZES.MEDIUM;
    const { linkURL, linkTargetAttribute, linkRelAttribute } = formatImgLinkObject({ linkableImageSettings });
    return {
      image: selectedImage,
      crop: percentageCrop,
      aspectRatio: getCropAspectName(percentageCrop.aspect),
      downloadSize,
      caption,
      credit,
      alt,
      sizeType,
      ...(isImageLinkable && { linkURL, linkTargetAttribute, linkRelAttribute }),
      provider: GETTY_IMAGES_PROVIDER,
    };
  };

  getFooterPluginButtonsProps = isGettyMode => {
    const { imageLoading, alt } = this.state;
    const { editedBlockData } = this.props;
    return isGettyMode ? {
      onAddClick: this.onImageChoose,
      isAddDisabled: this.isChooseButtonDisabled(),
      addButtonText: PLUGIN_BUTTON_CHOOSE_TEXT,
    } : {
      onAddClick: this.onAddHandler,
      isAddDisabled: imageLoading || !alt,
      addButtonText: pluginAddButtonTextHandler(editedBlockData),
    };
  };

  loadMoreImages = async () => {
    this.resultsPageNumber += 1;
    await this.searchGettyImages();
  };

  removePreviewImage = () => {
    this.setState({
      selectedImage: null,
    });
  };

  showErrorMessage = payload => {
    const { finishLoading } = this.props;
    this.setState({
      errorMessage: payload,
    });
    finishLoading();
  };

  handleGettyError = e => {
    const { finishLoading } = this.props;
    if (e.ErrorCode === RESPONSE_ERRORS.INVALID_PAGE) {
      this.setState({
        noMoreImagesFound: true,
      });
    }
    finishLoading();
  };

  searchGettyImages = async () => {
    const { startLoading, property, pluginName } = this.props;
    const { searchQuery } = this.state;
    if (searchQuery) {
      startLoading();
      try {
        const searchResult = await GettyServices.searchImages({
          pluginName,
          searchQuery,
          page: this.resultsPageNumber,
          sortedBy: this.sortedBy,
          property: property.slug,
        });
        if (searchResult.ErrorCode) {
          this.handleGettyError(searchResult);
        } else {
          this.setImages(searchResult);
        }
      } catch (e) {
        this.handleGettyError(e);
      }
      this.currentSearchRequest = searchQuery;
    } else {
      this.setState({
        errorMessage: ERROR_MESSAGES.NO_SEARCH_QUERY,
      });
    }
  };

  isChooseButtonDisabled = () => {
    const { selectedImage } = this.state;
    return !selectedImage;
  };

  shouldShowLoadMoreButton = () => {
    const { gettyImagesList, noMoreImagesFound, searchQuery } = this.state;
    return gettyImagesList.length && !noMoreImagesFound && searchQuery;
  };

  clearSearchQuery = () => {
    this.setState({
      searchQuery: '',
    });
  };

  renderLoadMoreButton = () => {
    const { loadMoreButton } = getStylesObject();
    return (
      this.shouldShowLoadMoreButton()
        ? (
          <div css={css(loadMoreButton)}>
            <TextButton content={LOAD_MORE_TEXT} onClick={this.loadMoreImages} />
          </div>
          )
        : null
    );
  };

  renderGettyPanel = () => {
    const { errorMessage, noMoreImagesFound } = this.state;
    const GettyHeader = this.renderGettyHeader;
    const GettyBody = this.renderGettyBody;
    const LoadMoreButton = this.renderLoadMoreButton;
    return (
      <Fragment>
        <GettyHeader />
        {errorMessage ? <Plugin.ErrorMsgComponent text={errorMessage} /> : <GettyBody />}
        <LoadMoreButton />
        {noMoreImagesFound ? <Plugin.ErrorMsgComponent text={ERROR_MESSAGES.NO_MORE_IMAGES} /> : null}
      </Fragment>
    );
  };

  renderGettyHeader = () => {
    const { isLoading } = this.props;
    const { searchQuery, dropDownValue } = this.state;
    const { header } = getStylesObject();
    const dropdownItems = Object.values(GETTY_DROPDOWN_OPTIONS);
    return (
      <div css={css(header)}>
        <SearchInput
          value={searchQuery}
          placeholder={ENTER_SEARCH_PLACEHOLDER}
          disabled={isLoading}
          сlearable="true"
          onChange={this.onSearchInputChange}
          onKeyDown={this.onSearchInputKeyDown}
          onClear={this.clearSearchQuery}
        />
        <Filter
          items={dropdownItems}
          selectedValue={dropDownValue}
          disabled={!searchQuery}
          variables={{ dropdownWidth: '120px' }}
          onSelectedChange={this.onDropdownChange}
        />
      </div>
    );
  };

  renderGettyBody = () => {
    const { gettyImagesList, selectedImage } = this.state;
    return gettyImagesList.length
      ? (
        <PluginGridWithPreview
          images={gettyImagesList}
          selectedImage={selectedImage}
          onImageError={this.onImageError}
          blockType={BLOCK_TYPES.GETTY}
          onGridImageClick={this.onGridImageClick}
          onImageChoose={this.onImageChoose}
          removePreviewImage={this.removePreviewImage}
        />
      )
      : null;
  };

  renderImageDescription = () => {
    const { caption, credit, alt } = this.state;
    const { shouldShowAuditAndTags } = this.props;
    return (
      <ImageDescription
        shouldShowAuditAndTags={shouldShowAuditAndTags}
        caption={caption}
        credit={credit}
        alt={alt}
        isCreditEditingEnabled={false}
        onChangeCaption={this.onChangeCaption}
        onChangeAlt={this.onChangeAlt}
        onKeyDown={this.onCaptionInputKeyDown}
      />
    );
  };

  renderFullBleedImageTogglerIfNeedTo = () => {
    const { sizeType } = this.state;
    const { shouldAllowHorizontalCropOnly } = this.props;
    const shouldRenderFullBleedImageToggler = !shouldAllowHorizontalCropOnly;
    return shouldRenderFullBleedImageToggler ? (
      <FullBleedImageToggler
        checked={sizeType === IMAGE_SIZE_TYPES.FULL_BLEED}
        onToggleChange={() => {
          this.setState({ sizeType: sizeType === IMAGE_SIZE_TYPES.REGULAR
            ? IMAGE_SIZE_TYPES.FULL_BLEED
            : IMAGE_SIZE_TYPES.REGULAR,
          });
        }}
      />
    ) : null;
  };

  onLinkableImageDataChange = data => {
    if (validateLinkableImage(data.targetLink)) {
     this.setLinkableImageLinkError('');
    }
    return this.setLinkableImageSettings(data);
  };

  renderLinkableImageSettings = () => {
    const { isImageLinkable, linkableImageSettings, linkableImageLinkError } = this.state;
    const { shouldAllowHorizontalCropOnly } = this.props;
    return (
      !shouldAllowHorizontalCropOnly && (
      <LinkableImageSettings
        checked={isImageLinkable}
        onToggleChange={this.setIsImageLinkable}
        onLinkableImageDataChange={this.onLinkableImageDataChange}
        linkableImageSettings={linkableImageSettings}
        linkableImageLinkError={linkableImageLinkError}
      />
)
    );
  };

  renderImageConfigurationIfNeedTo = () => {
    const { selectedImage, imageLoading } = this.state;
    const shouldRenderImageConfiguration = !!selectedImage.previewImageUrl && !imageLoading;
    return shouldRenderImageConfiguration ? (
      <Fragment>
        {this.renderImageDescription()}
        {this.renderLinkableImageSettings()}
        {this.renderFullBleedImageTogglerIfNeedTo()}
      </Fragment>
    ) : null;
  };

  renderCroppingArea = () => {
    const { shouldAllowHorizontalCropOnly } = this.props;
    const { selectedImage, imageLoading, percentageCrop, cropAspectType } = this.state;
    const { editImageContainer } = getStylesObject();
    return (
      <div css={css(editImageContainer)}>
        <CroppingAreaCustomization
          imageLoading={imageLoading}
          hasImage
          src={selectedImage.previewImageUrl}
          percentageCrop={percentageCrop}
          cropAspectType={cropAspectType}
          setCompletedCrop={this.setCompletedCrop}
          onCropAspectTypeChange={this.onCropAspectTypeChange}
          onImageLoaded={this.onImageLoaded}
          shouldAllowHorizontalCropOnly={shouldAllowHorizontalCropOnly}
          backButton
          backButtonOnClick={this.onBackButtonClick}
        />
        {this.renderImageConfigurationIfNeedTo()}
      </div>
    );
  };

  render() {
    const { onCancel } = this.props;
    const { mode, imageLoading } = this.state;
    const isGettyMode = mode === PANEL_MODES.GETTY;
    return (
      <Plugin.Container>
        <Plugin.Content>
          {isGettyMode ? this.renderGettyPanel() : this.renderCroppingArea()}
        </Plugin.Content>
        <Plugin.Buttons onCancelClick={onCancel} isCancelDisabled={imageLoading} {...this.getFooterPluginButtonsProps(isGettyMode)} />
      </Plugin.Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  openPanel: openGettyPanel,
  searchImage: searchGettyImages,
  imageSelected: gettyImageSelected,
}, dispatch);

export const GettyPanel = connect(null, mapDispatchToProps)(GettyPanelComponent);

// Plugin Data

export const gettyEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<GettyTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<GettyPanel {...props} pluginName={GETTY_EMBED_PLUGIN_NAME} />),
  getPluginBlock: props => <ImageBlock {...props} />,
  getPluginOverviewBlock: props => (<ImageBlockOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.IMAGE,
};

export const gettyCreativeEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<GettyTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<GettyPanel {...props} pluginName={GETTY_CREATIVE_EMBED_PLUGIN_NAME} />),
  getPluginBlock: props => <ImageBlock {...props} />,
  getPluginOverviewBlock: props => (<ImageBlockOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.IMAGE,
};
