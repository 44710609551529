import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { isNullDate } from '@ftbpro/mm-admin-core-utils';
import { HTTP_STATUS_CODES } from 'core/network/mmServicesApiProvider';
import { pushGlobalNotificationActionCreator } from 'core/globalNotifications/store/globalNotifications.actions';
import { AdministrationServiceConfigsProvider } from '../../services/administrationServiceConfigsProvider';
import { networkAction } from '../../middleware/networkMiddleware/networkMiddleware.actions';
import { INITIAL_STATE } from './user.constants';
import { AppDispatch } from '../index';
import { UserDataDetailsFromServer } from './types';
import { getUserOrgId, formatUserRoles, foramtUserPremissions } from './user.utils';

export const FETCH_ERROR_TEXT = 'Sorry, we couldn\'t load user details';
export const NOT_AUTORIZED_TEXT = 'You are not authorized to do this action';
export const LOGOUT = 'Log Out';
const SLICE_NAME = 'user';

const userSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.rawTokenData = action.payload;
    },
    fetchLoggedUserDetailsPending(state) {
      state.isUserDataLoaded = false;
    },
    fetchLoggedUserDetailsSuccess(state, action: PayloadAction<UserDataDetailsFromServer>) {
      const { name, email, id, username, picture, registration, createdAt, roles, organization } = action.payload;
      const userRoles = formatUserRoles(roles);
      state.userDetails = {
        name,
        userId: id,
        email,
        username,
        picture,
        registrationDate: isNullDate(registration) ? createdAt : registration,
      };
      state.isUserDataLoaded = true;
      state.userRoles = userRoles;
      state.orgId = getUserOrgId(organization, userRoles);
      state.voltaxUserPremissions = foramtUserPremissions(roles);
    },
    fetchLoggedUserDetailsFailure(state) {
      state.isUserDataLoaded = true;
    },
  },
});

export const fetchLoggedUserDetails = () => async (dispatch: AppDispatch) => {
  await dispatch(networkAction({
    url: AdministrationServiceConfigsProvider.getAdministrationServiceUserDetailsEndpoint(),
    onPending: fetchLoggedUserDetailsPending,
    onSuccess: fetchLoggedUserDetailsSuccess,
    onFailure: () => {
      dispatch(pushGlobalNotificationActionCreator({
        text: FETCH_ERROR_TEXT,
        status: SNACK_BAR_STATUSES.ERROR,
        buttonText: LOGOUT,
        showLogout: true,
      }));
      return { type: fetchLoggedUserDetailsFailure.type };
    },
  }));
};

export const notifyTokenExpiredIfNeedTo = (error: { code: number }) => (dispatch: AppDispatch) => {
  if (error && error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
    dispatch(pushGlobalNotificationActionCreator({
      text: NOT_AUTORIZED_TEXT,
      status: SNACK_BAR_STATUSES.ERROR,
      buttonText: LOGOUT,
      showLogout: true,
    }));
  }
};
export const {
  setToken,
  fetchLoggedUserDetailsPending,
  fetchLoggedUserDetailsSuccess,
  fetchLoggedUserDetailsFailure,
} = userSlice.actions;

export default userSlice.reducer;
