import React, {
  useState,
  useCallback,
  Fragment,
} from 'react';
import { Icon, ImagePicker } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/core';
import 'react-image-crop/dist/ReactCrop.css';
import { UploadIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from '../Plugin/Plugin';
import { CroppingAreaCustomization } from '../../shared/croppingArea/CroppingAreaCustomization';
import { ImageBlock, ImageBlockOverview } from '../shared/ImageBlock';

import {
  CROP_ASPECT_TYPES,
  CROP_ASPECT_NAME_TO_VALUE,
  validateImageFile,
  removeForbiddenCharsFromImageName,
  getInitialPercentageCrop,
  substringImageNameFromUrl,
  getCropAspectName,
  removeFileExtension, formatImgLinkObject, validateLinkableImage,
} from '../../../services/imageServices/imageService.utils';
import { removeLineBreaks } from '../../utils/inlineText.utils';
import { pluginAddButtonTextHandler } from '../../utils/plugins.utils';

import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';
import { USER_UPLOAD_PROVIDER } from '../../components/elementPanel/elementPanel.constants';

import { getStylesObject } from '../../shared/croppingArea/styles/croppingArea.styles';
import { ImageDescription } from '../../shared/ImageDescription';
import { FullBleedImageToggler } from '../../shared/FullBleedImageToggler';
import { IMAGE_SIZE_TYPES } from '../../../constants/image.constants';
import { isEnterPressed } from '../../../../../core/utils/keyboard.utils';
import { LinkableImageSettings } from '../../shared/LinkableImageSettings';

const UPLOAD_IMAGE_PLACEHOLDER = 'Click to upload image';
const LINKABLE_IMAGE_URL_ERROR = 'URL must start with \'http://\' or \'https://\'';
const CREDIT_MIN_LENGTH = 3;

// Element-Panel Top Bar Button

export const ImageFromDriveTopBarButtonIcon = props => {
  return (
    <Icon icon={UploadIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component
export const EditImagePanelComponent = props => {
  const {
    shouldAllowHorizontalCropOnly,
    editedBlockData,
    isEditingBlock,
    username,
    onAdd,
    onCancel,
    shouldShowAuditAndTags,
  } = props;

  const getSizeType = () => {
    if (!isEditingBlock) {
      return IMAGE_SIZE_TYPES.REGULAR;
    }
    return (
      editedBlockData.value.sizeType === IMAGE_SIZE_TYPES.REGULAR
        ? IMAGE_SIZE_TYPES.REGULAR : IMAGE_SIZE_TYPES.FULL_BLEED);
  };

  const styles = getStylesObject();
  const [sizeType, setSizeType] = useState(getSizeType());
  const [src, setSrc] = useState(isEditingBlock ? editedBlockData.value.fullImageUrl : '');
  const [percentageCrop, setPercentageCrop] = useState({ aspect: isEditingBlock ? CROP_ASPECT_NAME_TO_VALUE[editedBlockData.value.aspectRatio] : CROP_ASPECT_TYPES.HORIZONTAL });
  const [currentImageName, setCurrentImageName] = useState('');
  const [caption, setCaption] = useState(isEditingBlock ? editedBlockData.value.caption : '');
  const [credit, setCredit] = useState(isEditingBlock ? editedBlockData.value.credit : '');
  const [alt, setAlt] = useState(isEditingBlock ? editedBlockData.value.alt : '');
  const [audit, setAudit] = useState('');
  const [tags, setTags] = useState([]);
  const [imageName, setImageName] = useState('');
  const [importImageError, setImportImageError] = useState(null);
  const [currentlyEditedImage, setCurrentlyEditedImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(isEditingBlock);
  const [isCreditEditingEnabled, setIsCreditEditingEnabled] = useState(isEditingBlock && editedBlockData.value.provider === USER_UPLOAD_PROVIDER);
  const [isImageLinkable, setIsImageLinkable] = useState(!!editedBlockData?.value.linkURL);
  const [linkableImageSettings, setLinkableImageSettings] = useState({
    targetLink: editedBlockData?.value.linkURL || '',
    shouldOpenNewTab: !!editedBlockData?.value.linkTargetAttribute,
    shouldNoFollow: !!editedBlockData?.value.linkRelAttribute,
  });
  const [linkableImageLinkError, setLinkableImageLinkError] = useState('');
  const [dimensions, setDimensions] = useState({});
  const hasAlt = !!alt;
  const hasCredit = !!credit && credit.length >= CREDIT_MIN_LENGTH;

  const toggleImageLinkableSetting = () => {
    setLinkableImageLinkError('');
    setIsImageLinkable(!isImageLinkable);
  };

  const onImageChange = image => {
    if (image) {
      const { imageData } = image;
      const fileName = removeFileExtension(imageData.file.name);
      setImageName(imageData.file.name);
      setCurrentImageName(removeForbiddenCharsFromImageName(fileName));
      setPercentageCrop({ aspect: percentageCrop.aspect });
      validateImageFile(image)
        .then(source => {
          setSrc(source);
          setImportImageError(null);
          setIsCreditEditingEnabled(true);
          setDimensions({ width: imageData.width, height: imageData.height, size: imageData.file.size });
        })
        .catch(errorMsg => {
          setImportImageError(errorMsg);
          setSrc('');
          setDimensions({});
        });
    } else {
      setImportImageError(null);
    }
    setSrc('');
    setCurrentImageName('');
    setDimensions({});
  };

  const setCompletedCrop = newPercentageCrop => setPercentageCrop(newPercentageCrop);

  const onCropAspectTypeChange = type => {
    if (currentlyEditedImage) {
      const newPercentageCrop = getInitialPercentageCrop(type);
      setCompletedCrop(newPercentageCrop);
    }
  };

  const onImageLoaded = useCallback(image => {
    setCurrentlyEditedImage(image);
    setImageLoading(false);
  }, []);

  const onChangeCaption = (e, value) => setCaption(removeLineBreaks(value));
  const onChangeCredit = (e, value) => setCredit(removeLineBreaks(value));
  const onChangeAlt = (e, value) => setAlt(removeLineBreaks(value));
  const onChangeAudit = (e, value) => setAudit(removeLineBreaks(value));

  const handleAddTag = ({ tagToAdd }) => {
    if (!tags.includes(tagToAdd)) {
      setTags([...tags, tagToAdd]);
    }
  };

  const handleRemoveTag = ({ tagToRemove }) => setTags(tags.filter(tag => tag !== tagToRemove));

  const getProvider = () => (editedBlockData ? editedBlockData.value.provider : USER_UPLOAD_PROVIDER);

  const getData = () => {
    const name = currentImageName || substringImageNameFromUrl(src);
    const { linkURL, linkTargetAttribute, linkRelAttribute } = formatImgLinkObject({ linkableImageSettings });
    return {
      name,
      src,
      crop: percentageCrop,
      caption,
      aspectRatio: getCropAspectName(percentageCrop.aspect, true),
      credit,
      sizeType,
      alt,
      isEditingBlock,
      provider: getProvider(),
      mediaLibraryData: {
        username,
        fileType: editedBlockData?.value?.fileExtension || '',
        path: editedBlockData?.value?.path || '',
        tags,
        audit,
        imageName: imageName || name,
        dimensions,
      },
      ...(isImageLinkable && { linkURL, linkTargetAttribute, linkRelAttribute }),
    };
  };

  const onAddHandler = () => {
    const isValidLinkableUrl = isImageLinkable && validateLinkableImage(linkableImageSettings.targetLink);

    if ((!isImageLinkable || isValidLinkableUrl) && hasAlt && hasCredit) {
      return onAdd(getData());
    }
    return setLinkableImageLinkError(LINKABLE_IMAGE_URL_ERROR);
  };

  const onCaptionInputKeyDown = e => {
    if (isEnterPressed(e)) {
      onAddHandler();
    }
  };

  const getImageForCrop = () => src || '';

  const renderImageDescription = () => {
    return (
      <ImageDescription
        shouldShowAuditAndTags={shouldShowAuditAndTags}
        caption={caption}
        credit={credit}
        alt={alt}
        audit={audit}
        tags={tags}
        isCreditEditingEnabled={isCreditEditingEnabled}
        onChangeCredit={onChangeCredit}
        onChangeCaption={onChangeCaption}
        onChangeAlt={onChangeAlt}
        onChangeAudit={onChangeAudit}
        handleAddTag={handleAddTag}
        handleRemoveTag={handleRemoveTag}
        onKeyDown={onCaptionInputKeyDown}
        isEditingBlock={isEditingBlock}
      />
    );
  };

  const renderFullBleedImageTogglerIfNeedTo = () => {
    const shouldRenderFullBleedImage = !shouldAllowHorizontalCropOnly;
    return shouldRenderFullBleedImage ? (
      <FullBleedImageToggler
        checked={sizeType === IMAGE_SIZE_TYPES.FULL_BLEED}
        onToggleChange={() => {
          setSizeType(sizeType === IMAGE_SIZE_TYPES.REGULAR
            ? IMAGE_SIZE_TYPES.FULL_BLEED
            : IMAGE_SIZE_TYPES.REGULAR);
        }}
      />
      ) : null;
  };

  const onLinkableImageDataChange = data => {
    if (validateLinkableImage(data.targetLink)) {
      setLinkableImageLinkError('');
    }
    return setLinkableImageSettings(data);
  };

  const renderLinkableImageSettings = () => {
    return (
      !shouldAllowHorizontalCropOnly && (
        <LinkableImageSettings
          checked={isImageLinkable}
          onToggleChange={toggleImageLinkableSetting}
          onLinkableImageDataChange={onLinkableImageDataChange}
          linkableImageSettings={linkableImageSettings}
          linkableImageLinkError={linkableImageLinkError}
        />
      )
    );
  };

  const renderImageConfigurationIfNeedTo = () => {
    const hasImage = !!src;
    const shouldRenderImageConfiguration = hasImage && !imageLoading;
    return shouldRenderImageConfiguration
      ? (
        <Fragment>
          {renderImageDescription()}
          {renderLinkableImageSettings()}
          {renderFullBleedImageTogglerIfNeedTo()}
        </Fragment>
      ) : null;
  };

  const renderImageArea = () => {
    const hasImage = !!src;
    return (
      <Fragment>
        <CroppingAreaCustomization
          imageLoading={imageLoading}
          hasImage={hasImage}
          src={getImageForCrop()}
          percentageCrop={percentageCrop}
          setCompletedCrop={setCompletedCrop}
          onCropAspectTypeChange={onCropAspectTypeChange}
          onImageLoaded={onImageLoaded}
          shouldAllowHorizontalCropOnly={shouldAllowHorizontalCropOnly}
          hasOriginalSize
        />
        {renderImageConfigurationIfNeedTo()}
      </Fragment>
    );
  };

  const hasImage = !!src;

  return (
    <Plugin.Container>
      <Plugin.Content>
        {isEditingBlock ? null : (
          <div css={css(styles.imagePickerWrapper)}>
            <ImagePicker
              buttonText={UPLOAD_IMAGE_PLACEHOLDER}
              fileName={currentImageName}
              accept=".jpg, .png, .jpeg, .webp"
              onFileChange={onImageChange}
            />
          </div>
        )}
        <div css={css(styles.editImageContainer)}>
          {importImageError ? <Plugin.ErrorMsgComponent text={importImageError} /> : renderImageArea()}
        </div>
      </Plugin.Content>
      <Plugin.CopyrightInformation />
      <Plugin.Buttons onAddClick={onAddHandler} onCancelClick={onCancel} isAddDisabled={!hasImage || !hasAlt || !hasCredit} addButtonText={pluginAddButtonTextHandler(editedBlockData)} />
    </Plugin.Container>
  );
};

// Plugin Data

export const imageFromDrivePluginData = {
  getPluginTopBarButtonIcon: props => (<ImageFromDriveTopBarButtonIcon {...props} />),
  getPluginPanelComponent: props => (<EditImagePanelComponent {...props} />),
  getPluginBlock: props => <ImageBlock {...props} />,
  getPluginOverviewBlock: props => (<ImageBlockOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.IMAGE,
};
