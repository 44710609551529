export const mediaOverlayStyles = {
  wrapper: {
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'none',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px',
  },
  button: {
    marginBottom: '8px',
  },
};
