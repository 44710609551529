import { HTTP_METHODS, mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { InThisStoryDataProvider } from './inThisStoryServiceConfigsProvider';

export class InThisStoryService {
  static getInThisStoryByOrganization(organization: string) {
    const configs = {
      method: HTTP_METHODS.GET,
    };
    return mmNetworkService.fetch(InThisStoryDataProvider.getInThisStoryByOrganization(organization), configs);
  }
}
