export const INTRO_PLACEHOLDER = 'Write here the intro... (Optional)';

export const EMPTY_INTRO_INITIAL_VALUE = '<p></p>';

export const COMMENTS_BUTTON_CLASS_NAME = 'comments-icon';

export const LIST_ITEM_CLASS_NAME = 'list-item';

export const INTRO_MAX_LENGTH = 1500;

export const ERROR_MESSAGE = 'You have reached the maximum number of characters';
