import { routerMiddleware } from 'connected-react-router';
import { AnyAction, getDefaultMiddleware, Middleware, MiddlewareArray, Reducer, ThunkAction } from '@reduxjs/toolkit';
import { NetworkAction } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import { ThunkMiddleware } from 'redux-thunk';
import { updateBackgroundTask, addBackgroundTask, removeBackgroundTask } from 'core/backgroundTasks/store/backgroundTasksSlice';
import { history } from '../routing/history';
import * as CustomMiddlewares from './rootMiddleware';
import { configureDynamicStore } from './dynamicStore';

const middleware: MiddlewareArray<ThunkMiddleware|Middleware> = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [...Object.values(NetworkAction), updateBackgroundTask.type, addBackgroundTask.type, removeBackgroundTask.type],
  },
})
.concat(
  CustomMiddlewares.networkMiddleware,
  CustomMiddlewares.editorAnalyticsMiddleware,
  CustomMiddlewares.echoAnalyticsMiddleware,
  CustomMiddlewares.contributorsAnalyticsMiddleware,
  CustomMiddlewares.reportsAnalyticsMiddleware,
  routerMiddleware(history),
);

export const store = configureDynamicStore(
  history,
  middleware,
);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  undefined,
  AnyAction
>

export const injectReducer = (name: string, reducer: Reducer) => store.injectReducer(name, reducer);
