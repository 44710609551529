import { subDaysFromDate, subHoursFromDate } from '../../../../core/utils/date.utils';
import {
  DATE_RANGE_FILTER_VALUES,
  DISPLAYING_FILTER_VALUES,
  FEED_FILTER_VALUES,
  STATUS_FILTER_VALUES,
} from '../constants/echoTable.constants';
import { ECHO_DASHBOARD_PAGE_SIZE } from '../constants/echo.constants';

export const getDisplayingQueryParam = displaying => {
  return displaying === DISPLAYING_FILTER_VALUES.ARCHIVED ? 'hidden=true' : '';
};

export const getSearchQueryParam = search => {
  return search ? `search=${search}` : '';
};

export const getStatusQueryParam = status => {
  const STATUS_SERVER_VALUES = {
    [STATUS_FILTER_VALUES.DASHBOARD.ALL_STATUSES]: '',
    [STATUS_FILTER_VALUES.DASHBOARD.SCHEDULED]: 'status=scheduled',
    [STATUS_FILTER_VALUES.DASHBOARD.SUBMITTED]: 'status=submitted',
    [STATUS_FILTER_VALUES.DASHBOARD.PUBLISHED]: 'status=published',
    [STATUS_FILTER_VALUES.DASHBOARD.FAILED]: 'status=failed',
  };
  return STATUS_SERVER_VALUES[status];
};

export const getDateRequestFields = dateRange => {
  if (dateRange) {
    const now = (new Date()).toISOString();
    const DATE_RANGE_SERVER_VALUES = {
      [DATE_RANGE_FILTER_VALUES.DASHBOARD.LAST_48_HOURS]: {
        from: `dateRangeFrom=${subHoursFromDate(new Date(), 48).toISOString()}`,
        to: `dateRangeTo=${now}`,
      },
      [DATE_RANGE_FILTER_VALUES.DASHBOARD.LAST_7_DAYS]: {
        from: `dateRangeFrom=${subDaysFromDate(new Date(), 7).toISOString()}`,
        to: `dateRangeTo=${now}`,
      },
      [DATE_RANGE_FILTER_VALUES.DASHBOARD.LAST_30_DAYS]: {
        from: `dateRangeFrom=${subDaysFromDate(new Date(), 30).toISOString()}`,
        to: `dateRangeTo=${now}`,
      },
      [DATE_RANGE_FILTER_VALUES.DASHBOARD.LAST_90_DAYS]: {
        from: `dateRangeFrom=${subDaysFromDate(new Date(), 90).toISOString()}`,
        to: `dateRangeTo=${now}`,
      },
      [DATE_RANGE_FILTER_VALUES.DASHBOARD.ALL_TIME]: {},
    };
    return DATE_RANGE_SERVER_VALUES[dateRange];
  }
  return {};
};

export const getFeedQueryParam = (selectedFeedName, availableFeeds) => {
  if (selectedFeedName && availableFeeds) {
    const FEED_SERVER_VALUES = {
      [FEED_FILTER_VALUES.ALL_FEEDS]: '',
    };
    availableFeeds.forEach(feed => {
      FEED_SERVER_VALUES[feed.name] = `topic=${feed.slug}`;
    });
    return FEED_SERVER_VALUES[selectedFeedName];
  }
  return '';
};

const LABELS_QUERY_PARAMS = {
  'Any of these labels': 'labelsAny',
  'All of these labels': 'labelsAll',
};

export const getLabelsQueryParam = (labels, includeLabelsMethod) => {
  const method = LABELS_QUERY_PARAMS[includeLabelsMethod];
  if (!labels?.length) {
    return '';
  }
  return `${method}=${labels.join(',')}`;
};

export const getPostsQueryString = ({ displaying, status, dateRange, feed, search, includeLabels, includeLabelsMethod }, availableFeeds) => {
  const dateRequestFields = getDateRequestFields(dateRange);
  const FILTERS_QUERY_OBJECT = {
    displaying: getDisplayingQueryParam(displaying),
    status: getStatusQueryParam(status),
    ...dateRequestFields,
    limit: `limit=${ECHO_DASHBOARD_PAGE_SIZE}`,
    feed: getFeedQueryParam(feed, availableFeeds),
    search: getSearchQueryParam(search),
    labels: getLabelsQueryParam(includeLabels, includeLabelsMethod),
  };
  return Object.values(FILTERS_QUERY_OBJECT).filter(queryParam => queryParam).join('&');
};
