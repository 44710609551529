export const EDITOR_DASHBOARD_TEXTS = {
  HEADER_TITLE: 'Editor Dashboard',
};

export const PUSH_NOTIFICATION_TEXTS = {
  DELETED_DRAFT: 'Draft was deleted',
  COPY_TO_CLIPBOARD: 'Successfully copied to clipboard',
};

export const EDITOR_DASHBOARD_VIEW_TYPES = {
  DRAFTS: 'Drafts',
  SUBMITTED: 'Submitted',
  PUBLISHED: 'Published',
};

export const CREATE_NEW_DRAFT_BUTTON_TEXT = 'Write an article';

export const NO_TITLE_MESSAGE = 'Untitled';

export const NO_DATA_MESSAGE = 'You have things to say, and the world is waiting to hear them. How about creating an article?';

export const EDITOR_LOCAL_STORAGE_VALUES = {
  LOCALE: 'editorLocale',
  SITE: 'editorSite',
};

export const AUTHORS_FILTER_PLACEHOLDER = 'All Authors';
