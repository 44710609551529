export const GIPHY_OEMBED_URL_PREFIX = 'https://giphy.com/services/oembed?url=';

export const SEARCH_GIPHY_PLACEHOLDER = 'Search for a GIF';

export const URL_BROKEN_TEXT = 'The URL you are using is not from Giphy. Please paste a correct one.';

export const GIPHY_DEFAULT_OFFSET = 30;

export const LOAD_MORE_TEXT = 'Load more images';

export const GIPHY_SEARCH_MODES = {
  URL: 'URL',
  SEARCH: 'SEARCH',
};

export const GIPHY_RADIO_OPTIONS = [
  {
    label: 'URL',
    value: GIPHY_SEARCH_MODES.URL,
  },
  {
    label: 'Search',
    value: GIPHY_SEARCH_MODES.SEARCH,
  },
];

export const GIPHY_ERROR_MESSAGES = {
  NO_DATA: 'Sorry, we did not find gif images',
  NO_SEARCH_QUERY: 'Please, provide the search query',
  NO_MORE_IMAGES: 'Sorry, there are no more gif images',
};
