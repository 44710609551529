import { AggFuncType, RowData, TableNewColDef } from '@ftbpro/mm-admin-ui-components';
import { DateType } from '../../pages/components/DateRangeSetup/DateRangeSetup';

export interface QueryBuilderSliceState {
  loading: boolean;
  isSaved: boolean;
  queryParams: QueryParams;
  reportsList: ReportConfigID[];
  dataSourceList: DataSource[];
  dimensionsColumnDefs: TableNewColDef[];
  availableDimensions: Dimensions;
  availableMetrics: Metrics;
  data: RowData[];
  scheduleParams: ScheduleParams;
  tableStatus: TableStatus;
  scheduledReports: ScheduleParams[];
  reportCreatorID?: string;
  isAPIKeyExist: boolean,
  APIKey: string,
  openGenerateAPIKeyModal: boolean,
  openRegenerateAPIKeyModal: boolean,
}

export interface QueryParams {
  dataSourceID: string;
  dimensions: string[];
  metrics: string[];
  dateType: DateType;
  startDate: string;
  endDate: string;
  filters: Array<Filter>;
  constraints: Array<Constraint>;
  timeZone: string;
  reportName: string;
  reportConfigID: string;
}

export interface LoadReportParams {
  dataSourceID: string;
  dimensions: string[];
  metrics: string[];
  dateRange: DateRange;
  filters: Array<Filter>;
  constraints: Array<Constraint>;
  timezone: string;
  name: string;
  id: string;
  userID: string;
}

export type Filter = {
  id: string;
  field: string;
  include: boolean;
  type: FilterType;
  data: string[];
};

export enum FilterType {
  ExactlyMatching = 'Exactly matching',
  StartsWith = 'Starts with',
  Contains = 'Contains',
  IsNull = 'Is null',
  In = 'In',
  Regex = 'Regex',
}

export type Constraint = {
  id: string;
  field: string;
  include: boolean;
  type: ConstraintType;
  data: string[];
};

export type ReportConfigID = {
  id: string;
  name: string;
};

export type ConstraintType =
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'eq'
  | 'between';

export type DisplayConstraintType =
  | '>'
  | '=>'
  | '<'
  | '<='
  | '='
  | 'Between';

export type TimeZoneType =
  'New York (Eastern)'
  | 'Phoenix (MST)'
  | 'Los Angeles (Pacific)'
  | 'London'
  | 'Berlin'
  | 'Bangkok'
  | 'Sydney'
  | 'UTC-12'
  | 'UTC-11'
  | 'UTC-10'
  | 'UTC-9'
  | 'UTC-8'
  | 'UTC-7'
  | 'UTC-6'
  | 'UTC-5'
  | 'UTC-4'
  | 'UTC-3'
  | 'UTC-2'
  | 'UTC-1'
  | 'UTC'
  | 'UTC+1'
  | 'UTC+2'
  | 'UTC+3'
  | 'UTC+4'
  | 'UTC+5'
  | 'UTC+6'
  | 'UTC+7'
  | 'UTC+8'
  | 'UTC+9'
  | 'UTC+10'
  | 'UTC+11'
  | 'UTC+12'
  | 'UTC+13'
  | 'UTC+14';

export type DataSource = {
  id: string;
  connection: string;
  caption: string;
  lastXhoursFilter: string;
  'lastXhoursFilter.standard': string;
  dataResolutionHours: boolean;
  standardSql: boolean;
  dimensions: Dimensions;
  metrics: Metrics;
  orderBy: string;
};

export type Dimensions = {
  [dimensionName: string]: {
    sql: string;
    type: string;
    caption: string;
  };
};

export type Metrics = {
  [metricName: string]: {
    sql: string;
    type: string;
    caption: string;
    hint: string;
    summaryType: string;
    sqlDvlp: string;
    aggregation?: AggFuncType
  };
};

export type ReportRequest = {
  dataSourceID: string;
  dimensions: Array<string>;
  metrics: Array<string>;
  dateRange: DateRange;
  filters: Array<Filter>;
  constraints: Array<Constraint>;
  noiseReduction: boolean;
  noiseReductionConfig: string;
  timezone: string;
  limit: number;
  name?: string;
  orderBy: string;
};

export type ScheduleRequest = {
  reportConfigID: string;
  reportName: string;
  emailAddresses: Array<string>;
  description: string;
  isActive: boolean;
  schedulePeriod: SchedulePeriod;
  scheduleTime: string;
  timeZone: string;
};

export type ReportSaveResponse = {
  id: string;
};

export type ScheduleParams = {
  reportConfigID: string;
  reportName: string;
  emailAddresses: Array<string>;
  description: string;
  isActive: boolean;
  schedulePeriod: SchedulePeriod | '';
  scheduleTime: string;
  id? : string;
  next?: string;
  previous?: string;
  timeZone: string;
};

export type DateRange = {
  field: string;
  type: DateType;
  from: string;
  to: string;
};

export enum TableStatus {
  CurrentReport = 'Current report',
  ScheduleReports = 'Schedule reports',
}

export type SaveStatus = 'Save' | 'Save As';

export type SchedulePeriod = 'Hourly' | 'Daily' | 'Sundays' | 'Weekly' | 'Monthly';

export type Status = 'success' | 'archived';

export type ScheduleReportStatus = 'Active' | 'Paused';

export type ReportConfig = {
  id: string;
  name: string;
}
