import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { closeElementPanel, openElementPanelAtPanelType } from '../elementPanel/elementPanel.actions';
import {
  pushGlobalNotificationActionCreator,
} from '../../../../../core/globalNotifications/store/globalNotifications.actions';
import { blockListSelector } from './blockList.selector';
import { getScrollManager } from '../../../../../core/scrollManager/scrollManager';
import {
  didStartTypingInTextBlock,
  getBlockByKey,
  isBlockKeyInsideListBlock,
} from '../../utils/blockList.utils';
import { mediaTypeToPanelType } from '../../plugins/pluginConfiguration';
import { ELEMENT_PANEL_CONTEXT } from '../../components/elementPanel/elementPanel.constants';
import { elementPanelSelector } from '../elementPanel/elementPanel.selector';
import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';
import { deleteCommentsListForEntity } from '../comments/comments.actions';
import { TABLE_BLOCK_EVENTS } from '../../components/blockList/systemBlocks/TableBlock/tableBlock.strings';

// alphabetical order for now
export const DISCARD_OVERVIEW_MODE_CHANGES = '[EDITOR] discard overview mode changes';
export const INSERT_BLOCK_DIVIDER = '[EDITOR] is inserting new block divider';
export const INSERT_BLOCK_ADDER_BEFORE_BLOCK = '[EDITOR] insert new block adder after block';
export const INSERT_DATA_BLOCK = '[EDITOR] is inserting new data block';
export const INSERT_LIST_ITEM_BLOCKS = '[EDITOR] is inserting new list item blocks';
export const INSERT_FAQ_BLOCKS = '[EDITOR] is inserting new faq blocks';
export const INSERT_PAGE_BREAK = '[EDITOR] is inserting page break';
export const INSERT_QUOTE_ITEM_BLOCK = '[EDITOR] is inserting new quote item block';
export const INSERT_TABLE_BLOCK = '[EDITOR] is inserting new table block';
export const INSERT_TABLE_OF_CONTENTS_BLOCK = '[EDITOR] is inserting new table of contents block';
export const INSERT_BLOCK_ADDER_ABOVE_TEXT_BLOCK = '[EDITOR] inserts a block adder above a block key';
export const INSERT_BLOCK_ADDER_BELOW_GROUPED_BLOCK = '[EDITOR] insert a block adder below a block key';
export const INSERT_BLOCK_ADDER_BELOW_TEXT_BLOCK = '[EDITOR] inserts a block adder below a block key';
export const ON_BLOCKLIST_SORT_END = '[EDITOR] user ended to drag block';
export const ON_BLOCKLIST_SORT_START = '[EDITOR] user started to drag block';
export const REMOVE_BLOCK = '[EDITOR] new block deleted';
export const REMOVE_BLOCK_ADDERS = '[EDITOR] removes block adders from block list';
export const REPLACE_TEXT_BLOCK_WITH_BLOCK_ADDER = '[EDITOR] user deleted last char in text block';
export const REPLACE_BLOCK = '[EDITOR] replace a block with a new one';
export const REPLACE_BLOCK_WITH_TEXT_BLOCKS = '[EDITOR] replace block with text blocks';
export const SET_CURRENTLY_ACTIVE_BLOCK_KEY = '[EDITOR] set currently active block key';
export const SET_BLOCK_KEY_FOR_INSERTION = '[EDITOR] set block key for insertion';
export const SORT_LIST_ITEM_BLOCKS = '[EDITOR] sort list item blocks';
export const TOGGLE_OVERVIEW_MODE = '[EDITOR] toggle draft overview mode';
export const TOGGLE_OFF_BLOCK_ADDER_REPLACEMENT_INDICATION = '[Editor] toggle off indication for text block replacing a block adder';
export const UPDATE_BLOCK_LIST_DATA = '[EDITOR] update block list data';
export const UPDATE_BLOCK_VALUE = '[EDITOR] updating value on block';
export const GENERATE_MULTIPLE_TEXT_BLOCKS = '[EDITOR] generate multiple text blocks';
export const UPDATE_GROUPED_BLOCK_VALUE = '[EDITOR] updating value on block inside a group block';
export const SET_NEXT_AVAILABLE_TEXT_BLOCK_AS_CURRENTLY_ACTIVE = '[EDITOR] set the next text block as currently active';
export const DELETE_MEDIA_FROM_GROUPED_BLOCK = '[EDITOR] delete media block from grouped block';

export const REORDERING_MENU_NOTIFICATION_TEXT = 'You have entered the reordering menu. You can drag, drop and rearrange all blocks';

export const setBlockKeyForInsertion = key => dispatch => {
  dispatch({
    type: SET_BLOCK_KEY_FOR_INSERTION,
    payload: key,
  });
};

export const setCurrentlyActiveBlockKey = key => dispatch => {
  dispatch({
    type: SET_CURRENTLY_ACTIVE_BLOCK_KEY,
    payload: key,
  });
};

export const closeElementPanelAndSetCurrentlyActiveBlockKey = key => dispatch => {
  closeElementPanel()(dispatch);
  setCurrentlyActiveBlockKey(key || null)(dispatch);
};

const blockInsertionReset = () => dispatch => {
  closeElementPanel()(dispatch);
  setBlockKeyForInsertion(null)(dispatch);
};

export const removeBlockAdders = () => dispatch => {
  dispatch({
    type: REMOVE_BLOCK_ADDERS,
  });
};

export const replaceBlock = (blockKey, blockType, blockValue) => dispatch => {
  blockInsertionReset()(dispatch);
  dispatch({
    type: REPLACE_BLOCK,
    payload: {
      blockType,
      blockValue,
      blockKey,
    },
  });
};

export const insertBlockAdderBeforeBlock = (key, adderType) => dispatch => {
  blockInsertionReset()(dispatch);
  dispatch({
    type: INSERT_BLOCK_ADDER_BEFORE_BLOCK,
    payload: {
      key,
      adderType,
    },
  });
};

export const insertBlockDivider = blockKey => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_BLOCK_DIVIDER,
    payload: {
      blockKey,
    },
  });
};

export const insertPageBreak = blockKey => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_PAGE_BREAK,
    payload: {
      blockKey,
    },
  });
};

export const insertDataBlock = (blockKey, blockType, blockValue) => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_DATA_BLOCK,
    payload: {
      blockType,
      blockValue,
      blockKey,
    },
  });
};

export const insertListItemBlock = (blockKey, numberOfNewBlocksToGenerate) => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_LIST_ITEM_BLOCKS,
    payload: {
      blockKey,
      numberOfNewBlocksToGenerate,
    },
  });
};

export const insertFAQBlocks = (blockKey, numberOfNewBlocksToGenerate) => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_FAQ_BLOCKS,
    payload: {
      blockKey,
      numberOfNewBlocksToGenerate,
    },
  });
};

export const insertQuoteBlock = blockKey => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_QUOTE_ITEM_BLOCK,
    payload: {
      blockKey,
    },
  });
};

export const insertTableBlock = (blockKey, blockValue) => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_TABLE_BLOCK,
    payload: {
      blockKey,
      blockValue,
    },
  });
};

export const insertTableOfContentsBlock = blockKey => dispatch => {
  blockInsertionReset()(dispatch);
  setCurrentlyActiveBlockKey(null)(dispatch);
  dispatch({
    type: INSERT_TABLE_OF_CONTENTS_BLOCK,
    payload: {
      blockKey,
    },
  });
};

export const replaceTextBlockWithBlockAdder = blockKeyToReplace => dispatch => {
  dispatch({
    type: REPLACE_TEXT_BLOCK_WITH_BLOCK_ADDER,
    payload: {
      blockKeyToReplace,
    },
  });
};

export const onBlockListSortEnd = (oldIndex, newIndex) => dispatch => {
  dispatch({
    type: ON_BLOCKLIST_SORT_END,
    payload: {
      oldIndex,
      newIndex,
    },
  });
};

export const onBlockListSortStart = () => dispatch => {
  dispatch({
    type: ON_BLOCKLIST_SORT_START,
  });
};

export const removeBlock = blockKey => (dispatch, getState) => {
  const currentlyActiveBlockKey = blockListSelector.currentlyActiveBlockKey(getState());
  const blockData = blockListSelector.blockData(getState(), blockKey);
  if (currentlyActiveBlockKey === blockKey || isBlockKeyInsideListBlock(blockData, currentlyActiveBlockKey)) {
    closeElementPanelAndSetCurrentlyActiveBlockKey(null)(dispatch);
  }

  if (blockData.type === 'table') {
    dispatch({
      type: TABLE_BLOCK_EVENTS.TABLE_DELETED,
    });
  }

  deleteCommentsListForEntity(blockKey)(dispatch, getState);
  dispatch({
    type: REMOVE_BLOCK,
    payload: blockKey,
  });
};

export const replaceBlockWithTextBlocks = (blockKey, textBlockValues) => dispatch => {
  dispatch({
    type: REPLACE_BLOCK_WITH_TEXT_BLOCKS,
    payload: {
      blockKey,
      textBlockValues,
    },
  });
};

export const insertBlockAdderAboveTextBlock = (blockKey, textBlockValue) => dispatch => {
  dispatch({
    type: INSERT_BLOCK_ADDER_ABOVE_TEXT_BLOCK,
    payload: {
      blockKeyToReplace: blockKey,
      textBlockValue,
    },
  });
};

export const insertBlockAdderBelowTextBlock = (blockKey, textBlockValue) => dispatch => {
  dispatch({
    type: INSERT_BLOCK_ADDER_BELOW_TEXT_BLOCK,
    payload: {
      blockKeyToReplace: blockKey,
      textBlockValue,
    },
  });
};

export const insertBlockAdderBelowGroupedBlock = groupedBlockKey => dispatch => {
  dispatch({
    type: INSERT_BLOCK_ADDER_BELOW_GROUPED_BLOCK,
    payload: {
      groupedBlockKey,
    },
  });
};

export const updateBlockListData = data => ({
  type: UPDATE_BLOCK_LIST_DATA,
  payload: data,
});

export const onBlockValueChange = (value, blockKey) => (dispatch, getState) => {
  const blockList = blockListSelector.blockList(getState());
  const block = getBlockByKey(blockList, blockKey);
  if (didStartTypingInTextBlock(block, value)) {
    getScrollManager().scrollElementToTheMiddleOfTheScreenIfNeedsTo(blockKey);
  }
  dispatch({
    type: UPDATE_BLOCK_VALUE,
    payload: {
      value,
      blockKey,
    },
  });
};

export const splitTextBlockIntoMultipleBlocks = (multipleValues, blockKey) => dispatch => {
  dispatch({
    type: GENERATE_MULTIPLE_TEXT_BLOCKS,
    payload: {
      multipleValues,
      blockKey,
    },
  });
};

export const toggleDraftOverviewMode = isToggleViewActive => dispatch => {
  dispatch({
    type: TOGGLE_OVERVIEW_MODE,
    payload: isToggleViewActive,
  });
  return isToggleViewActive && dispatch(pushGlobalNotificationActionCreator({
    text: REORDERING_MENU_NOTIFICATION_TEXT,
    status: SNACK_BAR_STATUSES.INFO,
  }));
};

export const discardOverviewModeChanges = () => ({
  type: DISCARD_OVERVIEW_MODE_CHANGES,
});

export const onGroupedBlockValueChange = (value, blockKey, groupBlockDescriptorKey) => dispatch => {
  dispatch({
    type: UPDATE_GROUPED_BLOCK_VALUE,
    payload: {
      value,
      blockKey,
      groupBlockDescriptorKey,
    },
  });
};

export const sortListItemBlocks = ascending => dispatch => {
  dispatch({
    type: SORT_LIST_ITEM_BLOCKS,
    payload: ascending,
  });
};

export const setNextAvailableTextBlockAsCurrentlyActive = blockKey => dispatch => {
  dispatch({
    type: SET_NEXT_AVAILABLE_TEXT_BLOCK_AS_CURRENTLY_ACTIVE,
    payload: blockKey,
  });
};

export const onBlockContainingTextFieldFocused = blockKey => dispatch => {
  setCurrentlyActiveBlockKey(blockKey)(dispatch);
  setBlockKeyForInsertion(null)(dispatch);
  removeBlockAdders()(dispatch);
};

export const onBlockContainingTextFieldBlurred = blockKey => (dispatch, getState) => {
  const currentlyActiveBlockKey = blockListSelector.currentlyActiveBlockKey(getState());
  const blockKeyForInsertion = blockListSelector.blockKeyForInsertion(getState());
  if (currentlyActiveBlockKey === blockKey && blockKeyForInsertion !== blockKey) {
    setCurrentlyActiveBlockKey(null)(dispatch);
    setBlockKeyForInsertion(null)(dispatch);
  }
};

export const deleteMediaFromListBlock = (blockKey, groupBlockDescriptor) => (dispatch, getState) => {
  const currentlyActiveBlockKey = blockListSelector.currentlyActiveBlockKey(getState());
  const groupBlockData = blockListSelector.blockData(getState(), groupBlockDescriptor.key);

  if (currentlyActiveBlockKey === blockKey) {
    closeElementPanelAndSetCurrentlyActiveBlockKey(null)(dispatch);
  }
  dispatch({
    type: DELETE_MEDIA_FROM_GROUPED_BLOCK,
    payload: {
      blockKey,
      groupBlockKey: groupBlockDescriptor.key,
      groupBlockData,
    },
  });
};

export const editBlock = blockDescriptor => dispatch => {
  const panelToOpen = mediaTypeToPanelType[blockDescriptor.type];
  setCurrentlyActiveBlockKey(blockDescriptor.key)(dispatch);
  return openElementPanelAtPanelType({ context: ELEMENT_PANEL_CONTEXT.EDITING_BLOCK, panelToOpen, editedBlockData: blockDescriptor })(dispatch);
};

export const cancelMediaEdit = () => dispatch => {
  return closeElementPanelAndSetCurrentlyActiveBlockKey(null)(dispatch);
};

export const createBlock = (blockType, blockData) => (dispatch, getState) => {
  const state = getState();
  const editedBlockData = elementPanelSelector.editedBlockData(state);
  const currentlyActiveBlockKey = blockListSelector.currentlyActiveBlockKey(state);
  const blockList = blockListSelector.blockList(state);
  const currentlyActiveBlock = getBlockByKey(blockList, currentlyActiveBlockKey);
  if (currentlyActiveBlock.type !== BLOCK_TYPES.ADDER && !editedBlockData) {
    insertDataBlock(currentlyActiveBlockKey, blockType, blockData)(dispatch);
  } else {
    replaceBlock(currentlyActiveBlockKey, blockType, blockData)(dispatch);
  }
};

export const toggleOffBlockAdderReplacementIndication = () => dispatch => {
  dispatch({
    type: TOGGLE_OFF_BLOCK_ADDER_REPLACEMENT_INDICATION,
  });
};
