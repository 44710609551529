const VISIBILITY_STATES = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  PRERENDER: 'prerender',
};

class WindowHandler {
  static open(url, isBlank = false) {
    window.open(url, isBlank ? '_blank' : null);
    return window.focus();
  }

  static redirectToUrl(url) {
    return window.location.replace(url);
  }

  static refresh() {
    return window.location.reload(true);
  }

  static isWindowVisible() {
    return window.document.visibilityState === VISIBILITY_STATES.VISIBLE;
  }
}

export {
  WindowHandler,
};
