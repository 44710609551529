import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { EchoNetworkService } from '../../services/echoServiceApiProvider';
import { Logger } from '../../../../../core/logger';
import { getEchoPollingService } from '../../echoPollingManager/echoPollingManager';

import { WindowHandler } from '../../../../../core/window/windowHandler';

import { pushGlobalNotificationActionCreator, dismissGlobalNotification } from '../../../../../core/globalNotifications/store/globalNotifications.actions';

import {
  POLLING_SNOOZE_INTERVAL,
  PUSH_NOTIFICATION_BUTTONS_TEXT,
  PUSH_NOTIFICATION_TEXTS,
} from '../../constants/echo.constants';

import {
  hasPostListBeenChanged,
  saveFilterValueToLocalStorage,
} from '../../utils/echo.utils';
import { copyToClipboard, guid } from '../../../../../core/utils/utils';
import { echoDashboardSelector } from './echoDashboard.selector';

import { CMSNavigator } from '../../../utils/CMSNavigator';
import { getPostData } from '../echoCustomization/echoCustomization.actions';

export const REQUEST_POSTS = '[NEW ECHO] requested posts from server';
export const REQUEST_POSTS_SUCCESS = '[NEW ECHO] get posts success';
export const REQUEST_POSTS_FAILURE = '[NEW ECHO] get posts failure';
export const TOGGLE_POST_VISIBILITY_REQUEST = '[NEW ECHO] requested post visibility change';
export const TOGGLE_POST_VISIBILITY_SUCCESS = '[NEW ECHO] post visibility change success';
export const TOGGLE_POST_VISIBILITY_FAILURE = '[NEW ECHO] post visibility change failure';
export const DELETE_POST_REQUEST = '[NEW ECHO] requested post deletion';
export const DELETE_POST_SUCCESS = '[NEW ECHO] post deletion success';
export const DELETE_POST_FAILURE = '[NEW ECHO] post deletion failure';
export const DRAFT_OPENED_FOR_EDITING_ECHO_DASHBOARD = '[NEW ECHO] publisher is editing draft';
export const DISPLAYING_FILTER_VALUE_CHANGE = '[NEW ECHO] publisher is changing displaying filter value';
export const STATUS_FILTER_VALUE_CHANGE = '[NEW ECHO] publisher is changing status filter value';
export const SET_NEW_DATE_RANGE = '[NEW ECHO] publisher is setting new date range';
export const SET_NEW_SEARCH_TERM = '[NEW ECHO] publisher is setting new search term';
export const COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD = '[NEW ECHO] copy url to clipboard from dashboard';
export const PREVIEW_PAGE_OPEN_FROM_DASHBOARD = '[NEW ECHO] preview page open from dashboard';
export const SET_LABELS_FILTER = '[NEW ECHO] set labels filter value';
export const SET_LABELS_METHOD_FILTER = '[NEW ECHO] set labels method filter value';

const INVALID_SEARCH_TERM_ERROR_CODE = 400;

export const notifyAboutNewData = ({ onRefreshAction }) => dispatch => {
  const notificationId = guid();
  dispatch(pushGlobalNotificationActionCreator({
    id: notificationId,
    text: PUSH_NOTIFICATION_TEXTS.NEW_DATA,
    status: SNACK_BAR_STATUSES.INFO,
    buttonText: PUSH_NOTIFICATION_BUTTONS_TEXT.REFRESH,
    onButtonClick: () => {
      onRefreshAction();
      dismissGlobalNotification(notificationId)(dispatch);
    },
    closeable: true,
    animationDuration: '10s',
    onCloseAction: () => getEchoPollingService().snoozePolling(POLLING_SNOOZE_INTERVAL),
  }));
};

function checkForPostUpdates({ getUpdatedPostRequest, onRefreshAction }) {
  return async (dispatch, getState) => {
    try {
      if (!WindowHandler.isWindowVisible()) {
        return;
      }
      const oldPosts = echoDashboardSelector.posts(getState());
      const { posts } = await getUpdatedPostRequest();
      if (hasPostListBeenChanged(oldPosts, posts)) {
        notifyAboutNewData({ onRefreshAction })(dispatch);
      }
    } catch (e) {
      Logger.error(e);
    }
  };
}

export function getPosts(filters, property) {
  return async dispatch => {
    dispatch({
      type: REQUEST_POSTS,
      payload: { filters },
    });
    try {
      const { posts, pagination } = await EchoNetworkService.getPosts({ filters, property });
      getEchoPollingService().resetPolling(() => EchoNetworkService.getPosts({ filters, property }));
      return dispatch({
        type: REQUEST_POSTS_SUCCESS,
        payload: {
          posts,
          pagination,
        },
      });
    } catch (error) {
      Logger.error(error);
      if (error.code === INVALID_SEARCH_TERM_ERROR_CODE) {
        dispatch(pushGlobalNotificationActionCreator({
          text: PUSH_NOTIFICATION_TEXTS.INVALID_SEARCH_TERM,
          status: SNACK_BAR_STATUSES.ERROR,
        }));
      }
      return dispatch({
        type: REQUEST_POSTS_FAILURE,
        payload: error,
      });
    }
  };
}

export function getPagedPosts(path) {
  return async dispatch => {
    dispatch({
      type: REQUEST_POSTS,
      payload: {},
    });
    try {
      const { posts, pagination } = await EchoNetworkService.getPagedPosts({ path });
      getEchoPollingService().resetPolling(() => EchoNetworkService.getPagedPosts({ path }));
      return dispatch({
        type: REQUEST_POSTS_SUCCESS,
        payload: {
          posts,
          pagination,
        },
      });
    } catch (error) {
      Logger.error(error);
      return dispatch({
        type: REQUEST_POSTS_FAILURE,
        payload: error,
      });
    }
  };
}

export function getPostsAndStartDataPolling(filters, property) {
  return async (dispatch, getState) => {
    try {
    await getPosts(filters, property)(dispatch);
    const getUpdatedPostRequest = () => EchoNetworkService.getPosts({ filters, property });
    const onRefreshAction = () => getPosts(filters, property)(dispatch);
    getEchoPollingService().startPolling(() => checkForPostUpdates({ getUpdatedPostRequest, onRefreshAction })(dispatch, getState));
    } catch (error) {
      Logger.error(error);
    }
  };
}

export function getPagedPostsAndStartDataPolling(path) {
  return async (dispatch, getState) => {
    await getPagedPosts(path)(dispatch);
    const getUpdatedPostRequest = () => EchoNetworkService.getPagedPosts({ path });
    const onRefreshAction = () => getPagedPosts(path)(dispatch);
    getEchoPollingService().startPolling(() => checkForPostUpdates({ getUpdatedPostRequest, onRefreshAction })(dispatch, getState));
  };
}

export function stopDataPolling() {
  return () => {
    getEchoPollingService().stopPolling();
  };
}

export function notifyInvalidSearchTerm() {
  return dispatch => {
    dispatch(pushGlobalNotificationActionCreator({
      text: PUSH_NOTIFICATION_TEXTS.SEARCH_LENGTH_FAILURE,
      status: SNACK_BAR_STATUSES.ERROR,
    }));
  };
}

export function togglePostVisibility({ id, property, hidden, filters }) {
  return async dispatch => {
    dispatch({
      type: TOGGLE_POST_VISIBILITY_REQUEST,
    });
    try {
      const newVisibilityDetails = { data: { hidden: !hidden }, postId: id, property };
      await EchoNetworkService.updatePostVisibility(newVisibilityDetails);
      dispatch({
        type: TOGGLE_POST_VISIBILITY_SUCCESS,
        payload: newVisibilityDetails,
      });
      dispatch(pushGlobalNotificationActionCreator({
        text: hidden ? PUSH_NOTIFICATION_TEXTS.MOVED_OUT_OF_ARCHIVED : PUSH_NOTIFICATION_TEXTS.MOVED_TO_ARCHIVED,
        status: SNACK_BAR_STATUSES.INFO,
      }));
      return dispatch(getPosts(filters, property));
    } catch (error) {
      Logger.error(error);
      dispatch(pushGlobalNotificationActionCreator({
        text: PUSH_NOTIFICATION_TEXTS.FAILURE,
        status: SNACK_BAR_STATUSES.INFO,
      }));
      return dispatch({
        type: TOGGLE_POST_VISIBILITY_FAILURE,
        payload: error,
      });
    }
  };
}

export function deletePost({ id, property, filters, placingContextScreen }) {
  return async dispatch => {
    dispatch({ type: DELETE_POST_REQUEST });
    try {
      await EchoNetworkService.deletePost({ postId: id, property });
      dispatch({ type: DELETE_POST_SUCCESS, payload: { placingContextScreen } });
      dispatch(pushGlobalNotificationActionCreator({
        text: PUSH_NOTIFICATION_TEXTS.DELETE_POST_SUCCESS,
        status: SNACK_BAR_STATUSES.SUCCESS,
      }));
      await dispatch(getPosts(filters, property));
    } catch (error) {
      Logger.error(error);
      dispatch({ type: DELETE_POST_FAILURE });
      dispatch(pushGlobalNotificationActionCreator({
        text: PUSH_NOTIFICATION_TEXTS.DELETE_POST_FAILURE,
        status: SNACK_BAR_STATUSES.ERROR,
      }));
    }
  };
}

export function openDraftForEdit(property, id) {
  return dispatch => {
    CMSNavigator.toDraftEditing(property, id);
    dispatch({
      type: DRAFT_OPENED_FOR_EDITING_ECHO_DASHBOARD,
      payload: id,
    });
  };
}

export function setDisplayingFilterValueChange(filters, filterKey, property) {
  return dispatch => {
    saveFilterValueToLocalStorage(filterKey, filters[filterKey]);
    dispatch({
      type: DISPLAYING_FILTER_VALUE_CHANGE,
    });
    return getPosts(filters, property)(dispatch);
  };
}

export function setStatusFilterValue(filters, filterKey, property) {
  return dispatch => {
    saveFilterValueToLocalStorage(filterKey, filters[filterKey]);
    dispatch({
      type: STATUS_FILTER_VALUE_CHANGE,
      payload: {
        newFilterState: filters[filterKey],
      },
    });
    return getPosts(filters, property)(dispatch);
  };
}

export function setNewDateRange(filters, filterKey, property) {
  return dispatch => {
    saveFilterValueToLocalStorage(filterKey, filters[filterKey]);
    dispatch({
      type: SET_NEW_DATE_RANGE,
      payload: filters[filterKey],
    });
    return getPosts(filters, property)(dispatch);
  };
}

export function setNewSearchTerm(filters, filterKey, property, shouldGetPosts = true) {
  return dispatch => {
    if (shouldGetPosts) {
      saveFilterValueToLocalStorage(filterKey, filters[filterKey]);
      dispatch({
        type: SET_NEW_SEARCH_TERM,
      });
      return getPosts(filters, property)(dispatch);
    }
    return dispatch({
      type: SET_NEW_SEARCH_TERM,
    });
  };
}

export function setLabelsFilter(filters, filterKey, property) {
  return dispatch => {
    saveFilterValueToLocalStorage(filterKey, filters[filterKey]);
    dispatch({
      type: SET_LABELS_FILTER,
      payload: filters[filterKey],
    });
    return getPosts(filters, property)(dispatch);
  };
}

export function setLabelsMethodFilter(filters, filterKey, property) {
  return dispatch => {
    saveFilterValueToLocalStorage(filterKey, filters[filterKey]);
    dispatch({
      type: SET_LABELS_METHOD_FILTER,
      payload: filters[filterKey],
    });
    return getPosts(filters, property)(dispatch);
  };
}

export function openPreviewPageFromDashboard(property, id) {
  return dispatch => {
    CMSNavigator.toEchoPreview(property, id);
    dispatch({
      type: PREVIEW_PAGE_OPEN_FROM_DASHBOARD,
    });
  };
}

export function getPostAndOpenPreviewPageFromDashboard(property, id) {
  return async dispatch => {
    await getPostData(id, property)(dispatch);
    openPreviewPageFromDashboard(property, id)(dispatch);
  };
}

export function copyUrlToClipboard(url) {
  return dispatch => {
    copyToClipboard(url);
    dispatch({
      type: COPY_URL_TO_CLIPBOARD_FROM_DASHBOARD,
      payload: url,
    });
  };
}
