export const getStylesObject = () => {
  return {
    image: {
      width: '100%',
      textAlign: 'center',
    },
    overviewImage: {
      width: '32px',
      height: '20px',
      marginRight: '16px',
    },
    loadMoreButton: {
      paddingTop: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    radioGroup: {
      marginBottom: '16px',
    },
    searchInput: {
      marginBottom: '16px',
    },
  };
};
