import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { notifyTokenExpiredIfNeedTo } from 'core/store/user/userSlice';
import { Logger } from 'core/logger';
import { propertySelector } from 'core/store/property/property.selector';
import { pushGlobalNotificationActionCreator } from 'core/globalNotifications/store/globalNotifications.actions';
import { HTTP_STATUS_CODES } from 'core/network/mmServicesApiProvider';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import { ContentEnrichmentConfigsProvider } from 'containers/cms/contentEnrichment/configs/ContentEnrichmentConfigsProvider';
import { CraftServiceApiProvider } from 'containers/craft/services/craftServiceApiProvider';
import { draftSelector } from './draft.selector';
import { getEditorServices } from '../../services/editorServiceApiProvider';
import {
  setAutoFillSeoDescription,
  setSeoTitleValue,
  updateArticleTagsValue,
  updateSeoDescriptionValue,
} from '../editorialMenu/tagsAndSeo/tagsAndSeoSlice';
import { updateCoverImage } from '../coverImage/coverImage.actions';
import { updateBlockListData } from '../blocks/blockList.actions';
import { getEditorSaveManager } from '../../editorSaveManager/editorSaveManager';
import { presentSubmitModal } from '../editorModals/editorModals.actions';
import { closeElementPanel } from '../elementPanel/elementPanel.actions';
import { startDraftAutoSaving } from '../draftSaving/draftSaving.actions';
import { CMSNavigator } from '../../../utils/CMSNavigator';
import { CMS } from '../../../constants/cms.constants';
import {
  updateSocialSharing,
  autoFillSocialSharingIfNeedTo,
} from '../editorialMenu/socialSharing/socialSharingSlice';
import { updateDraftComments } from '../comments/comments.actions';
import { setPostIntro } from '../postIntro/postIntroSlice';
import { tagsAndSeoSelector } from '../editorialMenu/tagsAndSeo/tagsAndSeo.selector';
import { blockListSelector } from '../blocks/blockList.selector';
import { postTitleSelector } from '../postTitle/postTitle.selector';
import { setPostTitle } from '../postTitle/postTitleSlice';
import { setInThisStoriesSlugs } from '../editorialMenu/inThisStory/inThisStorySlice';
import { EditorServiceDataProvider } from '../../services/editorServiceConfigsProvider';
import { IMPORT_ARTICLE_MODAL_TEXT } from '../../utils/importArticle.util';

export const START_DRAFT_CREATION = '[EDITOR] draft creation request';
export const UPDATE_CURRENT_DRAFT_ID = '[EDITOR] update draftId';
export const REQUEST_DRAFT_DATA = '[EDITOR] create request to fetch draft data';
export const REQUEST_DRAFT_DATA_SUCCESS = '[EDITOR] request to fetch draft data succeeded';
export const REQUEST_DRAFT_DATA_FAILURE = '[EDITOR] request to fetch draft data failed';
export const REQUEST_PROPERTY_SETTINGS = '[EDITOR] create request to fetch property settings';
export const REQUEST_PROPERTY_SETTINGS_SUCCESS = '[EDITOR] request to fetch property settings succeeded';
export const REQUEST_PROPERTY_SETTINGS_FAILURE = '[EDITOR] request to fetch property settings failed';
export const REQUEST_UPDATE_PUBLISH_DRAFT = '[EDITOR] request publish draft';
export const REQUEST_SUBMIT_PUBLISH_DRAFT = '[EDITOR] request submit draft';
export const SUBMIT_DRAFT_REQUEST_SUCCESS = '[EDITOR] submit draft request succeeded';
export const SUBMIT_DRAFT_REQUEST_FAILED = '[EDITOR] publish draft request failed';
export const UPDATE_PUBLISHED_DRAFT_REQUEST_SUCCESS = '[EDITOR] update published draft request succeed';
export const UPDATE_PUBLISHED_DRAFT_REQUEST_FAILED = '[EDITOR] update published draft request failed';
export const RESET_EDITOR_STATE = '[EDITOR] reset editor state';
export const SET_ORIGINALLY_LOADED_DRAFT_SNAPSHOT = '[EDITOR] set cached draft data';
export const OPEN_PREVIEW_PAGE_FROM_DRAFT = '[EDITOR] open preview page from draft';
export const ADD_TO_UNDO_LIST = '[EDITOR] add to undo list';
export const APPLY_UNDO_ON_DRAFT = '[EDITOR] apply undo on draft';
export const APPLY_REDO_ON_DRAFT = '[EDITOR] apply redo on draft';
export const IMPORT_ARTICLE = '[EDITOR] create request to import article data';
export const IMPORTED_ARTICLE_SUCCESS = '[EDITOR] import article data succeeded';
export const IMPORTED_ARTICLE_FAILED = '[EDITOR] import article data failed';

export const updateDraftId = id => ({
  type: UPDATE_CURRENT_DRAFT_ID,
  payload: id,
});

export const startDraftCreation = id => ({
  type: START_DRAFT_CREATION,
  payload: id,
});

export const createNewDraft = () => (dispatch, getState) => {
  const property = propertySelector.currentPropertySlug(getState(), CMS);
  return getEditorServices().createDraft(property).then(draftId => {
    dispatch(updateDraftId(draftId));
    dispatch(startDraftCreation(draftId));
    CMSNavigator.toDraftEditing(property, draftId);
  }).catch(error => {
    notifyTokenExpiredIfNeedTo(error)(dispatch);
    Logger.error('Could not create draft: ', error);
  });
};

export function notifyUpdatePublishedDraftRequest(dispatch) {
  dispatch({
    type: REQUEST_UPDATE_PUBLISH_DRAFT,
  });
}
export function notifySubmitDraftRequest(dispatch) {
  dispatch({
    type: REQUEST_SUBMIT_PUBLISH_DRAFT,
  });
}

export function notifySubmitDraftRequestSucceeded(dispatch) {
  dispatch({
    type: SUBMIT_DRAFT_REQUEST_SUCCESS,
  });
}

export function notifySubmitDraftRequestFailed(dispatch) {
  dispatch({
    type: SUBMIT_DRAFT_REQUEST_FAILED,
  });
}

export function notifyUpdateDraftRequestSucceeded(dispatch) {
  dispatch({
    type: UPDATE_PUBLISHED_DRAFT_REQUEST_SUCCESS,
  });
}

export function notifyUpdateDraftRequestFailed(dispatch) {
  dispatch({
    type: UPDATE_PUBLISHED_DRAFT_REQUEST_FAILED,
  });
}

export function executeSubmitDraft({ id, property }, dispatch) {
  return getEditorServices().submitDraft({ id, property }).then(res => {
    notifySubmitDraftRequestSucceeded(dispatch);
    presentSubmitModal(res.url)(dispatch);
  }).catch(error => {
    notifySubmitDraftRequestFailed(dispatch);
    dispatch(pushGlobalNotificationActionCreator({
      text: 'Post could not be submitted',
      status: SNACK_BAR_STATUSES.ERROR,
    }));
    notifyTokenExpiredIfNeedTo(error)(dispatch);
    Logger.error('Could not submit draft: ', error);
  });
}

const autoFillSeoDataIfNeedTo = () => (dispatch, getState) => {
  const seoDescription = tagsAndSeoSelector.seoDescription(getState());
  if (!seoDescription) {
    const autoFillDescription = blockListSelector.blockListBodyText(getState()).substring(0, 160) || postTitleSelector.postTitle(getState());
    dispatch(setAutoFillSeoDescription(autoFillDescription));
  }
};

export const submitDraft = (property, draftTitle, draftDescription, draftCover) => (dispatch, getState) => {
  const state = getState();
  const draftId = draftSelector.draftId(state);
  autoFillSeoDataIfNeedTo()(dispatch, getState);
  dispatch(autoFillSocialSharingIfNeedTo({ draftTitle, draftDescription, draftCover, dispatch }));
  notifySubmitDraftRequest(dispatch);
  return getEditorSaveManager().saveIfNeedTo().then(() => {
    executeSubmitDraft({ id: draftId, property }, dispatch);
  });
};

export function executeUpdatePublishedDraft({ id, property }, dispatch) {
  return getEditorServices().updatePublishedDraft({ id, property }).then(res => {
    notifyUpdateDraftRequestSucceeded(dispatch);
    presentSubmitModal(res.url)(dispatch);
  }).catch(error => {
    notifyUpdateDraftRequestFailed(dispatch);
    dispatch(pushGlobalNotificationActionCreator({
      text: 'Post could not be updated',
      status: SNACK_BAR_STATUSES.ERROR,
    }));
    notifyTokenExpiredIfNeedTo(error)(dispatch);
    Logger.error('Could not update draft: ', error);
  });
}

export const updatePublishedDraft = property => (dispatch, getState) => {
  const state = getState();
  const draftId = draftSelector.draftId(state);
  dispatch(autoFillSeoDataIfNeedTo());
  notifyUpdatePublishedDraftRequest(dispatch);
  return getEditorSaveManager().saveIfNeedTo().then(() => {
    executeUpdatePublishedDraft({ id: draftId, property }, dispatch);
  });
};

export const setOriginallyLoadedDraftSnapshot = () => (dispatch, getState) => {
  return dispatch({
    type: SET_ORIGINALLY_LOADED_DRAFT_SNAPSHOT,
    payload: draftSelector.draftPayload(getState()),
  });
};

export const updateDraftData = (draftData, isInitialValue = true) => dispatch => {
  const {
    title,
    intro,
    cover,
    body,
    tags,
    seo,
    seoTitle,
    socialSharing,
    comments,
    inThisStory,
  } = draftData;
  dispatch(setPostTitle(title));
  dispatch(updateCoverImage(cover));
  dispatch(updateBlockListData(body));
  dispatch(setPostIntro({ intro, isInitialValue }));
  dispatch(updateArticleTagsValue(tags));
  dispatch(updateSeoDescriptionValue(seo));
  dispatch(setSeoTitleValue(seoTitle));
  dispatch(updateSocialSharing(socialSharing));
  dispatch(updateDraftComments(comments));
  dispatch(setInThisStoriesSlugs(inThisStory));
};

export const draftNotFoundAction = () => dispatch => {
  dispatch(pushGlobalNotificationActionCreator({
    text: 'Draft is not found',
    status: SNACK_BAR_STATUSES.ERROR,
  }));
  return CMSNavigator.toEditorDashboard();
};

export const applyUndoOnDraft = () => (dispatch, getState) => {
  const latestUndo = draftSelector.draftPayload(getState());
  dispatch({
    type: APPLY_UNDO_ON_DRAFT,
    payload: latestUndo,
  });
};

export const applyRedoOnDraft = () => dispatch => {
  dispatch({
    type: APPLY_REDO_ON_DRAFT,
  });
};

export const addToUndoList = draftData => dispatch => {
  dispatch({
    type: ADD_TO_UNDO_LIST,
    payload: draftData,
  });
};

export const fetchDraftData = ({ property, id }) => (dispatch, getState) => {
  dispatch({
    type: REQUEST_DRAFT_DATA,
    payload: id,
  });
  return getEditorServices().getDraft({ property, id })
    .then(data => {
      const { draft, isWorkingDraft } = data;
      dispatch({
        type: REQUEST_DRAFT_DATA_SUCCESS,
        payload: {
          draft,
          isWorkingDraft,
        },
      });
      updateDraftData(draft)(dispatch);
      setOriginallyLoadedDraftSnapshot()(dispatch, getState);
      return startDraftAutoSaving({ property, addToUndoList })(dispatch, getState);
    })
    .catch(error => {
      Logger.error('Could not fetch draft: ', error);
      notifyTokenExpiredIfNeedTo(error)(dispatch);
      if (error.code === HTTP_STATUS_CODES.NOT_FOUND) {
        return draftNotFoundAction()(dispatch);
      }
      return dispatch({
        type: REQUEST_DRAFT_DATA_FAILURE,
        payload: error,
      });
    });
};

export const resetEditorState = () => dispatch => {
  dispatch({
    type: RESET_EDITOR_STATE,
  });
  dispatch(closeElementPanel());
};

export const openPreviewPageFromDraft = (property, id) => {
  return dispatch => {
    CMSNavigator.toEditorPreview(property, id);
    dispatch({
      type: OPEN_PREVIEW_PAGE_FROM_DRAFT,
    });
  };
};

const getArticleDataFromId = (property, id) => async dispatch => {
  const requestUrl = EditorServiceDataProvider.getEditorServiceDraftEndpoint({ property, id });
  await dispatch(networkAction({
    url: requestUrl,
    method: HttpMethod.Get,
    onPending: () => { return { type: IMPORT_ARTICLE }; },
    onSuccess: data => {
      dispatch(pushGlobalNotificationActionCreator({
        status: SNACK_BAR_STATUSES.SUCCESS,
        text: IMPORT_ARTICLE_MODAL_TEXT.IMPORT_SUCCESS,
      }));
      dispatch(updateDraftData(data.draft));
      return { type: IMPORTED_ARTICLE_SUCCESS };
    },
    onFailure: () => {
      dispatch(pushGlobalNotificationActionCreator({
        status: SNACK_BAR_STATUSES.ERROR,
        text: IMPORT_ARTICLE_MODAL_TEXT.NOT_FOUND_ERROR,
      }));
      return { type: IMPORTED_ARTICLE_FAILED };
    },
  }));
};

export const getImportedArticleData = (property, url) => async dispatch => {
  const requestUrl = ContentEnrichmentConfigsProvider.getContentEnrichmentPostsEndpoint(property, url);
  return new Promise((resolve, reject) => {
    return dispatch(networkAction({
      url: requestUrl,
      method: HttpMethod.Get,
      onPending: () => { return { type: IMPORT_ARTICLE }; },
      onSuccess: data => {
        resolve(dispatch(getArticleDataFromId(property, data.post.id)));
        return { type: IMPORT_ARTICLE };
      },
      onFailure: () => {
        dispatch(pushGlobalNotificationActionCreator({
          status: SNACK_BAR_STATUSES.ERROR,
          text: IMPORT_ARTICLE_MODAL_TEXT.NOT_FOUND_ERROR,
        }));
        reject();
        return { type: IMPORTED_ARTICLE_FAILED };
      },
    }));
  });
};

export const getPropertySettings = property => async dispatch => {
  const requestUrl = CraftServiceApiProvider.getPropertySettingsUrl(property);
  await dispatch(networkAction({
    url: requestUrl,
    method: HttpMethod.Get,
    onPending: () => {
      return { type: REQUEST_PROPERTY_SETTINGS };
    },
    onSuccess: data => {
      return { type: REQUEST_PROPERTY_SETTINGS_SUCCESS, payload: data };
    },
    onFailure: () => {
      return { type: REQUEST_PROPERTY_SETTINGS_FAILURE };
    },
  }));
};
