import { HTTP_STATUS_CODES } from 'core/network/mmServicesApiProvider';
import { AdCostsType } from './adServer.constants';

export enum CampaignType {
  All = 'All Campaign Types',
  Backfill = 'Backfill',
  DemandExtension = 'Demand Extension',
  DirectIO = 'Direct IO',
  NetworkTag = 'Network Tag',
  ProgrammaticGuaranteed = 'Programmatic Guaranteed',
  PublisherDemand = 'Publisher Demand',
}

export enum CampaignStatus {
  Unarchived = 'Unarchived',
  Archived = 'Archived',
}

export const CAMPAIGN_STATUS_FILTER_OPTIONS = {
  UNARCHIVE: 'Unarchived Campaigns',
  ARCHIVE: 'Archived Campaigns',
};

export const CAMPAIGNS_PAGE = {
  TITLE: 'Campaigns',
  CREATE_CAMPAIGN_BUTTON: 'New Campaign',
};

export const ALL_CAMPAIGNS = 'All Campaigns';

export const CAMPAIGNS_PAGE_CHANGE_STATUS_MODAL = {
  UNARCHIVE_TITLE: 'Unarchive Campaign',
  UNARCHIVE_DESCRIPTION: 'Are you sure you want to unarchive this campaign?',
  ARCHIVE_TITLE: 'Archive Campaign',
  ARCHIVE_DESCRIPTION: 'Are you sure you want to archive this campaign?\nAll line items within the campaign will stop delivering.',
  CANCEL_BUTTON: 'Cancel',
  CONFIRM_BUTTON: 'Yes',
};

export const CAMPAIGNS_PAGE_NOTIFICATIONS = {
  CREATE_CAMPAIGN_SUCCESS_MESSAGE: 'The campaign was created successfully',
  UPDATE_CAMPAIGN_SUCCESS_MESSAGE: 'The campaign was updated successfully',
  UNARCHIVE_CAMPAIGN_SUCCESS_MESSAGE: 'The campaign has been unarchived successfully',
  ARCHIVE_CAMPAIGN_SUCCESS_MESSAGE: 'The campaign has been archived successfully',
  ARCHIVE_CAMPAIGN_FAILURE_MESSAGE: 'The campaign can\'t be archived because all line items must be draft, paused or completed',
  CLONE_CAMPAIGN_SUCCESS_MESSAGE: 'The campaign has been cloned successfully',
  CLONE_CAMPAIGN_FAILURE_MESSAGE: 'A campaign or child line item with the same name already exists. Please rename the previous copy.',
};

export const ALL_SALESPERSONS = 'All Salespersons';

export const CAMPAIGNS_TABLE = {
  CAMPAIGNS_NO_DATA_MESSAGE: 'Looks like there aren\'t any campaigns yet',
  CAMPAIGNS_NO_DATA_FOR_SEARCH: 'Looks like there aren\'t any matches for your search',
  NO_UNARCHIVED_CAMPAIGNS_MESSAGE: 'Looks like there aren\'t any unarchived campaigns',
  NAME_COLUMN_LABEL: 'Name',
  ADVERTISER_COLUMN_LABEL: 'Advertiser',
  TYPE_COLUMN_LABEL: 'Type',
  ID_COLUMN_LABEL: 'ID',
  SALESPERSON_COLUMN_LABEL: 'Salesperson',
  OWNER_COLUMN_LABEL: 'Owner',
  DELIVERED_COLUMN_LABEL: 'Delivered',
  AIRTABLE_CODE_COLUMN_LABEL: 'AirTable Campaign Code',
  LAST_MODIFIED_COLUMN_LABEL: 'Last Modified',
  ACTIONS_MENU_COLUMN_LABEL: '',
};

export const CAMPAIGNS_TABLE_ACTIONS_MENU = {
  UNARCHIVE: 'Unarchive',
  ARCHIVE: 'Archive',
  CLONE: 'Clone',
  EDIT: 'Edit',
};

export const CLONE_CAMPAIGN_MODAL = {
  TITLE: 'Clone Campaign',
  DESCRIPTION: 'Are you sure you wish to clone this campaign?',
  CANCEL_BUTTON: 'Cancel',
  CONFIRM_BUTTON: 'Yes',
};

export const CREATE_CAMPAIGN_PAGE = {
  TITLE: 'New Campaign',
  CONFIGURATION_SETTINGS_DESCRIPTION: 'Configure the settings of a campaign.',
  CONFIGURATION_SETTINGS_CREATE_BUTTON: 'Create',
  CONFIGURATION_SETTINGS_CREATE_BUTTON_TOOLTIP: 'Please fill in all the required fields',
};

export const EDIT_CAMPAIGN_PAGE = {
  TITLE: 'Campaign Details',
  CREATE_LINE_ITEM_BUTTON: 'New Line Item',
  CONFIGURATION_SETTINGS_DESCRIPTION: 'Edit the settings of the campaign.',
  CONFIGURATION_SETTINGS_SAVE_BUTTON: 'Save',
  CONFIGURATION_SETTINGS_SAVE_BUTTON_TOOLTIP_INVALID_FIELDS: 'Please fill in all the required fields',
  CONFIGURATION_SETTINGS_SAVE_BUTTON_TOOLTIP_NO_CHANGES: 'No changes were made',
  DISABLE_CREATE_LINE_ITEM_BUTTON_TOOLTIP: 'The campaign must be unarchived',
};

export const CREATE_CAMPAIGN_DISCARD_CHANGES_MODAL = {
  TITLE: 'Hey, Wait!',
  DESCRIPTION: 'Are you sure you wish to go back without creating a campaign?\nNothing will be saved.',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'No',
};

export const EDIT_CAMPAIGN_DISCARD_CHANGES_MODAL = {
  TITLE: 'Hey, Wait!',
  DESCRIPTION: 'Are you sure you wish to go back without saving the changes?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'No',
};

export const CAMPAIGN_CONFIGURATION_SETTINGS = {
  CAMPAIGN_NAME_LABEL: 'Campaign Name',
  CAMPAIGN_NAME_PLACEHOLDER: 'Enter Campaign Name',
  CAMPAIGN_NAME_CONFLICT_ERROR: 'Please insert a unique name',
  CAMPAIGN_TYPE_LABEL: 'Campaign Type',
  CAMPAIGN_TYPE_PLACEHOLDER: 'Choose Campaign Type',
  ADVERTISER_LABEL: 'Advertiser',
  ADVERTISER_PLACEHOLDER: 'Choose Advertiser',
  ADD_ADVERTISER_BUTTON: '+ Add new advertiser',
  SALESPERSON_LABEL: 'Salesperson',
  SALESPERSON_PLACEHOLDER: 'Choose Salesperson',
  OWNER_LABEL: 'Owner (Optional)',
  OWNER_PLACEHOLDER: 'Choose Owner',
  AIRTABLE_CODE_LABEL: 'AirTable Campaign Code (Optional)',
  AIRTABLE_CODE_PLACEHOLDER: 'Add Your Text Here',
  CANCEL_BUTTON: 'Cancel',
};

export const CAMPAIGN_VIEW_MODE_SETTINGS = {
  TYPE: 'Type',
  ADVERTISER: 'Advertiser',
  SALESPERSON: 'Salesperson',
  OWNER: 'Owner',
  AIRTABLE_CODE: 'AirTable Campaign Code',
  ID: 'ID',
  LAST_MODIFIED: 'Last modified on',
  CLONE_BUTTON_TOOLTIP: 'Clone',
  ARCHIVE_BUTTON_TOOLTIP: 'Archive',
  UNARCHIVE_BUTTON_TOOLTIP: 'Unarchive',
  EDIT_BUTTON_TOOLTIP: 'Edit',
};

export const LINE_ITEM_VIEW_MODE_SUMMARY = {
  TYPE: 'Type',
  PRIORITY_TYPE: 'Priority Type',
  ADVERTISER: 'Advertiser',
  CAMPAIGN: 'Campaign',
  ID: 'ID',
  AIRTABLE_CODE: 'AirTable Code',
  IMPRESSION_CAP: 'Imp. Cap',
  REQUEST_CAP: 'Request Cap',
  LAST_MODIFIED: 'Last modified',
  PACING_TYPE: 'Pacing Type',
  IMPRESSION_GOAL: 'Imp. Goal',
  getStartTime: (timezoneDelivering: TimezoneDelivering) => `Start Time (${timezoneDelivering})`,
  getEndTime: (timezoneDelivering: TimezoneDelivering) => `End Time (${timezoneDelivering})`,
  TIME_PARTING: 'Time Parting',
  CREATIVE_OPTIMIZATION: 'Creative Opt.',
  USER_CAP: 'User Cap',
  PACING_STATUS: 'Pacing Status',
  IMPRESSIONS: 'Impressions',
  ERROR_RATE: 'Error Rate',
  VIEWABILITY: 'Viewability',
  COMPLETION: 'Completion',
  CTR: 'CTR',
  OPTIMIZED: 'Optimized',
  INTERNAL: 'Internal',
  EXTERNAL: 'External',
  SUB_PRIORITY: 'Sub Priority',
  CLONE_BUTTON_TOOLTIP: 'Clone',
  ARCHIVE_BUTTON_TOOLTIP: 'Archive',
  UNARCHIVE_BUTTON_TOOLTIP: 'Unarchive',
  EDIT_BUTTON_TOOLTIP: 'Edit',
  ACTIVATE_BUTTON_TOOLTIP: 'Activate',
  PAUSE_BUTTON_TOOLTIP: 'Pause',
  YES: 'Yes',
  NO: 'No',
};

export const LINE_ITEM_VIEW_MODE_ANALYTICS = {
  ANALYTICS_TITLE: 'Analytics',
};

export const CAMPAIGN_LINE_ITEMS_CONTAINER = {
  LINE_ITEMS_TITLE: 'Total Line Items',
};

export const CAMPAIGN_LINE_ITEMS_TABLE = {
  NAME_COLUMN_LABEL: 'Name',
  START_DATE_COLUMN_LABEL: 'Start Date',
  END_DATE_COLUMN_LABEL: 'End Date',
  TYPE_COLUMN_LABEL: 'Type',
  PRIORITY_TYPE_COLUMN_LABEL: 'Priority',
  AIRTABLE_CODE_COLUMN_LABEL: 'AirTable Line Item Code',
  GOAL_COLUMN_LABEL: 'Goal',
  DELIVERED_COLUMN_LABEL: 'Delivered',
  PACING_COLUMN_LABEL: 'Pacing',
  STATUS_COLUMN_LABEL: 'Status',
  ID_COLUMN_LABEL: 'ID',
  VIEWABILITY_COLUMN_LABEL: 'Viewability',
  COMPLETION_RATE_COLUMN_LABEL: 'Completion Rate',
  ERROR_RATE_COLUMN_LABEL: 'Error Rate',
  CTR_COLUMN_LABEL: 'CTR',
  ACTIONS_MENU_COLUMN_LABEL: '',
  NO_DATA_MESSAGE: 'Looks like there aren\'t any line items yet',
  NO_DATA_FOR_SEARCH_MESSAGE: 'Looks like there aren\'t any matches for your search',
  NO_UNARCHIVED_LINE_ITEMS_MESSAGE: 'Looks like there aren\'t any unarchived line items',
};

export const CAMPAIGN_LINE_ITEMS_TABLE_ACTIONS_MENU = {
  EDIT: 'Edit',
  CLONE: 'Clone',
  ACTIVATE: 'Activate',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  PAUSE: 'Pause',
  COPY_ID: 'Copy ID',
};

export const CAMPAIGN_EMPTY_LINE_ITEMS = {
  NO_LINE_ITEMS_TEXT: 'Looks like you don\'t have any line items',
  DISABLE_CREATE_LINE_ITEM_BUTTON_TOOLTIP: 'The campaign must be unarchived',
};

export const LINE_ITEM_START_ON_ACTIVATION = 'Upon Activation';

export const LINE_ITEMS_TABLE_PAGE = {
  TITLE: 'Line Items',
};

export const LINE_ITEMS_TABLE = {
  NAME_COLUMN_LABEL: 'Name',
  ADVERTISER_COLUMN_LABEL: 'Advertiser',
  CAMPAIGN_COLUMN_LABEL: 'Campaign',
  START_DATE_COLUMN_LABEL: 'Start Date',
  END_DATE_COLUMN_LABEL: 'End Date',
  TYPE_COLUMN_LABEL: 'Type',
  PRIORITY_TYPE_COLUMN_LABEL: 'Priority',
  STATUS_COLUMN_LABEL: 'Status',
  AIRTABLE_CODE_COLUMN_LABEL: 'AirTable Line Item Code',
  LAST_MODIFIED_COLUMN_LABEL: 'Last Modified',
  ID_COLUMN_LABEL: 'ID',
  GOAL_COLUMN_LABEL: 'Goal',
  DELIVERED_COLUMN_LABEL: 'Delivered',
  PACING_COLUMN_LABEL: 'Pacing',
  VIEWABILITY_COLUMN_LABEL: 'Viewability',
  COMPLETION_RATE_COLUMN_LABEL: 'Completion Rate',
  ERROR_RATE_COLUMN_LABEL: 'Error Rate',
  CTR_COLUMN_LABEL: 'CTR',
  ACTIONS_MENU_COLUMN_LABEL: '',
  NO_DATA_MESSAGE: 'Looks like there aren\'t any line items yet',
  NO_DATA_FOR_SEARCH_MESSAGE: 'Looks like there aren\'t any matches for your search',
  NO_UNARCHIVED_LINE_ITEMS_MESSAGE: 'Looks like there aren\'t any unarchived line items',
};

export const LINE_ITEMS_TABLE_ACTIONS_MENU = {
  EDIT: 'Edit',
  COPY_ID: 'Copy ID',
  ACTIVATE: 'Activate',
  PAUSE: 'Pause',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
};

export enum LineItemPriorityType {
  Sponsorship = 'Sponsorship',
  Guaranteed = 'Guaranteed',
  PriceBased = 'Price Based',
}

export const ALL_LINE_ITEM_PRIORITY_TYPES = 'All Priority Types' as LineItemPriorityType;

export enum LineItemStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Delivering = 'Delivering',
  Paused = 'Paused',
  Pending = 'Pending',
  Ended = 'Ended',
}

export const ALL_LINE_ITEM_STATUSES = 'All Statuses' as LineItemStatus;

export const ADVERTISERS_TABLE_PAGE = {
  TITLE: 'Advertisers',
  CREATE_ADVERTISER_BUTTON: 'New Advertiser',
};

export const ALL_ADVERTISERS = 'All Advertisers';

export enum AdvertiserType {
  All = 'All Advertisers Types',
  Agency = 'Agency',
  Brand = 'Brand',
  Network = 'Network',
}

export const ADVERTISERS_TABLE = {
  NAME_COLUMN_LABEL: 'Name',
  TYPE_COLUMN_LABEL: 'Type',
  ID_COLUMN_LABEL: 'ID',
  LAST_MODIFIED_COLUMN_LABEL: 'Last Modified',
  ACTIONS_BAR_COLUMN_LABEL: '',
  NO_DATA_MESSAGE: 'Looks like there aren\'t any advertisers yet',
  NO_DATA_FOR_SEARCH: 'Looks like there aren\'t any matches for your search',
};

export const ADVERTISERS_TABLE_ACTIONS_MENU = {
  EDIT: 'Edit',
};

export const ADVERTISER_MODAL = {
  EDIT_TITLE: 'Edit Advertiser',
  CREATE_TITLE: 'New Advertiser',
  NAME_LABEL: 'Advertiser Name',
  NAME_PLACEHOLDER: 'Enter Advertiser Name',
  TYPE_LABEL: 'Advertiser Type',
  TYPE_PLACEHOLDER: 'Choose Advertiser Type',
  ADA_ID_LABEL: 'ADA_ID',
  ADA_ID_PLACEHOLDER: 'Enter Advertiser Ada_Id',
  CANCEL_BUTTON: 'Cancel',
  CREATE_BUTTON: 'Create',
  SAVE_BUTTON: 'Save',
  CREATE_ADVERTISER_NAME_CONFLICT_ERROR: 'Please insert a unique name',
  PUBLISH_BUTTON_TOOLTIP_NO_CHANGES: 'No changes were made',
  PUBLISH_BUTTON_TOOLTIP_INVALID_FIELDS: 'Please fill in name and choose type',
};

export const ERRORS = {
  GENERAL_SERVER_ERROR: 'Something went wrong, please try again later',
  FETCH_ORGANIZATIONS_ERROR: 'Sorry, we couldn\'t load any organizations for tenant',
  UNARCHIVE_ERROR: 'Please unarchive the line item\'s parent campaign and try again.',
  FAILED_IMAGE_UPLOAD: 'Failed to upload image',
};

export const ADVERTISERS_NOTIFICATIONS = {
  CREATE_ADVERTISER_FAILURE_MESSAGE: 'Failed to create new advertiser',
  UPDATE_ADVERTISER_FAILURE_MESSAGE: 'Failed to update advertiser',
  CREATE_ADVERTISER_SUCCESS_MESSAGE: 'The advertiser was created successfully',
  UPDATE_ADVERTISER_SUCCESS_MESSAGE: 'The advertiser was updated successfully',
};

export const LINE_ITEM_SETTINGS_PAGE_HEADER = {
  CREATE_TITLE: 'New Line Item',
  EDIT_TITLE: 'Edit Line Item',
  CANCEL_BUTTON: 'Cancel',
  CREATE_BUTTON: 'Create',
  UPDATE_BUTTON: 'Update',
  PUBLISH_TOOLTIP_NO_CHANGES: 'No changes were made',
  PUBLISH_TOOLTIP_INVALID_FIELDS_TITLE: 'Please fix invalid fields:',
  NAME_MESSAGE: 'Name',
  TYPE_MESSAGE: 'Type',
  PRIORITY_TYPE_MESSAGE: 'Priority type',
  START_DATE_MESSAGE: 'Start date',
  END_DATE_MESSAGE: 'End date',
  TIME_PARTING_MESSAGE: 'Time parting',
  AD_COSTS_MESSAGE: 'CPM or CPD or vCPM',
  USER_CAPPING_MESSAGE: 'User capping (fill in or remove)',
  IMPRESSION_GOAL_MESSAGE: 'Impression goal',
  IMPRESSION_CAP_MESSAGE: 'Impression cap',
  REQUEST_CAP_MESSAGE: 'Request cap',
  DURATION_SCORE_ADJUSTMENT: 'Duration Score Adjustment',
  CREATIVE_SIZE: 'Creative Size',
};

export const LINE_ITEM_VIEW_MODE_PAGE_HEADER = {
  TITLE: 'Line Item Details',
  CREATE_CREATIVE_BUTTON: 'New Creative',
  ADD_CREATIVES_BUTTON: 'Add Creatives',
  DISABLE_CREATIVE_BUTTON_TOOLTIP: 'The line item must be unarchived',
};

export const LINE_ITEM_CREATIVES_CONTAINER = {
  CREATIVES_TITLE: 'Total Creatives',
};

export const LINE_ITEM_DETAILS_SECTION = {
  TITLE: 'Line Item Details',
  ADVERTISER: 'Advertiser',
  CAMPAIGN: 'Campaign',
  NAME_LABEL: 'Line Item Name',
  NAME_PLACEHOLDER: 'Enter Line Item Name',
  TYPE_LABEL: 'Type',
  TYPE_PLACEHOLDER: 'Choose Line Item Type',
  LINE_ITEM_NAME_CONFLICT_ERROR: 'Please insert a unique name',
  LAST_MODIFIED: 'Last modified on',
  AIRTABLE_CODE_LABEL: 'AirTable Line Item Code (Optional)',
  AIRTABLE_CODE_PLACEHOLDER: 'Enter AirTable Line Item Code',
};

export const LINE_ITEM_SETTINGS_SECTION = {
  TITLE: 'Line Item Settings',
  SUBTITLE: 'Please select the line item priority you want',
  NO_PRIORITY_TEXT: 'Priority not selected yet.',
};

export const LINE_ITEM_PRIORITY_TYPE_CARD = {
  SPONSORSHIP_DESCRIPTION: 'Sponsorship line items have first look and deliver 100% of the time against their targeted supply',
  GUARANTEED_DESCRIPTION: 'Guaranteed line items have fixed impression goals, and start and end dates. The line item is prioritised above the open auction',
  PRICE_BASED_DESCRIPTION: 'Price based lines compete based on their CPM, against the open auction (and guaranteed line items where possible)',
  ACTIVE_DESCRIPTION: '(Active)',
};

export enum LineItemType {
  VideoAd = 'Video Ad',
  VideoAdAndContent = 'Video Ad + Content',
  PromotedContent = 'Promoted Content',
  DisplayWidget = 'Display Widget',
}

export const ALL_LINE_ITEM_TYPES = 'All Types' as LineItemType;

export const SPONSORSHIP_TYPE_SETTINGS = {
  TITLE: 'Sponsorship type settings',
  SUBTITLE: 'Configure the settings of a new sponsorship line item. ',
};

export const GUARANTEED_TYPE_SETTINGS = {
  TITLE: 'Guaranteed type settings',
  SUBTITLE: 'Configure the settings of a new guaranteed line item. ',
};

export const PRICE_BASED_TYPE_SETTINGS = {
  TITLE: 'Price Based type settings',
  SUBTITLE: 'Configure the settings of a new price based line item. ',
};

export const DATE_RANGE = {
  START_DATE_LABEL: 'Start Date',
  END_DATE_LABEL: 'End Date',
  HOURS_INPUT_PLACEHOLDER: 'hh',
  MINUTES_INPUT_PLACEHOLDER: 'mm',
  DATE_ERROR: 'Invalid date',
  DATE_WARNING: 'End date is within the next hour',
  ADD_END_DATE_BUTTON: 'Add end date',
  REMOVE_END_DATE_BUTTON: 'Remove',
  IMMEDIATELY_SELECTED_TEXT: 'Line item will start delivering upon activation',
};

export const IMMEDIATELY_PICKER = {
  DATE_PICKER: 'Date Picker',
  IMMEDIATELY: 'Immediately',
};

export const AD_COSTS_CONTAINER = {
  getExternalLabel: (type: AdCostsType) => `Ext. ${type}`,
  getInternalLabel: (type: AdCostsType) => `Int. ${type}`,
  getOptimizedLabel: (type: AdCostsType) => `Opt. ${type}`,
  PLACEHOLDER: 'Enter Value',
  FIELDS_ARE_MANDATORY_ERROR: 'All three fields are mandatory',
  INVALID_VALUE_FORMAT_ERROR: 'Value should be a positive number or 0, and must not exceed 2 decimal places',
  GENERAL_COSTS_LOGIC_ERROR: 'This logic must apply: External ≥ Internal ≥ Optimized',
  OPTIMIZED_CPM_ERROR: 'Optimized CPM must be equal or lower than 100',
  EXTERNAL_TOOLTIP: 'External CPM Includes all media and production costs',
  INTERNAL_TOOLTIP: 'Internal CPM Represents the net CPM for Minute Media',
  OPTIMIZED_TOOLTIP: 'Optimized CPM Represents the MMPlus publisher\'s CPM',
  RATE_TYPE: 'Rate Type',
  CURRENCY: 'Currency',
  CURRENCY_TOOLTIP: 'The CPMs will be converted into USD for auction and reporting purposes. Currencies are updated every 24 hours',
};

export const AD_COSTS_CONTAINER_INFO = {
  getExternalLabel: (type: AdCostsType) => `External ${type}`,
  getInternalLabel: (type: AdCostsType) => `Internal ${type}`,
  getOptimizedLabel: (type: AdCostsType) => `Optimized ${type}`,
  EXTERNAL_DESCRIPTION: 'Includes all media and production costs',
  getInternalDescription: (type: AdCostsType) => `Represents the net ${type} for Minute Media`,
  getOptimizedDescription: (type: AdCostsType) => `Represents the MMPlus publisher's ${type}`,
};

export const CREATIVE_OPTIMIZATION_CONTAINER = {
  LABEL: 'Creatives Optimization',
  COMPLETION_RATE_LABEL: 'Completion rate',
  COMPLETION_RATE_MEANING: 'The creative with the best completion rate will be shown more often',
  AS_MANY_AS_POSSIBLE_LABEL: 'As Many As Possible',
  AS_MANY_AS_POSSIBLE_MEANING: 'All creatives will be shown, ordered by completion rate (The video player may not play all of them)',
  EVENLY_LABEL: 'Evenly',
  EVENLY_MEANING: 'Creatives will rotate randomly',
  CTR_LABEL: 'CTR',
  CTR_MEANING: 'The creative with the best CTR will be shown more often',
  VIEWABILITY_LABEL: 'Viewability',
  VIEWABILITY_MEANING: 'The creative with the best viewability will be shown more often',
  ERROR_RATE_LABEL: 'Error rate',
  ERROR_RATE_MEANING: 'The creative with the lowest error rate will be shown more often',
  WEIGHTED_LABEL: 'Weighted',
  WEIGHTED_MEANING: 'The creative with the bigger weight will be shown more often',
};

export const DISABLE_MIDROLLS_CHECKBOX = {
  LABEL: 'Disable Midrolls',
  TOOLTIP: 'Check this box to prevent midrolls from running while the content is playing',
};

export const DISABLE_ERROR_ADJUSTMENT_CHECKBOX = {
  LABEL: 'Disable Error Score Adjustment',
  TOOLTIP: 'Check this box to prevent score reduction when line item has higher than average error rate',
};

export const ENABLE_DURATION_SCORE_ADJUSTMENT_DATA = {
  CHECKBOX_LABEL: 'Enable Duration Score Adjustment',
  CHECKBOX_TOOLTIP: 'Check this box to enable line item score reduction when creative has an above average creative duration',
  AD_DURATION_FIELD_LABEL: 'Expected Ad Duration',
  AD_INTERVAL_FIELD_LABEL: 'Expected Ad Interval',
};

export const DISABLE_EOC_DELIVERY_CHECKBOX = {
  LABEL: 'Disable EOC Delivery',
  TOOLTIP: 'Check this box to prevent automatic accelerated delivery at the end of campaign hours',
};

export const IMPRESSION_GOAL_CONTAINER = {
  LABEL: 'Impression Goal',
  PLACEHOLDER: 'Enter Impression Goal',
  IMPRESSION_GOAL_MUST_EXCEED_TOTAL_IMPRESSIONS_ERROR: 'Impression goal must exceed current delivered impressions',
};

export const USER_CAPPINGS_LIST = {
  LABEL: 'User Capping',
  ADD_USER_CAPPING_BUTTON: '+ Add user cap',
};

export const USER_CAPPING_CONTAINER = {
  VALUE_PLACEHOLDER: '00',
  TIME_VALUE_PLACEHOLDER: '00',
  TEXT: 'per user per',
};

export const TIME_PARTINGS_LIST = {
  LABEL: 'Time Parting',
  ADD_TIME_PARTING_BUTTON: '+ Add time parting',
};

export const TIME_PARTING_CONTAINER = {
  DAYS_LABEL: 'Days of the week',
  MINUTES_PLACEHOLDER: '00',
  START_TIME_LABEL: 'Start time (UTC)',
  END_TIME_LABEL: 'End time (UTC)',
  TIME_ERROR: 'The start time must be before the end time',
  TIME_RANGE_ERROR: (time: string) => `The end time ${time} is overlaped in another time parting start time`,
};

export const TIMEZONE_DELIVERING = {
  LABEL: 'Timezone Delivering',
};

export enum UserCappingType {
  Requests = 'Requests',
  Impressions = 'Impressions',
  // ErrorCap = 'Error',
}

export enum UserCappingTimeType {
  Hours = 'Hours',
  Days = 'Days',
  Lifetime = 'Lifetime',
}

export enum CappingTimeType {
  Hours = 'Hourly',
  Days = 'Daily',
  Lifetime = 'Lifetime',
}

export enum TimezoneDelivering {
  London = 'London',
  NewYork = 'New York',
  LosAngeles = 'Los Angeles',
}

export enum TimePartingWeekdays {
  All = 'All',
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
  Sunday = 'Sun',
}

export enum CreativeOptimization {
  CompletionRate = 'Completion rate',
  AsManyAsPossible = 'As many as possible',
  Evenly = 'Evenly (default)',
  CTR = 'CTR',
  Viewability = 'Viewability',
  ErrorRate = 'Error rate',
  Weighted = 'Weighted',
}

export const ACTIVATE_LINE_ITEM_MODAL = {
  DEFAULT_TITLE: 'Activate Line Item',
  WARNING_TITLE: 'Hey, Wait!',
  DEFAULT_DESCRIPTION: 'Are you sure you want to activate this line item?',
  WARNING_DESCRIPTION: 'Are you sure you wish to activate this Line Item without adding supply targeting?',
  DEFAULT_CONFIRM_BUTTON: 'Yes',
  WARNING_CONFIRM_BUTTON: 'Yes, proceed',
  DEFAULT_CANCEL_BUTTON: 'Cancel',
  WARNING_CANCEL_BUTTON: 'No, go back',
};

export const PAUSE_LINE_ITEM_MODAL = {
  TITLE: 'Pause Line Item',
  DESCRIPTION: 'Are you sure you want to pause this line item?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const ARCHIVE_LINE_ITEM_MODAL = {
  TITLE: 'Archive Line Item',
  DESCRIPTION: 'Are you sure you want to archive this line item?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const UNARCHIVE_LINE_ITEM_MODAL = {
  TITLE: 'Unarchive Line Item',
  DESCRIPTION: 'Are you sure you want to unarchive this line item?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const CLONE_LINE_ITEM_MODAL = {
  TITLE: 'Clone Line Item',
  DESCRIPTION: 'Are you sure you want to clone this line item?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const ADD_CREATIVES_MODAL = {
  TITLE: 'Add Creatives',
  CONFIRM_BUTTON: 'Add Creatives',
  EXPLANATORY_TEXT: 'Options are naturally limited to creatives that align with the line item’s advertiser and type settings',
};

export enum LineItemArchiveFilterOptions {
  Unarchived = 'Unarchived Line items',
  Archived = 'Archived Line items',
}

export const LINE_ITEMS_NOTIFICATIONS = {
  CLONE_LINE_ITEM_SUCCESS_MESSAGE: 'The line item has been cloned successfully',
  CLONE_LINE_ITEM_FAILURE_MESSAGE: 'A line item with the same name already exists. Please rename the previous copy before trying to clone again.',
  UNARCHIVE_LINE_ITEM_SUCCESS_MESSAGE: 'The line item has been unarchived successfully',
  ARCHIVE_LINE_ITEM_SUCCESS_MESSAGE: 'The line item has been archived successfully',
  ACTIVATE_LINE_ITEM_FAILURE_MESSAGE_REASON_CREATIVES: 'Line item must have at least one active creative',
  ACTIVATE_LINE_ITEM_FAILURE_MESSAGE_REASON_DATES: 'Ensure accurate dates: Line items require valid start and end dates',
  ACTIVATE_LINE_ITEM_SUCCESS_MESSAGE: 'The line item has been activated successfully',
  PAUSE_LINE_ITEM_SUCCESS_MESSAGE: 'The line item has been paused successfully',
  COPY_LINE_ITEM_ID_SUCCESS_MESSAGE: 'Line item ID was copied to your clipboard',
  BULK_LINE_ITEMS_APPLY_PERSET_SUCCESS_MESSAGE: 'Preset successfully applied to all line items',
  BULK_LINE_ITEMS_APPLY_PERSET_FAILURE_MESSAGE: 'The process has failed, and none of the changes were saved. please try again later.',
  BULK_LINE_ITEMS_APPLY_STATUS_SUCCESS_MESSAGE: 'Status successfully applied to all selected line items',
  BULK_LINE_ITEMS_APPLY_STATUS_FAILURE_MESSAGE: 'The process has failed, and none of the changes were saved. please try again later.',
};

export const getActivateLineItemErrorText = (errorCode?: number) => {
  switch (errorCode) {
    case HTTP_STATUS_CODES.BAD_REQUEST:
      return LINE_ITEMS_NOTIFICATIONS.ACTIVATE_LINE_ITEM_FAILURE_MESSAGE_REASON_DATES;
    case HTTP_STATUS_CODES.CONFLICT:
      return LINE_ITEMS_NOTIFICATIONS.ACTIVATE_LINE_ITEM_FAILURE_MESSAGE_REASON_CREATIVES;
    default:
      return ERRORS.GENERAL_SERVER_ERROR;
  }
};

export const CREATIVES_TABLE_PAGE = {
  TITLE: 'Creatives',
  CREATE_CREATIVE_BUTTON: 'New Creative',
};

export const CREATIVES_TABLE = {
  NAME_COLUMN_LABEL: 'Name',
  ADVERTISER_COLUMN_LABEL: 'Advertiser',
  TYPE_COLUMN_LABEL: 'Type',
  ID_COLUMN_LABEL: 'ID',
  LAST_MODIFIED_COLUMN_LABEL: 'Last Modified',
  UPLOAD_STATUS_COLUMN_LABEL: 'Upload Status',
  ACTIONS_MENU_COLUMN_LABEL: '',
  NO_DATA_MESSAGE: 'Looks like there aren\'t any creatives yet',
  NO_DATA_FOR_SEARCH_MESSAGE: 'Looks like there aren\'t any matches for your search',
};

export const CREATIVES_TABLE_ACTIONS_MENU = {
  EDIT: 'Edit',
  COPY_VAST_URL: 'Copy VAST URL',
  CLONE: 'Clone',
};

export enum SubPriority {
  Low = 'Low',
  High = 'High',
}

export const SUB_PRIORITY_CONTAINER = {
  LABEL: 'Sub Priority',
};

export enum Pacing {
  Even = 'Even',
  Frontload = 'Custom',
  ASAP = 'ASAP',
}

export const PACING_DEFAULT_FRONTLOAD_COEFFICIENT = '125';

export const PACING_CONTAINER = {
  PACING_LABEL: 'Pacing',
  FRONTLOAD_VALUE_LABEL: 'Value (%)',
  FRONTLOAD_VALUE_TOOLTIP: 'Value between 1% to 200%',
};

export const IMPRESSION_CAP_CONTAINER = {
  LABEL: 'Impression Cap',
  PLACEHOLDER: 'Enter Impression Cap',
};

export const REQUEST_CAP_CONTAINER = {
  LABEL: 'Request Cap',
  PLACEHOLDER: 'Enter Request Cap',
};

export const CAPPING_CONTAINER = {
  OPTIONAL_LABEL: '(optional)',
  INVALID_CAPPING_VALUE_ERROR: 'This field should be a positive integer (Minimum of 1)',
};

export const CREATIVE_PAGE_HEADER = {
  CREATE_TITLE: 'New Creative',
  EDIT_TITLE: 'Edit Creative',
  CANCEL_BUTTON: 'Cancel',
  CREATE_BUTTON: 'Create',
  UPDATE_BUTTON: 'Update',
  PUBLISH_BUTTON_TOOLTIP_NO_CHANGES: 'No changes were made',
  PUBLISH_BUTTON_TOOLTIP_INVALID_FIELDS_TITLE: 'Please fix invalid fields:',
  NAME_MESSAGE: 'Creative Name',
  ADVERTISER_MESSAGE: 'Advertiser',
  TYPE_MESSAGE: 'Creative Type',
  CONTENT_OR_PLAYLIST_ID_MESSAGE: 'Content/Playlist ID',
  ADVERTISER_VAST_URL_MESSAGE: 'Advertiser VAST URL',
  UNREACHABLE_VAST_MESSAGE: 'Unreachable Advertiser VAST URL',
  CLICK_THROUGH_URL_MESSAGE: 'Click Through URL',
  VIDEO_FILE_MESSAGE: 'Video File',
  PIXELS_MESSAGE: 'Pixels',
  SKIP_OFFSET_MESSAGE: 'Invalid skip offset',
  INVALID_IMAGE: 'Invalid Image',
  COMPANION_AD_CLICK_THROUGH_URL: 'Companion Ad Click Through URL',
  COMPANION_AD_CREATIVE_VIEW_PIXEL: 'Companion Ad Creative View Pixel',
  WIDGET_SCRIPT: 'Widget Script',
  WIDGET_SIZE: 'Widget Size',
};

export const CREATIVE_SETTINGS = {
  LAST_MODIFIED: 'Last modified on',
  CREATIVE_NAME_LABEL: 'Creative Name',
  CREATIVE_NAME_PLACEHOLDER: 'Enter Creative Name',
  CREATIVE_NAME_CONFLICT_ERROR: 'Please insert a unique name',
  CREATIVE_DESCRIPTION_LABEL: 'Creative Description',
  CREATIVE_DESCRIPTION_PLACEHOLDER: 'Enter description',
  CREATIVE_TYPE_LABEL: 'Creative Type',
  CREATIVE_TYPE_PLACEHOLDER: 'Choose Creative Type',
  ADVERTISER_LABEL: 'Advertiser',
  ADVERTISER_PLACEHOLDER: 'Choose Advertiser',
  ADD_ADVERTISER_BUTTON: '+ Add new advertiser',
  VAST_SOURCE_LABEL: 'VAST Source',
};

export const CREATIVE_PAGE_PREVIEW = {
  COPY_VAST_URL_BUTTON: 'Copy VAST URL',
  CREATIVE_TYPE_LABEL: 'Creative Type',
  ADVERTISER_LABEL: 'Advertiser',
  VAST_SOURCE_LABEL: 'VAST Source',
  VIDEO_ID_LABEL: 'Video ID',
  VIDEO_LOADING_MESSAGE: 'Loading...',
  VIDEO_PROCESSING_MESSAGE: 'The video upload is still\nprocessing. Please wait.',
  VIDEO_FAILED_MESSAGE: 'The video upload has failed.\nPlease try again',
  VIDEO_UNAVAILABLE_MESSAGE: 'Video preview is unavailable\nfor external VAST',
};

export enum VideoStatus {
  Loading = 'Loading',
  Ready = 'Ready',
  Processing = 'Processing',
  Failed = 'Failed',
}

export const LINE_ITEM_DETAILS = {
  CAMPAIGN: 'Campaign',
  LINE_ITEM: 'Line Item',
};

export const CREATIVE_ADVERTISER_VAST_URL_INPUT = {
  LABEL: 'Advertiser VAST URL',
  PLACEHOLDER: 'Enter Advertiser VAST URL',
  MACRO_REPLACEMENT_PREVIEW: 'Macro Replacement Preview:',
  SHOW_MACROS_TABLE_BUTTON: 'Show macros table',
  HIDE_MACROS_TABLE_BUTTON: 'Hide macros table',
  INVALID_MACROS_ERROR: 'This VAST formatting is not recognized by Minute Media',
};

export const CREATIVE_CONTENT_OR_PLAYLIST_ID_INPUT = {
  LABEL: 'Content / Playlist ID',
  PLACEHOLDER: 'Enter Content / Playlist ID',
  INVALID_VIDEO_ID_ERROR: 'This is not a valid ID',
  RESTRICTION_MESSAGE: 'Video is subject to VMS restrictions. Ensure restriction and line item targeting align to avoid playback issues',
};

export const PIXELS_LIST = {
  LABEL: 'Pixels',
  ADD_PIXEL_BUTTON: 'Add Pixel',
};

export const PIXEL_CONTAINER = {
  NAME_LABEL: 'Pixel Name',
  NAME_PLACEHOLDER: 'Select pixel name',
  VALUE_LABEL: 'Value',
  VALUE_PLACEHOLDER: 'Enter value',
  NON_UNIQUE_URL_ERROR: 'Pixel must be unique',
};

export const CREATIVE_WIDGET_SCRIPT_CONTAINER = {
  SCRIPT_LABEL: 'Widget Script',
  SCRIPT_PLACEHOLDER: 'Enter Widget Script',
  SIZE_LABEL: 'Creative Size',
  SIZE_PLACEHOLDER: 'Choose Size',
};

export enum VideoAdPixelOptions {
  Impression = 'Impression',
  Start = 'Start',
  FirstQuartile = 'First Quartile',
  SecondQuartile = 'Second Quartile/Midpoint',
  ThirdQuartile = 'Third Quartile',
  Complete = 'Complete',
  ClickTracking = 'Click Tracking',
}

export enum PromotedContentPixelOptions {
  VideoStart = 'Video Start',
  Impression = 'Impression (3 second view)',
  ViewableEvent = 'Viewable Event',
}

export const CREATIVE_VIDEO_FILE_PICKER = {
  SELECT_FILE_DESCRIPTION: 'Select the relevant file saved on your computer',
  FILE_PICKER_BUTTON_TEXT: 'Upload a video',
  SUPPORTED_FILETYPES: 'Supported Filetypes: mp4, mov',
};

export const CREATIVE_IMAGE_FILE_PICKER = {
  SELECT_FILE_DESCRIPTION: 'Select the relevant file saved on your computer',
  FILE_PICKER_BUTTON_TEXT: 'Upload Image',
  SECONDARY_TEXT: (types: string[]) => `300X60 px; Size < 150k; Supported Filetypes: ${types.join(', ')}`,
};

export const CREATIVE_MOAT_TRACKING_CHECKBOX = {
  LABEL: 'MOAT Tracking',
  TOOLTIP: 'Enable MOAT for viewability and IVT measurement. Incurs additional ad serving fees',
};

export const CREATIVE_COMING_NEXT = {
  LABEL: 'Coming Next',
  TOOLTIP: 'Display messaging during ad playback to give users more info about the upcoming video content',
};

export const CREATIVE_CLICK_THROUGH_URL_INPUT = {
  LABEL: 'Click Through URL',
  PLACEHOLDER: 'Enter Click Through URL',
  RESTRICTION_MESSAGE: 'Warning - We\'re unable to reach the clickthrough URL, it is recommended to contact the advertiser.',
};

export const CREATIVE_LINE_ITEMS_DROPDOWN = {
  LABEL: 'Line Items',
  PLACEHOLDER: 'Choose Line Items',
};

export const CREATIVE_EXISTING_LINE_ITEMS = {
  TITLE: 'Existing Line Items',
  NOTIFICATION: '(Can\'t be deleted):',
};

export const MACRO_REPLACEMENT_INFO = {
  TITLE: 'Macro Replacement',
  MACROS_DESCRIPTION: 'Macros are placeholders that our ad server replaces with a real value when the creative serves.',
  TABLE_DESCRIPTION: 'The table below shows all macros supported in Voltax.',
};

export const MACRO_REPLACEMENT_TABLE = {
  NAME_COLUMN_LABEL: 'Macro Name',
  VOLTAX_FORMAT_COLUMN_LABEL: 'Voltax Macro Format',
};

export const VOLTAX_FORMAT_MACROS = {
  HOSTNAME: 'Hostname',
  PAGE_URL: 'PageUrl',
  GDPR_APPLIES: 'GDPR',
  GDPR_CONSENT_STRING: 'GDPRConsent',
  CACHEBUSTER: 'CacheBuster',
  PLAYER_WIDTH: 'PlayerWidth',
  PLAYER_HEIGHT: 'PlayerHeight',
  EXTERNAL_VIDEO_ID: 'ExternalID',
  US_PRIVACY_STRING: 'USPrivacy',
  SUPPLY_CHAIN: 'SupplyChain',
  CUSTOM_PARAMETERS: 'CustParams',
};

export const MACRO_NAMES = {
  HOSTNAME: 'Hostname',
  PAGE_URL: 'Page URL',
  GDPR_APPLIES: 'GDPR Applies',
  GDPR_CONSENT_STRING: 'GDPR Consent String',
  CACHEBUSTER: 'Cachebuster',
  PLAYER_WIDTH: 'Player Width',
  PLAYER_HEIGHT: 'Player Height',
  EXTERNAL_VIDEO_ID: 'External Video GUID',
  US_PRIVACY_STRING: 'US Privacy String',
  SUPPLY_CHAIN: 'Supply Chain',
  CUSTOM_PARAMETERS: 'Custom Parameters',
};

export const CREATIVE_PAGE_DISCARD_CHANGES_MODAL = {
  TITLE: 'Hey, Wait!',
  CREATE_DESCRIPTION: 'Are you sure you wish to go back without creating a creative?\nNothing will be saved.',
  EDIT_DESCRIPTION: 'Are you sure you wish to leave this page without saving your changes?',
  CREATE_CONFIRM_BUTTON: 'Yes, go back',
  EDIT_CONFIRM_BUTTON: 'Yes, leave',
  CANCEL_BUTTON: 'No, stay',
};

export const CREATIVES_NOTIFICATIONS = {
  CREATE_CREATIVE_SUCCESS_MESSAGE: 'The creative was created successfully',
  UPDATE_CREATIVE_SUCCESS_MESSAGE: 'The creative was updated successfully',
  PAUSE_CREATIVE_ERROR_MESSAGE: 'Delivering line items must have at least one active creative',
  COPY_VAST_URL_SUCCESS_MESSAGE: 'VAST URL was copied to your clipboard',
  REMOVE_CREATIVE_SUCCESS_MESSAGE: 'Creative removed successfully',
  CLONE_CREATIVE_SUCCESS_MESSAGE: 'The creative was cloned successfully',
  UNREACHABLE_VAST_ERROR: 'We\'re unable to reach the VAST URL, please contact the advertiser',
};

export const SUPPLY_TARGETING_SECTION = {
  ADD_SUPPLY_TARGETING_BUTTON: '+ Add Supply Targeting',
  TITLE: 'Supply Targeting',
  EDIT_BUTTON: 'Edit',
};

export const SUPPLY_TARGETING_MODAL_PRESET_NAME = {
  PLACEHOLDER: 'Enter preset name',
  UNIQUE_NAME_CONFLICT_ERROR: 'Please insert a unique name',
};

export const SUPPLY_TARGETING_PRESETS_DROPDOWN = {
  PLACEHOLDER: 'Load targeting preset (optional)',
  FORECASTING_PLACEHOLDER: 'Load targeting preset',
  OVERWRITE_PRESET_MODAL_TITLE: 'Hey, wait!',
  OVERWRITE_PRESET_MODAL_DESCRIPTION: 'Are you sure you wish to overwrite your changes with the selected preset?',
  OVERWRITE_PRESET_MODAL_CONFIRM_BUTTON: 'Yes',
  OVERWRITE_PRESET_MODAL_CANCEL_BUTTON: 'No',
};

export const SUPPLY_TARGETING_MODAL_HEADER = {
  SUPPLY_TARGETING_TITLE: 'Supply Targeting',
  CREATE_PRESET_TITLE: 'New targeting preset',
  EDIT_PRESET_TITLE: 'Edit targeting preset',
  CANCEL_BUTTON: 'Cancel',
  ADD_BUTTON: 'Add',
  CREATE_BUTTON: 'Create',
  SAVE_BUTTON: 'Save',
  DONE_BUTTON_TOOLTIP_NO_CHANGES: 'No changes were made',
  DONE_BUTTON_TOOLTIP_INVALID_FIELDS: 'Please fix invalid fields:',
  NAME_MESSAGE: 'Name',
  INVENTORY_MESSAGE: 'Inventory',
  GEOGRAPHY_MESSAGE: 'Geography',
  ZIPS_MESSAGE: 'Zip Targeting',
  BROWSER_MESSAGE: 'Browser',
  OPERATING_SYSTEM_MESSAGE: 'Operation system',
  URL_MESSAGE: 'URL',
  UTM_MESSAGE: 'UTM',
  VOLTAX_METADATA_MESSAGE: 'Voltax Metadata',
  VIDEO_ID_MESSAGE: 'Video ID',
  USER_IDENTIFIER_MESSAGE: 'User identifier',
  AD_SLOTS_MESSAGE: 'Ad Slot',
  CONTENT_PROVIDER_MESSAGE: 'Content Provider',
  VIDEO_TAG_MESSAGE: 'Video Tag',
  CUSTOM_TARGETING_MESSAGE: 'Custom Targeting',
};

export const SUPPLY_TARGETING_DISCARD_CHANGES_MODAL = {
  TITLE: 'Hey, Wait!',
  DESCRIPTION: 'Are you sure you wish to leave this page without saving your changes?',
  CONFIRM_BUTTON: 'Yes, go back',
  CANCEL_BUTTON: 'No, stay',
};

export const SAVE_AS_PRESET_CONTAINER = {
  SAVE_AS_PRESET_BUTTON: 'Save as preset',
  CANCEL_BUTTON: 'Cancel',
  SAVE_BUTTON: 'Save',
  PRESET_NAME_PLACEHOLDER: 'Enter preset name',
  SAVE_BUTTON_TOOLTIP_INVALID_FIELDS: 'Please fix invalid fields:',
  NAME_MESSAGE: 'Name',
};

export const SUPPLY_TARGETING_EXCLUDE_INCLUDE_TOGGLE = {
  EXCLUDE_LABEL: 'Exclude',
  INCLUDE_LABEL: 'Include',
};

export const SUPPLY_TARGETING_MODAL_SECTIONS = {
  INVENTORY: 'Inventory',
  AD_UNIT: 'Ad Unit',
  GEOGRAPHY: 'Geography',
  DMA_REGIONS: 'DMA Regions (US only)',
  ZIPS: 'Zip Targeting (US Only)',
  DEVICE_CATEGORY: 'Device Category',
  BROWSER: 'Browser',
  LANGUAGE: 'Browser Language',
  OPERATION_SYSTEM: 'Operation System',
  URL: 'URL',
  UTM: 'UTM',
  AUDIENCE: 'Audience',
  VIDEO_ID: 'Video ID',
  VOLTAX_METADATA: 'Voltax Metadata',
  USER_IDENTIFIER: 'User Identifier',
  PLAYER_SIZE: 'Player Size',
  AD_SLOT: 'Ad Slot',
  PLAYER_BEHAVIOR: 'Player Behavior',
  CONTENT_PROVIDER: 'Video Content Provider',
  VIDEO_TAG: 'Video Tag',
  JOUNCE_CLASSIFICATION: 'Jounce Classification',
  CUSTOM_TARGETING: 'Custom Targeting',
};

export const SUPPLY_TARGETING_MODAL_SELECTION_TREE_SECTION = {
  SEARCH_PLACEHOLDER: 'Search...',
};

export const SUPPLY_TARGETING_MODAL_URL_SECTION = {
  PLACEHOLDER: 'Add URL',
  LABEL_TEXT: 'Add URL targeting below, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_CONTENT_TAG_SECTION = {
  PLACEHOLDER: 'Add content tag',
  LABEL_TEXT: 'Add content tag below, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_COMMERCIAL_TAG_SECTION = {
  PLACEHOLDER: 'Add commercial tag',
  LABEL_TEXT: 'Add commercial tag below, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_DISTRIBUTION_CHANNEL_SECTION = {
  PLACEHOLDER: 'Add distribution channel',
  LABEL_TEXT: 'Add distribution channel targeting below, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_VIDEO_ID_SECTION = {
  PLACEHOLDER: 'Add video ID',
  LABEL_TEXT: 'Add video ID targeting below, then press enter to apply',
  INVALID_VIDEO_ID_ERROR: 'This is not a valid video ID',
};

export const SUPPLY_TARGETING_MODAL_UTM_SECTION = {
  TYPE_LABEL_TEXT: 'UTM Type',
  TYPE_PLACEHOLDER: 'Choose type',
  DESCRIPTION_TEXT: 'Choose UTM type.\nPress enter to apply one or more targeting values for each type.',
  INPUT_LABEL_TEXT: 'UTM Targeting',
  INPUT_PLACEHOLDER: 'Add UTM',
};

export enum UtmType {
  Source = 'UTM source',
  Medium = 'UTM medium',
  Campaign = 'UTM campaign',
  Term = 'UTM term',
  Content = 'UTM content',
}

export const SUPPLY_TARGETING_MODAL_VOLTAX_SECTION = {
  TYPE_LABEL_TEXT: 'Metadata Type',
  TYPE_PLACEHOLDER: 'Choose type',
  DESCRIPTION_TEXT: 'Choose metadata type.\nPress enter to apply one or more targeting values for each type.',
  INPUT_LABEL_TEXT: 'Metadata Targeting',
  INPUT_PLACEHOLDER: 'Add Metadata',
};

export enum VoltaxType {
  ContentTag = 'Content Tag',
  CommercialTag = 'Commercial Tag',
  ArticleID = 'Article ID',
  DistributionChannel = 'Distribution Channel',
}

export const SUPPLY_TARGETING_MODAL_ARTICLE_ID_SECTION = {
  PLACEHOLDER: 'Add article ID',
  LABEL_TEXT: 'Add article ID targeting below, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_USER_IDENTIFIER_SECTION = {
  BETWEEN: 'Between',
  RANGE_SEPARATOR: '-',
  INVALID_USER_IDENTIFIER_VALUES_ERROR: 'First integer must be of equal or lower value',
};

export const SUPPLY_TARGETING_MODAL_ZIP_SECTION = {
  PLACEHOLDER: 'Add Zip Code',
  LABEL_TEXT: 'Add Zip Code Targeting below, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_AD_SLOTS_SECTION = {
  PLACEHOLDER: 'Add an Ad Slot',
  LABEL_TEXT: 'Add a number between 1 and 9, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_CONTENT_PROVIDER_SECTION = {
  PLACEHOLDER: 'Add a Video Content Provider',
  LABEL_TEXT: 'Add Video Content Provider below, then press enter to apply',
};

export const SUPPLY_TARGETING_MODAL_VIDEO_TAG_SECTION = {
  INCLUDED_PLACEHOLDER: 'Add an included Video Tag',
  INCLUDED_LABEL_TEXT: 'Include',
  EXCLUDED_PLACEHOLDER: 'Add an excluded Video Tag',
  EXCLUDED_LABEL_TEXT: 'Exclude',
};

export const SUPPLY_TARGETING_MODAL_CUSTOM_TARGETING_SECTION = {
  DESCRIPTION_TEXT: 'Enter Custom Targeting key.\nPress enter to apply one or more targeting values for each key.',
  INCLUDE: 'Include',
  EXCLUDE: 'Exclude',
  KEY_PLACEHOLDER: 'Enter Target Key',
  KEY_LABEL: 'Target Key',
  INPUT_PLACEHOLDER: 'Add Target',
  INPUT_LABEL_TEXT: 'Target',
  NON_UNIQUE_KEY_ERROR: 'Key must be unique',
  KEY_AND_VALUE_ALREADY_USED: 'Key and value already used',
};

export const LINE_ITEM_PAGE_DISCARD_CHANGES_MODAL = {
  TITLE: 'Hey, Wait!',
  CREATE_DESCRIPTION: 'Are you sure you wish to go back without creating a line item?\nNothing will be saved.',
  EDIT_DESCRIPTION: 'Are you sure you wish to leave this page without saving your changes?',
  CREATE_CONFIRM_BUTTON: 'Yes, go back',
  EDIT_CONFIRM_BUTTON: 'Yes, leave',
  CANCEL_BUTTON: 'No, stay',
};

export const LINE_ITEM_PAGE_UPDATE_MODAL = {
  TITLE: 'Hey, Wait!',
  DESCRIPTION: 'Are you sure you wish to save this Line Item without adding supply targeting?',
  CONFIRM_BUTTON: 'Yes, proceed',
  CANCEL_BUTTON: 'No, go back',
};

export const AD_UNITS_TABLE_PAGE_HEADER = {
  TITLE: 'Ad Units',
  CREATE_AD_UNIT_BUTTON: 'New Ad Unit',
  CREATE_BUTTON_TOOLTIP: 'You cannot create a new ad unit without a property',
};

export const SUPPLY_TARGETING_SUMMARY = {
  SELECTED_TARGETING: 'Selected targeting',
  INCLUDE: 'Include',
  EXCLUDE: 'Exclude',
  getUserIdentifierData: (from: string, to: string) => (`Between ${from}-${to}`),
};

export enum AdUnitStatusFilterOptions {
  Unarchived = 'Unarchived Ad Units',
  Archived = 'Archived Ad Units',
}

export const AD_UNITS_TABLE = {
  NO_DATA_MESSAGE: 'Looks like there aren\'t any ad units yet',
  NO_DATA_FOR_SEARCH: 'Looks like there aren\'t any matches for your search',
  NO_UNARCHIVED_AD_UNITS_MESSAGE: 'Looks like there aren\'t any unarchived ad units',
  NAME_COLUMN_LABEL: 'Name',
  ID_COLUMN_LABEL: 'ID',
  LAST_MODIFIED_COLUMN_LABEL: 'Last Modified',
  ACTIONS_MENU_COLUMN_LABEL: '',
};

export const AD_UNITS_TABLE_ACTIONS_MENU = {
  EDIT: 'Edit',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  COPY_URL: 'Copy Ad Unit URL',
};

export const AD_UNIT_MODAL = {
  EDIT_TITLE: 'Edit Ad Unit',
  CREATE_TITLE: 'New Ad Unit',
  PUBLISH_BUTTON_TOOLTIP_NO_CHANGES: 'No changes were made',
  PUBLISH_BUTTON_TOOLTIP_INVALID_NAME: 'Please fill in a valid name',
  CANCEL_BUTTON: 'Cancel',
  CREATE_BUTTON: 'Create',
  UPDATE_BUTTON: 'Update',
};

export const AD_UNIT_NAME_INPUT = {
  LABEL: 'Ad Unit Name',
  PLACEHOLDER: 'Enter Ad Unit Name',
  TOOLTIP_CONTENT: 'Give your ad unit a descriptive name (no special characters or spaces are allowed).',
  UNIQUE_NAME_CONFLICT_ERROR: 'Please insert a unique name',
  PREVIEW_NAME_LABEL: 'Preview Name:',
};

export const AD_UNITS_NOTIFICATIONS = {
  CREATE_AD_UNIT_SUCCESS_MESSAGE: 'The new ad unit was created successfully',
  UPDATE_AD_UNIT_SUCCESS_MESSAGE: 'The ad unit was updated successfully',
  ARCHIVE_AD_UNIT_SUCCESS_MESSAGE: 'The ad unit has been archived successfully',
  UNARCHIVE_AD_UNIT_SUCCESS_MESSAGE: 'The ad unit has been unarchived successfully',
  COPY_AD_UNIT_URL_SUCCESS_MESSAGE: 'The ad unit URL was copied successfully',
};

export const ARCHIVE_AD_UNIT_MODAL = {
  TITLE: 'Archive Ad Unit',
  DESCRIPTION: 'Are you sure you want to archive this ad unit? The ad unit will no longer send eligible requests if it is implemented in production. Any line items targeted to this supply will stop delivering.',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const UNARCHIVE_AD_UNIT_MODAL = {
  TITLE: 'Unarchive Ad Unit',
  DESCRIPTION: 'Are you sure you wish to restore this ad unit? The ad unit could begin serving impressions again, from line items targeted to this supply.',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const VIEW_MODE_DELIVERY_DATA = {
  IMPRESSIONS: 'Impressions',
  CTR: 'CTR',
  VIEWABILITY: 'Viewability',
  COMPLETION: 'Completion',
};

export enum CreativeStatus {
  Active = 'Active',
  Paused = 'Paused',
}

export const ALL_CREATIVES_STATUSES = 'All Statuses' as CreativeStatus;

export enum VastSource {
  AdvertiserVastUrl = 'Advertiser VAST URL',
  VideoFile = 'Video File',
}

export const LINE_ITEM_CREATIVES_TABLE = {
  NAME_COLUMN_LABEL: 'Name',
  DELIVERED_COLUMN_LABEL: 'Delivered',
  CTR_COLUMN_LABEL: 'CTR',
  VIEWABILITY_COLUMN_LABEL: 'Viewability',
  COMPLETION_COLUMN_LABEL: 'Completion',
  ID_COLUMN_LABEL: 'ID',
  WEIGHT_COLUMN_LABEL: 'Weight',
  STATUS_COLUMN_LABEL: 'Status',
  LAST_MODIFIED_COLUMN_LABEL: 'Last Modified',
  ACTIONS_MENU_COLUMN_LABEL: '',
  NO_DATA_FOR_SEARCH_MESSAGE: 'Looks like there aren\'t any matches for your search',
};

export const LINE_ITEM_CREATIVES_TABLE_ACTIONS_MENU = {
  EDIT: 'Edit',
  ACTIVATE: 'Activate',
  PAUSE: 'Pause',
  REMOVE: 'Remove',
  CLONE: 'Clone',
};

export const LINE_ITEM_EMPTY_CREATIVES = {
  NO_CREATIVES_TEXT: 'Looks like you don\'t have any creatives',
  DISABLE_CREATE_CREATIVE_BUTTON_TOOLTIP: 'The line item must be unarchived',
};

export const ACTIVATE_CREATIVE_MODAL = {
  TITLE: 'Activate Creative',
  DESCRIPTION: 'Are you sure you want to activate this creative?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const PAUSE_CREATIVE_MODAL = {
  TITLE: 'Pause Creative',
  DESCRIPTION: 'Are you sure you want to pause this creative?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const REMOVE_CREATIVE_MODAL = {
  TITLE: 'Hey, Wait!',
  DESCRIPTION: 'Are you sure you wish to remove the creative from this line item?',
  CONFIRM_BUTTON: 'Yes, proceed',
  CANCEL_BUTTON: 'No, go back',
};

export const CLONE_CREATIVE_MODAL = {
  TITLE: 'Clone Creative',
  DESCRIPTION: 'Are you sure you want to clone this creative?',
  CONFIRM_BUTTON: 'Yes',
  CANCEL_BUTTON: 'Cancel',
};

export const TARGETING_PRESETS_TABLE_PAGE = {
  TITLE: 'Targeting Presets',
  CREATE_PRESET_BUTTON: 'New Preset',
};

export const TARGETING_PRESETS_TABLE = {
  NAME_COLUMN_LABEL: 'Name',
  ID_COLUMN_LABEL: 'ID',
  LAST_MODIFIED_COLUMN_LABEL: 'Last Modified',
  ACTIONS_MENU_COLUMN_LABEL: '',
  NO_DATA_MESSAGE: 'Looks like there aren\'t any targeting presets yet',
  NO_DATA_FOR_SEARCH_MESSAGE: 'Looks like there aren\'t any matches for your search',
};

export const TARGETING_PRESETS_TABLE_ACTIONS_MENU = {
  EDIT: 'Edit',
  DELETE: 'Delete',
};

export const PRESETS_NOTIFICATIONS = {
  CREATE_PRESET_SUCCESS_MESSAGE: 'The preset was created successfully',
  CREATE_PRESET_FAILURE_MESSAGE: 'Failed to create new preset',
  UPDATE_PRESET_SUCCESS_MESSAGE: 'The preset was updated successfully',
  UPDATE_PRESET_FAILURE_MESSAGE: 'Failed to update the preset',
};

export const DELETE_PRESET_MODAL = {
  TITLE: 'Hey, Wait!',
  DESCRIPTION: 'This preset will be permanently deleted. This action cannot be undone! Are you sure you want to continue?',
  CONFIRM_BUTTON: 'Yes, I\'m sure',
  CANCEL_BUTTON: 'No, go back',
};

export const AD_SERVER_VALIDATION_ERRORS = {
  MANDATORY_FIELD: 'This field is mandatory',
  INVALID_POSITIVE_INTEGER: 'This field should be a positive integer (Minimum of 1)',
  INVALID_POSITIVE_INTEGER_AND_ZERO: 'This field should be a positive integer (Minimum of 0)',
  SPECIAL_CHARS_NOT_PERMITTED: 'Special characters are not permitted',
  SPECIAL_CHARS_NOT_PERMITTED_EXCEPT_SPACES: 'Special characters are not permitted, except spaces',
  SPECIAL_CHARS_EXCEPT_UNDERSCORE_AND_PERIOD_NOT_PREMITTED: 'Special characters are not permitted, except underscores and periods',
  SPECIAL_CHARS_EXCEPT_UNDERSCORE_AND_DASH_NOT_PERMITTED: 'Special characters are not permitted, except underscores and dashes',
  SPECIAL_CHARS_NOT_PREMITTED_EXCEPT_PERIODS: 'Special characters are not permitted, except periods',
  SPACES_NOT_PREMITTED: 'Spaces are not permitted',
  SOME_SPECIAL_CHARS_NOT_PREMITTED: 'This field can only contain the following special characters: _@./#&+-|()\'$',
  INVALID_POSITIVE_FLOAT: 'This field should be a positive number, and must not exceed 2 decimal places',
  INVALID_SECURED_URL_ERROR: 'URL must start with https:// and contain no spaces',
  INVALID_NUMBER_RANGE: (min: number, max: number) => (max !== Infinity ? `Integer must be between ${min} and ${max}` : `Integer must be ${min} or greater`),
  INVALID_LENGTH: 'Some of the following zips are in an invalid length',
  NOT_ONLY_DIGITS: 'Some of the following zips contain non-digit characters',
};

export const MONITORING_TABLE_PAGE_HEADER = {
  TITLE: 'Line Item Monitoring',
  LAST_UPDATE: 'Last Update',
  HIGH_LABEL: 'HIGH',
  MEDIUM_LABEL: 'MEDIUM',
  LOW_LABEL: 'LOW',
};

export enum MonitoringPriorityType {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export const ALL_MONITORING_PRIORITY_TYPES = 'All Priority Types' as MonitoringPriorityType;

export const MONITORING_TABLE = {
  PRIORITY_TYPE_COLUMN_LABEL: 'Priority',
  LINE_ITEM_NAME_COLUMN_LABEL: 'Line Item Name',
  NAME_COLUMN_LABEL: 'Alert Name',
  STATUS_COLUMN_LABEL: 'Status',
  ACTIONS_MENU_COLUMN_LABEL: '',
  NO_DATA_MESSAGE: 'Looks like there aren\'t any alerts',
  NO_DATA_FOR_SEARCH_MESSAGE: 'Looks like there aren\'t any matches for your search',
};

export enum LineItemAlertsKPIs {
  Viewability = 'Viewability',
  CTR = 'CTR',
  ErrorRate = 'Error Rate',
  CompletionRate = 'Completion Rate',
  PacingName = 'Pacing',
}

export const MONITORING_TABLE_ALERT_NAME = {
  VIEWABILITY: 'Viewability below 70%',
  CTR: 'CTR below 0.01%',
  ERROR_RATE: 'Error Rate above 15%',
  COMPLETION_RATE: 'Completion Rate below 70%',
  PACING: 'Pacing critically, may underdeliver',
};

export const MONITORING_TABLE_ACTIONS_MENU = {
  COPY_ID_TOOLTIP: 'Copy ID',
  EXTERNAL_LINK_TOOLTIP: 'Go to line item',
};

export enum LineItemGraphType {
  Performance = 'KPIs',
  Delivery = 'Delivery',
  Revenue = 'Revenue',
}

export const ALL_ANALYTICS_METRICS = 'All metrics';

export enum AnalyticsTimeFilterLabels {
  Last24Hours = 'Last 24 Hours',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 days',
  Last30Days = 'Last 30 days',
  Last90Days = 'Last 90 days',
}

export const ADD_CREATIVES_MODAL_DROPDOWN = {
  LABEL: 'Select from Exiting Creatives',
  PLACEHOLDER: 'Enter creative name or ID',
};

export const REVENUE_INFO = {
  EXTERNAL_REVENUE: 'External Revenue:',
  INTERNAL_REVENUE: 'Internal Revenue:',
  TOTAL_BOOKED: 'Total Booked',
  DELIVERED: 'Delivered',
  PROJECTED_DAILY_REVENUE: 'Projected',
  EXTERNAL_BOOKED: 'Ext. Booked Rev.',
  EXTERNAL_DELIVERED: 'Ext. Delivered Rev.',
  EXTERNAL_PROJECTED_DAILY_REVENUE: 'Ext. Projected Rev.',
  INTERNAL_BOOKED: 'Int. Booked Rev.',
  INTERNAL_DELIVERED: 'Int. Delivered Rev.',
  INTERNAL_PROJECTED_DAILY_REVENUE: 'Int. Projected Rev.',
  TOTAL_BOOKED_TOOLTIP: 'Only applies to guaranteed lines and calculated as: goal * Internal CPM/1000',
  PROJECTED_DAILY_REVENUE_TOOLTIP: 'Projected daily revenue updates on midnight UTC every day',
};

export const DATE_RANGE_FILTERS_MODAL = {
  TITLE: 'Date Filter Options',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  ALL_DATES: 'All Dates',
  FILTERED_ONE_DATE: 'Filtered Dates (1)',
  FILTERED_TWO_DATES: 'Filtered Dates (2)',
  APPLY_BUTTON: 'Apply',
  CLEAR_BUTTON: 'Clear',
};

export const TABLES_LOCAL_STORAGE_KEYS = {
  CAMPAIGN_COLUMNS: 'campaignColumns',
  LINE_ITEM_CREATIVES_COLUMNS: 'lineItemCreativesColumns',
  CAMPAIGN_LINE_ITEMS_COLUMNS: 'campaignLineItemsColumns',
  CREATIVES_COLUMNS: 'creativesColumns',
  LINE_ITEMS_COLUMNS: 'lineItemsColumns',
  ADVERTISERS_COLUMNS: 'advertisersColumns',
};

export const FORECASTING_PAGE = {
  TITLE: 'Forecasting',
  RESET_ALL_BUTTON: 'Reset All',
  RUN_FORECAST_BUTTON: 'Run Forecast',
  SUPPLY_TARGETING_HEADER: 'Supply Targeting',
  MAX_IMPRESSIONS_AVAILABLE: 'Max Impressions Available',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  AD_INTERVAL_LABEL: 'Ad Interval',
  AD_DURATION_LABEL: 'Ad Duration',
};

export const FORECASTING_PAGE_ERRORS = {
  AD_INTERVAL: 'Ad Interval',
  AD_DURATION: 'Ad Duration',
  DATES: 'Dates',
};

export const SKIP_VIDEO_CONTAINER = {
  LABEL: 'Skip Video Content',
  PLACEHOLDER: 'Skip After (seconds)',
};

export const BREADCRUMBS = {
  getCampaignTooltipText: (campaignName: string) => `Go to: '${campaignName}' campaign details page`,
};

export const SKIP_VIDEO_FIELD = {
  CHECKBOX_LABEL: 'Enable Skip Button',
  FIELD_LABEL: 'Skip Offset (Seconds)',
};

export const COMPANION_AD = {
  CHECKBOX_LABEL: 'Enable Companion Ad',
  CLICK_THROUGH_TITLE: 'Click Through URL',
  CLICK_THROUGH_PLACEHOLDER: 'Enter URL',
  ALT_TEXT_TITLE: 'Alt Text',
  ALT_TEXT_PLACEHOLDER: 'Enter text',
  CREATIVE_VIEW_TITLE: 'Creative View Pixel',
  CREATIVE_VIEW_PLACEHOLDER: 'Enter URL',
};

export const UPLOAD_IMAGE_ERROR = {
  INVALID_DIMENSIONS: 'Image dimensions must be less than 300x60px. Please re-size the image and try again.',
  INVALID_FORMAT: 'Image must be GIF/JPEG/PNG. Please save the image in the correct format and try again.',
  INVALID_SIZE: 'File size must not exceed 150k. Please reduce the file and try again.',
};

export const CALL_TO_ACTION = {
  CHECKBOX_LABEL: 'Call to Action',
  TITLE_TEXT: 'CTA Button Title',
  TITLE_PLACEHOLDER: 'Enter text',
  LINK_TEXT: 'CTA Button Link',
  LINK_PLACEHOLDER: 'Enter URL',
  RESTRICTION_MESSAGE: 'Warning - We\'re unable to reach the CTA link, it is recommended to contact the advertiser.',
};

export const STN_SETTINGS_SECTION = {
  TITLE: 'STN Settings',
};

export const STL_IDS_CONTAINER = {
  LABEL: 'STL',
  PLACEHOLDER: 'Add STL',
};

export const CAMPAIGN_ID_CONTAINER = {
  LABEL: 'CO_ID',
  PLACEHOLDER: 'Enter Campaign ID',
};

export const CONTENT_CAMPAIGN_CONTAINER = {
  LABEL: 'Content Campaign',
  PLACEHOLDER: 'Choose Value',
  ITEMS: [1, 2, 3, 4, 5],
};

export const APPLY_PRESET_MODAL = {
  TITLE: 'Apply Preset to Line Items',
  DROPDOWN_LABLE: 'Preset',
  DROPDOWN_PLACEHOLDER: 'Choose Preset',
  CONFIRM_BUTTON: 'Apply',
  CANCEL_BUTTON: 'Cancel',
};

export const APPLY_PRESET_CONFIRM_MODAL = {
  TITLE: 'Preset Confirmation',
  TEXT: 'Are you sure you wish to apply targeting changes to the following line items?',
  CONFIRM_BUTTON: 'Yes, apply',
  CANCEL_BUTTON: 'No, go back',
};

export const BULK_OPERATIONS_DROPDOWN = {
  PLACEHOLDER: 'Bulk Operations',
  APPLY_PRESET: 'Apply Preset',
  PAUSE_ALL: 'Pause all',
  ARCHIVE_ALL: 'Archive all',
  UNARCHIVE_ALL: 'Unarchive all',
  PAUSE_ALL_VALUE: 'pause',
  ARCHIVE_ALL_VALUE: 'archive',
  UNARCHIVE_ALL_VALUE: 'unarchive',
};

export const APPLY_STATUS_CONFIRM_MODAL = {
  TITLE: 'Status Changes Confirmation',
  TEXT: 'Are you sure you wish to apply status changes to all selected line items?',
  CONFIRM_BUTTON: 'Yes, apply',
  CANCEL_BUTTON: 'No, go back',
};
