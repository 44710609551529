export const LIVE_BLOG_TEXT = {
  ERROR: 'Please enter valid 24 live blog data',
  PLACE_HOLDER: 'Paste 24 Live Blog embed',
};

export const LIVE_BLOG_SCRIPT = '<script src="https://v.24liveblog.com/24.js"></script>';

export const PROVIDER = '24liveblog';

export const ID = 'data-eid';

// Restricts to 90min
export const LIVE_BLOG_PROPERTY = '90min_en_international_web';
