import React, { Component, useRef, useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';

import { FacebookIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from './Plugin/Plugin';
import { EmbedBlock } from './shared/EmbedBlock';

import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import {
  getFacebookPostOembedData,
  getFacebookVideoOembedData,
  loadFacebook,
} from '../services/facebookServices';

import { overviewBlockText } from '../utils/blockList.utils';
import { urlInputPlaceholderGenerator, pluginAddButtonTextHandler } from '../utils/plugins.utils';

import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';

const QUERY_PARAMS = '&omitscript=true&maxwidth=480';
const ENTER_FACEBOOK_PLACEHOLDER = urlInputPlaceholderGenerator('Facebook');
const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Facebook. Please paste a correct one.';

// Element-Panel Top Bar Button

export const FacebookEmbedTopBarIcon = props => {
  return (
    <Icon icon={FacebookIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class FacebookEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.FACEBOOK;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      facebookData: null,
    };
  }

  componentDidMount() {
    // we call facebook load here to make sure that the script is in the DOM
    // if we don't call it early enough there will be a race condition causing facebook embed to look weird
    const { url } = this.state;
    loadFacebook();
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.FACEBOOK;
  }

  componentDidUpdate(prevProps, prevState) {
    const { facebookData } = this.state;
    const needsLoad = facebookData && facebookData !== prevState.facebookData;
    if (needsLoad) {
      loadFacebook();
    }
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { facebookData } = this.state;
    onAdd(facebookData);
  };

  onUrlInputKeyDown = e => {
    const { facebookData } = this.state;
    if (isEnterPressed(e) && facebookData) {
      this.onAdd(facebookData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading, property } = this.props;
    startLoading();
    if (this.isValidFacebookUrl(url)) {
      const getDataMethod = url.includes('video') ? getFacebookVideoOembedData : getFacebookPostOembedData;
      getDataMethod(url, QUERY_PARAMS, property.slug)
        .then(facebookData => {
          finishLoading();
          this.setState({ facebookData });
        })
        .catch(() => {
          finishLoading();
          this.setState({ facebookData: null });
        });
    } else {
      finishLoading();
      this.setState({ facebookData: null });
    }
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, facebookData } = this.state;
    if (url === '' || isLoading) {
      return null;
    }
    return facebookData && this.isValidFacebookUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: facebookData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidFacebookUrl = url => url.includes('facebook');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { facebookData, url } = this.state;
    const isAddDisabled = facebookData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_FACEBOOK_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent() }
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons onCancelClick={onCancel} onAddClick={this.onAdd} isAddDisabled={isAddDisabled} addButtonText={pluginAddButtonTextHandler(editedBlockData)} />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const FacebookBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  useEffect(() => {
    loadFacebook(setLoading(false));
  });

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function FacebookEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <FacebookEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const facebookEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<FacebookEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<FacebookEmbedPanelComponent {...props} />),
  getPluginBlock: props => <FacebookBlock {...props} />,
  getPluginOverviewBlock: props => (<FacebookEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.FACEBOOK,
};
