import { mandatoryFieldValidator } from 'containers/adServer/adServer.validations';
import { SITE_VARIABLES } from 'core/styles/variables';
import { VALID_URL_REGEX, createRegExValidator, createValidator } from 'core/utils/validationsBeta/validations';

export const IMPORT_ARTICLE_MODAL_TEXT = {
  MODAL_TITLE: 'Import Article',
  URL_TITLE: 'Article URL',
  PROPERTY_TITLE: 'Property',
  URL_PLACEHOLDER: 'Enter the article URL',
  PROPERTY_PLACEHOLDER: 'Select article property',
  IMPORT_SUCCESS: 'The article has been imported successfully!',
  NOT_FOUND_ERROR: 'The article could not be found. Please check that the URL is correct.',
};

export const BUTTONS_TEXT = {
  CANCEL: 'Cancel',
  DONE: 'Done',
};

export interface propertyInterface {
  name: string,
  slug: string,
  endpoint: string,
  hasAMP: boolean,
  supportsAuthorPage: boolean,
  supportsPostArchive: boolean,
  supportsFuzzyUrl: boolean,
  authorPagePrefix: string,
  authorPageTemplate: string,
}

export const VALIDATORS_ERRORS = {
  MANDATORY_FIELD: 'This field is mandatory',
  INVALID_URL_FIELD: 'Please enter a valid URL',
};

export const getStyleObject = () => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: SITE_VARIABLES.MARGIN.BIG,
    gridGap: '8px',
  },
});

export const IMPORT_ARTICLE_VALIDATORS = {
  mandatory: createValidator(mandatoryFieldValidator, VALIDATORS_ERRORS.MANDATORY_FIELD),
  validURL: createRegExValidator(VALID_URL_REGEX, VALIDATORS_ERRORS.INVALID_URL_FIELD),
};
