import { BackgroundTaskData } from '../store/backgroundTasks.constants';
import { TaskConfig, WebWorkerWrapper } from './WebWorkerWrapper';

export class NullWorker extends WebWorkerWrapper {
  declare config?: TaskConfig | undefined;

  formatData(): BackgroundTaskData | null {
    return null;
  }

  wrapTask(): string {
    return `() => {
      console.log('Just a NullWorker that does nothing')
    };`;
  }

  isDone(): boolean {
    return true;
  }
}
