import { ALL_PROPERTIES_IN_ORGANIZATION } from 'containers/administration/pages/UserEditPage/components/UserEditPageContent/userEditPageContent.constants';
import { PropertiesListItem } from '../property/property.types';
import { GlobalRole, ROLE_SCOPE, VoltaxRole } from './roles.constants';
import { Permission, UserRole, VoltaxUserPermissions } from './types';
import { LOCAL_HOST, MINUTEMEDIA_ORG_ID } from './user.constants';

export const formatUserRoles = (roles: { [key in UserRole]: unknown }): UserRole[] => {
  return Object.keys(roles) as UserRole[];
};

export const getOrgIdFromSubdomain = () => {
  const orgFromSubdomain = window.location.hostname.split('.')[0];
  return orgFromSubdomain === LOCAL_HOST ? MINUTEMEDIA_ORG_ID : orgFromSubdomain;
};

export const getUserOrgId = (orgId: string, roles: UserRole[]) => {
  const orgFromSubdomain = getOrgIdFromSubdomain();
  const isUserMegaAdmin = roles.includes(GlobalRole.MegaAdmin);
  return isUserMegaAdmin ? orgFromSubdomain : orgId;
};

export const hasRolePerProperty = (role: VoltaxRole, currentProperty: string, voltaxUserPremissions: VoltaxUserPermissions) => {
  return !!(
    voltaxUserPremissions[role]?.resources[0] === '*'
    || voltaxUserPremissions[role]?.resources.includes(currentProperty)
  );
};

export const hasRole = (roles: UserRole[], role: UserRole) => {
  return roles.includes(role);
};

export const foramtUserPremissions = (rolePermissions: Record<string, Permission>) => {
  Object.keys(rolePermissions).forEach((roleID: string) => {
   delete rolePermissions[roleID].properties;
  });
  return rolePermissions;
};

export const getPropertyIdByRole = (rolePermissions: Record<string, Permission>, propertiesList: PropertiesListItem[]) => {
  let res: string [] = [];
  Object.values(rolePermissions).forEach((roleID: Permission) => {
    if (roleID.resources[0] === ALL_PROPERTIES_IN_ORGANIZATION || roleID.scope === ROLE_SCOPE.organization) {
      res = propertiesList.map(prop => prop.id);
      return;
    }
    res.push(...roleID.resources);
  });

  const propertiesSet = new Set(res);
  return Array.from(propertiesSet);
};

export const getPropertyIdAndNameById = (rolePermissions: Record<string, Permission>, propertiesList: PropertiesListItem[]) => {
  const propertiesId = getPropertyIdByRole(rolePermissions, propertiesList);
  return propertiesList.filter(property => propertiesId.includes(property.id))
  .map(property => ({ slug: property.id, name: property.name }));
};
