import { getFormattedDate } from '@ftbpro/mm-admin-core-utils';
import { Embed, Feed, FeedFromServer, Post } from './types';
import { getEmbedType } from '../contentEnrichment.utils';

export const formatPostDataFromServer = ({ id, title, url }: Post) => ({
  title,
  url,
  id,
  verified: true,
});

export const formatFeedsFromServer = (feeds: FeedFromServer[]): Feed[] => {
  return feeds.map(({ name, slug }) => ({ value: name, slug }));
};

export const formatEmbedsForTable = (embeds: Embed[]) => embeds.map(embed => ({
    ...embed,
    embedType: getEmbedType(embed.embedType),
    author: embed.author.name,
    updatedAt: getFormattedDate(new Date(embed.updatedAt)),
    actions: embed,
  }));
