import React, { Component, Fragment } from 'react';
import { Icon, Input, RadioGroup, SearchInput, TextButton } from '@ftbpro/mm-admin-ui-components';
import { GiphyIcon } from '@ftbpro/mm-admin-assets';
import { css } from '@emotion/core';

import { Plugin } from '../Plugin/Plugin';
import { PluginGridWithPreview } from '../shared/PluginGridWithPreview';
import { pluginInputStyle } from '../shared/styles/pluginInput.styles';
import { GifBlock, GifBlockOverview } from './GifBlock';

import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';

import { pluginAddButtonTextHandler, urlInputPlaceholderGenerator } from '../../utils/plugins.utils';

import { getOembedDataByProxy } from '../../services/oembed/oEmbedApiProvider';

import { GiphyServices } from '../../services/giphyServices';

import {
  GIPHY_OEMBED_URL_PREFIX,
  SEARCH_GIPHY_PLACEHOLDER,
  URL_BROKEN_TEXT,
  GIPHY_DEFAULT_OFFSET,
  LOAD_MORE_TEXT,
  GIPHY_SEARCH_MODES,
  GIPHY_ERROR_MESSAGES,
  GIPHY_RADIO_OPTIONS,
} from './giphy.constants';
import { getUrlFromImageResponseObject, IMAGE_VIEW_TYPES, removeDuplicates } from '../shared/plugins.utils';

import { getStylesObject } from './giphy.styles';
import { getStylesObject as getEmbedStylesObject } from '../styles/embed.styles';
import { isEnterPressed } from '../../../../../core/utils/keyboard.utils';

const UNIQUE_PROPERTY = 'id';
const ENTER_GIPHY_PLACEHOLDER = urlInputPlaceholderGenerator('Giphy');

// Element-Panel Top Bar Button

export const GiphyEmbedTopBarIcon = props => {
  return (
    <Icon icon={GiphyIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class GiphyEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.GIPHY;
    this.currentSearchOffset = 0;
    this.paginationImagesCount = 0;
    this.currentSearchRequest = '';
    this.state = {
      url: isEditingBlock ? editedBlockData.value.url : '',
      gifSearchMode: GIPHY_SEARCH_MODES.URL,
      searchQuery: '',
      errorMessage: '',
      giphyImagesList: [],
      giphyEmbedData: null,
      selectedImage: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.GIPHY;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { searchQuery } = this.state;
    if (searchQuery !== prevState.searchQuery) {
      this.currentSearchOffset = 0;
    }
  };

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onUrlItemAdd = () => {
    const { onAdd } = this.props;
    const { giphyEmbedData, gifSearchMode } = this.state;
    onAdd(giphyEmbedData, { pluginMode: gifSearchMode });
  };

  onUrlInputKeyDown = e => {
    const { giphyEmbedData, gifSearchMode } = this.state;
    if (isEnterPressed(e) && giphyEmbedData) {
      this.onAdd(giphyEmbedData, { pluginMode: gifSearchMode });
    }
  };

  onSearchItemAdd = () => {
    const { selectedImage } = this.state;
    const { original } = selectedImage;
    this.getUrlOembedData(original.url, true);
  };

  onSearchInputChange = e => {
    const { errorMessage } = this.state;
    const searchQuery = e.target.value;
    this.setState({
      searchQuery,
      errorMessage: errorMessage === GIPHY_ERROR_MESSAGES.NO_SEARCH_QUERY && searchQuery ? null : errorMessage,
    });
  };

  onSearchInputKeyDown = async e => {
    const { searchQuery } = this.state;
    if (isEnterPressed(e) && searchQuery) {
      await this.searchGiphyImages();
    }
  };

  onGridImageClick = image => {
    const { startLoading } = this.props;
    startLoading();
    return this.setSelectedImage({
      ...image,
      previewImageUrl: getUrlFromImageResponseObject({
        blockType: BLOCK_TYPES.GIPHY,
        image,
        imageViewType: IMAGE_VIEW_TYPES.PREVIEW_IMAGE,
      }),
    });
  };

  onImageError = image => {
    const { id } = image;
    const { giphyImagesList } = this.state;
    this.setState({ giphyImagesList: giphyImagesList.filter(item => item.id !== id) });
  };

  setImages = images => {
    const { finishLoading } = this.props;
    const { searchQuery, giphyImagesList } = this.state;
    const imagesFound = images && images.length;
    this.currentSearchRequest = searchQuery;
    if (imagesFound) {
      const newImagesList = this.currentSearchOffset >= GIPHY_DEFAULT_OFFSET && this.currentSearchRequest === searchQuery ? [...giphyImagesList, ...images] : images;
      this.setState({
        giphyImagesList: removeDuplicates(newImagesList, UNIQUE_PROPERTY),
        errorMessage: null,
      });
    } else if (this.currentSearchOffset < GIPHY_DEFAULT_OFFSET) {
      this.setState({ giphyImagesList: [] });
      this.showErrorMessage(GIPHY_ERROR_MESSAGES.NO_DATA);
    }
    finishLoading();
  };

  getUrlOembedData = (url, shouldAdd = false) => {
    const { startLoading, finishLoading, onAdd, property } = this.props;
    const { gifSearchMode } = this.state;
    startLoading();
    if (this.isValidGiphyUrl(url)) {
      const oEmbedEndPoint = `${GIPHY_OEMBED_URL_PREFIX}${encodeURI(url)}`;
      getOembedDataByProxy(oEmbedEndPoint, url, BLOCK_TYPES.GIPHY, property.slug)
        .then(giphyEmbedData => {
          finishLoading();
          return shouldAdd ? onAdd(giphyEmbedData, { pluginMode: gifSearchMode }) : this.setState({ giphyEmbedData });
        })
        .catch(() => {
          finishLoading();
          this.setState({ giphyEmbedData: null });
        });
    } else {
      finishLoading();
      this.setState({ giphyEmbedData: null });
    }
  };

  getPreviewImage = () => {
    const { isLoading } = this.props;
    const { url } = this.state;
    if (!url || isLoading) {
      return null;
    }
    return this.isValidGiphyUrl(url) ? (
      <img
        alt="giphy"
        src={url}
        css={css(getEmbedStylesObject().embed)}
        style={{ width: '100%' }}
      />
    ) : <Plugin.ErrorMsgComponent text={URL_BROKEN_TEXT} />;
  };

  getFooterPluginButtonsProps = isOnUrlPasteMode => {
    const { url, giphyEmbedData, selectedImage } = this.state;
    return isOnUrlPasteMode ? {
      onAddClick: this.onUrlItemAdd,
      isAddDisabled: giphyEmbedData === null || !url,
    } : {
      onAddClick: this.onSearchItemAdd,
      isAddDisabled: !selectedImage,
    };
  };

  setSelectedImage = image => {
    const { finishLoading } = this.props;
    this.setState({
      selectedImage: image,
    });
    finishLoading();
  };

  loadMoreImages = async () => {
    this.currentSearchOffset += GIPHY_DEFAULT_OFFSET;
    await this.searchGiphyImages();
  };

  showErrorMessage = payload => {
    const { finishLoading } = this.props;
    this.setState({
      errorMessage: payload,
    });
    finishLoading();
  };

  removePreviewImage = () => {
    this.setState({
      selectedImage: null,
    });
  };

  searchGiphyImages = async () => {
    const { startLoading } = this.props;
    const { searchQuery } = this.state;
    if (searchQuery) {
      startLoading();
      try {
        const response = await GiphyServices.searchGifs({ searchQuery, offset: this.currentSearchOffset });
        this.paginationImagesCount = response.pagination.total_count;
        this.setImages(response.data);
      } catch (e) {
        this.showErrorMessage(e);
      }
    } else {
      this.setState({
        errorMessage: GIPHY_ERROR_MESSAGES.NO_SEARCH_QUERY,
      });
    }
  };

  shouldShowLoadMoreButton = () => {
    const { giphyImagesList, searchQuery } = this.state;
    return giphyImagesList.length && giphyImagesList.length < this.paginationImagesCount && searchQuery;
  };

  searchModeOptionChange = newSearchMode => this.setState({ gifSearchMode: newSearchMode, errorMessage: '' });

  clearUrlInput = () => this.setState({ url: '' });

  clearSearchQuery = () => this.setState({ searchQuery: '' });

  isValidGiphyUrl = url => url && url.includes('giphy') && url.includes('media') && url.includes('.gif');

  renderUrlInput = () => {
    const { url } = this.state;
    return (
      <Input
        value={url}
        placeholder={ENTER_GIPHY_PLACEHOLDER}
        autoFocus
        сlearable
        style={pluginInputStyle}
        onChange={this.onInputChange}
        onKeyDown={this.onUrlInputKeyDown}
        onClear={this.clearUrlInput}
      />
    );
  };

  renderSearchInput = () => {
    const { isLoading } = this.props;
    const { searchQuery } = this.state;
    return (
      <div css={css(getStylesObject({}).searchInput)}>
        <SearchInput
          value={searchQuery}
          placeholder={SEARCH_GIPHY_PLACEHOLDER}
          disabled={isLoading}
          shouldFocus
          сlearable
          variables={{ wrapperWidth: '100%', width: '100%' }}
          onChange={this.onSearchInputChange}
          onKeyDown={this.onSearchInputKeyDown}
          onClear={this.clearSearchQuery}
        />
      </div>
    );
  };

  renderGiphySearchGrid = () => {
    const { giphyImagesList, selectedImage } = this.state;
    return (
      giphyImagesList.length ? (
        <PluginGridWithPreview
          images={giphyImagesList}
          selectedImage={selectedImage}
          onImageError={this.onImageError}
          blockType={BLOCK_TYPES.GIPHY}
          onGridImageClick={this.onGridImageClick}
          onImageChoose={this.onSearchItemAdd}
          removePreviewImage={this.removePreviewImage}
        />
      ) : null
    );
  };

  renderLoadMoreButton = () => (
    this.shouldShowLoadMoreButton()
      ? (
        <div css={css(getStylesObject().loadMoreButton)}>
          <TextButton content={LOAD_MORE_TEXT} onClick={this.loadMoreImages} />
        </div>
      ) : null
  );

  renderGiphySearchPanel = () => {
    const { errorMessage } = this.state;
    const GiphyBody = this.renderGiphySearchGrid;
    const LoadMoreButton = this.renderLoadMoreButton;
    return (
      <Fragment>
        {errorMessage ? <Plugin.ErrorMsgComponent text={errorMessage} /> : <GiphyBody />}
        <LoadMoreButton />
      </Fragment>
    );
  };

  renderUrlPastePanel = () => {
    return (
      <Fragment>
        {this.renderUrlInput()}
        {this.getPreviewImage()}
      </Fragment>
    );
  };

  renderApiSearchPanel = () => {
    return (
      <Fragment>
        {this.renderSearchInput()}
        {this.renderGiphySearchPanel()}
      </Fragment>
    );
  };

  renderRadioGroup = () => {
    const { gifSearchMode } = this.state;
    return (
      <div css={css(getStylesObject({}).radioGroup)}>
        <RadioGroup
          items={GIPHY_RADIO_OPTIONS}
          checkedValue={gifSearchMode}
          onCheckChanged={this.searchModeOptionChange}
          disabled={false}
          orientation={RadioGroup.ORIENTATION.HORIZONTAL}
        />
      </div>
    );
  };

  render() {
    const { editedBlockData, onCancel } = this.props;
    const { gifSearchMode } = this.state;
    const isOnUrlPasteMode = gifSearchMode === GIPHY_SEARCH_MODES.URL;
    return (
      <Plugin.Container>
        <Plugin.Content>
          {this.renderRadioGroup()}
          {isOnUrlPasteMode ? this.renderUrlPastePanel() : this.renderApiSearchPanel()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons onCancelClick={onCancel} addButtonText={pluginAddButtonTextHandler(editedBlockData)} {...this.getFooterPluginButtonsProps(isOnUrlPasteMode)} />
      </Plugin.Container>
    );
  }
}

// Plugin Data

export const giphyEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<GiphyEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<GiphyEmbedPanelComponent {...props} />),
  getPluginBlock: props => <GifBlock {...props} />,
  getPluginOverviewBlock: props => (<GifBlockOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.GIPHY,
};
