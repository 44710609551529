import React, { Fragment, useEffect, useMemo } from 'react';
import { Filter, Text, Types } from '@ftbpro/mm-admin-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { navigationFiltersSelector } from 'core/store/navigationFilters/navigationFilters.selector';
import { Product } from 'core/constants';
import { FILTER_VARIABLES, getStylesObject } from './navigationFilters.styles';
import { getAvailablePropertiesNames, findPropertyByName, getSelectedProperty } from './navigationFilters.utils';
import {
  updateCurrentProperty,
} from '../../store/property/propertySlice';
import { propertySelector } from '../../store/property/property.selector';

import { useStyles } from '../../hooks/useStyles';

const PLACEHOLDER = 'Property';

export const HQPropertyFilter = (): JSX.Element => {
  const styles = useStyles(getStylesObject, []);
  const dispatch = useDispatch();
  const product = useSelector(navigationFiltersSelector.product) as Product;
  const preSelectedProperty = useSelector(navigationFiltersSelector.preSelectedProperty);
  const disabled = useSelector(navigationFiltersSelector.disablePropertySelection);
  const currentProperty = useSelector(state => propertySelector.currentProperty(state, product));
  const availableProperties = useSelector(propertySelector.availablePropertiesHQ);
  const availablePropertiesSlugs = useMemo(() => Object.values(availableProperties).map(({ id, name }) => ({
    slug: id,
    name,
  })), [availableProperties]);

  useEffect(() => {
    if (!isEmpty(availablePropertiesSlugs)) {
      const selectedProperty = getSelectedProperty(preSelectedProperty, currentProperty, availablePropertiesSlugs);
      dispatch(updateCurrentProperty({
        selectedProperty,
        product,
      }));
    }
  }, [preSelectedProperty, availablePropertiesSlugs, product, dispatch]);

  const onPropertyChange = (e: Types.ClickEventType, { value: property }: { value: string | number }) => {
    const selectedProperty = findPropertyByName(availablePropertiesSlugs, property as string);
    dispatch(updateCurrentProperty({
      selectedProperty,
      product,
    }));
  };

  return (
    <Fragment>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_M} style={styles.scopeFilterSeparator}>/</Text>
      <Filter
        placeholder={PLACEHOLDER}
        items={availablePropertiesSlugs && getAvailablePropertiesNames(availablePropertiesSlugs)}
        disabled={disabled}
        variables={FILTER_VARIABLES}
        onSelectedChange={onPropertyChange}
        selectedValue={currentProperty?.name}
      />
    </Fragment>
  );
};
