import { COLORS } from 'core/styles/colors';

export const getBlocksStyles = () => {
  return {
    blockWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      paddingTop: '8px',
      paddingBottom: '8px',
      minHeight: '96px',
    },
    overviewBlockTextWrapper: {
      flex: 1,
      overflow: 'hidden',
    },
    overviewPageBreakWrapper: {
      flex: 1,
      overflow: 'hidden',
      color: COLORS.PRIMARY100,
    },
    overviewBlockText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    overviewBlockIcon: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      width: '32px',
      flex: '0 0 auto',
      marginRight: '16px',
    },
    overviewDivider: {
      width: '32px',
      height: '2px',
      backgroundColor: COLORS.BLACK20,
    },
    overviewQuote: {
      flex: '1 1 auto',
      overflow: 'hidden',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    divider: {
      width: '480px',
      maxWidth: '100%',
      margin: '10px auto 9px',
      padding: 0,
      color: COLORS.BLACK20,
    },
    importedBlockWrapper: {
      color: COLORS.SECONDARY40,
      backgroundColor: COLORS.SECONDARY3,
      padding: '24px 78px',
      display: 'flex',
      flexDirection: 'column',
    },
    importedBlockIcon: {
      marginBottom: '17px',
    },
  };
};
