import { sanitizeScriptOutOfHtmlString } from '../../services/oembed/oEmbedApiProvider';
import { ID, LIVE_BLOG_SCRIPT, PROVIDER } from './liveBlog.constants';

const domParser = new DOMParser();

const HTML_TEXT_TYPE = 'text/html';

export const getLiveBlogIframe = (
  id: string,
) => `<iframe name="lb24" frameborder="0" height="960px" loading="lazy" width="100%" class="lb24-iframe" scrolling="auto" src="//v.24liveblog.com/iframe/?id=${id}"></iframe>
<script src="https://v.24liveblog.com/iframe.js"></script>`;

export const getHtmlTag = (htmlString: string, tagName: string) => {
  return domParser
    .parseFromString(htmlString, HTML_TEXT_TYPE)
    .getElementsByTagName(tagName)[0];
};

export const getEID = (embedCodeHTMLString: string) => {
  const liveBlogDiv = getHtmlTag(embedCodeHTMLString, 'div');
  return liveBlogDiv.getAttribute(ID);
};

export const isValidLiveBlog = (embedCodeHTMLString: string) => {
  return (
    embedCodeHTMLString.includes(LIVE_BLOG_SCRIPT)
    && embedCodeHTMLString.includes(ID)
  );
};

export const hasScript = (embedCodeHTMLString: string) => {
  return getHtmlTag(embedCodeHTMLString, 'script');
};

export const getLiveBlogData = (embedCodeHTMLString: string) => {
  let embedCode = embedCodeHTMLString;
  if (!hasScript(embedCodeHTMLString)) {
    embedCode = `${embedCode}${LIVE_BLOG_SCRIPT}`;
  }

  return {
    embedCodeHTMLString: embedCode,
    html: sanitizeScriptOutOfHtmlString(embedCodeHTMLString),
    eventId: getEID(embedCodeHTMLString),
    provider: PROVIDER,
  };
};
