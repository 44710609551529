import { mmNetworkService, HTTP_METHODS } from '../../../core/network/mmServicesApiProvider';
import { CraftConfigsProvider } from '../configs/CraftConfigProvider';

import { Logger } from '../../../core/logger';

import { getImageUploadStringParams, replaceHttpToHttpsIfNeedTo } from '../components/common/fields/fields.utils';

const {
  CRAFT_API_ENDPOINT,
  PROPERTIES_NAMESPACE,
  MANAGEMENT_NAMESPACE,
  PAGES_NAMESPACE,
  MEDIA_NAMESPACE,
  SIGNED_URL_NAMESPACE,
  LAYOUTS_NAMESPACE,
  AUTHORS_NAMESPACE,
  TEMPLATES_NAMESPACE,
  SITEMAPS_NAMESPACE,
  NAVIGATION_NAMESPACE,
  CATEGORIES_NAMESPACE,
  PROPERTY_SETTINGS_NAMESPACE,
  THEMES_NAMESPACE,
} = CraftConfigsProvider;

export class CraftServiceApiProvider {
  static createPage(property, id, payload = {}) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${PAGES_NAMESPACE}`;
    const fetchConfig = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, fetchConfig);
  }

  static createNavigation({ property, payload = {} }) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${NAVIGATION_NAMESPACE}`;
    const config = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, config);
  }

  static getLayouts(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${LAYOUTS_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static getAuthors(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${AUTHORS_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static getCategories(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${CATEGORIES_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static updateCategory({ property, slug, displayName, status, pageId }) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${CATEGORIES_NAMESPACE}/${slug}`;
    return mmNetworkService.fetch(url, {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify({
        displayName,
        pageID: pageId,
        isActive: status,
      }),
    });
  }

  static createAuthor({ property, userName, displayName }) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${AUTHORS_NAMESPACE}`;
    return mmNetworkService.fetch(url, {
      method: HTTP_METHODS.POST,
      body: JSON.stringify({
        userName,
        displayName,
      }),
    });
  }

  static publishLayout({ id, property, groups, name, isDefault }) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${LAYOUTS_NAMESPACE}/${id}`;
    return mmNetworkService.fetch(url, {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify({
        groups,
        isDefault,
        name,
      }),
    });
  }

  static getLayoutById(layoutId, property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${LAYOUTS_NAMESPACE}/${layoutId}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static createLayout(property, payload) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${LAYOUTS_NAMESPACE}`;
    const fetchConfig = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, fetchConfig);
  }

  static deleteLayout(layoutId, property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${LAYOUTS_NAMESPACE}/${layoutId}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.DELETE });
  }

  static getPages(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${PAGES_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static getPage(property, id) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${PAGES_NAMESPACE}/${id}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static updatePage(property, id, payload = {}) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${PAGES_NAMESPACE}/${id}`;
    const fetchConfig = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, fetchConfig);
  }

  static deletePage(property, id) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${PAGES_NAMESPACE}/${id}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.DELETE });
  }

  static getTemplates(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${TEMPLATES_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static getSitemap(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${SITEMAPS_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static fetchProperties() {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static insertPageToSitemap(property, pathParts, content) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${SITEMAPS_NAMESPACE}/insert`;
    const body = JSON.stringify({ pathParts, content });
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.PATCH, body });
  }

  static pushRoutesToPilot(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${SITEMAPS_NAMESPACE}/push`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.PUT });
  }

  static removePageFromSitemap(property, pageId) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${SITEMAPS_NAMESPACE}/items/remove`;
    const body = JSON.stringify({
      content: {
        type: 'page',
        pageID: pageId,
      },
    });
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.PATCH, body });
  }

  static getNavigations(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${NAVIGATION_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static getNavigation(property, id) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${NAVIGATION_NAMESPACE}/${id}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static updateNavigation({ property, id, payload }) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${NAVIGATION_NAMESPACE}/${id}`;
    const config = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, config);
  }

  static getSignedUploadUrl({ filename, imageData, crop, extension, property }) {
    const uploadParamsString = getImageUploadStringParams({ crop, extension, imageData });
    const configs = {
      method: 'GET',
    };
    const signedUploadUrl = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MEDIA_NAMESPACE}/${SIGNED_URL_NAMESPACE}/${filename}?${uploadParamsString}`;
    return mmNetworkService.fetch(signedUploadUrl, configs);
  }

  static uploadImageToMediaProvider(signedUrl, imageData) {
    const data = new FormData();
    data.append('file', imageData.file);
    const configs = {
      method: 'POST',
      body: data,
    };
    return mmNetworkService.externalFetch(signedUrl, configs);
  }

  static uploadImage({ source, filename, imageData, crop, extension, property }) {
    return CraftServiceApiProvider.getSignedUploadUrl({ source, filename, imageData, crop, extension, property })
      .then(signedUploadResponse => {
        return CraftServiceApiProvider.uploadImageToMediaProvider(signedUploadResponse.signed_url, imageData)
          .then(response => response.json())
          .then(obj => {
            return { url: replaceHttpToHttpsIfNeedTo(obj.url), path: signedUploadResponse.image_url, fileExtension: extension };
          });
      })
      .catch(e => {
        Logger.log(e);
        return null;
      });
  }

  static cropAndUploadImage({ source, filename, imageData, crop, extension, property }) {
    return new Promise((resolve, reject) => {
      Promise.all([
        CraftServiceApiProvider.uploadImage({ source, filename: `uncropped-${filename}`, imageData, extension, property }),
        CraftServiceApiProvider.uploadImage({ source, filename, imageData, crop, extension, property }),
      ])
        .then(values => {
          const [originalImage, croppedImage] = values;
          resolve({ ...croppedImage, fullImageUrl: originalImage.url });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getPropertySettingsUrl(property) {
    return `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${PROPERTY_SETTINGS_NAMESPACE}`;
  }

  static getPropertySettings(property) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${PROPERTY_SETTINGS_NAMESPACE}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static getTheme(property, themeID) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${THEMES_NAMESPACE}/${themeID}`;
    return mmNetworkService.fetch(url, { method: HTTP_METHODS.GET });
  }

  static updateThemeByID(property, themeID, payload) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${THEMES_NAMESPACE}/${themeID}`;
    const config = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, config);
  }

  static previewTheme(property, payload) {
    const url = `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${THEMES_NAMESPACE}/preview`;
    const config = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, config);
  }

  static getThemesRequestUrl(property) {
    return `${CRAFT_API_ENDPOINT}/${PROPERTIES_NAMESPACE}/${property}/${MANAGEMENT_NAMESPACE}/${THEMES_NAMESPACE}`;
  }
}
