import { BackgroundTaskStatus, BackgroundTaskViewerComponentProps } from '@ftbpro/mm-admin-ui-components';

export enum TaskType {
  BulkQueryTask = 'bulkQuery',
  ResumableUploadTask = 'resumableUpload',
  DetachedTask = 'detachedTask'
}

export interface BackgroundTaskData extends Partial<BackgroundTaskViewerComponentProps> {
  type?: TaskType
  errorPageUrl?: string
  resourcePageUrl?: string
  openErrorPageInNewTab?: boolean
  // add TaskConfig ?? // might be needed for persistance in the Creation's team bulk operations
}

export type SerializableBackgroundTaskData = Omit<BackgroundTaskData, 'onClose' | 'onPause' | 'onResume' | 'onCancel' | 'onErrorTextButtonClick' | 'onDoneTextButtonClick'>

export interface BackgroundTasksMap {
  [key: string]: BackgroundTaskData
}

export interface BackgroundTasksState {
  tasks: BackgroundTasksMap
}

export const INITIAL_STATE: BackgroundTasksState = {
  tasks: {},
};

export const INITIAL_TASK_DATA: BackgroundTaskData = {
  status: BackgroundTaskStatus.Loading,
  type: TaskType.BulkQueryTask,
};
