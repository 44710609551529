export const CONTRIBUTOR_ANALYTICS = 'Contributor Analytics';

export const EVENT_TYPES = {
    USER_STATS_TAB_CLICK: 'My Stats tab clicked',
    USER_STATS_FILTER_CLICK: 'My Stats filter changed',
    USER_STATS_SITE_STATS_CLICK: 'Site Stats button clicked',
    USER_STATS_PROPERTY_FILTER: 'Site Stats post stats filter',
    SITE_STATS_TAB_CLICK: 'Site Stats tab clicked',
    SITE_STATS_FILTER_CLICK: 'Site Stats filter changed',
    SITE_STATS_USER_STATS_CLICK: 'My Stats button clicked',
};
