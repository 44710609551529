import { EmbedType } from '../../../store/types';

export const EMBED_TYPES_SLUGS = {
  [EmbedType.StoryLink]: 'StoryLink',
  [EmbedType.Rank]: 'Rank',
  [EmbedType.Trade]: 'Trade',
  [EmbedType.Score]: 'Score',
  [EmbedType.Matchup]: 'Matchup',
  [EmbedType.Grade]: 'Grade',
  [EmbedType.GroupOfLinks]: 'GroupOfLinks',
  [EmbedType.ThreeImageLinks]: 'ThreeImageLinks',
  [EmbedType.GroupOfExternalLinks]: 'GroupOfExternalLinks',
};

export const EMBED_TYPES_LABELS = {
  ALL: 'All Embeds',
  [EmbedType.StoryLink]: 'Story Link',
  [EmbedType.Rank]: 'Rank',
  [EmbedType.Trade]: 'Trade',
  [EmbedType.Score]: 'Score',
  [EmbedType.Matchup]: 'Matchup',
  [EmbedType.Grade]: 'Grade',
  [EmbedType.GroupOfLinks]: 'Group Of Links',
  [EmbedType.ThreeImageLinks]: '3 Image Links',
  [EmbedType.GroupOfExternalLinks]: 'Group Of External Links',
};

export const EMBED_TYPES = [
  {
    slug: '',
    label: EMBED_TYPES_LABELS.ALL,
  },
  {
    slug: EMBED_TYPES_SLUGS.StoryLink,
    label: EMBED_TYPES_LABELS.StoryLink,
  },
  {
    slug: EMBED_TYPES_SLUGS.Rank,
    label: EMBED_TYPES_LABELS.Rank,
  },
  {
    slug: EMBED_TYPES_SLUGS.Trade,
    label: EMBED_TYPES_LABELS.Trade,
  },
  {
    slug: EMBED_TYPES_SLUGS.Score,
    label: EMBED_TYPES_LABELS.Score,
  },
  {
    slug: EMBED_TYPES_SLUGS.Matchup,
    label: EMBED_TYPES_LABELS.Matchup,
  },
  {
    slug: EMBED_TYPES_SLUGS.Grade,
    label: EMBED_TYPES_LABELS.Grade,
  },
  {
    slug: EMBED_TYPES_SLUGS.GroupOfLinks,
    label: EMBED_TYPES_LABELS.GroupOfLinks,
  },
  {
    slug: EMBED_TYPES_SLUGS.ThreeImageLinks,
    label: EMBED_TYPES_LABELS.ThreeImageLinks,
  },
  {
    slug: EMBED_TYPES_SLUGS.GroupOfExternalLinks,
    label: EMBED_TYPES_LABELS.GroupOfExternalLinks,
  },
];

export const SEARCH_TEXT = {
  PLACEHOLDER: 'Search for a custom embed',
};

export const EXTERNAL_LINK_PROPERTIES = ['home-is-love', 'path-2-career', 'the-wellness-way', 'floor8_en_international_web', 'sandbox_for_tests'];
