export const getStylesObject = () => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: '16px',
    },
    loadMoreButton: {
      paddingTop: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    editImageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    },
  };
};
