import { ConfigsProvider } from '../../../../core/configs/ConfigsProvider';

const RECOMMENDATION_SERVICES_BASE_URL = ConfigsProvider.TAGS_API_BASE_URL;
const PROPERTIES = '/properties/';
const POSTS = '/posts';
const PAGES = '/pages';
const SEARCH = '/search';

export class RecommendationServiceConfigProvider {
  static getRecommendationPostsEndpoint(property) {
    return `${RECOMMENDATION_SERVICES_BASE_URL}${PROPERTIES}${property}${POSTS}`;
  }

  static getRecommendationPagesEndpoint(property) {
    return `${RECOMMENDATION_SERVICES_BASE_URL}${PROPERTIES}${property}${PAGES}`;
  }

  static getRecommendationSearchPostsEndpoint(property) {
    return `${RECOMMENDATION_SERVICES_BASE_URL}${PROPERTIES}${property}${POSTS}${SEARCH}`;
  }
}
