import { ConfigsProvider } from '../../../../core/configs/ConfigsProvider';

const { VMS_VIDEO_BASE_URL } = ConfigsProvider;

const PROPERTIES = '/properties/';
const MANAGEMENT = '/management';
const UPLOAD = '/upload';
const IMAGES = '/images';
const SUBMIT = '/submit';
const APPROVE = '/approve';
const APPROVE_IMAGE = '/approved-images';
const COPY_IMAGE = '/copy-images';

export class MediaLibraryImagesConfigsProvider {
  static getImages({ property, queryString }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${IMAGES}?${queryString}`;
  }

  static getPagedPosts({ path }) {
    function insert(str, index, value) {
      return str.substring(0, index) + value + str.substring(index);
    }
    const indexOfPlace = path.indexOf('/images');
    return `${VMS_VIDEO_BASE_URL}${insert(path, indexOfPlace, MANAGEMENT)}`;
  }

  static getImageInfo({ property, id }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${IMAGES}/${id}`;
  }

  static updateImageInfo({ property, id }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${IMAGES}/${id}`;
  }

  static getImageUploadUrl({ property }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${UPLOAD}${IMAGES}`;
  }

  static getApproveUploadUrl({ property }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${UPLOAD}${APPROVE_IMAGE}`;
  }

  static deleteImage({ property, id }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${IMAGES}/${id}`;
  }

  static deletePublishedImage({ property, id }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${APPROVE_IMAGE}/${id}`;
  }

  static submitImageForApproval({ property, id }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${IMAGES}/${id}${SUBMIT}`;
  }

  static approveImage({ property, id }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${IMAGES}/${id}${APPROVE}`;
  }

  static updatePublishedImage({ property, id }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${APPROVE_IMAGE}/${id}`;
  }

  static copyImage({ property }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${COPY_IMAGE}`;
  }

  static copyImageByFile({ property }) {
    return `${VMS_VIDEO_BASE_URL}${PROPERTIES}${property}${MANAGEMENT}${UPLOAD}${COPY_IMAGE}`;
  }
}
