import { HTTP_METHODS, mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { EchoServiceConfigsProvider } from './echoServiceConfigsProvider';
import { getPostsQueryString } from '../utils/echoQueryStringBuilder';
import { generateMetadataObject } from '../components/echoCustomization/metadata/echoMetadata.utils';

export class EchoNetworkService {
  static getPosts({ property, filters }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getPostListEndpoint({ property, queryString: getPostsQueryString(filters) }), configs);
  }

  static getPagedPosts({ path }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getPaginationPostListEndpoint({ path }), configs);
  }

  static getPostData({ postId, property }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getPostDataEndpoint({ property, postId }), configs);
  }

  static getScheduledPosts({ property, filters, availableFeeds }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getScheduledPostsListEndpoint({ property, queryString: getPostsQueryString(filters, availableFeeds) }), configs);
  }

  static getAvailableFeeds(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getAvailableFeedsEndpoint(property), configs);
  }

  static getArticlePreview({ property, id, additionalAuthors, metadata, mainCategory }) {
    const data = {
      additionalAuthors,
      metadata: generateMetadataObject(metadata),
      mainCategory,
    };
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getArticlePreviewEndpoint({ property, id }), configs);
  }

  static getAvailableAdsCategories(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getAvailableAdsCategoriesEndpoint(property), configs);
  }

  static getAvailableAuthors(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getAvailableAuthorsEndpoint(property), configs);
  }

  static getPropertySettings(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getPropertySettingsEndPoint(property), configs);
  }

  static updatePostComments({ data, property, postId }) {
    const configs = {
      method: 'PUT',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdateCommentsEndpoint({ property, postId }), configs);
  }

  static updatePostLabels({ data, property, postId }) {
    const configs = {
      method: 'PUT',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdateLabelsEndpoint({ property, postId }), configs);
  }

  static updatePostVisibility({ data, property, postId }) {
    const configs = {
      method: 'PUT',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdateVisibilityEndpoint({ property, postId }), configs);
  }

  static updatePostFeeds({ postId, property, data }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getFeaturePostEndpoint({ property, postId }), configs);
  }

  static updatePostMetadata({ data, property, postId }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdatePostMetadataEndpoint({ property, postId }), configs);
  }

  static updatePostExcludeFromRecommendations({ data, property, postId }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdatePostExcludeFromRecommendationsEndpoint({ property, postId }), configs);
  }

  static updatePostCustomCanonicalURL({ data, property, postId }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdatePostCustomCanonicalURLEndpoint({ property, postId }), configs);
  }

  static updatePostPublishDate({ data, property, postId }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdatePostPublishDateEndpoint({ property, postId }), configs);
  }

  static addMainCategory({ data, property, postId }) {
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(data),
    };

    return mmNetworkService.fetch(EchoServiceConfigsProvider.getAddPostMainCategoryEndpoint({ property, postId }), configs);
  }

  static updatePostPath({ data, property, postId }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdatePostPathEndpoint({ property, postId }), configs);
  }

  static updatePostScheduledFeeds({ data, property, postId }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdatePostScheduledFeedsEndpoint({ property, postId }), configs);
  }

  static unpinFeaturedFeed({ data, property, postId }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUnpinFeedEndpoint({ property, postId }), configs);
  }

  static deletePost({ property, postId }) {
    const configs = {
      method: 'DELETE',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getPostDataEndpoint({ property, postId }), configs);
  }

  static updatePostAuthors({ data, property, postId }) {
    const configs = {
      method: 'PATCH',
      body: JSON.stringify(data),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getUpdatePostAuthorsEndpoint({ property, postId }), configs);
  }

  static getPushNotificationFeeds(property, postId) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getPushNotificationFeedsEndpoint(property, postId), configs);
  }

  static addPushNotificationFeeds(property, postId, feeds) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(feeds),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getPushNotificationFeedsEndpoint(property, postId), configs);
  }

  static getFeaturedRecommendationArticles({ property, additionalPostsTopic, limit }) {
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getFeaturedRecommendationArticlesEndpoint({ property, additionalPostsTopic, limit }));
  }

  static getMainCategory({ property, postId }) {
    const configs = {
      method: HTTP_METHODS.GET,
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getMainArticleEndPoint({ property, postId }), configs);
  }

  static updateTaxonomy({ property, postId, taxonomy }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify(taxonomy),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getTaxonomyEndPoint({ property, postId }), configs);
  }

  static updateInThisStory({ property, postId, inThisStory }) {
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify({ inThisStory }),
    };
    return mmNetworkService.fetch(EchoServiceConfigsProvider.getInThisStoryEndPoint({ property, postId }), configs);
  }
}
