import { isEmpty, isNumber } from '@ftbpro/mm-admin-core-utils';
import { AggFuncType } from '@ftbpro/mm-admin-ui-components';
import { ChartsType } from './dashboards.constants';
import { getChartFormatter } from '../../pages/DashboardPage/dashboardPage.utils';
import { DateObject, DateType } from '../../pages/components/DateRangeSetup/DateRangeSetup';
import { DateRange, TableFormat } from './dashboards.types';

const DIMENSION_INDEX = 0;
const METRIC_INDEX = 1;

const getLineGraphData = (reportArray: any[]) => {
  const dataKey = !isEmpty(reportArray) ? reportArray[0][Object.keys(reportArray[0])[METRIC_INDEX]]?.caption : '';
  const formattedData: {[key: string]: string | number}[] = [];
  reportArray.forEach((item: {[key: string]: any }) => {
    const values = Object.values(item);
    formattedData.push({ name: values[DIMENSION_INDEX].display, [dataKey]: values[METRIC_INDEX].value });
  });
  return { data: formattedData, dataKeys: [dataKey] };
};

const getTotalValue = (reportArray: any[]) => {
  return reportArray.reduce((sum: number, item: {[key: string]: any}) => {
    const values = Object.values(item);
    return sum + values[METRIC_INDEX].value;
  }, 0);
};

const getPieChartData = (reportArray: any[]) => {
  const formattedData: { value?: number, name?: string, legendValue: string }[] = [];
  const total = getTotalValue(reportArray);
  reportArray.forEach((item: {[key: string]: any}) => {
    const values = Object.values(item);
    const { value } = values[METRIC_INDEX];
    formattedData.push({
      value,
      name: values[DIMENSION_INDEX].display,
      legendValue: total === 0 ? '0%' : `${Math.round((value / total) * 100)}%`,
    });
  });
  return { data: formattedData };
};

const getBarChartData = (reportArray: any[]) => {
  const formattedData: { value?: number, name: string }[] = [];
  reportArray.forEach((item: {[key: string]: any}) => {
    const values = Object.values(item);
    formattedData.push({ value: values[METRIC_INDEX].value, name: values[DIMENSION_INDEX].display });
  });
  return { data: formattedData };
};

const getStatsData = (reportArray: any[]) => {
  const formattedData: { value: number, caption: string }[] = Object.values(reportArray[0]);
  return { value: formattedData[0].value, title: formattedData[0].caption };
};

const getTableData = (reportArray: any[], tableformats: { [key: string] : TableFormat }) => {
  const formattedData: {[key: string]: any}[] = [];
  const columnsDefinition: { field: string, headerName: string, valueFormatter?: (params: any) => string, aggFunc?: AggFuncType }[] = [];
  reportArray.forEach((item: {[key: string]: any}, index: number) => {
    const itemKeys = Object.keys(item);
    const tableObject = itemKeys.reduce((prev, current) => {
      if (index === 0) {
        const { aggFunc, tableformatsStyle } = tableformats?.[current] || {};
        const valueFormatter = isNumber(item[current].value) ? (params: any) => getChartFormatter(tableformatsStyle)(params.value) : undefined;
        columnsDefinition.push({ field: current, headerName: item[current].caption, valueFormatter, aggFunc: isNumber(item[current].value) ? aggFunc || AggFuncType.Sum : undefined });
      }
      return { ...prev, [current]: item[current].value };
    }, {});
    formattedData.push(tableObject);
  });
  return { data: formattedData, columnsDefinition };
};

export const formatReportData = (report: {[ key: string]: any}, chartType: ChartsType, tableformats: { [key: string] : TableFormat }) => {
  const reportArray = report.report;
  switch (chartType) {
    case ChartsType.LineGraph:
      return getLineGraphData(reportArray);
    case ChartsType.BarChart:
      return getBarChartData(reportArray);
    case ChartsType.Stats:
      return getStatsData(reportArray);
    case ChartsType.Table:
      return getTableData(reportArray, tableformats);
    case ChartsType.PieChart:
      return getPieChartData(reportArray);
    default:
      return null;
  }
};

export const formatDateObject = (dateObject: DateObject): DateRange => {
  return {
    field: 'date',
    type: dateObject.dateType || DateType.Today,
    from: dateObject.startDate,
    to: dateObject.endDate,
  };
};
