import {
  PUSH_GLOBAL_NOTIFICATION,
  DISMISS_GLOBAL_NOTIFICATION,
} from './globalNotifications.actions';

import { removeGlobalNotification } from '../globalNotifications.utils';

const initialState = {
  globalNotifications: [],
};

export const globalNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_GLOBAL_NOTIFICATION: {
      const newGlobalNotification = action.payload;
      const globalNewNotifications = state.globalNotifications;
      return {
        ...state,
        globalNotifications: [...globalNewNotifications, newGlobalNotification],
      };
    }
    case DISMISS_GLOBAL_NOTIFICATION:
      return {
        ...state,
        globalNotifications: removeGlobalNotification(state.globalNotifications, action.payload),
      };
    default:
      return state;
  }
};
