import { POLLING_INTERVAL } from '../constants/echo.constants';

const EMPTY_FUNC = () => {};

class EchoPollingManager {
  intervalRef = null;

  snoozeTimeoutRef = null;

  pollingAction = EMPTY_FUNC;

  startPolling(pollingAction) {
    if (this.intervalRef) {
      this.stopPolling();
    }
    this.pollingAction = pollingAction;
    this.intervalRef = setInterval(this.pollingAction, POLLING_INTERVAL);
  }

  clearTimers() {
    clearInterval(this.intervalRef);
    clearTimeout(this.snoozeTimeoutRef);
  }

  stopPolling() {
    this.clearTimers();
    this.pollingAction = EMPTY_FUNC;
  }

  resetPolling(updatedPollingAction) {
    this.clearTimers();
    this.pollingAction = updatedPollingAction;
    this.startPolling(this.pollingAction);
  }

  snoozePolling(snoozeDuration) {
    this.clearTimers();
    this.snoozeTimeoutRef = setTimeout(() => this.startPolling(this.pollingAction), snoozeDuration);
  }
}

let instance;

export const getEchoPollingService = () => {
  if (!instance) {
    instance = new EchoPollingManager();
  }
  return instance;
};
