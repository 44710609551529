import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_STATE, BackgroundTaskData } from './backgroundTasks.constants';
import { removeTaskFromLocalStorage } from './backgroundTasks.utils';

const SLICE_NAME = 'backgroundTasks';

const backgroundTasks = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    addBackgroundTask(state, action: PayloadAction<{ taskID: string, taskData: BackgroundTaskData }>) {
      state.tasks[action.payload.taskID] = action.payload.taskData;
    },
    updateBackgroundTask(state, action: PayloadAction<{ taskID: string, taskData: BackgroundTaskData }>) {
      const { taskID, taskData } = action.payload;
      state.tasks = {
        ...state.tasks,
        [taskID]: {
          ...state.tasks[taskID],
          ...taskData,
        },
      };
    },
    removeBackgroundTask(state, action: PayloadAction<string>) {
      removeTaskFromLocalStorage(action.payload);
      delete state.tasks[action.payload];
    },
  },
});

export const {
  addBackgroundTask,
  removeBackgroundTask,
  updateBackgroundTask,
} = backgroundTasks.actions;

export default backgroundTasks.reducer;
