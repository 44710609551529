import { RootState } from 'core/store';
import { userSelector } from 'core/store/user/user.selector';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { Action } from '@reduxjs/toolkit';
import { DashboardsPaths } from '../reports.constants';
import { Filter } from '../store/dashboards/dashboards.types';
import { DateObject } from '../pages/components/DateRangeSetup/DateRangeSetup';
import { queryBuilderSelector } from '../store/queryBuilder/queryBuilder.selector';

export const createDashboardsCustomDimensions = (state: RootState, dashboardPath?: DashboardsPaths) => {
  const userId = userSelector.getUserId(state);
  const orgId = userSelector.getUserOrgId(state);

  return {
    userId,
    organization: orgId,
    dashboardPath,
  };
};

export const getFiltersLabel = (selectedFilters: Filter[]) => {
  return selectedFilters.reduce((acc: string, filter: Filter) => {
    const accPrefix = !isEmpty(acc) ? `${acc}, ` : '';
    return `${accPrefix}${filter.field}: ${filter.data.toString()}`;
  }, '');
};

export const getTimeframeLabel = (selectedDateRange: DateObject) => {
  return Object.keys(selectedDateRange).reduce((acc: string, key: string) => {
    const accPrefix = !isEmpty(acc) ? `${acc}, ` : '';
    return `${accPrefix}${[key]}: ${selectedDateRange[key as keyof DateObject]}`;
  }, '');
};

export const shouldSkipAction = (action: Action) => {
  return !action.type || !action.type.includes('REPORTS');
};

const getReportData = (reportDataFromStore: string, reportDataFromSchedule: string, reportDataFromEvent?: string) => {
  if (reportDataFromEvent) {
    return reportDataFromEvent;
  } if (!isEmpty(reportDataFromSchedule)) {
    return reportDataFromSchedule;
  }
  return reportDataFromStore;
};

export const createQueryBuilderCustomDimensions = (state: RootState, reportIdFromEvent?: string, reportNameFromEvent?: string) => {
  const userId = userSelector.getUserId(state);
  const orgId = userSelector.getUserOrgId(state);
  const queryParams = queryBuilderSelector.queryParams(state);
  const scheduleParams = queryBuilderSelector.scheduleParams(state);
  const { reportConfigID: scheduleReportConfigID, reportName: scheduleReportName } = scheduleParams;
  const { dataSourceID, startDate, endDate, reportName, reportConfigID } = queryParams;

  return {
    userId,
    organization: orgId,
    dataSource: dataSourceID,
    dateRange: `${startDate} : ${endDate}`,
    reportId: getReportData(reportConfigID, scheduleReportConfigID, reportIdFromEvent),
    reportName: getReportData(reportName, scheduleReportName, reportNameFromEvent),
  };
};

export const getRequestFromAction = (type: string) => type.split('/')[2];
