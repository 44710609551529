import { SITE_VARIABLES } from '../../styles/variables';

export const getStylesObject = () => {
  return {
    organizationLink: {
      color: SITE_VARIABLES.TEXT_COLOR.DEFAULT,
      textDecoration: 'underline',
    },
  };
};
