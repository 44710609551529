import { SERVER_POST_STATUSES, ECHO_PUBLISH_BUTTON_TEXT, ICON_TOOLTIPS } from '../constants/echo.constants';
import { isFeedInList, getFeedObjectByFeedSlug, isCanonicalUrlValid } from './echo.utils';
import { isBettingContentValid, isEditUrlValid } from '../components/echoCustomization/metadata/echoMetadata.utils';
import {
  BETTING_CONTENT_ERROR,
  CANONICAL_URL_ERROR,
  DEFAULT_ADS_CATEGORY,
  EDIT_URL_ERROR,
} from '../components/echoCustomization/metadata/echoMetadata.constants';

export const areArraysEqual = (first, second) => {
  return first.length === second.length && first.every((value, index) => value === second[index]);
};

const areRecommendedArticlesArraysEqual = (featuredArticlesForCurrentPost, selectedRecommendedArticles) => {
  if (featuredArticlesForCurrentPost && featuredArticlesForCurrentPost.length === selectedRecommendedArticles.length) {
    return !featuredArticlesForCurrentPost.some(
      article => !selectedRecommendedArticles.some(selectArticle => selectArticle.id === article.id),
    );
  }
  return false;
};

export const areSponsoredContentObjectsEqual = (first, second) => {
  const firstObjKeys = Object.keys(first);
  const secondObjKeys = Object.keys(second);
  if (!firstObjKeys.length && !secondObjKeys.length) {
    return true;
  }
  return firstObjKeys.length === secondObjKeys.length && !!firstObjKeys.filter(key => first[key] === second[key]).length;
};

export const areInThisStoriesEqual = (originalInThisStory = [], selectedInThisStory = []) => {
  return originalInThisStory?.length === selectedInThisStory?.length && (selectedInThisStory?.length === 0 || originalInThisStory?.every(slug => selectedInThisStory?.indexOf(slug) > -1));
};

const arePostMetaDataObjectsEqual = (first, second) => {
  const firstObjKeys = Object.keys(first);
  const secondObjKeys = Object.keys(second);
  return firstObjKeys.length === secondObjKeys.length && firstObjKeys.every(key => first[key] === second[key]);
};

export const areAdditionalAuthorsEqual = (original, added) => {
  return original.length === added.length && original.every((value, index) => value.username === added[index].username);
};

export const hasPostMetaDataBeenChanged = ({ originalPostData, postMetaData }) => {
  const originalPostNoIndex = !!originalPostData.metadata.noIndex;
  const originalPostExcludeFromRSS = !!originalPostData.metadata.excludeFromRSS;
  const originalPostHideOwner = !!originalPostData.metadata.hideOwner;
  const originalPostAdsCategory = originalPostData.metadata.adsCategory || DEFAULT_ADS_CATEGORY;
  const originalPostCommercialTags = originalPostData.metadata.commercialTags ? originalPostData.metadata.commercialTags : [];
  const originalPostSponsoredContent = originalPostData.metadata.sponsoredContent ? originalPostData.metadata.sponsoredContent : {};
  const originalBettingContent = originalPostData.metadata.betting || {};
  const bettingContent = postMetaData.isBettingContentEnabled ? postMetaData.bettingContent : {};
  const originalFeaturedArticlesForCurrentPost = originalPostData.metadata.featuredArticlesForCurrentPost ? originalPostData.metadata.featuredArticlesForCurrentPost : [];
  const originalCanonicalUrl = originalPostData.metadata.canonicalUrl || '';
  const originalPublishDate = originalPostData.createdAt || '';
  const CHANGED_FIELDS = {
    postPath: originalPostData.path !== postMetaData.postPath,
    adsCategory: originalPostAdsCategory !== postMetaData.adsCategory,
    commercialTags: !areArraysEqual(originalPostCommercialTags, postMetaData.commercialTags),
    noIndex: originalPostNoIndex !== postMetaData.noIndex,
    excludeFromRSS: originalPostExcludeFromRSS !== postMetaData.excludeFromRSS,
    canonicalUrl: originalCanonicalUrl !== postMetaData.canonicalUrl,
    hideOwner: originalPostHideOwner !== postMetaData.hideOwner,
    sponsoredContent: !areSponsoredContentObjectsEqual(originalPostSponsoredContent, postMetaData.sponsoredContent),
    bettingContent: !arePostMetaDataObjectsEqual(originalBettingContent, bettingContent),
    selectedRecommendedArticles: !!postMetaData.additionalPostsTopic || !areRecommendedArticlesArraysEqual(originalFeaturedArticlesForCurrentPost,
      [...postMetaData.selectedRecommendedArticles]),
    publishDate: postMetaData.isPublishDateEnabled && originalPublishDate !== postMetaData.publishDate,
  };
  return Object.values(CHANGED_FIELDS).includes(true);
};

export const isPostPublished = postData => postData && postData.status === SERVER_POST_STATUSES.PUBLISHED;
export const isPostScheduled = postData => postData && postData.status === SERVER_POST_STATUSES.SCHEDULED;
export const getCustomizationHeaderButtonText = postData => (isPostPublished(postData) ? ECHO_PUBLISH_BUTTON_TEXT.UPDATE : ECHO_PUBLISH_BUTTON_TEXT.PUBLISH);

export const getCustomizationHeaderButtonToolTip = (postData, { addedFeeds, featuredFeeds, scheduledFeeds, selectedVertical, supportsCustomVertical }) => {
  const isPublished = isPostPublished(postData);
  const publishErrors = [];
  if (!addedFeeds.length && !featuredFeeds.length && !scheduledFeeds.length) {
    publishErrors.push(ICON_TOOLTIPS.CAN_NOT_PUBLISH);
  }
  if (isPublished) {
    publishErrors.push(ICON_TOOLTIPS.CAN_NOT_UPDATE);
  }
  if (!selectedVertical && supportsCustomVertical) {
    publishErrors.push(ICON_TOOLTIPS.ADD_VERTICAL);
  }
  return publishErrors;
};

export const hasPinToTopDateBeenChanged = (originalFeed, newFeed) => {
  return originalFeed.pinnedTTL ? new Date(originalFeed.pinnedTTL).getTime() !== new Date(newFeed.pinnedTTL).getTime() : !!originalFeed.pinnedTTL !== !!newFeed.pinnedTTL;
};

export const hasFeedListBeenChanged = ({ originalPostData, feedList, feedListName }) => {
  if (originalPostData[feedListName].length !== feedList.length) {
    return true;
  }
  return !!originalPostData[feedListName].filter(originalFeedObj => {
    if (isFeedInList(originalFeedObj, feedList)) {
      const feedObj = getFeedObjectByFeedSlug(originalFeedObj.slug, feedList);
      return Object.keys(originalFeedObj).filter(key => {
        return originalFeedObj[key] !== feedObj[key] || hasPinToTopDateBeenChanged(originalFeedObj, feedObj);
      }).length;
    }
    return false;
  }).length;
};

const FEATURED_FEEDS_LIST_NAME = 'featuredFeeds';
const SCHEDULED_FEEDS_LIST_NAME = 'scheduledFeeds';

export const validatePost = ({ postMetaData }) => {
  const { postPath, canonicalUrl, isCanonicalUrlEnabled, isBettingContentEnabled, bettingContent } = postMetaData;
  const errors = [];
  if (!isEditUrlValid(postPath)) {
    errors.push(EDIT_URL_ERROR);
  }
  if (!isCanonicalUrlValid(canonicalUrl) && isCanonicalUrlEnabled) {
    errors.push(CANONICAL_URL_ERROR);
  }
  if (isBettingContentEnabled && !isBettingContentValid(bettingContent)) {
    errors.push(BETTING_CONTENT_ERROR);
  }
  return errors;
};

export const wasPostChanged = ({ originalPostData, postData, postMetaData, featuredFeeds, addedFeeds, scheduledFeeds, selectedMainCategory, selectedTopic, selectedVertical, supportsCustomVertical }) => {
  const hasAddedFeeds = addedFeeds.length > 0;
  const totalFeeds = featuredFeeds.length + addedFeeds.length + scheduledFeeds.length > 0;
  const featuredFeedsBeenChanged = originalPostData && hasFeedListBeenChanged({ originalPostData, feedList: featuredFeeds, feedListName: FEATURED_FEEDS_LIST_NAME });
  const scheduledFeedsBeenChanged = originalPostData && hasFeedListBeenChanged({ originalPostData, feedList: scheduledFeeds, feedListName: SCHEDULED_FEEDS_LIST_NAME });
  const postMetaDataBeenChanged = originalPostData && hasPostMetaDataBeenChanged({ originalPostData, postMetaData });
  const additionalAuthorsBeenChanged = originalPostData && !areAdditionalAuthorsEqual(originalPostData.authors.additional, postData.authors.additional);
  const hasSelectedMainCategoryChanged = originalPostData && originalPostData.mainCategory !== selectedMainCategory;
  const hasSelectedInThisStoryChanged = originalPostData && !areInThisStoriesEqual(originalPostData?.inThisStory || [], postMetaData?.inThisStory);
  const hasTopicChanged = originalPostData && originalPostData.customTopic !== selectedTopic;
  const hasVertical = selectedVertical !== '' || !(supportsCustomVertical && selectedVertical === '');
  const hasVerticalChange = originalPostData && originalPostData.customVertical !== selectedVertical;
  const hasExcludeFromRecommendationsChanged = originalPostData && (!!originalPostData.excludeFromRecommendations !== postMetaData.excludeFromRecommendations);
  const hasChangesAndIsValid = (
    hasAddedFeeds
    || hasSelectedMainCategoryChanged
    || featuredFeedsBeenChanged
    || postMetaDataBeenChanged
    || scheduledFeedsBeenChanged
    || additionalAuthorsBeenChanged
    || hasExcludeFromRecommendationsChanged
    || hasTopicChanged
    || hasSelectedInThisStoryChanged
    || hasVerticalChange)
    && hasVertical
    && totalFeeds;
  const hasChanges = (hasSelectedMainCategoryChanged
    || featuredFeedsBeenChanged
    || postMetaDataBeenChanged
    || scheduledFeedsBeenChanged
    || additionalAuthorsBeenChanged
    || hasExcludeFromRecommendationsChanged
    || hasSelectedInThisStoryChanged);
  const validationErrors = validatePost({ postMetaData });
  return {
    hasChangesAndIsValid,
    hasChanges,
    validationErrors,
  };
};

export const getRecommendedArticlesToUnFeature = (selectedRecommendedArticles, featuredArticlesForCurrentPost, isManualRecommendationsEnabled) => {
  if (!isManualRecommendationsEnabled) {
    return featuredArticlesForCurrentPost;
  }
  return featuredArticlesForCurrentPost.filter(
    article => !selectedRecommendedArticles.some(featureArticle => featureArticle.id === article.id),
  );
};

export const getRecommendedArticlesToFeature = (selectedRecommendedArticles, featuredArticlesForCurrentPost) => {
  const unFeaturedArticles = selectedRecommendedArticles.filter(
    article => !featuredArticlesForCurrentPost.some(featureArticle => featureArticle.id === article.id),
  );
  if (unFeaturedArticles.length) {
    return unFeaturedArticles.map(unFeaturedArticle => unFeaturedArticle.article);
  }
  return unFeaturedArticles;
};
