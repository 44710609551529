import { DEFAULT_ADS_CATEGORY } from '../components/echoCustomization/metadata/echoMetadata.constants';
import { POST_RECOMMENDATION_TOPIC_PREFIX } from '../constants/echo.constants';
import { isPostPublished, isPostScheduled } from './echoPublish.utils';

// TODO: Temporary solution: until we find a solution on the service side for not returning the <post|id> topics which
// represent the post topics created for manual recommendation purposes, we filter the returning feeds
// from the featuredFeeds so that they wont contain any <post|id> topics.
function filterOutPostTopics(featuredFeeds) {
  return featuredFeeds.filter(feedItem => !feedItem.slug.startsWith(POST_RECOMMENDATION_TOPIC_PREFIX));
}

// TODO: Temporary solution: On the client side, we cannot distinguish if a feed with the same property as the post is an auto feature feed or not.
// Consequently, we can only remove the cross-property auto feature feeds, even though all auto feature feeds should be removed.
// This function can be removed once the backend is updated to exclude all auto feature feeds.
export function filterOutCrossPropertyAutoFeatureFeeds(featuredFeeds, currProperty, selectedITSSlugs, availableITSList) {
  const selectedITS = availableITSList?.length && selectedITSSlugs?.length
    ? availableITSList.filter(its => selectedITSSlugs.includes(its.slug))
    : [];
  const isITSSelectedFeed = feedItem => selectedITS.some(itsItem => {
    return itsItem.autoFeature?.topic === feedItem.slug && itsItem.autoFeature?.property === feedItem.property;
  });

  return featuredFeeds?.filter(feedItem => feedItem.property === currProperty || isITSSelectedFeed(feedItem)) || [];
}

export function formatFeedsWithInThisStoryData(feeds, inThisStorySlugs, availableInThisStoryList) {
  const currentITSList = availableInThisStoryList.filter(its => inThisStorySlugs.some(itsSlug => itsSlug === its.slug));

  return feeds.map(feed => {
    const foundFeed = currentITSList.find(its => its.autoFeature?.topic === feed.slug && its.autoFeature?.property === feed.property);
    if (foundFeed) {
      const { autoFeature: { topic, property }, displayName, category } = foundFeed;
      return { ...feed, slug: topic, property, name: displayName, inThisStory: true, category };
    }
    return feed;
  });
}

function formatInThisStorySlugsIntoFeaturedFeed(inThisStory, availableInThisStoryList) {
  return inThisStory?.length > 0
    ? inThisStory.map(itsSlug => availableInThisStoryList.find(its => its.slug === itsSlug))
      .map(({ autoFeature: { property, topic }, displayName, category }) => ({ name: displayName, slug: topic, property, inThisStory: true, category }))
    : [];
}

// TODO: Remove once madden migration is done.
const getFormattedMaddenData = serverMaddenData => {
  const exampleArticleUrl = { value: serverMaddenData?.exampleArticleUrl || '', error: '' };
  const articleFinalUrl = { value: serverMaddenData?.articleFinalUrl || '', error: '' };
  const authors = serverMaddenData?.authors || [];

  for (let i = authors.length; i < 2; i += 1) {
    authors.push({ name: '', pageUrl: '' });
  }

  const destinations = serverMaddenData?.destinations || [];
  for (let i = destinations.length; i < 7; i += 1) {
    destinations.push({ pageUrl: '', feedName: '' });
  }
  return {
    exampleArticleUrl,
    articleFinalUrl,
    authors,
    destinations,
  };
};
function formatFeaturedFeeds(featuredFeeds, post, inThisStory, availableInThisStoryList) {
  let filteredFeeds = filterOutCrossPropertyAutoFeatureFeeds(featuredFeeds, post.property, inThisStory, availableInThisStoryList);
  filteredFeeds = filterOutPostTopics(filteredFeeds);

  if ((isPostPublished(post) || isPostScheduled(post)) && inThisStory?.length > 0) {
    return formatFeedsWithInThisStoryData(filteredFeeds, inThisStory, availableInThisStoryList);
  }
  return filteredFeeds;
}

export const getPostResponseFormatter = (post, availableInThisStoryList) => {
  const { id, coverURL, coverType, title, comments, labels, authors, hidden, createdAt, featuredAt, status, path, property, featuredFeeds, metadata, scheduledFeeds, bodyText, url, mainCategory, customCanonicalURL, customTopic, customVertical, excludeFromRecommendations, inThisStory } = post;
  const { owner, additional } = authors;
  const { maddenData } = metadata;

  const formattedFeaturedFeeds = formatFeaturedFeeds(featuredFeeds, post, inThisStory, availableInThisStoryList);
  const formattedScheduledFeeds = formatFeaturedFeeds(scheduledFeeds, post, inThisStory, availableInThisStoryList);

  return {
    originalPostData: { // TODO: Temporary solution: this can be changed back to 'originalPostData: post' once filterOutCrossPropertyAutoFeatureFeeds function is removed.
      ...post,
      featuredFeeds: formattedFeaturedFeeds,
      scheduledFeeds: formattedScheduledFeeds,
    },
    postData: {
      id,
      coverUrl: coverURL,
      coverType,
      createdAt,
      featuredAt,
      hidden,
      status,
      property,
      title,
      path,
      bodyText,
      url,
      comments,
      labels,
      authors: {
        owner: {
          name: owner.name,
          username: owner.username,
        },
        additional,
      },
    },
    postMetaData: {
      noIndex: metadata.noIndex || false,
      hideOwner: metadata.hideOwner || false,
      excludeFromRSS: metadata.excludeFromRSS || false,
      excludeFromRecommendations: excludeFromRecommendations || false,
      canonicalUrl: customCanonicalURL || '',
      postPath: path,
      adsCategory: metadata.adsCategory || DEFAULT_ADS_CATEGORY,
      commercialTags: metadata.commercialTags || [],
      sponsoredContent: metadata.sponsor || {},
      isSponsoredContentEnabled: !!metadata.sponsor || false,
      additionalPostsTopic: metadata.additionalPostsTopic || null,
      mainCategory,
      isPublishDateEnabled: false,
      publishDate: '',
      topic: customTopic,
      vertical: customVertical,
      bettingContent: metadata.betting || {},
      isBettingContentEnabled: !!metadata.betting,
      inThisStory: inThisStory || [],
    },
    featuredFeeds: formattedFeaturedFeeds,
    addedFeeds: [...((isPostPublished(post) || isPostScheduled(post)) ? [] : formatInThisStorySlugsIntoFeaturedFeed(inThisStory, availableInThisStoryList))],
    scheduledFeeds: formattedScheduledFeeds,
    maddenData: getFormattedMaddenData(maddenData), // TODO: Remove once madden migration is done.
  };
};

export function createImageObjectForSponsor({ uploadResponse, caption }) {
  const { url, path, fileExtension, fullImageUrl } = uploadResponse;
  return {
    url,
    path,
    fileExtension,
    fullImageUrl: fullImageUrl || url,
    caption,
    description: caption,
    provider: 'UserUpload',
    credit: '',
    aspectRatio: {
      x: 1,
      y: 1,
    },
  };
}
