import fetch from 'isomorphic-fetch';
import fetchJsonp from 'fetch-jsonp';
import { ConfigsProvider } from '../configs/ConfigsProvider'; // todo get from store
import { Logger } from '../logger';
import { getToken } from '../auth/auth0.utils';

export const HTTP_STATUS_CODES = {
  OK: 200,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  CONFLICT: 409,
};

export const HTTP_METHODS = {
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
};

const FETCH_TIMEOUT = 60 * 1000;

export const PROMISE_STATUS = {
  FULLFILED: 'fulfilled',
  REJECTED: 'rejected',
};

export const allPromisesFullfiled = promisesResponses => promisesResponses.every(response => response.status === PROMISE_STATUS.FULLFILED);

export const allPromisesRejected = promisesResponses => promisesResponses.every(response => response.status === PROMISE_STATUS.REJECTED);

export class mmNetworkService {
  static getDefaultRequestConfig() {
    const token = getToken();

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Client-Key': ConfigsProvider.CLIENT_ID_KEY,
      },
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    };
  }

  static externalFetch(url, conf) {
    return fetch(url, conf);
  }

  static async performFetch({ url, defaultConf, options = {} }) {
    let signal;
    let abortController;

    const { timeout = FETCH_TIMEOUT, ...inputConf } = options;
    const isAbortSupported = 'AbortController' in global;
    if (isAbortSupported) {
      abortController = new global.AbortController();
      ({ signal } = abortController);
    }
    const conf = { ...defaultConf, ...inputConf, signal: inputConf.signal ?? signal };
    const fetchPromise = fetch(url, conf)
      .then(async resp => {
        if (resp.status === HTTP_STATUS_CODES.NO_CONTENT) return '';

        if (resp.status === HTTP_STATUS_CODES.OK) {
          return resp.json();
        }
        const resObject = await resp.json();
        return Promise.reject(resObject.error || resObject);
      })
      .then(res => res.data || res)
      .catch(errResp => {
        if (typeof errResp.code !== 'undefined' || errResp.code !== HTTP_STATUS_CODES.UNAUTHORIZED) {
          Logger.info('Error request ', errResp, 'url=', url);
        } else {
          Logger.info('Error request ', errResp);
        }
        const error = {
          ...errResp,
          status: errResp.code,
        };
        throw error;
      });

    if (isAbortSupported) {
      const timeoutHandler = setTimeout(
        () => {
          abortController.abort();
          clearTimeout(timeoutHandler);
        },
        timeout,
      );
    }
    return fetchPromise;
  }

  static fetch(url, options) {
    const defaultConf = this.getDefaultRequestConfig();
    return this.performFetch({ url, defaultConf, options });
  }

  static postFormData(url, options) {
    const defaultConf = this.getDefaultRequestConfig();
    delete defaultConf.headers['Content-Type'];
    return this.performFetch({ url, defaultConf, options });
  }

  static jsonpExternalFetch(url, inputConf) {
    return fetchJsonp(url, inputConf);
  }

  static imageFetch(url) {
    const defaultConf = this.getDefaultRequestConfig();
    const conf = {
      ...defaultConf,
      headers: {
       ...defaultConf.headers,
        Accept: 'image/jpeg',
      },
    };
    return fetch(url, conf);
  }

  static async mmFetch({ url, options = {} }) {
    let signal;
    let abortController;
    const { timeout = FETCH_TIMEOUT, ...inputConf } = options;
    const isAbortSupported = 'AbortController' in global;
    if (isAbortSupported) {
      abortController = new global.AbortController();
      ({ signal } = abortController);
    }
    const conf = { ...inputConf, signal };
    const fetchPromise = fetch(url, conf)
      .then(async response => {
        if (response.status === HTTP_STATUS_CODES.NO_CONTENT) return '';

        if (response.status === HTTP_STATUS_CODES.OK) {
          return response.json();
        }
        const resObject = await response.json();
        return Promise.reject(resObject.error || resObject);
      })
      .catch(errResp => {
        if (typeof errResp.code !== 'undefined' || errResp.code !== HTTP_STATUS_CODES.UNAUTHORIZED) {
          Logger.info('Error request ', errResp, 'url=', url);
        } else {
          Logger.info('Error request ', errResp);
        }
        const error = {
          ...errResp,
          status: errResp.code,
        };
        return Promise.reject(error);
      });

    if (isAbortSupported) {
      const timeoutHandler = setTimeout(
        () => {
          abortController.abort();
          clearTimeout(timeoutHandler);
        },
        timeout,
      );
    }
    return fetchPromise;
  }
}
