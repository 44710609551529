import { BackgroundTaskStatus } from '@ftbpro/mm-admin-ui-components';
import { Dispatch } from '@reduxjs/toolkit';
import { Logger } from 'core/logger';
import { MessageSource, TaskTerminationReason } from './BackgroundTasks.constants';
import { BackgroundTaskData, INITIAL_TASK_DATA, SerializableBackgroundTaskData, TaskType } from './store/backgroundTasks.constants';
import { addTaskToLocalStorage } from './store/backgroundTasks.utils';
import { addBackgroundTask, updateBackgroundTask } from './store/backgroundTasksSlice';
import { TaskConfig } from './workers/WebWorkerWrapper';
import { WorkersFactory } from './workers/WorkersFactory';

/**
 * A method for initating a new Background task
 *
 * in order to continue showing the active tasks after refresh all init params must be serializable
 *
 * @param taskConfig the TaskConfig interface is the configuration which the WebWorker
 *  will use to perform the background task, in each interval,
 * and in addition to format the content and title
 * of the BackgroundTaskViewer (if not sent , default values will be used)
 *
 * the content and title formatters are strings that can contain placeholders of the form
 * ${expression}.
 * expression can be one of ResourcesStatusCount keys : total, succeeded, failed, inProgress, pending, canceled
 * background task service will perform the substitutions
 *
 * @expample
 * ```'Updating ${succeeded} out of ${total} items'```
 *
 * @param onTerminate A string that represents an action type.
 *
 * Background task service will dispatch the action - ``` dispatch({type: <onTerminateActionType>, payload: { id, reason: termination reason, status?: task status }})```
 */
export function initBackgroundTask(dispatch: Dispatch, params: {
  taskID: string,
  backgroundTaskData: SerializableBackgroundTaskData,
  taskConfig: TaskConfig,
  onTerminateActionTypeName?: string
}) {
  const {
    backgroundTaskData,
    taskID,
    taskConfig,
    onTerminateActionTypeName,
  } = params;

  const taskData = { ...INITIAL_TASK_DATA, ...backgroundTaskData };
  addTaskToLocalStorage({ taskID, taskData, taskConfig, onTerminateActionTypeName });
  dispatch(addBackgroundTask({ taskID, taskData }));
  const workerWrapper = WorkersFactory.createWorker(taskConfig);

  const { contentFormatByState, inProgressTitleFormatter, ...payload } = taskConfig; // eslint-disable-line

  workerWrapper.onmessage = ((e: MessageEvent<any>) => {
    Logger.debug(e);
    try {
      const formattedData = workerWrapper.formatData(e.data);
      formattedData && dispatch(updateBackgroundTask({ taskID, taskData: formattedData }));
      if (workerWrapper.isDone(e)) {
        workerWrapper.terminate();
        onTerminateActionTypeName && dispatch({ type: onTerminateActionTypeName, payload: { taskID, reason: TaskTerminationReason.Completed, status: formattedData?.status } });
      } else {
        workerWrapper.postMessage({ source: MessageSource.BackgroundTasksService, payload });
      }
    } catch (error) {
      Logger.error(`got error: ${error} , with msg: ${e} in BackgroundTasksService with config: ${taskConfig}`);
      workerWrapper.terminate();
      onTerminateActionTypeName && dispatch({ type: onTerminateActionTypeName, payload: { taskID, reason: TaskTerminationReason.Failed } });
      dispatch(updateBackgroundTask({
        taskID,
        taskData: {
          status: BackgroundTaskStatus.Unavailable,
        },
      }));
    }
  });
  workerWrapper.postMessage({ source: MessageSource.BackgroundTasksService, payload });

  return {
    terminate: () => {
      workerWrapper.terminate();
      onTerminateActionTypeName && dispatch({ type: onTerminateActionTypeName, payload: { reason: TaskTerminationReason.AbortedByUser } });
    },
  };
}

export async function addBackgroundTaskViewer(dispatch: Dispatch, params: {
  taskID: string,
  backgroundTaskData: BackgroundTaskData,
}) {
  const {
    backgroundTaskData,
    taskID,
  } = params;

  const taskData = {
    ...INITIAL_TASK_DATA,
    ...backgroundTaskData,
    type: TaskType.DetachedTask,
  };
  dispatch(addBackgroundTask({ taskID, taskData }));
}

export async function updateBackgroundTaskViewerProgress(dispatch: Dispatch, params: {
  taskID: string,
  backgroundTaskData: BackgroundTaskData,
}) {
  const {
    backgroundTaskData,
    taskID,
  } = params;

  dispatch(updateBackgroundTask({ taskID, taskData: backgroundTaskData }));
}
