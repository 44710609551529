export const MMPLAYER_SEARCH_PLACEHOLDER = 'Search for a video';
export const ENTER_MMPLAYER_EMBED_CODE_PLACEHOLDER = 'Paste Voltax Player iframe embed code';
export const EMBED_BROKEN_URL_TEXT = 'The embed code you are using is not valid for Minute Media. Please make sure that you are using an iframe embed code.';
export const MMPLAYER_LOAD_MORE_TEXT = 'Load more videos';

export const VMS_SEARCH_LIMIT = 10;
export const VMS_SEARCH_OFFSET = 10;

export const MMPLAYER_SEARCH_MODES = {
  EMBED: 'EMBED',
  SEARCH: 'SEARCH',
};

export const MMPLAYER_RADIO_OPTIONS = [
  {
    label: 'Search',
    value: MMPLAYER_SEARCH_MODES.SEARCH,
  },
  {
    label: 'Embed',
    value: MMPLAYER_SEARCH_MODES.EMBED,
  },
];

export const MMPLAYER_ERRORS = {
  SEARCH_ERROR: 'Search request failed, please, try again later',
  NO_DATA: 'Sorry, we did not find any videos',
  NO_MORE_VIDEOS: 'Sorry, there are no more videos',
  VIDEO_ERROR: 'Video cannot be loaded',
};
