import { LoggerClass, SentryProps } from '@ftbpro/mm-admin-logger';
import { ConfigsProvider } from '../configs/ConfigsProvider';

const sentryProps = {
  dsn: ConfigsProvider.SENTRY_DSN,
  orgSlug: ConfigsProvider.SNETRY_ORG_SLUG,
  ignoreErrors: ConfigsProvider.ERRORS_TO_IGNORE,
  allowUrls: ConfigsProvider.SENTRY_ALLOW_URLS,
  tenant: ConfigsProvider.TENANT,
  shouldReportToFullstory: ConfigsProvider.FeatureFlags.ReportFullStory,
} as SentryProps;

const loggerProps = {
  sentryProps,
  sentryLogLevel: ConfigsProvider.SENTRY_LOG_LEVEL,
  consoleLogLevel: ConfigsProvider.CONSOLE_LOG_LEVEL,
};

export const Logger = new LoggerClass(loggerProps);
