import { connectRouter, RouterState } from 'connected-react-router';
import { Reducer, AnyAction } from '@reduxjs/toolkit';
import { History } from 'history';
import { BackgroundTasksState } from 'core/backgroundTasks/store/backgroundTasks.constants';
import backgroundTasksReducer from 'core/backgroundTasks/store/backgroundTasksSlice';
import navigationFiltersReducer from 'core/store/navigationFilters/navigationFiltersSlice';
import propertyReducer from './property/propertySlice';
import { globalNotificationsReducer } from '../globalNotifications/store/globalNotifications.reducer';
import organizationReducer, { OrganizationSliceState } from './organization/organizationSlice';
import { NavigationFiltersState } from './navigationFilters/navigationFilters.constants';
import { PropertySliceState } from './property/property.types';
import userReducer from './user/userSlice';
import { UserSliceState } from './user/types';

export interface StaticReducers {
  router: Reducer<RouterState, AnyAction>
  user: Reducer<UserSliceState, AnyAction>
  property: Reducer<PropertySliceState, AnyAction>
  organization: Reducer<OrganizationSliceState, AnyAction>
  navigationFilters: Reducer<NavigationFiltersState, AnyAction>
  globalNotifications: Reducer
  backgroundTasks: Reducer<BackgroundTasksState, AnyAction>
}

export const staticReducers = (history: History): StaticReducers => ({
  router: connectRouter(history) as Reducer<RouterState, AnyAction>, // TODO(upgrade pkg) - temp hack - need to upgrade 'connected-react-router'
  user: userReducer,
  property: propertyReducer,
  organization: organizationReducer,
  navigationFilters: navigationFiltersReducer,
  globalNotifications: globalNotificationsReducer,
  backgroundTasks: backgroundTasksReducer,
});
