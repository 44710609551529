import { DashboardsSliceState, FilterType, DashboardState, Filter, FilterDataState } from './dashboards.types';
import { DashboardsPaths } from '../../reports.constants';
import { DateType } from '../../pages/components/DateRangeSetup/DateRangeSetup';

export const DASHBOARDS_DATA_SOURCE_ID = 'pubs_external_dashboard';

export const UNIFIED_PLAYER_DASHBOARD_DATA_SOURCE_ID = 'unified_reporting_dashboard';

export enum ChartsType {
  Stats = 'stats',
  BarChart = 'barChart',
  LineGraph = 'lineGraph',
  Table = 'table',
  PieChart = 'pieChart'
}

export enum ChartsFormat {
  Decimal = 'decimal',
  Currency = 'currency',
  Percent = 'percent',
}

export enum FilterId {
  Domain= 'domain',
  Country = 'country',
  Placement = 'placement',
  Device = 'device_category',
  OS = 'os',
  Activity = 'activity',
  PlacementId = 'placementID',
  DemandSource = 'demand_source',
  PlayerName = 'player_name',
  DemandOwner = 'demand_owner',
  Browser = 'browser',
  DemandType = 'demand_type',
}

export const PAGE_SIZE = 50;

export const SLICE_SCOPE = 'dashboards';

const FILTER_INITIAL_STATE: FilterDataState = {
  filterListItems: [],
  filterItemsLoading: false,
  filterItemsError: '',
};

const FILTERS_DATA = {
  [FilterId.Domain]: FILTER_INITIAL_STATE,
  [FilterId.Country]: FILTER_INITIAL_STATE,
  [FilterId.Placement]: FILTER_INITIAL_STATE,
  [FilterId.Device]: FILTER_INITIAL_STATE,
  [FilterId.OS]: FILTER_INITIAL_STATE,
  [FilterId.Activity]: FILTER_INITIAL_STATE,
  [FilterId.PlacementId]: FILTER_INITIAL_STATE,
  [FilterId.DemandSource]: FILTER_INITIAL_STATE,
  [FilterId.PlayerName]: FILTER_INITIAL_STATE,
  [FilterId.DemandOwner]: FILTER_INITIAL_STATE,
  [FilterId.Browser]: FILTER_INITIAL_STATE,
  [FilterId.DemandType]: FILTER_INITIAL_STATE,
};

export const DATE_INITIAL_VALUE = {
  startDate: '2023-09-27',
  endDate: '2023-10-03',
  dateType: 'Last 7 Days' as DateType,
};

const DASHBOARD_INITIAL_STATE: DashboardState = {
  chartsData: {},
};

export const INITIAL_STATE: DashboardsSliceState = {
  [DashboardsPaths.MMSSPRevenue]: DASHBOARD_INITIAL_STATE,
  [DashboardsPaths.RevenueOverview]: DASHBOARD_INITIAL_STATE,
  [DashboardsPaths.Homepage]: DASHBOARD_INITIAL_STATE,
  [DashboardsPaths.VoltaxRevenue]: DASHBOARD_INITIAL_STATE,
  [DashboardsPaths.RevenueOverviewSummary]: DASHBOARD_INITIAL_STATE,
  [DashboardsPaths.UnifiedPlayerRevenue]: DASHBOARD_INITIAL_STATE,
  filtersData: FILTERS_DATA,
  disclaimerData: {},
  pageLoaded: false,
};

export const MM_SSP_FILTERS_INITIAL_VALUE = {
  data: ['MM SSP (exc Voltax)'],
  field: 'activity',
  id: '',
  include: true,
  type: FilterType.ExactlyMatching,
};

export const VOLTAX_REVENUE_FILTERS_INITIAL_VALUE = {
  data: ['MM SSP (exc Voltax)'],
  field: 'activity',
  id: '',
  include: false,
  type: FilterType.ExactlyMatching,
};

export const INITIAL_FILTER: Filter = {
  id: '',
  field: '',
  include: true,
  data: [],
  type: FilterType.In,
};

export const DISCLAIMER_DATA_SOURCE = 'rev_overview_disclaimer';
export const DISCLAIMER = 'Disclaimer';

export const DASHBOARDS_EVENT_TYPES = {
  DASHBOARD_FILTERS_APPLIED: 'REPORTS/dashboards/reportApplyButtonClicked',
  DASHBOARD_RESET_CLICKED: 'REPORTS/dashboards/reportResetButtonClicked',
  DASHBOARD_PAGE_VIEWED: 'REPORTS/dashboards/reportDashboardPageViewed',
  DASHBOARD_PAGE_LOADED: 'REPORTS/dashboards/reportDashboardPageLoaded',
  DASHBOARD_DISCLAIMER_VIEWABLE: 'REPORTS/dashboards/reportDisclaimerInView',
};

export const UNIFIED_DISCLAIMER_DATA_SOURCE = 'unified_reporting_disclaimer';
