import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';

// TODO(types) - MUST change to specific type
let instance: any;

// TODO(types) - MUST change to specific type
export interface NotificationData {
  status: SNACK_BAR_STATUSES;
  text: string | string[];
  buttonText?: string;
  onButtonClick?: any;
  closeable?: boolean;
  onCloseAction?: () => { [key: string]: any};
  stayOpen?: boolean;
  animationDuration?: any;
  showLogout?: boolean
}

export interface NotificationDataWithID extends NotificationData {
  id: number
}

class NotificationCreator {
  uniqueId = 1;

  createGlobalNotification = (globalNotification: NotificationData): NotificationDataWithID => {
    this.uniqueId += 1;
    return { id: this.uniqueId, ...globalNotification };
  }
}
export const getNotificationCreator = () => {
  if (!instance) { instance = new NotificationCreator(); }
  return instance;
};

// TODO(types) - MUST change to specific type
export function removeGlobalNotification(gloablNotifications: NotificationDataWithID[], globalNotificationId:any): NotificationDataWithID[] {
  return gloablNotifications.filter(globalNotification => globalNotification.id !== globalNotificationId);
}
