import { PAGE_TITLES } from '../../../core/utils/utils';

export const NOT_FEATURED_POST_DATE = '-';

export const CMS = 'cms';

export const CMS_PAGES = {
  EDITOR_DASHBOARD: 'Editor Table',
  EDITOR_DRAFT: 'Editor Draft',
  ECHO_DASHBOARD: 'Publisher Table',
  ECHO_CUSTOMIZATION: 'Publisher Customization',
};

export const MAP_CMS_PAGES_TO_PAGE_TITLE = {
  [CMS_PAGES.EDITOR_DRAFT]: PAGE_TITLES.EDITOR,
  [CMS_PAGES.EDITOR_DASHBOARD]: PAGE_TITLES.EDITOR,
  [CMS_PAGES.ECHO_CUSTOMIZATION]: PAGE_TITLES.ECHO_PUBLISHER,
  [CMS_PAGES.ECHO_DASHBOARD]: PAGE_TITLES.ECHO_PUBLISHER,
};

export const SWIMSUIT_SLUG = 'si_swimsuit';
