export const COPY_TEXT = {
  COPY: 'Copy table data to clipboard',
  COPY_SUCCESS: 'Table data copied to clipboard. You can now paste it in another table.',
};

export const TABLE_BLOCK_EVENTS = {
  TABLE_SELECTED: '[EDITOR] Tables option selected from the media bar',
  TABLE_CREATED: '[EDITOR] Table created',
  TABLE_COPIED: '[EDITOR] Table copied',
  TABLE_DELETED: '[EDITOR] Table deleted',
  TABLE_EDITED: '[EDITOR] Table edited',
};
