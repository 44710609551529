export const PREVIEW_PREFIX = 'preview_';
export const PLUGIN_BUTTON_ADD_TEXT = 'Add';
export const PLUGIN_BUTTON_EDIT_TEXT = 'Save';
export const PLUGIN_BUTTON_CANCEL_TEXT = 'Cancel';
export const PLUGIN_BUTTON_CHOOSE_TEXT = 'Choose';
export const PLUGIN_BUTTON_IMPORT_TEXT = 'Import';
export const PLUGIN_COPYRIGHT_INFORMATION = 'By uploading or embedding the content you confirm your right to use it';
export const PLUGIN_COPYRIGHT_TOOLTIP_INFORMATION = 'We recommend reviewing the terms and privacy policies of any third-party plugins you are using regarding any personal information exchanged.';
export const LOAD_MORE_TEXT = 'Load more images';
export const ENTER_SEARCH_PLACEHOLDER = 'Search for an image';
export const PANEL_MODES = {
  GETTY: 'GETTY',
  CROP: 'CROP',
  IMAGN: 'IMAGN',
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
  IMAGE_EXCHANGE: 'IMAGE_EXCHANGE',
};

export const ERROR_MESSAGES = {
  NO_DATA: 'We searched far and wide, but didn\'t find anything like that. Sorry! ',
  NO_SEARCH_QUERY: 'Please, provide the search query',
  NO_MORE_IMAGES: 'Sorry, there are no more images',
};

export const RESPONSE_ERRORS = {
  INVALID_PAGE: 'InvalidPage',
  CODE500: 500,
};

export const GETTY_IMAGES_PROVIDER = 'GettyImages';
export const IMAGE_EXCHANGE_PROVIDER = 'ImageExchange';
export const MEDIA_LIBRARY_EMBED_PROVIDER = 'VoltaxImages';
export const IMAGN_IMAGES_PROVIDER = 'ImagnImages';
