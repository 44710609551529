import { runTwitterScript } from '../plugins/externalScripts/editorPluginsScripts';

import { mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { EditorServiceDataProvider } from './editorServiceConfigsProvider';
import { TWITTER_SORTING_OPTIONS } from '../plugins/twitter/twitter.constants';

const TWITTER_API_SEARCH = 'https://api.twitter.com/1.1/search/tweets.json';
const LOCAL_STORAGE_TWITTER_TOKEN = 'twitterAuthToken';

export class TwitterServices {
  static setTwitterTokenToLocalStorage(token) {
    localStorage.setItem(LOCAL_STORAGE_TWITTER_TOKEN, token);
  }

  static getTwitterTokenToLocalStorage() {
    return localStorage.getItem(LOCAL_STORAGE_TWITTER_TOKEN);
  }

  static getTwitterAuthTokenUrl(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getTwitterAuthTokenEndpoint(property), configs);
  }

  static getConfigForTwitterProxySearchRequest(url) {
    return {
      method: 'POST',
      body: JSON.stringify({
        url,
        method: 'GET',
        headers: {
          Authorization: TwitterServices.getTwitterTokenToLocalStorage(),
        },
      }),
    };
  }

  static refreshToken(property) {
    return new Promise((resolve, reject) => {
      TwitterServices.getTwitterAuthTokenUrl(property).then(response => {
        TwitterServices.setTwitterTokenToLocalStorage(response);
        resolve();
      })
        .catch(e => {
          reject(e);
        });
    });
  }

  static getTwitterSearchRequestUrl({ searchQuery, searchSorting = TWITTER_SORTING_OPTIONS.POPULAR, searchCount = 15, maxId = '' }) {
    return encodeURI(`${TWITTER_API_SEARCH}?q=${searchQuery}&result_type=${searchSorting}&count=${searchCount}${maxId ? `&max_id=${maxId}` : ''}`);
  }

  static async searchTweets({ searchQuery, searchSorting, searchCount, maxId, property }) {
    const url = TwitterServices.getTwitterSearchRequestUrl({ searchQuery, searchSorting, searchCount, maxId });
    try {
      const response = await mmNetworkService.fetch(EditorServiceDataProvider.getProxyEndpoint(property), TwitterServices.getConfigForTwitterProxySearchRequest(url));
      if (response.statuses) {
        return response.statuses;
      }
      return response.data.errors;
    } catch (e) {
      // Attempt to refresh a token and call search func again
      try {
        await TwitterServices.refreshToken(property);
        const response = await mmNetworkService.fetch(EditorServiceDataProvider.getProxyEndpoint(property), TwitterServices.getConfigForTwitterProxySearchRequest(url));
        return response.statuses;
      } catch (error) {
        return error;
      }
    }
  }

  static async loadTweets(callback, nodeId) {
    try {
      const twitter = await runTwitterScript();
      if (callback && typeof callback === 'function') {
        twitter.ready(() => {
          callback();
        });
      }
      return twitter.widgets.load(nodeId ? document.getElementById(nodeId) : null);
    } catch (e) {
      return null;
    }
  }
}
