import { COLORS } from '../../../../../../core/styles/colors';

export const LOGO_FIELD_KEYS = {
  URL: 'url',
  ALT: 'alt',
  SMALL_SCREEN_LOGO: 'smallScreenLogo',
  LARGE_SCREEN_LOGO: 'largeScreenLogo',
};

export const LOGO_SIZES = [
  LOGO_FIELD_KEYS.SMALL_SCREEN_LOGO,
  LOGO_FIELD_KEYS.LARGE_SCREEN_LOGO,
];

export const SUPPORTED_EXTENSIONS = 'image/svg+xml';

export const INITIAL_LOGO_FIELD_VALUE = {
  [LOGO_FIELD_KEYS.SMALL_SCREEN_LOGO]: {
    value: '',
    error: '',
  },
  [LOGO_FIELD_KEYS.LARGE_SCREEN_LOGO]: {
    value: '',
    error: '',
  },
  [LOGO_FIELD_KEYS.URL]: {
    value: '',
    error: '',
  },
  [LOGO_FIELD_KEYS.ALT]: {
    value: '',
    error: '',
  },
};

export const ITEMS_TEXTS = {
  [LOGO_FIELD_KEYS.SMALL_SCREEN_LOGO]: {
    label: 'Mobile Logo',
    info: 'Logo file format must be SVG',
  },
  [LOGO_FIELD_KEYS.LARGE_SCREEN_LOGO]: {
    label: 'Desktop Logo',
    info: 'Logo file format must be SVG',
  },
  [LOGO_FIELD_KEYS.ALT]: {
    label: 'Alt text',
    info: 'Insert logo description for SEO purposes',
    placeholder: 'Enter Alt text',
  },
  [LOGO_FIELD_KEYS.URL]: {
    label: 'Link to',
    info: 'Insert destination link',
    placeholder: 'Enter URL address',
  },
};

export const DEFAULT_FILENAME = 'Logo.svg';

export const LOGO_PREVIEW_WIDTH = '320px';
export const LOGO_PREVIEW_BACKGROUND_TEXT = 'Preview background';
export const LOGO_PREVIEW_BACKGROUNDS = [
  COLORS.WHITE,
  COLORS.BLACK30,
  COLORS.BLACK50,
  COLORS.BLACK70,
  COLORS.BLACK100,
];

export const DEFAULT_PREVIEW_OPTIONS = {
  background: LOGO_PREVIEW_BACKGROUNDS[0],
};
