import { ThunkMiddleware } from 'redux-thunk';
import { DATE_FORMATS, formatDate } from '@ftbpro/mm-admin-core-utils';
import { reportEvent } from 'core/analytics';
import {
  CONTRIBUTOR_ANALYTICS,
  EVENT_TYPES,
} from './contributorsAnalyticsMiddleware.strings';
import {
  SITE_STATS_EVENT_TYPES,
  USER_STATS_EVENT_TYPES,
} from '../analytics.constants';
import {
  shouldSkipAction,
  createCustomDimensions,
} from './contributorsAnalyticsMiddleware.utils';
import { USER_STATS_LABELS, SITE_STATS_LABELS } from '../analytics.strings';
import { setUserStatsTab, setSiteStatsTab } from '../store/analyticsSlice';
import { getAnalyticsSlice } from '../store/analytics.selector';
import { TabKey } from '../store/types';

export const contributorsAnalyticsMiddleware: ThunkMiddleware = store => next => action => {
    const state = store.getState();
    const contributorsAnalyticsState = getAnalyticsSlice(state);

    if (shouldSkipAction(action)) {
      return next(action);
    }

    switch (action.type) {
      case setUserStatsTab.type: {
        reportEvent(
          `tab=${USER_STATS_LABELS[action.payload as TabKey]}`,
          EVENT_TYPES.USER_STATS_TAB_CLICK,
          CONTRIBUTOR_ANALYTICS,
          createCustomDimensions(state),
        );

        return next(action);
      }
      case USER_STATS_EVENT_TYPES.USER_STATS_FILTER_CLICK: {
        reportEvent(
          `tab=${
            USER_STATS_LABELS[contributorsAnalyticsState.userStatsTab]
          };date=${action.payload}`,
          EVENT_TYPES.USER_STATS_FILTER_CLICK,
          CONTRIBUTOR_ANALYTICS,
          createCustomDimensions(state),
        );

        return next(action);
      }
      case USER_STATS_EVENT_TYPES.USER_STATS_PROPERTY_FILTER: {
        reportEvent(
          `tab=${
            USER_STATS_LABELS[contributorsAnalyticsState.userStatsTab]
          };property=${action.payload}`,
          EVENT_TYPES.USER_STATS_FILTER_CLICK,
          CONTRIBUTOR_ANALYTICS,
          createCustomDimensions(state),
        );

        return next(action);
      }
      case USER_STATS_EVENT_TYPES.USER_STATS_SITE_STATS_CLICK: {
        reportEvent(
          '',
          EVENT_TYPES.USER_STATS_SITE_STATS_CLICK,
          CONTRIBUTOR_ANALYTICS,
          createCustomDimensions(state),
        );
        return next(action);
      }
      case setSiteStatsTab.type: {
        reportEvent(
          `tab=${SITE_STATS_LABELS[action.payload as TabKey]};property=${
            state.property.cms.currentProperty.name
          }`,
          EVENT_TYPES.SITE_STATS_TAB_CLICK,
          CONTRIBUTOR_ANALYTICS,
          createCustomDimensions(state),
        );
        return next(action);
      }
      case SITE_STATS_EVENT_TYPES.SITE_STATS_FILTER_CLICK: {
        const tab = contributorsAnalyticsState.siteStatsTab;
        let label = '';

        if (tab === TabKey.Overview) {
          label = `tab=${SITE_STATS_LABELS[tab]};property=${state.property.cms.currentProperty.name};date=${action.payload}`;
        } else {
          const { userId, startDate, search } = contributorsAnalyticsState.postQuery;
          const author = userId?.length ? userId : 'All Authors';
          const date = formatDate(startDate, DATE_FORMATS.MONTH_AND_YEAR);

          label = `tab=${SITE_STATS_LABELS[tab]};property=${state.property.cms.currentProperty.name};date=${date};author=${author};search=${search}`;
        }

        reportEvent(
          label,
          EVENT_TYPES.SITE_STATS_FILTER_CLICK,
          CONTRIBUTOR_ANALYTICS,
          createCustomDimensions(state),
        );
        return next(action);
      }
      case SITE_STATS_EVENT_TYPES.SITE_STATS_USER_STATS_CLICK: {
        reportEvent(
          '',
          EVENT_TYPES.SITE_STATS_USER_STATS_CLICK,
          CONTRIBUTOR_ANALYTICS,
          createCustomDimensions(state),
        );
        return next(action);
      }
      default:
        return next(action);
    }
  };
