import { ConfigsProvider } from 'core/configs/ConfigsProvider';

const { SMART_GENERATION_SERVICE_BASE_URL } = ConfigsProvider;

const PROPERTIES = 'properties';
const POSTS = 'posts';

export class SmartServiceConfigsProvider {
  static getSmartGenerationEndpoint(
    property: string,
    postId: string,
  ) {
    return `${SMART_GENERATION_SERVICE_BASE_URL}/${PROPERTIES}/${property}/${POSTS}/${postId}`;
  }
}
