import { notifyTokenExpiredIfNeedTo } from 'core/store/user/userSlice';
import { presentArticleOverlapModal } from '../editorModals/editorModals.actions';
import { getEditorSaveManager } from '../../editorSaveManager/editorSaveManager';

export const NOTIFY_DRAFT_SAVE_SUCCESS = '[EDITOR] notify draft save success';
export const NOTIFY_DRAFT_SAVE_FAILURE = '[EDITOR] notify draft save failure';
export const NOTIFY_SAVING_DRAFT = '[EDITOR] notify saving draft';

const OVERLAP_CODE = 409;

export const handleDraftSavingError = error => dispatch => {
  const { code } = error;
  if (code === OVERLAP_CODE) {
    presentArticleOverlapModal()(dispatch);
  }
  notifyTokenExpiredIfNeedTo(error)(dispatch);
  return dispatch({
    type: NOTIFY_DRAFT_SAVE_FAILURE,
    payload: error,
  });
};

export const handleDraftSaveStatus = response => dispatch => {
  const { success, error } = response;
  if (success) {
    return dispatch({
      type: NOTIFY_DRAFT_SAVE_SUCCESS,
      payload: response,
    });
  }
  return handleDraftSavingError(error)(dispatch);
};

export const notifySavingDraft = () => dispatch => {
  dispatch({
    type: NOTIFY_SAVING_DRAFT,
  });
};

export const startDraftAutoSaving = ({ property, addToUndoList }) => (dispatch, getState) => {
  const onSaveHandler = saveDraftResponse => handleDraftSaveStatus(saveDraftResponse)(dispatch);
  const onStartSaving = () => dispatch(notifySavingDraft());
  const onAddToUndoList = draftData => dispatch(addToUndoList(draftData));
  getEditorSaveManager().startAutoSave({ property, onSaveHandler, onStartSaving, onAddToUndoList, getState });
};

export const stopDraftAutoSaving = () => () => {
  getEditorSaveManager().stopAutoSave();
};
