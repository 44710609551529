export const flexRow = {
  display: 'flex',
  flexDirection: 'row',
} as const;

export const flexRowEnd = {
  ...flexRow,
  justifyContent: 'flex-end',
};

export const flexRowCenter = {
  ...flexRow,
  justifyContent: 'center',
};

export const flexRowAlignCenter = {
  ...flexRow,
  alignItems: 'center',
};

export const flexRowBetween = {
  ...flexRow,
  justifyContent: 'space-between',
};

export const flexRowBetweenBaseline = {
  ...flexRowBetween,
  alignItems: 'baseline',
};

export const flexRowBetweenCenter = {
  ...flexRowBetween,
  alignItems: 'center',
};

export const flexRowBetweenStart = {
  ...flexRowBetween,
  alignItems: 'flex-start',
};

export const flexRowCenterCenter = {
  ...flexRowCenter,
  alignItems: 'center',
};

export const flexRowStart = {
  ...flexRow,
  justifyContent: 'flex-start',
};

export const flexRowStartStart = {
  ...flexRowStart,
  alignItems: 'flex-start',
};

export const flexRowStartCenter = {
  ...flexRowStart,
  alignItems: 'center',
};

export const flexRowStartBaseline = {
  ...flexRowStart,
  alignItems: 'baseline',
};

export const flexRowStartEndWrap = {
  ...flexRowStart,
  alignItems: 'flex-end',
  flexWrap: 'wrap',
};

export const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
} as const;

export const flexColumnCenter = {
  ...flexColumn,
  justifyContent: 'center',
};

export const flexColumnCenterCenter = {
  ...flexColumnCenter,
  alignItems: 'center',
};

export const flexColumnStartCenter = {
  ...flexColumn,
  alignItems: 'center',
};

export const flexColumnBetween = {
  ...flexColumn,
  justifyContent: 'space-between',
};

export const flexRowEndCenter = {
  ...flexRowEnd,
  alignItems: 'center',
};

export const inlineFlexRow = {
  display: 'inline-flex',
  flexDirection: 'row',
};

export const inlineFlexCenter = {
  ...inlineFlexRow,
  alignItems: 'center',
};
