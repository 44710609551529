import { combineReducers } from '@reduxjs/toolkit';
import socialSharingReducer from './socialSharing/socialSharingSlice';
import tagsAndSeoReducer from './tagsAndSeo/tagsAndSeoSlice';
import inThisStoryReducer from './inThisStory/inThisStorySlice';
import hyperlinksRecommendationReducer from './hyperlinksRecommendation/hyperlinksRecommendationSlice';
import migrationReducer from './migration/migrationSlice';
import { getEditorState } from '../editor.selector';

export function getEditorialMenuState(state) {
  return getEditorState(state).editorialMenu;
}

export const editorialMenuReducer = combineReducers({
  socialSharing: socialSharingReducer,
  tagsAndSeo: tagsAndSeoReducer,
  hyperlinksRecommendation: hyperlinksRecommendationReducer,
  inThisStory: inThisStoryReducer,
  migration: migrationReducer,
});
