import { Types } from '@ftbpro/mm-admin-ui-components';
import { COLORS } from 'core/styles/colors';
import { SITE_VARIABLES } from 'core/styles/variables';
import { injectGlobal } from 'emotion';

export interface AppcuesLaunchpadStyles {
  appcuesLaunchpad: Types.StylesObject
}

export const getStylesObject = (): AppcuesLaunchpadStyles => {
  return {
    appcuesLaunchpad: {
      '.appcues-widget-icon:after': {
        display: 'none !important',
      },
      height: '32px',
      width: '32px',

    },
  };
};

export const injectGlobalOverideStyles = () => injectGlobal`
.appcues-widget-list li.appcues-unread {
  font-weight: 400 !important;
  color: ${SITE_VARIABLES.COLORS.PRIMARY} !important;
}
.appcues-widget-list li {
  color: ${SITE_VARIABLES.COLORS.DARK_VADER} !important;
}
li.appcues-nothing-new {
  display: none;
}
a[data-itemid] time {
  display: none;
}
.appcues-widget-dropdown {
  border-radius: 2px;
  filter: drop-shadow(0px 2px 4px ${COLORS.BLACK100_OPACITY15});
}
`;
