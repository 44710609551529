import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { setLocalStorageData } from '@ftbpro/mm-admin-core-utils';
import { formatHqProperties, getCurrentPropertyLocalStorageKey } from './property.utils';
import { pushGlobalNotificationActionCreator } from '../../globalNotifications/store/globalNotifications.actions';
import { Product } from '../../constants';
import { AdministrationServiceConfigsProvider } from '../../services/administrationServiceConfigsProvider';
import { networkAction } from '../../middleware/networkMiddleware/networkMiddleware.actions';
import { PropertySliceState } from './property.types';

const FETCH_ERROR_TEXT = 'Sorry, we couldn\'t load any properties';

const SLICE_NAME = 'property';

export const initialState: PropertySliceState = {
  availablePropertiesHQ: {},
  organizationProperties: [],
  arePropertiesLoading: false,
  error: null,
};

const propertySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateCurrentProperty(state, action) {
      const { selectedProperty, product, userId } = action.payload;
      setLocalStorageData(getCurrentPropertyLocalStorageKey(userId, product), JSON.stringify(selectedProperty));
      state[product as Product] = { currentProperty: selectedProperty };
    },
    resetPropertiesByOrganization(state, action) {
      const { product } = action.payload;
      state.availablePropertiesHQ = {};
      state[product as Product] = { currentProperty: null };
    },
    fetchPropertiesByOrganizationSuccess(state, action) {
      state.availablePropertiesHQ = formatHqProperties(action.payload.properties);
    },
    fetchPropertiesByOrganizationRejected(state, action) {
      state.availablePropertiesHQ = {};
      state.error = action.payload;
    },
    fetchPropertiesRejected(state, action) {
      state.organizationProperties = [];
      state.error = action.payload;
    },
    fetchPropertiesPending(state) {
      state.arePropertiesLoading = true;
    },
    fetchPropertiesSuccess(state, action) {
      state.arePropertiesLoading = false;
      state.organizationProperties = action.payload.properties;
    },
  },
});

export const fetchPropertiesByOrganization = createAsyncThunk<void,
  { organizationId: string, productId: string }>(
    `${SLICE_NAME}/fetchPropertiesByOrganization`,
    async ({ organizationId, productId }, thunkAPI) => {
      const { dispatch } = thunkAPI;
      await dispatch(networkAction({
        url: AdministrationServiceConfigsProvider.getOrganizationPropertiesEndpoint(organizationId, productId),
        onSuccess: fetchPropertiesByOrganizationSuccess,
        onFailure:
          error => {
            dispatch(pushGlobalNotificationActionCreator({
              text: FETCH_ERROR_TEXT,
              status: SNACK_BAR_STATUSES.ERROR,
              stayOpen: true,
            }));
            return { type: fetchPropertiesByOrganizationRejected.type, payload: error };
          },
      }));
    },
  );

  export const fetchProperties = createAsyncThunk<void, {organization?: string }>(
    `${SLICE_NAME}/fetchProperties`,
    async ({ organization = '' } = {}, thunkAPI) => {
      const { dispatch } = thunkAPI;
      await dispatch(networkAction({
        url: AdministrationServiceConfigsProvider.getPropertiesEndpoint(organization),
        onFailure: fetchPropertiesRejected,
        onPending: fetchPropertiesPending,
        onSuccess: fetchPropertiesSuccess,
      }));
    },
  );

export const {
  updateCurrentProperty,
  resetPropertiesByOrganization,
  fetchPropertiesByOrganizationSuccess,
  fetchPropertiesByOrganizationRejected,
  fetchPropertiesRejected,
  fetchPropertiesPending,
  fetchPropertiesSuccess,
} = propertySlice.actions;

export default propertySlice.reducer;
