export const IMAGES_DASHBOARD_TEXTS = {
  HEADER_TITLE: 'Images',
};

export const UPLOAD_IMAGES = 'Upload';

export const TAGS_TEXT = {
  TAGS_HEADER: 'Tags',
  TAGS_INCLUDE_IMAGE: 'TAGS INCLUDE IMAGE',
  INCLUDE_IMAGE_TAGS_LIST: 'Include images with any of these tags:',
  TAGS_FILTER_INPUT_PLACEHOLDER: 'Add Tag',
  INC: 'Inc',
};

export const TAGS_OPTIONS = [
  {
    text: 'Any of these tags',
    value: 'any_tags',
  },
];

export const TAGS_OPTIONS_LABELS = TAGS_OPTIONS.map(item => item.text);

export const IMAGES_DASHBOARD_PAGE_SIZE = 50;

export const TABLE_NO_DATA_MESSAGE = 'There are no images';

export const FILTER_KEYS = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  STATUS: 'status',
  TAGS: 'tags',
  SEARCH: 'search',
};

export const STATUS_FILTER_VALUES = {
  DASHBOARD: {
    ALL_STATUSES: 'All Statuses',
    APPROVED: 'Approved',
    PENDING_APPROVAL: 'Pending approval',
    DRAFT: 'Draft',
    UPLOADING: 'Uploading',
  },
};

export const SERVER_IMAGE_STATUSES = {
  PROCESSING: 'processing',
  DRAFT: 'draft',
  PENDING_APPROVAL: 'pendingApproval',
  APPROVED: 'ready',
};

export const STATUS_FILTER_VALUES_QUERY = {
  [STATUS_FILTER_VALUES.DASHBOARD.ALL_STATUSES]: '',
  [STATUS_FILTER_VALUES.DASHBOARD.APPROVED]: SERVER_IMAGE_STATUSES.APPROVED,
  [STATUS_FILTER_VALUES.DASHBOARD.PENDING_APPROVAL]: SERVER_IMAGE_STATUSES.PENDING_APPROVAL,
  [STATUS_FILTER_VALUES.DASHBOARD.DRAFT]: SERVER_IMAGE_STATUSES.DRAFT,
  [STATUS_FILTER_VALUES.DASHBOARD.UPLOADING]: SERVER_IMAGE_STATUSES.PROCESSING,
};
