import { flexColumn, flexRowStartEndWrap } from '../../../../core/styles/flex';
import { SITE_VARIABLES } from '../../../../core/styles/variables';

export const MediaLibraryInputWithChipStyle = {
  root: {
    ...flexColumn,
    width: '300px',
  },
  chipsContainer: {
    ...flexRowStartEndWrap,
    marginTop: SITE_VARIABLES.MARGIN.SMALL,
  },
  chip: {
    margin: `0 ${SITE_VARIABLES.MARGIN.SMALL} ${SITE_VARIABLES.MARGIN.SMALL} 0`,
    width: 'fit-content',
  },
};
