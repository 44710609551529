import { getEditorState } from '../editor.selector';
import { coverImageSelector } from '../coverImage/coverImage.selector';
import { ELEMENT_PANEL_PLUGIN_TYPES } from '../../plugins/pluginConfiguration';
import { ELEMENT_PANEL_CONTEXT } from '../../components/elementPanel/elementPanel.constants';

function getElementPanelState(state) {
  return getEditorState(state).elementPanel;
}

export const elementPanelSelector = {
  isElementPanelOpen(state) {
    return getElementPanelState(state).isElementPanelOpen;
  },
  isElementPanelLoading(state) {
    return getElementPanelState(state).isElementPanelLoading;
  },
  currentActivePanelType(state) {
    const currentPanel = getElementPanelState(state).currentActivePanelType;
    return currentPanel || ELEMENT_PANEL_PLUGIN_TYPES.EMPTY_PANEL;
  },
  elementPanelContext(state) {
    return getElementPanelState(state).elementPanelContext;
  },
  isEditingCoverImage(state) {
    return getElementPanelState(state).elementPanelContext === ELEMENT_PANEL_CONTEXT.EDITING_COVER_IMAGE || coverImageSelector.isLoadingCoverImage(state);
  },
  editedBlockData(state) {
    return getElementPanelState(state).editedBlockData;
  },
  isEditingPanelActive(state) {
    return getElementPanelState(state).isEditingPanelActive;
  },
  isContentEnrichment(state) {
    return getElementPanelState(state).isContentEnrichment;
  },
};
