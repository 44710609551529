import { generateBlockKey } from './blockKey.utils';

export const BLOCK_TYPES = {
  ADDER: 'adder',
  APESTER: 'apester',
  CEROS: 'ceros',
  CONTENT_ENRICHMENT: 'mm-content-embed',
  DAILY_MOTION: 'dailyMotion',
  DIVIDER: 'divider',
  EXCO: 'exco',
  FACEBOOK: 'facebook',
  GETTY: 'getty',
  IMAGE_EXCHANGE: 'imageExchange',
  MEDIA_LIBRARY_EMBED: 'mediaLibraryEmbed',
  GIPHY: 'giphy',
  GFYCAT: 'gfycat',
  IFRAME_EMBED: 'iframeEmbed',
  INSTAGRAM: 'instagram',
  IMAGE: 'image',
  IMAGN: 'imagn',
  LIST_ITEM: 'list-item',
  LIVE_BLOG: 'live-blog',
  MM_PLAYER: 'mmPlayer',
  PAGE_BREAK: 'page-break',
  PLAYBUZZ: 'playbuzz',
  QUOTE: 'quote',
  SOUNDCLOUD: 'soundcloud',
  STREAMABLE: 'streamable',
  TABLE: 'table',
  TEXT: 'inline-text',
  TITLE: 'title',
  TWITTER: 'twitter',
  VIMEO: 'vimeo',
  YOUTUBE: 'youtube',
  IMPORTED_TABLE: 'imported-table',
  IMPORTED_EMBEDDED_CONTENT: 'imported-embedded-content',
  TABLE_OF_CONTENTS: 'table-of-contents',
  FAQ: 'faq',
  QUESTION: 'question',
  IMPORTED_VIDEO: 'imported-video',
  RAW_HTML: 'raw-html',
  CROWD_SIGNAL: 'crowd-signal',
};

export function generateNewBlockDescriptor(blockType, blockValue = null, blockKey = null) {
  return {
    key: blockKey || generateBlockKey(),
    type: blockType,
    value: blockValue,
  };
}

export function generateInlineTextBulkDescriptors(textBlockValuesArray) {
  return textBlockValuesArray.map(textValue => generateNewBlockDescriptor(BLOCK_TYPES.TEXT, textValue));
}
