import React, { Component, useEffect, useRef, useState } from 'react';
import { Icon, Input, Text } from '@ftbpro/mm-admin-ui-components';
import { SoundcloudIcon } from '@ftbpro/mm-admin-assets';
import { css } from '@emotion/core';

import { Plugin } from './Plugin/Plugin';
import { EmbedBlock } from './shared/EmbedBlock';

import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';
import { pluginAddButtonTextHandler, urlInputPlaceholderGenerator } from '../utils/plugins.utils';
import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const SOUNDCLOUD_OEMBED_URL_PREFIX = `https://soundcloud.com/oembed?format=json&${OEMBED_QUERY_PARAMS.URL}=`;
const ENTER_SOUNDCLOUD_PLACEHOLDER = urlInputPlaceholderGenerator('SoundСloud');
export const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from SoundСloud. Please paste a correct one.';
const SOUNDCLOUND_OVERVIEW_TEXT = 'Soundcloud video';

// Element-Panel Top Bar Button

export const SoundcloudEmbedTopBarIcon = props => {
  return (
    <Icon icon={SoundcloudIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class SoundcloudEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.SOUNDCLOUD;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      soundcloudData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.SOUNDCLOUD;
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { soundcloudData } = this.state;
    onAdd(soundcloudData);
  };

  onUrlInputKeyDown = e => {
    const { soundcloudData } = this.state;
    if (isEnterPressed(e) && soundcloudData) {
      this.onAdd(soundcloudData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    if (this.isValidSoundcloudUrl(url)) {
      const oEmbedEndPoint = `${SOUNDCLOUD_OEMBED_URL_PREFIX}${encodeURI(url)}`;
      getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.SOUNDCLOUD)
        .then(soundcloudData => {
          finishLoading();
          this.setState({ soundcloudData: { ...soundcloudData, html: soundcloudData.html } });
        })
        .catch(() => {
          finishLoading();
          this.setState({ soundcloudData: null });
        });
    } else {
      finishLoading();
      this.setState({ soundcloudData: null });
    }
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, soundcloudData } = this.state;
    if (url === '' || isLoading) {
      return null;
    }
    return soundcloudData && this.isValidSoundcloudUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: soundcloudData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidSoundcloudUrl = url => url.includes('soundcloud');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { soundcloudData, url } = this.state;
    const isAddDisabled = soundcloudData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_SOUNDCLOUD_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons onCancelClick={onCancel} onAddClick={this.onAdd} isAddDisabled={isAddDisabled} addButtonText={pluginAddButtonTextHandler(editedBlockData)} />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const SoundcloudBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  const isIframeInserted = () => {
    return blockRef.current && blockRef.current.querySelector('iframe');
  };

  useEffect(() => {
    if (isIframeInserted()) {
      blockRef.current.querySelector('iframe').addEventListener('load', () => {
        setLoading(false);
      });
    }
  });

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function SoundcloudEmbedOverview() {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <SoundcloudEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>{SOUNDCLOUND_OVERVIEW_TEXT}</Text>
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const soundcloudEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<SoundcloudEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<SoundcloudEmbedPanelComponent {...props} />),
  getPluginBlock: props => <SoundcloudBlock {...props} />,
  getPluginOverviewBlock: props => (<SoundcloudEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.SOUNDCLOUD,
};
