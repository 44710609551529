import { CMS_PAGES } from '../../../constants/cms.constants';
import { TAB_PANEL } from '../../components/editorialMenu/editorialMenu.constants';

export const EVENT_TYPES = {
  NEW_ARTICLE_CLICK: 'new article clicked',
  CONTENT_ADDED: 'content added',
  DELETE_CONTENT: 'delete content',
  EDIT_PICTURE: 'edit picture',
  TAGS_AND_SEO_CLICKED: 'tags and seo clicked',
  TAGS_ADDED: 'tags added',
  SUBMIT_CLICKED: 'submit clicked',
  UPDATE_PUBLISHED_DRAFT_REQUEST_SUCCESS: 'submit update clicked',
  NAVIGATION_CLICKED: 'navigation clicked',
  DRILL_DOWN_CLICKED: 'drill down clicked',
  USER_TYPED_IN_SEARCH_BAR: 'user typed in search bar',
  USER_CLICKED_ON_ARTICLE: 'user clicked on article',
  USER_RE_EDITS_ARTICLE: 'user re edits title',
  USER_DELETES_ARTICLE: 'user deletes article',
  USER_CLICKED_PREVIEW_MODE: 'user clicked preview move',
  BlOCK_ADDED: 'Block added',
  LIST_ADDED: 'listicle added',
  QUOTE_ADDED: 'quote added',
  DIVIDER_ADDED: 'divider added',
  HYPERLINKS_SUGGESTIONS_ACCESSED: 'hyperlinks suggestions accessed',
  SOCIAL_SHARING_CLICKED: 'social sharing clicked',
  PREVIEW_PAGE_OPEN_FROM_EDITOR_DASHBOARD: 'preview clicked from table',
  COPY_HYPERLINK_URL_TO_CLIPBOARD: 'hyperlink from suggestions copied',
  TEXT_EDITOR_HYPERLINK_CLICKED: 'Link button clicked',
  HYPERLINK_MODAL_CLOSED: 'Link pop up closed',

  REPORT_USE_ARTICLE_DATA_FOR_SOCIAL_SHARING: 'use article data clicked',
  SIDEBAR_OVERVIEW_CLICKED: 'sidebar overview clicked',
  SIDEBAR_SEO_CLICKED: 'sidebar seo clicked',
  SIDEBAR_SOCIAL_SHARING_CLICKED: 'sidebar social sharing clicked',
  REPORT_TAKE_TO_SEO_TITLE_CLICK: 'take me to seo title clicked',
  REPORT_TAKE_TO_SEO_DESCRIPTION_CLICK: 'take me to seo description clicked',
  REPORT_TAKE_TO_HYPERLINKS_CLICK: 'take me to hyperlinks clicked',
  REPORT_TAKE_TO_TAGS_CLICK: 'take me to tags clicked',
  REPORT_LIGHT_BULB_TOOLTIP_CLICK: 'lightbulb tooltip clicked',
  REPORT_URL_COPIED_FROM_FEATURE_SUGGESTIONS: 'hyperlink from suggestions copied',
  REPORT_SEO_HELPER_CENTER_BUTTON_CLICK: 'seo help center button clicked',
  REPORT_SUBMIT_BUTTON_CLICK_FROM_ARTICLE: 'sumbit clicked',

  SET_AUTO_FILL_SEO_DESCRIPTION_VALUE: 'seo description auto fill',
  OPEN_PREVIEW_PAGE_FROM_DRAFT: 'preview clicked from article',
  ARTICLE_COMMENTS_ADDED: 'article comments added',
  [CMS_PAGES.EDITOR_DRAFT]: 'Editor pageview',
  TAGS_AND_SEO_SETTINGS_SAVED: 'tags and seo settings saved',

  WRITING_SUGGESTION_OPENED: 'Writing Suggestions list opened',
  WRITING_SUGGESTION_CLOSED: 'Writing Suggestions close button clicked',
  WRITING_SUGGESTION_SELECTED: 'Writing Suggestions item selected',

  SUGGESTED_SEO_DESCRIPTION_CLICKED: 'Meta description Voltax Spark button clicked',
  SUGGESTED_SEO_DESCRIPTION_OPENED: 'suggested seo description clicked',
  SUGGESTED_SEO_DESCRIPTION_RECOMMENDATION_CLICKED: 'suggested seo description recommendation clicked',
  SUGGESTED_SEO_DESCRIPTION_FEEDBACK: 'suggested seo description feedback',
  SUGGESTED_SEO_DESCRIPTION_REGENERATED: 'suggested seo description regeneration clicked',

  CUSTOM_EMBED_CREATED: 'Custom Embed created',
  HEADLINE_SPARK_CLICKED: 'Headline Voltax Spark clicked',
  HEADLINE_FEEDBACK_CLICKED: 'Headline feedback clicked',
  HEADLINE_CLICKED: 'Headlines Suggestions clicked',
  HEADLINE_CLOSED: 'Headlines Suggestions closed',
  HEADLINE_REGENERATED: 'Headlines Suggestions regenerated',
  HEADLINE_SELECTED: 'Headlines Suggestions selected',
  HEADLINE_REMINDER_OPENED: 'Headlines Suggestion reminder on page',
  HEADLINE_REMINDER_CLICKED: 'Headlines Suggestion reminder clicked',

  TABLE_BLOCK_SELECTED: 'Tables option selected from the media bar',
  TABLE_BLOCK_CREATED: 'Table created',
  TABLE_BLOCK_COPIED: 'Table data copied to clipboard',
  TABLE_BLOCK_DELETED: 'Table deleted',
  TABLE_BLOCK_EDITED: {
    ROW_ADD: 'Row added to table',
    ROW_REMOVE: 'Row removed from table',
    COLUMN_ADD: 'Column added to table',
    COLUMN_REMOVE: 'Column removed from table',
  },

  OPEN_GETTY_PANEL: 'Getty Images Component selected',
  GETTY_IMAGE_SEARCH: 'Getty Images search',
  GETTY_IMAGE_SELECTED: 'Getty Images selected',
};

export const EDITORIAL_MENU_KEY_TO_EVENT_TYPE = {
  [TAB_PANEL.OVERVIEW_MENU.KEY]: EVENT_TYPES.SIDEBAR_OVERVIEW_CLICKED,
  [TAB_PANEL.SEO_MENU.KEY]: EVENT_TYPES.SIDEBAR_SEO_CLICKED,
  [TAB_PANEL.SOCIAL_SHARING_MENU.KEY]: EVENT_TYPES.SIDEBAR_SOCIAL_SHARING_CLICKED,
};

export const EDITOR = 'Editor';
export const CONTRIBUTOR_ANALYTICS = 'Contributor Analytics';
export const WRITING_SUGGESTIONS_EVENT_TYPES = {
  EDITOR_SUGGESTIONS_CLICKED: 'EDITOR_SUGGESTIONS_CLICKED',
  EDITOR_SUGGESTIONS_ITEM: 'EDITOR_SUGGESTIONS_ITEM',
};
