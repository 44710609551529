import {
  replaceBlockWithTextBlocks,
  replaceTextBlockWithBlockAdder,
  insertBlockAdderAboveTextBlock,
  insertBlockAdderBelowTextBlock,
} from './blockList.actions';

import {
  splitEditorIntoTwoNodes,
  isAnchorCollapsedToEnd,
  isAnchorCollapsedToStart,
} from '../../utils/slate/slate.utils';
import {
  isSlateEditorOfTypeList,
  getNodesValueByIndex,
} from '../../utils/slate/slateValue.utils';

export const HYPERLINK_MODAL_CLOSED = '[EDITOR] hyperlink modal closed';
export const HYPERLINK_MODAL_OPENED = '[EDITOR] hyperlink modal opened';

export const onTextBlockBecameEmpty = blockKey => dispatch => {
  replaceTextBlockWithBlockAdder(blockKey)(dispatch);
};

export const onEnterClickedInInlineText = (blockKey, slateEditor) => dispatch => {
  const editorValue = getNodesValueByIndex(slateEditor, 0);
  const shouldInsertBlockAdderAboveTextBlock = !isSlateEditorOfTypeList(slateEditor) && isAnchorCollapsedToStart(slateEditor);
  if (shouldInsertBlockAdderAboveTextBlock) {
    return insertBlockAdderAboveTextBlock(blockKey, editorValue)(dispatch);
  }
  const shouldInsertBlockAdderBelowTextBlock = isSlateEditorOfTypeList(slateEditor) || isAnchorCollapsedToEnd(slateEditor);
  if (shouldInsertBlockAdderBelowTextBlock) {
    return insertBlockAdderBelowTextBlock(blockKey, editorValue)(dispatch);
  }
  const [firstNodeAfterSplit, secondNodeAfterSplit] = splitEditorIntoTwoNodes(slateEditor);
  const newSlateValuesArray = [firstNodeAfterSplit, secondNodeAfterSplit];
  return replaceBlockWithTextBlocks(blockKey, newSlateValuesArray)(dispatch);
};
