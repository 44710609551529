import React, { useState, useMemo, useEffect, SyntheticEvent } from 'react';
import { Input } from '@ftbpro/mm-admin-ui-components';
import { isEnterPressed } from '@ftbpro/mm-admin-core-utils';
import { useStyles } from '@ftbpro/mm-admin-core-hooks';
import { BLOCK_TYPES } from 'containers/cms/editor/utils/blocksDescriptorGenerator';
import { pluginAddButtonTextHandler } from 'containers/cms/editor/utils/plugins.utils';
import { Plugin } from '../../Plugin/Plugin';
import { pluginInputStyle } from '../../shared/styles/pluginInput.styles';
import { getStylesObject } from '../../styles/embed.styles';
import {
  isValidLiveBlog,
  getLiveBlogData,
  getEID,
  getLiveBlogIframe,
} from '../liveBlogEmbed.utils';
import { LiveBlogPanelProps } from '../liveBlog.types';
import { LIVE_BLOG_TEXT } from '../liveBlog.constants';

export const LiveBlogPanel = (props: LiveBlogPanelProps) => {
  const {
    onCancel,
    editedBlockData,
    onAdd,
    isLoading,
    startLoading,
    finishLoading,
  } = props;

  const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.LIVE_BLOG;
  const [embedCodeHTMLString, setEmbedCodeHTMLString] = useState(
    isEditingBlock ? editedBlockData.value.embedCodeHTMLString : '',
  );
  const [liveBlogData, setLiveBlogData] = useState({});
  const { ERROR, PLACE_HOLDER } = LIVE_BLOG_TEXT;
  const styles = useStyles(getStylesObject);
  const isValid = useMemo(() => {
    return embedCodeHTMLString && isValidLiveBlog(embedCodeHTMLString);
  }, [embedCodeHTMLString]);

  const getLiveBlogEmbedData = (liveBlogString: string) => {
    startLoading();

    const formattedData = getLiveBlogData(liveBlogString);
    setLiveBlogData(formattedData);
    setEmbedCodeHTMLString(formattedData.embedCodeHTMLString);

    finishLoading();
  };

  useEffect(() => {
    if (embedCodeHTMLString) {
      getLiveBlogEmbedData(embedCodeHTMLString);
    }
  }, []);

  const onEmbedFieldKeyDown = (e: SyntheticEvent<HTMLElement, Event>) => {
    if (isEnterPressed(e) && liveBlogData) {
      onAdd(liveBlogData);
    }
  };

  const onAddClick = () => {
    onAdd(liveBlogData);
  };

  const onInputChange = (
    e: SyntheticEvent<HTMLElement, Event>,
    data?: string,
  ) => {
    setEmbedCodeHTMLString(data || '');
    if (data && isValidLiveBlog(data)) {
      getLiveBlogEmbedData(data);
    }
  };

  const clearInput = () => {
    setEmbedCodeHTMLString('');
    setLiveBlogData({});
  };

  const getPreviewComponent = () => {
    if (embedCodeHTMLString.trim() === '' || isLoading) {
      return null;
    }

    let iframe = null;
    if (isValid) {
      const id = getEID(embedCodeHTMLString);
      iframe = getLiveBlogIframe(id as string);
    }

    return liveBlogData && iframe ? (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: iframe }}
        css={styles.embed}
        key="dangerouslySetInnerHTML"
      />
    ) : (
      <Plugin.ErrorMsgComponent text={ERROR} />
    );
  };

  return (
    <Plugin.Container>
      <Plugin.Content>
        <Input
          value={embedCodeHTMLString}
          placeholder={PLACE_HOLDER}
          style={pluginInputStyle}
          onChange={onInputChange}
          onKeyDown={onEmbedFieldKeyDown}
          multiline
          autoHeight
          clearable
          onClear={clearInput}
        />
        {getPreviewComponent()}
      </Plugin.Content>
      <Plugin.CopyrightInformation />
      <Plugin.Buttons
        isAddDisabled={!isValid}
        onAddClick={onAddClick}
        isCancelDisabled={false}
        addButtonText={pluginAddButtonTextHandler(liveBlogData)}
        onCancelClick={onCancel}
      />
    </Plugin.Container>
  );
};
