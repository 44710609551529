import React, { Component, useState, useRef, useEffect } from 'react';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';
import { InstagramIcon } from '@ftbpro/mm-admin-assets';
import { css } from '@emotion/core';

import { Plugin } from './Plugin/Plugin';
import { EmbedBlock } from './shared/EmbedBlock';

import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';

import { getInstagramOembedData, loadInstagramPosts } from '../services/instagramServices';
import { overviewBlockText } from '../utils/blockList.utils';
import { urlInputPlaceholderGenerator, pluginAddButtonTextHandler } from '../utils/plugins.utils';

import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';

const ENTER_INSTAGRAM_PLACEHOLDER = urlInputPlaceholderGenerator('Instagram');
const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Instagram. Please paste a correct one.';

// Element-Panel Top Bar Button

export const InstagramEmbedTopBarIcon = props => {
  return (
    <Icon icon={InstagramIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class InstagramEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.INSTAGRAM;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      instagramData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.INSTAGRAM;
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { instagramData } = this.state;
    onAdd(instagramData);
  };

  onUrlInputKeyDown = e => {
    const { instagramData } = this.state;
    if (isEnterPressed(e) && instagramData) {
      this.onAdd(instagramData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading, property } = this.props;
    startLoading();
    if (!this.isValidInstagramUrl(url)) {
      finishLoading();
      this.setState({ instagramData: null });
      return;
    }
    getInstagramOembedData(url, property.slug)
      .then(instagramData => {
        finishLoading();
        this.setState({ instagramData });
        loadInstagramPosts();
      })
      .catch(() => {
        finishLoading();
        this.setState({ instagramData: null });
      });
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, instagramData } = this.state;
    if (!url || isLoading) {
      return null;
    }
    return instagramData && this.isValidInstagramUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: instagramData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidInstagramUrl = url => url.includes('instagram');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { instagramData, url } = this.state;
    const isAddDisabled = instagramData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_INSTAGRAM_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent() }
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const InstagramBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  const mutationCallback = () => {
    const instagramNode = blockRef.current;
    instagramNode.querySelector('iframe').addEventListener('load', () => {
      setLoading(false);
    });
  };
  const observer = new MutationObserver(mutationCallback);

  useEffect(() => {
    loadInstagramPosts();
    observer.observe(blockRef.current, { childList: true });
    return () => { observer.disconnect(); };
  });

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function InstagramEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <InstagramEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const instagramEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<InstagramEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<InstagramEmbedPanelComponent {...props} />),
  getPluginBlock: props => <InstagramBlock {...props} />,
  getPluginOverviewBlock: props => (<InstagramEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.INSTAGRAM,
};
