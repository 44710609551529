import {
  DATE_FORMATS,
  formatDate,
  getStartOfYesterday,
} from '@ftbpro/mm-admin-core-utils';
import {
  Constraint,
  ConstraintType,
  DisplayConstraintType,
  Filter,
  FilterType,
  QueryBuilderSliceState,
  QueryParams,
  ScheduleParams,
  SchedulePeriod,
  ScheduleReportStatus,
  Status,
  TableStatus,
  TimeZoneType,
} from './queryBuilder.types';
import { DateType } from '../../pages/components/DateRangeSetup/DateRangeSetup';

export const SLICE_SCOPE = 'queryBuilder';

export const INITIAL_FILTER: Filter = {
  id: '',
  field: '',
  include: true,
  type: FilterType.ExactlyMatching,
  data: [],
};

export const INITIAL_CONSTRAINT: Constraint = {
  id: '',
  field: '',
  include: true,
  type: 'eq',
  data: [],
};

export const INITIAL_QUERY_PARAMS: QueryParams = {
  dataSourceID: '',
  reportConfigID: '',
  reportName: 'New Report',
  dimensions: [],
  metrics: [],
  startDate: formatDate(getStartOfYesterday(), DATE_FORMATS.ISO_DATE),
  endDate: formatDate(getStartOfYesterday(), DATE_FORMATS.ISO_DATE),
  dateType: DateType.Yesterday,
  filters: [],
  constraints: [],
  timeZone: 'UTC',
};

export const INITIAL_SCHEDULE_PARAMS: ScheduleParams = {
  reportConfigID: '',
  reportName: '',
  emailAddresses: [],
  description: '',
  isActive: true,
  schedulePeriod: '',
  scheduleTime: '',
  timeZone: 'UTC',
};

export const INITIAL_STATE: QueryBuilderSliceState = {
  loading: false,
  isSaved: false,
  queryParams: INITIAL_QUERY_PARAMS,
  reportsList: [],
  dataSourceList: [],
  data: [],
  dimensionsColumnDefs: [],
  availableMetrics: {},
  availableDimensions: {},
  scheduleParams: INITIAL_SCHEDULE_PARAMS,
  tableStatus: TableStatus.CurrentReport,
  scheduledReports: [],
  isAPIKeyExist: false,
  APIKey: '',
  openGenerateAPIKeyModal: false,
  openRegenerateAPIKeyModal: false,
};

export const CONSTRAINT_OPERATORS: { [ADisplayConstraintTyp in DisplayConstraintType] : ConstraintType } = {
  '>': 'gt',
  '=>': 'gte',
  '<': 'lt',
  '<=': 'lte',
  '=': 'eq',
  Between: 'between',
};

export const DISPLAY_CONSTRAINT_OPERATORS: { [AConstraintType in ConstraintType] : DisplayConstraintType } = {
  gt: '>',
  gte: '=>',
  lt: '<',
  lte: '<=',
  eq: '=',
  between: 'Between',
};

export const TIMEZONE_OPERATORS: { [ATimeZoneType in TimeZoneType] : string } = {
  'New York (Eastern)': 'America/New_York',
  'Phoenix (MST)': 'America/Phoenix',
  'Los Angeles (Pacific)': 'America/Los_Angeles',
  London: 'Europe/London',
  Berlin: 'Europe/Berlin',
  Bangkok: 'Asia/Bangkok',
  Sydney: 'Australia/Sydney',
  'UTC-12': 'UTC-12',
  'UTC-11': 'UTC-11',
  'UTC-10': 'UTC-10',
  'UTC-9': 'UTC-9',
  'UTC-8': 'UTC-8',
  'UTC-7': 'UTC-7',
  'UTC-6': 'UTC-6',
  'UTC-5': 'UTC-5',
  'UTC-4': 'UTC-4',
  'UTC-3': 'UTC-3',
  'UTC-2': 'UTC-2',
  'UTC-1': 'UTC-1',
  UTC: 'UTC+0',
  'UTC+1': 'UTC+1',
  'UTC+2': 'UTC+2',
  'UTC+3': 'UTC+3',
  'UTC+4': 'UTC+4',
  'UTC+5': 'UTC+5',
  'UTC+6': 'UTC+6',
  'UTC+7': 'UTC+7',
  'UTC+8': 'UTC+8',
  'UTC+9': 'UTC+9',
  'UTC+10': 'UTC+10',
  'UTC+11': 'UTC+11',
  'UTC+12': 'UTC+12',
  'UTC+13': 'UTC+13',
  'UTC+14': 'UTC+14',
};

export const TIMEZONE_OPERATORS_LABELS: { [TimeZoneType in string] : TimeZoneType } = {
  'America/New_York': 'New York (Eastern)',
  'America/Phoenix': 'Phoenix (MST)',
  'America/Los_Angeles': 'Los Angeles (Pacific)',
  'Europe/London': 'London',
  'Europe/Berlin': 'Berlin',
  'Asia/Bangkok': 'Bangkok',
  'Australia/Sydney': 'Sydney',
  'UTC-12': 'UTC-12',
  'UTC-11': 'UTC-11',
  'UTC-10': 'UTC-10',
  'UTC-9': 'UTC-9',
  'UTC-8': 'UTC-8',
  'UTC-7': 'UTC-7',
  'UTC-6': 'UTC-6',
  'UTC-5': 'UTC-5',
  'UTC-4': 'UTC-4',
  'UTC-3': 'UTC-3',
  'UTC-2': 'UTC-2',
  'UTC-1': 'UTC-1',
  'UTC+0': 'UTC',
  'UTC+1': 'UTC+1',
  'UTC+2': 'UTC+2',
  'UTC+3': 'UTC+3',
  'UTC+4': 'UTC+4',
  'UTC+5': 'UTC+5',
  'UTC+6': 'UTC+6',
  'UTC+7': 'UTC+7',
  'UTC+8': 'UTC+8',
  'UTC+9': 'UTC+9',
  'UTC+10': 'UTC+10',
  'UTC+11': 'UTC+11',
  'UTC+12': 'UTC+12',
  'UTC+13': 'UTC+13',
  'UTC+14': 'UTC+14',
};

export const TABLE_PAGE_SIZE = 50;

export const ScheduleOptions: Array<SchedulePeriod> = ['Hourly', 'Daily', 'Sundays', 'Weekly', 'Monthly'];

export const ScheduleReportOptions: {[ASchedulePeriod in SchedulePeriod | ''] : string[]} = {
  '': [],
  Hourly: [],
  Weekly: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
  ],
  Daily: [
      '00:00',
      '1:00',
      '2:00',
      '3:00',
      '4:00',
      '5:00',
      '6:00',
      '7:00',
      '8:00',
      '9:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
  ],
  Monthly: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
  ],
  Sundays: [
    '00:00',
    '1:00',
    '2:00',
    '3:00',
    '4:00',
    '5:00',
    '6:00',
    '7:00',
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ],
};

export const ReportStatus: {[AScheduleReportStatus in ScheduleReportStatus] : Status} = {
  Active: 'success',
  Paused: 'archived',
};

export const QUERY_BUILDER_EVENT_TYPES = {
  QUERY_BUILDER_SHARE_CLICKED: 'REPORTS/queryBuilder/shareButtonClicked',
  QUERY_BUILDER_SCHEDULE_REPORT_CREATED: 'REPORTS/queryBuilder/scheduleReportCreation',
  QUERY_BUILDER_RUN_REPORT: 'REPORTS/queryBuilder/runReport',
  QUERY_BUILDER_CLEAR_ALL_CLICKED: 'REPORTS/queryBuilder/clearAllClicked',
  QUERY_BUILDER_SAVED_REPORT_LOADED: 'REPORTS/queryBuilder/savedReportLoaded',
  QUERY_BUILDER_GENERATE_API_KEY: 'REPORTS/queryBuilder/apiKeyGenerated',
  QUERY_BUILDER_SCHEDULE_REPORTS_VIEWED: 'REPORTS/queryBuilder/scheduledReportsViewed',
  QUERY_BUILDER_FETCH_DATA_SOURCE_ERROR: 'REPORTS/queryBuilder/fetchDataSourceListRejected',
  QUERY_BUILDER_FETCH_REPORT_ERROR: 'REPORTS/queryBuilder/fetchReportRejected',
  QUERY_BUILDER_SAVE_REPORT_ERROR: 'REPORTS/queryBuilder/saveReport',
  QUERY_BUILDER_FETCH_REPORTS_LIST_ERROR: 'REPORTS/queryBuilder/fetchReportsConfigList',
  QUERY_BUILDER_LOAD_REPORT_ERROR: 'REPORTS/queryBuilder/loadReport',
  QUERY_BUILDER_FETCH_SCHEDULE_ERROR: 'REPORTS/queryBuilder/fetchSchedule',
  QUERY_BUILDER_SAVE_SCHEDULE_ERROR: 'REPORTS/queryBuilder/saveSchedule',
  QUERY_BUILDER_UPDATE_SCHEDULE_ERROR: 'REPORTS/queryBuilder/updateSchedule',
  QUERY_BUILDER_DELETE_SCHEDULE_ERROR: 'REPORTS/queryBuilder/deleteSchedule',
  QUERY_BUILDER_DELETE_SAVED_REPORT: 'REPORTS/queryBuilder/deleteSavedReport',
};
