import { SITE_VARIABLES } from '../../../../../../../core/styles/variables';
import { flexRow, flexColumn } from '../../../../../../../core/styles/flex';

export const imageInfoDataStyles = {
  descriptionWrapperInput: {
    marginBottom: SITE_VARIABLES.MARGIN.BIG,
  },
  descriptionWrapperInputChip: {
    ...flexColumn,
    maxWidth: '100%',
    minWidth: 'auto',
    width: '100%',
  },
  imagePreviewDescriptionText: {
    ...flexRow,
    paddingBottom: `${SITE_VARIABLES.PADDING.TINY}`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'eclipse',
  },
  imagePreviewDescriptionTextId: {
    display: 'flex',
    color: `${SITE_VARIABLES.TEXT_COLOR.HINT}`,
    whiteSpace: 'pre',
  },
  imageDescriptionValue: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  urlDescriptionText: {
    ...flexRow,
    paddingBottom: `${SITE_VARIABLES.PADDING.TINY}`,
    overflow: 'visible',
    whiteSpace: 'nowrap',
    textOverflow: 'eclipse',
    position: 'relative',
  },
};
