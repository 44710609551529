/** @jsx jsx */
import { useEffect, useState, FunctionComponent } from 'react';
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Loader, SNACK_BAR_STATUSES, ThemeProvider } from '@ftbpro/mm-admin-ui-components';
import '@ftbpro/mm-admin-ui-components/dist/bundle.css';
import { useAuth } from '@ftbpro/mm-admin-auth';
import { LOGOUT_ERROR } from 'app.strings';
import { useUserData } from 'core/hooks/useUserData';
import { identifyUserInAppcues } from 'core/externalScripts/appcues';
import { BackgroundTasksProvider } from 'core/backgroundTasks/BackgroundTasksProvider';
import { fetchLoggedUserDetails, setToken } from 'core/store/user/userSlice';
import { getPropertyIdByRole } from 'core/store/user/user.utils';
import { fetchProperties } from 'core/store/property/propertySlice';
import { userSelector } from 'core/store/user/user.selector';
import { history } from './core/routing/history';
import { GlobalNotificationsCenter } from './core/globalNotifications/GlobalNotificationsCenter';
import { injectGTMScript } from './core/externalScripts/analyticsService';
import { getAllowedNavigationItems } from './core/utils/navigation.utils';
import { getStylesObject, injectGlobalOverideStyles } from './app.styles';
import { useStyles } from './core/hooks/useStyles';
import { getCurrentUrlForRedirect, isUserBlocked } from './core/auth/auth0.utils';
import { ConfigsProvider } from './core/configs/ConfigsProvider';
import { identifyUserInFullStory } from './core/externalScripts/fullStory';
import { AppContent } from './AppContent';
import { BlockedUserErrorPage } from './core/components/errorPage/BlockedUserErrorPage/BlockedUserErrorPage';
import { propertySelector } from './core/store/property/property.selector';
import { fetchOrganizationData, setAvailableTenants, setIsExternal } from './core/store/organization/organizationSlice';
import { NavigationItemData } from './platformConfigs/types';
import { pushGlobalNotificationActionCreator } from './core/globalNotifications/store/globalNotifications.actions';

// TODO(types) - MUST change to specific type
interface AppComponentProps {
  routes: () => JSX.Element,
  availableNavigationItems: NavigationItemData[],
  isExternal: boolean,
}

export const App: FunctionComponent<AppComponentProps> = props => {
  const {
    routes,
    availableNavigationItems,
    isExternal,
  } = props;
  const [navigationItems, setNavigationItems] = useState([]);

  const { isLoading, isAuthenticated, token, loginWithRedirect, logout } = useAuth();
  const styles = useStyles(getStylesObject, []);
  const arePropertiesLoading = useSelector(propertySelector.arePropertiesLoading);
  const propertiesList = useSelector(propertySelector.organizationProperties);
  const voltaxUserPremissions = getPropertyIdByRole(useSelector(userSelector.getVoltaxUserPermission), propertiesList);
  const userData = useUserData();
  const { userRoles, userDetails: { username, email, name }, orgId } = userData;

  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
      dispatch(fetchLoggedUserDetails());
      injectGlobalOverideStyles();
      injectGTMScript();
    }
  }, [token]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchProperties({ organization: orgId }));
      dispatch(fetchOrganizationData({ orgId }));
    }
  }, [orgId]);

  useEffect(() => {
    dispatch(setIsExternal(isExternal));
    dispatch(setAvailableTenants(ConfigsProvider.TENANTS_LIST));
  }, []);

  useEffect(() => {
    const allowedNavigationItems = getAllowedNavigationItems(availableNavigationItems, userRoles);
    setNavigationItems(allowedNavigationItems);
  }, [userRoles]);

  useEffect(() => {
    if (username) {
      identifyUserInFullStory(username);
    }
  }, [username]);

  useEffect(() => {
    if (userData) {
      identifyUserInAppcues(
        userData,
        voltaxUserPremissions,
      );
    }
  }, [voltaxUserPremissions, userData]);

  // TODO add isLoading or isAuthenticated and load appropriate ui
  const loginUser = () => {
    loginWithRedirect();
    return <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />;
  };

  const handleUnauthorizedUser = () => {
    const targetUrl = getCurrentUrlForRedirect().deepLink;
    return isUserBlocked(targetUrl) ? <BlockedUserErrorPage /> : loginUser();
  };

  const logoutHandler = () => {
    try {
      if (isExternal) {
        logout();
      } else {
        logout();
        window.location.replace('/logout.hq.html');
      }
    } catch (error) {
      dispatch(pushGlobalNotificationActionCreator({
        text: LOGOUT_ERROR,
        status: SNACK_BAR_STATUSES.ERROR,
      }));
    }
  };

  const handleAuthenticatedResult = () => {
    return (isAuthenticated ? (
      <ThemeProvider>
        <BackgroundTasksProvider>
          <div css={styles.container} data-testid="app">
            <AppContent
              userData={{ name, email }}
              logout={logoutHandler}
              navigationItems={navigationItems}
              routes={routes}
              styles={styles}
              isAppDataInitializing={arePropertiesLoading}
            />
            <GlobalNotificationsCenter logoutAction={logout} />
          </div>
        </BackgroundTasksProvider>
      </ThemeProvider>
    ) : handleUnauthorizedUser()
    );
  };

  return (
    <ConnectedRouter history={history}>
      {isLoading
        ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
        : handleAuthenticatedResult()}
    </ConnectedRouter>

  );
};
