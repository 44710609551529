import {
  Node,
  Text,
} from 'slate';
import {
  EMPTY_SLATE_VALUE,
  SLATE_NODE_TYPES,
  WRAPPING_SLATE_NODES,
} from './slate.constants';

export const WHITESPACE_REGEX = /\s*/g;

const mapNodeToString = (node => {
  return Node.string(node);
});

export const getNodesValueByIndex = (slateEditor, index) => {
  return [Node.child(slateEditor, index)];
};

export const getStringFromTextValue = value => {
  return value.map(mapNodeToString).join('\n');
};

/* ---------------- */
// VALUE CHECKS FUNCTION (areValuesEqual, isValueEmpty etc.)
/* ----------------- */

export const isSlateEditorOfTypeList = slateEditor => {
  const [nodeAsAValue] = getNodesValueByIndex(slateEditor, 0);
  return nodeAsAValue.type === SLATE_NODE_TYPES.BULLETED_LIST
    || nodeAsAValue.type === SLATE_NODE_TYPES.NUMBERED_LIST;
};

const zeroWidthSpaceCharValue = 8203;
const isTextEmptyWithZeroWidthSpace = text => {
  return text.length === 1 && text.charCodeAt(0) === zeroWidthSpaceCharValue;
};

const getStringOfSlateValue = value => {
  return value.reduce((accumulatedString, node) => {
    return accumulatedString + Node.string(node);
  }, '');
};

export const isSlateValueEmpty = value => {
  if (value.length === 0) {
    return true;
  }
  const stringValue = getStringOfSlateValue(value);
  return stringValue.length === 0 || isTextEmptyWithZeroWidthSpace(stringValue);
};

export const areTextsInSlateValuesEqual = (value1, value2) => {
  return getStringFromTextValue(value1) === getStringFromTextValue(value2);
};

export const getNumberOfNodesInAValue = value => {
  return value.length;
};

/* ---------------- */
// VALUE CREATION AND SEPARATION FUNCTIONS
/* ----------------- */

export const createSlateValue = (childrenArray, slateType = SLATE_NODE_TYPES.PARAGRAPH) => {
  return [
    {
      type: slateType,
      children: childrenArray,
    },
  ];
};

export const createMultipleTextNodes = textArray => {
  const slateNodes = textArray.map(text => {
    return {
      children: [{ text }],
      type: SLATE_NODE_TYPES.PARAGRAPH,
    };
  });
  return slateNodes;
};

export const separateSlateValueIntoMultipleValues = slateValue => {
  return slateValue.map(node => {
    return [node];
  });
};

const doesNodeContainTextBesidesWhiteSpace = node => {
  return node.text.replace(WHITESPACE_REGEX, '').length > 0;
};

export const getSanitizedSlateFragment = slateFragments => {
  const blockLevelFragments = [];
  const inlineTextNodesAndLinks = [];
  slateFragments.forEach(slateFragment => {
    if (WRAPPING_SLATE_NODES.includes(slateFragment.type)) {
      blockLevelFragments.push(slateFragment);
      /*
        Depend on how user copy text, sometimes the last wrapping html element is not coppied fully,
        so we do not have an indication of what that element is.
        We take all these nodes (besides fully whitespaces ones), and wrap them in a paragraph
       */
    } else if (slateFragment.url || doesNodeContainTextBesidesWhiteSpace(slateFragment)) {
      inlineTextNodesAndLinks.push(slateFragment);
    }
  });
  if (inlineTextNodesAndLinks.length > 0) {
    const paragraphValue = createSlateValue(inlineTextNodesAndLinks);
    return [...blockLevelFragments, ...paragraphValue];
  }
  return blockLevelFragments;
};

/* ---------------- */
// VALUE VALIDATION FUNCTIONS
/* ----------------- */

const isNodeTextNode = node => {
  return Text.isText(node);
};

const isNodeValidLinkNode = node => {
  return typeof node.url === 'string'
    && node.type === SLATE_NODE_TYPES.LINK
  && node.children.every(isNodeTextNode);
};

const isNodeChildValid = nodeChild => {
  return isNodeTextNode(nodeChild) || isNodeValidLinkNode(nodeChild);
};

const isValidTextBlock = node => {
  return node.children.every(isNodeChildValid);
};

const isValidListItems = node => {
  return node.type === SLATE_NODE_TYPES.LIST_ITEM && node.children.every(isNodeChildValid);
};

const isValidList = listNode => {
  return listNode.children.every(isValidListItems);
};

const isValidNode = node => {
  switch (node.type) {
    case SLATE_NODE_TYPES.PARAGRAPH:
    case SLATE_NODE_TYPES.HEADING_TWO:
    case SLATE_NODE_TYPES.HEADING_THREE:
    case SLATE_NODE_TYPES.HEADING_FOUR: {
      return isValidTextBlock(node);
    }
    case SLATE_NODE_TYPES.BULLETED_LIST:
    case SLATE_NODE_TYPES.NUMBERED_LIST: {
      return isValidList(node);
    }
    default: return false;
  }
};

export const isFragmentValid = fragment => {
  return fragment.every(isValidNode);
};

export const isEmptySlateParagraph = value => JSON.stringify(value) === JSON.stringify(EMPTY_SLATE_VALUE);
