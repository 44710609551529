import { SITE_VARIABLES } from '../../../styles/variables';

export const getStylesObject = () => {
  return {
    logo: {
      margin: `${SITE_VARIABLES.MARGIN.BIG} 0px 0px ${SITE_VARIABLES.MARGIN.SMALL}`,
      width: '24px',
      height: '24px',
    },
  };
};
