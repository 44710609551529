import { ConfigsProvider } from '../../../core/configs/ConfigsProvider';

export class CraftConfigsProvider {
  static get CRAFT_API_ENDPOINT() {
    return ConfigsProvider.CRAFT_API_BASE_URL;
  }

  static get MANAGEMENT_NAMESPACE() {
    return 'management';
  }

  static get PAGES_NAMESPACE() {
    return 'pages';
  }

  static get TEMPLATES_NAMESPACE() {
    return 'templates';
  }

  static get LAYOUTS_NAMESPACE() {
    return 'layouts';
  }

  static get AUTHORS_NAMESPACE() {
    return 'authors';
  }

  static get FEEDS_NAMESPACE() {
    return 'feeds';
  }

  static get SITEMAPS_NAMESPACE() {
    return 'sitemap';
  }

  static get PROPERTIES_NAMESPACE() {
    return 'properties';
  }

  static get MEDIA_NAMESPACE() {
    return 'media';
  }

  static get SIGNED_URL_NAMESPACE() {
    return 'signed-upload-url';
  }

  static get CATEGORIES_NAMESPACE() {
    return 'maincategories';
  }

  static get NAVIGATION_NAMESPACE() {
    return 'navigations';
  }

  static get PROPERTY_SETTINGS_NAMESPACE() {
    return 'settings';
  }

  static get THEMES_NAMESPACE() {
    return 'themes';
  }
}
