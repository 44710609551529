import { SITE_VARIABLES } from '../../../../core/styles/variables';

export const fullBleedImageTogglerStyles = {
  controlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: SITE_VARIABLES.MARGIN.TINY,
    paddingTop: SITE_VARIABLES.PADDING.MEDIUM_SMALL,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggle: {
    marginLeft: SITE_VARIABLES.MARGIN.TINY,
  },
  furtherInfo: {
    color: SITE_VARIABLES.COLORS.ARCHIVE,
  },
};
