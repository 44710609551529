import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export function navigateToPage(url: string) {
  history.push(url);
}

const historyStack = [history.location.pathname];

history.listen(({ pathname }) => {
  historyStack.push(pathname);
});

export function clearHistoryStack() {
  historyStack.length = 0;
}

export function getPreviouslyVisitedPage(shouldClearHistoryStack = true) {
  const lastVisitedPage = historyStack[historyStack.length - 2];
  if (shouldClearHistoryStack) {
    clearHistoryStack();
  }
  return lastVisitedPage;
}
