import { getAMPMTimeObject } from '@ftbpro/mm-admin-core-utils';
import {
  PIN_TO_TOP_HOURS,
  POST_RECOMMENDATION_TOPIC_PREFIX,
  NULLISH_DATE,
} from '../constants/echo.constants';
import {
  getDifferenceInMinutes, addHoursToDate, getStartOfDay,
  isTodayDate,
  getAMPMTimeFromDate,
  formatDate,
  DATE_FORMATS,
  getTimeFromTimeVars,
  setTimeToDate,
} from '../../../../core/utils/date.utils';

import { HTTP, HTTPS } from '../../services/imageServices/imageService.constants';

export const getTimeForFeedCard = date => {
  const createdAtDate = new Date(date);
  const format = isTodayDate(createdAtDate) ? DATE_FORMATS.TIME : DATE_FORMATS.SHORT_DATE_WITH_TIME;
  return formatDate(date, format);
};

export const getTimeForPostData = date => {
  const createdAtDate = new Date(date);
  const format = isTodayDate(createdAtDate) ? DATE_FORMATS.TIME : DATE_FORMATS.DATE;
  return formatDate(date, format);
};

export const getFeedObjectByFeedName = (name, feeds) => feeds.find(e => e.name === name);
export const getFeedObjectByFeedSlug = (slug, feeds) => feeds.find(e => e.slug === slug);
export const clearFeedFromFeedList = (feedList, feedToRemove) => feedList.filter(e => e.slug !== feedToRemove.slug);
export const isFeedInList = (feed, feedsList) => !!getFeedObjectByFeedSlug(feed.slug, feedsList);
export const isInThisStoryInFeedList = (inThisStory, feedsList) => !!getFeedObjectByFeedSlug(inThisStory.autoFeature.topic, feedsList);

// we do not display postRecommendationTopic feeds in the publisher, so we do not want to handle them under
// the logics of unfeature non existing feeds in current feed list
export const isPostRecommendationTopicFeed = feed => feed.slug.startsWith(POST_RECOMMENDATION_TOPIC_PREFIX);

export const findRemovedFeedsFromOriginalFeedList = ({ addedFeeds, currentFeedList, originalFeedList }) => {
  return originalFeedList.filter(originalFeed => !isPostRecommendationTopicFeed(originalFeed) && !isFeedInList(originalFeed, currentFeedList) && !isFeedInList(originalFeed, addedFeeds));
};

export const findRemovedInThisStoryFeedsFromOriginalList = ({ addedInThisStoryFeeds, currentInThisStoryList, originalInThisStoryList = [] }) => {
  return originalInThisStoryList?.filter(originalInThisStory => {
    return !isFeedInList(originalInThisStory, currentInThisStoryList)
      && !isInThisStoryInFeedList(originalInThisStory, addedInThisStoryFeeds);
  })?.map(({ autoFeature: { topic, property } }) => ({ slug: topic, property }));
};

export const findRemovedAuthorsFromOriginalAuthorsList = ({ original, added }) => {
  return original.filter(author => !added.find(addedAuthor => addedAuthor.name === author.name));
};

export const prepareUnscheduledFeedsForServer = feeds => feeds.filter(feed => !feed.featureTime).map(feed => ({ ...feed, featureTime: new Date() }));

export const isUrlChanged = (newUrl, oldUrl) => {
  return newUrl !== oldUrl;
};

export const removeMainCategoryFromPostPath = (postPath, mainCategory) => {
  return mainCategory ? postPath.replace(`/${mainCategory}`, '') : postPath;
};

export const areCommentsEqual = (originalComments, newComments) => {
  if (originalComments.length !== newComments.length) {
    return false;
  }
  for (let i = 0; i < originalComments.length; i += 1) {
    if (JSON.stringify(originalComments[i]) !== JSON.stringify(newComments[i])) {
      return false;
    }
  }
  return true;
};

const FILTER_STORAGE_PREFIX = 'echoDashboard-';

export const saveFilterValueToLocalStorage = (filterName, filterValue) => {
  localStorage.setItem(`${FILTER_STORAGE_PREFIX}${filterName}`, filterValue);
};

export const getFilterValueFromLocalStorage = filterName => {
  return localStorage.getItem(`${FILTER_STORAGE_PREFIX}${filterName}`);
};

export const getArrayFilterValueFromLocalStorage = filterName => {
  const value = localStorage.getItem(`${FILTER_STORAGE_PREFIX}${filterName}`);
  return value ? value.split(',') : [];
};

const MINUTES_IN_DAY = 1440;
const MINUTES_STEP = 15;

export const generateTimeOptions = selectedDate => {
  const isSelectedDateToday = isTodayDate(selectedDate);
  const date = isSelectedDateToday ? new Date() : new Date(selectedDate);
  const midnight = getStartOfDay(date);
  const minutesFromMidnight = getDifferenceInMinutes(date, midnight);
  const optionsList = [];
  let hours;
  let minutes;
  let ampm;
  for (let i = 0; i < MINUTES_IN_DAY; i += MINUTES_STEP) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    ampm = hours % 24 < 12 ? 'AM' : 'PM';
    hours %= 12;
    if (hours === 0) {
      hours = 12;
    } else if (hours < 10) {
      hours = `0${hours}`;
    }
    optionsList.push({ displayValue: `${hours}:${minutes} ${ampm}`, minutesValue: i });
  }
  return {
    optionsList: isSelectedDateToday ? optionsList.filter(option => option.minutesValue > minutesFromMidnight) : optionsList,
    timeValue: getAMPMTimeFromDate(selectedDate),
  };
};

export const createPinTime = (pinnedTime, featureTime) => {
  const now = new Date();
  if (!pinnedTime || pinnedTime === NULLISH_DATE) {
    return null;
  }
  if (now <= new Date(featureTime)) {
    return addHoursToDate(new Date(featureTime), PIN_TO_TOP_HOURS);
  }
  return addHoursToDate(new Date(), PIN_TO_TOP_HOURS);
};

export const generateFeedsListWithFeatureTime = (feeds, featureTime) => {
  const now = new Date();
  return feeds.map(feed => {
    return {
      ...feed,
      featureTime: now <= new Date(featureTime) ? featureTime : '',
      pinnedTTL: createPinTime(feed.pinnedTTL, featureTime),
    };
  });
};

export const replaceFeedsByGivenFeedsWithFeatureTime = (srcFeedsList, modifiedFeeds) => {
  return srcFeedsList.map(srcFeed => (isFeedInList(srcFeed, modifiedFeeds) ? getFeedObjectByFeedSlug(srcFeed.slug, modifiedFeeds) : srcFeed));
};

export const setPinToTopTime = (srcFeedsList, modifiedFeed) => {
  const { featureTime } = modifiedFeed;
  const newPinnedToTopTime = modifiedFeed.featureTime ? addHoursToDate(new Date(featureTime), PIN_TO_TOP_HOURS) : addHoursToDate(new Date(), PIN_TO_TOP_HOURS);
  return srcFeedsList.map(srcFeed => (srcFeed.slug === modifiedFeed.slug ? { ...srcFeed, pinnedTTL: srcFeed.pinnedTTL ? null : newPinnedToTopTime } : srcFeed));
};

export const hasPostListBeenChanged = (oldList, newList) => {
  return oldList.length !== newList.length || !(oldList.every((item, index) => item.id === newList[index].id));
};

export const arePostsTheSame = (prevPostId, nextPostId) => {
  return prevPostId === nextPostId;
};

export const buildFullPathUrl = (relativePath, endpoint) => `https://${endpoint}${relativePath}`;

export const isCanonicalUrlValid = value => [HTTP, HTTPS].some(protocol => value.startsWith(protocol));

export const combineDateAndTime = (date, time) => {
  return setTimeToDate(new Date(date.setSeconds(0, 0)), getTimeFromTimeVars(time.hours || '00', time.minutes, time.period));
};

export const getAMPMTimeObjectFromDate = date => {
  const { hours, minutes, period } = getAMPMTimeObject(date);
  return { hours, minutes, period };
};

// TODO: Remove once madden migration is done.
export const getIsMaddenStaticProperty = (tenant, property) => {
  const isMaddenPropertyQa = property === 'site-qa-2';
  const isMaddenPropertyProd = property === 'si-temp';
  return (tenant === 'qa' && isMaddenPropertyQa) || (tenant === 'mmsport' && isMaddenPropertyProd);
};

export const getIsMaddenVoltaxSiteProperty = (tenant, organization, property) => {
  const isMaddenPropertyQa = organization === 'minutemedia' && property === 'floor8_en_international_web';
  const isMaddenPropertyProd = organization === 'si' && property !== 'si-temp'; // si-temp is the static site
  return (tenant === 'qa' && isMaddenPropertyQa) || (tenant === 'mmsport' && isMaddenPropertyProd);
};

export const getValidationUrlErrorMessage = errorCode => {
  const error_msg = {
    212: 'The example URL is not a post page link. Please provide a valid post page URL.',
    51027: 'The author page URL is incorrect or does not exist. Please enter a correct URL.',
    4: 'The article final URL is not supported. Please make sure that the URL begins with “www” and/or has a supported domain',
  };
  return error_msg[errorCode];
};

export const IN_THIS_STORY_TOOLTIP = "Automatic Team's Feed";
