import { configureStore, combineReducers, Reducer, EnhancedStore, MiddlewareArray, Middleware, AnyAction } from '@reduxjs/toolkit';
import { History } from 'history';
import { ThunkMiddleware } from 'redux-thunk';
import { ConfigsProvider } from '../configs/ConfigsProvider';
import { staticReducers, StaticReducers } from './root.reducer';
import { isDebugMode } from '../utils/utils';

interface DynamicReducers { [key:string]: Reducer }
type CombinedReducers = StaticReducers & DynamicReducers

function createReducer(history: History, asyncReducers: DynamicReducers = {}): CombinedReducers {
  return {
    ...staticReducers(history),
    ...asyncReducers,
  };
}

interface CustomStore extends EnhancedStore<any, any | AnyAction, MiddlewareArray<ThunkMiddleware|Middleware>>{
  asyncReducers: DynamicReducers
  injectReducer: (key: string, asyncReducer: Reducer) => void
}

// Configure the store
export function configureDynamicStore(history: History, middleware: MiddlewareArray<ThunkMiddleware|Middleware>) {
  const store = configureStore({
    reducer: createReducer(history),
    middleware,
    devTools: ConfigsProvider.ENV !== 'production' || isDebugMode(),
  }) as CustomStore;

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key: string, asyncReducer: Reducer) => {
    if (!store.asyncReducers) store.asyncReducers = {};
    if (!!key && !(store.asyncReducers?.[key])) {
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(combineReducers(createReducer(history, store.asyncReducers)));
    }
  };

  // Return the modified store
  return store;
}
