import { UserDetails, UserSliceState } from './types';

export const INITIAL_STATE: UserSliceState = {
  userRoles: [],
  userDetails: {} as UserDetails,
  orgId: '',
  isUserDataLoaded: false,
  voltaxUserPremissions: {},
};

export const LOCAL_HOST = 'localhost';

export const MINUTEMEDIA_ORG_ID = 'minutemedia';

export const UPLOAD_IMAGES_ROLE = 'upload_images';
