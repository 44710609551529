import { EmbedType, Post } from './store/types';
import { EMBED_TYPES } from './components/ContentEnrichmentTable/ContentEnrichmentTableFilters/contentEnrichmentTableFilters.constants';

export const isUrlExist = (posts: Post[], id: string) => posts.findIndex(post => post.id === id) > -1;

export const smartTrimWithEllipses = (
  str: string,
  maxLen: number,
  separator = ' ',
) => {
  if (!str || str.length <= maxLen) return str;
  return str.slice(0, str.lastIndexOf(separator, maxLen)).concat('...');
};

export const getEmbedType = (slug: EmbedType) => {
  const typeObject = EMBED_TYPES.find(embed => embed.slug === slug);
  return typeObject ? typeObject.label : '';
};
