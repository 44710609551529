import React from 'react';
import { GENERAL_ERROR } from '../../../app.strings';
import { Logger } from '../../logger';
import { ErrorPage } from '../errorPage/ErrorPage';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    Logger.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage {...GENERAL_ERROR} />;
    }

    return children;
  }
}
