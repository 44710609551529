import { STATUSES } from '@ftbpro/mm-admin-ui-components';
import { TIME_PERIODS } from 'core/utils/date.utils';

export const ECHO_DASHBOARD_PAGE_SIZE = 50;

export const PIN_TO_TOP_HOURS = 8;

export const NULLISH_DATE = '0001-01-01T00:00:00Z';

export const SERVER_POST_STATUSES = {
  SUBMITTED: 'submitted',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  FAILURE: 'failed',
  ARCHIVED: 'archived',
};

export const POST_DATA_TEXT = {
  CREATED_AT: 'Submission Date: ',
  BY: 'By ',
  POST_STATUSES_TEXTS: {
    [SERVER_POST_STATUSES.SUBMITTED]: 'Submitted',
    [SERVER_POST_STATUSES.SCHEDULED]: 'Scheduled',
    [SERVER_POST_STATUSES.PUBLISHED]: 'Published',
    [SERVER_POST_STATUSES.FAILURE]: 'Failure',
    [SERVER_POST_STATUSES.ARCHIVED]: 'Archived',
  },
  POST_STATUSES_INDICATOR_TYPES: {
    [SERVER_POST_STATUSES.NEW]: STATUSES.NEW,
    [SERVER_POST_STATUSES.SCHEDULED]: STATUSES.PENDING,
    [SERVER_POST_STATUSES.PUBLISHED]: STATUSES.SUCCESS,
    [SERVER_POST_STATUSES.FAILURE]: STATUSES.FAILURE,
    [SERVER_POST_STATUSES.ARCHIVED]: STATUSES.ARCHIVED,
  },
};

export const ECHO_PUBLISH_BUTTON_TEXT = {
  PUBLISH: 'Publish',
  UPDATE: 'Update',
};

export const CUSTOMIZATION_HEADER_TITLE = 'Article Customization';

export const PN_HEADER_TITLE = 'Push Notifications';

export const REMOVE_SCHEDULED_POST_TEXT = {
  TITLE: 'Remove Scheduled Post From Feed',
  MODAL_TEXT: 'Are you sure you want to remove this scheduled post?',
  BUTTON_TEXT: 'Remove',
  CANCEL_TEXT: 'Cancel',
};

export const SCHEDULE_POST_TEXT = {
  TITLE: 'Pick Date and Time',
  BUTTON_TEXT: 'Schedule',
  BUTTON_TOOLTIP_MISSING_TIME: 'Please choose a time',
  BUTTON_TOOLTIP_INVALID_TIME: 'Please choose a time that has not passed',
  PUBLISH_NOW_TEXT: 'Publish Now',
};

export const PUSH_NOTIFICATION_TEXTS = {
  FAILURE: 'Oops. Something didn\'t go according to plan here.',
  MOVED_TO_ARCHIVED: 'The article is now archived.',
  MOVED_OUT_OF_ARCHIVED: 'The article is now active.',
  PUBLISHED: 'The article was published successfully.',
  UPDATED: 'The article was updated successfully.',
  DELETE_POST_SUCCESS: 'The post was deleted successfully.',
  DELETE_POST_FAILURE: 'Error occurred deleting the post, try again later.',
  SEARCH_LENGTH_FAILURE: 'Search term should be at least 3 characters long.',
  INVALID_SEARCH_TERM: 'Search term must contain at least 3 valid characters.',
  GET_POST_DATA_FAILURE: 'Article could not be accessed',
  COPY_TO_CLIPBOARD: 'Successfully copied to clipboard',
  NEW_DATA: 'There is new data on dashboard, click \'refresh\' to reload data',
};

export const PUSH_NOTIFICATION_BUTTONS_TEXT = {
  REFRESH: 'REFRESH',
};

export const ICON_TOOLTIPS = {
  SCHEDULING: 'Scheduling',
  PREVIEW: 'Preview',
  EDIT: 'Edit',
  DELETE: 'Delete',
  COPY_URL: 'Copy URL',
  COMMENTS: 'Comments',
  LABELS: 'Labels',
  SEARCH: 'Search will be available soon',
  SPONSORING: 'Sponsoring will be available soon',
  CAN_NOT_PUBLISH: 'You must select at least one destination',
  ADD_VERTICAL: 'You must select a vertical for the article',
  CAN_NOT_UPDATE: 'Your article is already updated to the latest version',
  VISIBLE_OWNER: 'Author visible',
  HIDDEN_OWNER: 'Author hidden',
  ADD_AUTHOR: 'Add author',
  REMOVE_AUTHOR: 'Remove author',
};

export const SORTING_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const AUTHORS_MODAL_COMPONENT_TEXT = {
  TITLE: 'Adding Authors',
  ADD_BUTTON: 'Add',
  CANCEL_BUTTON: 'Cancel',
};

export const MAX_ADDITIONAL_AUTHORS_AMOUNT = 6;

export const AUTHORS_LIMIT_ERROR = 'You can add only 6 additional authors';

export const POLLING_INTERVAL = 60000;
export const POLLING_SNOOZE_INTERVAL = 180000;

export const POST_RECOMMENDATION_TOPIC_PREFIX = 'post|';

export const SCHEDULE_DEFAULT_TIME = {
  hours: '12',
  minutes: '00',
  period: TIME_PERIODS.AM,
};
