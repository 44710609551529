import { mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { MediaLibraryImagesConfigsProvider } from './mediaLibraryImagesConfigsProvider';
import { getImagesQueryString } from '../utils/mediaLibraryQueryStringBuilder';
import { generateImageInfoBody } from '../utils/images.utils';
import { getPixelCroppingAndUrl, getRealImageObject } from '../../../cms/services/imageServices/imageService.utils';
import { Logger } from '../../../../core/logger';

const VOLTAX_PROVIDER = 'voltaxMediaLibrary';

export class MediaLibraryImagesNetworkService {
  static getImages({ property, filters, limit }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.getImages({
      property,
      queryString: getImagesQueryString(filters, limit),
    }), configs);
  }

  static getImageInfo({ property, id }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.getImageInfo({ property, id }), configs);
  }

  static getPagedPosts({ path }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.getPagedPosts({ path }), configs);
  }

  static getImageUploadUrl({ property, uploadedFile, currenUserName, tags }) {
    const { height, width, extension, file } = uploadedFile.imageData;
    const { name, size } = file;
    const configs = {
      method: 'POST',
      body: JSON.stringify({
        title: name,
        extension,
        provider: VOLTAX_PROVIDER,
        caption: '',
        tags: tags || [],
        uploadedBy: currenUserName,
        altText: '',
        credit: '',
        auditTrail: '',
        height,
        width,
        size,
      }),
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.getImageUploadUrl({ property }), configs);
  }

  static getApproveUploadUrl({
                               property,
                               uploadedFile,
                               currenUserName,
                               tags,
                             }) {
    const {
      height,
      width,
      extension,
      file,
    } = uploadedFile.imageData;
    const {
      name,
      size,
    } = file;
    const configs = {
      method: 'POST',
      body: JSON.stringify({
        title: name,
        extension,
        provider: VOLTAX_PROVIDER,
        caption: name,
        tags,
        uploadedBy: currenUserName,
        approvedBy: currenUserName,
        altText: name,
        credit: '',
        auditTrail: '',
        height,
        width,
        size,
      }),
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.getApproveUploadUrl({ property }), configs);
  }

  static uploadFile({ file, url }) {
    const formData = new FormData();
    formData.append('file', file.imageSrc);
    const config = {
      method: 'PUT',
      body: formData,
    };
    return mmNetworkService.externalFetch(url, config);
  }

  static deleteImage({ property, id }) {
    const configs = {
      method: 'DELETE',
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.deleteImage({ property, id }), configs);
  }

  static deletePublishedImage({ property, id }) {
    const configs = {
      method: 'DELETE',
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.deletePublishedImage({ property, id }), configs);
  }

  static submitImageForApproval({ property, id }) {
    const configs = {
      method: 'POST',
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.submitImageForApproval({ property, id }), configs);
  }

  static approveImage({ property, id, imageInfo, currentUser }) {
    const configs = {
      method: 'POST',
      body: generateImageInfoBody({ imageInfo, currentUser }),
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.approveImage({ property, id }), configs);
  }

  static updateImageInfo({ property, id, imageInfo }) {
    const {
      title,
      caption,
      alt,
      credit,
      audit,
      tags,
    } = imageInfo;
    const configs = {
      method: 'PATCH',
      body: JSON.stringify({
        title,
        provider: VOLTAX_PROVIDER,
        caption,
        altText: alt,
        credit,
        auditTrail: audit,
        tags,
      }),
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.updateImageInfo({ property, id }), configs);
  }

  static updatePublishedImage({ property, id, imageInfo }) {
    const {
      title,
      caption,
      alt,
      credit,
      audit,
      tags,
    } = imageInfo;
    const configs = {
      method: 'PATCH',
      body: JSON.stringify({
        title,
        provider: VOLTAX_PROVIDER,
        caption,
        altText: alt,
        credit,
        auditTrail: audit,
        tags,
      }),
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.updatePublishedImage({ property, id }), configs);
  }

  static async uploadImageFromEditor({
    fileType,
    imageName,
    imageData,
    crop,
    username,
    property,
    tags,
    audit,
    caption,
    credit,
    alt,
    dimensions,
  }) {
    try {
      const config = {
        method: 'POST',
        body: JSON.stringify({
          ...dimensions,
          title: imageName,
          extension: fileType || 'jpg',
          provider: VOLTAX_PROVIDER,
          caption,
          tags,
          uploadedBy: username,
          approvedBy: username,
          altText: alt,
          credit,
          auditTrail: audit,
        }),
      };
      const image = await getRealImageObject(imageData);
      const { signedUrl } = await mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.getApproveUploadUrl({ property }), config);
      const response = await MediaLibraryImagesNetworkService.uploadFile({ file: { imageSrc: imageData }, url: signedUrl });
      const { url, public_id, format } = await response.json();
      const { url: cdnUrl } = await MediaLibraryImagesNetworkService.copyImage({ property, url, provider: VOLTAX_PROVIDER });
      const { url: croppedUrl, cropping } = await getPixelCroppingAndUrl({ image, crop, url: cdnUrl });
      return {
        path: public_id,
        fileExtension: format,
        url: croppedUrl,
        fullImageUrl: cdnUrl,
        newCropping: cropping,
      };
    } catch (e) {
      Logger.log(e);
      return e;
    }
  }

  static copyImage({ property, url, provider }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify({
        url,
        provider,
      }),
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.copyImage({ property }), configs);
  }

  static copyImageByFile({ property, provider }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify({ provider }),
    };
    return mmNetworkService.fetch(MediaLibraryImagesConfigsProvider.copyImageByFile({ property }), configs);
  }
}
