import { getNewEchoState } from '../newEcho.selector';

function getEchoDashboardState(state) {
  return getNewEchoState(state).echoDashboard;
}

export const echoDashboardSelector = {
  isLoading(state) {
    return getEchoDashboardState(state).isLoading;
  },
  posts(state) {
    return getEchoDashboardState(state).posts;
  },
  filters(state) {
    return getEchoDashboardState(state).filters;
  },
  pagination(state) {
    return getEchoDashboardState(state).pagination;
  },
};
