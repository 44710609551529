import React from 'react';
import { Text } from '@ftbpro/mm-admin-ui-components';

import {
  BLOCK_TYPES,
  generateNewBlockDescriptor,
} from './blocksDescriptorGenerator';

import {
  areTextsInSlateValuesEqual,
  createSlateValue,
  getStringFromTextValue,
} from './slate/slateValue.utils';

import { BLOCK_ADDER_TYPES, LIST_ITEMS_SORTING_ORDER } from '../components/blockList/blocklist.constants';

import { getStylesObject } from './blockList.styles';

export function getBlockIndexByKey(blockList, key) {
  const currentBlock = blockList.find(block => block.key === key);
  return blockList.indexOf(currentBlock);
}

function getGroupingBlockIndex(blockList, groupedBlockKey) {
  const groupingBlockTypes = [BLOCK_TYPES.LIST_ITEM];
  return blockList.findIndex(block => groupingBlockTypes.includes(block.type) && getBlockIndexByKey(block.value.items, groupedBlockKey) !== -1);
}

export function getBlockByKey(blockList, key) {
  const blockIndex = getBlockIndexByKey(blockList, key);
  if (blockIndex !== -1) {
    return blockList[blockIndex];
  }
  const indexOfGroupingBlock = getGroupingBlockIndex(blockList, key);
  const selectedBlock = indexOfGroupingBlock !== -1 ? blockList[indexOfGroupingBlock].value.items.find(block => block.key === key) : null;
  return selectedBlock;
}

function isBlockAdder(block) {
  return block.type === BLOCK_TYPES.ADDER;
}

function filterMiddleAddersFromBlockList(blockList) {
  return blockList.filter((block, index) => !isBlockAdder(block) || index === blockList.length - 1);
}

export function removeMiddleAdders(blockList) {
  return [
    ...filterMiddleAddersFromBlockList(blockList),
  ];
}

function currentBlockIsAdder(blockList, key) {
  return isBlockAdder(blockList.find(block => block.key === key));
}

export function isLastContentBlock(blockKey, blockList) {
  return blockList[blockList.length - 1].type === BLOCK_TYPES.ADDER && blockList[blockList.length - 2].key === blockKey;
}

export function insertNewBlockAdderAfterBlockIfNeeded(blockList, blockKeyForInsertion, blockAdderDescriptor) {
  if (currentBlockIsAdder(blockList, blockKeyForInsertion)) {
    return [...blockList];
  }
  const newBlockList = filterMiddleAddersFromBlockList(blockList);
  const blockIndex = getBlockIndexByKey(newBlockList, blockKeyForInsertion);
  if (!isLastContentBlock(blockKeyForInsertion, newBlockList)) {
    return [
      ...newBlockList.slice(0, blockIndex + 1),
      blockAdderDescriptor,
      ...newBlockList.slice(blockIndex + 1),
    ];
  }
  return [...newBlockList];
}

export function enumerateListItemBlocks(blockList, sortingOrder) {
  const sortedAscending = sortingOrder === LIST_ITEMS_SORTING_ORDER.ASCENDING;
  let listItemNumber = sortedAscending ? 0 : blockList.filter(block => block.type === BLOCK_TYPES.LIST_ITEM).length + 1;
  return blockList.map(block => {
    if (block.type === BLOCK_TYPES.LIST_ITEM) {
      listItemNumber = sortedAscending ? listItemNumber += 1 : listItemNumber -= 1;
      const newGroupBlockItems = block.value.items.map(item => (item.type === BLOCK_TYPES.TITLE ? { ...item, value: { ...item.value, listItemNumber } } : item));
      return { ...block, value: { ...block.value, items: newGroupBlockItems, listItemNumber } };
    }
    return block;
  });
}

export const getSortingOrder = blockList => {
  const listItemBlocks = blockList.filter(block => block.type === BLOCK_TYPES.LIST_ITEM);
  if (listItemBlocks.length > 1) {
    const isAscending = listItemBlocks[0].value.listItemNumber < listItemBlocks[listItemBlocks.length - 1].value.listItemNumber;
    return isAscending ? LIST_ITEMS_SORTING_ORDER.ASCENDING : LIST_ITEMS_SORTING_ORDER.DESCENDING;
  }
  return LIST_ITEMS_SORTING_ORDER.ASCENDING;
};

export function removeBlock(blockList, key) {
  const indexToDelete = getBlockIndexByKey(blockList, key);
  const newBlockList = [
    ...blockList.slice(0, indexToDelete),
    ...blockList.slice(indexToDelete + 1),
  ];
  const enumeratedBlockList = enumerateListItemBlocks(newBlockList, getSortingOrder(blockList));
  return removeMiddleAdders(enumeratedBlockList);
}

export function replaceBlockWithBlockAdderInBlockList(blockList, blockKey, blockAdderDescriptor) {
  const indexToReplace = getBlockIndexByKey(blockList, blockKey);
  if (indexToReplace === -1) {
    return blockList;
  }
  const inTwoLastPositions = indexToReplace === blockList.length - 2 || indexToReplace === blockList.length - 1;
  if (inTwoLastPositions) {
    return [...blockList.slice(0, indexToReplace), blockAdderDescriptor];
  }

  return [
    ...blockList.slice(0, indexToReplace),
    blockAdderDescriptor,
    ...blockList.slice(indexToReplace + 1),
  ];
}

export function replaceBlockInBlockList(blockList, blockKeyToReplace, newBlockDescriptor) {
  const indexToReplace = getBlockIndexByKey(blockList, blockKeyToReplace);
  if (indexToReplace !== -1) {
    return [
      ...blockList.slice(0, indexToReplace),
      newBlockDescriptor,
      ...blockList.slice(indexToReplace + 1),
    ];
  }
  const indexOfGroupingBlock = getGroupingBlockIndex(blockList, blockKeyToReplace);
  if (indexOfGroupingBlock !== -1) {
    const newGroupBlockValue = replaceBlockInGroupBlock(blockList[indexOfGroupingBlock], blockKeyToReplace, newBlockDescriptor); // eslint-disable-line
    return [
      ...blockList.slice(0, indexOfGroupingBlock),
      newGroupBlockValue,
      ...blockList.slice(indexOfGroupingBlock + 1),
    ];
  }
    return blockList;
}

export function replaceBlockInBlockListWithMultipleBlocks(blockList, blockKeyToReplace, blocks) {
  const indexToReplace = getBlockIndexByKey(blockList, blockKeyToReplace);
  if (indexToReplace !== -1) {
    return [
      ...blockList.slice(0, indexToReplace),
      ...blocks,
      ...blockList.slice(indexToReplace + 1),
    ];
  }
  return blockList;
}

export function replaceBlockInGroupBlock(groupBlockDescriptor, blockKeyToReplace, newBlockDescriptor) {
  const newGroupBlockItems = replaceBlockInBlockList(groupBlockDescriptor.value.items, blockKeyToReplace, newBlockDescriptor);
  const newBlockValue = { ...groupBlockDescriptor.value, items: newGroupBlockItems };
  return { ...groupBlockDescriptor, value: newBlockValue };
}

export function removeDoubleBottomBlockAdderIfNeedTo(blockList) {
  const blockListLength = blockList.length;
  if (blockListLength >= 2 && isBlockAdder(blockList[blockListLength - 1]) && isBlockAdder(blockList[blockListLength - 2])) {
    return removeBlock(blockList, blockList[blockList.length - 1].key);
  }
  return blockList;
}

export function insertBlocksAtBlockKeyIndex(blockList, blockKey, newBlocks, beforeBlock = false) {
  const blockIndexToInsert = getBlockIndexByKey(blockList, blockKey);
  const blockOffset = beforeBlock ? 0 : 1;
  return [
    ...blockList.slice(0, blockIndexToInsert + blockOffset),
    ...newBlocks,
    ...blockList.slice(blockIndexToInsert + blockOffset),
  ];
}
export const generateSeveralFAQBlocks = numberOfNewBlocksToGenerate => {
  const groupedBlocks = [];
  for (let i = 0; i < numberOfNewBlocksToGenerate; i += 1) {
    const questionBlock = generateNewBlockDescriptor(BLOCK_TYPES.QUESTION, { text: '' });
    const answerBlock = generateNewBlockDescriptor(BLOCK_TYPES.TEXT, createSlateValue([{ text: '' }]));
    const newGroupBlock = generateNewBlockDescriptor(BLOCK_TYPES.FAQ, { items: [questionBlock, answerBlock] });
    groupedBlocks.push(newGroupBlock);
  }
  return groupedBlocks;
};

export function generateSeveralNewGroupedBlocks(numberOfNewBlocksToGenerate) {
  const groupedBlocks = [];
  for (let i = 0; i < numberOfNewBlocksToGenerate; i += 1) {
    const listItemNumber = i;
    const titleBlock = generateNewBlockDescriptor(BLOCK_TYPES.TITLE, { text: '', listItemNumber });
    const groupedPlaceholder = generateNewBlockDescriptor(BLOCK_TYPES.ADDER, BLOCK_ADDER_TYPES.GROUPED_PLACEHOLDER);
    const textBlock = generateNewBlockDescriptor(BLOCK_TYPES.TEXT, createSlateValue([{ text: '' }]));
    const newGroupBlock = generateNewBlockDescriptor(BLOCK_TYPES.LIST_ITEM, { listItemNumber, items: [titleBlock, groupedPlaceholder, textBlock] });
    groupedBlocks.push(newGroupBlock);
  }
  return groupedBlocks;
}

export function generateMultipleNewInlineTextBlocks(arrayOfTextNodes) {
  return arrayOfTextNodes.map(textNode => {
    return generateNewBlockDescriptor(BLOCK_TYPES.TEXT, textNode);
  });
}

export function insertBlockAtBlockKeyIndex(blockList, blockKey, newBlock, beforeBlock = false) {
  const blockIndexToInsert = getBlockIndexByKey(blockList, blockKey);
  const blockOffset = beforeBlock ? 0 : 1;
  return [
    ...blockList.slice(0, blockIndexToInsert + blockOffset),
    newBlock,
    ...blockList.slice(blockIndexToInsert + blockOffset),
  ];
}

export function insertLastPositionBlockAdderIfNeedTo(blockList, newBlockAdderDescriptor) {
  if (blockList.length === 0) {
    return [newBlockAdderDescriptor];
  }
  if (blockList[blockList.length - 1].type !== BLOCK_TYPES.ADDER) {
    return [
      ...blockList,
      newBlockAdderDescriptor,
    ];
  }
  return blockList;
}

export function replaceBlockWithBlocksArray(blockList, blocksArray, key) {
  const indexToReplace = getBlockIndexByKey(blockList, key);
  return [
    ...blockList.slice(0, indexToReplace),
    ...blocksArray,
    ...blockList.slice(indexToReplace + 1),
  ];
}

export function updateBlockValueInBlockList(blockList, value, blockKey) {
  const blockToChange = getBlockByKey(blockList, blockKey);
  const indexToReplace = getBlockIndexByKey(blockList, blockKey);
  if (indexToReplace === -1) {
    return blockList;
  }
  const newBlock = { ...blockToChange, value };
  return [
    ...blockList.slice(0, indexToReplace),
    newBlock,
    ...blockList.slice(indexToReplace + 1),
  ];
}

export function getBlockListFromMultipleBlocksAndNewActiveBlockKey(blockList, newBlocks, blockKey) {
  const indexToReplace = getBlockIndexByKey(blockList, blockKey);
  if (indexToReplace === -1) {
    return blockList;
  }
  const newBlockList = [
    ...blockList.slice(0, indexToReplace),
    ...newBlocks,
    ...blockList.slice(indexToReplace + 1),
  ];
  return {
    newBlockList,
    newCurrentlyActiveBlockKey: newBlockList[newBlocks.length - 1].key,
  };
}

export function removeAllBlockAdders(blocklist) {
  return blocklist.filter(block => block.type !== BLOCK_TYPES.ADDER);
}

export function isInsertionAllowedBeforeBlock(block, blockList) {
  if (block.type === BLOCK_TYPES.ADDER) {
    return false;
  }

  const blockIndex = getBlockIndexByKey(blockList, block.key);
  // block adder is preceding a block
  if (blockIndex >= 0 && blockIndex < (blockList.length - 1) && blockList[blockIndex - 1]?.type === BLOCK_TYPES.ADDER) {
    return false;
  }
  return true;
}

export function isBlockBottomAdder(blockKey, blockList) {
  const block = getBlockByKey(blockList, blockKey);
  return block && block.type === BLOCK_TYPES.ADDER && block.value === BLOCK_ADDER_TYPES.BOTTOM;
}

export function getAccumulatedTextStringFromBlockList(blockList) {
  const textStringArray = blockList.map(block => {
    switch (block.type) {
      case BLOCK_TYPES.TEXT:
        return getStringFromTextValue(block.value);
      case BLOCK_TYPES.LIST_ITEM:
        return getAccumulatedTextStringFromBlockList(block.value.items);
      case BLOCK_TYPES.QUESTION:
      case BLOCK_TYPES.TITLE:
        return block.value.text;
      case BLOCK_TYPES.QUOTE:
        return `${block.value.text} ${block.value.cite}`;
      case BLOCK_TYPES.FAQ:
        return getAccumulatedTextStringFromBlockList(block.value.items);
      default:
        return '';
    }
  });
  return textStringArray.filter(word => word !== '').join(' ');
}

export function overviewBlockText(value = {}) {
  const { caption, credit } = value;
  if (!credit && !caption) {
    return null;
  }
  return credit && caption ? (
    <Text type={Text.TEXT_TYPES.PARAGRAPH_M} style={getStylesObject()}>{`@${value.credit} | ${value.caption}`}</Text>
  ) : (
    <Text type={Text.TEXT_TYPES.PARAGRAPH_M} style={getStylesObject()}>{`@${value.credit}`}</Text>
  );
}

export const didStartTypingInTextBlock = (block, newBlockValue) => (block && block.type === BLOCK_TYPES.TEXT && !areTextsInSlateValuesEqual(block.value, newBlockValue));

export const getNextAvailableTextBlock = (blockList, blockKey) => {
  const isGroupedBlock = getGroupingBlockIndex(blockList, blockKey) !== -1;
  if (isGroupedBlock) {
    const groupingBlock = blockList[getGroupingBlockIndex(blockList, blockKey)];
    return groupingBlock.value.items.find(item => item.type === BLOCK_TYPES.TEXT);
  }
  const index = getBlockIndexByKey(blockList, blockKey);
  const relevantBlockList = blockList.slice(index + 1);
  return relevantBlockList.find(item => item.type === BLOCK_TYPES.TEXT || (item.type === BLOCK_TYPES.ADDER && item.value === BLOCK_ADDER_TYPES.BOTTOM));
};

export const isBlockKeyInsideListBlock = (blockDescriptor, blockKey) => {
  return blockDescriptor.type === BLOCK_TYPES.LIST_ITEM && blockDescriptor.value.items.find(block => block.key === blockKey);
};

export const getListBlockTypeCount = blockList => {
  return blockList.filter(e => e.type === BLOCK_TYPES.LIST_ITEM).length;
};
