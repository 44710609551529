import { Action } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { userSelector } from 'core/store/user/user.selector';
import { CONTRIBUTORS_ACTION_TYPE } from '../analytics.constants';

export const createCustomDimensions = (state: RootState) => {
  const platformUserId = userSelector.getUserId(state);
  const platformUserRole = userSelector.getUserRoles(state);

  return {
    platformUserId,
    platformUserRole,
  };
};

export const shouldSkipAction = (action: Action) => {
  const { type: actionType } = action;
  if (!actionType) {
    return true;
  }

  return !actionType.includes(CONTRIBUTORS_ACTION_TYPE);
};
