import { Dispatch } from '@reduxjs/toolkit';
import { Logger } from 'core/logger';
import { getOrgIdFromSubdomain } from 'core/store/user/user.utils';
import { InThisStoryService } from '../../services/inThisStoryServices/inThisStoryConfigServiceApiProvider';

export const IN_THIS_STORY_REQUEST = '[IN THIS STORY] - get in this story list by domain';
export const IN_THIS_STORY_REQUEST_SUCCESS = '[IN THIS STORY] - get in this story list SUCCESS';
export const IN_THIS_STORY_REQUEST_FAILED = '[IN THIS STORY] - get in this story list FAILED';

export const fetchInThisStoryData = () => (dispatch: Dispatch) => {
  dispatch({
    type: IN_THIS_STORY_REQUEST,
  });
  const currentOrganization: string = getOrgIdFromSubdomain();

  return InThisStoryService.getInThisStoryByOrganization(currentOrganization)
    .then(data => {
      const { inThisStories } = data;
      dispatch({
        type: IN_THIS_STORY_REQUEST_SUCCESS,
        payload: {
          inThisStories,
        },
      });
    })
    .catch(error => {
      Logger.error('Could not fetch InThisStory: ', error);

      return dispatch({
        type: IN_THIS_STORY_REQUEST_FAILED,
        payload: error,
      });
    });
};
