import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@ftbpro/mm-admin-auth';
import { unregister } from './registerServiceWorker';
import { store } from './core/store';
import { Logger } from './core/logger';
import { getAuth0InitOption } from './core/configs/auth0Config';
import { App } from './App';
import { initFullStory } from './core/externalScripts/fullStory';
import { AvailableNavigationItems } from './platformConfigs/external/navigationItems';
import { Routes } from './platformConfigs/external/Routes';
import { initAppcues } from './core/externalScripts/appcues';

const target = document.querySelector('#root');

initFullStory();
initAppcues();

try {
  render(
    <Provider store={store}>
      <Auth0Provider
        {...getAuth0InitOption()}
      >
        <App
          isExternal
          availableNavigationItems={AvailableNavigationItems}
          routes={Routes}
        />
      </Auth0Provider>
    </Provider>,
    target,
  );
  unregister();
} catch (error) {
  Logger.error(error);
}
