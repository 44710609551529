/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSliceName } from 'core/store/store.utils';
import { Product } from 'core/constants';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import { AppDispatch, RootState } from 'core/store';
import { SmartServiceConfigsProvider } from 'containers/cms/editor/services/smartServiceConfigsProvider';
import { DropdownListItemProps } from '@ftbpro/mm-admin-ui-components';
import { IN_THIS_STORY_REQUEST_SUCCESS } from 'containers/cms/commonComponents/inThisStorySelect/inThisStory.actions';
import { removeItemFromImmutableArrayByValue } from '@ftbpro/mm-admin-core-utils';
import { InThisStoryDataObject, InThisStorySliceState } from './types';
import { EDITOR_ACTION_TYPE_PREFIX } from '../../editor.constants';

const sliceScope = 'inThisStory';
const SLICE_NAME_WITH_PREFIX = EDITOR_ACTION_TYPE_PREFIX + sliceScope;
const SLICE_NAME = getSliceName(SLICE_NAME_WITH_PREFIX, sliceScope);

export const initialState: InThisStorySliceState = {
  inThisStoryList: [],
  selectedInThisStories: [],
};

const inThisStory = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    addInThisStory(state: InThisStorySliceState, action: PayloadAction<string>) {
      const inThisStorySlug = action.payload;
      if (state.selectedInThisStories.indexOf(inThisStorySlug) === -1) {
        state.selectedInThisStories.push(inThisStorySlug);
      }
    },
    removeInThisStory(state: InThisStorySliceState, action: PayloadAction<string>) {
      const inThisStorySlug = action.payload;
      if (state.selectedInThisStories.indexOf(inThisStorySlug) > -1) {
        state.selectedInThisStories = removeItemFromImmutableArrayByValue(state.selectedInThisStories, inThisStorySlug);
      }
    },
    setInThisStoriesSlugs(state: InThisStorySliceState, action: PayloadAction<string[]>) {
      state.selectedInThisStories = action.payload || [];
    },
  },
  extraReducers(builder) {
    builder.addCase(IN_THIS_STORY_REQUEST_SUCCESS, (state: RootState, action: Action) => {
      const { inThisStories: inThisStoryList } = (action as PayloadAction<{ inThisStories: InThisStoryDataObject[] }>).payload;
      return {
        ...state,
        inThisStoryList,
      };
    });
  },
});

export const {
  addInThisStory,
  removeInThisStory,
  setInThisStoriesSlugs,
} = inThisStory.actions;

export default inThisStory.reducer;
