import { BLOCK_TYPES } from './blocksDescriptorGenerator';

const INSTARGAM_POST_URL = /instagram\.com\/p\/(.*?)(\/|"|'|$)/;
const DAILY_MOTION_VIDEO_ID = /video\/(.*?)(\/|"|'|\?|$)/;
const SOUNDCLOUD_TRACK_ID = /2F([0-9][^&]*)/;
const VIMEO_VIDEO_ID = /(?!\/video\/)([0-9]+)(?=[?])/;

const mediaIdExtractor = {
  [BLOCK_TYPES.YOUTUBE]: url => {
    let id = '';
    let calculatedUrl = url;
    calculatedUrl = calculatedUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (calculatedUrl[2] !== undefined) {
      id = calculatedUrl[2].split(/[^0-9a-z_-]/i);
      [id] = id;
    } else {
      [id] = calculatedUrl;
    }
    return id || undefined;
  },

  [BLOCK_TYPES.TWITTER]: url => {
    const match = url.match(/status\/(\d+)/);
    if (match) {
      return match[1];
    }

    return undefined;
  },

  [BLOCK_TYPES.INSTAGRAM]: (url, htmlString) => {
    const match = htmlString.match(INSTARGAM_POST_URL);
    if (match) {
      return match[1];
    }

    return undefined;
  },

  [BLOCK_TYPES.DAILY_MOTION]: (url, htmlString) => {
    const match = htmlString.match(DAILY_MOTION_VIDEO_ID);
    if (match) {
      return match[1];
    }

    return undefined;
  },

  [BLOCK_TYPES.SOUNDCLOUD]: (url, htmlString) => {
    const match = htmlString.match(SOUNDCLOUD_TRACK_ID);
    if (match) {
      return match[1];
    }

    return undefined;
  },
  [BLOCK_TYPES.VIMEO]: (url, htmlString) => {
    const match = htmlString.match(VIMEO_VIDEO_ID);
    if (match) {
      return match[1];
    }

    return undefined;
  },

  [BLOCK_TYPES.GFYCAT]: url => {
    const match = url.split('/');
    if (match) {
      return match[match.length - 1];
    }

    return undefined;
  },
};

export const extractIdFromMedia = (type, oEmbedData) => {
  if (oEmbedData.id) { return oEmbedData.id; }
  if (typeof mediaIdExtractor[type] === 'function' && (oEmbedData.url || oEmbedData.html)) {
    return mediaIdExtractor[type](oEmbedData.url, oEmbedData.html);
  }
  if (oEmbedData.embed_id && type === BLOCK_TYPES.CONTENT_ENRICHMENT) { return oEmbedData.embed_id; }

  return null;
};
